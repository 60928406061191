import React, { useEffect, useState } from 'react';
import { Dialog, List, ListItem, ListItemText, DialogContent, Pagination, DialogTitle, Typography, IconButton, Grid, Divider } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';


export default function OnlineUsersModal({ open, setOpen }) {
    // Pagination için state
    const LoginState = useSelector((state: any) => state.Login)

    const [page, setPage] = useState(1);
    const itemsPerPage = 50; // Sayfa başına düşen kullanıcı sayısı
    const [users, setUsers] = useState([]); // Kullanıcı listesi

    // Sayfalama fonksiyonu
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const [loading, setLoading] = useState(false)
    async function getOnlineUsers() {
        setLoading(true)
        axios.get(
            `${process.env.REACT_APP_API_URL}/report/dashboard/onlineUsers`,
            {
                headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
            }
        ).then((response) => {
            // expected the setProducts to be filled with the return of this request
            setUsers(response.data);
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            err?.response?.data?.detail.errorMessage
                ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
                    variant: 'error',
                })
                : enqueueSnackbar(
                    'The process could not be completed. Please try again a few minutes later.',
                    { variant: 'error' }
                )
        })
    }
    useEffect(() => {
        if (open) {
            getOnlineUsers()
        }
    }, [open])


    // Listeyi sayfalara ayırma
    const paginatedUsers = users?.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    const today = new Date();
    const dateTimeStr = today.toLocaleString('tr-TR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });

    return (
        <Dialog open={open} maxWidth="xs" fullWidth>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: 80, textAlign: "center" }}>
                        <Typography fontSize={16}>
                            {dateTimeStr}
                        </Typography>
                    </div>

                    <Typography fontSize={26} fontWeight={"bold"}>
                        ONLINE USERS
                    </Typography>
                    <IconButton edge="end" color="inherit" onClick={() => setOpen(false)} size="small">
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent dividers sx={{padding:"5px"}}>
                <Grid container height={450}>
                    <Grid item xs={12} display={"flex"} justifyContent={"space-between"} padding={1} marginBottom={1} borderBottom={"1px solid #DCDDDD"}>
                        <Typography fontSize={14} color={"#6E6F6F"}>Name</Typography>
                        <Typography fontSize={14} color={"#6E6F6F"}>Time</Typography>
                    </Grid>
                    <div style={{ overflow: 'auto', height: '400px',width:"100%" }}>
                        {paginatedUsers?.map((user, index) => (
                            <Grid item xs={12} rowGap={1} padding={1}>
                                <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #DCDDDD" }}>
                                    <Typography fontSize={16} color={"#212529"}>{user.nameSurname}</Typography>
                                    <Typography fontSize={16} color={"#212529"}>{user.timeConnected}</Typography>
                                </div>
                            </Grid>

                        ))}
                    </div>
                </Grid>
                {/* Pagination */}
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "end", paddingRight: 30 }}>
                    <Pagination
                        disabled={loading}
                        count={Math.ceil(users?.length / itemsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color="primary"
                        style={{ padding: 1, display: 'flex', justifyContent: 'center' }}
                        size='small'
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}>
                        <Typography variant="caption">
                            Showing {(page - 1) * itemsPerPage + 1}-{Math.min(page * itemsPerPage, users?.length)} of {users?.length} records
                        </Typography>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
