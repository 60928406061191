import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AddCategory } from './AddCategory'
import Radio from '@mui/material/Radio'
import axios from 'axios'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
} from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { TransitionProps } from '@mui/material/transitions'
import CloseIcon from '@mui/icons-material/Close'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  /* border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  }, */
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ height: 20 }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  /*  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)', */
  /*   flexDirection: 'row-reverse', */
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  /*   borderTop: '1px solid rgba(0, 0, 0, .125)', */
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

type Props = {
  company: any
  setCategoryId?: any
  categoryId?: any
  categoryNamee?: any
  button?: any
}

export const SelectCategory: FC<Props> = ({ company, setCategoryId, categoryId, categoryNamee, button }) => {
  const [show, setShow] = useState(false)
  const [categoryName, setcategoryName] = useState<any>('Select Category')
  useEffect(() => {
    if (categoryNamee?.length > 1) {
      setcategoryName(categoryNamee)
    } else {
      setcategoryName('Select Category')
    }
  }, [categoryNamee])

  const handleClose = () => {
    setShow(false)
  }
  const handleSave = () => {
    setShow(false)
  }
  const handleShow = () => setShow(true)
  const LoginState = useSelector((state: any) => state.Login)
  const [categories, setCategories] = useState<any>([])

  const [first, setfirst] = useState(false)
  const [getCategoriesLoading, setGetCategoriesLoading] = useState(false)
  useEffect(() => {
    function test() {
      setGetCategoriesLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/content/getAccessibleCategories`, {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        })
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          if (company === 0) {
            setCategories(response.data.customerCategory)
          } else {
            setCategories(response.data.customerCategory)
          }
          setGetCategoriesLoading(false)
        })
        .catch((error) => {
          error.response.data.detail.errorMessage
            ? enqueueSnackbar(`${error.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
            : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              { variant: 'error' }
            )
          setGetCategoriesLoading(false)
        })
    }

    test()
  }, [first])
  const [hover, setHover] = useState('')
  const [Category, setCategory] = useState<any>('')
  useEffect(() => {
    setCategory(categoryId)
  }, [categoryId])

  useEffect(() => {
    setCategoryId(Category)
  }, [Category])

  return (
    <>
      <Button
        variant='text'
        style={{
          fontSize: '16px',
          fontWeight: 'bold',
          color: 'white',
          textTransform: 'none',
          fontFamily: 'Atma',
        }}
        onClick={handleShow}
      >
        {categoryName}
      </Button>
      <Dialog
        open={show}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth='xl'
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle fontWeight={'bold'}>
          {'SELECT CATEGORY'}{' '}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className='d-flex modal-bodyy'
            style={{
              overflowY: 'scroll',
              overflowX: 'scroll',
              minHeight: '600px',
              height: '600px',
            }}
          >
            {getCategoriesLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              categories?.map((data: any) => (
                <>
                  <div>
                    {
                      //1.katman
                    }
                    <Accordion
                      style={{
                        marginRight: 20,
                        borderRadius: '10px 10px 5px 5px',
                        border: '1px solid #d5d5d5',
                        minWidth: '300px',
                      }}
                      defaultExpanded={true}
                    >
                      <AccordionSummary
                        /* expandIcon={
                                  <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                } */
                        style={{
                          background: '#D5D5D5',
                          borderRadius: '10px 10px 0px 0px',
                          padding: '0px 10px 0px 0px',
                          minHeight: '60px',
                        }}
                      >
                        <div className='d-flex ' style={{ alignItems: 'center', height: 20 }}>
                          {/* <Radio
                            checked={Category == data.categoryId}
                            onClick={(e: any) => {
                              setcategoryName(data?.name)
                              setCategory(e.target.value)
                              e.stopPropagation()
                            }}
                            value={data.categoryId}
                            name='category'
                            color='success'
                            
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: '22px',
                              },
                              '&.Mui-checked': {
                                color: '#53b658',
                              },
                            }}
                          /> */}
                          <div
                            style={{
                              fontWeight: 'bold',
                              cursor: 'pointer',
                              fontSize: '12px',
                              marginLeft: '-4px',
                            }}
                          >
                            {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                              data.name.slice(1)}
                          </div>
                          <span
                            title='Number of available questions'
                            className='text-dark  ms-2'
                            style={{ fontSize: '10px', color: '#8e8e8e', fontWeight: 'bold' }}
                          >
                            ({data.questionCount})
                          </span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: '3px' }}>
                        {
                          //2. katman
                        }

                        {data?.children?.map((child: any) =>
                          child.children.length > 0 ? (
                            <>
                              <Accordion
                                style={{
                                  marginTop: '5px',
                                  marginBottom: '5px',
                                  boxShadow: 'none',
                                  borderRadius: '10px 10px 0px 0px',
                                }}
                                defaultExpanded={true}
                              >
                                <AccordionSummary
                                  sx={{
                                    margin: '0px!important',
                                    '.MuiAccordionSummary-content': {
                                      // AccordionSummary'nin içeriği için stil
                                      marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                    },
                                  }}
                                  style={{
                                    borderRadius: '10px 10px 0px 0px',
                                    padding: '0px 5px 0px 0px',
                                    minHeight: '20px',
                                    marginLeft: '0px!important',
                                    background: hover === child.categoryId ? '#e7e7e7' : '',
                                  }}
                                >
                                  <div
                                    className='d-flex'
                                    style={{
                                      alignItems: 'center',
                                      justifyItems: 'start',
                                      height: 20,
                                    }}
                                    onMouseEnter={(e: any) => {
                                      setHover(child.categoryId)
                                    }}
                                    onMouseLeave={(e: any) => {
                                      setHover('')
                                    }}
                                  >
                                    {/* <Radio
                                      checked={Category == child.categoryId}
                                      onClick={(e: any) => {
                                        setcategoryName(child?.name)
                                        setCategory(e.target.value)
                                        e.stopPropagation()
                                      }}
                                      value={child.categoryId}
                                      name='category'
                                      color='success'
                                      sx={{
                                        '& .MuiSvgIcon-root': {
                                          fontSize: '22px',
                                        },
                                        '&.Mui-checked': {
                                          color: '#53b658',
                                        },
                                      }}
                                    /> */}
                                    <span
                                      className={'text-gray-800 d-flex'}
                                      style={{
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                        marginLeft: '-4px',
                                      }}
                                    >
                                      {child.name.charAt(0).toUpperCase() + child.name.slice(1)}
                                    </span>
                                    <span
                                      title='Number of available questions'
                                      className='text-dark text-muted ms-2'
                                      style={{
                                        fontSize: '10px',
                                        color: '#8e8e8e',
                                        fontWeight: 'normal',
                                      }}
                                    >
                                      ({child.questionCount})
                                    </span>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: '3px' }}>
                                  {child?.children?.map((child: any) => (
                                    <div id={child.categoryId} className='ms-0 mt-3 '>
                                      <div
                                        className='d-flex'
                                        style={{
                                          alignItems: 'center',
                                          height: 35,
                                          paddingLeft: 5,
                                          background: hover === child.categoryId ? '#e7e7e7' : '',
                                        }}
                                        onMouseEnter={(e: any) => {
                                          setHover(child.categoryId)
                                        }}
                                        onMouseLeave={(e: any) => {
                                          setHover('')
                                        }}
                                      >
                                        <Radio
                                          checked={Category == child.categoryId}
                                          onClick={(e: any) => {
                                            setcategoryName(child?.name)
                                            setCategory(e.target.value)
                                            e.stopPropagation()
                                          }}
                                          value={child.categoryId}
                                          name='category'
                                          color='success'
                                          /* size='small' */
                                          sx={{
                                            width: '30px',
                                            height: '30px',
                                            '& .MuiSvgIcon-root': {
                                              fontSize: '16px',
                                            },
                                            '&.Mui-checked': {
                                              color: '#53b658',
                                            },
                                          }}
                                        />
                                        <a
                                          className={'text-gray-800 d-flex '}
                                          style={{
                                            cursor: 'pointer',
                                            fontSize: '12px',
                                            alignItems: 'center',
                                            maxWidth: '210px',
                                            marginLeft: '-4px',
                                          }}
                                        >
                                          {child.name.charAt(0).toUpperCase().replace('🅜', '') +
                                            child.name.slice(1)}
                                        </a>

                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '20px',
                                          }}
                                        >
                                          <span
                                            title='Number of available questions'
                                            className='ms-2'
                                            style={{ color: '#8e8e8e', fontSize: '10px' }}
                                          >
                                            {`(${child.questionCount})`}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </AccordionDetails>
                              </Accordion>
                            </>
                          ) : null
                        )}
                        {
                          // 3. katman
                        }

                        {data?.children?.map((child: any) =>
                          child.children.length > 0 ? null : (
                            <div id={child.categoryId} className='ms-0 mt-3 '>
                              <div
                                className='d-flex border-1'
                                style={{
                                  alignItems: 'center',
                                  height: 35,
                                  paddingLeft: 4,
                                  background: hover === child.categoryId ? '#e7e7e7' : '',
                                }}
                                onMouseEnter={(e: any) => {
                                  setHover(child.categoryId)
                                }}
                                onMouseLeave={(e: any) => {
                                  setHover('')
                                }}
                              >
                                <Radio
                                  checked={Category == child.categoryId}
                                  onClick={(e: any) => {
                                    setcategoryName(child?.name)
                                    setCategory(e.target.value)
                                    e.stopPropagation()
                                  }}
                                  value={child.categoryId}
                                  name='category'
                                  color='success'
                                  /* size='small' */
                                  sx={{
                                    '& .MuiSvgIcon-root': {
                                      fontSize: '22px',
                                    },
                                    '&.Mui-checked': {
                                      color: '#53b658',
                                    },
                                  }}
                                />
                                <a
                                  className={'text-gray-800 d-flex'}
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    maxWidth: '210px',
                                    marginLeft: '-4px',
                                  }}
                                >
                                  {child.name.charAt(0).toUpperCase() + child.name.slice(1)}
                                </a>

                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '20px',
                                  }}
                                >
                                  <span
                                    title='Number of available questions'
                                    className='ms-2'
                                    style={{ color: '#8e8e8e', fontSize: '10px' }}
                                  >
                                    {`(${child.questionCount})`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
              ))
            )}
            {/* {products?.map((data: any) => (
  
                      {
                        //3. section
                      }
                      {data?.children?.map((child: any) =>
                        child.children.length > 0 ? null : (
                          
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
             
            ))} */}
          </div>
          {/*  <div>
            Not in the list? <AddCategory company={company} second={first} setSecond={setfirst} />
          </div> */}
        </DialogContent>
        <DialogActions style={{ width: '90%' }}>
          {/*  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',marginRight:20}}>
            <Typography fontSize={12} color={'#8E8E8E'} marginRight={1}>
              Not in the list?
            </Typography>
            <AddCategory company={company} second={first} setSecond={setfirst} />
          </div> */}
          <Button
            variant='contained'
            color='primary'
            style={{ marginLeft: '1%' }}
            onClick={() => (handleSave(), button && button())}
          >
            DONE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
