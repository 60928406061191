/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core/PageData'
import HelpPageLanding from './HelpPageLanding'

const HelpBreadcrumbs: Array<PageLink> = [
    {
      title: 'Help',
      // path: '/apps/user-management/users',
      path: '/help/onboarding',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
const HelpPage: FC = () => {
  const intl = useIntl()
  return (
    <>
     <PageTitle breadcrumbs={HelpBreadcrumbs} >Onboarding</PageTitle>
     <div className='row'>
     <HelpPageLanding/>
     </div>
    </>
  )
}

export {HelpPage}
