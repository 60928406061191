import React, {useEffect, useState} from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import {Box, IconButton, TextField, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import {SnackbarProvider, enqueueSnackbar} from 'notistack'
import {useSelector} from 'react-redux'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export interface AddEditGroupModalProps {
  open: boolean
  openEdit: boolean
  setOpen: any
  setOpenEdit: any
  first: any
  setFirst: any
  groupDetail?: any
}

function AddEditGroupModal({
  open,
  setOpen,
  openEdit,
  setOpenEdit,
  first,
  setFirst,
  groupDetail,
}: AddEditGroupModalProps) {

  const LoginState = useSelector((state: any) => state.Login)

  const [discardWarnModal, setDiscardWarnModal] = useState(false)
  const [isChange, setIsChange] = useState(false)

  function handleClose() {
    open && setOpen(false)
    openEdit && setOpenEdit(false)
    handleClear()
  }

  const [groupName, setGroupName] = React.useState('')
  const [explanation, setExplanation] = React.useState('')
  const [groupNameError, setGroupNameError] = React.useState('')

  useEffect(() => {
    if (openEdit) {
      setGroupName(groupDetail?.name)
      setExplanation(groupDetail?.explanation)
    }
  }, [openEdit])

  const handleGroupNameChange = (event) => {
    setIsChange(true)
    setGroupNameError('')
    setGroupName(event.target.value)
  }

  const handleExplanationChange = (event) => {
    setIsChange(true)
    setExplanation(event.target.value)
  }
  const [loading, setLoading] = useState(false)
  const handleSave = () => {
    setLoading(true)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/groupCreate`,
        // '{\n  "name": "uhud",\n  "explanation": "uhudaçıklama",\n  "userIdList": [\n    1,61,65\n  ]\n}',
        {
          name: groupName,
          explanation: explanation,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
          timeout:90000
        }
      )
      .then(function (response) {
        enqueueSnackbar('Saved is success', {variant: 'success'})
        setLoading(false)
        setFirst(!first)
        setTimeout(() => {
            handleClose()   
        }, 1000);
       
      })
      .catch((err: any) => {
        setLoading(false)
        err.response?.data?.detail?.errorMessage === 'GROUP NAME ALREADY EXISTS' &&
        setGroupNameError(err.response?.data?.detail?.errorMessage)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              {variant: 'error'}
            )
      })
  }

  const handleEdit = () => {
    setLoading(true)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/updateGroup`,
        {
          name: groupName,
          explanation: explanation,
          groupId: groupDetail?.groupId,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
          timeout:90000
        }
      )
      .then(async function (response: any) {
        enqueueSnackbar('Saved is success', {variant: 'success'})
        setFirst(!first)
        setLoading(false)
        handleClose()
      })
      .catch((err: any) => {
        setLoading(false)
        err.response?.data?.detail?.errorMessage === 'GROUP NAME ALREADY EXISTS' &&
          setGroupNameError(err.response?.data?.detail?.errorMessage)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              {variant: 'error'}
            )
      })
  }

  function formatDate(date) {
    // Tarih stringini Date objesine dönüştür
    const tarih = new Date(date);
  

    const day = tarih.getDate().toString().padStart(2, '0');
    const month = (tarih.getMonth() + 1).toString().padStart(2, '0'); // Ay 0'dan başlar
    const year = tarih.getFullYear();
  

    const hour = tarih.getHours().toString().padStart(2, '0');
    const minutes = tarih.getMinutes().toString().padStart(2, '0');
    const second = tarih.getSeconds().toString().padStart(2, '0');
  

    return `${day}.${month}.${year} ${hour}:${minutes}:${second}`;
  }

  function handleClear() {
    setGroupName('')
    setGroupNameError('')
    setExplanation('')
  }

  return (
    <>
      <Dialog
        open={open || openEdit}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={'xs'}
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle display={'flex'} /* justifyContent={'space-between'} */ alignItems={'center'}>
          <Typography fontSize={26} fontWeight={'bold'}>
            {open && 'Create User Group'}
            {openEdit && 'Edit User Group'}
          </Typography>
          <Typography fontSize={20} marginLeft={5} fontWeight={'bold'}>
            {openEdit && `${groupDetail?.groupId}`}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            minHeight: '400px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <TextField
            id='group-name'
            label='Group Name'
            variant='outlined'
            required
            fullWidth
            margin='normal'
            value={groupName}
            onChange={handleGroupNameChange}
            inputProps={{maxLength: 36}}
            error={Boolean(groupNameError)}
            helperText={groupNameError ? groupNameError : ' '}
          />
          <TextField
            id='explanation'
            label='Explanation'
            variant='outlined'
            fullWidth
            margin='normal'
            multiline
            rows={4}
            value={explanation}
            onChange={handleExplanationChange}
            inputProps={{maxLength: 140}}
          />
          {openEdit && (
            <Box marginTop={4}>
              <Typography fontSize={14}>Last edited by</Typography>
              <Typography fontSize={14} marginTop={2}>
                {groupDetail?.modifiedName}
              </Typography>
              <Typography fontSize={14}>{formatDate(groupDetail?.modifiedDate)}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='inherit' onClick={() =>(isChange ? setDiscardWarnModal(true) : handleClose())}>
            DISCARD
          </Button>
          <Button
            variant='contained'
            disabled={(open && groupName.length < 2) || loading}
            onClick={() => (open && handleSave(), openEdit && handleEdit())}
            style={{marginRight: '30x', marginLeft: '20px'}}
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
      {
            //#region Discard WarnModal
          }
          <Dialog
            open={discardWarnModal}
            TransitionComponent={Transition}
            fullWidth
            maxWidth={'xs'}
            PaperProps={{
              style: {
                backgroundColor: '#ED6C02',
              },
            }}
            /* onClose={handleClickWarnModalClose} */
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle>
              <Typography color='white' variant='h5'>
                <WarningAmberIcon fontSize='large' /> Warning
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                <Typography color='white' variant='h6'>
                  You have unsaved changes.
                  <br />
                  Do you want to discard, Anyway?
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                style={{color: 'white'}}
              onClick={() =>  (handleClose(),setDiscardWarnModal(false))}
              >
                Yes
              </Button>
              <Button style={{color: 'white'}} onClick={() => setDiscardWarnModal(false)}>
                <CloseIcon />
              </Button>
            </DialogActions>
          </Dialog>
          {
            //#endregion
          }
    </>
  )
}

export default function Snackbar(props: any) {
    return (
      <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} maxSnack={3}>
        <AddEditGroupModal {...props} />
      </SnackbarProvider>
    )
  }