import axios from "axios"
import { useQuery } from "react-query"
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Chart from 'react-apexcharts'
type AppProps = {
    series: number[];
    labels: string[];
}

const NrOfUsers: React.FC = ({ }) => {
  const options = {

    chart: {
      foreColor: 'text-dark',

      height: 350,
      toolbar: {
        show: false,},
      zoom: {
        enabled: true
      }
      
    }
  };

  const series = [
    {
      name: "Active Members",
      data: [31, 40, 28, 51, 42, 109, 100]
    },
    {
      name: "Registered Members",
      data: [11, 32, 45, 32, 34, 52, 41]
    }
  ];

  return (
      <ReactApexChart
        type="line"
        options={options}
        series={series}
        height={350}
      />
  );
};
export {NrOfUsers};


