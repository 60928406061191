import React, { CSSProperties, FC, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { useIntl } from 'react-intl'
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  DialogContentText,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import Grid from '@mui/material/Grid'
import AddGroupModal from './companents/AddGroupModal'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { SnackbarProvider, useSnackbar } from 'notistack'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { TransitionProps } from '@mui/material/transitions'
import SelectImageUser from './companents/SelectImageUser'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useDispatch } from 'react-redux'
import Creators from '../../ReduxNew/reducers/LoginRedux'

type Props = {
  setfirst: any
  first: any
  openAddUserModal?: any
  setOpenAddUserModal?: any
  selectedUserData?: any
  openEditUserModal?: any
  setOpenEditUserModal?: any
  userRole: any
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const AddUser: FC<Props> = ({
  setfirst,
  first,
  selectedUserData,
  openAddUserModal,
  setOpenAddUserModal,
  openEditUserModal,
  setOpenEditUserModal,
  userRole,
}) => {
  const intl = useIntl()
  const LoginState = useSelector((state: any) => state.Login)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  /*  openEditUserModal */
  const [editModalChanges, setEditModalChanges] = useState<any>(false)
  const [selectImageModal, setSelectImageModal] = useState<any>(false)
  const [refresh, setRefresh] = useState<any>(false)
  const [name, setName] = useState<any>('')
  const [errorName, setErrorName] = useState('')
  const [notificationCheck, setNotificationCheck] = useState(false)
  const [surname, setSurname] = useState('')
  const [errorSurname, setErrorSurname] = useState('')
  const [phoneNumber, setPhoneNumber] = useState<any>('')
  const [email, setEmail] = React.useState('')
  const [errorEmail, setErrorEmail] = useState<any>('')
  const [selectedRoles, setSelectedRoles] = useState<any>([])
  const [selectedGroups, setSelectedGroups] = useState<any>([])
  const [roles, setRoles] = useState<any>([])
  const [groupList, setGroupList] = useState<any>([])

  let roleId = [0, 1, 3]
  let isSystemAdminRole = [0]
  let roleRoleChange = [0, 1]
  let roleIdPassword = [0, 1, 3]
  let roleCheck = [0, 1, 2, 3, 4, 5, 6]
  let roleIsValid = userRole?.some((role: any) => roleId?.includes(role.roleId))
  let isSystemAdmin = selectedRoles?.some((role: any) => isSystemAdminRole?.includes(role.roleId))
  let roleIsValidPassword = userRole?.some((role: any) => roleIdPassword?.includes(role.roleId))
  let roleChangeIsValid = userRole?.some((role: any) => roleRoleChange?.includes(role.roleId))
  const roleIdsToCheck: [] = userRole?.map((role: any) => role.roleId)
  const userRoleIds: [] =
    selectedUserData?.length > 0 ? selectedUserData[0]?.role.map((user: any) => user.roleId) : []
  const isRoleIdLessThanSelectedUserIds = roleIdsToCheck?.some((roleId: any) =>
    userRoleIds.every((userRoleId: any) => roleId < userRoleId)
  )


  // warn modal start

  const [openWarnModalRole, setOpenWarnModalRole] = useState<any>(false)
  const [openWarnModal, setOpenWarnModal] = useState<any>(false)
  const [warnModalTitle, setWarnModalTitle] = useState<any>('')
  const [warnModalMessage, setWarnModalMessage] = useState<any>('')
  const [warnModalType, setWarnModalType] = useState<any>('')
  const [warnModalIcon, setWarnModalIcon] = useState<any>('')
  const [defaultPassword, setDefaultPassword] = useState<any>('')
  const [passwordLoading, setPasswordLoading] = useState<any>(false)

  //image  start
  const [imageCoordinates, setImageCoordinates] = useState<any>({})
  //image  end

  useEffect(() => {
    openWarnModal && setWarnModalMessageByType(warnModalType)
    getDefaultPassword()
  }, [warnModalType])

  function setWarnModalMessageByType(warnModalType: any) {
    let message: any = ''
    let title: any = ''
    let icon: any = ''
    if (warnModalType === 'resetPassword') {
      message = (
        <>
          <Typography color='white'>
            This operation will RESET the user's password to
            <Typography fontWeight='bold'>{defaultPassword}</Typography> Continue?
          </Typography>
        </>
      )
      title = 'Info'
      icon = <ErrorOutlineOutlinedIcon fontSize='large' />
    } else if (warnModalType === 'WARNING') {
      message = (
        <>
          <Typography color='white'>
            You have unsaved changes. Do you want to discard, anyway?
          </Typography>
        </>
      )
      title = 'WARNING'
      icon = <WarningAmberOutlinedIcon fontSize='large' />
    }
    setWarnModalTitle(title)
    setWarnModalMessage(message)
    setWarnModalIcon(icon)
    setWarnModalType(warnModalType)
  }

  function handleClickWarnModalClose() {
    setOpenWarnModal(false)
  }
  // warn modal end
  const handleClose = () => {
    clearState()
    openAddUserModal && setOpenAddUserModal(false)
    openEditUserModal && setOpenEditUserModal(false)
  }

  const [employeeId, setEmployeeId] = useState<any>('')
  const [errorEmployeeId, setErrorEmployeeId] = useState<any>('')

  //add group
  const [openGroupModal, setOpenGroupModal] = useState<any>(false)
  //add group end

  //silinecek
  const [errormessage, seterrormessage] = useState<any>('')

  function getGroupList() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/getGroupListNoPagination`, {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setGroupList(response.data)
      })
  }

  function getRoles() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/getRoleListForUserId`, {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      })
      .then(function (response) {
        //handle success
        setRoles(response.data)
      })
  }
  async function getDefaultPassword() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/user/getDefaultPassword`, {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      })
      .then(function (response) {
        //handle success
        setDefaultPassword(response.data)
      })
  }
  //notification check
  useEffect(() => {
    notificationCheck === false && setSelectedRoles([])
  }, [notificationCheck])

  function clearState() {
    setName('')
    setErrorName('')
    setSurname('')
    setErrorSurname('')
    setEmail('')
    setErrorEmail('')
    setPhoneNumber('')
    setSelectedGroups([])
    setSelectedRoles([])
    setEmployeeId('')
    seterrormessage('')
    setNotificationCheck(false)
    setEditModalChanges(false)
  }

  useEffect(() => {
    if (openEditUserModal && selectedUserData?.length > 0) {
      setName(`${selectedUserData[0].name}`)
      setSurname(selectedUserData[0].surname)
      setEmail(selectedUserData[0].email)
      setEmailFirst(selectedUserData[0].email)
      setPhoneNumber(selectedUserData[0].phone)
      setSelectedGroups(selectedUserData[0].group)
      setSelectedRoles(selectedUserData[0].role)
      selectedUserData[0].InternalEmployeeId && setEmployeeId(selectedUserData[0].InternalEmployeeId)
      setFirstEmployeeId(selectedUserData[0].InternalEmployeeId)
      setNotificationCheck(selectedUserData[0].emailNotification)
      setMediaCard({ mediaUrl: selectedUserData[0].avatarPath, file: '' })
    }
  }, [openEditUserModal])

  useEffect(() => {
    if (openAddUserModal || openEditUserModal ) {
      roleChangeIsValid && getRoles()
    }
  }, [openAddUserModal, openEditUserModal])

  const [mediaCard, setMediaCard] = useState<any>({
    mediaUrl: '',
    file: null,
  })

  async function blobToBase64(blob: any) {
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader()
      reader.onload = () => {
        const base64String: any = reader.result.split(',')[1]
        resolve(base64String)
      }
      reader.onerror = (error: any) => {
        reject(error)
      }
      reader.readAsDataURL(blob)
    })
  }

  const changeFile = async (value: any, url: any) => {
    const newCard = { ...mediaCard }
    /* newCard.file = value */
    const base64String = await blobToBase64(value)
    newCard.mediaUrl = url
    // Base64 veriyi yeni kartınıza ekleyin

    newCard.file = base64String
    setMediaCard(newCard)
  }

  const changeMediaUrl = (value: string) => {
    /*  const newCard = {...mediaCard}
    newCard.mediaUrl = value
    setMediaCard(newCard) */
  }

  // Grouplist Add refresh
  useEffect(() => {
    if (openAddUserModal || openEditUserModal) {
      getGroupList()
    }
  }, [openAddUserModal, openEditUserModal, refresh])
  const [emailFirst, setEmailFirst] = useState('')
  const [emailLoading, setEmailLoading] = useState(false)
  const sendRequestAfterDelay = () => {
    if (email.length > 0) {
      checkValidEmail(email)
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(sendRequestAfterDelay, 1000); // 1.5 saniye gecikme
    return () => clearTimeout(timeoutId); // Her yeni email geldiğinde önceki timeout'u iptal et
  }, [email]);

  const checkValidEmail = (email: any) => {
    setEmailLoading(false)
    seterrormessage('')
    if (emailFirst !== email &&
      email.length > 0 &&
      validateEmail(email)) {
      emailFirst !== email && setEmailLoading(true)
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/user/checkValidEmail`,
          // '{\n  "email": "user@example.com",\n  "customerId": 0\n}',
          {
            email: email,
          },
          {
            headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
          }
        )
        .then(function (response) {
          //handle success
          setEmailLoading(false)
          if (response.data === false) {
            setErrorEmail(`This email is already in use`)
          } else {
            seterrormessage('')
          }

        })
        .catch((error) => {
          setEmailLoading(false)
        })
    }


  }


  const [employeeLoading, setEmployeeLoading] = useState(false)
  const [firstEmployeeId, setFirstEmployeeId] = useState(false)
  const checkValidEmployeeId = (e: any) => {

    if (firstEmployeeId !== e.target.value) {
      setErrorEmployeeId("")
      setEmployeeLoading(true)
    }
    firstEmployeeId !== e.target.value &&
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/user/checkValidEmployeeId`,
          {
            employeeId: e.target.value,
          },
          {
            headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
          }
        )
        .then(function (response) {
          //handle success
          setEmployeeLoading(false)
          if (response.data === false) {
            setErrorEmployeeId('Previously saved, select a different one')
          } else {
            seterrormessage('')
          }
        })
        .catch((error) => {
          /* console.log(error) */
          setErrorEmployeeId('Previously saved, select a different one')
          setEmployeeLoading(false)
        })
  }
  const [timerEmployee, setTimerEmployee] = useState(null);

  /*   const handleChangeEmploye = (e) => {
      
      setEmployeeId(e.target.value);
      setEditModalChanges(true);
  
      // Eğer önceki zamanlayıcı varsa iptal et
      if (timerEmployee) {
        clearTimeout(timerEmployee);
      }
  
      // Yeni bir zamanlayıcı ayarla
      const newTimer = setTimeout(() => {
        console.log("timer çalıştı")
         checkValidEmployeeId(e.target.value);
      }, 2000); // Kullanıcı yazmayı bıraktıktan 2 saniye sonra çalışacak
  
      setTimerEmployee(newTimer);
    }; */

  useEffect(() => {
    if (employeeId.length < 1) {
      setErrorEmployeeId("")
    }
  }, [employeeId])

  const [saveLoading, setSaveLoading] = useState<any>(false)

  function getuserWidthJwt() {
    dispatch(Creators.getUserReduxRequest())
  }

  async function handleSave() {
    let groupID = selectedGroups.map((li: any) => li.groupId)
    let roleID = selectedRoles.map((li: any) => li.roleId)
    setSaveLoading(true)
    if (true) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/user/createUser`,
          {
            name: name,
            surname: surname,
            email: email,
            phone: phoneNumber,
            InternalEmployeeId: employeeId,
            emailNotification: notificationCheck,
            groupId: groupID,
            roleId: roleID,
            file: mediaCard.mediaUrl == '/media/stock/image.png' ? null : mediaCard.file,
          },
          {
            headers: {
              Authorization: `Bearer ${LoginState.loginData.access_token}`,
            },
          }
        )
        .then((response) => {
          setSaveLoading(false)
          enqueueSnackbar(`Successfully saved`, { variant: 'success' })
          setTimeout(() => {
            handleClose()
            setfirst(!first)
            getuserWidthJwt()
          }, 1000)
        })
        .catch((err) => {
          setSaveLoading(false)
          err.response.data?.detail.errorMessage
            ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
            : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              { variant: 'error' }
            )
        })
    }
  }

  async function handleUpdate() {
    let groupID = selectedGroups.map((li: any) => li.groupId)
    let roleID = selectedRoles.map((li: any) => li.roleId)
    setSaveLoading(true)
    if (true) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/user/updateUser`,
          {
            name: name,
            surname: surname,
            email: email,
            id: selectedUserData[0].userId,
            phone: phoneNumber,
            InternalEmployeeId: employeeId,
            emailNotification: notificationCheck,
            groupId: groupID,
            roleId: roleID,
            file: mediaCard.file ? mediaCard.file : null,
          },
          {
            headers: {
              Authorization: `Bearer ${LoginState.loginData.access_token}`,
            },
          }
        )
        .then((response) => {
          setSaveLoading(false)
          enqueueSnackbar(`Successfully saved`, { variant: 'success' })
          setTimeout(() => {
            handleClose()
            setfirst(!first)
            navigate(`/manage/users`)
          }, 1000)
        })
        .catch((err) => {
          setSaveLoading(false)
          err.response.data?.detail.errorMessage
            ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
            : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              { variant: 'error' }
            )
        })
    }
  }

  const customerProfile = useSelector((state: any) => state.Login.customerProfile)

  let user = customerProfile
  let myRoleIsTrainer = user?.role.some(role => role.roleId === 3);
  let userRoleCheck = selectedRoles?.some(role => roleCheck?.includes(role.roleId));
  let myRoleMax = user?.role
    .map(role => role.roleId) // Önce tüm roleId'leri alalım
    .filter(roleId => roleIdPassword.includes(roleId)) // roleIdPassword içinde olanları filtreleyelim
    .reduce((min, roleId) => roleId < min ? roleId : min, Infinity)
  let userRoleMax = selectedRoles
    .map(role => role.roleId) // Önce tüm roleId'leri alalım
    .filter(roleId => roleIdPassword.includes(roleId)) // roleIdPassword içinde olanları filtreleyelim
    .reduce((min, roleId) => roleId < min ? roleId : min, Infinity)


  const mode = useThemeMode()
  const customControlStyles: CSSProperties = {
    textDecorationColor: mode.mode == 'dark' ? 'black' : '',
    backgroundColor: mode.mode == 'dark' ? '#1b1b29' : '',
    color: mode.mode == 'dark' ? 'black' : '',
    width: '246px',
  }



  async function resetPassword() {
    setPasswordLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/user/resetUserPassword/${selectedUserData[0].userId}`,
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        setOpenWarnModal(false)
        enqueueSnackbar(`Successfully saved`, { variant: 'success' })
        setTimeout(() => {
          setPasswordLoading(false)
        }, 1000)
      })
      .catch((err) => {
        setPasswordLoading(false)
        err.response.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  const handleNameChange = (event: any) => {
    setEditModalChanges(true);
    const newName = event.target.value
      .split(' ')
      .map((namePart) =>
        namePart.charAt(0).toUpperCase() + namePart.slice(1).toLowerCase()
      )
      .join(' ');
    setName(newName);
    newName.length < 2 ? setErrorName('Required field') : setErrorName('')
  }

  const handleSurnameChange = (event: any) => {
    setEditModalChanges(true)
    const newSurname = event.target.value
      .split(' ') // İsimleri boşluk karakterine göre ayır
      .map((namePart) =>
        namePart.charAt(0).toUpperCase() + namePart.slice(1).toLowerCase()
      )
      .join(' ');
    setSurname(newSurname)
    newSurname.length < 2 ? setErrorSurname('Required field') : setErrorSurname('')
  }

  const handleEmailChange = (event: any) => {
    setEditModalChanges(true)
    const newEmail = event.target.value.trim().toLowerCase()
    setEmail(newEmail)

    if ((newEmail && !validateEmail(newEmail)) || newEmail.length < 2) {
      setErrorEmail('Required field')
    } else {
      setErrorEmail('')
    }
  }
  const validateEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleChangeRoles = (event: any, value: any) => {
    setEditModalChanges(true)
    // Eğer seçilen roller arasında "MasterAdmin" varsa diğer rolleri sil
    if (value.some((role: any) => role.value === '1')) {
      setSelectedRoles(value.filter((role: any) => role.value === '1'))
    } else {
      setSelectedRoles(value)
      openEditUserModal && setOpenWarnModalRole(true)
    }
  }
  let ratio = 155 / imageCoordinates.height


  return (
    <>
      <Dialog
        open={openAddUserModal || openEditUserModal}
        TransitionComponent={Transition}
        keepMounted
        maxWidth='sm'
        fullWidth
        style={{ zIndex: 5 }}
        /*  onClose={handleClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
          {openEditUserModal ? `Edit User` : 'Add User'}
          {openEditUserModal && <span>{selectedUserData[0]?.userId}</span>}
          <IconButton
            onClick={() =>
              editModalChanges
                ? (setWarnModalMessageByType('WARNING'), setOpenWarnModal(true))
                : handleClose()
            }
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider variant='middle' sx={{ borderTop: '2px solid' }} />
        <DialogContent>
          <Grid container spacing={2} rowGap={2} marginTop={2} xs={12}>
            <Grid xs={12} marginLeft={3}>
              <Box display={'flex'}>
                <Grid xs={6}>
                  <TextField
                    id='outlined-basic'
                    label='Name*'
                    variant='outlined'
                    fullWidth
                    value={name}
                    error={Boolean(errorName)}
                    onChange={handleNameChange}
                    helperText={errorName}
                  />
                  <TextField
                    id='outlined-basic'
                    label='Surname*'
                    variant='outlined'
                    fullWidth
                    value={surname}
                    error={Boolean(errorSurname)}
                    onChange={handleSurnameChange}
                    style={{ marginTop: 20 }}
                    helperText={errorSurname}
                  />
                </Grid>

                <Grid xs={5} textAlign={'center'}>
                  <Box display='flex' justifyContent='end' width={'100%'} alignItems='center'>
                    {/*     <SelectContentUser
                      customerId={10}
                      mediaType={mediaType}
                      mediaUrl={mediaUrl}
                      setEditModalChanges={setEditModalChanges}
                      changeMediaUrl={setMediaUrl}
                      changeMediaType={setMediaType}
                      changeFile={setfile}
                    /> */}
                    <SelectImageUser
                      setSelectImageModal={setSelectImageModal}
                      selectImageModal={selectImageModal}
                      mediaUrl={mediaCard.mediaUrl}
                      changeMediaUrl={changeMediaUrl}
                      changeFile={changeFile}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid xs={12} marginLeft={3}>
              <Box display={'flex'}>
                <Grid xs={6}>
                  <TextField
                    id='outlined-basic'
                    label='Email*'
                    variant='outlined'
                    fullWidth
                    /*  onBlur={() => {
                       checkValidEmail(email)
                     }} */
                    value={email}
                    onKeyUp={() => {
                      if (errorEmail === "") {
                        setNotificationCheck(true)
                      } else {
                        setNotificationCheck(false)
                      }
                      if (email.length < 1) {
                        setNotificationCheck(false)
                      }
                    }}
                    onChange={handleEmailChange}
                    error={Boolean(errorEmail)}
                    helperText={errorEmail}
                    InputProps={{
                      endAdornment: (
                        emailLoading && (
                          <InputAdornment position='end'>
                            <CircularProgress size={20} />
                          </InputAdornment>
                        )
                      ),
                    }}
                  />
                </Grid>
                <Grid xs={6} height={"51.69px"}>
                  {
                    <Stack direction='column' justifyItems={'center'} alignItems='center'>
                      <Typography>Use for notifications {notificationCheck}</Typography>
                      <Checkbox
                        checked={notificationCheck}
                        disabled={errorEmail || email.length < 1}
                        onChange={() => (
                          setNotificationCheck(!notificationCheck), setEditModalChanges(true)
                        )}
                      />
                    </Stack>
                  }

                </Grid>
              </Box>
            </Grid>
            <Grid xs={12} marginLeft={3}>
              <Box display={'flex'}>
                <Grid xs={6}>
                  <Autocomplete
                    multiple
                    fullWidth
                    limitTags={1}
                    id='multiple-limit-tags'
                    value={selectedGroups}
                    onChange={(event: any, value: any) => (
                      setSelectedGroups(value), setEditModalChanges(true)
                    )}
                    options={groupList}
                    getOptionLabel={(option: any) => option.groupName}
                    /*     defaultValue={[top100Films[1], top100Films[2], top100Films[3]]} */
                    renderInput={(params: any) => (
                      <TextField {...params} label='Group' placeholder='Group' />
                    )}
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid xs={12} marginLeft={3}>
              <Box display={'flex'}>
                <Grid xs={6}>
                  <Autocomplete
                    multiple
                    fullWidth
                    limitTags={1}
                    id='multiple-limit-tags'
                    value={selectedRoles}
                    disabled={!notificationCheck || !roleChangeIsValid || isSystemAdmin}
                    onChange={handleChangeRoles}
                    options={roles}
                    getOptionLabel={(option: any) => option.roleName}
                    renderInput={(params: any) => (
                      <TextField {...params} label='Role' placeholder='Role' />
                    )}
                  />
                </Grid>
                <Grid xs={6}>
                  <Stack direction='column' spacing={1} justifyItems={'center'} alignItems='center'>
                    <Typography>Not in the list?</Typography>
                    {roleIsValid && (
                      <Button
                        style={{ color: '#2884EF', fontWeight: 'bold', textTransform: 'none' }}
                        variant='text'
                        onClick={() => setOpenGroupModal(true)}
                      >
                        Create new group
                      </Button>
                    )}
                  </Stack>
                </Grid>
                {/*   {
                   selectedRoles.find((role: any) => role.title === 'MasterAdmin') &&
                   <IconButton
                   aria-label='delete'
                   color='primary'
                   onClick={() => {
                     setSelectedRoles([])
                   }}
                 >
                   <CachedIcon />
                 </IconButton>
                } */}
              </Box>
            </Grid>
            <Grid container xs={12} marginLeft={3}>
              <Grid xs={6}>
                <Box>
                  <PhoneInput
                    country={'tr'}
                    value={phoneNumber}
                    inputStyle={{
                      height: '51.69px',
                      width: '261.6px',
                    }}
                    onChange={(phone: any) => (setPhoneNumber(phone), setEditModalChanges(true))}
                    isValid={(value: any, country: any) => {
                      if (value.match(/12345/)) {
                        return 'Invalid value: ' + value + ', ' + country.name
                      } else if (value.match(/1234/)) {
                        return false
                      } else {
                        return true
                      }
                    }}
                    countryCodeEditable={false}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      autoFocus: true,
                    }}
                  />
                </Box>
              </Grid>
              <Grid xs={6} display={'flex'} justifyContent={'center'}>
                {openEditUserModal && roleIsValidPassword && myRoleMax <= userRoleMax && (
                  <Button
                    style={{ color: userRoleCheck && myRoleIsTrainer ? "" : '#2884EF', fontWeight: 'bold', textTransform: 'none' }}
                    disabled={userRoleCheck && myRoleIsTrainer}
                    variant='text'
                    onClick={() => {
                      setWarnModalType('resetPassword')
                      setOpenWarnModal(true)
                    }}
                  >
                    Reset User Password
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid xs={12} marginLeft={3}>
              <Box display={'flex'}>
                <Grid xs={6}>
                  <TextField
                    id='outlined-basic'
                    label='Employee ID'
                    onChange={(e: any) => (setEmployeeId(e.target.value))}
                    value={employeeId}
                    variant='outlined'
                    fullWidth
                    onBlur={(e: any) => (employeeId.length > 0 && checkValidEmployeeId(e), setEditModalChanges(true))}
                    error={Boolean(errorEmployeeId)}
                    helperText={errorEmployeeId}
                    InputProps={{
                      endAdornment: (
                        employeeLoading && (
                          <InputAdornment position='end'>
                            <CircularProgress size={20} />
                          </InputAdornment>
                        )
                      ),
                    }}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider variant='middle' sx={{ borderTop: '2px solid' }} />
        <DialogActions>
          <Button
            variant='contained'
            onClick={() =>
              editModalChanges
                ? (setWarnModalMessageByType('WARNING'), setOpenWarnModal(true))
                : handleClose()
            }
            color='inherit'
          >
            Discard
          </Button>
          {openEditUserModal ? (
            <Button
              variant='contained'
              style={{ marginRight: '40px' }}
              disabled={
                Boolean(errorName || errorSurname || errorEmail || errorEmployeeId) || emailLoading ||
                !name ||
                !surname ||
                !email ||
                saveLoading
              }
              onClick={() => {
                handleUpdate()
              }}
            >
              Save
            </Button>
          ) : (
            <Button
              variant='contained'
              style={{ marginRight: '40px' }}
              disabled={
                Boolean(errorName || errorSurname || errorEmail) || emailLoading ||
                !name ||
                !surname ||
                !email ||
                saveLoading
              }
              onClick={() => {
                handleSave()
              }}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <AddGroupModal
        openGroupModal={openGroupModal}
        setRefresh={setRefresh}
        refresh={refresh}
        setOpenGroupModal={setOpenGroupModal}
      />
      <Dialog
        open={openWarnModal}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: warnModalType === 'resetPassword' ? '#0288D1' : '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            {warnModalIcon} {warnModalTitle}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {warnModalMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickWarnModalClose} style={{ color: 'white' }}>
            No
          </Button>
          {passwordLoading ? (
            <CircularProgress color='primary' size={20} />
          ) : (
            <Button
              disabled={warnModalType === 'resetPassword' ? passwordLoading : null}
              onClick={() => {
                if (warnModalType === 'resetPassword') {
                  resetPassword()
                }
                if (warnModalType === 'WARNING') {
                  handleClickWarnModalClose()
                  handleClose()
                }
              }}
              style={{ color: 'white' }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openWarnModalRole}
        TransitionComponent={Transition}
        fullWidth
        maxWidth={'xs'}
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Typography color='white' variant='h6'>
              This operation will CHANGE the user’s role.<br /> Continue?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: 'white' }}
            onClick={() => {
              setOpenWarnModalRole(false)
            }}
          >
            Yes
          </Button>
          <Button
            style={{ color: 'white' }}
            onClick={() => (setOpenWarnModalRole(false), setSelectedRoles(selectedUserData[0].role))}
          >
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default function Snackbar(props: any) {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} maxSnack={3}>
      <AddUser {...props} />
    </SnackbarProvider>
  )
}
