import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {NewPassword} from './NewPassword'
import axios from 'axios'
import {stepButtonClasses} from '@mui/material'
import {Modal} from 'react-bootstrap'

export function ForgotPassword() {
  const location = useLocation()
  const [display, setdisplay] = useState(true)

  useEffect(() => {
    if (location.state.customerId !== 'test' || null || 0) {
      setdisplay(false)
      setcustomer(location.state.customerId)
    }
    if (location.state.customerId === null) {
      setcustomer('0')
      setdisplay(false)
    }
    if (location.state.customerId === 'test') {
      setdisplay(true)
    }
  }, [location])
  const intl = useIntl()

  const [products, setProducts] = useState([])

  useEffect(() => {
    const ax = axios.create({headers: {Accept: 'application/json'}})
    function test() {
      const res = ax
        .get(`${process.env.REACT_APP_API_URL}/customer/getAllLoginActiveCustomers`)
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setProducts(response.data)
        })
    }
    test()
  }, [])

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState(undefined)
  const [customerId, setcustomer] = useState()

  const [lenght, setLenght] = useState()
  const [first, setfirst] = useState()
  const handleKeyPress = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = products.filter(function (products) {
        return products.title.substring(0, value?.length).toLowerCase() === value
      })
    setfirst(matches[0].title?.length)
    if (first > lenght && lenght > 1) {
      event.target.value = matches[0].title
      setcustomer(matches[0].customerId)
    }
  }
  const [email, setEmail] = useState()
  const [error, setError] = useState(undefined)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const ax = axios.create({headers: {Accept: 'application/json'}})
  function test() {
    const res = ax
      .post(`${process.env.REACT_APP_API_URL}/user/forgotPasswordEmail`, {
        email: email,
        customerId: customerId,
        loginEnv: 1,
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
      })
    // .catch(function (error) {
    //   setError(true)
    // })
  }
  function resend() {
    const res = ax
      .post(`${process.env.REACT_APP_API_URL}/user/forgotPasswordEmail`, {
        email: email,
        customerId: customerId,
        loginEnv: 1,
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        handleShow()
        setTimeout(() => {
          handleClose()
        }, 3000)
      })
    // .catch(function (error) {
    //   setError(true)
    // })
  }

  const [customerName, setcustomerNme] = useState(undefined)

  var edit = true
  var value
  const checkMatch = (event) => {
    value = event.target.value.toLowerCase()
    var matches = products.filter(function (products) {
      return products?.title?.substring(0, value?.length).toLowerCase() === value
    })
    if (matches?.length > 1) {
      edit = true
    }
    var focusout = matches?.filter(function (items) {
      return items?.title.toLowerCase().length === value.length
    })

    if (matches?.length === 1) {
      if (edit === true) {
        event.target.value = matches[0].title
        edit = false
      }
      setcustomer(matches[0].customerId)
      setcustomerNme(matches[0].title)
    }
    if (matches?.length > 1 && focusout?.length === 1) {
      setcustomer(focusout[0].customerId)
      setcustomerNme(focusout[0].title)
    }
    if (matches?.length > 1 && focusout?.length !== 1) {
      setcustomer('')
      setcustomerNme('')
    }
    if (matches?.length === 0) {
      setcustomer('')
      setcustomerNme('')
    }
  }
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email)
  }
  const [button, setButton] = useState(0)

  const HandleMail = (event) => {
    if (!isValidEmail(event.target.value)) {
      setButton(0)
    } else {
      setButton(1)
    }
  }
  const [customerNamee, setcustomerName] = useState('')
  useEffect(() => {
    if (location.state.customerName.length > 0) {
      setcustomerName(location.state.customerName)
    } else {
      setcustomerName('login')
    }
  }, [location])
  const [isOutBlur, setisOutBlur] = useState(true)
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick()
      test()
    }
  }
  return (
    <>
      <div className={error ? 'd-none' : ' '}>
        <div className='text-center p-10 h1  text-muted'>Forgot Password?</div>
        <div id='deneme'>
          <div className='d-flex justify-content-between mt-20'>
            <button className='col-3 btn btn-dark border border-secondary' disabled></button>
            <button className='col-3 btn btn-secondary' disabled></button>
            <button className='col-3 btn btn-secondary' disabled></button>
          </div>

          <div
            className='mt-20 isactive form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
          >
            <div className='text-center mb-10 mt-20'>
              {/* begin::Title */}
              {/* end::Title */}

              {/* begin::Link */}
              {/* end::Link */}
            </div>

            {/* begin::Title */}
            {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  Sorry, looks like there are some errors detected, please try again.
                </div>
              </div>
            )}

            {hasErrors === false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>Sent password reset. Please check your email</div>
              </div>
            )}
            {/* end::Title */}

            {/* begin::Form group */}
            <div id='buttons' className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
              <input
                type='email'
                placeholder=''
                autoComplete='off'
                className='form-control form-control-lg form-control-solid'
                onChange={(e) => {
                  setEmail(e.target.value)
                  HandleMail(e)
                  setisOutBlur(true)
                }}
                onBlur={(e) => setisOutBlur(false)}
                onKeyDown={handleKeyDown}
              />
              <span
                className={
                  email?.length > 0 && button !== 1 && isOutBlur === false
                    ? 'text-danger'
                    : 'd-none'
                }
              >
                Please Enter a Valid Email
              </span>
            </div>
            <div className={display == false ? 'd-none' : 'fv-row mb-10'}>
              <label className='form-label fs-6 fw-bolder text-dark'>Company</label>
              <input
                placeholder='Company'
                name='Company'
                className='form-control form-control-lg form-control-solid'
                onChange={(e) => checkMatch(e)}
              />
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='mt-10 d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className={
                  button == 1
                    ? 'btn btn-lg btn-primary fw-bolder me-4'
                    : 'btn btn-lg btn-primary fw-bolder me-4 disabled'
                }
                onClick={() => {
                  handleClick()
                  test()
                }}
              >
                <span className='indicator-label'>Send</span>
                {loading && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <Link to={`/${customerNamee}`}>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-primary fw-bolder'
                >
                  Back
                </button>
              </Link>{' '}
            </div>
            {/* end::Form group */}
          </div>
        </div>
        <div className='d-none text-center' id='deneme2'>
          <div className='d-flex justify-content-between'>
            <button className='col-3 btn btn-secondary' disabled></button>
            <button className='col-3 btn btn-dark border border-secondary' disabled></button>
            <button className='col-3 btn btn-secondary' disabled></button>
          </div>
          <div className='mt-20 col-12 text-center h1'>
            <span>Verify Your Email</span>
          </div>
          <div className='col-12 text-center mt-15 h2'>
            <span className='text-muted'>
              The password reset link will be sent to the email you’ve provided. It would be a good
              idea to check your junk emails.
            </span>
            <br />
            <br />
            <br />
            <br />

            <span className=' mt-20 h2 text-muted'>
              Did’t receive an email?{' '}
              <button
                type='submit'
                id='kt_password_reset_submit'
                onClick={() => {
                  handleClick()
                  resend()
                }}
                className='btn fw-bolder me-4'
              >
                <span className='indicator-label'>Resend</span>
              </button>
            </span>
          </div>
        </div>

        <div className=' text-center d-none' id='deneme4'>
          <div className='d-flex justify-content-between'>
            <button className='col-3 btn btn-secondary' disabled></button>
            <button className='col-3 btn btn-secondary' disabled></button>
            <button className='col-3 btn btn-dark border border-secondary' disabled></button>
          </div>
          <div className='col-12 mt-20 mb-20'>
            <span className='h1'>HURRAY!</span>
            <br />
            <br />
            <br />
            <br />
            <span className='h2 mt-20'>Your password changed.</span>
          </div>
          <button className='btn btn-danger'>
            {' '}
            <a className='text-white' href='/'>
              Back Home
            </a>{' '}
          </button>
        </div>
      </div>
      <div className={error ? 'text-center ' : 'd-none'}>
        <span className='h1 mt-20 text-center'>
          {' '}
          Your mail adress and company name didn't match please retry
        </span>
        <br />
        <a href='/login' className='mt-20 btn btn-primary'>
          Back Home
        </a>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter '
        centered
      >
        <Modal.Header closeButton className='bg-light-success border-0'></Modal.Header>
        <Modal.Body className='bg-light-success text-success'>
          {' '}
          <span className='svg-icon svg-icon-4hx svg-icon-success me-3'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='mh-50px'
            >
              <path
                opacity='0.3'
                d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                fill='currentColor'
              ></path>
              <path
                d='M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z'
                fill='currentColor'
              ></path>
            </svg>
          </span>
          We will send a password reset link to {email}
        </Modal.Body>
        <Modal.Footer className='bg-light-success  border-0'></Modal.Footer>
      </Modal>
    </>
  )
}
function handleClick() {
  var myElement = document.getElementById('deneme')
  //myElement.classList.add("anotherclass");
  myElement?.classList.add('d-none')
  var myElement1 = document.getElementById('deneme2')
  myElement1?.classList.remove('d-none')
}

function handleClick1() {
  var myElement = document.getElementById('deneme2')
  //myElement.classList.add("anotherclass");
  myElement?.classList.add('d-none')
  var myElement1 = document.getElementById('deneme3')
  myElement1?.classList.remove('d-none')
}
