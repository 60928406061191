import axios from 'axios'
const create = () => {
  const LoginUser = async (body) => {

    // axios
    //   .post('http://127.0.0.1:8000/auth/login', {
    //     customerId: 1,
    //     email: body.email,
    //     password: body.password,
    //   })
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signIn`, {
        customerName: body.customerName,
        email: body.email,
        password: body.password,
        grecaptcha: body.token,
        gdprAccept: body.gdprAccept,
        loginEnvironment: 1,
      }, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8',
        }
      });

      return response.data;
  
    } catch (error) {
 
      return {error: true, text: error.response.data.detail};
    }
  }

  const refreshToken = async (refreshToken) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/auth/refreshToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${refreshToken}`,
      },
      /* body: JSON.stringify({
        refreshToken: refreshToken, // Eski refresh token'ı gönderiyoruz
      }), */
    })
      .then(function (response) {
        if (response.ok) {
          return response.json()
        } else {
          return {error: false, status: response.status, text: response.statusText}
        }
      })
      .then(function (json) {
        return json
      })
      .catch((error) => {
        return {error: true, text: error}
      })
  }

   const getUserWithJWT = async(accessToken) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/getUserWithJWT`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data; // response objesinin data özelliğini döndür
    } catch (err) {
      console.error(err);
      return { error: true, text: err.message };
    }
        
    }

  return {LoginUser, refreshToken,getUserWithJWT}
}
export default {create}
