import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    LinearProgress,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Slide,
    TextField,
    Tooltip,
    Typography,
    darken,
    lighten,
    styled,
} from '@mui/material'
import {
    DataGridPro,
    GridCellParams,
    GridColumnVisibilityModel,
    GridFilterModel,
    GridRowParams,
    GridSortModel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { TransitionProps } from '@mui/material/transitions'
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReactApexChart from 'react-apexcharts'
import GaugeChart from 'react-gauge-chart'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LinkIcon from '@mui/icons-material/Link';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { useNavigate } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    '& .super-app-theme--Open': {
        backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode
                ),
            },
        },
    },
    '& .super-app-theme--secondary': {
        backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.secondary.light,
                theme.palette.mode
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.secondary.light,
                    theme.palette.mode
                ),
            },
        },
    },
}))
const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />
})

function ShowPaper() {
    const LoginState = useSelector((state: any) => state.Login)
    const navigate = useNavigate()
    const getIdFromUrl = () => {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        return params.get('id')
    }
    const journeyActivityId = getIdFromUrl()


    const getNameFromUrl = () => {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        return params.get('name')
    }
    const getTokenFromUrl = () => {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        return params.get('token')
    }
    const testName = getNameFromUrl()
    const token = getTokenFromUrl()

    const [invidualScore, setInvidualScore] = useState<any>(null)
    

    useEffect(() => {
        if(token){
            fetchQuizPaper(token)
        }
    }, [token])


    const [quizData, setQuizData] = useState<any>([])
    const [userName, setUserName] = useState<any>(null)
    const [quizLoading, setQuizLoading] = useState(false)
    const [quizAnswerOpen, setQuizAnswerOpen] = useState(false)
   
    const fetchQuizPaper = async (number?: string) => {
        setQuizLoading(true)
        await axios
            .post(
                `${process.env.REACT_APP_API_URL}/report/testResult/showPaper?token=${token}`, {},
                {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                setUserName(response.data.nameSurname)
                setQuizData(response.data.questionList)
                setQuizLoading(false)
            })
            .catch((err) => {
                setQuizLoading(false)
            })
    }




    return (
        <>
            <div
                style={{
                    display: 'flex',
                    height: 40,
                    width: '80%',
                    marginBottom: '5px',
                    marginTop: '-30px',
                    cursor: 'default',
                    pointerEvents: 'none',
                    userSelect: 'none',
                }}
            >
                <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
                    Reports - Journey Detail By Activity - {testName} - Test Results
                </Typography>
            </div>

            <Grid container spacing={2}>
                {
                    //#region Mother Grid
                    <Grid container item xs={12} md={12} lg={12} xl={12} spacing={2}    /* minWidth={640} */ >

                        {
                            //#region question list
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <Grid xs={12} width={"100%"} height={800} marginBottom={5} >
                                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                                        <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                            sx={{
                                                background: "#EFF7FF",
                                                borderRadius: "10px 10px 0px 0px"
                                            }}
                                        >
                                            <Typography marginLeft={3} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Question List</Typography>

                                            <div style={{ display: "flex", alignItems: "center", width: 100, justifyContent: "space-between" }}>
                                                <div>
                                                    <Tooltip arrow title={"The 5 most successful people in terms of correct answer rate in the selected date range."} placement='top' >
                                                        <IconButton size='small'>
                                                            <InfoOutlinedIcon fontSize='medium' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </Grid>
                                        {
                                            //#region content
                                            <Grid container xs={12} height={"100%"} display={"flex"} paddingLeft={1} paddingRight={1} rowGap={1} alignItems={"start"} justifyContent={"space-between"}
                                                sx={{ overflowX: "auto" }}
                                            >
                                                <Grid container item xs={12} height={35} sx={{ position: "sticky", top: 0, background: "white", zIndex: 2 }}  >
                                                    <Grid item xs={2} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                        <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>MEDIA</Typography>
                                                    </Grid>
                                                    <Grid item xs={5} height={"100%"} display={"flex"} alignItems={"center"} >
                                                        <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>QUESTION</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} height={"100%"} display={"flex"} alignItems={"center"}>
                                                        <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>CATEGORY</Typography>
                                                    </Grid>
                                                    <Grid item xs={1} height={"100%"} display={"flex"} alignItems={"center"}>
                                                        <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>ID</Typography>
                                                    </Grid>
                                                    <Grid item xs={1} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                        <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>TEAM SCORE</Typography>
                                                    </Grid>
                                                    <Grid item xs={1} height={"100%"} display={"flex"} alignItems={"center"} textAlign={"center"}>
                                                        {
                                                         <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>{userName !== null && userName}</Typography> 
                                                        }
                                                    </Grid>
                                                    {quizLoading &&
                                                        <Grid item xs={12} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                            <CircularProgress size={30} color='primary' />
                                                        </Grid>
                                                    }
                                                </Grid>
                                                {
                                                    quizData.map((item: any, index: number) => {
                                                        return <>
                                                            <Grid item xs={12} height={"auto"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                                <Grid item xs={2} height={"100%"} display={"flex"} alignItems={"center"}>
                                                                    <div style={{ width: 40 }}>
                                                                        <Typography fontSize={20} fontWeight={"bold"} color={"#8E8E8E"} >{index + 1}</Typography>
                                                                    </div>

                                                                    <Avatar
                                                                        src={item.mediaUrl}
                                                                        variant='rounded'
                                                                        sx={{
                                                                            width: "80px",
                                                                            height: "36px",
                                                                            borderRadius: 2
                                                                        }}
                                                                        imgProps={{
                                                                            style: {
                                                                                objectFit: "contain"
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Avatar
                                                                            src={"/media/stock/image.png"}
                                                                            variant='rounded'
                                                                            sx={{
                                                                                width: "80px",
                                                                                height: "36px",
                                                                                borderRadius: 2,
                                                                                background: "none"
                                                                            }}
                                                                            imgProps={{
                                                                                style: {
                                                                                    objectFit: "contain"
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Avatar>
                                                                </Grid>
                                                                <Grid item xs={5} height={"100%"} display={"flex"} alignItems={"center"} >
                                                                    <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>{item.description}</Typography>
                                                                </Grid>
                                                                <Grid item xs={2} height={"100%"} display={"flex"} alignItems={"center"}>
                                                                    <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>{item.category}</Typography>
                                                                </Grid>
                                                                <Grid item xs={1} height={"100%"} display={"flex"} alignItems={"center"}>
                                                                    <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>{item.categoryId}</Typography>
                                                                </Grid>
                                                                <Grid item xs={1} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                                    <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>{item.teamScore}</Typography>
                                                                </Grid>

                                                                <Grid item xs={1} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                                                                    {
                                                                            item.userIsCorrect ?
                                                                                <CheckCircleIcon sx={{ color: '#4CAF50' }} /> : <CancelRoundedIcon sx={{ color: '#FF0000' }} />
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            {
                                                                userName &&
                                                                <Grid item xs={12} minHeight={"100px"} display={"flex"} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                                                                    <Grid item xs={6} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                                                                    </Grid>
                                                                    <Grid item xs={4} height={"auto"} display={"flex"}
                                                                        rowGap={1} paddingTop={1} paddingBottom={2} flexDirection={"column"} justifyContent={"start"} alignItems={"center"} textAlign={"center"}
                                                                      /*   sx={{
                                                                            overflowY: "scroll",
                                                                            '&::-webkit-scrollbar': {
                                                                                display: 'none',
                                                                            },
                                                                            '-ms-overflow-style': 'none',  
                                                                            'scrollbarWidth': 'none',
                                                                        }} */
                                                                    >
                                                                        {item.questionAnswers?.map((item: any) => {
                                                                            return <Box display={"flex"} width={"100%"} height={"100%"} maxHeight={80} borderRadius={"3px"} border={"1px solid #D5D5D5"} justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Checkbox disabled={true} checked={item.isCorrect} />
                                                                                <Box width={"100%"} height={"100%"} maxHeight={80} display={"flex"} justifyContent={"start"}
                                                                                    sx={{
                                                                                        overflowY: "scroll",
                                                                                        '&::-webkit-scrollbar': {
                                                                                            display: 'none',
                                                                                        },
                                                                                        '-ms-overflow-style': 'none',
                                                                                        'scrollbarWidth': 'none',
                                                                                    }}
                                                                                    paddingTop={2} paddingBottom={2}>
                                                                                    <Typography fontSize={12} color={"#707070"}>{item.description}</Typography>
                                                                                </Box>
                                                                                {
                                                                                    item.isSelected &&
                                                                                    <Checkbox disabled={true} checked={item.isSelected} />
                                                                                }

                                                                            </Box>
                                                                        })
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                            <Divider sx={{ width: "95%" }} />
                                                        </>
                                                    })
                                                }


                                            </Grid>
                                            //#endregion
                                        }

                                    </Paper>
                                </Grid>
                            </Grid>
                            //#endregion
                        }
                    </Grid >
                    //#endregion
                }

            </Grid >
        </>
    )
}

export default ShowPaper
