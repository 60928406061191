import axios from "axios"
import { useQuery } from "react-query"
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Chart from 'react-apexcharts'
type AppProps = {
    series: number[];
    labels: string[];
}

const NrGroupUser: React.FC = ({ }) => {
  const options = {
    toolbar: {
      show: false},
    xaxis: {
      categories: [
        "Group1",
        "Group2",
        "Group3",
        "Group4s",
        "Group5",
      ],
    },
    
    plotOptions: {
      bar: {
        horizontal: true, //horizontal bar chart
      },
    },
  };

  const series = [
    {
      name: "Height in feet",
      data: [2722, 2080, 2063, 1972, 1516],
    },
  ];

  return (
      <ReactApexChart
        type="bar"
        options={options}
        series={series}
        height={350}
      />
  );
};
export {NrGroupUser};


