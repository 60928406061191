import React, {useEffect, useState, useRef} from 'react'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {useSelector} from 'react-redux'
import {canvasPreview} from './canvasPreview'
import axios from 'axios'
import {enqueueSnackbar} from 'notistack'
import CircularProgress from '@mui/material/CircularProgress'
import {
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  TextField,
  Avatar,
  Divider,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, {centerCrop, makeAspectCrop, Crop, PixelCrop} from 'react-image-crop'
import {Modal} from 'react-bootstrap'
import EmojiPicker, {EmojiStyle, Theme, EmojiClickData, Emoji} from 'emoji-picker-react'
import ReactPlayer from 'react-player'
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Radio from '@mui/material/Radio'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ImageIcon from '@mui/icons-material/Image'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

function SelectContentNew(props: any) {
  let {changeFile, mediaUrl, mediaType, index, changeMediaUrl, iconDisabled} = props

  const [openSelectContent, setOpenSelectContent] = useState(false)

  const handleClickOpen = () => {
    setOpenSelectContent(true)
  }

  const handleClose = () => {
    setOpenSelectContent(false)
  }

  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const LoginState = useSelector((state: any) => state.Login)

  //#region Image Cropper start
  const [contentImage, setContentImage] = useState<any>('')
  const [selectedEmoji, setSelectedEmoji] = useState<string>('')
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(16 / 9)

  function clearCanvas() {
    if (previewCanvasRef.current) {
      const context = previewCanvasRef.current.getContext('2d')
      if (context) {
        context.clearRect(0, 0, previewCanvasRef.current.width, previewCanvasRef.current.height)
      }
    }
  }

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const {width, height} = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  const formData = new FormData()

  function onCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }
      const image = new Image();
      blobUrlRef.current = URL.createObjectURL(blob);
      image.onload = () => {
        // Görüntü yüklendiğinde, yeniden boyutlandırma işlemi için resizeAndDownloadCanvas'ı çağır
        resizeAndDownloadCanvas(image, 800, 450, function(resizedBlob) {
            // Yeniden boyutlandırılmış blob'u changeFile fonksiyonuna geçir
            changeFile(resizedBlob,index,1);
        });
      };
      image.src = blobUrlRef.current;
     
      setContentImage(blobUrlRef.current)
      
    },'image/png')
  }
  function resizeAndDownloadCanvas(image, width, height, callback) {
    // Yeni bir canvas oluşturun
    let resizedCanvas = document.createElement('canvas');
    let ctx = resizedCanvas.getContext('2d');
    resizedCanvas.width = width;
    resizedCanvas.height = height;

    // Görüntüyü yeniden boyutlandırılmış canvas'a çizin
    ctx.drawImage(image, 0, 0, width, height);

    // Yeniden boyutlandırılmış canvas'ı blob olarak alın
    resizedCanvas.toBlob(function(blob) {
        // Burada blob ile istediğiniz işlemi yapabilirsiniz
        callback(blob);
    }, 'image/png');
}
  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
    }
  }, [completedCrop, scale, rotate, imgRef.current, previewCanvasRef.current])

  //#endregion Image Cropper end

  //#region cloud İmage start
  const [cloudImages, setCloudImages] = useState([])
  const [totalPageCloud, setTotalPageCloud] = useState(1)
  const [pageCloud, setPageCloud] = useState(1)
  const [selectedCloudImage, setSelectedCloudImage] = useState({
    imageUrl: '',
    imageName: '',
    lastModified: '',
  })

  const [loadingCloudImage, setLoadingCloudImage] = useState(false)
  const [searchCloudImage, setSearchCloudImage] = useState('')

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageCloud(value)
  }

  useEffect(() => {
    if (tabValue === 1) {
      getCloudImage()
    }
  }, [pageCloud])

  useEffect(() => {
    if (tabValue === 1) {
      getCloudImage()
    }
    if (tabValue === 3) {
      handleGetVideo()
    }
    if (tabValue === 4) {
      handleGetIcons()
    }
  }, [tabValue])

  async function getCloudImage() {
    setLoadingCloudImage(true)
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/content/getImagesFromCloud?page=${pageCloud}&imageName=${searchCloudImage}`,
        {
          headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setCloudImages(response.data.imageList)
        setTotalPageCloud(response.data.totalPageCount)
        setLoadingCloudImage(false)
      })
      .catch((err) => {
        setLoadingCloudImage(false)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              {variant: 'error'}
            )
      })
    setSelectedCloudImage({
      imageUrl: '',
      imageName: '',
      lastModified: '',
    })
  }
  function getCloudImageNotSearch() {
    setLoadingCloudImage(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/content/getImagesFromCloud?page=${pageCloud}&imageName=`,
        {
          headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setCloudImages(response.data.imageList)
        setTotalPageCloud(response.data.totalPageCount)
        setLoadingCloudImage(false)
      })
      .catch((err) => {
        setLoadingCloudImage(false)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              {variant: 'error'}
            )
      })
    setSelectedCloudImage({
      imageUrl: '',
      imageName: '',
      lastModified: '',
    })
  }
  // cloud İmage end
  // emoji start

  function handleEmojiSelect(emojiData: EmojiClickData, event: MouseEvent) {
    setSelectedEmoji(emojiData.unified)
  }

  //#endregion emoji end

  //#region video start
  const [selectedVideo, setSelectedVideo] = useState({videoId: 0, link: '',videoDuration:""})
  const [addVideo, setAddVideo] = useState(false)
  const [addVideoHover, setAddVideoHover] = useState(false)
  const [videoLink, setVideoLink] = useState('')
  const [videoTitle, setVideoTitle] = useState('')
  const [videoTitleError, setVideoTitleError] = useState('')
  const [searchVideo, setSearchVideo] = useState('')
  const [loadingAddVideo, setloadingAddVideo] = useState(false)
  const [loadingDeleteVideo, setloadingDeleteVideo] = useState(false)
  const [loadingGetVideo, setloadingGetVideo] = useState(false)
  const [rows, setRows] = useState<any>([])

  useEffect(() => {
    if (searchVideo === '') {
      handleGetVideo()
      setSelectedVideo({videoId: 0, link: '',videoDuration:""})
    }
  }, [searchVideo])

  function createData(
    videoId: number,
    link: string,
    explanation: string,
    createdDate: string,
    duration: string
  ) {
    return {videoId, link, explanation, createdDate, duration}
  }

  async function handleGetVideo() {
    setloadingGetVideo(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/content/getVideoList?videoName=${searchVideo}`, {
        headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setRows(response.data)
        setloadingGetVideo(false)
      })
      .catch((err) => {
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              {variant: 'error'}
            )
        setloadingGetVideo(false)
      })
  }
  async function handleGetVideoNotFilter() {
    setloadingGetVideo(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/content/getVideoList`, {
        headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setRows(response.data)
        setloadingGetVideo(false)
      })
      .catch((err) => {
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              {variant: 'error'}
            )
        setloadingGetVideo(false)
      })
  }

  async function handleDeleteVideo() {
    setloadingDeleteVideo(true)
    setSearchVideo('')
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/content/deleteVideo`,
        {
          videoId: selectedVideo.videoId,
        },
        {
          headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        enqueueSnackbar('Successfully Saved', {variant: 'success'})
        handleGetVideo()
        setloadingAddVideo(false)
        setloadingDeleteVideo(false)
      })
      .catch((err) => {
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              {variant: 'error'}
            )
        setloadingDeleteVideo(false)
      })
  }

  async function handleAddVideo() {
    setloadingAddVideo(true)
    setSearchVideo('')
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/content/addVideo`,
        {
          link: videoLink,
        },
        {
          headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        handleGetVideo()
        enqueueSnackbar('Successfully Saved', {variant: 'success'})
        setloadingAddVideo(false)
        setAddVideo(false)
        setVideoLink('')
      })
      .catch((err) => {
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              {variant: 'error'}
            )
        setloadingAddVideo(false)
      })
  }
  //#endregion video end

  //#region icons start
  const [iconList, seticonList] = useState<any>([])
  const [selectedIcon, setSelectedIcon] = useState<any>({mediaName: '', mediaUrl: ''})
  const [searchIcon, setSearchIcon] = useState<any>('')
  const [loadingFilter, setLoadingFilter] = useState<any>(false)
  const [loadingIcons, setLoadingIcons] = useState<any>(false)

  const filterIcons = iconList.filter((item: any) => {
    return item.mediaName.toLowerCase().includes(searchIcon.toLowerCase())
  })

  async function handleGetIcons() {
    setLoadingIcons(true)
    setSelectedIcon({mediaUrl: '', mediaName: ''})
    axios
      .get(`${process.env.REACT_APP_API_URL}/Game/getMobixaIcons`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((response: any) => {
        seticonList(response.data)
        setLoadingIcons(false)
      })
      .catch((err) => {
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              {variant: 'error'}
            )
        setLoadingIcons(false)
      })
  }
  //#endregion icons end

  //#region image warn modal
  const [imageWarnModal, setImageWarnModal] = useState<boolean>(false)

  const handleCloseWarnModal = () => {
    setImageWarnModal(false)
    setTabValue(0)
  }
  const [videoDeleteWarnModal, setVideoDeleteWarnModal] = useState<boolean>(false)
  const handleCloseDeleteWarnModal = () => {
    setVideoDeleteWarnModal(false)
  }

  function handleClear() {
    setImageWarnModal(false)
    setContentImage('')
    setTabValue(0)
    setSearchCloudImage('')
    setSearchIcon('')
    setSearchVideo('')
    setSelectedEmoji('')
    setSelectedCloudImage({
      imageUrl: '',
      imageName: '',
      lastModified: '',
    })
    setSelectedVideo({videoId: 0, link: '',videoDuration:""})
    setSelectedIcon({mediaName: '', mediaUrl: ''})
    changeMediaUrl('', index, 0)
    changeFile('', index, 0)
  }
  //#endregion

  useEffect(() => {
    if (mediaUrl?.length > 0) {
      setContentImage(mediaUrl)
    }
    if (mediaType === 1) {
      setTabValue(1)
    }
    if (mediaType === 2) {
      setTabValue(3)
    }
    if (mediaType === 4) {
      setTabValue(2)
      setSelectedEmoji(mediaUrl)
    }
  }, [mediaUrl])

  const handleSave = () => {
    if (tabValue === 0) {
      onCropClick()
      changeMediaUrl(contentImage, index, 1)
    }
    if (tabValue === 1) {
      setContentImage(selectedCloudImage.imageUrl)
      changeMediaUrl(selectedCloudImage.imageUrl, index, 1)
    }
    if (tabValue === 2) {
      setContentImage(selectedEmoji)
      changeMediaUrl(selectedEmoji, index, 4)
    }
    if (tabValue === 3) {
      setContentImage(selectedVideo.link)
      changeMediaUrl(selectedVideo.link, index, 2)
    }
    /*   if (tabValue === 2) {
      setContentImage(selectedIcon.mediaUrl)
    } */
    handleClose()
  }
  const [binHover, setBinHover] = useState<boolean>(false)

  useEffect(() => {
    if (contentImage !== '' && tabValue === 0) {
      changeMediaUrl(contentImage, index, 1)
    }
  }, [contentImage])

  return (
    <div>
  <Button
        variant='outlined'
        onClick={handleClickOpen}
        style={{
          backgroundColor: 'transparent',
          height: '200px',
          width: '100%',
          padding: '0px',
          display: 'flex',
          alignContent: 'center',
        }}
      >
        {tabValue === 0 || tabValue === 1 || tabValue === 4 ? (
          contentImage.length > 0 ? (
            <img
              src={contentImage}
              style={{
                objectFit: 'contain',
                width: '100%',
                height: '100%',
              }}
            />
          ) : (
            <Box width={'100%'} height={'100%'} padding={0}>
              <ImageIcon color='disabled' style={{fontSize: '100px'}} />
              <Typography style={{color: 'gray', marginTop: '10px'}}>
                Click to select image or video
              </Typography>
            </Box>
          )
        ) : null}
        {tabValue === 2 && (
          <Emoji unified={selectedEmoji} emojiStyle={EmojiStyle.APPLE} size={150} />
        )}
        {tabValue === 3 && (
          <ReactPlayer controls={true} url={contentImage} height={'100%'} width={'100%'} />
        )}
        {contentImage.length > 0 && (
          <Tooltip arrow title='Delete' style={{position: 'absolute', right: '0px'}}>
            <IconButton
              aria-label='delete'
              disabled={loadingDeleteVideo}
              onMouseEnter={() => setBinHover(true)}
              onMouseLeave={() => setBinHover(false)}
              onClick={(e: any) => {
                e.stopPropagation()
                setImageWarnModal(true)
              }}
            >
              <DeleteForeverRoundedIcon color='error' fontSize='large' />
            </IconButton>
          </Tooltip>
        )}
      </Button>
      <Modal show={openSelectContent} size='xl' onHide={handleClose}>
        <Modal.Header closeButton style={{fontSize: 24, fontWeight: 'bold'}}>
          Select Media
        </Modal.Header>
        <Divider />
        <Modal.Body>
          <Box sx={{width: '100%'}} minHeight={500}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                centered
                aria-label='basic tabs example'
              >
                <Tab
                  icon={tabValue === 0 && <CheckCircleIcon />}
                  iconPosition='start'
                  label='LOCAL IMAGE'
                  style={{margin: '0 40px', fontSize: 16, fontWeight: 'bold'}}
                  {...a11yProps(0)}
                />
                <Tab
                  icon={tabValue === 1 && <CheckCircleIcon />}
                  iconPosition='start'
                  label='CLOUD IMAGE'
                  style={{margin: '0 40px', fontSize: 16, fontWeight: 'bold'}}
                  {...a11yProps(1)}
                />
                <Tab
                  icon={tabValue === 2 && <CheckCircleIcon />}
                  iconPosition='start'
                  label='EMOJI'
                  style={{margin: '0 40px', fontSize: 16, fontWeight: 'bold'}}
                  {...a11yProps(2)}
                />
                <Tab
                  icon={tabValue === 3 && <CheckCircleIcon />}
                  iconPosition='start'
                  label='YOUTUBE VIDEO'
                  style={{margin: '0 40px', fontSize: 16, fontWeight: 'bold'}}
                  {...a11yProps(3)}
                />
                {/* <Tab disabled={iconDisabled} label='ICON' {...a11yProps(4)} /> */}
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <div className='row d-flex align-items-center'>
                <div
                  className='col-8 text-center mt-3' /* style={{height: '350px', width: '100%'}} */
                >
                  {!!imgSrc && (
                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                      onComplete={(c) => setCompletedCrop(c)}
                      aspect={aspect}
                    >
                      <img
                        ref={imgRef}
                        height='350px'
                        width='100%'
                        alt='Crop me'
                        src={imgSrc}
                        style={{transform: `scale(${scale}) rotate(${rotate}deg)`}}
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                  )}
                </div>

                {!!completedCrop && (
                  <div
                    className='col-4 align-content-center text-center'
                    style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}
                  >
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <Typography fontWeight={'bold'}>Preview</Typography>
                    </div>

                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        objectFit: 'contain',
                        width: '100%',
                        height: '150px',
                        /*  visibility: 'hidden', */
                      }}
                    />
                  </div>
                )}

                <div
                  style={{
                    height: !completedCrop && '400px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'end',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: 8,
                      width: '80%',
                    }}
                  >
                    <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                      <label className='col-12 text-center ' >
                        <div className='btn btn-primary' style={{fontSize:10}}>
                          <span>SELECT IMAGE</span>
                        </div>
                        <input
                          type='file'
                          accept='image/*'
                          name='profile_avatar'
                          /* accept='.png, .jpg, .jpeg' */
                          hidden
                          onChange={onSelectFile}
                        />
                      </label>
                    </div>
                    {!!completedCrop && (
                      <div>
                        <Button
                          style={{height: 43.6}}
                          size='large'
                          color='error'
                          variant='contained'
                          onClick={(e: any) => {
                            e.stopPropagation()
                            setImgSrc('')
                            setCompletedCrop(null)
                            clearCanvas()
                          }}
                        >
                          DELETE{' '}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              {loadingCloudImage ? (
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <CircularProgress size={30} color='inherit' />
                </Box>
              ) : (
                <Grid container justifyContent={'center'}>
                  {/*     <Grid xs={12} justifyContent={'end'} display={'flex'} marginBottom={2}>
                    <TextField
                      id='outlined-basic'
                      label='Search'
                      variant='outlined'
                      size='small'
                      placeholder='min 3ch max 50ch'
                      sx={{
                        width: '200px',
                      }}
                      value={searchCloudImage}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const inputValue = e.target.value
                        if (inputValue.length <= 50) {
                          setSearchCloudImage(inputValue)
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {searchCloudImage.length > 2 && (
                              <Tooltip title='Delete'>
                                <IconButton
                                  aria-label='delete'
                                  onClick={() => (
                                    setSearchCloudImage(''), getCloudImageNotSearch()
                                  )}
                                >
                                  <CloseIcon color='error' />
                                </IconButton>
                              </Tooltip>
                            )}
                            {searchCloudImage.length > 2 && (
                              <Tooltip title='Search'>
                                <IconButton
                                  aria-label='search'
                                  color='success'
                                  onClick={() => getCloudImage()}
                                >
                                  <CheckIcon />
                                </IconButton>
                              </Tooltip>
                            )}

                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid> */}
                  <Grid xs={8} overflow='scroll' height={'500px'}>
                    <div style={{padding: 1, border: '1px solid #000000b8', borderRadius: 15}}>
                      {cloudImages.length === 0 ? (
                        <Box
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          width={'100%'}
                          height={'100%'}
                        >
                          <Typography variant='h5' textAlign={'center'} sx={{opacity: '0.5'}}>
                            Image Not Found
                          </Typography>
                        </Box>
                      ) : null}

                      {cloudImages?.map((i: any, index: number) => (
                        <div className=' btn col-3 rounded' key={index}>
                          <div
                            className='my-auto  d-flex justify-content-center align-items-center'
                            style={{border: '0px !important'}}
                          >
                            <div className='symbol symbol-50px'>
                              <Button
                                variant='text'
                                onClick={() => {
                                  setSelectedCloudImage({
                                    imageUrl: i.imageUrl,
                                    imageName: i.imageName,
                                    lastModified: i.lastModified,
                                  })
                                }}
                                sx={{
                                  border:
                                    selectedCloudImage.imageUrl === i.imageUrl
                                      ? '2px solid #2196f3'
                                      : '',
                                }}
                              >
                                <img
                                  key={i}
                                  className=' symbol-50px rounded'
                                  src={i.imageUrl}
                                  alt=''
                                  height={50}
                                  width={50}
                                />
                              </Button>
                            </div>
                          </div>
                          <span>{i.imageName}</span>
                          <br />
                        </div>
                      ))}

                      {cloudImages.length !== 0 && (
                        <Box
                          display={'flex'}
                          justifyContent={'center'}
                          marginTop={2}
                          marginBottom={2}
                          alignItems={'center'}
                        >
                          <Pagination
                            size='small'
                            count={totalPageCloud}
                            page={pageCloud}
                            onChange={handlePageChange}
                            showFirstButton
                            showLastButton
                          />
                        </Box>
                      )}
                    </div>
                  </Grid>
                  <Grid
                    xs={4}
                    paddingLeft={1}
                    display={'flex'}
                    justifyContent={'space-between'}
                    textAlign={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                  >
                    <div></div>
                    {selectedCloudImage.imageUrl.length > 2 && (
                      <Box border={'1px solid black'} borderRadius={'15px'} padding={1}>
                        <div>
                          <Typography>{selectedCloudImage.lastModified}</Typography>
                          <img
                            src={selectedCloudImage.imageUrl}
                            style={{borderRadius: '15px'}}
                            alt=''
                            height={'100%'}
                            width={'100%'}
                          />
                          <Typography>{selectedCloudImage.imageName}</Typography>
                        </div>
                      </Box>
                    )}
                    {selectedCloudImage.imageUrl.length > 2 && (
                      <div>
                        <Button
                          variant='contained'
                          color='error'
                          style={{height: 43.6, width: 88.8, marginBottom: 83, marginLeft: 37}}
                          onClick={() =>
                            setSelectedCloudImage({
                              imageUrl: '',
                              imageName: '',
                              lastModified: '',
                            })
                          }
                        >
                          DELETE
                        </Button>
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <Grid container>
                <Grid xs={8} paddingRight={'10px'}>
                  <EmojiPicker
                    onEmojiClick={handleEmojiSelect}
                    autoFocusSearch={false}
                    theme={Theme.AUTO}
                    width={'100%'}
                  />
                </Grid>
                <Grid
                  xs={4}
                  container
                  justifyContent={'center'}
                  alignContent={'center'}
                  direction={'column'}
                >
                  {selectedEmoji !== '' && (
                    <Button
                      variant='text'
                      color='primary'
                      style={{
                        color: 'black',
                        /*       background: '#1976D2', */
                        border: '1px solid black',
                        borderRadius: '15px',
                        padding: '5px',
                        display: 'flex',
                        width: '340px',
                        height: '245px',
                        marginTop: '10px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textTransform: 'none',
                      }}
                    >
                      <Emoji
                        unified={selectedEmoji}
                        emojiStyle={EmojiStyle.APPLE}
                        size={100}
                        lazyLoad={true}
                      />
                      <Typography textAlign={'center'}>{selectedEmoji}</Typography>
                    </Button>
                  )}
                  {selectedEmoji !== '' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 78,
                        marginLeft: 42,
                      }}
                    >
                      <Button
                        variant='contained'
                        color='error'
                        style={{marginTop: 2}}
                        sx={{height: 43.6, width: 88.8}}
                        onClick={() => setSelectedEmoji('')}
                      >
                        DELETE
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={3}>
            <Grid container>
                <Grid xs={12} display={'flex'} justifyContent={'space-between'}>
                  <Box padding={0} display={'flex'} alignItems={'start'} minHeight={56} width={'76%'}>
                    <TextField
                      label='Link'
                      variant='outlined'
                      size='small'
                      placeholder='Youtube/Vimeo/Medianova link….'
                      sx={{
                        /*  width: '200px', */
                        marginRight: '10px',
                      }}
                      fullWidth
                      disabled={loadingAddVideo}
                      value={videoLink}
                      onChange={(e: any) => setVideoLink(e.target.value)}
                    /*  InputProps={{
                       endAdornment: (
                         <>
                           {videoLink.length > 2 && (
                             <Tooltip title='Add Video' placement='top'>
                               <IconButton
                                 aria-label='add'
                                 onClick={() => handleAddVideo()}
                                 disabled={loadingAddVideo}
                                 size='small'
                                 sx={{ background: 'white', marginLeft: '7px' }}
                               >
                                 <AddCircleOutlinedIcon
                                   sx={{
                                     color: '#53b658',
                                   }}
                                   fontSize='large'
                                 />
                               </IconButton>
                             </Tooltip>
                           )}
                           {videoLink.length > 2 && (
                             <Tooltip title='Close Add Video' placement='top'>
                               <IconButton
                                 aria-label='closeAddVideo'
                                 onClick={() => (setAddVideo(false), setVideoLink(''))}
                                 size='small'
                                 sx={{ background: 'white', marginRight: '7px' }}
                               >
                                 <DeleteForeverRoundedIcon fontSize='large' color='error' />
                               </IconButton>
                             </Tooltip>
                           )}
                         </>
                       ),
                     }} */
                    />
                    <TextField
                      label='Title'
                      variant='outlined'
                      size='small'
                      placeholder='Title Min3 Max250 ch'
                      sx={{
                        width: '40%',
                        marginRight: '10px',
                      }}
                      disabled={loadingAddVideo}
                      value={videoTitle}
                      onChange={(e: any) =>e.target.value.length <= 250 ?( setVideoTitle(e.target.value),setVideoTitleError("") ): null}
                      inputProps={{
                        maxLength: 250
                      }}
                      error={Boolean(videoTitleError)}
                      helperText={videoTitleError}
                    />
                  </Box>
                  <Box display={"flex"} alignItems={"center"} width={"24%"} height={39} justifyContent={"start"}>
                    <>
                      {videoLink.length > 2 && videoTitle.length > 2 && (
                        <Tooltip arrow title='Add Video' placement='top'>
                          <IconButton
                            aria-label='add'
                            onClick={() => handleAddVideo()}
                            disabled={loadingAddVideo}
                            size='small'
                            sx={{ background: 'white', marginLeft: '7px' }}
                          >
                            <AddCircleOutlinedIcon
                              sx={{
                                color: '#53b658',
                              }}
                              fontSize='large'
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {(videoLink.length > 2 || videoTitle.length > 2) && (
                        <Tooltip arrow title='Close Add Video' placement='top'>
                          <IconButton
                            aria-label='closeAddVideo'
                            onClick={() => (setAddVideo(false), setVideoLink(''),setVideoTitle(""))}
                            size='small'
                            sx={{ background: 'white', marginRight: '7px' }}
                          >
                            <DeleteForeverRoundedIcon fontSize='large' color='error' />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  </Box>
                  
                </Grid>
                <Grid xs={9} marginTop={4}>
                  {loadingGetVideo ? (
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <TableContainer /* component={Paper}  */ sx={{ maxHeight: 400, border: "1px solid black", borderRadius: "5px" }}>
                      <Table
                        sx={{ minWidth: 350 }}
                        size='small'
                        stickyHeader
                        aria-label='a dense table'
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align='left'>Explanation</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }} align='left'>
                              Created Date
                            </TableCell>
                            {/*   <TableCell align='left'>Duration</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.length === 0 && (
                            <Box
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              height={'100px'}
                              width={'100%'}
                            >
                              <Typography>No Videos Found</Typography>
                            </Box>
                          )}
                          {rows.map((row: any, i: number) => (
                            <TableRow
                              key={row.videoId}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component='th' scope='row'>
                                <Box display={'flex'} padding={0} alignItems={'center'}>
                                  <div>
                                    <Radio
                                      key={i}
                                      checked={row.videoId === selectedVideo.videoId}
                                      onChange={() => {
                                       
                                        setSelectedVideo({ videoId: row.videoId, link: row.link, videoDuration: row.duration })
                                      }}
                                      sx={{
                                        '& .MuiSvgIcon-root': {
                                          fontSize: '22px',
                                        },
                                        '&.Mui-checked': {
                                          color: '#53b658',
                                        },
                                      }}
                                      name='radio-buttons'
                                    />
                                  </div>
                                  <Typography>{row.explanation}</Typography>
                                </Box>
                              </TableCell>
                              <TableCell align='left' style={{ whiteSpace: 'nowrap' }}>
                                <Box display={'flex'} alignItems={'center'} minWidth={155} width={"auto"} height={45} justifyContent={"start"}>
                                  <Typography>{row.createdDate}</Typography>
                                  <Box display={"flex"} alignItems={"center"} justifyContent={"end"} width={"100%"}>
                                    {row.videoId === selectedVideo.videoId && (
                                      <Tooltip arrow title='Delete' style={{ marginLeft: '5px' }}>
                                        <IconButton
                                          aria-label='delete'
                                          disabled={loadingDeleteVideo}
                                          onClick={() => setVideoDeleteWarnModal(true)}
                                        >
                                          <DeleteForeverRoundedIcon
                                            color={loadingDeleteVideo ? 'inherit' : 'error'}
                                            fontSize='large'
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </Box>
                                </Box>
                              </TableCell>
                              {/*   <TableCell align='left'>
                                <Box display={'flex'} alignItems={'center'}>
                                  <Typography>{row.duration}</Typography>
                                  {row.videoId === selectedVideo.videoId && (
                                    <Tooltip title='Delete' style={{ marginLeft: '5px' }}>
                                      <IconButton
                                        aria-label='delete'
                                        disabled={loadingDeleteVideo}
                                        onClick={() => setVideoDeleteWarnModal(true)}
                                      >
                                        <DeleteForeverRoundedIcon
                                          color={loadingDeleteVideo ? 'inherit' : 'error'}
                                          fontSize='large'
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </TableCell> */}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
                <Grid
                  xs={3}
                  display={'flex'}
                  justifyContent={'center'}
                  direction={'column'}
                  marginTop={4}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    padding={0}
                    marginLeft={2}
                    flexDirection={'column'}
                  >
                    <div></div>
                    <ReactPlayer
                      controls={true}
                      url={selectedVideo.link}
                      height={'100%'}
                      width={'100%'}
                    />
                    {selectedVideo.link.length > 2 && (
                      <div>
                        <Button
                          variant='contained'
                          color='error'
                          style={{ marginTop: 85, marginRight: 55 }}
                          sx={{ height: 43.6, width: 88.8 }}
                          onClick={() => setSelectedVideo({ videoId: 0, link: '', videoDuration: "" })}
                        >
                          DELETE
                        </Button>
                      </div>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={4}>
              <Grid container alignItems={'center'}>
                <Grid xs={12} container justifyContent={'end'}>
                  <TextField
                    id='outlined-basic'
                    label='Search'
                    variant='outlined'
                    size='small'
                    placeholder='min 3ch max 50ch'
                    sx={{
                      width: '200px',
                    }}
                    value={searchIcon}
                    disabled={loadingIcons}
                    onChange={(e: any) => setSearchIcon(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {searchIcon.length > 2 && (
                            <Tooltip arrow title='Delete'>
                              <IconButton aria-label='delete' onClick={() => setSearchIcon('')}>
                                <CloseIcon color='error' />
                              </IconButton>
                            </Tooltip>
                          )}
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid xs={10} container height={500} overflow={'auto'} marginTop={2}>
                  {loadingIcons ? (
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      width={'100%'}
                    >
                      <CircularProgress />
                    </Box>
                  ) : filterIcons?.length === 0 ? (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      width={'100%'}
                    >
                      <Typography variant='h5' textAlign={'center'} sx={{opacity: '0.5'}}>
                        Image Not Found
                      </Typography>
                    </Box>
                  ) : (
                    filterIcons?.map((item: any) => {
                      if (item?.mediaName.length > 1) {
                        return (
                          <Grid xs={1.5} paddingRight={'10px'} paddingLeft={'10px'}>
                            <Button
                              onClick={() => {
                                setSelectedIcon({
                                  mediaUrl: item?.mediaUrl,
                                  mediaName: item?.mediaName,
                                })
                              }}
                              variant='text'
                              color='primary'
                              style={{
                                color: selectedIcon.mediaUrl === item.mediaUrl ? 'white' : '',
                                background:
                                  selectedIcon.mediaUrl === item.mediaUrl ? '#1976D2' : '',
                                padding: '5px',
                                display: 'flex',
                                height: '105px',
                                width: '89px',
                                marginTop: '10px',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textTransform: 'none',
                              }}
                            >
                              <Avatar
                                src={item?.mediaUrl}
                                variant='rounded'
                                sx={{width: '56px', height: '56px'}}
                              />
                              <Typography /* style={{whiteSpace:"nowrap"}} */>
                                {item?.mediaName}
                              </Typography>
                            </Button>
                          </Grid>
                        )
                      }
                    })
                  )}
                </Grid>
                <Grid xs={2} display={'flex'} justifyContent={'center'}>
                  {selectedIcon.mediaUrl === '' ? null : (
                    <Button
                      variant='text'
                      color='primary'
                      style={{
                        color: 'white',
                        background: '#1976D2',
                        padding: '5px',
                        display: 'flex',
                        marginTop: '10px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textTransform: 'none',
                      }}
                    >
                      <Avatar
                        src={selectedIcon.mediaUrl}
                        variant='rounded'
                        sx={{width: '128px', height: '128px'}}
                      />
                      <Typography>{selectedIcon?.mediaName}</Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CustomTabPanel>
          </Box>
        </Modal.Body>
        <Divider />
        <Modal.Footer>
          <Button variant='contained' color='inherit' onClick={handleClose}>
            Discard
          </Button>
          <Button variant='contained' onClick={handleSave} style={{marginLeft: '15px'}}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {
        //İmage Delete Warn Modal
      }
      <Modal show={imageWarnModal} onHide={handleCloseWarnModal}>
        <Modal.Header className='bg-light-danger border-0' closeButton></Modal.Header>
        <Modal.Body className='bg-light-danger text-danger text-center  border-0'>
          Are You Sure? Image will be deleted.
        </Modal.Body>
        <Modal.Footer className='bg-light-danger border-0'>
          <Button variant='contained' color='inherit' onClick={handleCloseWarnModal}>
            No
          </Button>
          <Button
            variant='contained'
            color='error'
            style={{marginLeft: '15px'}}
            onClick={() => {
              handleClear()
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {
        // video delete warn modal
      }
      <Modal show={videoDeleteWarnModal} onHide={handleCloseDeleteWarnModal}>
        <Modal.Header className='bg-light-danger border-0' closeButton></Modal.Header>
        <Modal.Body className='bg-light-danger text-danger text-center  border-0'>
          Are You Sure? Image will be deleted.
        </Modal.Body>
        <Modal.Footer className='bg-light-danger border-0'>
          <Button variant='contained' color='inherit' onClick={handleCloseDeleteWarnModal}>
            No
          </Button>
          <Button
            variant='contained'
            color='error'
            style={{marginLeft: '15px'}}
            onClick={() => {
              handleDeleteVideo()
              handleCloseDeleteWarnModal()
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SelectContentNew
