import React, {useCallback, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {
  Box,
  Button,
  Chip,
  MenuItem,
  Pagination,
  Select,
  Typography,
  darken,
  lighten,
  styled,
} from '@mui/material'
import {
  DataGridPro,
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {useSelector} from 'react-redux'
const ExcelJS = require('exceljs');

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({theme}) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

function CategoriesReport() {
  const LoginState = useSelector((state: any) => state.Login)

  const QuestionsBreadcrumbs: Array<PageLink> = [
    {
      title: 'Reports',
      // path: '/apps/user-management/users',
      path: '/reports',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  })
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [selectQuestion, setSelectQuestion] = useState([])
  const [questionStatus, setQuestionStatus] = useState(-1)
  const [categoriesOptions, setCategoriesOptions] = useState<any>({
    sortModel: [{field: 'name', sort: 'asc'}],
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    /*    id: false,
           brokerId: false,
           status: false, */
  })

  const [status] = useState([
    {value: 0, label: 'Blocked'},
    {value: 1, label: 'Open'},
    {value: 2, label: 'Deleted'},
  ])
  const [level] = useState([
    {value: 1, label: 1},
    {value: 2, label: 2},
    {value: 3, label: 3},
  ])

  const StatusCell = ({value}: {value: number}) => {
    const getStatusComponent = (status: number) => {
      if (status === 0) {
        return (
          <Chip
            className='overflow-visible'
            label='Blocked'
            variant='outlined'
            color='secondary'
            sx={{overflow: 'visible', maxWidth: '90px', fontSize: '13px'}}
          />
        )
      }
      if (status === 1) {
        return (
          <Chip
            className='overflow-visible'
            label='Open'
            variant='outlined'
            color='success'
            sx={{overflow: 'visible', maxWidth: '90px', fontSize: '13px'}}
          />
        )
      }
      if (status === 2) {
        return (
          <Chip
            className='overflow-visible'
            label='Deleted'
            variant='outlined'
            color='error'
            sx={{overflow: 'visible', maxWidth: '90px', fontSize: '13px'}}
          />
        )
      }
      return null
    }

    return <div style={{height: '19px', width: '57px'}}>{getStatusComponent(value)}</div>
  }

  const columns: any = [
    {
      field: 'categoryId',
      headerName: 'CATEGORY ID',
      width: 140,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value.toFixed(0),
    },
    {
      field: 'name',
      headerName: 'CATEGORY',
      width: 140,
      headerAlign: 'left',
      type: 'text',
      align: 'left',
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'source',
      headerName: 'SOURCE',
      width: 140,
      description: 'Client is your company or you as the customer.',
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'level',
      headerName: 'LEVEL',
      width: 130,
      description:
        '1=Top category, consisting of 2nd and 3rd level of categories. 2=Mid level category, may have 3rd level of categories or may have questions only. 3=Bottom level of category. No child. Can have questions only.',
      headerAlign: 'left',
      align: 'left',
      type: 'singleSelect',
      valueOptions: level.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'totalNrOfQuestions',
      headerName: 'QUESTİONS',
      width: 160,
      description:
        'The number of questions this category has. Including the questions of child categories.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => (params?.value ? params.value.toFixed(0) : '0'),
    },
/*     {
      field: 'difficulty',
      headerName: 'DIFFICULTY',
      width: 140,
      description:
        'The average difficulty level of all the contained questions. 1:Easy 2:Med 3:Hard',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => (params?.value ? params.value.toFixed(2) : '0.00'),
    }, */
    {
      field: 'totalAppearances',
      headerName: 'APPEARANCES',
      width: 140,
      description:
        'The total displayed number of all the questions contained by the category in the given period of time.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'success',
      headerName: 'SUCCESS %',
      width: 140,
      description:
        'The proportion of the right answers over all displayed questions of this category in the given period of time. ',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => (params?.value ? params.value.toFixed(2) : '0.00'),
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
  ]

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ml: 1, mr: 1}}
          >
            <MenuItem selected value={100}>
              100
            </MenuItem>
            <MenuItem value={150}>150</MenuItem>
            <MenuItem value={200}>200</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelectQuestion(newSelectionModel)
  }

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }

    // Diğer işlemleri burada yapabilirsiniz
  }
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setCategoriesOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
          return
        } */

    setCategoriesOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: {...filterModel},
    }))
  }, [])

  const [loadingExport, setLoadingExport] = useState(false)
  async function downloadExcel() {
    setLoadingExport(true)
    setTimeout(() => {
      setLoadingExport(false)
    }, 1000)
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report');
    worksheet.columns = [
      { header: 'Category ID', key: 'CategoryId', width: 20 },
      { header: 'Category', key: 'Category', width: 30 },
      { header: 'Source', key: 'Source', width: 10 },
      { header: 'Level', key: 'Level', width: 18 },
      { header: 'Questions', key: 'Questions', width: 14 },
      { header: 'Appearances', key: 'Appearances', width: 30 },
      { header: 'Success (%)', key: 'Success', width: 18 },
    ];

    // Başlık satırının stilini ayarlayın
    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4F81BD' } // Koyu mavi arka plan rengi
      };
      cell.font = {
        color: { argb: 'FFFFFFFF' }, // Beyaz yazı rengi
        bold: true
      };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        right: { style: 'thin', color: { argb: 'FFFFFFFF' } }
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    worksheet.autoFilter = {
      from: 'A1',
      to: 'G1'
    };

    // newData üzerinde dolaşarak her bir satırı Excel'e ekleyin
    let rowNumber = 2;
    rows.forEach(row => {
      const newRow = worksheet.addRow({
        CategoryId: row.categoryId,
        Category: row.name,
        Source: row.source,
        Level: row.level,
        Questions:row.totalNrOfQuestions,
        Appearances: row.totalAppearances,
        Success:row.success,
      });
      const fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: rowNumber % 2 === 0 ? 'B8CCE4' : 'DCE6F1' },
      };
      newRow.border = {
        top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        right: { style: 'thin', color: { argb: 'FFFFFFFF' } }
      };
      newRow.eachCell((cell) => {
        cell.fill = fill;
      });
      rowNumber++;
    });

    // Excel dosyasını kaydedin
    const buffer = await workbook.xlsx.writeBuffer(); // Workbook'u bir ArrayBuffer olarak döndür
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Blob'u oluştur
    const url = window.URL.createObjectURL(blob); // Blob için bir URL oluştur
    const anchor = document.createElement('a'); // Bir <a> elementi oluştur
    anchor.href = url;
    anchor.download = 'categoriesReport.xlsx'; // İndirilecek dosyanın adını ayarla
    document.body.appendChild(anchor); // <a> elementini DOM'a ekle
    anchor.click(); // Programatik olarak tıklayarak indirmeyi başlat
    document.body.removeChild(anchor); // <a> elementini DOM'dan kaldır
    window.URL.revokeObjectURL(url);

  /*   const newData = rows.map((row) => {
      const newRow = {
        CategoryId: row.categoryId,
        Category: row.name,
        Source: row.source,
        Level: row.level,
        Questions:row.totalNrOfQuestions,
        Appearances: row.totalAppearances,
        Success:row.success,
      }
      return newRow;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, 'row')
    //Buffer
    XLSX.write(workBook, {bookType: 'xlsx', type: 'buffer'})
    //Binary string
    XLSX.write(workBook, {bookType: 'xlsx', type: 'binary'})
    //Download
    XLSX.writeFile(workBook, 'categoriesReport.xlsx') */
  }
  const [timeButtons, setTimeButtons] = useState('last3Months')

  useEffect(() => {
    fetchRows(timeButtons)
  }, [timeButtons])

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <LoadingButton
          size='small'
          onClick={downloadExcel}
          endIcon={<FileDownloadIcon />}
          loading={loadingExport}
          loadingPosition='end'
          variant='outlined'
        >
          <span>Export</span>
        </LoadingButton>
        <Box marginLeft={14} /* marginBottom={3} */>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('monthToDate')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'monthToDate' ? 'bold' : '',
              color: timeButtons === 'monthToDate' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'monthToDate' ? 'underline' : 'none',
            }}
          >
            Month to date
          </Button>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('lastMonth')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'lastMonth' ? 'bold' : '',
              color: timeButtons === 'lastMonth' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'lastMonth' ? 'underline' : 'none',
            }}
          >
            Last month
          </Button>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('last3Months')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'last3Months' ? 'bold' : '',
              color: timeButtons === 'last3Months' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'last3Months' ? 'underline' : 'none',
            }}
          >
            Last 3 months
          </Button>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('allTime')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'allTime' ? 'bold' : '',
              color: timeButtons === 'allTime' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'allTime' ? 'underline' : 'none',
            }}
          >
            All time
          </Button>
        </Box>
      </GridToolbarContainer>
    )
  }

  /*   useEffect(() => {
    fetchRows()
  }, [paginationModel, userOptions]) */

  const fetchRows = async (timeButtons: any) => {
    setIsLoadingData(true)

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/report/categoryReport?timeStamp=${timeButtons}`,
        {},
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const formattedRows = response.data?.map((row: any) => ({
          categoryId: row.categoryId,
          name: row.name,
          level: row.level,
          totalNrOfQuestions: row.totalNrOfQuestions,
          difficulty: row.difficulty,
          totalAppearances: row.totalAppearances,
          source: row.source,
          success: row.successRate,
        }))

        setRows(formattedRows || [])
        setRowCount(response.data.length)
        setIsLoadingData(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
      })
  }

  return (
    <>
      {/*   <PageTitle breadcrumbs={QuestionsBreadcrumbs}>Categories</PageTitle> */}
      <div
        style={{
          display: 'flex',
          height: 40,
          width: '80%',
          marginBottom: '5px',
          marginTop: '-25px',
          cursor: 'default',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
          Reports - Categories
        </Typography>
      </div>
      <div className='card card-custom p-3  '>
        <Box sx={{height: 600, width: '100%'}}>
          <StyledDataGrid
            /*   {...data}
                loading={data.rows.length === 0} */
            rows={rows || []}
            columns={columns}
            rowHeight={50}
            columnHeaderHeight={40}
            disableVirtualization
            /* checkboxSelection */
            onCellDoubleClick={handleCellClick}
            getRowId={(row) => row.categoryId}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            disableRowSelectionOnClick
            sortingMode='client'
            sortModel={categoriesOptions?.sortModel}
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={handleSelectionModelChange}
            filterMode='client'
            onFilterModelChange={onFilterChange}
            isRowSelectable={(params: GridRowParams) => params.row.source === 'Client'}
            rowCount={rowCount}
            loading={isLoadingData}
            paginationModel={paginationModel}
            paginationMode='client'
            pagination={true}
            onPaginationModelChange={setPaginationModel}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
            }
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
            sx={{
              boxShadow: "none",
              border: "none",
              '& .MuiDataGrid-footerContainer': {
                padding: '0px', 
                height: 30
              },

              '& .MuiDataGrid-cell:hover': {
                /*    color: 'primary.main', */
                cursor: 'pointer',
              },
              '&	.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                color: '#A1A5B7',
                fontSize: 12,
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
              },
            }}
          />
        </Box>
      </div>
    </>
  )
}

export default CategoriesReport
