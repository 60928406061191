import { call, put } from 'redux-saga/effects';
import QuestionsActions from '../reducers/QuestionRedux';

export function* getQuestions(api, action) {
    try {
        const response = yield call(api.getQuestions);
        //  
        if (response.hasOwnProperty('error')) {
            yield put(QuestionsActions.getQuestionFailure(response))
        } else {
            yield put(QuestionsActions.getQuestionSuccess(response))
        }
    } catch (error) {
        yield put(QuestionsActions.getQuestionFailure(error))
    }
}