import axios from 'axios'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {Multiselect} from 'multiselect-react-dropdown'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {useQueryResponse} from '../../../../app/modules/apps/user-management/users-list/core/QueryResponseProvider'
import {height, style} from '@mui/system'

type Props = {
  Time: any
  SetTime: any
  customerId: any
  button?:any
  durationVideo?:string
}
const Time: React.FC<Props> = ({Time, SetTime, customerId,button,durationVideo}) => {
  const {isLoading} = useQueryResponse()
  

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  return (
    <>
      {/* begin::Filter Button */}
      <button
        className='btn border border-4 rounded rounded-circle p-1'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-start'
        data-kt-menu-flip='top-start'
        style={{color: 'white', fontFamily: 'Atma',borderColor:"white", width: '42px',height:'42px', zIndex: '99999',right:8,top:-7,position:"absolute" }}
      >
        {Time}
      </button>

      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div
        className='menu menu-sub menu-sub-dropdown '
        style={{marginLeft: '-15px', width: '42px', backgroundColor: 'transparent',  }}
        data-kt-menu='true'
      >
        <button
          className='mt-2 btn  rounded rounded-circle p-1'
          data-kt-menu-dismiss='true'
          style={{
            color: 'white',
            fontFamily: 'Atma',
            fontSize: '12px',
            height: '35px',
            backgroundColor: '#474761',
            zIndex: '99999 !important'
          }}
          onClick={() => (SetTime('Auto'),button())}
        >
          Auto
        </button>
        <button
          className='btn rounded rounded-circle p-1'
          data-kt-menu-dismiss='true'
          style={{color: 'white', fontFamily: 'Atma', backgroundColor: '#474761', height: '35px',zIndex: '99999 !important'}}
          onClick={() => (SetTime('10'),button())}
        >
          10
        </button>
        <button
          className='btn rounded rounded-circle p-1'
          data-kt-menu-dismiss='true'
          style={{color: 'white', fontFamily: 'Atma', backgroundColor: '#474761', height: '35px'}}
          onClick={() => (SetTime('15'),button())}
        >
          15
        </button>
        <button
          className='btn rounded rounded-circle p-1'
          data-kt-menu-dismiss='true'
          style={{color: 'white', fontFamily: 'Atma', backgroundColor: '#474761', height: '35px'}}
          onClick={() => (SetTime('25'),button())}
        >
          25
        </button>
        <button
          className='btn rounded rounded-circle p-1'
          data-kt-menu-dismiss='true'
          style={{color: 'white', fontFamily: 'Atma', backgroundColor: '#474761', height: '35px'}}
          onClick={() => (SetTime('40'),button())}
        >
          40
        </button>
        <button
          className='btn rounded rounded-circle p-1'
          data-kt-menu-dismiss='true'
          style={{color: 'white', fontFamily: 'Atma', backgroundColor: '#474761', height: '35px'}}
          onClick={() => (SetTime('60'),button())}
        >
          60
        </button>
        <button
          className='btn rounded rounded-circle p-1'
          data-kt-menu-dismiss='true'
          style={{color: 'white', fontFamily: 'Atma', backgroundColor: '#474761', height: '35px'}}
          onClick={() => (SetTime('90'),button())}
        >
          90
        </button>
        <button
          className='btn rounded rounded-circle p-1'
          data-kt-menu-dismiss='true'
          style={{color: 'white', fontFamily: 'Atma', backgroundColor: '#474761', height: '35px'}}
          onClick={() => (SetTime('120'),button())}
        >
          120
        </button>
        <button
          className='btn rounded rounded-circle p-1'
          data-kt-menu-dismiss='true'
          style={{color: 'white', fontFamily: 'Atma', backgroundColor: '#474761', height: '35px'}}
          onClick={() => (SetTime('180'),button())}
        >
          180
        </button>
        <button
          className='btn rounded rounded-circle p-1'
          data-kt-menu-dismiss='true'
          style={{color: 'white', fontFamily: 'Atma', backgroundColor: '#474761', height: '35px'}}
          onClick={() => (SetTime('300'),button())}
        >
          300
        </button>
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {Time}
