import zIndex from '@mui/material/styles/zIndex'
import axios from 'axios'
import React, {FC, useEffect, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {styled} from '@mui/material/styles'
import Switch, {SwitchProps} from '@mui/material/Switch'
import {Avatar, Box, Button, Grid, Paper, TextField, Typography} from '@mui/material'

type Props = {
  show: boolean
  setShow: (isUserLoading: boolean) => void
  journeyId: any
  journeyName: any
  socket?: any
  handleMessageChange: any
  handleSendMessage: any
  setMessages: any
  messages: any
  message: any
  journeyChatIsOn:any
  setJourneyChatIsOn:any
  journeyChatDisable:any
  journeyUnReadedMessage:any,
  setJourneyUnReadedMessage:any,
}
const Chat: FC<Props> = ({
  show,
  setShow,
  journeyId,
  journeyName,
  handleMessageChange,
  handleSendMessage,
  setMessages,
  messages,
  message,
  journeyChatIsOn,
  setJourneyChatIsOn,
  journeyUnReadedMessage,
  setJourneyUnReadedMessage,
  journeyChatDisable
}) => {
  const [user, setUser] = useState<any>('')
  const customerProfile = useSelector((state: any) => state.Login.customerProfile)
  useEffect(() => {
/*     const userDetailString: any = sessionStorage.getItem('customerProfile')
    const userDetail: any = JSON.parse(userDetailString) || null */
   
    if(customerProfile !== null){
      setUser(customerProfile?.userId)
    }
  }, [customerProfile])

  const handleClose = () => {
    if(messages.length > 0){
      readMessage(messages[messages.length - 1].timeStamp)
    }
    setShow(false)
    setMessages([])
  }

  const [oldMessage, setOldMessage] = useState<any>(false)

  const LoginState = useSelector((state: any) => state.Login)
  const defaultImage = toAbsoluteUrl('/media/avatars/blank.png')
 
  const [userList, setuserList] = useState<any>([])
  

  useEffect(() => {
    function test2() {
      axios
        .get(`${process.env.REACT_APP_API_URL}/chat/getChatUsersWithJourneyId/${journeyId}`, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
          },
        })
        .then((response) => {
          setuserList(response.data)
        })
    }
    if (journeyId !== -1 && show && journeyChatDisable === true) {
      test2()
    }
  }, [user, show])

  useEffect(() => {
   if(messages.length > 0){
    scrollToBottom()
   }
  }, [messages])
  

  async function getMessages() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/chat/getChatMessagesWithJourneyId/${journeyId}`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setOldMessage(response.data?.moreMessagesAvailable)
        setMessages(response.data?.messages)
        if(response.data?.messages.length > 0){
          readMessage(response.data?.messages[response.data?.messages.length - 1]?.timeStamp)
        }
        setTimeout(() => {
          scrollToBottom()
        }, 100);
        
      })
      
  }
  async function getOldMessages(timeStamp: any) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/chat/getChatMessagesWithJourneyId/${journeyId}?maxTimestamp=${timeStamp}`,
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
          },
        }
      )
      .then(async (response) => {
        // expected the setProducts to be filled with the return of this request
        setMessages((prevMessages: any) => [...response.data.messages, ...prevMessages])
        setOldMessage(response.data.moreMessagesAvailable)
        setTimeout(() => {
          scrollLatestNewMessage()
        }, 200)
        /* scrollToBottoms() */
      })
  }

  useEffect(() => {
    if (journeyId !== -1 && journeyId && show && journeyChatDisable === true) {
      getMessages()
    }
  }, [journeyId, show])

  //#region unreadMessage
  async function readMessage(timeStamp: any) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/chat/lastReadedMessage/${journeyId}?timeStamp=${timeStamp}`,
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
          },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setJourneyUnReadedMessage(true)
      }).catch((err) => {

      })
      setTimeout(() => {
        setJourneyUnReadedMessage(false)
      }, 1000);
  
  }

if(journeyChatIsOn){
  window.addEventListener('beforeunload', function (e) {
    // Tarayıcı kapatılmadan önce bir uyarı modalı aç
    e.preventDefault(); // Tarayıcı kapatma işlemini durdur
    messages.length > 0 && readMessage(messages[messages.length - 1].timeStamp)
  });
}

  //#endregion
  //#region chatStatus


  async function handleChatStatus() {
    const newChatStatus = !journeyChatIsOn // Mevcut chat durumunun tersini alın
    setJourneyChatIsOn(newChatStatus)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/changeJourneyChatStatus`,
        {
          journeyId: journeyId,
          chatIsOn: newChatStatus,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
          },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
      })
  }
//#endregion

  const messagesEndRef: any = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'})
  }
  const messagesLastRef = useRef<any>(null)
  function scrollLatestNewMessage() {
    messagesLastRef.current?.scrollIntoView({behavior: 'smooth'})
  }

  
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
  ))(({theme}) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms !important',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }))

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className='fa-solid fa-2x fa-comments' />
          </Modal.Title>
          <div className='d-flex justify-content-center w-100 text-center'>
            <td>
              <tr style={{color: '#80849b', fontWeight: 'bold'}}>Chat</tr>
              <tr style={{fontWeight: 'bold', textTransform: 'uppercase'}} className='text-dark'>
                {journeyName}
              </tr>
            </td>
          </div>
          <div
            style={{marginRight: '5px', marginLeft: '-60px', color: '#80849b'}}
            className='d-flex '
          >
            <div className='my-auto'>Off</div>
            <div>
              <IOSSwitch
                sx={{m: 1}}
                defaultChecked
                disabled={journeyChatDisable === false ? true : false}
                checked={journeyChatIsOn}
                onChange={handleChatStatus}
              />
            </div>
            <div className='my-auto'>On</div>
          </div>
          <br />
        </Modal.Header>
        {
          journeyChatIsOn &&
          <div className='d-flex ms-5 mt-2'>
          {userList?.avatarPaths?.map((e: any, i: number) => {
            if (i < 8) {
              return (
                <div className='rounded-circle bg-gray-200 d-flex' key={i}>
                  <Avatar 
                   style={{marginLeft: i != 0 ? '-15px' : '', zIndex: i % 2 == 0 ? 1 : 5,width:"40px",height:"40px"}}
                   src={userList?.avatarPaths?.length > 0 ? e : defaultImage}
                   onError={(e: any) => {
                     e.currentTarget.src = defaultImage
                   }}
                  />
                </div>
              )
            }
          })}
          {userList?.totalOtherUserslength !== 0 ? (
            <div
              style={{
                width: '40px',
                height: '40px',
                marginLeft: '-15px',
                zIndex: 20,
                color: '#80849b',
              }}
              className='rounded-circle bg-gray-200 d-flex align-items-center justify-content-center p-2 '
            >
              +{userList.totalOtherUserslength}
            </div>
          ) : null}
        </div>
        }
        {
          journeyChatIsOn &&
          <div style={{marginTop: '-3px'}} className='px-5'>
          <hr />
        </div>
        }

        <Modal.Body>
          <div className='card p-0'>
            <div
              className='w-100 p-0'
              style={{height: '500px', width: '%100', overflowY: 'scroll', overflowX: 'hidden'}}
            >
              {oldMessage ? (
                <Box display={'flex'} justifyContent={'center'}>
                  <Button variant='text' style={{textTransform:"none"}} onClick={() => getOldMessages(messages[0].timeStamp)}>
                    Previous Messages
                  </Button>
                </Box>
              ) :null}
              {messages?.length > 0 &&
                messages.map((e: any, i: number) => {
                  const sendedTime: any = new Date(e.sendedTime)
                  const year = sendedTime.getFullYear()
                  const month = String(sendedTime.getMonth() + 1).padStart(2, '0')
                  const day = String(sendedTime.getDate()).padStart(2, '0')
                  const hours = String(sendedTime.getHours()).padStart(2, '0')
                  const minutes = String(sendedTime.getMinutes()).padStart(2, '0')

                  return user === e.userId ? (
                    <Grid
                      container
                      direction={'column'}
                      padding={'5px'}
                      marginTop={i !== 0 ? '15px' : ''}
                    >
                      <Box display={'flex'} justifyContent={'flex-end'} alignItems={"center"}>
                        <Typography
                          color={'#6C757D'}
                          marginRight={2}
                          marginTop={'2px'}
                          variant='subtitle1'
                        >{`${day}/${month}/${year} ${hours}:${minutes}`}</Typography>
                        <Box marginRight={1} display={'flex'} alignItems={'center'}>
                          <Box
                            marginRight={1}
                            display={'flex'}
                            flexDirection={'column'}
                            textAlign={'end'}
                          >
                            <Typography
                              color={'#212529'}
                              fontWeight={'bold'}
                              variant='h6'
                            >{`${e.name} ${e.surname}`}</Typography>
                            <Typography color={'#6C757D'} fontWeight={'bold'} variant='subtitle1'>
                              {e.groups[0]}
                            </Typography>
                          </Box>

                          <Avatar src={e.avatarPath} />
                        </Box>
                      </Box>

                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                          padding: '5px',
                          marginTop: '5px',
                          background: '#EEF6FF',
                          borderRadius: '5px',
                        }}
                      >
                        <Typography
                          color={'#6C757D'}
                          style={{
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word',
                            hyphens: 'auto',
                            maxWidth: '430px',
                          }}
                          variant='subtitle1'
                        >
                          {e.message}
                        </Typography>
                      </Box>
                      {i === 18 && messages.length > 20 && <div ref={messagesLastRef} />}
                    </Grid>
                  ) : (
                    <Grid
                      container
                      direction={'column'}
                      padding={'5px'}
                      marginTop={i !== 0 ? '15px' : ''}
                    >
                      <Box display={'flex'} justifyContent={'start'} alignItems={"center"}>
                        <Box marginLeft={1} display={'flex'} alignItems={'center'}>
                          <Avatar src={e.avatarPath} />
                          <Box
                            marginLeft={1}
                            display={'flex'}
                            flexDirection={'column'}
                            textAlign={'start'}
                          >
                            <Typography
                              color={'#212529'}
                              fontWeight={'bold'}
                              variant='h6'
                            >{`${e.name} ${e.surname}`}</Typography>
                            <Typography color={'#6C757D'} fontWeight={'bold'} variant='subtitle1'>
                              {e?.groups && e.groups[0]}
                            </Typography>
                          </Box>
                        </Box>
                        <Typography
                          color={'#6C757D'}
                          marginLeft={2}
                          marginTop={'2px'}
                          variant='subtitle1'
                        >{`${day}/${month}/${year} ${hours}:${minutes}`}</Typography>
                      </Box>

                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          padding: '5px',
                          marginTop: '5px',
                          background: '#F8F5FF',
                          borderRadius: '5px',
                        }}
                      >
                        <Typography
                          color={'#6C757D'}
                          style={{
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word',
                            hyphens: 'auto',
                            maxWidth: '430px',
                          }}
                          variant='subtitle1'
                        >
                          {e.message}
                        </Typography>
                      </Box>
                      {i === 18 && messages.length > 20 && <div ref={messagesLastRef} />}
                    </Grid>
                  )
                })}
              <div ref={messagesEndRef} />
            </div>
            <div>
              <TextField
                placeholder='Type a message'
                style={{resize: 'none', height: '80px', marginTop: '5px'}}
                className='w-100 form-control'
                rows={2}
                multiline
                autoFocus
                onChange={(e:any) => {
                    handleMessageChange(e)
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault(); // Enter tuşunun varsayılan davranışını engelle
                    handleSendMessage();
                    setTimeout(() => {
                      scrollToBottom(); 
                    }, 200);
                  }
                }}
                value={message}
                disabled={journeyChatIsOn ? false : true}
              />
              <Button
                 disabled={journeyChatIsOn ? message.length < 1 : true}
                 className=' float-end'
                 variant='contained'
                onClick={
                  () =>( handleSendMessage(),scrollToBottom())
                }
              >
                Send
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Chat
