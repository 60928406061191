import {useIntl} from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import GroupList from './GroupList'
import {Typography} from '@mui/material'
// import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const GroupBreadcrumbs: Array<PageLink> = [
  {
    title: 'User List',
    // path: '/apps/user-management/users',
    path: '/CustomerDashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function GrouprPage() {
  const intl = useIntl()

  return (
    <div>
      <>
        {/*   <PageTitle breadcrumbs={GroupBreadcrumbs}>{intl.formatMessage({id: 'Group.List'})}</PageTitle> */}
        <div
          style={{
            display: 'flex',
            height: 40,
            width: '80%',
           /*  marginBottom: '10px', */
            marginTop: '-20px',
            cursor: 'default',
            pointerEvents: 'none', // Tıklama olaylarını engeller
            userSelect: 'none',
          }}
        >
          <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
            Manage - {intl.formatMessage({id: 'Group.List'})}
          </Typography>
        </div>
        <GroupList />
      </>
    </div>
  )
}
export default GrouprPage
