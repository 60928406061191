import { useState } from 'react';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'

const CustomerBreadcrumbs: Array<PageLink> = [
  {
    title: 'Admin Roles',
    // path: '/apps/user-management/users',
    path: '/administration',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const Roles = [
  [ {
     "isChecked": false,
     "id": "SystemAdmin",
     "transactionName":"Transaction1"
   },
   {
    "isChecked": true,
     "id": "SystemAdmin",
     "transactionName":"Transaction2"
   },
   {
    "isChecked": false,
     "id": "SystemAdmin",
     "transactionName":"Transaction3"
   },
    {
    "isChecked": true,
     "id": "SystemAdmin",
     "transactionName":"Transaction4"
   }],
   [{
     "isChecked": true,
     "id": "MasterAdmin",
     "transactionName":"Transaction1"
   },
   {
 "isChecked": true,
     "id": "MasterAdmin",
     "transactionName":"Transaction2"
   },
   {
     "isChecked": true,
     "id": "MasterAdmin",
     "transactionName":"Transaction3"
   },
    {
    "isChecked": true,
     "id": "MasterAdmin",
     "transactionName":"Transaction4"
   }],
   [{
     "isChecked": true,
     "id": "Trainer",
     "transactionName":"Transaction1"
   },
   {
     "isChecked": true,
     "id": "Trainer",
     "transactionName":"Transaction2"
   },
   {
    "isChecked": true,
     "id": "Trainer",
     "transactionName":"Transaction3"
   },
    {
     "isChecked": true,
     "id": "Trainer",
     "transactionName":"Transaction4"
   }],
  [ {
     "isChecked": true,
     "id": "Editör",
     "transactionName":"Transaction1"
   },
   {
     "isChecked": true,
     "id": "Editör",
     "transactionName":"Transaction2"
   },
   {
     "isChecked": false,
     "id": "Editör",
     "transactionName":"Transaction3"
   },
    {
     "isChecked": false,
     "id": "Editör",
     "transactionName":"Transaction4"
   }]
 ]


const  RolesPage = () => {
  
  const [expense, setExpense] = useState(Roles)

  const addColumn = () => {
    setExpense((Roles) => [
      ...Roles,[
      {
        isChecked: false,
        id: "5",
        transactionName: "Transaction1"
      },{
        isChecked: false,
        id: "5",
        transactionName: "Transaction2"
      },{
        isChecked: false,
        id: "5",
        transactionName: "Transaction3"
      },
      {
        isChecked: false,
        id: "5",
        transactionName: "Transaction4"
      }
    ],]);
  };
  return (
        <div>
 
            <>
              <PageTitle breadcrumbs={CustomerBreadcrumbs}>Customers</PageTitle>
              <div className='card'>
            <div className='m-5'>
            <button className='btn btn-sm btn-secondary float-end' onClick={addColumn}><span className='h1'>+</span></button>

            <table className="table ">
                <thead>
                    <tr className='border-bottom border-dark'>
                    <th scope="col"> </th>
                    {expense.map((itemTop,i)=>(
                      <th scope="col">{itemTop[i].id}</th>
                    ))}
                    {/* <th scope="col">System Admin</th>
                    <th scope="col">Master Admin</th>
                    <th scope="col">Trainer</th>
                    <th scope="col">Editör</th> */}
                    </tr>
                </thead>
                <tbody>
                <tr className='border-bottom'></tr>
             {
              expense.map((item,i)=>
              (
                <tr className='border-bottom'>
                    <th scope="row">{item[i].transactionName}</th>
                    {expense[i].map((innerItem)=>(
                    <td><input type="checkbox" id={innerItem.id}  className="form-check-input" checked={innerItem.isChecked}/></td>
                    ))}
                </tr>
                    )
              )}
             </tbody>
            </table>
            </div>
        </div>
                  
             
        
            </>

  </div>
  )
}
export default RolesPage;
