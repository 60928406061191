/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
// import {KTSVG, toAbsoluteUrl} from '../../../helpers'


type Props = {
  className: string
}

const LastContentListWidget: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>New Contents</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-50px'>Type</th>
                <th className='min-w-120px'>Content</th>
                <th className='min-w-50px'>Category</th>
                <th className='min-w-40px'>Editor</th>
                <th className='min-w-50px'>Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <div className='rounded d-flex align-items-center bg-question text-white' style={{minHeight:'40px'}}>
                    <span className='text-center mx-auto'>Question</span>
                  </div>
                </td>
                
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Etkili iletişim kurmak, bu iki sorunun cevabının birbirine çok yakın hale
                    getirmeye çalışmaktır. Sizce bu bilgilerden hangisi doğrudur?
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    İLETİŞİM
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Merve Küçükşahin
                  </a>
                  <span className='text-muted fw-semobold text-muted d-block fs-7'>12/03/2022</span>
                </td>
                <td>
                <div className="badge badge-light-success fw-bold">Active</div>
                </td>
              </tr>
              <tr>
              <td>
                <div className='rounded d-flex align-items-center bg-knowledgecard text-white' style={{minHeight:'40px'}}>
                  <span className='text-center mx-auto'>Hafıza Kartı</span>
                </div>
              </td>
                
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Etkili iletişim kurmak, bu iki sorunun cevabının birbirine çok yakın hale
                    getirmeye çalışmaktır. Sizce bu bilgilerden hangisi doğrudur?
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    İLETİŞİM
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Kaan Gürbüz
                  </a>
                  <span className='text-muted fw-semobold text-muted d-block fs-7'>12/03/2022</span>
                </td>
                <td>
                <div className="badge badge-light-danger fw-bold">Erased</div>
                </td>
              </tr>
              <tr>
              <td>
                  <div className='rounded d-flex align-items-center bg-memorycard text-white' style={{minHeight:'40px'}}>
                    <span className='text-center mx-auto'>Bilgi Kartı</span>
                  </div>
                </td>
                
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Etkili iletişim kurmak, bu iki sorunun cevabının birbirine çok yakın hale
                    getirmeye çalışmaktır. Sizce bu bilgilerden hangisi doğrudur?
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    MANTIK
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Berkant Ersoy
                  </a>
                  <span className='text-muted fw-semobold text-muted d-block fs-7'>10/03/2022</span>
                </td>
                <td>
                <div className="badge badge-light-warning fw-bold">Passive</div>
                </td>
              </tr>
              <tr>
              <td>
                  <div className='rounded d-flex align-items-center bg-video text-white' style={{minHeight:'40px'}}>
                    <span className='text-center mx-auto'>Video</span>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Etkili iletişim kurmak, bu iki sorunun cevabının birbirine çok yakın hale
                    getirmeye çalışmaktır. Sizce bu bilgilerden hangisi doğrudur?
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    EMPATİ
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Mehmet Akgün
                  </a>
                  <span className='text-muted fw-semobold text-muted d-block fs-7'>10/03/2022</span>
                </td>
                <td>
                <div className="badge badge-light-success fw-bold">Active</div>
                </td>
              </tr>
              <tr>
              
              <td>
                  <div className='rounded d-flex align-items-center bg-question text-white' style={{minHeight:'40px'}}>
                    <span className='text-center mx-auto'>Question</span>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Etkili iletişim kurmak, bu iki sorunun cevabının birbirine çok yakın hale
                    getirmeye çalışmaktır. Sizce bu bilgilerden hangisi doğrudur?
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    EMPATİ
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Uhud Durmuş
                  </a>
                  <span className='text-muted fw-semobold text-muted d-block fs-7'>09/03/2022</span>
                </td>
                <td>
                <div className="badge badge-light-success fw-bold">Active</div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {LastContentListWidget}
