import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { styled } from '@mui/material/styles'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { enqueueSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SelectImageCustomer from './SelectImageCustomer'
import timeZones from './timeZones.json'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import PhoneInput from 'react-phone-input-2'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))
// blob image
/* function DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI?.split(',')
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0]?.split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], {type: mimeString})
  }
 */
export default function AlertDialog(props: any) {
  const {
    openAddCustomerModal,
    setOpenAddCustomerModal,
    setOpenEditCustomerModal,
    openEditCustomerModal,
    plans,
    products,
    first,
    setFirst,
    roleIsValidBasicInfoEdit,
  } = props
  const LoginState = useSelector((state: any) => state.Login)

  useEffect(() => {
    if (openAddCustomerModal === false) {
      clearState()
    } else {
      changePassword()
    }
  }, [openAddCustomerModal, openEditCustomerModal])

  function clearState() {
    setCompulsoryMessage('')
    setName('')
    setSelectPlan('')
    setErrorName('')
    setCompulsory(false)
    setErrors({ name: '', surname: '', email: '' })
    setMasterDetail({ name: '', surname: '', email: '' })
    setPhoneNumber('')
  }

  const handleClose = () => {
    openAddCustomerModal && setOpenAddCustomerModal(false)
    openEditCustomerModal && setOpenEditCustomerModal(false)
    setEditModalChanges(false)
    setPasswordChanges(false)
    setTimeout(() => {
      setPasswordChangesYes(false)
    }, 1000)
  }

  const [name, setName] = useState('')
  const [sendNameCheck, setSendNameCheck] = useState(false)
  const [nameLoading, setNameLoading] = useState(false)
  const [selectPlan, setSelectPlan] = useState<any>('')
  const [timeZone, setTimeZone] = useState<any>('')
  const [compulsory, setCompulsory] = useState<any>(false)
  const [errorName, setErrorName] = useState('')
  const [defaultPassword, setDefaultPassword] = useState<number>(-1)
  const [passwordChanges, setPasswordChanges] = useState<any>(false)
  const [loading, setLoading] = useState(false)
  const [timeoutId, setTimeoutId] = useState<number | null>(null)

  useEffect(() => {
    if (compulsory === false) {
      setCompulsoryMessage('')
    }
  }, [compulsory])

  function changePassword() {
    const randomPassword = Math.floor(Math.random() * 900000) + 100000
    setDefaultPassword(randomPassword)
  }

  const validateName = (value: any) => {
    if (value.length < 3) {
      setErrorName('Min 3 - Max 24 character')
    } else {
      setErrorName('')
    }
  }
  const handleNameChange = (e: any) => {
    setName(e.target.value.toUpperCase())
    setErrorName('')
    setEditModalChanges(true)
    if (e.target.value.toUpperCase().length > 2) {
      setNameLoading(true)
      setSendNameCheck(false)
      if (timeoutId) {
        clearTimeout(timeoutId) // Önceki zamanlayıcıyı iptal et
      }

      const newTimeoutId: any = setTimeout(() => {
        setNameLoading(false)
        customerNameCheck(e.target.value.toUpperCase())
      }, 2000)

      setTimeoutId(newTimeoutId) // Yeni zamanlayıcıyı kaydet
    }
  }

  //warn modal start
  const [openWarnModal, setOpenWarnModal] = useState<any>(false)
  const [warnModalTitle, setWarnModalTitle] = useState<any>('')
  const [warnModalMessage, setWarnModalMessage] = useState<any>('')
  const [warnModalType, setWarnModalType] = useState<any>('WARNING')
  const [warnModalIcon, setWarnModalIcon] = useState<any>('')
  const [editModalChanges, setEditModalChanges] = useState<any>(false)

  useEffect(() => {
    openWarnModal && setWarnModalMessageByType(warnModalType)
  }, [warnModalType])

  function handleClickWarnModalClose() {
    setOpenWarnModal(false)
  }

  async function customerNameCheck(name: any) {
    if (name.length > 2) {
      setNameLoading(true)
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/customer/addCustomerNameCheck`,
          { title: name },
          {
            headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
          }
        )
        .then((response) => {
          if (response.data === true) {
            setSendNameCheck(true)
          } else {
            setSendNameCheck(false)
            setErrorName('This name is already taken')
          }
          setNameLoading(false)
        })
        .catch((err) => {
          setSendNameCheck(false)
          setNameLoading(false)
        })
    }
  }
  const [compulsoryMessage, setCompulsoryMessage] = useState('')
  const [errorMessageCompulsory, setErrorMessageCompulsory] = useState('')

  const handleBlurCompulsory = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.length < 250) {
      setErrorMessageCompulsory('Min 250 characters required')
    } else {
      setErrorMessageCompulsory('')
    }
  }
  const handleCompulsoryMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompulsoryMessage(e.target.value)
    setErrorMessageCompulsory('')
    setEditModalChanges(true)
  }

  function setWarnModalMessageByType(warnModalType: any) {
    let message: any = ''
    let title: any = ''
    let icon: any = ''

    if (warnModalType === 'WARNING') {
      message = (
        <>
          <Typography color='white'>
            You have unsaved changes. Do you want to discard, anyway?
          </Typography>
        </>
      )
      title = 'WARNING'
      icon = <WarningAmberOutlinedIcon fontSize='large' />
    }
    if (warnModalType === 'PASSWORD') {
      message = (
        <>
          <Typography color='white'>
            Warning. This is the default password for all users to login Mobixa App only. It is NOT
            for Admin users!
            <br />
            From now on, only new users and the users who’s password has been reset by the manager
            will use this password to login Mobixa App. This operation does NOT affect the current
            users. <br /> Proceed?
          </Typography>
        </>
      )
      title = 'WARNING'
      icon = <WarningAmberOutlinedIcon fontSize='large' />
    }
    setWarnModalTitle(title)
    setWarnModalMessage(message)
    setWarnModalIcon(icon)
    setWarnModalType(warnModalType)
  }
  //warn modal end

  useEffect(() => {
    if (openEditCustomerModal) {
      setName(products?.title)
      setSelectPlan(products?.subscriptionId)
      setDefaultPassword(parseInt(products?.defaultUserPassword))
      setCompulsory(products?.GDPRStatement)
      setCompulsoryMessage(products?.GDPRText)
      setMediaCard({ mediaUrl: products?.avatarPath, file: '' })
      setTimeZone(products?.timezone)
      setEditModalChanges(false)
      setMasterDetail({
        name: products?.masterAdmin.name,
        surname: products?.masterAdmin.surname,
        email: products?.masterAdmin.email,
      })
      setPhoneNumber(products?.masterAdmin.phone)
    }
  }, [openEditCustomerModal])
  const [passwordChangesYes, setPasswordChangesYes] = useState(false)

  //#region Media
  const [selectImageModal, setSelectImageModal] = useState<any>(false)
  const [mediaCard, setMediaCard] = useState<any>({
    mediaUrl: '',
    file: false,
  })

  async function blobToBase64(blob: any) {
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader()
      reader.onload = () => {
        const base64String: any = reader.result/* .split(',')[1] */
        resolve(base64String)
      }
      reader.onerror = (error: any) => {
        reject(error)
      }
      reader.readAsDataURL(blob)
    })
  }

  const changeFile = async (value: any, url: any) => {
    const newCard = { ...mediaCard }
    /* newCard.file = value */
    const base64String = await blobToBase64(value)

    newCard.mediaUrl = url
    // Base64 veriyi yeni kartınıza ekleyin

    newCard.file = base64String
    setMediaCard(newCard)
    setEditModalChanges(true)
  }

  const changeMediaUrl = (value: string) => {
    /*  const newCard = {...mediaCard}
    newCard.mediaUrl = value
    setMediaCard(newCard) */
  }
  //#endregion

  const [masterDetail, setMasterDetail] = useState({ name: '', surname: '', email: '' })
  const [errors, setErrors] = useState({ name: '', surname: '', email: '' })
  const [phoneNumber, setPhoneNumber] = useState<any>('')
  /*  console.log('masterDetail: ', masterDetail) */

  function handleSave() {
    setLoading(true)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/customer/addCustomer`,
        {
          title: name,
          /*         plan: selectPlan, */
          GDPRStatement: compulsory || false,
          GDPRText: compulsoryMessage || null,
          defaultPassword: defaultPassword,
          timeZone: timeZone,
          file:
            mediaCard.mediaUrl == '/media/stock/image.png' || mediaCard.mediaUrl === ''
              ? null
              : mediaCard.file,
          masterAdminName: masterDetail.name,
          masterAdminSurname: masterDetail.surname,
          masterAdminEmail: masterDetail.email,
          masterAdminPhone: phoneNumber,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then(function (response) {
        setLoading(false)
        enqueueSnackbar('Successfully saved', { variant: 'success' })
        setFirst(!first)
        setTimeout(() => {
          handleClose()
        }, 1000);
      })
      .catch(function (err) {
        setLoading(false)
        err?.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  const handleEdit = async () => {
    /*    var bodyFormData = new FormData()
    bodyFormData.append('file', Image)
    bodyFormData.append('GDPRStatement', isCecked)
    bodyFormData.append('defaultUserPassword', value)
    bodyFormData.append('statusId', isCeckedStatu) */
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/customer/editCustomer/${products?.customerId}`,
        /* data: bodyFormData, */
        {
          title: name,
          /*        plan: selectPlan, */
          GDPRStatement: compulsory || false,
          GDPRText: compulsoryMessage || null,
          defaultPassword: defaultPassword,
          timeZone: timeZone,
          file:
            mediaCard.mediaUrl == '/media/stock/image.png' || mediaCard.mediaUrl === ''
              ? null
              : mediaCard.file,
          masterAdminName: masterDetail.name,
          masterAdminSurname: masterDetail.surname,
          masterAdminEmail: masterDetail.email,
          masterAdminPhone: phoneNumber,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        //handle success
        warnModalType === 'PASSWORD' ? enqueueSnackbar('Default password has been changed', { variant: 'success' }) :
          enqueueSnackbar('Successfully saved', { variant: 'success' })
        setTimeout(() => {
          setFirst(!first)
          handleClose()
        }, 1000)
      })
      .catch((err) => {
        //handle error
        err?.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }
  const [accordion, setAccordion] = useState({ gdpr: true, master: true })

  const validateForm = () => {
    let tempErrors = { name: '', surname: '', email: '' }
    let formIsValid = true
    if (
      masterDetail.name.length > 0 ||
      masterDetail.surname.length > 0 ||
      masterDetail.email.length > 0
    ) {
      // Adı kontrol et
      if (masterDetail.name.length < 1 && (masterDetail.surname || masterDetail.email)) {
        formIsValid = false
        tempErrors.name = 'Required field'
      } else {
        tempErrors.name = ''
      }

      // Soyadı kontrol et
      if (masterDetail.surname.length < 1 && (masterDetail.name || masterDetail.email)) {
        formIsValid = false
        tempErrors.surname = 'Required field'
      } else {
        tempErrors.surname = ''
      }

      // E-posta kontrol et
      if (!masterDetail.email && (masterDetail.name || masterDetail.surname)) {
        formIsValid = false
        tempErrors.email = 'Required field'
      } else if (
        masterDetail.email &&
        !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(masterDetail.email)
      ) {
        formIsValid = false
        tempErrors.email = 'Invalid email format'
      }
    } /* else{

      tempErrors = {name: '', surname: '', email: ''}
    } */

    /* console.log('tempErrors: ', tempErrors) */
    setErrors(tempErrors)
    return formIsValid
  }
  /*   console.log('errors: ', errors) */
  const handleSubmit = async (e) => {
    e.preventDefault()
    /*  console.log('validateForm(): ', validateForm()) */
    if (validateForm()) {
      /*  console.log('Form submitted', masterDetail) */
      setErrors({ ...errors, name: '', surname: '', email: '' })
      /*    await checkValidEmail(masterDetail.email) */
      // Form geçerliyse gönderme işlemini burada yap
    }
  }
  const [emailFirst, setEmailFirst] = useState('')
  const [emailLoading, setEmailLoading] = useState(false)

  const checkValidEmail = (email: any) => {
    setEmailLoading(false)
    setErrors({ ...errors, email: '' })
    emailFirst !== email && setEmailLoading(true)
    emailFirst !== email &&
      email.length > 0 &&
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/customer/checkMasterAdminEmail`,
          // '{\n  "email": "user@example.com",\n  "customerId": 0\n}',
          {
            email: email,
            customerId: openEditCustomerModal ? products?.customerId : null
          },
          {
            headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
          }
        )
        .then(function (response) {
          //handle success
          setEmailLoading(false)
          if (response.data === false) {
            if (products?.masterAdmin?.email !== masterDetail.email) {
              setErrors({ ...errors, name: '', surname: '', email: 'This email is already in use' })
            } else {
              setErrors({ ...errors, name: '', surname: '', email: '' })
            }
          }
        })
        .catch((error) => {
          setEmailLoading(false)
        })
  }
  const emailStatus = (e: any) => {
    if (e === 0) {
      return <Chip size='small' label='Blocked' variant='outlined' sx={{ fontWeight: 'bold' }} />
    }
    if (e === 1) {
      return <Chip size='small' label='Open' variant='outlined' sx={{ fontWeight: 'bold' }} />
    }
    if (e === 2) {
      return (
        <Tooltip
          arrow
          title={`Verification email has been sent on ${products?.emailVerificationSendDate} Click to resend.`}
          placement='right-end'
        >
          <Chip size='small' label='Pending' variant='outlined' sx={{ fontWeight: 'bold' }} />
        </Tooltip>
      )
    }
    if (e === 3) {
      return (
        <Tooltip
          arrow
          title={`${products?.emailConfirmedDate} Email adress has been confirmed by the user.`}
          placement='right-end'
        >
          <Chip size='small' label='Confirmed' variant='outlined' sx={{ fontWeight: 'bold' }} />
        </Tooltip>
      )
    }
  }
  /*  console.log('products: ', products) */
  const capitalizeWords = (str) => {
    return str.split(' ').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  };

  return (
    <div>
      <Dialog
        open={openAddCustomerModal || openEditCustomerModal}
        /* onClose={handleClose} */
        fullWidth
        sx={{ zIndex: 1 }}
        maxWidth='sm'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          id='alert-dialog-title'
        >
          <Box display={'flex'} alignItems={'center'}>
            <Typography fontSize={26} fontWeight={'bold'}>
              {openAddCustomerModal ? 'Add Customer' : 'Edit Customer'}
            </Typography>
            <Typography marginLeft={15} fontSize={20} fontWeight={'bold'}>
              {products?.customerId}
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={6} sm={6} display={'flex'} textAlign={'center'} alignItems={'center'}>
              <TextField
                id='outlined-basic'
                label='ORGANISATION'
                variant='outlined'
                fullWidth
                required
                disabled={roleIsValidBasicInfoEdit === true ? false : true}
                value={name}
                onChange={(e) => (handleNameChange(e), setEditModalChanges(true))}
                onBlur={() => validateName(name)}
                inputProps={{ maxLength: 24, style: { textTransform: 'uppercase' } }}
                error={Boolean(errorName)}
                helperText={errorName}
              />
              {nameLoading ? <CircularProgress color='primary' size={20} /> : null}
            </Grid>
            <Grid item xs={6} sm={6} container justifyContent={'center'}>
              {/*  <Avatar
                variant='rounded'
                style={{width: '150px', height: '50px'}}
                src={products?.avatarPath || '/broken-image.jpg'}
              /> */}
              <SelectImageCustomer
                setSelectImageModal={setSelectImageModal}
                selectImageModal={selectImageModal}
                mediaUrl={mediaCard.mediaUrl}
                changeMediaUrl={changeMediaUrl}
                changeFile={changeFile}
              />
            </Grid>
            <Grid item xs={6} sm={6} textAlign={'center'}>
              <TextField
                id='outlined-basic'
                label='Time-Zone'
                variant='outlined'
                required
                fullWidth
                disabled={roleIsValidBasicInfoEdit === true ? false : true}
                select
                value={timeZone}
                onChange={(e: any) => (setTimeZone(e.target.value), setEditModalChanges(true))}
              >
                {timeZones.map((option: any, i: number) => (
                  <MenuItem key={i} value={option.optionValue}>
                    {option.optionName}
                  </MenuItem>
                ))}
              </TextField>
              {/*  <TextField
                id='outlined-basic'
                label='Plan'
                variant='outlined'
                required
                fullWidth
                disabled={roleIsValidBasicInfoEdit === true ? false : true}
                select
                value={selectPlan}
                onChange={(e: any) => (setSelectPlan(e.target.value), setEditModalChanges(true))}
              >
                {plans.map((option: any) => (
                  <MenuItem key={option.subscriptionId} value={option.subscriptionId}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField> */}
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              display={'flex'}
              flexDirection={'column'}
              textAlign={'center'}
              alignItems={'center'}
              justifyContent={'center'}
              marginTop={'-5px'}
            >
              <Typography color={'#00000099'} fontWeight={'bold'}>
                Default Password <br /> For New Users
              </Typography>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '-5px',
                }}
              >
                <Typography color={'#D32F2F'} fontWeight={'bold'} fontSize={12}>
                  {defaultPassword}
                </Typography>
                <IconButton
                  aria-label='refresh'
                  onClick={() => (
                    changePassword(), setPasswordChanges(true), setEditModalChanges(true)
                  )}
                >
                  <RefreshIcon />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} textAlign={'center'}>

            </Grid>
            <Grid item xs={6}></Grid>

            <Grid item xs={6}></Grid>
            <Grid item xs={6}></Grid>
            <Grid xs={12}>
              <Accordion
                expanded={accordion.master}
                onClick={() => setAccordion({ ...accordion, master: !accordion.master })}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <Typography>Master Admin</Typography>
                </AccordionSummary>
                <AccordionDetails onClick={(e) => e.stopPropagation()}>
                  <Grid container>
                    <Grid xs={6}>
                      <form noValidate autoComplete='off' onBlur={handleSubmit}>
                        <TextField
                          fullWidth
                          id='name'
                          label='Name'
                          margin='normal'
                          value={masterDetail.name} // Bu değişkenlerin state'den alındığını varsayıyorum
                          onClick={(e) => (e.stopPropagation(), setEditModalChanges(true))}
                          onChange={(e) => {
                            const formattedValue = capitalizeWords(e.target.value);
                            setMasterDetail({ ...masterDetail, name: formattedValue })
                          }} // State güncelleme fonksiyonu
                          error={!!errors.name}
                          helperText={errors.name}
                        />
                        <TextField
                          fullWidth
                          id='surname'
                          label='Surname'
                          margin='normal'
                          value={masterDetail.surname}
                          onClick={(e) => (e.stopPropagation(), setEditModalChanges(true))}
                          onChange={(e) => {
                            const formattedValue = capitalizeWords(e.target.value);
                            setMasterDetail({ ...masterDetail, surname: formattedValue })
                          }
                          }
                          error={!!errors.surname}
                          helperText={errors.surname}
                        />
                        <TextField
                          fullWidth
                          id='email'
                          label='Email'
                          margin='normal'
                          value={masterDetail.email}
                          onClick={(e) => (e.stopPropagation(), setEditModalChanges(true))}
                          onChange={(e) =>
                            setMasterDetail({ ...masterDetail, email: e.target.value.toLowerCase() })
                          }
                          error={!!errors.email}
                          helperText={errors.email}
                          InputProps={{
                            endAdornment: emailLoading && (
                              <InputAdornment position='end'>
                                <CircularProgress size={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </form>
                      <Box marginTop={1}>
                        <PhoneInput
                          country={'tr'}
                          value={phoneNumber}
                          inputStyle={{
                            height: '51.69px',
                            width: '265.6px',
                          }}
                          onChange={(phone: any) => (setPhoneNumber(phone), setEditModalChanges(true))}
                          isValid={(value: any, country: any) => {
                            if (value.match(/12345/)) {
                              return 'Invalid value: ' + value + ', ' + country.name
                            } else if (value.match(/1234/)) {
                              return false
                            } else {
                              return true
                            }
                          }}
                          countryCodeEditable={false}
                          inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                          }}
                        />
                      </Box>
                    </Grid>
                    {openEditCustomerModal && (
                      <Grid xs={6} display={'flex'} alignItems={'end'} paddingBottom={10}>
                        {
                          products?.masterAdmin?.emailStatus &&
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginLeft: 20,
                            marginRight: 15,
                          }}
                          >

                            <Typography fontSize={12} color={'#ADB5BD'}>
                              Email Status
                            </Typography>
                            <Typography variant='caption' style={{ marginLeft: '8px' }}>
                              {emailStatus(products?.masterAdmin?.emailStatus)}
                            </Typography>
                          </div>
                        }


                        {products?.masterAdmin?.emailStatus === 2 && (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                              variant='text'
                              style={{ textDecoration: 'underline', textTransform: 'none' }}
                              color='primary'
                            >
                              Resend
                            </Button>
                          </div>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid xs={12} marginTop={1}>
              <Accordion
                expanded={accordion.gdpr}
                onClick={(e) => (
                  e.preventDefault(), setAccordion({ ...accordion, gdpr: !accordion.gdpr })
                )}
              >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                  <Typography>GDPR</Typography>
                </AccordionSummary>
                <AccordionDetails onClick={(e) => e.stopPropagation()}>
                  <Grid item xs={12} sm={12} textAlign={'center'}>
                    {/*  <Typography color={'#343A40'}>GDPR Statement</Typography> */}
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <Typography color={'#343A40'}>
                        Not <br /> compulsory
                      </Typography>
                      <Typography color={'#343A40'}>
                        <IOSSwitch
                          sx={{ m: 1 }}
                          value={compulsory}
                          checked={compulsory}
                          onClick={(e) => (
                            e.stopPropagation(),
                            setCompulsory(!compulsory),
                            setEditModalChanges(true)
                          )}
                        />
                      </Typography>
                      <Typography color={'#343A40'}>Compulsory</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} textAlign={'center'}>
                    {compulsory && (
                      <TextField
                        id='outlined-basic'
                        label=''
                        variant='outlined'
                        fullWidth
                        required
                        multiline
                        rows={11}
                        value={compulsoryMessage}
                        onClick={(e) => (e.stopPropagation(), setEditModalChanges(true))}
                        onChange={handleCompulsoryMessageChange}
                        onBlur={handleBlurCompulsory}
                        placeholder='Copy and paste your GDPR statement here. Min 250 character'
                        error={Boolean(errorMessageCompulsory)}
                        helperText={errorMessageCompulsory}
                      />
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {passwordChangesYes === true ? null : (
            <Button
              variant='contained'
              onClick={() =>
                editModalChanges
                  ? (setWarnModalMessageByType('WARNING'), setOpenWarnModal(true))
                  : handleClose()
              }
              color='inherit'
              style={{ marginRight: '10px' }}
            >
              DISCARD
            </Button>
          )}

          {openAddCustomerModal && (
            <Button
              disabled={
                errors.email !== '' ||
                errors.name !== '' ||
                errors.surname !== '' ||
                Boolean(errorName) ||
                /*                 selectPlan === '' || */
                nameLoading ||
                loading ||
                timeZone === '' ||
                (name.length < 3 && sendNameCheck === false) ||
                (compulsory === true &&
                  (compulsoryMessage?.length < 250 || errorMessageCompulsory !== ''))
              }
              onClick={() =>
                passwordChanges === true
                  ? (setWarnModalMessageByType('PASSWORD'), setOpenWarnModal(true))
                  : handleSave()
              }
              variant='contained'
              style={{ marginRight: '40px' }}
            >
              SAVE{' '}
              {loading ? (
                <CircularProgress color='inherit' size={20} style={{ marginLeft: '10px' }} />
              ) : null}
            </Button>
          )}

          {openEditCustomerModal && passwordChangesYes === false && (
            <Button
              disabled={
                errors.email !== '' ||
                errors.name !== '' ||
                errors.surname !== '' ||
                Boolean(errorName) ||
                /* selectPlan === '' || */
                nameLoading ||
                loading ||
                timeZone === '' ||
                editModalChanges === false ||
                (name.length < 3 && sendNameCheck === false) ||
                (compulsory === true &&
                  (compulsoryMessage?.length < 250 || errorMessageCompulsory !== ''))
              }
              onClick={() =>
                passwordChanges === true
                  ? (setWarnModalMessageByType('PASSWORD'), setOpenWarnModal(true))
                  : handleEdit()
              }
              variant='contained'
              style={{ marginRight: '40px' }}
            >
              SAVE
              {loading ? (
                <CircularProgress color='inherit' size={20} style={{ marginLeft: '10px' }} />
              ) : null}
            </Button>
          )}
          {openEditCustomerModal && passwordChangesYes === true && (
            <Button
              disabled={
                Boolean(errorName) ||
                /* selectPlan === '' || */
                nameLoading ||
                loading ||
                (name?.length < 3 && sendNameCheck === false) ||
                (compulsory === true &&
                  (compulsoryMessage.length < 250 || errorMessageCompulsory !== ''))
              }
              onClick={handleClose}
              variant='contained'
              style={{ marginRight: '40px' }}
            >
              DONE
              {loading ? (
                <CircularProgress color='inherit' size={20} style={{ marginLeft: '10px' }} />
              ) : null}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {
        //warn modal
      }
      <Dialog
        open={openWarnModal}
        TransitionComponent={Transition}
        keepMounted
        sx={{ zIndex: 2 }}
        PaperProps={{
          style: {
            backgroundColor: warnModalType === 'resetPassword' ? '#0288D1' : '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            {warnModalIcon} {warnModalTitle}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {warnModalMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (warnModalType === 'WARNING') {
                handleClickWarnModalClose()
                handleClose()
              }
              if (warnModalType === 'PASSWORD' && openEditCustomerModal) {
                handleEdit()
                setPasswordChangesYes(true)
                handleClickWarnModalClose()
              }
            }}
            style={{ color: 'white' }}
          >
            Yes
          </Button>

          <Button
            onClick={() => {
              if (warnModalType === 'PASSWORD') {
                setDefaultPassword(products?.defaultUserPassword)
                setPasswordChanges(false)
              }
              handleClickWarnModalClose()
            }}
            style={{ color: 'white' }}
          >
            <CloseIcon />
          </Button>

          {/* <CircularProgress color='primary' size={20} /> */}
        </DialogActions>
      </Dialog>
    </div>
  )
}
