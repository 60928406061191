import React, { FC, forwardRef, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { GameDashboardWidget } from '../../widgets/gDashboardWidget/GameDashboardWidget'
import { NewGamesWidget } from '../../widgets/gDashboardWidget/NewGamesWidget'
import { PopularGamesWidget } from '../../widgets/gDashboardWidget/PopularGamesWidget'
import { useSelector, useDispatch } from 'react-redux'
import VersionModal from '../../widgets/shared/Versionmodal'
import { ID, initialListView, KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Dropdown, Modal } from 'react-bootstrap'
import axios from 'axios'
import { spawn } from 'child_process'
import { Pagination } from '@mui/material'
import { FilterTableGroup } from './FilterTable'
import AddGroupModal from './AddGroupModal'
import EditGroupModal from './GroupEditModal'
import Loading from '../../../_metronic/layout/components/Loading'
import { enqueueSnackbar } from 'notistack'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CloseIcon from '@mui/icons-material/Close'
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridValidRowModel,
} from '@mui/x-data-grid-pro'
import { styled, lighten, darken } from '@mui/system'
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  DialogContentText,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Slide,
  Tooltip,
  Typography,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import AddEditGroupModal from './AddEditGroupModal'

type Props = {
  id?: ID
}

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const GroupList: FC<Props> = ({ id }) => {
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  const [selected1, setSelected1] = useState<Array<ID>>(initialListView.selected)
  const [expense, setExpense] = useState<any>([])
  const [showModal, setShowModal] = useState(false)
  const customerProfile = useSelector((state: any) => state.Login.customerProfile)

  const [userRole, setUserRole] = useState<any>([])
  useEffect(() => {

    let role = customerProfile?.role
    setUserRole(role)
  }, [])

  //Permission Start
  let roleId = [0, 1, 3]

  let roleIsValidGroupAddEdit = userRole?.some((role: any) => roleId.includes(role.roleId))

  //Permission end


  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  useEffect(() => { }, [selected1, isCheckAll])

  const handle = () => {
    if (show === true) {
      setShow(false)
    } else {
      setShow(true)
    }
  }

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll)
    setSelected(expense?.items?.map((li: any) => li.groupId))
    if (isCheckAll) {
      setSelected([])
    }
  }
  useEffect(() => {
    if (isCheckAll === true) {
      setselectedLenght(expense.total)
    }
    if (isCheckAll === false) {
      setselectedLenght(selected1.length)
    }
  }, [isCheckAll, selected1])
  const [sortOptions, setSortOptions] = useState<any>('')
  const [selectedGroup, setselectedGroup] = useState<any>(-1)
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState<any>(`page=${page}&size=15&`)
  const LoginState = useSelector((state: any) => state.Login)
  const [user, setuser] = useState<any>('')
  const [selectedLenght, setselectedLenght] = useState<any>('')

  const [filterOptions, setFilterOptions] = useState<any>({
    group: '',
    Explanation: '',
    nrofUsersS: '',
    nrofUsersE: '',
    EditDateS: '',
    EditDateE: '',
  })
  const [first, setfirst] = useState(true)
  useEffect(() => { }, [LoginState.loginData])
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    // ${pagination}${filterOptions.fullname}${filterOptions.email}${filterOptions.role}${filterOptions.JoinDS}${filterOptions.JoinDE}${filterOptions.Group}${filterOptions.StatusId}
    setisLoading(true)
    setPagination(`page=${page}&size=15&`)

    const ax = axios.create({ headers: { Accept: 'application/json' } })
    async function test2() {
      let customerId = customerProfile.customerId
      setuser(customerId)
      /* const res = await ax
        .get(`${process.env.REACT_APP_API_URL}/user/getUserWithJWT`, {
          headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
        })
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setuser(response.data.customerId)
        }) */
    }
    if (user == '') {
      test2()
    }
  }, [pagination, page, user, filterOptions, sortOptions])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }
  const [i, setI] = useState(false)
  const [arrow, setArrow] = useState({
    row: 0,
    sort: '',
  })
  const arrowF = (e: any) => {
    if (arrow.row === e) {
      return <span>{arrow.sort}</span>
    }
  }
  const intl = useIntl()
  const refreshButton = () => {
    if (sortOptions.length > 1) {
      return (
        <button
          className='btn svg-icon  svg-icon-2x'
          style={{ marginTop: '-10px', marginRight: '-20px' }}
          onClick={() => {
            setFilterOptions({
              group: '',
              Explanation: '',
              nrofUsersS: '',
              nrofUsersE: '',
              EditDateS: '',
              EditDateE: '',
            })
            setArrow({
              row: 0,
              sort: '',
            })
            setSortOptions('')
            setI(false)
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='27.644'
            height='27.644'
            viewBox='0 0 27.644 27.644'
          >
            <path
              id='refresh-circle'
              d='M61.822,48A13.822,13.822,0,1,0,75.644,61.822,13.838,13.838,0,0,0,61.822,48Zm0,20.8a6.246,6.246,0,1,1,0-12.493h.28l-.938-.937a.93.93,0,1,1,1.316-1.316l2.658,2.658a.93.93,0,0,1,0,1.316L62.48,60.685a.93.93,0,0,1-1.316-1.316l1.2-1.2c-.158-.007-.339-.007-.538-.007a4.386,4.386,0,1,0,4.386,4.386.93.93,0,1,1,1.861,0A6.254,6.254,0,0,1,61.822,68.8Z'
              transform='translate(-48 -48)'
              fill='#7e8299'
            />
          </svg>
        </button>
      )
    } else {
      return <></>
    }
  }
  const [showStatu, setShowStatu] = useState(false)

  const handleClose = () => setShowStatu(false)

  const handleShow = (e: any) => {
    setShowStatu(true)
    if (isCheckAll === true) {
      setselectedLenght(expense.total)
    }
    if (isCheckAll === false) {
      setselectedLenght(selected.length)
    }
  }
  function handleDelete() {
    if (isCheckAll === false) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/user/groupDelete`, selected, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          enqueueSnackbar('Saved is success', { variant: 'success' })
          setSelected([])
          handleClose()
          setfirst(!first)
        }).catch((err) => {
          err.response?.data?.detail.errorMessage
            ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
            : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              { variant: 'error' }
            )
        })
    }
  }
  const [hover, sethover] = useState('')
  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem('modalGroup') != undefined) {
        if (localStorage.getItem('modalGroup') != '-1') {
          let data: any = localStorage.getItem('modalGroup')
          setselectedGroup(JSON.parse(data))
          setShowModal(true)
        }
      }
      localStorage.setItem('modalGroup', '-1')
    }, 2000)
  }, [])

  //Yeni Alan
  const [groupListLoading, setGroupListLoading] = useState<boolean>(false)
  const [groupList, setGroupList] = useState<any>([])
  useEffect(() => {
    function test() {
      setGroupListLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/user/getGroupListNoPagination`, {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        })
        .then((response) => {
          setGroupList(response.data)
          setGroupListLoading(false)
        })
        .catch((err) => {
          setGroupListLoading(false)
        })
    }
    test()
  }, [first])

  //States Datagridpro start
  const [rows, setRows] = useState<any>([])
  const [rowCount, setRowCount] = useState(0)
  const [loadingExport, setLoadingExport] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [options, setOptions] = useState<any>({ sortModel: [{ field: "modifiedDate", sort: "desc" }] })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    InternalEmployeeId: false,
  })
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [statusLoading, setStatusLoading] = useState(false)
  const [customerStatus, setCustomerStatus] = useState(-1)
  const [selectCategoryDataGrid, setSelectCategoryDataGrid] = useState<number>(0)
  const [selected, setSelected] = useState<any>([])
  const [selectedCategoryQuestion, setSelectedCategoryQuestion] = useState<any>([])
  const [createTest, setCreateTest] = useState<any>({
    testType: 0,
    title: '',
    categoryList: [],
    timeLimit: '',
  })
  useEffect(() => {
    getGroupList()
  }, [options, paginationModel, columnVisibilityModel, first])

  async function getGroupList() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/getGroupList?page=${paginationModel.page + 1}&size=${paginationModel.pageSize
        }`,
        {
          options,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        const formattedRows = response.data.items.map((row: any) => ({
          groupId: row.groupId,
          name: row.name,
          explanation: row.explanation,
          modifiedName: row.modifiedName,
          modifiedDate: row.modifiedDate,
          numberOfUsers: row.numberOfUsers,
        }))

        setRows(formattedRows)
        setRowCount(response.data.total)
        setStatusLoading(false)

        setExpense(response.data)
        setisLoading(false)
      })
      .catch((err) => {
        setisLoading(false)
      })
  }

  //States Datagridpro end
  // function datagridpro start
  const columns: any = [
    {
      field: 'groupId',
      headerName: 'ID',
      width: 60,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value?.toFixed(0),
    },
    {
      field: 'name',
      headerName: 'GROUP',
      width: 150,
      type: 'singleSelect',
      valueOptions: groupList.map((category: any) => ({
        value: category.groupId,
        label: category.groupName,
      })),
      renderCell: (params: any) => params.value,
    },
    {
      field: 'explanation',
      headerName: 'EXPLANATION',
      width: 300,
    },
    {
      field: 'numberOfUsers',
      headerName: 'NR OF USERS',
      width: 150,
      filterable: false,
      type: 'number',
      align: 'center',
    },
    {
      field: 'modifiedName',
      headerName: 'EDITED BY',
      width: 150,

      type: 'singleSelect',
    },
    {
      field: 'modifiedDate',
      headerName: 'LAST EDITED DATE',
      width: 170,
      type: 'dateTime',
      valueGetter: ({ value }: { value: Date }) => value && new Date(value),
    },
  ]

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <LoadingButton
          size='small'
          onClick={downloadExcel}
          endIcon={<FileDownloadIcon />}
          loading={loadingExport}
          loadingPosition='end'
          variant='outlined'
        >
          <span>Export</span>
        </LoadingButton>
      </GridToolbarContainer>
    )
  }

  async function downloadExcel() {
    setLoadingExport(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/getGroupList?isExcel=1`,
        {
          options,
          columnVisibilityModel,
        },
        {
          responseType: 'blob',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const excelBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const excelUrl = URL.createObjectURL(excelBlob)

        const link = document.createElement('a')
        link.href = excelUrl
        link.download = 'groupList.xlsx'
        link.click()
        URL.revokeObjectURL(excelUrl)
        setLoadingExport(false)
      })
      .catch((err) => {
        setLoadingExport(false)
      })
    //frontend excel download
    /*     const newData = rows.map((row: any) => {
      delete row.tableData
      return row
    })
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, 'row')
    //Buffer
    XLSX.write(workBook, {bookType: 'xlsx', type: 'buffer'})
    //Binary string
    XLSX.write(workBook, {bookType: 'xlsx', type: 'binary'})
    //Download
    XLSX.writeFile(workBook, 'Veri Listesi.xlsx') */
  }
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
      return
    } */

    setOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelected(newSelectionModel)
    // SelectedCategoryQuestion update and add
  }

  // function datagridpro start
  const handleCellOneClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin

    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }
    const newSelectionModel = [...selected]; // Mevcut seçili satırların bir kopyasını oluşturun
    const selectionIndex = newSelectionModel.indexOf(params.id);

    if (selectionIndex === -1) {
      // Eğer tıklanan hücrenin ID'si şu anda seçili değilse, seçime ekleyin
      newSelectionModel.push(params.id);
    } else {
      // Eğer tıklanan hücrenin ID'si zaten seçiliyse, seçimden kaldırın
      newSelectionModel.splice(selectionIndex, 1);
    }

    setSelected(newSelectionModel);

  }

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {


    setselectedGroup(params.row)
    setShowModal(true)
  }

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ ml: 1, mr: 1 }}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }
  const [hoverButtonEditGroup, setHoverButtonEditGroup] = useState(false)
  const [hoverButtonDeleteGroup, setHoverButtonDeleteGroup] = useState(false)
  const [hoverButtonCreateGroup, setHoverButtonCreateGroup] = useState(false)
  const [addGroupOpen, setAddGroupOpen] = useState(false)

  return (
    <>
      <Modal
        show={showStatu}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header className='bg-light-danger border-0' closeButton>
          <Modal.Title id='contained-modal-title-vcenter' className='text-danger '>
            Warning !
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-light-danger border-0 text-daanger'>
          <span className='text-danger'>
            {selectedLenght} {intl.formatMessage({ id: 'Group.Delete.Error' })}
          </span>
        </Modal.Body>
        <Modal.Footer className='bg-light-danger border-0 text-daanger'>
          <button className='btn' onClick={handleClose}>
            {intl.formatMessage({ id: 'No' })}
          </button>
          <button style={{ width: '136px' }} className='btn btn-danger' onClick={handleDelete}>
            {intl.formatMessage({ id: 'Yes' })}
          </button>
        </Modal.Footer>
      </Modal>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='border-0'>
            {/* begin::Card toolbar */}
            <div className='w-50 float-end'>
              <div
                style={{ marginTop: '-60px', marginRight: 80 }}
                className='d-flex justify-content-end align-items-center'
              >
                <Box
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                  alignContent='center'
                  marginLeft={2}
                  sx={{ height: 50, /* marginTop: '-10px' */ }}
                >
                  {
                    roleIsValidGroupAddEdit &&
                    <Tooltip
                      arrow
                      title='Create Group'
                      placement='top'
                      onMouseEnter={() => {
                        setHoverButtonCreateGroup(true)
                      }}
                      onMouseLeave={() => {
                        setHoverButtonCreateGroup(false)
                      }}
                      style={{ background: hoverButtonCreateGroup ? '#D5D5D5 ' : 'white' }}
                    >
                      <IconButton
                        aria-label='create'
                        onClick={() => setAddGroupOpen(true)}
                        size='small'
                        sx={{ background: 'white' /* , marginRight: '7px' */ }}
                      >
                        <AddIcon
                          sx={{ fontSize: '30px', color: hoverButtonCreateGroup ? 'black' : '' }}
                        />
                      </IconButton>
                    </Tooltip>
                  }

                </Box>
                {/* <AddGroupModal
                  show={addGroupOpen}
                  setShow={setAddGroupOpen}
                  setfirst={setfirst}
                  first={first}
                  companyId={user}
                /> */}
                <AddEditGroupModal
                  open={addGroupOpen}
                  setOpen={setAddGroupOpen}
                  setFirst={setfirst}
                  first={first}
                  openEdit={showModal}
                  setOpenEdit={setShowModal}
                  groupDetail={selectedGroup}
                />
                <Box
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                  alignContent='center'
                  marginLeft={2}
                  sx={{ height: 50, /* marginTop: '-10px' */ }}
                >
                  {selected.length === 1 && roleIsValidGroupAddEdit && (
                    <Tooltip
                      arrow
                      title='Edit Group'
                      placement='top'
                      onMouseEnter={() => {
                        setHoverButtonEditGroup(true)
                      }}
                      onMouseLeave={() => {
                        setHoverButtonEditGroup(false)
                      }}
                      style={{ background: hoverButtonEditGroup ? '#D5D5D5 ' : 'white' }}
                    >
                      <IconButton
                        aria-label='edit'
                        onClick={() => {
                          let rowSelected = rows.find((item: any) => item.groupId === selected[0])
                          setselectedGroup(rowSelected)
                          setShowModal(true)
                        }}
                        size='small'
                        sx={{ background: 'white' /* , marginRight: '7px' */ }}
                      >
                        <EditIcon
                          sx={{ fontSize: '30px', color: hoverButtonEditGroup ? 'black' : '' }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
                <Box
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                  alignContent='center'
                  marginLeft={2}
                  sx={{ height: 50, /* marginTop: '-10px' */ }}
                >
                  {selected.length > 0 && roleIsValidGroupAddEdit && (
                    <Tooltip
                      arrow
                      title='Delete Group'
                      placement='top'
                      onMouseEnter={() => {
                        setHoverButtonDeleteGroup(true)
                      }}
                      onMouseLeave={() => {
                        setHoverButtonDeleteGroup(false)
                      }}
                      style={{ background: hoverButtonDeleteGroup ? '#D5D5D5 ' : 'white' }}
                    >
                      <IconButton
                        aria-label='delete'
                        onClick={handleShow}
                        size='small'
                        sx={{ background: 'white' /* , marginRight: '7px' */ }}
                      >
                        <DeleteIcon
                          sx={{ fontSize: '30px', color: hoverButtonDeleteGroup ? 'black' : '' }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </div>
            </div>
          </div>

          <div className={`card p-3`} style={{ minHeight: '600px' }}>
            {/* begin::Header */}
            <StyledDataGrid
              rows={rows || []}
              columns={columns || []}
              rowHeight={50}
              columnHeaderHeight={40}
              disableVirtualization
              checkboxSelection
              keepNonExistentRowsSelected
              disableMultipleRowSelection={false}
              onCellDoubleClick={handleCellClick}
              onCellClick={handleCellOneClick}
              rowSelectionModel={selected}
              getRowId={(row) => row.groupId}
              disableRowSelectionOnClick
              sortingMode='server'
              onSortModelChange={handleSortModelChange}
              sortModel={options?.sortModel}
              onRowSelectionModelChange={handleSelectionModelChange}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
              filterMode='server'
              onFilterModelChange={onFilterChange}
              rowCount={rowCount}
              loading={isLoadingData}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              paginationMode='server'
              pagination={true}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
              }
              slots={{
                toolbar: CustomToolbar,
                pagination: CustomPagination,
              }}
              sx={{
                boxShadow: "none",
                border: "none",
                '& .MuiDataGrid-footerContainer': {
                  padding: '0px',
                  height: 30
                },

                '& .MuiDataGrid-cell:hover': {
                  /*    color: 'primary.main', */
                  cursor: 'pointer',
                },
                '&	.MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  color: '#A1A5B7',
                  fontSize: 12,
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
                },
              }}
            />
          </div>
        </>
      )}

    </>
  )
}

export default GroupList
