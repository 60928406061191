import React, {FC, useEffect, CSSProperties, useState} from 'react'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import Select, {StylesConfig} from 'react-select'
import {Country, State, City} from 'country-state-city'
import PhoneInput from 'react-phone-input-2'
import Axios from 'axios'
import {useSelector} from 'react-redux'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {colorLighten} from '../../../../_metronic/assets/ts/_utils'
import {Divider} from '@mui/material'
//warn modal
import {SnackbarProvider, useSnackbar} from 'notistack'

type Props = {
  customer: any
  first: any
  setfirst: any
  customerId: any
}
function EditBillingInfo ({customer, first, setfirst, customerId}: Props)  {

  const {enqueueSnackbar} = useSnackbar()

  const [show, setShow] = useState(false)
  const isValidUrl = (urlString: any) => {
    var urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // validate fragment locator
    return !!urlPattern.test(urlString)
  }
  function handleDecimalsOnValue(value: any) {
    const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/s
    return value.match(regex)[0]
  }
  const [currentValue, setCurrentValue] = useState(undefined)

  function checkValue(event: any) {
    setCurrentValue(handleDecimalsOnValue(event.target.value))
    setPaymentPerInvoice(Number(currentValue))
  }
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const mode = useThemeMode()
  const [selectedCountry, setSelectedCountry] = useState<any>('')
  const [selectedState, setSelectedState] = useState<any>('')
  const [selectedCity, setSelectedCity] = useState<any>('')
  const [BillingName, setBillingName] = useState<any>('')
  const [Adress, setAdress] = useState<any>('')
  const [TaxtId, setTaxtId] = useState<any>('')
  const [TaxOffice, setTaxOffice] = useState<any>('')
  const [phone, setPhone] = useState<any>('')
  const [WebSite, setWebSite] = useState<any>('')
  const [Currency, setCurrency] = useState<any>('')
  const [Payment, setPayment] = useState<any>('')
  const [PaymentFreq, setPaymentFreq] = useState<any>('')
  const [PaymentPerInvoice, setPaymentPerInvoice] = useState<any>()
  const [TimeZone, setTimeZone] = useState<any>('')
  const [btn, setBtn] = useState<any>(true)
  const handleNumInp = (event: any) => {
    const result = event.target.value.replace(/\D/g, '')
    setTaxtId(result)
  }
  const [isUrlValid, setIsUrlValid] = useState(true)
  const handleValidUrl = (event: any) => {
    setIsUrlValid(isValidUrl(event.target.value))
  }

  useEffect(() => {
    setBillingName(customer?.commercialTitle)
    setAdress(customer?.address)
    setTaxtId(customer?.taxId)
    setPhone(customer?.companyPhone)
    setTaxOffice(customer?.taxOffice)
    setWebSite(customer?.webSite)
    setCurrency(customer?.currency)
    setPayment(customer?.paymentType)
    setCurrentValue(customer?.paymentPerInvoice)
    setTimeZone(customer?.timezone)
    if (customer?.subscriptionId >= 3) {
      setBtn(false)
    }
    setPaymentFreq(customer?.paymentFrequency)
  }, [customer])
  const LoginState = useSelector((state: any) => state.Login)
  const [isSuccess, setIsSuccess] = useState(false)
  const handleSave = () => {
    Axios.post(
      `${process.env.REACT_APP_API_URL}/customer/editCustomer/billing/${customerId}`,
      {
        commercialTitle: BillingName,
        taxId: TaxtId,
        taxOffice: TaxOffice,
        country: selectedCountry.name,
        province: selectedState.name,
        city: selectedCity.name,
        address: Adress,
        companyPhone: phone,
        webSite: WebSite,
        timezone: TimeZone,
        currency: Currency,
        paymentType: Payment,
        paymentFrequency: PaymentFreq,
        paymentPerInvoice: currentValue,
      },
      {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/json',
        },
      }
    ).then(function (response: any) {
      enqueueSnackbar('Successfully saved', {variant: 'success'})
      setIsSuccess(true)
    }).catch((err) => {
      enqueueSnackbar('Saved Error', {variant: 'error'})
      setIsSuccess(false)
    })
  }
  function handleDone () {
    setIsSuccess(false)
    setfirst(!first)
    handleClose()
  }
  const customControlStyles: CSSProperties = {
    textDecorationColor: mode.mode == 'dark' ? 'black' : '',
    backgroundColor: mode.mode == 'dark' ? '#1b1b29' : '',
    color: mode.mode == 'dark' ? 'white' : '',
    border: '0px',
  }
  const selectStyle: StylesConfig = {
    control: (provided, state) => {
      // provided has CSSObject type
      // state has ControlProps type
      // return type is CSSObject which means this line will throw error if uncommented
      // return false;
      return {
        ...provided,
        ...customControlStyles,
      }
    },
  }

  return (
    <>
      <Button variant='contained' size='large' onClick={handleShow}>
        Edit
      </Button>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header>
          <div className='w-100 d-flex justify-content-between'>
            <div className='h2 my-auto'>Edit Billing Information</div>
            <a onClick={handleClose} className='btn btn-icon btn-secondary rounded-circle btn-sm'>
              <i className='fa-solid fa-x'></i>{' '}
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <table className='table'>
            <thead>
              <tr>
                <th scope='row' className='align-middle'>
                  Billing Name
                </th>
                <th>
                  <input
                    type='text'
                    className=' form-control'
                    value={BillingName}
                    onChange={(e: any) => setBillingName(e.target.value)}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope='row' className='align-middle'>
                  Country
                </th>
                <td>
                  <Select
                    defaultInputValue={customer?.country}
                    options={Country.getAllCountries()}
                    className='form-control  p-0 border-1 color'
                    closeMenuOnSelect={true}
                    styles={selectStyle}
                    getOptionLabel={(options) => {
                      return options['name']
                    }}
                    getOptionValue={(options) => {
                      return options['name']
                    }}
                    value={selectedCountry}
                    onChange={(item) => {
                      setSelectedCountry(item)
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope='row' className='align-middle'>
                  Province
                </th>
                <td>
                  <Select
                    defaultInputValue={customer?.province}
                    options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                    className='form-control form-control-solid p-0 border-1'
                    closeMenuOnSelect={true}
                    styles={selectStyle}
                    getOptionLabel={(options) => {
                      return options['name']
                    }}
                    getOptionValue={(options) => {
                      return options['name']
                    }}
                    value={selectedState}
                    onChange={(item) => {
                      setSelectedState(item)
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope='row' className='align-middle'>
                  City
                </th>
                <td>
                  <Select
                    defaultInputValue={customer?.city}
                    options={City.getCitiesOfState(
                      selectedState?.countryCode,
                      selectedState?.isoCode
                    )}
                    className='form-control form-control-solid p-0 border-1'
                    closeMenuOnSelect={true}
                    styles={selectStyle}
                    getOptionLabel={(options) => {
                      return options['name']
                    }}
                    getOptionValue={(options) => {
                      return options['name']
                    }}
                    value={selectedCity}
                    onChange={(item) => {
                      setSelectedCity(item)
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope='row' className='align-middle'>
                  Address
                </th>
                <td>
                  <input
                    type='text'
                    className=' form-control'
                    value={Adress}
                    onChange={(e: any) => setAdress(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th scope='row' className='align-middle'>
                  Tax ID
                </th>
                <td>
                  <input
                    maxLength={12}
                    type='text'
                    className=' form-control'
                    value={TaxtId}
                    onChange={handleNumInp}
                  />
                </td>
              </tr>
              <tr>
                <th scope='row' className='align-middle'>
                  Tax Office
                </th>
                <td>
                  <input
                    type='text'
                    className=' form-control'
                    value={TaxOffice}
                    onChange={(e: any) => setTaxOffice(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th scope='row' className='align-middle'>
                  Company Phone
                </th>
                <td>
                  <PhoneInput
                    value={customer?.companyPhone}
                    specialLabel=''
                    buttonStyle={{backgroundColor: 'white'}}
                    dropdownStyle={{color: 'black'}}
                    inputStyle={{
                      color: '#92929f',
                      width: '100%',
                      border: 'none',
                      backgroundColor: mode.mode == 'dark' ? '#1b1b29' : '#f5f8fa',
                    }}
                    country={'tr'}
                    enableSearch={true}
                    onChange={(phone: any) => setPhone(phone)}
                  />
                </td>
              </tr>
              <tr>
                <th scope='row' className='align-middle'>
                  Web Site
                </th>
                <td>
                  <input
                    type='text'
                    className={
                      isUrlValid === false ? ' form-control border-danger' : ' form-control'
                    }
                    value={WebSite}
                    onChange={(e: any) => {
                      setWebSite(e.target.value)
                      handleValidUrl(e)
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th scope='row' className='align-middle'>
                  Time Zone
                </th>
                <td>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    value={TimeZone}
                    onChange={(e) => setTimeZone(e.target.value)}
                  >
                    <option value=''>Select a Timezone..</option>
                    <option value='International Date Line West'>
                      (GMT-11:00) International Date Line West
                    </option>
                    <option value='Midway Island'>(GMT-11:00) Midway Island</option>
                    <option value='Samoa'>(GMT-11:00) Samoa</option>
                    <option value='Hawaii'>(GMT-10:00) Hawaii</option>
                    <option value='Alaska'>(GMT-08:00) Alaska</option>
                    <option value='Pacific Time (US &amp; Canada)'>
                      (GMT-07:00) Pacific Time (US &amp; Canada)
                    </option>
                    <option value='Tijuana'>(GMT-07:00) Tijuana</option>
                    <option value='Arizona'>(GMT-07:00) Arizona</option>
                    <option value='Mountain Time (US &amp; Canada)'>
                      (GMT-06:00) Mountain Time (US &amp; Canada)
                    </option>
                    <option value='Chihuahua'>(GMT-06:00) Chihuahua</option>
                    <option value='Mazatlan'>(GMT-06:00) Mazatlan</option>
                    <option value='Saskatchewan'>(GMT-06:00) Saskatchewan</option>
                    <option value='Central America'>(GMT-06:00) Central America</option>
                    <option value='Central Time (US &amp; Canada)'>
                      (GMT-05:00) Central Time (US &amp; Canada)
                    </option>
                    <option value='Guadalajara'>(GMT-05:00) Guadalajara</option>
                    <option value='Mexico City'>(GMT-05:00) Mexico City</option>
                    <option value='Monterrey'>(GMT-05:00) Monterrey</option>
                    <option value='Bogota'>(GMT-05:00) Bogota</option>
                    <option value='Lima'>(GMT-05:00) Lima</option>
                    <option value='Quito'>(GMT-05:00) Quito</option>
                    <option value='Eastern Time (US &amp; Canada)'>
                      (GMT-04:00) Eastern Time (US &amp; Canada)
                    </option>
                    <option value='Indiana (East)'>(GMT-04:00) Indiana (East)</option>
                    <option value='Caracas'>(GMT-04:00) Caracas</option>
                    <option value='La Paz'>(GMT-04:00) La Paz</option>
                    <option value='Georgetown'>(GMT-04:00) Georgetown</option>
                    <option value='Atlantic Time (Canada)'>
                      (GMT-03:00) Atlantic Time (Canada)
                    </option>
                    <option value='Santiago'>(GMT-03:00) Santiago</option>
                    <option value='Brasilia'>(GMT-03:00) Brasilia</option>
                    <option value='Buenos Aires'>(GMT-03:00) Buenos Aires</option>
                    <option value='Newfoundland'>(GMT-02:30) Newfoundland</option>
                    <option value='Greenland'>(GMT-02:00) Greenland</option>
                    <option value='Mid-Atlantic'>(GMT-02:00) Mid-Atlantic</option>
                    <option value='Cape Verde Is.'>(GMT-01:00) Cape Verde Is.</option>
                    <option value='Azores'>(GMT) Azores</option>
                    <option value='Monrovia'>(GMT) Monrovia</option>
                    <option value='UTC'>(GMT) UTC</option>
                    <option value='Dublin'>(GMT+01:00) Dublin</option>
                    <option value='Edinburgh'>(GMT+01:00) Edinburgh</option>
                    <option value='Lisbon'>(GMT+01:00) Lisbon</option>
                    <option value='London'>(GMT+01:00) London</option>
                    <option value='Casablanca'>(GMT+01:00) Casablanca</option>
                    <option value='West Central Africa'>(GMT+01:00) West Central Africa</option>
                    <option value='Belgrade'>(GMT+02:00) Belgrade</option>
                    <option value='Bratislava'>(GMT+02:00) Bratislava</option>
                    <option value='Budapest'>(GMT+02:00) Budapest</option>
                    <option value='Ljubljana'>(GMT+02:00) Ljubljana</option>
                    <option value='Prague'>(GMT+02:00) Prague</option>
                    <option value='Sarajevo'>(GMT+02:00) Sarajevo</option>
                    <option value='Skopje'>(GMT+02:00) Skopje</option>
                    <option value='Warsaw'>(GMT+02:00) Warsaw</option>
                    <option value='Zagreb'>(GMT+02:00) Zagreb</option>
                    <option value='Brussels'>(GMT+02:00) Brussels</option>
                    <option value='Copenhagen'>(GMT+02:00) Copenhagen</option>
                    <option value='Madrid'>(GMT+02:00) Madrid</option>
                    <option value='Paris'>(GMT+02:00) Paris</option>
                    <option value='Amsterdam'>(GMT+02:00) Amsterdam</option>
                    <option value='Berlin'>(GMT+02:00) Berlin</option>
                    <option value='Bern'>(GMT+02:00) Bern</option>
                    <option value='Rome'>(GMT+02:00) Rome</option>
                    <option value='Stockholm'>(GMT+02:00) Stockholm</option>
                    <option value='Vienna'>(GMT+02:00) Vienna</option>
                    <option value='Cairo'>(GMT+02:00) Cairo</option>
                    <option value='Harare'>(GMT+02:00) Harare</option>
                    <option value='Pretoria'>(GMT+02:00) Pretoria</option>
                    <option value='Bucharest'>(GMT+03:00) Bucharest</option>
                    <option value='Helsinki'>(GMT+03:00) Helsinki</option>
                    <option value='Kiev'>(GMT+03:00) Kiev</option>
                    <option value='Kyiv'>(GMT+03:00) Kyiv</option>
                    <option value='Riga'>(GMT+03:00) Riga</option>
                    <option value='Sofia'>(GMT+03:00) Sofia</option>
                    <option value='Tallinn'>(GMT+03:00) Tallinn</option>
                    <option value='Vilnius'>(GMT+03:00) Vilnius</option>
                    <option value='Athens'>(GMT+03:00) Athens</option>
                    <option value='Istanbul'>(GMT+03:00) Istanbul</option>
                    <option value='Minsk'>(GMT+03:00) Minsk</option>
                    <option value='Jerusalem'>(GMT+03:00) Jerusalem</option>
                    <option value='Moscow'>(GMT+03:00) Moscow</option>
                    <option value='St. Petersburg'>(GMT+03:00) St. Petersburg</option>
                    <option value='Volgograd'>(GMT+03:00) Volgograd</option>
                    <option value='Kuwait'>(GMT+03:00) Kuwait</option>
                    <option value='Riyadh'>(GMT+03:00) Riyadh</option>
                    <option value='Nairobi'>(GMT+03:00) Nairobi</option>
                    <option value='Baghdad'>(GMT+03:00) Baghdad</option>
                    <option value='Abu Dhabi'>(GMT+04:00) Abu Dhabi</option>
                    <option value='Muscat'>(GMT+04:00) Muscat</option>
                    <option value='Baku'>(GMT+04:00) Baku</option>
                    <option value='Tbilisi'>(GMT+04:00) Tbilisi</option>
                    <option value='Yerevan'>(GMT+04:00) Yerevan</option>
                    <option value='Tehran'>(GMT+04:30) Tehran</option>
                    <option value='Kabul'>(GMT+04:30) Kabul</option>
                    <option value='Ekaterinburg'>(GMT+05:00) Ekaterinburg</option>
                    <option value='Islamabad'>(GMT+05:00) Islamabad</option>
                    <option value='Karachi'>(GMT+05:00) Karachi</option>
                    <option value='Tashkent'>(GMT+05:00) Tashkent</option>
                    <option value='Chennai'>(GMT+05:30) Chennai</option>
                    <option value='Kolkata'>(GMT+05:30) Kolkata</option>
                    <option value='Mumbai'>(GMT+05:30) Mumbai</option>
                    <option value='New Delhi'>(GMT+05:30) New Delhi</option>
                    <option value='Sri Jayawardenepura'>(GMT+05:30) Sri Jayawardenepura</option>
                    <option value='Kathmandu'>(GMT+05:45) Kathmandu</option>
                    <option value='Astana'>(GMT+06:00) Astana</option>
                    <option value='Dhaka'>(GMT+06:00) Dhaka</option>
                    <option value='Almaty'>(GMT+06:00) Almaty</option>
                    <option value='Urumqi'>(GMT+06:00) Urumqi</option>
                    <option value='Rangoon'>(GMT+06:30) Rangoon</option>
                    <option value='Novosibirsk'>(GMT+07:00) Novosibirsk</option>
                    <option value='Bangkok'>(GMT+07:00) Bangkok</option>
                    <option value='Hanoi'>(GMT+07:00) Hanoi</option>
                    <option value='Jakarta'>(GMT+07:00) Jakarta</option>
                    <option value='Krasnoyarsk'>(GMT+07:00) Krasnoyarsk</option>
                    <option value='Beijing'>(GMT+08:00) Beijing</option>
                    <option value='Chongqing'>(GMT+08:00) Chongqing</option>
                    <option value='Hong Kong'>(GMT+08:00) Hong Kong</option>
                    <option value='Kuala Lumpur'>(GMT+08:00) Kuala Lumpur</option>
                    <option value='Singapore'>(GMT+08:00) Singapore</option>
                    <option value='Taipei'>(GMT+08:00) Taipei</option>
                    <option value='Perth'>(GMT+08:00) Perth</option>
                    <option value='Irkutsk'>(GMT+08:00) Irkutsk</option>
                    <option value='Ulaan Bataar'>(GMT+08:00) Ulaan Bataar</option>
                    <option value='Seoul'>(GMT+09:00) Seoul</option>
                    <option value='Osaka'>(GMT+09:00) Osaka</option>
                    <option value='Sapporo'>(GMT+09:00) Sapporo</option>
                    <option value='Tokyo'>(GMT+09:00) Tokyo</option>
                    <option value='Yakutsk'>(GMT+09:00) Yakutsk</option>
                    <option value='Darwin'>(GMT+09:30) Darwin</option>
                    <option value='Adelaide'>(GMT+09:30) Adelaide</option>
                    <option value='Canberra'>(GMT+10:00) Canberra</option>
                    <option value='Melbourne'>(GMT+10:00) Melbourne</option>
                    <option value='Sydney'>(GMT+10:00) Sydney</option>
                    <option value='Brisbane'>(GMT+10:00) Brisbane</option>
                    <option value='Hobart'>(GMT+10:00) Hobart</option>
                    <option value='Vladivostok'>(GMT+10:00) Vladivostok</option>
                    <option value='Guam'>(GMT+10:00) Guam</option>
                    <option value='Port Moresby'>(GMT+10:00) Port Moresby</option>
                    <option value='Solomon Is.'>(GMT+10:00) Solomon Is.</option>
                    <option value='Magadan'>(GMT+11:00) Magadan</option>
                    <option value='New Caledonia'>(GMT+11:00) New Caledonia</option>
                    <option value='Fiji'>(GMT+12:00) Fiji</option>
                    <option value='Kamchatka'>(GMT+12:00) Kamchatka</option>
                    <option value='Marshall Is.'>(GMT+12:00) Marshall Is.</option>
                    <option value='Auckland'>(GMT+12:00) Auckland</option>
                    <option value='Wellington'>(GMT+12:00) Wellington</option>
                    <option value="Nuku'alofa">(GMT+13:00) Nuku'alofa</option>
                  </select>
                </td>
              </tr>
              <tr className={customer?.subscriptionId === 0 ? 'd-none' : ''}>
                <th scope='row' className='align-middle'>
                  Currency
                </th>
                <td>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    value={Currency}
                    onChange={(e: any) => {
                      setCurrency(e.target.value)
                    }}
                  >
                    <option>Select currency</option>
                    <option value='GBP'>British Pound Sterling - £</option>
                    <option value='EUR'>Euro - €</option>
                    <option value='TL'>Turkish Lira - ₺</option>
                    <option value='RUB'>Russian Ruble - ₽</option>
                    <option value='USD'>US Dollar - $</option>
                  </select>
                </td>
              </tr>
              <tr className={customer?.subscriptionId === 0 ? 'd-none' : ''}>
                <th scope='row' className='align-middle'>
                  Payment Type
                </th>
                <td>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    value={Payment}
                    onChange={(e: any) => {
                      setPayment(e.target.value)
                    }}
                  >
                    {' '}
                    <option>Select Payment Type</option>
                    <option value='Credit Card'>Credit Card</option>
                    <option value='Direct Cash'>Direct Cash</option>
                  </select>
                </td>
              </tr>
              <tr className={customer?.subscriptionId === 0 ? 'd-none' : ''}>
                <th scope='row' className='align-middle'>
                  Payment Frequency
                </th>
                <td>
                  <select
                    disabled={btn}
                    className='form-select form-select-solid form-select-lg'
                    value={PaymentFreq}
                    onChange={(e: any) => {
                      setPaymentFreq(e.target.value)
                    }}
                  >
                    {' '}
                    <option>Select Payment Frequency</option>
                    <option value='Monthly'>Monthly</option>
                    <option value='Annual'>Annualy</option>
                  </select>
                </td>
              </tr>
              <tr className={customer?.subscriptionId === 0 ? 'd-none' : ''}>
                <th scope='row' className='align-middle'>
                  Payment Per Invoice
                </th>
                <td>
                  <input
                    disabled={btn}
                    className=' form-control'
                    type='text'
                    value={currentValue}
                    onChange={(event) => checkValue(event)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Divider variant='middle' sx={{borderTop: '2px solid'}} />
        <Modal.Footer>
          {
            isSuccess === true ? null :
            <Button
            style={{marginRight: '20px'}}
            color='inherit'
            size='small'
            variant='contained'
            onClick={handleClose}
          >
            Discard
          </Button>
          }
          <Button
            style={{marginRight: '40px'}}
            size='small'
            variant='contained'
            onClick={() => isSuccess === true ? handleDone() : handleSave()}
          >
           { isSuccess === true ? "Done" :"Save" } 
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default function Snackbar({customer, first, setfirst, customerId}: Props) {
  return (
    <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} maxSnack={3}>
      <EditBillingInfo {...{customer, first, setfirst, customerId}} />
    </SnackbarProvider>
  )
}
