/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import React, { FC, useEffect, useState } from 'react'
import { Avatar, Box, Button, Grid, IconButton, LinearProgress, Paper, Tooltip, Typography, withStyles } from '@mui/material'
import GaugeChart from 'react-gauge-chart'
import ReactApexChart from 'react-apexcharts'
import { useNavigate } from 'react-router-dom'

//icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import StatusBars from './component/statusBars'
import OnlineUsersModal from './component/onlineUserModal'






const CustomerDashboardWrapper = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const language: any = intl?.messages?.customerDashboard


  const LoginState = useSelector((state: any) => state.Login)
  //#region yearCalculate
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();

  const formattedDate = `${day}.${month}.${year}`;
  //#endregion

  const capitalizeFirstLetter2 = (string) => {
    return string.split(' ') // Boşluk karakterine göre string'i ayırarak bir dizi oluştur
    .map(word => word.length > 0 
      ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Her kelimenin ilk harfini büyük yap
      : word
    )
    .join(' ')
  };
/*   const capitalizeFirstLetter = (string) => {
    return string.split(' ') // Boşluk karakterine göre string'i ayırarak bir dizi oluştur
      .map(word => word.length > 0 
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Her kelimenin ilk harfini büyük yap
        : word
      )
      .join(' '); // Kelimeleri boşluk karakteri ile birleştir
  }; */
  const capitalizeFirstLetter = (string, locale = navigator.language) => {
    return string
      .split(' ') // Boşluk karakterine göre string'i ayırarak bir dizi oluştur
      .map(word =>
        word.length > 0
          ? word.charAt(0).toLocaleUpperCase(locale) + word.slice(1).toLocaleLowerCase(locale) // Her kelimenin ilk harfini büyük yap
          : word
      )
      .join(' '); // Kelimeleri boşluk karakteri ile birleştir
  };


  const [timeIntervalSuccess, setTimeIntervalSuccess] = useState(0)
  const [timeIntervalProgress, setTimeIntervalProgress] = useState(0)

  const [apexChartSuccess, setApexChartSuccess] = useState<any>({
    series: [{
      name: "Knowledge Level",
      data: [0, 0, 0, 0, 0, 0]
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      }, 
      /* title: {
        text: 'Product Trends by Month',
        align: 'left'
      }, */
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: ["", "", "", "", "", "",],
        labels: {
          style: {
            fontSize: '8px' // Burada font boyutunu ayarlıyoruz
          }
        },
      }
    }
  })

  const [apexChartSuccessLastThreeMonth, setapexChartSuccessLastThreeMonth] = useState<any>({
    series: [{
      name: 'Knowledge Level',
      data: [0, 0, 0]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        /* bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }, */
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val ?  val + "%" : "" ;
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: ["", "", ""],
        labels: {
          style: {
            fontSize: '12px' // Burada font boyutunu ayarlıyoruz
          }
        },
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        enabled: false
      },
    },
  })
  const [knowledgeLevel, setKnowledgeLevel] = useState({ questions: 0, answers: 0, gaugeChartPercent: 0.0, gaugeChart: 0 })
  const [topLearners, setTopLearners] = useState([
    /*  { userId: 1, avatarPath: "https://www.nasol.com.tr/urunlerresim/buyuk/f1167-167.jpg", name: "ramazan", surname: "cetinkaya", progress: 30 } */
  ])
  const [topSubjects, setTopSubjects] = useState([
    /* { userId: 1, avatarPath: "https://www.nasol.com.tr/urunlerresim/buyuk/f1167-167.jpg", name: "deneme asda", progress: 30 } */
  ])

  const [completionLevel, setCompletionLevel] = useState({ assignedActivities: 2131, completedActivities: 3123, gaugeChartPercent: 0.5, gaugeChart: "50" })
  const [activityFinishers, setActivityFinishers] = useState([
    /* { userId: 1, avatarPath: "https://www.nasol.com.tr/urunlerresim/buyuk/f1167-167.jpg", name: "ramazan11", surname: "cetinkaya2", count: 30 } */
  ])
  const [completedActivities, setCompletedActivities] = useState([
    /* { userId: 1, avatarPath: "https://www.nasol.com.tr/urunlerresim/buyuk/f1167-167.jpg", name: "deneme1", surname: "dd123sada11", count: 30 } */
  ])

  const [apexChartProgressCompletionLevel, setApexChartProgressCompletionLevel] = useState<any>({
    series: [{
      name: 'Completion Level',
      data: [0]
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      /* title: {
        text: 'Product Trends by Month',
        align: 'left'
      }, */
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: [""],
        labels: {
          style: {
            fontSize: '8px' // Burada font boyutunu ayarlıyoruz
          }
        },
      }
    },
  }
  )
  const [apexChartProgressCompletionLevelLastThreeMonth, setApexChartProgressCompletionLevelLastThreeMonth] = useState<any>({
    series: [{
      name: '',
      data: [0]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        /* bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }, */
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val ?  val + "%" : "" ;
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [""],
        labels: {
          style: {
            fontSize: '10px' // Burada font boyutunu ayarlıyoruz
          }
        },
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        enabled: false
      },
    },
  }
  )
  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return { hours, minutes: remainingMinutes };
  }
  const [avgTimeSpend, setAvgTimeSpend] = useState({ hours: 0, minutes: 0 })
  const [apexChartTimeSpend, setApexChartTimeSpend] = useState<any>({
    series: [{
      name: "Avg. Time Spend",
      data: [0,]
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      /* title: {
        text: 'Product Trends by Month',
        align: 'left'
      }, */
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: ["",],
        labels: {
          style: {
            fontSize: '8px' // Burada font boyutunu ayarlıyoruz
          }
        },
      }
    },
  })
  const [apexChartProgressTimeSpendLastThreeMonth, setApexChartProgressTimeSpendLastThreeMonth] = useState<any>({
    series: [{
      name: '',
      data: [0, 0]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        /* bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }, */
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        /*  formatter: function (val) {
           return val + "%";
         }, */
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ["", ""],
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        enabled: false
      },
    },
  })


  const [liveJourneys, setliveJourneys] = useState({ finished: 0, live: 0, paused: 0, scheduled: 0, total: 0 })
  const [latestJourneys, setLatestJourneys] = useState([
    /*  { journeyId: 0, journeyName: "", progress: 0 } */
  ])
  const [onlineUsers, setOnlineUsers] = useState()
  const [apexChartUserActiveness, setApexChartUserActiveness] = useState<any>({
    series: [{
      name: "User Activeness",
      data: [0]
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      /* title: {
        text: 'Product Trends by Month',
        align: 'left'
      }, */
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: [""],
        labels: {
          style: {
            fontSize: '8px' // Burada font boyutunu ayarlıyoruz
          }
        },
      }
    },
  }) 
  const [apexChartQuestions, setApexChartQuestions] = useState<any>({
    series: [1000, 5000, 0],
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Customer', 'Mobixa Team', 'Ai',],
      colors: ['#4CAF50', '#F82D7BDE', '#2196F3'],
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '45%', // Bu değer donut'un iç boşluğunun boyutunu belirler, yüzdelik olarak.
          },
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          },
          plotOptions: {
            pie: {
              donut: {
                size: '45%', // Küçük ekranlarda donut'un kalınlığını artırır
              },
            },
          },
        }
      }]
    },
  })
  const [apexChartActivities, setApexChartActivities] = useState<any>({
    series: [44, 100, 60,],
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Learning Card', 'Game', 'Test',],
      colors: ['#D00000DE', '#9C27B0', '#007DBF'],
      plotOptions: {
        pie: {
          donut: {
            size: '45%', // Bu değer donut'un iç boşluğunun boyutunu belirler, yüzdelik olarak.
          },
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          },
          plotOptions: {
            pie: {
              donut: {
                size: '45%', // Küçük ekranlarda donut'un kalınlığını artırır
              },
            },
          },
        }
      }]
    },
  })
  const [chartQuestionsNumber, setChartQuestionsNumber] = useState(0)
  const [chartActivitiesNumber, setChartActivitiesNumber] = useState(0)
  const [categories, setCategories] = useState({ main: 5, sub: 15 })

  const processDataForChart = (data) => {
    const categories = data.map(item => item.date);
    const seriesData = data.map(item => item.successRate);

    return {
      categories,
      seriesData
    };
  };
  const processDataForChartProgress = (data) => {
    const categories = data.map(item => item.date);
    const seriesData = data.map(item => item.progressRate);

    return {
      categories,
      seriesData
    };
  };
  const processDataForChartAvgTime = (data) => {
    const categories = data.map(item => item.date);
    const seriesData = data.map(item => item.avgOnlineTime);

    return {
      categories,
      seriesData
    };
  };
  const processDataForChartUserActiveness = (data) => {
    const categories = data.map(item => item.date);
    const seriesData = data.map(item => item.userCount);

    return {
      categories,
      seriesData
    };
  };

  const [loadingSuccess, setLoadingSuccess] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(false)
  const [loadingGeneral, setLoadingGeneral] = useState(false)

  async function handleGetSuccessData(item: number) {
    setLoadingSuccess(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/report/dashboard/success?timeFrame=${item === 0 ? "monthToDate" : item === 1 ? "lastMonth" : item === 2 ? "last3Months" : "allTime"}`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((response) => {
        setLoadingSuccess(false)


        const { categories, seriesData } = processDataForChart(response.data.successRateChartData);

        setApexChartSuccess(prevState => ({
          ...prevState,
          series: [{ ...prevState.series[0], data: seriesData }],
          options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories } }
        }));

        setapexChartSuccessLastThreeMonth(prevState => ({
          ...prevState,
          series: [{ ...prevState.series[0], data: seriesData }],
          options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories } }
        }));

        setTopLearners(response.data.topLearners)

        setTopSubjects(response.data.topSubjects)

        setKnowledgeLevel({ answers: response.data.correctAnswerCount, questions: response.data.totalAnswerCount, gaugeChart: response.data.successRate, gaugeChartPercent: (response.data.successRate / 100) })

      })
      .catch((err) => {
        /*  console.log(err) */
        setLoadingSuccess(false)
      })
  }

  async function handleGetProgressData(item: number) {
    setLoadingProgress(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/report/dashboard/progress?timeFrame=${item === 0 ? "monthToDate" : item === 1 ? "lastMonth" : item === 2 ? "last3Months" : "allTime"}`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((response) => {
        setLoadingProgress(false)


        const { categories, seriesData } = processDataForChartProgress(response.data.progressRateChartData);

        setApexChartProgressCompletionLevel(prevState => ({
          ...prevState,
          series: [{ ...prevState.series[0], data: seriesData }],
          options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories } }
        }));

        setApexChartProgressCompletionLevelLastThreeMonth(prevState => ({
          ...prevState,
          series: [{ ...prevState.series[0], data: seriesData }],
          options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories } }
        }));


        const data = processDataForChartAvgTime(response.data.timeSpentChartData);

        setApexChartTimeSpend(prevState => ({
          ...prevState,
          series: [{ ...prevState.series[0], data: data.seriesData }],
          options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories: data.categories } }
        }));

        setApexChartProgressTimeSpendLastThreeMonth(prevState => ({
          ...prevState,
          series: [{ ...prevState.series[0], data: data.seriesData }],
          options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories:data.categories } }
        }));



        setActivityFinishers(response.data.topFinishers)


        setCompletedActivities(response.data.topActivityCompleted)

        setCompletionLevel({ assignedActivities:response.data.totalActivityCount , completedActivities: response.data.totalCompletedActivityCount , gaugeChart: response.data.progressRate, gaugeChartPercent: (response.data.progressRate / 100) })


        const avgTimeSpend = convertMinutesToHoursAndMinutes(response?.data?.avgTimeSpent)
        setAvgTimeSpend(avgTimeSpend)


      })
      .catch((err) => {
        /*  console.log(err) */
        setLoadingProgress(false)
      })
  }
  async function handleGetGeneralData() {
    setLoadingGeneral(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/report/dashboard/general`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((response) => {
        setLoadingGeneral(false)

        setOnlineUsers(response.data.onlineUserCount)


        const { categories, seriesData } = processDataForChartUserActiveness(response.data.userActiveness);
        setApexChartUserActiveness(prevState => ({
          ...prevState,
          series: [{ ...prevState.series[0], data: seriesData }],
          options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories } }
        }));


        setliveJourneys({ live: response.data.startedJourneyCount, paused: response.data.pausedJourneyCount, finished: response.data.finishedJourneyCount, scheduled: response.data.readyJourneyCount, total: response.data.totalJourneyCount, })


        setLatestJourneys(response.data.lastestJourneys)


        const seriesDataQuestions = [response.data.customerQuestionCount, response.data.mobixaQuestionCount, response.data.aiQuestionCount]
        const totalQuestionCount = response.data.customerQuestionCount + response.data.mobixaQuestionCount + response.data.aiQuestionCount
        setApexChartQuestions(prevState => ({
          ...prevState,
          series: seriesDataQuestions,
        }));
        setChartQuestionsNumber(totalQuestionCount)


        const seriesDataActivities = [response.data.learningCardCount, response.data.gameCount, response.data.testCount]
        const totalActivitiesCount = response.data.learningCardCount + response.data.gameCount + response.data.testCount
        setApexChartActivities(prevState => ({
          ...prevState,
          series: seriesDataActivities,
        }));
        setChartActivitiesNumber(totalActivitiesCount)


        setCategories({ main: response.data.mainCategoryCount, sub: response.data.subCategoryCount })

      })
      .catch((err) => {
        /*  console.log(err) */
        setLoadingGeneral(false)
      })
  }

  useEffect(() => {
    handleGetSuccessData(timeIntervalSuccess)
  }, [timeIntervalSuccess])

  useEffect(() => {
    handleGetProgressData(timeIntervalProgress)
  }, [timeIntervalProgress])

  useEffect(() => {
    handleGetGeneralData()
  }, [])

  //#region report routes 
  function handleRouteJourney() {
    navigate('/reports/journeysReport')
  }
  function handleRouteUsers() {
    navigate('/reports/UserReport')
  }
  function handleRouteCategory() {
    navigate('/reports/CategoriesReport')
  }
  function handleRouteActivity() {
    navigate('/reports/activitiesReport')
  }
  //#endregion

  function handleRouteJourneyList() {
    navigate('/journey')
  }
  function handleRouteQuestionList() {
    navigate('/manage/QuestionPage')
  }
  function handleRouteActivityList() {
    navigate('/manage/activities')
  }
  function handleRouteCategoryList() {
    navigate('/category/Categories')
  }
  const [openOnlineUserModal, setOpenOnlineUserModal] = useState(false)

  return (
    <>
      <Grid container spacing={2} height={"100%"} paddingLeft={2} paddingRight={2} flexGrow={'revert'}  >
        <Grid item xs={12}> <Typography fontSize={14} color={"#B9B8B8"}>{formattedDate}</Typography></Grid>
        {
          //#region Success
          <Grid item xs={12} md={12} lg={6} xl={6} minWidth={640} height={"auto"}>
            <Paper elevation={3} sx={{ padding: 1, height: "100%", borderRadius: "10px" }}>
              {
                //#region topBar Success
                <Grid xs={12} height={30} display={"flex"} justifyContent={"space-between"} >
                  <Typography fontSize={14} fontWeight={"bold"} color={"#707070"}>SUCCESS</Typography>
                  <Box>
                    <Button variant='text' size='small' disabled={loadingSuccess} sx={{ fontSize: 12, textTransform: "none", fontWeight: timeIntervalSuccess === 0 ? "bold" : "", textDecoration: timeIntervalSuccess === 0 ? "underline" : "" }}
                      onClick={() => setTimeIntervalSuccess(0)}
                    >Month to date</Button>
                    <Button variant='text' size='small' disabled={loadingSuccess} sx={{ fontSize: 12, textTransform: "none", fontWeight: timeIntervalSuccess === 1 ? "bold" : "", textDecoration: timeIntervalSuccess === 1 ? "underline" : "" }}
                      onClick={() => setTimeIntervalSuccess(1)}
                    >Last month</Button>
                    <Button variant='text' size='small' disabled={loadingSuccess} sx={{ fontSize: 12, textTransform: "none", fontWeight: timeIntervalSuccess === 2 ? "bold" : "", textDecoration: timeIntervalSuccess === 2 ? "underline" : "" }}
                      onClick={() => setTimeIntervalSuccess(2)}
                    >Last 3 months</Button>
                    <Button variant='text' size='small' disabled={loadingSuccess} sx={{ fontSize: 12, textTransform: "none", fontWeight: timeIntervalSuccess === 3 ? "bold" : "", textDecoration: timeIntervalSuccess === 3 ? "underline" : "" }}
                      onClick={() => setTimeIntervalSuccess(3)}
                    >All time</Button>
                  </Box>
                </Grid>
                //#endregion
              }

              <Grid item container display={"flex"} justifyContent={"center"} paddingLeft={2} paddingRight={1} >
                <Grid xs={12} width={815} height={"auto"} >
                  <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                    <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                      sx={{
                        background: "#EFF7FF"
                      }}
                    >
                      <div></div>
                      <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Knowledge Level</Typography>
                      <div>
                        <Tooltip arrow title={language?.knowledgeLevel} placement='top' >
                          <IconButton size='small'>
                            <InfoOutlinedIcon fontSize='medium' />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Grid>
                    {
                      //#region content
                      <Grid xs={12} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <div style={{ position: "relative", display: "inline-block", height: "100%", width: 180, marginLeft: -15, paddingTop: 50 }}>
                          <GaugeChart
                            id="gauge-chart5"
                            nrOfLevels={420}
                            arcsLength={[0.5, 0.25, 0.25]}
                            colors={['#FF0000', '#D9F82D', '#76FF00']}
                            percent={knowledgeLevel.gaugeChartPercent}
                            arcPadding={0.02}
                            textColor={'#555555'}
                            needleColor={'#C9CED4'}
                            needleBaseColor={'#E0E0E0'}
                            hideText={true}
                          />
                          <div style={{ display: "flex", alignItems: "end", justifyContent: "center", marginTop: -70, paddingLeft: 15 }}>
                            <Typography fontSize={40} fontWeight={"bold"} color={"#212529"}>{knowledgeLevel.gaugeChart}</Typography>
                            <Typography fontSize={25} fontWeight={"bold"} color={"#212529"}>%</Typography>
                          </div>
                        </div>
                        <Box marginLeft={-2.5} sx={{ height: 90, width: 115, border: "1px solid #D5D5D5", borderRadius: "5px", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", textAlign: "center" }}>
                          <Typography color={"#212529"} fontSize={14} fontWeight={"bold"}>{knowledgeLevel.questions}</Typography>
                          <Typography color={"#212529"} fontSize={12} >Asked questions</Typography>
                          <Typography color={"#212529"} fontSize={14} fontWeight={"bold"}>{knowledgeLevel.answers}</Typography>
                          <Typography color={"#212529"} fontSize={12} >Correct answers</Typography>
                        </Box>
                        {
                          (timeIntervalSuccess === 0 || timeIntervalSuccess === 1) &&
                          <Box sx={{ width: 430, minHeight: 150, height: "100%", paddingRight: 2 }}>
                            {
                              !loadingSuccess &&
                              <ReactApexChart key={0} options={apexChartSuccess.options} series={apexChartSuccess.series} type="line" height={"100%"} />
                            }
                          </Box>
                        }
                        {
                          (timeIntervalSuccess === 2 || timeIntervalSuccess === 3) &&
                          <Box sx={{ width: 430, minHeight: 131, height: "100%", paddingRight: 2 }}>
                            <ReactApexChart key={1} options={apexChartSuccessLastThreeMonth.options} series={apexChartSuccessLastThreeMonth.series} type="bar" height={"100%"} />
                          </Box>
                        }

                      </Grid>
                      //#endregion
                    }
                    <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                      sx={{
                        background: "#EFF7FF"
                      }}
                    >
                      
                      <div style={{ display: "flex", alignItems: "center",justifyContent:"center", cursor:"pointer",width:"100%" }} onClick={handleRouteJourney}>
                       {/*  <Button variant='text' size='small' onClick={handleRouteJourney}> */}
                          <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                          <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                       {/*  </Button> */}
                      </div>
                     
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container display={"flex"} paddingLeft={2} paddingRight={1} columnSpacing={1} marginTop={4} >
                {
                  //#region topLearners
                  <Grid item xs={6} height={"287px"}  >
                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                      <Grid xs={12} height={25} maxHeight={25} paddingLeft={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                        <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Top Learners</Typography>
                        <div>
                          <Tooltip arrow title={language?.topLearner} placement='top' >
                            <IconButton size='small'>
                              <InfoOutlinedIcon fontSize='medium' />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                      {
                        //#region content
                        <Grid xs={12} height={"100%"} flexDirection={"column"} display={"flex"} alignItems={"start"} justifyContent={"start"} rowGap={1} padding={1}>
                          {
                            topLearners?.map((item: any, index) =>
                              <Box key={index} display={"flex"} alignContent={"end"} justifyContent={"space-between"} width={"100%"}>
                                <Box display={"flex"} justifyContent={"start"} alignItems={"center"} columnGap={1} width={"100%"} /* minWidth={250} */>
                                  <Typography fontSize={20} color={"#707070"}>{index + 1}</Typography>
                                  <Avatar src={item.avatarPath} sx={{ width: "35px", height: "35px", objectFit: "contain" }} />
                                  <Typography fontSize={14} /* fontWeight={"bold"} */ color={"#000000"} >
                                    {`${capitalizeFirstLetter(item.name)} ${capitalizeFirstLetter(item.surname)}`}
                                  </Typography>
                                </Box>
                                <Box display={"flex"} /* flexDirection={"column"} */ justifyContent={"start"} alignItems={"center"} ml={1} minWidth={"136px"} width={"136px"}>
                                  <Box width={item.successRate <= 5 ? "5px" :`${item.successRate}px`} >
                                  <LinearProgress variant="determinate" value={/* item.successRate */100} sx={{ width:"100%"/* item.successRate === 0 ? "1%": `${item.successRate}%` */, height: 20, borderRadius: "0px 5px 5px 0px", zIndex:2, 
                                  '& .MuiLinearProgress-barColorPrimary': {
                                    backgroundColor:
                                      item.successRate < 50 ? "#FF0000" :
                                      item.successRate < 75 ? "#D9F82D" :
                                      item.successRate <= 100 ? "#4CAF50" : undefined, 
                                  },  }} />
                                  </Box>
                                  <Box width={35}>
                                  <Typography fontSize={14} fontWeight={"bold"}  color={"black"} /* marginTop={-2.6} */ zIndex={2} ml={"2px"} >
                                    {`${item.successRate}%`}
                                  </Typography>
                                  </Box>
                                  
                                  <Box flexGrow={1}>

                                  </Box>
                                </Box>
                              </Box>
                            )
                          }
                        </Grid>
                        //#endregion
                      }
                      <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                       {/*  <div></div> */}
                        <div style={{ display: "flex", alignItems: "center",justifyContent:"center",cursor:"pointer",width:"100%" }} onClick={handleRouteUsers}>
                         {/*  <Button variant='text' size='small' onClick={handleRouteUsers}> */}
                            <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                            <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                         {/*  </Button> */}
                        </div>
                      {/*   <div></div> */}
                      </Grid>
                    </Paper>
                  </Grid>
                  //#endregion
                }
                {
                  //#region topSubjects
                  <Grid item xs={6} height={"auto"} >
                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                      <Grid xs={12} height={25} maxHeight={25} paddingLeft={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                        <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Top Subjects</Typography>
                        <div>
                          <Tooltip arrow title={language?.topSubject} placement='top' >
                            <IconButton size='small'>
                              <InfoOutlinedIcon fontSize='medium' />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                      {
                        //#region content
                        <Grid xs={12} height={"100%"} display={"flex"} flexDirection={"column"} alignItems={"start"} justifyContent={"start"} rowGap={1} padding={1}>
                          {
                            topSubjects?.map((item: any, index) =>
                              <Box key={index} display={"flex"} alignContent={"end"} justifyContent={"space-between"} width={"100%"}>
                                <Box display={"flex"} justifyContent={"start"} alignItems={"center"} columnGap={1} width={"100%"}>
                                  <Typography fontSize={20} color={"#707070"}>{index + 1}</Typography>
                                  <Typography fontSize={14} /* fontWeight={"bold"} */ color={"#000000"} >
                                    {`${capitalizeFirstLetter2(item.name)}`}
                                  </Typography>
                                </Box>
                                <Box display={"flex"} /* flexDirection={"column"} */ justifyContent={"center"} alignItems={"center"} ml={1} minWidth={"136px"} width={"136px"}>
                                <Box width={item.successRate < 5 ? "5px" :`${item.successRate}px`} maxWidth={100}>
                                  <LinearProgress variant="determinate" value={/* item.successRate */100} sx={{ width:"100%"/* item.successRate === 0 ? "1%":`${item.successRate}%` */, height: 20, borderRadius: "0px 5px 5px 0px", zIndex: 1 }} />
                                </Box>
                                  <Typography fontSize={14} fontWeight={"bold"} color={"black"} /* marginTop={-2.6} */ zIndex={2} ml={"2px"} >
                                    {`${item.successRate}%`}
                                  </Typography>
                                  <Box flexGrow={1}>

                                  </Box>
                                </Box>
                              </Box>
                            )
                          }
                        </Grid>
                        //#endregion
                      }
                      <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                        {/* <div></div> */}
                        <div style={{ display: "flex", alignItems: "center",justifyContent:"center",cursor:"pointer",width:"100%" }} onClick={handleRouteCategory}>
                          {/* <Button variant='text' size='small' onClick={handleRouteCategory}> */}
                            <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                            <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                          {/* </Button> */}
                        </div>
                       {/*  <div></div> */}
                      </Grid>
                    </Paper>
                  </Grid>
                  //#endregion
                }


              </Grid>
            </Paper>
          </Grid>
          //#endregion
        }
        {
          //#region JOURNEYS && USERS
          <Grid item xs={12} md={12} lg={6} xl={6} container rowSpacing={1} direction="row" minWidth={640}>
            {
              //#region  JOURNEYS
              <Grid item xs={12} height={315} >
                <Paper elevation={3} sx={{ padding: 0.5, height: "100%", borderRadius: "10px" }}>
                  <Grid xs={12} paddingLeft={0.5}><Typography fontSize={14} fontWeight={"bold"} color={"#707070"}>JOURNEYS</Typography></Grid>
                  <Grid container display={"flex"} justifyContent={"center"} alignItems={"center"} paddingLeft={2} paddingRight={2} columnSpacing={1} rowSpacing={1} marginTop={"0px"}  >
                    {
                      //#region Live Journeys
                      <Grid item xs={6} height={"200px"}  >
                        <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                          <Grid xs={12} height={25} maxHeight={25} paddingLeft={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                            sx={{
                              background: "#EFF7FF"
                            }}
                          >
                            <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Live Journeys</Typography>
                            <div >
                              <Tooltip arrow title={language?.liveJourneys} placement='top' >
                                <IconButton size='small'>
                                  <InfoOutlinedIcon fontSize='medium' />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Grid>
                          {
                            //#region content Live Journeys
                            <Grid xs={12} height={"100%"} display={"flex"} flexDirection={"column"} alignItems={"start"} justifyContent={"center"} padding={0.5}>
                              {/* <Box display={"flex"} justifyContent={"center"} width={"100%"}>
                                <Typography fontSize={40} fontWeight={"bold"} color={"#212529"}>
                                  {liveJourneys.live}
                                </Typography>
                              </Box> */}
                              <Box display={"flex"} justifyContent={"center"} width={"100%"}>
                                {
                                  !loadingGeneral &&
                                  <StatusBars live={liveJourneys.live} finished={liveJourneys.finished} paused={liveJourneys.paused} scheduled={liveJourneys.scheduled} totalCount={liveJourneys.total} />
                                }
                              </Box>
                            </Grid>
                            //#endregion
                          }
                          <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                            sx={{
                              background: "#EFF7FF"
                            }}
                          >
                           {/*  <div></div> */}
                            <div style={{ display: "flex", alignItems: "center",justifyContent:"center",cursor:"pointer",width:"100%" }} onClick={handleRouteJourneyList}>
                             {/*  <Button variant='text' size='small' onClick={handleRouteJourneyList}> */}
                                <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                                <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                              {/* </Button> */}
                            </div>
                            {/* <div></div> */}
                          </Grid>
                        </Paper>
                      </Grid>
                      //#endregion
                    }
                    {
                      //#region Latest Journeys
                      <Grid item xs={6} height={220} >
                        <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                          <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                            sx={{
                              background: "#EFF7FF"
                            }}
                          >
                            <Typography marginLeft={2} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Latest Journeys</Typography>

                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: 130 }}>
                              <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Progress</Typography>
                              <Tooltip arrow title={language?.latestJourneys} placement='top' >
                                <IconButton size='small' >
                                  <InfoOutlinedIcon fontSize='medium' />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Grid>
                          {
                            //#region content Latest Journeys
                            <Grid xs={12} height={"100%"} minWidth={290} display={"flex"} flexDirection={"column"} alignItems={"start"} justifyContent={"start"} padding={1}>
                              {
                                latestJourneys?.map((item: any, index) =>
                                  <Box key={index} display={"flex"} alignContent={"end"} justifyContent={"space-between"} width={"100%"}>
                                    <Box display={"flex"} justifyContent={"start"} alignItems={"center"} columnGap={2} paddingLeft={0.5} width={"100%"}>
                                      <Typography fontSize={20} color={"#707070"}>{index + 1}</Typography>

                                      <Typography fontSize={14} /* fontWeight={"bold"} */ color={"#000000"} >
                                        {`${capitalizeFirstLetter(item.journeyName)} `}
                                      </Typography>
                                    </Box>
                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"start"} mr={1}>
                                      <LinearProgress variant="determinate" value={item.progressRate} sx={{
                                        width: 115, height: 20, borderRadius: "0px 5px 5px 0px", zIndex: 1, backgroundColor: '#4caf5080',
                                        '& .MuiLinearProgress-bar': {
                                          backgroundColor: '#4CAF50',
                                        }
                                      }} />
                                      <Typography fontSize={14} color={"white"} marginTop={-2.6} zIndex={2} ml={"2px"} >
                                        {`${item.progressRate}%`}
                                      </Typography>
                                    </Box>
                                  </Box>
                                )
                              }
                            </Grid>
                            //#endregion
                          }
                          <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                            sx={{
                              background: "#EFF7FF"
                            }}
                          >
                           {/*  <div></div> */}
                            <div style={{ display: "flex", alignItems: "center",width:"100%",justifyContent:"center",cursor:"pointer" }} onClick={handleRouteJourneyList}>
                              {/* <Button variant='text' size='small' onClick={handleRouteJourneyList}> */}
                                <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                                <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                              {/* </Button> */}
                            </div>
                           {/*  <div></div> */}
                          </Grid>
                        </Paper>
                      </Grid>
                      //#endregion
                    }
                  </Grid>
                </Paper>
              </Grid>
              //#endregion
            }
            {
              //#region USERS
              <Grid item xs={12} height={286} >
                <Paper elevation={3} sx={{ padding: 1, height: "100%", borderRadius: "10px" }}>
                  <Grid xs={12}><Typography fontSize={14} fontWeight={"bold"} color={"#707070"}>USERS</Typography></Grid>
                  <Grid container display={"flex"} justifyContent={"center"} alignItems={"center"} paddingLeft={2} paddingRight={2} columnSpacing={1} rowSpacing={1} marginTop={"0px"}  >
                    {
                      //#region USERS
                      <Grid item xs={12} height={"235px"} >
                        <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                          <Grid xs={12} height={25} maxHeight={25} paddingLeft={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                            sx={{
                              background: "#EFF7FF"
                            }}
                          >
                            <Typography marginLeft={5} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>User Activeness</Typography>
                            <div >
                              <Tooltip arrow title={language?.userActiveness} placement='top' >
                                <IconButton size='small'>
                                  <InfoOutlinedIcon fontSize='medium' />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Grid>
                          {
                            //#region content User Activeness
                            <Grid xs={12} height={"auto"} display={"flex"} alignItems={"start"} justifyContent={"space-between"} padding={1}>
                              <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} width={200} height={"100%"}>
                                <Typography fontSize={40} fontWeight={"bold"} color={"#212529"}>
                                  {onlineUsers}
                                </Typography>
                                <Typography fontSize={16} color={"#212529"}>
                                  Online Users
                                </Typography>
                              </Box>
                              <Box sx={{ width: 430, minHeight: 100, height: "150px", paddingRight: 2 }}>
                                <ReactApexChart options={apexChartUserActiveness.options} series={apexChartUserActiveness.series} type="line" height={"100%"} />
                              </Box>
                            </Grid>
                            //#endregion
                          }
                          <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                            sx={{
                              background: "#EFF7FF"
                            }}
                          >
                          {/*   <div></div> */}
                            <div style={{ display: "flex", alignItems: "center",width:"100%",justifyContent:"center",cursor:"pointer" }} onClick={() => setOpenOnlineUserModal(true)}>
                              {/* <Button variant='text' size='small' > */}
                                <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                                <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                             {/*  </Button> */}
                            </div>
                           {/*  <div></div> */}
                          </Grid>
                        </Paper>
                      </Grid>
                      //#endregion
                    }
                  </Grid>
                </Paper>
              </Grid>
              //#endregion 
            }

          </Grid>
          //#endregion
        }
        {
          //#region PROGRESS 
          <Grid item xs={12} md={12} lg={6} xl={6} minWidth={640} height={802}>
            <Paper elevation={3} sx={{ padding: 1, height: "100%", borderRadius: "10px" }}>
              {
                //#region topBar Progress
                <Grid xs={12} height={30} display={"flex"} justifyContent={"space-between"} >
                  <Typography fontSize={14} fontWeight={"bold"} color={"#707070"}>PROGRESS</Typography>
                  <Box>
                    <Button variant='text' size='small' sx={{ fontSize: 12, textTransform: "none", fontWeight: "bold", textDecoration: timeIntervalProgress === 0 ? "underline" : "" }}
                      onClick={() => setTimeIntervalProgress(0)}
                    >Month to date</Button>
                    <Button variant='text' size='small' sx={{ fontSize: 12, textTransform: "none", fontWeight: "bold", textDecoration: timeIntervalProgress === 1 ? "underline" : "" }}
                      onClick={() => setTimeIntervalProgress(1)}
                    >Last month</Button>
                    <Button variant='text' size='small' sx={{ fontSize: 12, textTransform: "none", fontWeight: "bold", textDecoration: timeIntervalProgress === 2 ? "underline" : "" }}
                      onClick={() => setTimeIntervalProgress(2)}
                    >Last 3 months</Button>
                    <Button variant='text' size='small' sx={{ fontSize: 12, textTransform: "none", fontWeight: "bold", textDecoration: timeIntervalProgress === 3 ? "underline" : "" }}
                      onClick={() => setTimeIntervalProgress(3)}
                    >All time</Button>
                  </Box>
                </Grid>
                //#endregion
              }
              <Grid item container display={"flex"} justifyContent={"center"} paddingLeft={2} paddingRight={2} >
                <Grid xs={12} width={815} height={"auto"} >
                  <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                    <Grid xs={12} height={25} maxHeight={25} paddingLeft={4} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                      sx={{
                        background: "#EFF7FF"
                      }}
                    >
                      <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Completion Level</Typography>
                      <div>
                        <Tooltip arrow title={language?.completionLevel} placement='top' >
                          <IconButton size='small'>
                            <InfoOutlinedIcon fontSize='medium' />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Grid>
                    {
                      //#region content
                      <Grid xs={12} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <div style={{ position: "relative", display: "inline-block", height: "100%", paddingTop: 50, width: 180, marginLeft: -15 }}>
                          <GaugeChart
                            id="gauge-chart5"
                            nrOfLevels={420}
                            arcsLength={[1]}
                            colors={['#007DBF']}
                            percent={(completionLevel.gaugeChartPercent)}
                            arcPadding={0.02}
                            textColor={'#555555'}
                            needleColor={'#C9CED4'}
                            needleBaseColor={'#E0E0E0'}
                            hideText={true}
                          />
                          <div style={{ display: "flex", alignItems: "end", justifyContent: "center", marginTop: -70, paddingLeft: 15 }}>
                            <Typography fontSize={40} fontWeight={"bold"} color={"#212529"}>{completionLevel.gaugeChart}</Typography>
                            <Typography fontSize={25} fontWeight={"bold"} color={"#212529"}>%</Typography>
                          </div>
                        </div>
                        <Box marginLeft={-2.5} sx={{ height: 90, width: 115, border: "1px solid #D5D5D5", borderRadius: "5px", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", textAlign: "center" }}>
                          <Typography color={"#212529"} fontSize={14} fontWeight={"bold"}>{completionLevel.assignedActivities}</Typography>
                          <Typography color={"#212529"} fontSize={12} >Assigned activities</Typography>
                          <Typography color={"#212529"} fontSize={14} fontWeight={"bold"}>{completionLevel.completedActivities}</Typography>
                          <Typography color={"#212529"} fontSize={12} >Completed</Typography>
                        </Box>
                        {
                          (timeIntervalProgress === 0 || timeIntervalProgress === 1) &&
                          <Box sx={{ width: 430, minHeight: 150, height: "100%", paddingRight: 1 }}>
                            {
                              !loadingProgress &&
                              <ReactApexChart key={3} options={apexChartProgressCompletionLevel.options} series={apexChartProgressCompletionLevel.series} type="bar" height={"100%"} />
                            }
                          </Box>
                        }
                        {
                          (timeIntervalProgress === 2 || timeIntervalProgress === 3) &&
                          <Box sx={{ width: 430, minHeight: 131, height: "100%", paddingRight: 2 }}>
                            <ReactApexChart key={4} options={apexChartProgressCompletionLevelLastThreeMonth.options} series={apexChartProgressCompletionLevelLastThreeMonth.series} type="bar" height={"100%"} />
                          </Box>
                        }

                      </Grid>
                      //#endregion
                    }
                    <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                      sx={{
                        background: "#EFF7FF"
                      }}
                    >
                      {/* <div></div> */}
                      <div style={{ display: "flex", alignItems: "center",width:"100%",justifyContent:"center",cursor:"pointer" }} onClick={handleRouteActivity}>
                       {/*  <Button variant='text' size='small' onClick={handleRouteActivity}> */}
                          <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                          <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                       {/*  </Button> */}
                      </div>
                     {/*  <div></div> */}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container display={"flex"} justifyContent={"center"} paddingLeft={2} paddingRight={2} columnSpacing={1} rowSpacing={1} marginTop={1}  >
                {
                  //#region Activity Finishers
                  <Grid item xs={6} height={"306px"}  >
                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                      <Grid xs={12} height={25} maxHeight={25} paddingLeft={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                        <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Activity Finishers</Typography>
                        <div>
                          <Tooltip arrow title={language?.activityFinishers} placement='top' >
                            <IconButton size='small'>
                              <InfoOutlinedIcon fontSize='medium' />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                      {
                        //#region content
                        <Grid xs={12} height={"100%"} display={"flex"} flexDirection={"column"} alignItems={"start"} justifyContent={"start"} rowGap={1} padding={1}>
                          {
                            activityFinishers?.map((item: any, index) =>
                              <Box key={index} display={"flex"} alignContent={"end"} justifyContent={"space-between"} width={"100%"}>
                                <Box display={"flex"} justifyContent={"start"} alignItems={"center"} columnGap={1} width={"100%"}>
                                  <Typography fontSize={20} color={"#707070"}>{index + 1}</Typography>
                                  <Avatar src={item.avatarPath} />
                                  <Typography fontSize={14} /* fontWeight={"bold"} */ color={"#000000"} >
                                    {`${capitalizeFirstLetter(item.name ? item.name : "")} ${capitalizeFirstLetter(item.surname ? item.surname : "")}`}
                                  </Typography>
                                </Box>
                                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"start"} ml={2} paddingRight={2}>
                                  <Typography fontSize={14} fontWeight={"bold"} color={"#000000"} zIndex={2} ml={"2px"} >
                                    {`${item.userCompletedActivityCount}`}
                                  </Typography>
                                </Box>
                              </Box>
                            )
                          }
                        </Grid>
                        //#endregion
                      }
                      <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                      {/*   <div></div> */}
                        <div style={{ display: "flex", alignItems: "center",width:"100%",justifyContent:"center",cursor:"pointer" }} onClick={handleRouteUsers}>
                          {/* <Button variant='text' size='small' onClick={handleRouteUsers}> */}
                            <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                            <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                          {/* </Button> */}
                        </div>
                      {/*   <div></div> */}
                      </Grid>
                    </Paper>
                  </Grid>
                  //#endregion
                }
                {
                  //#region Completed Activities
                  <Grid item xs={6} height={"auto"} >
                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                      <Grid xs={12} height={25} maxHeight={25} paddingLeft={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                        <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Completed Activities</Typography>
                        <div>
                          <Tooltip arrow title={language?.completedActivities} placement='top' >
                            <IconButton size='small'>
                              <InfoOutlinedIcon fontSize='medium' />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                      {
                        //#region content
                        <Grid xs={12} height={"100%"} display={"flex"} flexDirection={"column"} alignItems={"start"} justifyContent={"start"} rowGap={1} padding={1}>
                          {
                            completedActivities?.map((item: any, index) =>
                              <Box key={index} display={"flex"} alignContent={"end"} justifyContent={"space-between"} width={"100%"}>
                                <Box display={"flex"} justifyContent={"start"} alignItems={"center"} columnGap={1} width={"100%"}>
                                  <Typography fontSize={20} color={"#707070"}>{index + 1}</Typography>
                                  <Typography fontSize={14} /* fontWeight={"bold"} */ color={"#000000"} >
                                    {`${capitalizeFirstLetter(item.name)}`}
                                  </Typography>
                                </Box>
                                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"start"} paddingRight={2} ml={2}>
                                  <Typography fontSize={14} fontWeight={"bold"} color={"#000000"} zIndex={2} ml={"2px"} >
                                    {`${item.completedActivities}`}
                                  </Typography>
                                </Box>
                              </Box>
                            )
                          }
                        </Grid>
                        //#endregion
                      }
                      <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                     {/*    <div></div> */}
                        <div style={{ display: "flex", alignItems: "center",justifyContent:"center",width:"100%",cursor:"pointer" }} onClick={handleRouteActivity}>
                         {/*  <Button variant='text' size='small' onClick={handleRouteActivity}> */}
                            <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                            <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                          {/* </Button> */}
                        </div>
                        {/* <div></div> */}
                      </Grid>
                    </Paper>
                  </Grid>
                  //#endregion
                }
                {
                  //#region Avg. TimeSpent
                  <Grid item xs={11} display={"flex"} justifyContent={"center"} height={"auto"} >
                    <Paper elevation={3} sx={{ height: "100%", width: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                      <Grid xs={12} height={25} maxHeight={25} paddingLeft={9} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                        <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Avg. Time Spend</Typography>
                        <div>
                          <Tooltip arrow title={language?.avgTimeSpend} placement='top' >
                            <IconButton size='small'>
                              <InfoOutlinedIcon fontSize='medium' />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                      {
                        //#region content
                        <Grid xs={12} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} padding={1}>
                          <Box sx={{/*  border: "1px solid #D5D5D5", */ borderRadius: "5px", width: 280, display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", textAlign: "center" }}>
                            <Box display={"flex"} alignItems={"end"} textAlign={"center"} justifyContent={"center"} >
                              {avgTimeSpend.hours > 0 && (
                                <React.Fragment>
                                  <Typography color={"#212529"} fontSize={40} fontWeight={"bold"} sx={{ height: 55 }}>
                                    {avgTimeSpend.hours}
                                  </Typography>
                                  <Typography color={"#212529"} fontSize={25}>
                                    hr
                                  </Typography>
                                </React.Fragment>
                              )}
                              <Typography color={"#212529"} marginLeft={1} fontSize={40} fontWeight={"bold"} sx={{ height: 55 }}>{avgTimeSpend.minutes}</Typography>
                              <Typography color={"#212529"} fontSize={25}  >min</Typography>
                            </Box>
                            <Typography color={"#212529"} fontSize={16} >{timeIntervalProgress === 0 || timeIntervalProgress === 1 ? "per day" : "per month"}</Typography>
                          </Box>
                          {
                            (timeIntervalProgress === 1 || timeIntervalProgress === 0 || timeIntervalProgress === 3) &&
                            <Box sx={{ width: "100%", height: "100%", minHeight: 150, paddingRight: 2 }}>
                              {
                                !loadingProgress &&
                                <ReactApexChart key={15} options={apexChartTimeSpend.options} series={apexChartTimeSpend.series} type="line" height={"100%"} />
                              }
                            </Box>
                          }
                          {
                            timeIntervalProgress === 2 &&
                            <Box sx={{ width: "100%", height: "100%", paddingRight: 2 }} >
                              <ReactApexChart key={16} options={apexChartProgressTimeSpendLastThreeMonth.options} series={apexChartProgressTimeSpendLastThreeMonth.series} type="bar" height={"100%"} />
                            </Box>
                          }
                        </Grid>
                        //#endregion
                      }
                      <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                       {/*  <div></div> */}
                        <div style={{ display: "flex", alignItems: "center",justifyContent:"center",width:"100%",cursor:"pointer" }} onClick={handleRouteUsers}>
                         {/*  <Button variant='text' size='small' onClick={handleRouteUsers}> */}
                            <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                            <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                         {/*  </Button> */}
                        </div>
                      {/*   <div></div> */}
                      </Grid>
                    </Paper>
                  </Grid>
                  //#endregion
                }

              </Grid>
            </Paper>
          </Grid>
          //#endregion
        }
        {
          //#region CONTENT 
          <Grid item xs={12} md={12} lg={6} xl={6} minWidth={640} height={495}>
            <Paper elevation={3} sx={{ padding: 1, height: "100%", borderRadius: "10px" }}>
              <Grid xs={12} height={20} display={"flex"} ><Typography fontSize={14} fontWeight={"bold"} color={"#707070"}>CONTENT</Typography></Grid>
              <Grid container display={"flex"} justifyContent={"center"} paddingLeft={2} paddingRight={2} columnSpacing={2} rowSpacing={2} marginTop={0} >
                {
                  //#region Questions
                  <Grid item xs={6} height={"auto"}  >
                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                      <Grid xs={12} height={25} maxHeight={25} paddingLeft={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                        <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Questions</Typography>
                        <div>
                          <Tooltip arrow title={language?.questions} placement='top' >
                            <IconButton size='small'>
                              <InfoOutlinedIcon fontSize='medium' />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                      {
                        //#region content Questions
                        <Grid xs={12} height={"100%"} display={"flex"} alignItems={"start"} justifyContent={"center"} padding={1}>
                          <Box width={"100%"} height={"180px"} >
                            <Box display={"flex"} justifyContent={"center"} >
                              <Typography fontSize={20} fontWeight={"bold"} color={"#212529"} zIndex={2} position={"absolute"} marginTop={7.7} marginLeft={0.5}>{chartQuestionsNumber}</Typography>
                            </Box>
                            <ReactApexChart options={apexChartQuestions.options} series={apexChartQuestions.series} type="donut" height={400} />
                          </Box>
                        </Grid>
                        //#endregion
                      }
                      <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                      {/*   <div></div> */}
                        <div style={{ display: "flex", alignItems: "center",justifyContent:"center",width:"100%",cursor:"pointer",zIndex:2 }} onClick={handleRouteQuestionList}>
                          {/* <Button variant='text' size='small' onClick={handleRouteQuestionList}> */}
                            <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                            <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                          {/* </Button> */}
                        </div>
                       {/*  <div></div> */}
                      </Grid>
                    </Paper>
                  </Grid>
                  //#endregion
                }
                {
                  //#region Activities
                  <Grid item xs={6} height={"auto"} >
                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                      <Grid xs={12} height={25} maxHeight={25} paddingLeft={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                        <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Activities</Typography>
                        <div>
                          <Tooltip arrow title={language?.activities} placement='top' >
                            <IconButton size='small'>
                              <InfoOutlinedIcon fontSize='medium' />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                      {
                        //#region content Activities
                        <Grid xs={12} height={"100%"} display={"flex"} alignItems={"start"} justifyContent={"center"} padding={1}>
                          <Box width={"100%"} height={"180px"} >
                            <Box display={"flex"} justifyContent={"center"} >
                              <Typography fontSize={20} fontWeight={"bold"} color={"#212529"} zIndex={2} position={"absolute"} marginTop={7.7} marginLeft={0.5}>{chartActivitiesNumber}</Typography>
                            </Box>
                            <ReactApexChart options={apexChartActivities.options} series={apexChartActivities.series} type="donut" height={400} />
                          </Box>
                        </Grid>
                        //#endregion
                      }
                      <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                       {/*  <div></div> */}
                        <div style={{ display: "flex", alignItems: "center",justifyContent:"center",width:"100%",cursor:"pointer",zIndex:2 }} onClick={handleRouteActivityList}>
                          {/* <Button variant='text' size='small' onClick={handleRouteActivityList}> */}
                            <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                            <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                        {/*   </Button> */}
                        </div>
                     {/*    <div></div> */}
                      </Grid>
                    </Paper>
                  </Grid>
                  //#endregion
                }
                {
                  //#region  Categories
                  <Grid item xs={6} display={"flex"} justifyContent={"center"} height={"auto"} >
                    <Paper elevation={3} sx={{ height: "100%", width: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                      <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                        <div></div>
                        <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Categories</Typography>
                        <div>
                          <Tooltip arrow title={language?.categories} placement='top' >
                            <IconButton size='small' >
                              <InfoOutlinedIcon fontSize='medium' />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                      {
                        //#region content
                        <Grid xs={12} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} padding={1}>
                          <Grid xs={6} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                            <Box display={"flex"} alignItems={"end"} textAlign={"center"} justifyContent={"center"} >
                              <Typography color={"#212529"} fontSize={40} fontWeight={"bold"} >{categories.main}</Typography>
                            </Box>
                            <Typography color={"#212529"} fontSize={25} >main</Typography>
                          </Grid>
                          <Grid xs={6} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                            <Box display={"flex"} alignItems={"end"} textAlign={"center"} justifyContent={"center"} >
                              <Typography color={"#212529"} fontSize={40} fontWeight={"bold"} >{categories.sub}</Typography>
                            </Box>
                            <Typography color={"#212529"} fontSize={25} >sub</Typography>
                          </Grid>

                        </Grid>
                        //#endregion
                      }
                      <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"center"} alignItems={"center"}
                        sx={{
                          background: "#EFF7FF"
                        }}
                      >
                       {/*  <div></div> */}
                        <div style={{ display: "flex", alignItems: "center",width:"100%",justifyContent:"center",cursor:"pointer" }} onClick={handleRouteCategoryList}>
                          {/* <Button variant='text' size='small' onClick={handleRouteCategoryList}> */}
                            <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                            <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                          {/* </Button> */}
                        </div>
                       {/*  <div></div> */}
                      </Grid>
                    </Paper>
                  </Grid>
                  //#endregion
                }
              </Grid>
            </Paper>
          </Grid>
          //#endregion
        }
      </Grid>
      <OnlineUsersModal open={openOnlineUserModal} setOpen={setOpenOnlineUserModal} />
    </>
  )
}

export { CustomerDashboardWrapper }
