import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { combineReducers } from 'redux';
import rootSaga from '../sagas/';
import history from './history';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable'

const transformerConfig = {
    whitelistPerReducer: {
    },
}


const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: seamlessImmutableReconciler,
    transforms: [seamlessImmutableTransformCreator(transformerConfig)]
}


/* ------------- Redux Configuration ------------- */
const middleware = []
const enhancers = []

/* ------------- Saga Middleware ------------- */
const sagaMiddleware = createSagaMiddleware()
middleware.push(sagaMiddleware)
middleware.push(routerMiddleware(history))
/* ------------- Assemble Middleware ------------- */
enhancers.push(applyMiddleware(...middleware))


const createAppropriateStore = createStore


const appReducer = combineReducers({
    Login: require('./LoginRedux').reducer,
    Question: require('./QuestionRedux').reducer,
})


const rootReducer = (state, action) => {
    if (action.type === 'CLEAR_REDUX') //Clear state data when user logout
    state = undefined
    return appReducer(state, action)
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createAppropriateStore(persistedReducer, compose(...enhancers))

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
