import axios from 'axios'
const create = () => {
  const QuestionList = (body) => {

    axios
      .get('http://127.0.0.1:8000/content/question/getAll', {
        headers: {Authorization: `Bearer ${body.acces_token}`},
      })
        .then(function (response) {
        if (response.ok) {
          return response.json()
        } else {
         return {'error':true,'status':response.status,'text':response.statusText}
        }
      })
      .then(function (json) {
        return json
      })
      .catch((error) => { 

        return {'error':true,'text':error}
      })
  }
  return {QuestionList}
}
export default {create}
