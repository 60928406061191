import {Avatar, Box, Grid, Paper, Radio, Typography} from '@mui/material'
import React from 'react'
import {Modal} from 'react-bootstrap'

type JourneyAwardPreviewProps = {
  show: boolean
  setShow: (show: boolean) => void
  journeyId: any
  awardDetail: any
  journeyName: any
}

function JourneyAwardPreview({
  show,
  setShow,
  journeyId,
  awardDetail,
  journeyName,
}: JourneyAwardPreviewProps) {
  function handleClose(): void {
    setShow(false)
  }

  const renderContent = (count:any) => {
    switch (count) {
      case 1:
        return <Typography variant='h6' fontWeight={'bold'} color={'black'}>Champion</Typography>
      case 2:
        return <Typography variant='h6' fontWeight={'bold'} color={'black'}>3 winners</Typography>
      case 3:
        return <Typography variant='h6' fontWeight={'bold'} color={'black'}>Top 10</Typography>
      case 4:
        return <Typography variant='h6' fontWeight={'bold'} color={'black'}>Top 25</Typography>
      case 5:
        return <Typography variant='h6' fontWeight={'bold'} color={'black'}>Top 100</Typography>
      case 6:
        return <Typography variant='h6' fontWeight={'bold'} color={'black'}>All finishers</Typography>
      default:
        return <Typography variant='h6' fontWeight={'bold'} color={'black'}>None</Typography>
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{padding: '10px20px10px10px'}}>
          <Grid container justifyContent={'center'}>
            <Grid xs={1}>
              <Avatar src='/media/journey/award_ok.png' />
            </Grid>
            <Grid xs={11} display={'flex'} justifyContent={'center'}>
              <Typography fontSize={'26px'} fontWeight={'bold'}>
                {' '}
                {journeyName}
              </Typography>
            </Grid>
          </Grid>
        </Modal.Header>
        <Modal.Body style={{height: '600px'}}>
          <Grid container>
            <Grid xs={12} display={'flex'} justifyContent={'center'}>
              <Typography variant='h5' fontWeight={'bold'} color={'#6C757D'}>
                Award
              </Typography>
            </Grid>
            <Grid xs={12} marginTop={3} display={'flex'} justifyContent={'center'} alignItems={"center"}>
              <Typography variant='h6' color={'#6C757D'}>
                Award winners
              </Typography>
              <Box display={"flex"} alignItems={"center"}>
                <Radio
                  checked={true}
                  name='radio-buttons'
                />
                  {
                  renderContent(awardDetail.journeyAwardWinners)
                  }
              </Box>
            </Grid>
            <Grid xs={12} marginTop={1} display={'flex'} justifyContent={'center'} alignItems={"center"}>
            <Box style={{width:"300px",height:"170px",border:"1px solid #E5E5E5",borderRadius:"5px",overflow:"hidden",marginTop:"10px",marginBottom:"10px"}}>
              <Avatar variant='square' src={awardDetail.journeyAwardMediaUrl} style={{height:"100%",width:"100%",objectFit:"contain"}} />
            </Box>
            </Grid>
            <Grid xs={12} marginTop={1} display={'flex'} justifyContent={'center'} alignItems={"center"}>
            <Box style={{width:"350px",height:"200px",border:"1px solid #E5E5E5",padding:"5px",
            borderRadius:"5px",overflowY:"auto",marginTop:"10px",marginBottom:"10px",
            display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center" }}>
                <Typography width={"100%"} height={"100%"} fontSize={"15px"}>{awardDetail.journeyAwardMediaText}</Typography>
            </Box>
            </Grid>
          </Grid>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default JourneyAwardPreview
