/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {Routes, Route, useNavigate} from 'react-router-dom'
import { ProgressBar } from 'react-bootstrap'

//import {Dropdown1} from '../../content/dropdown/Dropdown1'
//import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
}
const ContentDashboardWidged: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  strokeColor,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const navigatetoQuestion = () => {
    // 👇️ navigate to /
    navigate('/manage/QuestionPage')
  }
  const navigatetoVideo = () => {
    // 👇️ navigate to /
    navigate('/manage/dashboard/')
  }
  const navigatetoImage = () => {
    // 👇️ navigate to /
    navigate('/manage/dashboard/')
  }
  const navigatetoCategories = () => {
    // 👇️ navigate to /
    navigate('/manage/content/AddCategoryWrapper')
  }
  const navigatetoKnowledgeCards = () => {
    // 👇️ navigate to /
    navigate('/manage/content/knowledge-cards')
  }
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {

    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor, strokeColor)
    )
    if (chart) {
      chart.render()
    }
    return chart
  }
  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])
  const now = 75;

  return (
    <div className={`card mt-5 ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 bg-${chartColor}`}>
        <h3 className='card-title fw-bold text-white'>Content Dashboard</h3>
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div
          ref={chartRef}
          className={`mixed-widget-2-chart card-rounded-bottom bg-${chartColor}`}
        ></div>
        {/* end::Chart */}
        {/* begin::Stats */}
        <div className='card-p mt-n20 position-relative'>
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            <button
              className='btn bg-category border-0 btn text-start  col  px-6 py-8 rounded-2 mb-7 me-7 '
              onClick={navigatetoCategories}
              
            >
              <span className='  svg-icon svg-icon-3x svg-icon-white  my-2'
              style={{width: 'auto', display: 'block'}}
              >
                <svg
                  className='flex'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path opacity='0.3' d='M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z' fill='currentColor' />
                  <path
                    opacity='0.3'
                    d='M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z'
                    fill='currentColor'
                  />
                  <path
                    d='M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <div className=' display-5 text-white'>{/*value of total Categories*/}160</div>
              <span className='fw-semobold fs-6 text-white'>
                {intl.formatMessage({id: 'DASHBOARD.CATEGORIES'})}
              </span>
            </button>
            <button
              onClick={navigatetoQuestion}
              className='   bg-question  text-start btn col  px-6 py-8 rounded-2 me-7 mb-7'
            >
              <span
                className='  svg-icon svg-icon-3x svg-icon-white opacity-75  '
                style={{width: 'auto', display: 'block'}}
              >
                <svg
                  className='flex'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    opacity='0.3'
                    d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                    fill='currentColor'
                  />
                  <path
                    d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <div className=' text-white  display-5'>{/*value of total question*/}160</div>
              <span className='text-white fw-semobold fs-6 mt-5'>
                {intl.formatMessage({id: 'DASHBOARD.QUESTIONS'})}
              </span>
            </button>
            
            {/* end::Col */}
            <button
              onClick={navigatetoImage}
              className='text-start bg-pictures col px-6 py-8 rounded-2 btn mb-7'
            >
              <span className='  svg-icon svg-icon-3x svg-icon-white  opacity-75'>
                <svg
                  className='flex'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    opacity='0.3'
                    d='M22 5V19C22 19.6 21.6 20 21 20H19.5L11.9 12.4C11.5 12 10.9 12 10.5 12.4L3 20C2.5 20 2 19.5 2 19V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5ZM7.5 7C6.7 7 6 7.7 6 8.5C6 9.3 6.7 10 7.5 10C8.3 10 9 9.3 9 8.5C9 7.7 8.3 7 7.5 7Z'
                    fill='currentColor'
                  />
                  <path
                    d='M19.1 10C18.7 9.60001 18.1 9.60001 17.7 10L10.7 17H2V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V12.9L19.1 10Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <div className=' text-white  display-5'>{/*value of total Pictures*/} 160</div>
              <span className='text-white fw-semobold fs-6 mt-5'>
                {intl.formatMessage({id: 'DASHBOARD.PICTURES'})}
              </span>
            </button>
            {/* begin::Col */}
            
            {/* end::Col */}
          </div>
          {/* end::Row */}
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            
            <button
              onClick={navigatetoVideo}
              className='text-start btn col bg-video  px-6 py-8 rounded-2 me-7 mb-7'
            >
              <span className='  svg-icon svg-icon-3x svg-icon-white  my-2'>
                <svg
                  className='flex'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    d='M21 6.30005C20.5 5.30005 19.9 5.19998 18.7 5.09998C17.5 4.99998 14.5 5 11.9 5C9.29999 5 6.29998 4.99998 5.09998 5.09998C3.89998 5.19998 3.29999 5.30005 2.79999 6.30005C2.19999 7.30005 2 8.90002 2 11.9C2 14.8 2.29999 16.5 2.79999 17.5C3.29999 18.5 3.89998 18.6001 5.09998 18.7001C6.29998 18.8001 9.29999 18.8 11.9 18.8C14.5 18.8 17.5 18.8001 18.7 18.7001C19.9 18.6001 20.5 18.4 21 17.5C21.6 16.5 21.8 14.9 21.8 11.9C21.8 9.00002 21.5 7.30005 21 6.30005ZM9.89999 15.7001V8.20007L14.5 11C15.3 11.5 15.3 12.5 14.5 13L9.89999 15.7001Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <div className=' text-white display-5'>{/*value of total Video*/}160</div>
              <span className='text-white fw-semobold fs-6'>
                {intl.formatMessage({id: 'DASHBOARD.VIDEO'})}
              </span>
            </button>
            <button 
            className='text-start btn col bg-knowledgecard px-6 py-8 rounded-2 me-7 mb-7 ' 
            onClick={navigatetoKnowledgeCards}
            >
              <span className='  svg-icon svg-icon-3x svg-icon-white opacity-75  my-2'>
                <svg
                  className='flex svg-icon-white'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    opacity='0.3'
                    d='M21 22H14C13.4 22 13 21.6 13 21V3C13 2.4 13.4 2 14 2H21C21.6 2 22 2.4 22 3V21C22 21.6 21.6 22 21 22Z'
                    fill='currentColor'
                  />
                  <path
                    d='M10 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H10C10.6 2 11 2.4 11 3V21C11 21.6 10.6 22 10 22Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <div className=' text-white display-5'>{/*value of total knowlodge-card*/}160</div>
              <span className='text-white fw-semobold fs-6'>
                {' '}
                {intl.formatMessage({id: 'DASHBOARD.KNOWLEDGECARDS'})}
              </span>
            </button>

            {/* end::Col */}
            <button className='text-start  col bg-memorycard  px-6 py-8 rounded-2  mb-7 btn'>
              <span className='  svg-icon svg-icon-3x svg-icon-white opacity-75  my-2'>
                <svg
                  className='flex'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    d='M4.5 7C5.88071 7 7 5.88071 7 4.5C7 3.11929 5.88071 2 4.5 2C3.11929 2 2 3.11929 2 4.5C2 5.88071 3.11929 7 4.5 7Z'
                    fill='currentColor'
                  />
                  <path
                    opacity='0.3'
                    d='M14 4.5C14 5.9 12.9 7 11.5 7C10.1 7 9 5.9 9 4.5C9 3.1 10.1 2 11.5 2C12.9 2 14 3.1 14 4.5ZM18.5 2C17.1 2 16 3.1 16 4.5C16 5.9 17.1 7 18.5 7C19.9 7 21 5.9 21 4.5C21 3.1 19.9 2 18.5 2ZM4.5 9C3.1 9 2 10.1 2 11.5C2 12.9 3.1 14 4.5 14C5.9 14 7 12.9 7 11.5C7 10.1 5.9 9 4.5 9ZM11.5 9C10.1 9 9 10.1 9 11.5C9 12.9 10.1 14 11.5 14C12.9 14 14 12.9 14 11.5C14 10.1 12.9 9 11.5 9ZM18.5 9C17.1 9 16 10.1 16 11.5C16 12.9 17.1 14 18.5 14C19.9 14 21 12.9 21 11.5C21 10.1 19.9 9 18.5 9ZM4.5 16C3.1 16 2 17.1 2 18.5C2 19.9 3.1 21 4.5 21C5.9 21 7 19.9 7 18.5C7 17.1 5.9 16 4.5 16ZM11.5 16C10.1 16 9 17.1 9 18.5C9 19.9 10.1 21 11.5 21C12.9 21 14 19.9 14 18.5C14 17.1 12.9 16 11.5 16ZM18.5 16C17.1 16 16 17.1 16 18.5C16 19.9 17.1 21 18.5 21C19.9 21 21 19.9 21 18.5C21 17.1 19.9 16 18.5 16Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <div className=' text-white display-5'>{/*value of total memory-card*/}160</div>
              <span className='text-white fw-semobold fs-6'>
                {intl.formatMessage({id: 'DASHBOARD.MEMORYCARDS'})}
              </span>
            </button>
            {/* begin::Col */}
            
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
  )
}
const chartOptions = (
  chartHeight: string,
  chartColor: string,
  strokeColor: string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const color = getCSSVariableValue('--kt-' + chartColor)
  return {
    series: [
      {
        name: 'Net Profit',
        data: [40, 55, 42, 80, 50, 45, 70],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 100,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      enabled: false,
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['transparent'],
    markers: {
      colors: [color],
      strokeColors: [strokeColor],
      strokeWidth: 3,
    },
  }
}
export {ContentDashboardWidged}
