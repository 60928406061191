import React, {FC, useState} from 'react'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'

type Props = {
  show: boolean
  setShow: (isUserLoading: boolean) => void
  Gdpr: any
  gdprStatement: boolean | undefined
}

export const ShowGdpr: FC<Props> = ({show, setShow, Gdpr, gdprStatement}) => {
  const handleClose = () => setShow(false)

  function gdpr() {
    if (gdprStatement === true) {
      return (
        <Button
          variant='text'
          style={{padding: '0px',fontWeight:"bold",color:"#009ef7"}}
          onClick={() => setShow(true)}
        >
          Show
        </Button>
      )
    } else {
      return <></>
    }
  }
  return (
    <>
      {gdpr()}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header >
        <div className='w-100 d-flex justify-content-between'>
            <div className='h2 my-auto'>GDPR</div>
            <a onClick={handleClose} className='btn btn-icon btn-secondary rounded-circle btn-sm'>
              <i className='fa-solid fa-x'></i>{' '}
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div
            className='scroll scroll-pull card'
            data-scroll='true'
            data-suppress-scroll-x='false'
            data-swipe-easing='false'
            style={{height: '300px'}}
          >
            <div dangerouslySetInnerHTML={{__html: Gdpr}} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size='small'  onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
