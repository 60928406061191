import React, { FC, useState } from 'react'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import { DialogContentText, IconButton, Slide, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { TransitionProps } from '@mui/material/transitions'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CloseIcon from '@mui/icons-material/Close'

type Props = {
  customerID: number
  show: any
  setShow: any
  gdprData: any
  gdprAccept: any
  setGdprAccept: any
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export const GDPRModal: FC<Props> = ({
  customerID,
  show,
  setShow,
  gdprAccept,
  setGdprAccept,
  gdprData,
}) => {
  const handleClose = () => setShow(false)

  const [openWarnModal, setOpenWarnModal] = useState<any>(false)

  function handleClickWarnModalClose() {
    setOpenWarnModal(false)
  }

  return (
    <>
      <Modal show={show} /* onHide={handleClose} */ size='lg' animation={false}>
        <Modal.Header>
          <div className='w-100 d-flex justify-content-between'>
            {!gdprData ? (
              <div className='h2 my-auto'>GDPR Statement - MOBIXA Platform</div>
            ) : (
              <div className='h2 my-auto'>GDPR Statement - {gdprData.customerName} </div>
            )}

            <a onClick={handleClose} className='btn btn-icon btn-secondary rounded-circle btn-sm'>
              <i className='fa-solid fa-x'></i>{' '}
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: '400px', overflow: 'scroll' }}>
            {!gdprData ? (
              <div
                data-mesh-id='comp-l9muqdqi1inlineContent-gridContainer'
                data-testid='mesh-container-content'
              >
                <div
                  id='comp-k7aaa4eg'
                  className='KcpHeO tz5f0K comp-k7aaa4eg wixui-rich-text'
                  data-testid='richTextElement'
                >
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        <span style={{ fontWeight: 'bold' }} className='wixui-rich-text__text'>
                          Notification In Accordance With the General Data Protection Regulation
                        </span>
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        <span style={{ fontWeight: 'bold' }} className='wixui-rich-text__text'>
                          <span className='wixGuard wixui-rich-text__text'>​</span>
                        </span>
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The purpose of this Statement is to define the terms and conditions of usage
                        of any confidential information shared by the users (Users) to the websites
                        administered by SmartCV Technology Inc. (Company). These sites are&nbsp;
                        <span
                          style={{ textDecoration: 'underline' }}
                          className='wixui-rich-text__text'
                        >
                          <a
                            href='https://mobixa.net/'
                            target='_blank'
                            rel='noreferrer noopener'
                            className='wixui-rich-text__text'
                          >
                            https://mobixa.net
                          </a>
                        </span>
                        &nbsp;
                        <span
                          style={{ textDecoration: 'underline' }}
                          className='wixui-rich-text__text'
                        >
                          <a
                            href='https://game.mobixa.net/'
                            target='_blank'
                            rel='noreferrer noopener'
                            className='wixui-rich-text__text'
                          >
                            https://game.mobixa.net
                          </a>
                        </span>
                        &nbsp;(Site) and MOBIXA Mobile application (Mobile Application) ( Hereon to
                        be named, along with the sites and mobile application as PLATFORM).
                        Definitions in the User Agreement will be taken into account in the
                        interpretation of the expressions not defined in this Notice. By accepting
                        the User Agreement, the User will be deemed to have accepted this Notice.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <br className='wixui-rich-text__text' />
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        <span style={{ fontWeight: 'bold' }} className='wixui-rich-text__text'>
                          Which Data Do We Process?
                        </span>
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The company collects the following data provided by the user in digital
                        environment, during the membership process.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Name, surname, picture, business or private e-mail
                        address, telephone number, home or workplace address,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Password and similar security information used for
                        authentication and account access,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Demographic data such as age, gender, country,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Data required for payment transaction,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Any completed survey by the user that is sent by the
                        Company. These surveys will be sent on a voluntary basis and are not
                        required to be answered.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; As a result of you playing the game, any questions
                        you have completed while playing the game and any statistics that indicate
                        your visual auditory and phonetic skills.&nbsp;
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The Company can obtain information about the Users’ use of the Platform by
                        using cookies (Cookies), which are technical communication files. In order
                        to determine your access to the services offered on the platforms and your
                        usage habits, the following data can be collected via cookies.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Scanner Type
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; IP number
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; OS,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Pages Viewed
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Display Time
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Mobile device type
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Mobile device operating system
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Your instant location
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The data that are irreversibly anonymized by Articles 3 and 7 of the Law on
                        the Protection of Personal Data will not be considered as personal data in
                        accordance with the provisions of the aforementioned law and the processing
                        activities related to these data will be carried out without being bound by
                        this Statement.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        <span style={{ fontWeight: 'bold' }} className='wixui-rich-text__text'>
                          For What Purposes Do We Use Your Data?
                        </span>
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The Company will utilize any data provided by Users, and any further
                        resulting data created by the Company on the basis of provided data by Users
                        with the purpose of increasing users’ benefits provided by the Platforms,
                        the improvement of Platforms for the future, compliance with any laws and
                        for the purposes highlighted in this agreement.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <br className='wixui-rich-text__text' />
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The Company may obtain information about the User's use of the Platforms by
                        using cookies (Cookies), which are technical communication files, process
                        the data in this context and transmit it to third parties for the purpose of
                        utilizing the analytical support and services offered by such parties and
                        only to the minimum extent required by such parties to conduct a beneficial
                        analysis. The mentioned technical communication files are small text files
                        that the Platforms send to the User's browser to be stored in the main
                        memory. The company may also collect this information with its own mobile
                        application. The technical communication file facilitates the use of the
                        internet in this sense by storing the status and preference settings about a
                        website. The technical communication file includes how many people use the
                        Platforms at which times, for what purpose, how many times they visit the
                        Platforms and how long they stay, to obtain statistical information about
                        the usage success rate and demographics, and to dynamically generate content
                        from user pages specially designed for users. It is designed to assist and
                        is used for these purposes. The technical communication file is not designed
                        to receive any other personal data from the main memory. Most of the
                        browsers are initially designed to accept the technical communication file,
                        but users can always change the browser settings so that the technical
                        communication file does not arrive, or the technical communication file is
                        sent.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The Company determines and uses the IP address of the Users, when necessary,
                        in order to identify problems related to the system and to resolve the
                        problems urgently. IP addresses can also be used to identify Users in
                        general and to collect comprehensive demographic information.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        <span style={{ fontWeight: 'bold' }} className='wixui-rich-text__text'>
                          Who Can Access Your Data?
                        </span>
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The Company utilizes data provided by the Users and the data which is
                        created by the Company through provided data for the purpose of providing
                        appropriate and customized services for the user, to comply with the clauses
                        highlighted in the Users Agreement and Notification, to better and customize
                        services provided by the Company, to elevate services provided by the
                        Company and for any purpose highlighted under “For What Purposes we use your
                        Data” in this notification. Third Parties, for the purpose of further
                        analysis will be used, only to the minimum extent to which such analyses
                        require provided data.&nbsp;
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        Your data will never be shared for advertising and promotional purposes.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The Company will also be able to process the data and share it with third
                        parties in accordance with Articles 5 and 8 of the Personal Data Protection
                        Law and / or in case of exceptions in the relevant legislation, without
                        obtaining the User's consent. The main cases are as follows:
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; It is clearly prescribed in the laws,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; In cases of life-threatening danger to the user or
                        any other persons whereby disclosure of data is required and if the user may
                        not physically disclose data or does not offer any legally required
                        excuse.&nbsp;
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; In cases where data is required to establish or
                        pursue an agreement, including the User Agreement and whereby the data is
                        directly related to such agreements.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; It is mandatory to fulfill legal obligations,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; It has been made public by the user themselves
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Data processing is mandatory for the establishment,
                        use or protection of a right
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; In cases where, for the legitimate uses of the
                        company, data processing is required, provided that such data does not harm
                        the personal rights and freedom of the user.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        Limited to the fulfillment of the above-mentioned purposes, the Company has
                        the right to transfer personal data to its servers located anywhere in the
                        world (the servers may belong to itself, its affiliates, subcontractors or
                        outsourcing service providers) in order to receive hosting services.&nbsp;
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        <span style={{ fontWeight: 'bold' }} className='wixui-rich-text__text'>
                          Your Right to Access Your Data and Correction Requests
                        </span>
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The user, by applying to the Company reserves the right to
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Learn whether personal data is processed,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; If personal data has been processed, to request
                        information regarding this,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; The purpose of processing personal data and whether
                        they are used in accordance with their purpose,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; To ascertain the third parties to whom personal data
                        are transferred,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Correct personal data in case of incomplete or
                        incorrect processing
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; Deletion of personal data within the framework of the
                        conditions stipulated in the relevant legislation.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; To notify any third parties the correction, deletion
                        and destruction processes made in accordance to the relevant legislations.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; To launch a complaint against any negative results
                        that may occur as a result of any data analysis. This may only be applied
                        exclusively in cases of analyses completed through automated systems.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; In case of damage to data due to unlawful processing
                        of personal data, the user reserves the right to demand amends to done
                        damage.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The user may send the above-mentioned requests in writing to the e-mail
                        address,{' '}
                        <a
                          data-auto-recognition='true'
                          href='mailto:info@mobixa.net'
                          className='wixui-rich-text__text'
                        >
                          info@mobixa.net
                        </a>
                        . In accordance with the above-mentioned requests, the company may give its
                        affirmative / negative response through written or digital platforms. The
                        user will never be charged for any requests, implications of such requests
                        and procedures that may follow with said request. However, if the
                        transactions require a cost, it is possible to charge a fee on the tariff
                        determined by the Personal Data Protection Board in accordance with Article
                        13 of the Personal Data Protection Law.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The User guarantees that the information subject to this Notice is complete,
                        accurate and up-to-date and will update them immediately in case of any
                        change to this information. If the User does not provide up-to-date
                        information, the Company cannot be held responsible.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        <span style={{ fontWeight: 'bold' }} className='wixui-rich-text__text'>
                          Storage Period of Personal Data
                        </span>
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The Company, for the purpose of users’ benefitting from the Platforms, to
                        provide the necessary services in the Platforms and to oblige in Users
                        Notification an Agreements, will store the data as long as services are
                        utilized by the User.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        In addition, in the event of any dispute arising from the User Agreement,
                        the Company will be able to store personal data for the period of limitation
                        specified in accordance with the relevant legislation, limited to the
                        purpose of making the necessary defenses within the scope of the dispute.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        Our Guarantees and Commitments Regarding Data Security
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The Company guarantees the following regarding data that has been sent to it
                        electronically, under conditions highlighted in Privacy Policy or
                        Legislation concerned;
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; That Personal data are not processed unlawfully,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; That Personal data are not accessed unlawfully,
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; The Company ensures that appropriate level of
                        security measures will be taken in order to protect personal data
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        · &nbsp; &nbsp; &nbsp; The company will be obliged to undertake periodic and
                        necessary inspections regarding its protection systems.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The Company will not disclose any personal data obtained about its users to
                        any third parties, which would be in violation of this Notification and the
                        Law of Protection of Personal Data. The company guarantees that the data
                        will only be used for purposes highlighted in mentioned Notifications and
                        Agreements. In cases where links are provided to other applications, the
                        Company will not be held responsible for any privacy policies or content
                        that these applications have.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        <span style={{ fontWeight: 'bold' }} className='wixui-rich-text__text'>
                          Contact Permission
                        </span>
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        By accepting the Company's User Agreement and this Notice, you consent to
                        the collection, storage, processing, use and transfer of your personal data.
                        This data will be used in order to provide you with various advantages and
                        to make all kinds of electronic communication in accordance with the purpose
                        of the application.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        This personal data may be shared with the Company and all of its
                        subsidiaries and affiliates, with third parties in Turkey and / or abroad
                        with whom we have a contractual relationship, in accordance with the purpose
                        of use of the platform and limited to the purposes shared with you in this
                        text.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        Users accept and declare that they consent to the use and storage of their
                        personal data by the Company in this way.&nbsp;
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The Company guarantees that the data in question will be safely secured,
                        defended against any unauthorized attempts to seize it and defend against
                        any attempted unlawful usage of data provided by its users, in accordance of
                        the the related laws.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        <span style={{ fontWeight: 'bold' }} className='wixui-rich-text__text'>
                          Changes to the Statement
                        </span>
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        The Company may change the provisions of this Notice at any time by
                        publishing it on the Platforms. The changes made to the notification will
                        become effective on the date of publication. For you to be aware of the
                        changes made in this Statement, the necessary information will be given to
                        our members via the e-mail address you specified while registering on the
                        platforms.
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    &nbsp;
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        <span style={{ fontWeight: 'bold' }} className='wixui-rich-text__text'>
                          Resolution Of Disputes
                        </span>
                      </span>
                    </span>
                  </p>
                  <p
                    className='font_8 wixui-rich-text__text'
                    style={{ fontSize: '16px', lineHeight: 'normal' }}
                  >
                    <span style={{ fontSize: '16px' }} className='wixui-rich-text__text'>
                      <span style={{ letterSpacing: 'normal' }} className='wixui-rich-text__text'>
                        This notification is subject to the laws of the Republic of Turkey. All
                        disputes arising out of or related to this Agreement will be ultimately
                        resolved by arbitration in accordance with the Istanbul Arbitration Center
                        Arbitration Rules.
                      </span>
                    </span>
                  </p>
                  <p className='font_8 wixui-rich-text__text' style={{ fontSize: '16px' }}>
                    &nbsp;
                  </p>
                  <p className='font_8 wixui-rich-text__text' style={{ fontSize: '16px' }}>
                    &nbsp;
                  </p>
                  <p className='font_8 wixui-rich-text__text' style={{ fontSize: '14px' }}>
                    &nbsp;
                  </p>
                </div>
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: gdprData.gdpr }} />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {gdprData && (
            <Button
              variant='contained'
              onClick={() => setOpenWarnModal(true)}
              style={{ marginRight: '40px' }}
              color='inherit'
            >
              Reject
            </Button>
          )}

          <Button
            variant='contained'
            style={{ marginRight: '40px' }}
            onClick={() => (setGdprAccept(true), setShow(false))}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
      <Dialog
        open={openWarnModal}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: /*  '#0288D1' : */ '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText color={'white'} id='alert-dialog-slide-description'>
            Your consent is required to use Mobixa platforms. Next time, we will kindly ask for your
            consent, again. Are you sure about NOT accepting the GDPR statement?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            /* passwordLoading ? (
            <CircularProgress color='primary' size={20} />
          ) :  */ <Button
              /* disabled={ passwordLoading } */
              onClick={() => (setOpenWarnModal(false), setShow(false))}
              style={{ color: 'white' }}
            >
              Yes
            </Button>
          }
          <IconButton onClick={handleClickWarnModalClose} style={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
