import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    Pagination,
    Select,
    Slide,
    Tooltip,
    Typography,
    darken,
    lighten,
    styled,
} from '@mui/material'
import {
    DataGridPro,
    GridCellParams,
    GridColumnVisibilityModel,
    GridFilterModel,
    GridSortModel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useSelector } from 'react-redux'
import { TransitionProps } from '@mui/material/transitions'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
const ExcelJS = require('exceljs');

const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    '& .super-app-theme--Open': {
        backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode
                ),
            },
        },
    },
    '& .super-app-theme--secondary': {
        backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.secondary.light,
                theme.palette.mode
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.secondary.light,
                    theme.palette.mode
                ),
            },
        },
    },
}))
const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />
})

function JourneysReportActivity() {
    const LoginState = useSelector((state: any) => state.Login)
    const navigate = useNavigate();
    const getIdFromUrl = () => {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        return params.get('id')
    }
    const journeyId = getIdFromUrl()

    const getNameFromUrl = () => {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        return params.get('name')
    }
    const journeyName = getNameFromUrl()

    const QuestionsBreadcrumbs: Array<PageLink> = [
        {
            title: 'Reports',
            // path: '/apps/user-management/users',
            path: '/reports',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100,
    })
    const [isLoadingData, setIsLoadingData] = useState(false)
    const [rows, setRows] = useState([])
    const [rowCount, setRowCount] = useState(0)
    const [selectQuestion, setSelectQuestion] = useState([])
    const [questionStatus, setQuestionStatus] = useState(-1)
    const [journeyOptions, setJourneyOptions] = useState<any>({
        sortModel: [{ field: 'journeyId', sort: 'desc' }],
    })

    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
        /*    id: false,
               brokerId: false,
               status: false, */
    })

    const [status] = useState([
        { value: 0, label: 'PAUSED' },
        { value: 1, label: 'READY' },
        { value: 2, label: 'STARTED' },
        { value: 3, label: 'FINISHED' },
        { value: 5, label: 'DRAFT' },
    ])
    const [chat] = useState([
        { value: 'On', label: 'On' },
        { value: 'Off', label: 'Off' },
    ])
    const [type] = useState([
        { value: 'PRIVATE', label: 'Private' },
        { value: 'PUBLIC', label: 'Public' },
    ])

    const StatusCell = ({ value }: { value: number }) => {
        const getStatusComponent = (status: number) => {
            if (status === 0) {
                return (
                    <Chip
                        className='overflow-visible'
                        label='PAUSED'
                        variant='outlined'
                        size='small'
                        color='default'
                        sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
                    />
                )
            }
            if (status === 1) {
                return (
                    <Chip
                        className='overflow-visible'
                        label='READY'
                        variant='outlined'
                        size='small'
                        color='primary'
                        sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
                    />
                )
            }
            if (status === 2) {
                return (
                    <Chip
                        className='overflow-visible'
                        label='STARTED'
                        variant='outlined'
                        size='small'
                        color='success'
                        sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
                    />
                )
            }
            if (status === 3) {
                return (
                    <Chip
                        className='overflow-visible'
                        label='FINISHED'
                        variant='outlined'
                        size='small'
                        color='secondary'
                        sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
                    />
                )
            }
            if (status === 5) {
                return (
                    <Chip
                        className='overflow-visible'
                        label='DRAFT'
                        variant='outlined'
                        size='small'
                        color='success'
                        sx={{
                            overflow: 'visible',
                            maxWidth: '90px',
                            fontSize: '13px',
                            background: '#cccccc',
                            color: '#707070',
                        }}
                    />
                )
            }
            return null
        }

        return <div style={{ height: '19px', width: '57px' }}>{getStatusComponent(value)}</div>
    }
    const [award] = useState([
        { value: 0, label: 'None' },
        { value: 1, label: 'Champion' },
        { value: 2, label: '3 Winners' },
        { value: 3, label: 'Top 10' },
        { value: 4, label: 'Top 25' },
        { value: 5, label: 'Top 100' },
        { value: 6, label: 'All Finishers' },
    ])

    const AwardCell = ({ value }: { value: number }) => {
        const getStatusComponent = (status: number) => {
            if (status === 0) {
                return <Typography>None</Typography>
            }
            if (status === 1) {
                return <Typography>Champion</Typography>
            }
            if (status === 2) {
                return <Typography>3 Winners</Typography>
            }
            if (status === 3) {
                return <Typography>Top 10</Typography>
            }
            if (status === 4) {
                return <Typography>Top 25</Typography>
            }
            if (status === 5) {
                return <Typography>Top 100</Typography>
            }
            if (status === 6) {
                return <Typography>All Finishers</Typography>
            }
            return null
        }

        return <div style={{ height: '19px', width: '57px' }}>{getStatusComponent(value)}</div>
    }
    const [hoverVisibility, setHoverVisibility] = useState(null)
    const [activityType] = useState([
        { value: "LEARNING CARD", label: 'LEARNING CARD' },
        { value: "TEST", label: 'TEST' },
        { value: "GAME", label: 'GAME' },
    ])
    const [activitySubType] = useState([
        { value: "DEEPLEARN", label: 'DEEPLEARN ' },
        { value: "TENTEN", label: 'TENTEN' },
        { value: "RECALL", label: 'RECALL' },
        { value: "PEAK", label: 'PEAK' },
        { value: "QUIZ", label: 'QUIZ' },
        { value: "EXAM", label: 'EXAM' },
        { value: "-", label: '-' },
    ])

    const columns: any = [
        {
            field: 'order',
            headerName: 'ORDER',
            width: 140,
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            /*   valueGetter: (params: any) => params.value?.toFixed(0), */
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 140,
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            /*     valueGetter: (params: any) => params.value?.toFixed(0), */
        },
        {
            field: 'title',
            headerName: 'ACTIVITY NAME',
            width: 130,
            headerAlign: 'left',
            align: 'left',
            /*  valueGetter: (params: any) => params.value.toFixed(0), */
        },
        {
            field: 'type',
            headerName: 'TYPE',
            width: 120,
            headerAlign: 'left',
            align: 'left',
            type: 'singleSelect',
            valueOptions: activityType.map((option) => ({
                value: option.value,
                label: option.label,
            })),
            /*  valueGetter: (params: any) => params.value.toFixed(0), */
        },
        {
            field: 'subType',
            headerName: 'SUB TYPE',
            width: 120,
            headerAlign: 'left',
            align: 'left',
            type: 'singleSelect',
            valueOptions: activitySubType.map((option) => ({
                value: option.value,
                label: option.label,
            })),
            /*  valueGetter: (params: any) => params.value.toFixed(0), */
        },
        {
            field: 'progress',
            headerName: (
                <Box display={"flex"} flexDirection={"column"} textAlign={"center"} justifyContent={"center"} marginTop={1.6}>
                    <Typography
                        display={'flex'}
                        alignItems={'center'}
                        fontSize={12}
                        color={'#a1a5b7'}
                        fontWeight={'bold'}
                    >
                        COMPLETION

                    </Typography>
                    <Typography marginLeft={1} fontSize={10}>
                        %
                    </Typography>
                </Box>
            ),
            width: 120,
            description: 'The average activity completion rate by users of this journey.',
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            valueGetter: (params: any) => (params?.value ? params.value.toFixed(2) : '0.00'),
            /* valueGetter: (params: any) => params.value.toFixed(0), */
        },
        {
            field: 'success',
            headerName: (<Box display={"flex"} flexDirection={"column"} textAlign={"center"} justifyContent={"center"} marginTop={1.6}>
                <Typography
                    display={'flex'}
                    alignItems={'center'}
                    fontSize={12}
                    color={'#a1a5b7'}
                    fontWeight={'bold'}
                >
                    SUCCESS
                </Typography>
                <Typography marginLeft={1} fontSize={10}>
                    %
                </Typography>
            </Box>
            ),
            width: 150,
            description: 'It is the correct answer rate of all questions shown to users in this journey.',
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", width: "100%" }}>
                    {params?.value ? params.value.toFixed(2) : '0.00'}
                    {
                        params.row.type === "TEST" &&
                        <IconButton size='small' onClick={() => (navigate(`/reports/TestResult?id=${params.row.journeyActivityId}&name=${params.row.title}`))} onMouseEnter={() => setHoverVisibility(params.id)} onMouseLeave={() => setHoverVisibility(null)}>
                            <VisibilityIcon fontSize="medium" style={{ color: hoverVisibility === params.id ? "#707070" : '#D5D5D5' }} />
                        </IconButton>
                    }
                </div>
            ),
        }
    ]

    const CustomPagination = () => {
        const handlePageChange = (event: any, page: any) => {
            setPaginationModel((prevModel) => ({
                ...prevModel,
                page: page - 1,
            }))
        }

        const handlePageSizeChange = (event: any) => {
            setPaginationModel((prevModel) => ({
                ...prevModel,
                pageSize: parseInt(event.target.value, 10),
                page: 0,
            }))
        }

        const startRow = paginationModel.page * paginationModel.pageSize + 1
        const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
        const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

        return (
            <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Box>
                    Rows per page:
                    <Select
                        value={paginationModel.pageSize}
                        onChange={handlePageSizeChange}
                        variant='outlined'
                        size='small'
                        sx={{ ml: 1, mr: 1 }}
                    >
                        <MenuItem selected value={100}>
                            100
                        </MenuItem>
                        <MenuItem value={150}>150</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                    </Select>
                </Box>
                <Pagination
                    count={totalPages}
                    page={paginationModel.page + 1}
                    showFirstButton
                    showLastButton
                    disabled={isLoadingData}
                    size='small'
                    onChange={handlePageChange}
                />
                <Box>
                    Showing {startRow}-{endRow} of {rowCount} record
                </Box>
            </Box>
        )
    }

    const handleSelectionModelChange = (newSelectionModel: any) => {
        if (newSelectionModel.length > 1) {
            // Yalnızca en son seçilen öğeyi koruyun
            const newSelectedId = newSelectionModel[newSelectionModel.length - 1];
            setSelectQuestion([newSelectedId]);
        } else {
            setSelectQuestion(newSelectionModel);
        }
    }

    const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
        // Tıklanan hücreye özgü işlemleri gerçekleştirin
        /*  if (params.field === '__check__' || params.row.source === 'Mobixa') {
          return
        } */
        if (selectQuestion.includes(params.id)) {
            setSelectQuestion([]);
        } else {
            // Değilse, tıklanan satırı seç
            setSelectQuestion([params.id]);
        }
        /* fetchUserList(params.row.journeyId) */
        // Diğer işlemleri burada yapabilirsiniz
    }
    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setJourneyOptions((prevOptions: any) => ({
            ...prevOptions,
            sortModel: [...sortModel],
        }))
    }, [])
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        // Here you save the data you need from the filter model

        /*    if (filterModel.items.length < 1) {
              return
            } */

        setJourneyOptions((prevOptions: any) => ({
            ...prevOptions,
            filterModel: { ...filterModel },
        }))
    }, [])

    const [loadingExport, setLoadingExport] = useState(false)

    async function downloadExcel() {
        setLoadingExport(true)
        setTimeout(() => {
            setLoadingExport(false)
        }, 1000)
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Report');
        worksheet.columns = [
            { header: 'Order', key: 'Order', width: 10 },
            { header: 'ID', key: 'Id', width: 10 },
            { header: 'Activity Name', key: 'ActivityName', width: 18 },
            { header: 'Type', key: 'Type', width: 18 },
            { header: 'SubType', key: 'SubType', width: 20 },
            { header: 'Completion (%)', key: 'Completion', width: 19 },
            { header: 'Success (%)', key: 'Success', width: 18 },
        ];

        // Başlık satırının stilini ayarlayın
        worksheet.getRow(1).font = { bold: true };
        worksheet.getRow(1).eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '4F81BD' } // Koyu mavi arka plan rengi
            };
            cell.font = {
                color: { argb: 'FFFFFFFF' }, // Beyaz yazı rengi
                bold: true
            };
            cell.border = {
                top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
                left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
                bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
                right: { style: 'thin', color: { argb: 'FFFFFFFF' } }
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });
        worksheet.autoFilter = {
            from: 'A1',
            to: 'G1'
        };

        // newData üzerinde dolaşarak her bir satırı Excel'e ekleyin
        let rowNumber = 2;
        rows.forEach(row => {
            const newRow = worksheet.addRow({
                Order: row.order,
                Id: row.id,
                ActivityName: row.title,
                Type: row.type,
                SubType: row.subType,
                Completion: row.progress,
                Success: row.success,
            });
            const fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: rowNumber % 2 === 0 ? 'B8CCE4' : 'DCE6F1' },
            };
            newRow.border = {
                top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
                left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
                bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
                right: { style: 'thin', color: { argb: 'FFFFFFFF' } }
            };
            newRow.eachCell((cell) => {
                cell.fill = fill;
            });
            rowNumber++;
        });

        // Excel dosyasını kaydedin
        const buffer = await workbook.xlsx.writeBuffer(); // Workbook'u bir ArrayBuffer olarak döndür
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Blob'u oluştur
        const url = window.URL.createObjectURL(blob); // Blob için bir URL oluştur
        const anchor = document.createElement('a'); // Bir <a> elementi oluştur
        anchor.href = url;
        anchor.download = 'journeyDetailReportByActivity.xlsx'; // İndirilecek dosyanın adını ayarla
        document.body.appendChild(anchor); // <a> elementini DOM'a ekle
        anchor.click(); // Programatik olarak tıklayarak indirmeyi başlat
        document.body.removeChild(anchor); // <a> elementini DOM'dan kaldır
        window.URL.revokeObjectURL(url);
        /*  const newData = rows.map((row) => {
           const newRow = {
             Order: row.order,
             Id: row.id,
             ActivityName: row.title,
             Type: row.type,
             SubType: row.subType,
             Completion:row.progress,
             Success: row.success,
           }
           return newRow;
         });
       const workSheet = XLSX.utils.json_to_sheet(newData)
       const workBook = XLSX.utils.book_new()
       XLSX.utils.book_append_sheet(workBook, workSheet, 'row')
       //Buffer
       XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' })
       //Binary string
       XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' })
       //Download
       XLSX.writeFile(workBook, 'journeyDetailReportByActivity.xlsx') */
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <LoadingButton
                    size='small'
                    onClick={downloadExcel}
                    endIcon={<FileDownloadIcon />}
                    loading={loadingExport}
                    loadingPosition='end'
                    variant='outlined'
                >
                    <span>Export</span>
                </LoadingButton>
            </GridToolbarContainer>
        )
    }

    useEffect(
        () => {
            fetchRows()
        },
        [
            /* paginationModel, userOptions */
        ]
    )

    const fetchRows = async () => {
        setIsLoadingData(true)

        await axios
            .get(
                `${process.env.REACT_APP_API_URL}/report/journeyActivityListReport/${journeyId}`,
                {
                    headers: {
                        accept: 'application/json',
                        Authorization: `Bearer ${LoginState.loginData.access_token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                const formattedRows = response.data?.map((row: any) => ({
                    id: row.activityId,
                    subType: row.activitySubType,
                    type: row.activityType,
                    order: row.sortOrder,
                    surname: row.surname,
                    progress: row.progressRate,
                    success: row.successRate,
                    title: row.title,
                    journeyActivityId: row.journeyActivityId
                }))
                setRows(formattedRows || [])
                setRowCount(response.data.length)
                setIsLoadingData(false)
            })
            .catch((err) => {
                setIsLoadingData(false)
            })
    }

    const [openUserList, setOpenUserList] = useState(false)
    const [userListLoading, setUserListLoading] = useState(false)
    const [userList, setUserList] = useState([])



    const fetchUserList = async (journeyId: number) => {
        setUserListLoading(true)
        await axios
            .post(
                `${process.env.REACT_APP_API_URL}/report/journeyAssignedUsers/${journeyId}`,
                {},
                {
                    headers: {
                        accept: 'application/json',
                        Authorization: `Bearer ${LoginState.loginData.access_token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                setUserList(response.data.items || [])
                setUserListLoading(false)
            })
            .catch((err) => {
                setUserListLoading(false)
            })
    }
    const [excelLoading, setExcelLoading] = useState(false)
    async function fetchUserListExcelExport() {
        setExcelLoading(true)
        await axios
            .post(
                `${process.env.REACT_APP_API_URL}/report/journeyAssignedUsers/${journeyId}?isExcel=1`,
                {},
                {
                    responseType: 'blob',
                    headers: {
                        accept: 'application/json',
                        Authorization: `Bearer ${LoginState.loginData.access_token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                const excelBlob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                })
                const excelUrl = URL.createObjectURL(excelBlob)

                const link = document.createElement('a')
                link.href = excelUrl
                link.download = `reportUserList-${journeyId}.xlsx`
                link.click()
                URL.revokeObjectURL(excelUrl)
                setExcelLoading(false)
            })
            .catch((err) => {
                setExcelLoading(false)
            })
    }
    const [hoverButton, setHoverButton] = useState(false)
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    height: 40,
                    width: '80%',
                    marginBottom: '5px',
                    marginTop: '-25px',
                    cursor: 'default',
                    pointerEvents: 'none',
                    userSelect: 'none',
                }}
            >
                <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
                    Reports - Journey Detail By Activity - {journeyName}
                </Typography>
            </div>
            <Box
                display='flex'
                justifyContent='flex-end'
                alignItems='center'
                alignContent='center'
            /*     sx={{ height: 50 }} */
            >
                <div style={{ marginTop: '-70px', marginRight: 90 }}>
                    {
                        <Tooltip
                            arrow
                            title='Go to list'
                            placement='top'
                            onMouseEnter={() => {
                                setHoverButton(true)
                            }}
                            onMouseLeave={() => {
                                setHoverButton(false)
                            }}
                            style={{ background: hoverButton ? '#D5D5D5 ' : 'white' }}
                        >
                            <IconButton
                                aria-label='Go to list'
                                onClick={() => {
                                    const data = rows.find((item: any) => item.journeyId === selectQuestion[0])

                                    navigate(`/reports/journeysReport`);
                                }}
                                size='small'
                                sx={{
                                    background: 'white',
                                    color: hoverButton ? 'black' : '',
                                    marginLeft: '7px',
                                }}
                            >
                                <FormatListBulletedOutlinedIcon sx={{ fontSize: '30px' }} />
                            </IconButton>
                        </Tooltip>
                    }
                </div>
            </Box>
            <div className='card card-custom  p-3'>
                <Box sx={{ height: 600, width: '100%' }}>
                    <StyledDataGrid
                        rows={rows || []}
                        columns={columns || []}
                        rowHeight={50}
                        columnHeaderHeight={40}
                        disableVirtualization
                        /* checkboxSelection */
                        keepNonExistentRowsSelected
                        /*  onCellClick={handleCellClick} */
                        getRowId={(row) => row.id}
                        disableRowSelectionOnClick
                        sortingMode='client'
                        onSortModelChange={handleSortModelChange}
                        sortModel={journeyOptions?.sortModel}
                        onRowSelectionModelChange={handleSelectionModelChange}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                        filterMode='client'
                        onFilterModelChange={onFilterChange}
                        //  isRowSelectable={(params: GridRowParams) => params.row.source === 'Client'}
                        rowCount={rowCount}
                        loading={isLoadingData}
                        paginationModel={paginationModel}
                        paginationMode='client'
                        rowSelectionModel={selectQuestion}
                        pagination={true}
                        onPaginationModelChange={setPaginationModel}
                        getRowClassName={(params) =>
                            `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
                        }
                        slots={{
                            toolbar: CustomToolbar,
                            pagination: CustomPagination,
                        }}
                        sx={{
                            boxShadow: "none",
                            border: "none",
                            '& .MuiDataGrid-footerContainer': {
                                padding: '0px', // Footer'ın içerisindeki padding'i daraltmak için bu değerleri ayarlayabilirsiniz
                                height: 30
                            },

                            '& .MuiDataGrid-cell:hover': {
                                /*    color: 'primary.main', */
                                cursor: 'pointer',
                            },
                            '&	.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                                color: '#A1A5B7',
                                fontSize: 12,
                            },
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
                            },
                        }}
                    />
                </Box>
            </div>

        </>
    )
}

export default JourneysReportActivity
