import {call, put,select} from 'redux-saga/effects'
import LoginTypes from '../reducers/LoginRedux'

export function* loginUser(api, action) {
/*   console.log("actionSaga : ", action) */
  try {
    const response = yield call(api.LoginUser, action.body)

    if (response.hasOwnProperty('error')) {
      yield put(LoginTypes.loginFailure(response))
    } else {
      yield put(LoginTypes.loginSuccess(response))
    }
  } catch (error) {
  
    
    yield put(LoginTypes.loginFailure(error))
  }
}

export function* refreshToken(api, action) {
  
  try {
 /*    const refreshToken = loginData.refresh_token; */
    const response = yield call(api.refreshToken, action.refreshToken);
    if (response.hasOwnProperty('error')) {
      yield put(LoginTypes.refreshTokenFailure(response));
    } else {
      yield put(LoginTypes.refreshTokenSuccess(response.access_token));
    }
  } catch (error) {
    yield put(LoginTypes.refreshTokenFailure(error));
  }
}

export function* userWidthJwtSaga(api, action) {
  try {
    const loginData = yield select((state) => state.Login.loginData); // Redux state'inden loginData al
    const userData = yield call(api.getUserWithJWT, loginData.access_token); // Token ile API çağrısı
    if (userData.hasOwnProperty('error')) {
      yield put(LoginTypes.loginFailure())
    } else {
      yield put(LoginTypes.getUserRedux(userData));
    }
    /* sessionStorage.setItem('customerProfile', JSON.stringify(userData)); */
    localStorage.setItem('user', JSON.stringify(userData.customerId))
    // Burada userData ile ilgili işlemler yapılabilir.
  } catch (error) {
    // Hata işleme
    yield put(LoginTypes.loginFailure(error))
    // Burada bir hata action'ı dispatch edilebilir.
  }
}
