import React from 'react'
import {FC, useRef, useState} from 'react'
import { Modal} from 'react-bootstrap'
import {Editor} from '@tinymce/tinymce-react'
import Axios from 'axios'
import {useSelector} from 'react-redux'
import { Button, Paper } from '@mui/material'
type Props = {
  userId: any
  first:any
  setfirst:any
}
export const AddNotes: FC<Props> = ({userId,first,setfirst}) => {
  // require('./custom.css');
  // const React = require('react');

  const [show, setShow] = useState(false)
  let rm: any = document.getElementById('remaining')
  const editorRef: any = useRef(null)
  const sizeLimit = 750
  const [value, setValue] = useState<any>('')
  const [length, setLength] = React.useState(0)
  const handleInit = (evt: any, editor: any) => {
    setLength(editor.getContent({format: 'text'}).length)
    editorRef.current = editor
  }
  const handleUpdate = (value: any, editor: any) => {
    setValue(`<body>${value}</body>`)
  }
  const handleBeforeAddUndo = (evt: any, editor: any) => {
    const length = editor.getContent({format: 'text'}).length
    // note that this is the opposite test as in handleUpdate
    // because we are determining when to deny adding an undo level
    if (length > sizeLimit) {
      evt.preventDefault()
    }
  }
  const handleClose = () => {setShow(false)
    setValue('')}
  const handleShow = () => setShow(true)

  const LoginState = useSelector((state: any) => state.Login)
  const handleSave = () => {

    Axios.post(
      `${process.env.REACT_APP_API_URL}/user/addUserNotes/${userId}`,
      {
        note: value,
      },
      { 
        headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
      }
    ).then(function (response: any) {
      handleClose()
      setfirst(!first)
    })
  }
  return (
    <>
      <Button variant='contained'  onClick={handleShow}>
          Add Note
        </Button>

      <Modal className='bg-white bg-opacity-75 p-3 mb-5' size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontWeight:"bold"}}>Add Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3'>
          <Paper className='p-1'>
          <Editor
            onInit={handleInit}
            value={value}
            onEditorChange={handleUpdate}
            onBeforeAddUndo={handleBeforeAddUndo}
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            init={{
              menubar: false,
              toolbar_mode: 'wrap',
              auto_focus: 'element1',
              content_css: "/custom.css",
              content_style: 'body { font-size: 14pt; font-family: Arial;}',
              plugins: 'lists advlist colorpicker emoticons charmap',
              toolbar: 'undo redo | bold italic underline |bullist numlist',
              relative_urls: false,
              statusbar: false,
              branding: false,
              automatic_uploads: false,
              image_advtab: false,
              image_caption: false,
              image_description: false,
              paste_data_images: false,
              language: 'tr_TR',
              convert_urls: false,
              setup: function (ed) {
                ed.ui.registry.addButton('customInsertButton', {
                  icon: 'edit-image',
                  onAction: function (_) {},
                })
              },
            }}
          />
          </Paper>
       
        </Modal.Body>
        <Modal.Footer>
          <Button variant='contained' color='inherit' onClick={handleClose}>
            DISCARD
          </Button>
          <Button variant='contained' sx={{marginLeft:"30px"}} onClick={handleSave}>
            SAVE
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
