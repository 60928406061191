/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import  ActivitiesList from './List'
import { Typography } from '@mui/material'
const ActvitiesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Manage',
    // path: '/apps/user-management/users',
    path: '/manage/activities',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const Activities: FC = () => {
  const intl = useIntl()
  return (
    <>
     {/*  <PageTitle breadcrumbs={ActvitiesBreadcrumbs}>Activity List</PageTitle> */}
     <div
          style={{
            display: 'flex',
            height: 40,
            width: '80%',
           /*  marginBottom: '5px', */
            marginTop: '-20px',
            cursor: 'default',
            pointerEvents: 'none',
            userSelect: 'none',
          }}
        >
          <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
            Manage - Activity List
          </Typography>
        </div>
      <ActivitiesList/>
    </>
  )
}

export {Activities}
