import React, { useCallback, useEffect, useState } from 'react'
import { PageLink } from '../../../_metronic/layout/core'
import {
  Box,
  Button,
  Chip,
  MenuItem,
  Pagination,
  Select,
  Typography,
  darken,
  lighten,
  styled,
} from '@mui/material'
import {
  DataGridPro,
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useSelector } from 'react-redux'

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

function UserGroupReports() {
  const LoginState = useSelector((state: any) => state.Login)

  const QuestionsBreadcrumbs: Array<PageLink> = [
    {
      title: 'Reports',
      // path: '/apps/user-management/users',
      path: '/reports',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const [groupList, setGroupList] = useState<any>([])
  useEffect(() => {
    function test() {
      axios
        .get(`${process.env.REACT_APP_API_URL}/user/getGroupListNoPagination`, {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        })
        .then((response) => {
          setGroupList(response.data)
        })
        .catch((err) => { })
    }
    test()
  }, [])

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [selectQuestion, setSelectQuestion] = useState([])
  const [groupOptions, setGroupOptions] = useState<any>({ sortModel: [{ field: 'name', sort: 'asc' }] })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    /*    id: false,
           brokerId: false,
           status: false, */
  })

  const [status] = useState([
    { value: 0, label: 'Blocked' },
    { value: 1, label: 'Open' },
    { value: 2, label: 'Deleted' },
  ])

  const StatusCell = ({ value }: { value: number }) => {
    const getStatusComponent = (status: number) => {
      if (status === 0) {
        return (
          <Chip
            className='overflow-visible'
            label='Blocked'
            variant='outlined'
            color='secondary'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 1) {
        return (
          <Chip
            className='overflow-visible'
            label='Open'
            variant='outlined'
            color='success'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 2) {
        return (
          <Chip
            className='overflow-visible'
            label='Deleted'
            variant='outlined'
            color='error'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      return null
    }

    return <div style={{ height: '19px', width: '57px' }}>{getStatusComponent(value)}</div>
  }

  const columns: any = [
    {
      field: 'groupId',
      headerName: 'GROUP ID',
      width: 90,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value.toFixed(0),
    },
    {
      field: 'name',
      headerName: 'GROUP',
      width: 150,
      type: 'singleSelect',
      valueOptions: groupList.map((category: any) => ({
        value: category.groupId,
        label: category.groupName,
      })),
      renderCell: (params: any) => params.value,
    },
    {
      field: 'numberOfUsers',
      description:
        'The number of users in the group, consisting of OPEN-statued users only. All other status are excluded.',
      headerName: 'USERS',
      width: 120,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'totalActiveUserCount',
      headerName: 'ACTIVE USERS',
      description:
        'Active users are the users who use mobixa at least 1 minute in the indicated period of time.',
      width: 230,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'activeRatio',
      headerName: (
        /* 'ACTIVE %' */ <Typography
          /*  textAlign={'center'} */
          display={'flex'}
          alignItems={'center'}
          fontSize={14}
          color={'#a1a5b7'}
          fontWeight={'bold'}
        >
          ACTIVE
          <Typography marginLeft={1} fontSize={10}>
            %
          </Typography>
        </Typography>
      ),
      description: 'The percentage of active users in the selected period.',
      width: 130,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => (params?.value ? params.value.toFixed(2) : '0.00'),
    },
    {
      field: 'avgTimeInMobixa',
      /* 'AVG. TIME IN MOBIXA %' */
      headerName: (
        <Typography
          /* textAlign={'center'} */ display={'flex'}
          alignItems={'center'}
          fontSize={14}
          color={'#a1a5b7'}
          fontWeight={'bold'}
        >
          AVG. TIME IN MOBIXA
          <Typography marginLeft={1} fontSize={10}>
            hh : mm
          </Typography>
        </Typography>
      ),
      description: 'This is the indicator of the average time spent by all the users on Mobixa',
      width: 200,
      /*   sortComparator: (v1: any, v2: any) => {
        const time1 = v1?.split(':')
        const time2 = v2?.split(':')
        const num1 = parseInt(time1[0], 10) * 60 + parseInt(time1[1], 10)
        const num2 = parseInt(time2[0], 10) * 60 + parseInt(time2[1], 10)
        return num1 - num2
      }, */
      renderCell: (params: any) => {
        return <span>{params.value}</span>
      },
    },
    {
      field: 'avgAssignedJourneys',
      headerName: 'AVG. NR. OF JOURNEYS',
      description:
        'The average number of Journeys been assigned to the users in the indicated period of time. Regardless of finished or not.',
      width: 210,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => (params?.value ? params.value.toFixed(2) : '0.00'),
    },
    {
      field: 'avgProgress',
      headerName: (
        /* 'AVG. PROGRESS %' */ <Typography
          /*  textAlign={'center'} */
          display={'flex'}
          alignItems={'center'}
          fontSize={14}
          color={'#a1a5b7'}
          fontWeight={'bold'}
        >
          AVG. PROGRESS
          <Typography marginLeft={1} fontSize={10}>
            %
          </Typography>
        </Typography>
      ),
      description:
        'The average nr. of all the competed activities over all assigned activities. Ex. All the users in the group have 1000 activities in total.  If sum of 500 is finished, the progress is 50%',
      width: 200,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => (params?.value ? params.value.toFixed(2) : '0.00'),
      /*   valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'avgSuccess',
      /* 'SUCCESS %' */
      headerName: (
        <Typography
          /* textAlign={'center'} */ display={'flex'}
          alignItems={'center'}
          fontSize={14}
          color={'#a1a5b7'}
          fontWeight={'bold'}
        >
          AVG. SUCCESS
          <Typography marginLeft={1} fontSize={10}>
            %
          </Typography>
        </Typography>
      ),
      description:
        'The proportion of the right answers over all displayed questions by the group’s members in the given period of time.',
      width: 140,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => (params?.value ? params.value.toFixed(2) : '0.00'),
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
  ]

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ ml: 1, mr: 1 }}
          >
            <MenuItem selected value={100}>
              100
            </MenuItem>
            <MenuItem value={150}>150</MenuItem>
            <MenuItem value={200}>200</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelectQuestion(newSelectionModel)
  }

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }

    // Diğer işlemleri burada yapabilirsiniz
  }
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setGroupOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])

  const [loadingExport, setLoadingExport] = useState(false)
  const [options, setOptions] = useState<any>({
    sortModel: [
      { field: 'successRate', sort: 'desc' },
      { field: 'statusId', sort: 'desc' },
    ],
  })
  async function downloadExcel() {
    setLoadingExport(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/report/userGroupReport?isExcel=1&timeStamp=${timeButtons}`,
        {
          options,
          columnVisibilityModel,
        },
        {
          responseType: 'blob',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (response) => {
        const excelBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const excelUrl = await URL.createObjectURL(excelBlob)

        const link = document.createElement('a')
        link.href = excelUrl
        link.download = 'userReport.xlsx'
        link.click()
        URL.revokeObjectURL(excelUrl)
        setLoadingExport(false)
      })
      .catch((err) => {
        setLoadingExport(false)
      })
    /* const newData = rows.map((row: any) => {
          delete row.tableData
          row.statusId = row.statusId === 1 ? 'Open' : row.statusId === 0 ? 'Block' :""
          return row
        })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, 'row')
        //Buffer
        XLSX.write(workBook, {bookType: 'xlsx', type: 'buffer'})
        //Binary string
        XLSX.write(workBook, {bookType: 'xlsx', type: 'binary'})
        //Download
        XLSX.writeFile(workBook, 'userReports.xlsx') */
  }
  const [timeButtons, setTimeButtons] = useState('last3Months')

  useEffect(() => {
    fetchRows(timeButtons)
  }, [timeButtons])

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <LoadingButton
          size='small'
          onClick={downloadExcel}
          endIcon={<FileDownloadIcon />}
          loading={loadingExport}
          loadingPosition='end'
          variant='outlined'
        >
          <span>Export</span>
        </LoadingButton>
        <Box marginLeft={14} /* marginBottom={3} */>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('monthToDate')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'monthToDate' ? 'bold' : '',
              color: timeButtons === 'monthToDate' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'monthToDate' ? 'underline' : 'none',
            }}
          >
            Month to date
          </Button>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('lastMonth')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'lastMonth' ? 'bold' : '',
              color: timeButtons === 'lastMonth' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'lastMonth' ? 'underline' : 'none',
            }}
          >
            Last month
          </Button>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('last3Months')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'last3Months' ? 'bold' : '',
              color: timeButtons === 'last3Months' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'last3Months' ? 'underline' : 'none',
            }}
          >
            Last 3 months
          </Button>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('allTime')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'allTime' ? 'bold' : '',
              color: timeButtons === 'allTime' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'allTime' ? 'underline' : 'none',
            }}
          >
            All time
          </Button>
        </Box>
      </GridToolbarContainer>
    )
  }

  useEffect(() => {
    fetchRows(timeButtons)
  }, [timeButtons, options, paginationModel])

  const fetchRows = async (timeButtons: any) => {
    setIsLoadingData(true)

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/report/userGroupReport?timeStamp=${timeButtons}`,
        {
          options,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const formattedRows = response.data?.map((row: any) => ({
          groupId: row.groupId,
          name: row.name,
          numberOfUsers: row.numberOfUsers,
          totalActiveUserCount: row.totalActiveUserCount,
          avgTimeInMobixa: row.activeRatio,
          timeInMobixa: row.avgTimeInMobixa,
          assignedJourneys: row.avgAssignedJourneys,
          avgSuccess: row.avgSuccess,
          avgProgress: row.avgProgress,
        }))

        setRows(formattedRows || [])
        setRowCount(response.data.length)
        setIsLoadingData(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
      })
  }

  return (
    <>
      {/*  <PageTitle breadcrumbs={QuestionsBreadcrumbs}>User Group</PageTitle> */}
      <div
        style={{
          display: 'flex',
          height: 40,
          width: '80%',
          marginBottom: '5px',
          marginTop: '-25px',
          cursor: 'default',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
          Reports - User Group
        </Typography>
      </div>
      <div className='card card-custom p-3  '>
        <Box sx={{ height: 600, width: '100%' }}>
          <StyledDataGrid
            /*   {...data}
                loading={data.rows.length === 0} */
            rows={rows || []}
            columns={columns}
            rowHeight={50}
            columnHeaderHeight={40}
            disableVirtualization
            /* checkboxSelection */
            onCellDoubleClick={handleCellClick}
            getRowId={(row) => row.groupId}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            disableRowSelectionOnClick
            sortingMode='server'
            sortModel={groupOptions?.sortModel}
            onSortModelChange={(newSortModel) => setOptions(prev => ({ ...prev, sortModel: newSortModel }))}
            onRowSelectionModelChange={handleSelectionModelChange}
            filterMode='server'
            onFilterModelChange={onFilterChange}
            isRowSelectable={(params: GridRowParams) => params.row.source === 'Client'}
            rowCount={rowCount}
            loading={isLoadingData}
            paginationModel={paginationModel}
            paginationMode='server'
            pagination={true}
            onPaginationModelChange={setPaginationModel}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
            }
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
            sx={{
              boxShadow: 'none',
              border: 'none',
              '& .MuiDataGrid-footerContainer': {
                padding: '0px',
                height: 30,
              },

              '& .MuiDataGrid-cell:hover': {
                /*    color: 'primary.main', */
                cursor: 'pointer',
              },
              '&	.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                color: '#A1A5B7',
                fontSize: 12,
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
              },
            }}
          />
        </Box>
      </div>
    </>
  )
}

export default UserGroupReports