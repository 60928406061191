/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {
  ContentDashboardWidged,
  ApexWidged,
  CreatorWeekWidget,
} from '../../../_metronic/partials/widgets'
import { LastContentListWidget } from '../../widgets/cDashboardWidget/LastContentListWidget'


const DashboardPage: FC = () => (
  
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-12'>
      <div className='col-xl-7'>
        <ContentDashboardWidged
          className=' mb-xl-8 '
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div>
      <div className='col-xl-5'>
      <div className='col-xl-12 card  mt-5 '>

        <ApexWidged
        />
      </div>
      <div className='col-xl-12 mt-5 mb-5'>

        <CreatorWeekWidget
          className=''

        />
              </div>

      </div>
      
    </div>
    <div>
      <LastContentListWidget className=''/>

      
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    
    {/* end::Row */}

    {/* begin::Row */}
   
    
    {/* end::Row */}
  </>
)

const CDashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <DashboardPage />
    </>
  )
}

export {CDashboardWrapper}
