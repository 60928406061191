import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import axios from 'axios'

import { Box, Button, CircularProgress, Grid, Pagination, Typography, darken, lighten, styled } from '@mui/material'
import { DataGridPro, GridCellParams, GridColumnVisibilityModel, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import { enqueueSnackbar } from 'notistack'

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

type Props = {
  journeyId: any
  journeyName: any
  setGroupList: any
  groupList: any
  participantsUser: any
  participantsUser1:any
  groupListFilter?:any
  setHandleGroupLoading?:any
  handleGroupLoading?:any
}

const AddGroup: FC<Props> = ({ journeyId, participantsUser1,  setGroupList, participantsUser,groupListFilter,setHandleGroupLoading,
  handleGroupLoading }) => {
  const LoginState = useSelector((state: any) => state.Login)

  const [unGroupAllLoading, setUnGroupAllLoading] = useState(false)
  const [groupAllLoading, setGroupAllLoading] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)

  const [rowCount, setRowCount] = useState(0)
  const [rows, setRows] = useState<any>([])
  const [allRows, setAllRows] = useState<any>([])
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    InternalEmployeeId: false,
  })
  const [groupOptions, setGroupOptions] = useState<any>({})
  const [selected, setSelected] = useState<any>([])
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  })


  const nameCell = (params: any) => {
    const { name, surname, /* group, */ avatar } = params.row
    return (
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {/*  <Avatar src={avatar} /> */}
        <Box marginLeft={'10px'}>
          <Typography>{name + ' ' + surname}</Typography>
          {/* {group.length > 0 && (
            <Typography color={'#6C757D'}>{group.split(',')[0].trim()}</Typography>
          )} */}
        </Box>
      </Box>
    )
  }


  const columns: any = [
    /* {
      field: '__check__',
      headerName: ' ',
      headerAlign: 'center',
      sortable:false,
      width: 50,
      renderHeader: () => (
        <DropdownCheckboxHeader />
      ),
      // checkboxSelection özelliğini bu sütuna eklemeyi unutmayın
    }, */
    {
      field: 'name',
      headerName: 'GROUP',
      width: 150,
      type: 'singleSelect',
      selectable: false,
      valueOptions: groupListFilter.map((category: any) => ({
        value: category.groupId,
        label: category.groupName,
      })), renderCell: (params: any) => params.value,
    },
    {
      field: 'numberOfUsers',
      headerName: 'NR OF USERS',
      width: 150,
      filterable: false,
      type: 'number',
      align: 'center',
    },
  ]

  const handleCellOneClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }
    const newSelectionModel = [...selected]; // Mevcut seçili satırların bir kopyasını oluşturun
    const selectionIndex = newSelectionModel.indexOf(params.id);

    if (selectionIndex === -1) {
      // Eğer tıklanan hücrenin ID'si şu anda seçili değilse, seçime ekleyin
      newSelectionModel.push(params.id);
    } else {
      // Eğer tıklanan hücrenin ID'si zaten seçiliyse, seçimden kaldırın
      newSelectionModel.splice(selectionIndex, 1);
    }

    setSelected(newSelectionModel);

  }
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setGroupOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const handleSelectionModelChange = (newSelectionModel: any) => {
    //allRows  a göre  selected yap
    setSelected(newSelectionModel)
  }
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {


    setGroupOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])
  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        {/*  <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ml: 1, mr: 1}}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box> */}
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }
//###############################################################
//###############################################################
//###############################################################
//###############################################################
  //#region groupList 2
  const [groupOptions1, setGroupOptions1] = useState<any>({})
  const [selected1, setSelected1] = useState<any>([])
  const [paginationModel1, setPaginationModel1] = useState({
    page: 0,
    pageSize: 100,
  })
  const [rowCount1, setRowCount1] = useState(0)
  const [rows1, setRows1] = useState<any>([])
  const [allRows1, setAllRows1] = useState<any>([])
  const [columnVisibilityModel1, setColumnVisibilityModel1] = useState<GridColumnVisibilityModel>({
    InternalEmployeeId: false,
  })
  const [isLoadingData1, setIsLoadingData1] = useState(false)

  
  const columns1: any = [
    {
      field: 'name',
      headerName: 'GROUP',
      width: 150,
      type: 'singleSelect',
      selectable: false,
      valueOptions: groupListFilter.map((category: any) => ({
        value: category.groupId,
        label: category.groupName,
      })), renderCell: (params: any) => params.value,
    },
    {
      field: 'numberOfUsers',
      headerName: 'NR OF USERS',
      width: 150,
      filterable: false,
      type: 'number',
      align: 'center',
    },
  ]

  const handleCellOneClick1 = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }
    const newSelectionModel1 = [...selected1]; // Mevcut seçili satırların bir kopyasını oluşturun
    const selectionIndex1 = newSelectionModel1.indexOf(params.id);

    if (selectionIndex1 === -1) {
      // Eğer tıklanan hücrenin ID'si şu anda seçili değilse, seçime ekleyin
      newSelectionModel1.push(params.id);
    } else {
      // Eğer tıklanan hücrenin ID'si zaten seçiliyse, seçimden kaldırın
      newSelectionModel1.splice(selectionIndex1, 1);
    }

    setSelected1(newSelectionModel1);

  }
  const handleSortModelChange1 = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setGroupOptions1((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const handleSelectionModelChange1 = (newSelectionModel: any) => {
    //allRows  a göre  selected yap
    setSelected1(newSelectionModel)

  }
  const onFilterChange1 = useCallback((filterModel: GridFilterModel) => {

    setGroupOptions1((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])
  const CustomPagination1 = () => {
    const handlePageChange1 = (event: any, page: any) => {
      setPaginationModel1((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange1 = (event: any) => {
      setPaginationModel1((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow1 = paginationModel1.page * paginationModel1.pageSize + 1
    const endRow1 = Math.min((paginationModel1.page + 1) * paginationModel1.pageSize, rowCount1)
    const totalPages1 = Math.ceil(rowCount1 / paginationModel1.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        {/*  <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ml: 1, mr: 1}}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box> */}
        <Pagination
          count={totalPages1}
          page={paginationModel1.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData1}
          size='small'
          onChange={handlePageChange1}
        />
        <Box>
          Showing {startRow1}-{endRow1} of {rowCount1} record
        </Box>
      </Box>
    )
  }

  //#endregion

  async function getGroupList() {
    setIsLoadingData(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/getJourneyUnAssignedGroups?journeyId=${journeyId}&page=${paginationModel.page + 1
        }&size=${paginationModel.pageSize}`,
        {
          options:groupOptions,
          columnVisibilityModel
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        const formattedRows = response.data.items.map((row: any) => ({
          id: row.groupId,
          name: row.name,
          explanation: row.explanation,
          modifiedName: row.modifiedName,
          modifiedDate: row.modifiedDate,
          numberOfUsers: row.numberOfUsers,
        }))

        setRows(formattedRows)
        setRowCount(response.data.total)
        setIsLoadingData(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
      })
  }
  async function getGroupList1() {
    setIsLoadingData1(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/getJourneyAssignedGroups?journeyId=${journeyId}&page=${paginationModel1.page + 1
        }&size=${paginationModel.pageSize}`,
        {
          options:groupOptions1,
          columnVisibilityModel1
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        const formattedRows = response.data.items.map((row: any) => ({
          id: row.groupId,
          name: row.name,
          explanation: row.explanation,
          modifiedName: row.modifiedName,
          modifiedDate: row.modifiedDate,
          numberOfUsers: row.numberOfUsers,
        }))

        setRows1(formattedRows)
        setRowCount1(response.data.total)
        setTimeout(() => {
          setIsLoadingData1(false) 
        }, 500);
      })
      .catch((err) => {
        setIsLoadingData1(false)
      })
  }
  const handleGroup = async () => {
    setHandleGroupLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/addGroupToJourney?journeyId=${journeyId}`,
        {
          groupIdList: selected
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      ).then(async (response: any) => {
        //loading eklenecek
        setSelected([])
        await getGroupList1()
        getGroupList()
        setHandleGroupLoading(false)
      }).catch((err) => {
        setHandleGroupLoading(false)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }
  const handleUnGroup = async () => {
    setHandleGroupLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/removeGroupFromJourney?journeyId=${journeyId}`,
        {
          groupIdList: selected1
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      ).then(async (response: any) => {
        //loading eklenecek
        setSelected1([])
       await getGroupList()
       getGroupList1()
       setHandleGroupLoading(false)
      }).catch((err) => {
        setHandleGroupLoading(false)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }
  const handleGroupAll = async () => {
    setGroupAllLoading(true)
    setHandleGroupLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/addGroupToJourney?journeyId=${journeyId}&isAll=true`,
        {
          filters: {
            options:groupOptions
          }
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      ).then(async (response: any) => {
        setGroupAllLoading(false)
        //loading eklenecek
        setSelected([])
       await getGroupList1()
        getGroupList()
        setHandleGroupLoading(false)
      }).catch((err) => {
        setGroupAllLoading(false)
        setHandleGroupLoading(false)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }
  const handleUnGroupAll = async () => {
    setUnGroupAllLoading(true)
    setHandleGroupLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/removeGroupFromJourney?journeyId=${journeyId}&isAll=true`,
        {
          filters: {
            options:groupOptions1
          }
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      ).then( async (response: any) => {
        setUnGroupAllLoading(false)
        //loading eklenecek
        setSelected1([])
        await getGroupList()
        getGroupList1()
        setHandleGroupLoading(false)
      }).catch((err) => {
        setUnGroupAllLoading(false)
        setHandleGroupLoading(false)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  useEffect(() => {
    getGroupList()
  }, [groupOptions, paginationModel, columnVisibilityModel,])
  useEffect(() => {
    getGroupList1()

  }, [groupOptions1, paginationModel1, columnVisibilityModel1,])

  useEffect(() => {
    if(rows1.length > 0){
      setGroupList(rows1)
    }else{
      setGroupList([])
    }

  }, [rows1])

  return (
    <>
      <Grid container>
        <Grid xs={5.5}>
          <Box display={"flex"} justifyContent={"space-between"} paddingLeft={2} paddingRight={2}>
            <div></div>
            <Typography color={"#ADB5BD"} fontSize={20}>All Users</Typography>
            <Button variant='outlined' sx={{ fontWeight: "bold" }} size='small' color='primary' disabled={unGroupAllLoading || groupAllLoading || isLoadingData || isLoadingData1 || rows.length < 1} onClick={() => handleGroupAll()} >Select All {groupAllLoading && <CircularProgress size={17} color='primary' sx={{ marginLeft: 1 }} />}</Button>
          </Box>
          <Box sx={{ height: 530, width: '100%', padding: 0 }}>
            <StyledDataGrid
              rows={rows || []}
              columns={columns || []}
              rowHeight={50}
              disableVirtualization
              key={1}
              checkboxSelection={participantsUser === true ? false : true}
              keepNonExistentRowsSelected
              onCellClick={handleCellOneClick}
              getRowId={(row) => row.id}
              disableRowSelectionOnClick
              sortingMode='server'
              onSortModelChange={handleSortModelChange}
              rowSelectionModel={selected}
              onRowSelectionModelChange={handleSelectionModelChange}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              filterMode='server'
              onFilterModelChange={onFilterChange}
              rowCount={rowCount}
              loading={isLoadingData}
              paginationModel={paginationModel}
              paginationMode='server'
              pagination={true}
              onPaginationModelChange={setPaginationModel}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
              }
              slots={{
                pagination: CustomPagination,
              }}
              sx={{
                borderRadius: '15px',
                boxShadow: 3,
                '& .MuiDataGrid-cell:hover': {
                  cursor: 'pointer',
                },
                '&	.MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  color: '#A1A5B7',
                  fontSize: 17,
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
              }}
            />
          </Box>
        </Grid>
        <Grid xs={1} display={"flex"} alignContent={"center"} flexDirection={"column"} justifyContent={"center"}>
          <Button variant='text' disabled={selected.length < 1 || participantsUser} onClick={handleGroup}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='100'
              height='100'
              viewBox='0 0 35.958 49.632'
            >
              <g id='ArrowSmall' transform='translate(35) rotate(90)'>
                <path
                  id='Vector'
                  d='M0,0,24.8,24.816,0,49.632H12.4L35.029,26.988a3,3,0,0,0,0-4.343L12.4,0Z'
                  transform='translate(50 40) scale(1 -1) rotate(90)'
                  fill='#6e6f6f'
                  opacity={selected.length < 1 || participantsUser? '0.5' : '0.8'}
                />
              </g>
            </svg>
          </Button>
          <Button variant='text' disabled={selected1.length < 1 || participantsUser1} onClick={handleUnGroup}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='100'
              height='100'
              viewBox='0 0 35.958 49.632'
            >
              <g id='ArrowSmall' transform='translate(35.958) rotate(90)'>
                <path
                  id='Vector'
                  d='M0,0,24.8,24.816,0,49.632H12.4L35.029,26.988a3,3,0,0,0,0-4.343L12.4,0Z'
                  transform='translate(49.632 0) rotate(90)'
                  fill='#6e6f6f'
                  opacity={selected1.length < 1 || participantsUser1? '0.5' : '0.8'}
                />
              </g>
            </svg>
          </Button>
        </Grid>
        <Grid xs={5.5}>
          <Box display={"flex"} justifyContent={"space-between"} paddingLeft={2} paddingRight={2}>
            <div></div>
            <Typography color={"#ADB5BD"} fontSize={20}>Assigned Users</Typography>
            <Button variant='outlined' sx={{ fontWeight: "bold" }} size='small' color='error' disabled={unGroupAllLoading || groupAllLoading || isLoadingData || isLoadingData1 || rows1.length < 1} onClick={() => handleUnGroupAll()} >Remove All{unGroupAllLoading && <CircularProgress size={17} color='error' sx={{ marginLeft: 1 }} />}</Button>
          </Box>
          <Box sx={{ height: 530, width: '100%', padding: 0 }}>
            <StyledDataGrid
              rows={rows1 || []}
              columns={columns1 || []}
              key={2}
              rowHeight={50}
              checkboxSelection={participantsUser1 === true ? false : true}
              keepNonExistentRowsSelected
              onCellClick={handleCellOneClick1}
              getRowId={(row) => row.id}
              disableRowSelectionOnClick
              sortingMode='server'
              onSortModelChange={handleSortModelChange1}
              rowSelectionModel={selected1}
              onRowSelectionModelChange={handleSelectionModelChange1}
              columnVisibilityModel={columnVisibilityModel1}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel1(newModel)
              }
              filterMode='server'
              onFilterModelChange={onFilterChange1}
              rowCount={rowCount1}
              loading={isLoadingData1}
              paginationModel={paginationModel1}
              paginationMode='server'
              pagination={true}
              onPaginationModelChange={setPaginationModel1}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
              }
              slots={{
                pagination: CustomPagination1,
              }}
              sx={{
                borderRadius: '15px',
                boxShadow: 3,
                '& .MuiDataGrid-cell:hover': {
                  cursor: 'pointer',
                },
                '&	.MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  color: '#A1A5B7',
                  fontSize: 17,
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
export default AddGroup
