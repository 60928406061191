import React, { CSSProperties, FC, forwardRef, useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Tabs, Tab, Row, Col, Stack, Spinner } from 'react-bootstrap'
import * as XLSX from 'xlsx'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import Select, { StylesConfig } from 'react-select'
import { useIntl } from 'react-intl'
import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Slide,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import AddGroupModal from './AddGroupModal'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { TransitionProps } from '@mui/material/transitions'
import { useDispatch } from 'react-redux'
import Creators from '../../../ReduxNew/reducers/LoginRedux'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

type Props = {
  setfirst: any
  first: any
  groupList: any
  groupListLoading: boolean
  userCount?: number
  maxUserCount?: number
  setOpenWarnModalStatus?: any
}

export const WizardAddUser: FC<Props> = ({ setfirst, first, groupList, groupListLoading, userCount, maxUserCount, setOpenWarnModalStatus }) => {
  // handleFinish final to do
  const delay = (ms: any) => new Promise((res) => setTimeout(res, ms))
  const dispatch = useDispatch()
  function getuserWidthJwt() {
    dispatch(Creators.getUserReduxRequest())
  }

  const [items, setItems] = useState([])
  const [SendData, setSendData] = useState<any[]>([])
  //excel to json works
  const [openWarnModal, setopenWarnModal] = useState(false)
  const [totalExcelUsers, setTotalExcelUsers] = useState([])
  const [userWrongData, setUserWrongData] = useState({ email: [], name: [], surname: [], duplicateEmail: [] })

  const readExcel = (file: any) => {
    const invalidData = [];
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)

      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result
        const wb = XLSX.read(bufferArray, { type: 'buffer' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]

        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1,
        })

        resolve(data)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })

    promise.then((d: any) => {
      setItems(d)
      // setSendData(Array.from(d).map((e:any)=>e))

      var rownum = 0

      const data = d.map(function (item: any) {
        rownum++;
        const cleanedItem = [
          item[0]?.toString().trim(),
          item[1]?.toString().trim(),
          item[2]?.toString().trim(),
          item[3],
          item[4]?.toString().trim(),
          item[5]?.toString().trim(),
        ];

        const emailNotification =
          cleanedItem[3] === '1' || cleanedItem[3] === 1
            ? false
            : cleanedItem[3] === '0' || cleanedItem[3]/* ?.toLowerCase() */ === 'yes'
              ? true
              : true;

        return {
          name: cleanedItem[0]?.split(' ')
            .map((namePart) =>
              namePart.charAt(0).toUpperCase() + namePart.slice(1).toLowerCase()
            )
            .join(' ') || '',
          surname: cleanedItem[1]?.split(' ')
            .map((namePart) =>
              namePart.charAt(0).toUpperCase() + namePart.slice(1).toLowerCase()
            )
            .join(' ') || '',
          email: cleanedItem[2]?.toLowerCase() || '',
          emailNotification: emailNotification,
          phone: cleanedItem[4] || '',
          InternalEmployeeId: cleanedItem[5] || '',
          rowNumber: rownum,
        };
      });

      /*  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       console.log("data : ", data)
       const results = data.filter((element: any) => {
         const isEmailValid = emailRegex.test(element.email);
         return isEmailValid && (element.name || element.surname || element.email !== '');
       }) */
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      data.forEach((item: any) => {
        if (
          !(item.name && item.name.length >= 2) &&
          !(item.surname && item.surname.length >= 2) ||
          !emailRegex.test(item.email)
        ) {
          invalidData.push(item);
        }
      });
      let dataWrong = ""
      const emailCountMap = new Map();
      data.forEach((element: any) => {
        if (element.email && element.email.length > 0) {
          emailCountMap.set(element.email, (emailCountMap.get(element.email) || 0) + 1);
        }
      });
      setUserWrongData(data.reduce((acc, element) => {
        // Herhangi bir alan doluysa ve belirli hatalar varsa, hataları topla
        const keysToLower = Object.keys(element).map(key => key.toLowerCase());

        // Belirtilen anahtarlar mevcut değilse hata toplama işlemine devam et
        if (!(keysToLower.includes('name') || keysToLower.includes('surname') || keysToLower.includes('email'))) {
          if ((element.name && element.name.length > 0) ||
            (element.surname && element.surname.length > 0) ||
            (element.email && element.email.length > 0)) {

            if (!element.name || element.name.length === 1) {
              if (!acc.name) acc.name = [];
              acc.name.push(element.rowNumber);
            }

            if (!element.surname || element.surname.length === 1) {
              if (!acc.surname) acc.surname = [];
              /* acc.surname.push(element.rowNumber); */
              acc.name.push(element.rowNumber);
            }

            if (element.email.length > 0 && !emailRegex.test(element.email)) {
              if (!acc.email) acc.email = [];
              acc.email.push(element.rowNumber);
            }
            if (element.email && emailCountMap.get(element.email) > 1) {
              if (!acc.duplicateEmail) acc.duplicateEmail = [];
              acc.duplicateEmail.push(element.rowNumber);
            }
          }

        }
        return acc; // Hataları biriktirerek döndür
      }, {}));

      const results = data.filter((element: any) =>
        (element.name && element.name.length >= 2) && (element.surname && element.surname.length >= 2) && emailRegex.test(element.email)
      );

      if (results.length > 0 && typeof results[0] === 'object') {
        // İlk elemanın anahtarlarını küçük harfe çevir
        const keysToLower = Object.keys(results[0]).map(key => key.toLowerCase());

        // 'name', 'surname', 'email' başlıklarını içerip içermediğini kontrol et
        if (keysToLower.includes('name') || keysToLower.includes('surname') || keysToLower.includes('email')) {
          // Eğer içeriyorsa, ilk elemanı sil
          results.shift();
        }
      }
      setTotalExcelUsers(results)
      const maxLength = maxUserCount - userCount;
      const finalResults = results.slice(0, maxLength);
      setSendData(finalResults)
    })
  }
  useEffect(() => {
    if (SendData.length > 0) {
      handleCheckData()
    }
  }, [SendData])

  const [state, setState] = useState({
    photo: ' ',
  })
  const [isSelected, setisSelected] = useState(false)
  const [afterDot, setafterDot] = useState<any>('')
  const mode = useThemeMode()
  const customControlStyles: CSSProperties = {
    textDecorationColor: mode.mode == 'dark' ? 'black' : '',
    backgroundColor: mode.mode == 'dark' ? '#1b1b29' : '',
    color: mode.mode == 'dark' ? 'black' : '',
  }
  const selectStyle: StylesConfig = {
    control: (provided, state) => {
      // provided has CSSObject type
      // state has ControlProps type

      // return type is CSSObject which means this line will throw error if uncommented
      // return false;

      return {
        ...provided,
        ...customControlStyles,
      }
    },
  }
  const onChange = (event: any) => {
    setafterDot(event.target.value.substr(event.target.value.indexOf('.')))
    setisSelected(true)
    const Ulanyeterbe = 1
    event.persist()
    setState((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }))

    state.photo = event.target.value
    setText(state.photo)
    return text
  }
  const [text, setText] = useState<any>('')
  const [show, setShow] = useState(false)
  const sleep = async (milliseconds: any) => {
    await new Promise((resolve) => {
      return setTimeout(resolve, milliseconds)
    })
  }
  const handleClose = () => {
    setCurrentTab(0)
    setShow(false)
    setfile('')
    setisSelected(false)
    setText('')
    setselectedGroup([])
    handleDelete()
  }

  const handleShow = () => setShow(true)
  const [currentTab, setCurrentTab] = React.useState(0)
  const [check, setCheck] = useState<any>('')
  function isMoreThanTenErrors(item: any) {
    const rows = item.split(', ').filter((n) => n) // Boş değerleri filtreliyoruz
    return rows.length > 10
  }

  function handleClick() {
    var myElement = document.getElementById('success')
    //myElement.classList.add("anotherclass");
    myElement?.classList.remove('invisible')
    var myElement = document.getElementById('next')
    //myElement.classList.add("anotherclass");
    myElement?.classList.remove('disabled')
  }
  /*  const [GroupList, setGroupList] = useState<any>([]) */
  const [openGroupModal, setOpenGroupModal] = useState<any>(false)
  const [refresh, setRefresh] = useState<any>(false)
  const [selectedGroup, setselectedGroup] = useState<any>([])

  function handleGroupListChange(event: any, newValue: any) {
    setselectedGroup(newValue)
  }

  const LoginState = useSelector((state: any) => state.Login)
  //grup list

  /*  useEffect(() => {
    const ax = axios.create({headers: {Accept: 'application/json'}})

    function test() {
      const res = ax
        .get(
          `${process.env.REACT_APP_API_URL}/user/getGroupListCustomerIdNoPagination`,
          {
            headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
          }
        )
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setGroupList(response.data)
        })
    }
    test()
  }, [ customer ]) */
  const [placeholder, setPlaceholder] = useState<any>('')
  //databaseye yolluyoruz

  useEffect(() => {
    if (selectedGroup?.length > 0) {
      setPlaceholder(true)
    } else {
      setPlaceholder(false)
    }
  }, [selectedGroup])

  const ax = axios.create({ headers: { Accept: 'application/json' } })
  //databaseye yolluyoruz
  const [file, setfile] = useState<any>(null)
  const [loadingExcel, setLoadingExcel] = useState(false)
  const [checkError, setCheckError] = useState(null)

  function handleCheckData() {
    setLoadingExcel(true)
    const form = new FormData()
    form.append('file', file)
    axios.post(
      `${process.env.REACT_APP_API_URL}/user/importUsersFromExcel`,
      {
        users: SendData,
        isPreview: true
      },
      {
        headers: {
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      }
    )
      .then(async function (response) {
        /*    setCheck(response.data) */

        setCheckError(response.data)

      })
      // expected the setProducts to be filled with the return of this request
      .catch((err) => {
        setTimeout(() => {
          setLoadingExcel(false)
        }, 1500)
      })
  }
  function handleFinish() {
    setLoadingExcel(true)
    const axios = require('axios')
    const form = new FormData()
    form.append('file', file)
    var groupID = selectedGroup.map((li: any) => li.value)

    ax.post(
      `${process.env.REACT_APP_API_URL}/user/importUsersFromExcel`,
      {
        users: SendData,
        groupId: groupID,
        isPreview: false
      },
      {
        headers: {
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      }
    )
      .then(async function (response) {
        setCheck(response.data)
        setTimeout(() => {
          setLoadingExcel(false)
        }, 1500)
        await axios
          .post(`${process.env.REACT_APP_API_URL}/user/uploadUserExcelFile/${text3}`, form, {
            headers: {
              accept: 'application/json',
              Authorization: `Bearer ${LoginState.loginData.access_token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((e: any) => {
            setfirst(!first)
            handleDelete()
            selectedGroup()
          })
          .catch((err) => { })
        getuserWidthJwt()
      })
      // expected the setProducts to be filled with the return of this request
      .catch((err) => {
        setTimeout(() => {
          setLoadingExcel(false)
        }, 1500)
      })
  }
  var loading = document.getElementById('loading')
  var returnn = document.getElementById('return')

  //minumum 3sn delay koyuyoruz ki loading ekranı direkt geçmesin
  const testSleep = async () => {
    await sleep(1000)
    loading?.classList.add('d-none')
    returnn?.classList.remove('d-none')
  }
  useEffect(() => {
    testSleep()
  }, [check])
  const [text2, settext2] = useState<any>('')
  const [text3, settext3] = useState<any>('')
  useEffect(() => {
    settext2(text?.substr(text?.indexOf('.')))
  }, [text])
  useEffect(() => {
    settext3(text2.slice(1))
  }, [text2])
  const HandleError = (e: any) => {
    return (
      <div className='d-flex justify-content-center '>
        <div className='mt-20'>
          <span className='text-success h4'>
            ✔ {e.successNumber} {intl.formatMessage({ id: 'User.Import.Error.1' })}
          </span>
          <br />
          <div className='mt-5'>
            <span className='text-danger h4 mt'>
              ! {e.errorNumber} {intl.formatMessage({ id: 'User.Import.Error.2' })}
            </span>
          </div>

          <div className='mt-5'>
            <span
              className={e?.errors?.emailAlreadyInUse.length > 0 ? 'text-danger h6 ms-4' : 'd-none'}
            >
              {intl.formatMessage({ id: 'User.Import.Error.11' })} {e?.errors?.emailAlreadyInUse}{' '}
              {intl.formatMessage({ id: 'User.Import.Error.3' })} <br />
            </span>
            <span className={e?.errors?.emptyEmail.length > 0 ? 'text-danger h6 ms-4' : 'd-none'}>
              {intl.formatMessage({ id: 'User.Import.Error.11' })} {e?.errors?.emptyEmail}{' '}
              {intl.formatMessage({ id: 'User.Import.Error.4' })}
              <br />
            </span>

            <span className={e?.errors?.emptySurname.length > 0 ? 'text-danger h6 ms-4' : 'd-none'}>
              {intl.formatMessage({ id: 'User.Import.Error.11' })} {e?.errors?.emptySurname}{' '}
              {intl.formatMessage({ id: 'User.Import.Error.5' })} <br />
            </span>

            <span
              className={
                e?.errors?.atLeastTwoCharactersName.length > 0 ? 'text-danger h6 ms-4' : 'd-none'
              }
            >
              {intl.formatMessage({ id: 'User.Import.Error.11' })}{' '}
              {e?.errors?.atLeastTwoCharactersName}{' '}
              {intl.formatMessage({ id: 'User.Import.Error.6' })}
              <br />
            </span>

            <span
              className={
                e?.errors?.atLeastTwoCharactersSurname.length > 0 ? 'text-danger h6 ms-4' : 'd-none'
              }
            >
              {intl.formatMessage({ id: 'User.Import.Error.11' })}{' '}
              {e?.errors?.atLeastTwoCharactersSurname}{' '}
              {intl.formatMessage({ id: 'User.Import.Error.7' })}
              <br />
            </span>

            <span
              className={
                e?.errors?.internalEmployeeIdAlreadyInUse.length > 0
                  ? 'text-danger h6 ms-4'
                  : 'd-none'
              }
            >
              {intl.formatMessage({ id: 'User.Import.Error.11' })}{' '}
              {e?.errors?.internalEmployeeIdAlreadyInUse}
              {intl.formatMessage({ id: 'User.Import.Error.8' })}
              <br />
            </span>

            <span
              className={e?.errors?.notUniqueEmail.length > 0 ? 'text-danger h6 ms-4' : 'd-none'}
            >
              {intl.formatMessage({ id: 'User.Import.Error.11' })} {e?.errors?.notUniqueEmail}{' '}
              {intl.formatMessage({ id: 'User.Import.Error.9' })}
              <br />
            </span>

            <span
              className={
                e?.errors?.notUniqueInternalEmployeeId.length > 0 ? 'text-danger h6 ms-4' : 'd-none'
              }
            >
              {intl.formatMessage({ id: 'User.Import.Error.11' })}{' '}
              {e?.errors?.notUniqueInternalEmployeeId}{' '}
              {intl.formatMessage({ id: 'User.Import.Error.10' })}
              <br />
            </span>

            <span
              className={e?.errors?.notValidEmail.length > 0 ? 'text-danger h6 ms-4' : 'd-none'}
            >
              {intl.formatMessage({ id: 'User.Import.Error.12' })} {e?.errors?.notValidEmail}{' '}
              {intl.formatMessage({ id: 'User.Import.Error.11' })} <br />
            </span>
          </div>
        </div>
      </div>
    )
  }
  const intl = useIntl()
  const [hoverButton, setHoverButton] = useState(false)

  const [step, setStep] = useState(0)
  const steps = ['Upload Excel File', 'Select User Groups', 'Finish']
  const [excelZoom, setExcelZoom] = useState(false)

  useEffect(() => {
    if (show) {
      setStep(0)
    }
  }, [show])

  const [selectedFileName, setSelectedFileName] = useState('') // Dosya adını tutacak durum değişkeni
  const fileInputRef = useRef(null)
  const handleDelete = () => {
    setSelectedFileName(null)
    setfile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0]

    setfile(event?.target.files[0])
    readExcel(file)
    onChange(event)
    if (file) {
      setSelectedFileName(file.name) // Dosya adını durum değişkenine kaydet
    }
  }

  return (
    <>
      <Box
        display='flex'
        justifyContent='flex-end'
        alignItems='center'
        alignContent='center'
        sx={{ height: 50 }}
      >
        <Tooltip
          arrow
          title='Upload'
          placement='top'
          onMouseEnter={() => {
            setHoverButton(true)
          }}
          onMouseLeave={() => {
            setHoverButton(false)
          }}
          style={{ background: hoverButton ? '#D5D5D5 ' : 'white' }}
        >
          <IconButton
            aria-label='delete'
            onClick={() => userCount >= maxUserCount ? setOpenWarnModalStatus(true) : handleShow()}
            size='small'
            sx={{ background: 'white', marginRight: '7px' }}
          >
            <UploadOutlinedIcon sx={{ fontSize: '30px', color: hoverButton ? 'black' : ' ' }} />
          </IconButton>
        </Tooltip>
      </Box>
      {/*    <button type='button' className='btn ' onClick={handleShow}>
        <span className='svg-icon svg-icon-3x'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='34.682'
            height='44.14'
            viewBox='0 0 34.682 44.14'
          >
            <g id='upload' transform='translate(-80 -32.007)'>
              <path
                id='Path_2184'
                data-name='Path 2184'
                d='M109.164,176H98.917v14.286a1.576,1.576,0,1,1-3.153,0V176H85.518A5.523,5.523,0,0,0,80,181.518v18.917a5.523,5.523,0,0,0,5.518,5.518h23.647a5.523,5.523,0,0,0,5.518-5.518V181.518A5.523,5.523,0,0,0,109.164,176Z'
                transform='translate(0 -129.805)'
                fill='#f4f4f4'
              />
              <path
                id='Path_2185'
                data-name='Path 2185'
                d='M171.3,37.389l5.191,5.19a1.576,1.576,0,0,0,2.229-2.229l-7.882-7.882a1.576,1.576,0,0,0-2.229,0l-7.882,7.882a1.576,1.576,0,0,0,2.229,2.229l5.191-5.19v8.805H171.3Z'
                transform='translate(-72.379 0)'
                fill='#f4f4f4'
              />
            </g>
          </svg>
        </span>
      </button> */}
      <Dialog
        open={show}
        onClose={handleClose}
        maxWidth={'lg'}
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <Typography fontWeight={'bold'} fontSize={26}>
            Import Users
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{ minHeight: '400px' }}>
          <Grid container>
            {
              //#region  stepper
              <Grid xs={12} display={'flex'} justifyContent={'center'}>
                <Grid xs={8}>
                  <Stepper activeStep={step} /* alternativeLabel */>
                    {steps.map((label) => (
                      <Step
                        key={label}
                        sx={{
                          '& .MuiStepIcon-root': {
                            fontSize: '25px',
                            color: '#9E9E9E', // Varsayılan renk
                            fontWeight: 'bold',
                          },
                          '& .Mui-active, & .Mui-completed': {
                            color: '#9c27b0 !important',
                          },
                        }}
                      >
                        <StepLabel
                          sx={{
                            '& .MuiStepLabel-label': {
                              fontSize: '15px', // Label'ların font boyutunu ayarlayın
                            },
                            '& .Mui-active': {
                              fontWeight: 'bold',
                            },
                          }}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
              </Grid>
              //#endregion
            }
            {step === 0 && (
              <Grid xs={12} padding={2} marginTop={1} display={'flex'} justifyContent={'center'}>
                <Grid xs={6}>
                  <Typography color={'#8E8E8E'} fontSize={14}>
                    The Excel sheet must have at least 3 columns: Name, Surname and Email.
                    <br />
                    All other columns are optional.
                    <br />
                    The order of columns is important!
                    <br />
                    1st column: Names
                    <br />
                    2nd column: Surnames
                    <br />
                    3rd column: Email addresses.
                    <br />
                    Existing users with the same email address will not be recorded.
                    <br />
                    <br />
                    4th column is the indicator of dummy emails. You can define the email address
                    for this user as a dummy email, by just typing 1 in column D. The last column is
                    for the employee id of the user in your organization.
                    <br />
                    <br />
                    Save your work as an Excel file on your computer.
                    <br />
                    When you’ve done, press the button and select the file.
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Button
                    variant='text'
                    onClick={() => setExcelZoom(!excelZoom)}
                    style={{ padding: '5px', width: '100%' }}
                  >
                    <Avatar
                      src='/media/userWizardExcel.png'
                      variant='rounded'
                      style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                    />
                  </Button>
                  <Backdrop
                    sx={{ color: '#fff', padding: 10, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={excelZoom}
                    onClick={() => setExcelZoom(false)}
                  >
                    <Avatar
                      src='/media/userWizardExcel.png'
                      variant='rounded'
                      style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                    />
                  </Backdrop>
                  <Box width={'100%'} display={'flex'} justifyContent={'end'}>
                    <Typography fontSize={10}>Example Excel Sheet</Typography>
                  </Box>
                  <Box
                    width={'100%'}
                    marginTop={5}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                  >
                    <Button
                      component='label'
                      variant='contained'
                      style={{ background: '#9C27B0' }}
                      startIcon={<UploadOutlinedIcon />}
                    >
                      SELECT FILE
                      <VisuallyHiddenInput
                        type='file'
                        onChange={handleFileChange}
                        accept='.xlsx,.xls,.xlsm'
                        ref={fileInputRef}
                      />
                    </Button>
                    {file && (
                      <Box
                        marginTop={1}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Typography marginLeft={3} color={'#6E6F6F'}>
                          Selected File:
                        </Typography>

                        <Typography marginLeft={1} fontWeight={'bold'}>
                          {selectedFileName}
                        </Typography>
                        <Tooltip arrow title='Delete' placement='top'>
                          <IconButton
                            size='small'
                            style={{ marginLeft: '5px' /* ,background:"#ADB5BD" */ }}
                            onClick={() => handleDelete()}
                          >
                            <CloseIcon fontSize='small' color='error' />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                    {file && (SendData.length < 1 ? <Box
                      marginTop={1}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Paper sx={{ padding: 2, width: "100%", display: "flex", flexDirection: "column" }}>
                        <Typography color={"#D00000"} sx={{ textAlign: "center" }}>
                          The 3rd column in the file has no email address.
                        </Typography>
                        <Typography color={"#D00000"} sx={{ textAlign: "center" }}>  Press X button above.</Typography>
                        <Typography color={"#D00000"} sx={{ textAlign: "center" }}>
                          Review, fix and reload the file.
                        </Typography>
                      </Paper>
                    </Box> : (totalExcelUsers?.length > (maxUserCount - userCount)) && (
                      <Box
                        marginTop={1}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Paper sx={{ padding: 2, width: "100%", display: "flex", flexDirection: "column" }}>
                          <Typography color={"#D00000"} sx={{ textAlign: "center" }}>
                            The number of users in the Excel file exceeds user capacity on your plan.
                          </Typography>
                          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <Typography color={"#D00000"} sx={{ textAlign: "center" }}>  The first  </Typography>
                            <Typography fontWeight={"bold"} color={"#D00000"} sx={{ textAlign: "center" }} marginLeft={0.5}> {maxUserCount - userCount} lines</Typography>
                            <Typography color={"#D00000"} sx={{ textAlign: "center" }} marginLeft={0.5}> in the excel file will be added.</Typography>
                          </div>

                          <Typography color={"#D00000"} sx={{ textAlign: "center" }}>
                            Press Next to proceed or click X button to select a new file.
                          </Typography>
                        </Paper>
                      </Box>)
                    )}
                    {file && (userWrongData.duplicateEmail?.length > 0 || userWrongData.email?.length > 0 || userWrongData.name?.length > 0) ?
                      <Box
                        marginTop={1}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Paper sx={{ padding: 2, width: "100%", display: "flex", flexDirection: "column" }}>
                          {userWrongData.duplicateEmail?.length > 0 &&
                            <Typography color={"#D00000"} sx={{ textAlign: "center" }}>
                              Row {userWrongData.duplicateEmail.length > 9 ? `9+` : userWrongData.duplicateEmail.join(', ')} duplicated email
                            </Typography>
                          }
                          {
                            userWrongData.email?.length > 0 &&
                            <Typography color={"#D00000"} sx={{ textAlign: "center" }}>
                              Row {userWrongData.email.length > 9 ? `9+` : userWrongData.email.join(', ')} missing email
                            </Typography>
                          }
                          {userWrongData.name?.length > 0 &&
                            <Typography color={"#D00000"} sx={{ textAlign: "center" }}>
                              Row {userWrongData.name.length > 9 ? `9+` : userWrongData.name.join(', ')} missing name or surname
                            </Typography>
                          }

                          <Typography color={"#D00000"} sx={{ textAlign: "center" }}>  Press X button above.</Typography>
                          <Typography color={"#D00000"} sx={{ textAlign: "center" }}>
                            Review, fix and reload the file.
                          </Typography>
                        </Paper>
                      </Box> : null
                    }
                    {file && checkError !== null ?
                      <Box
                        marginTop={1}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Paper sx={{ padding: 2, width: "100%", display: "flex", flexDirection: "column" }}>
                          {checkError?.nrOfEmails !== 0 &&
                            <Box display="flex" columnGap={1}>
                              <Typography color={"#1976D2"} sx={{ textAlign: "center" }}>
                                Number of email addresses in the list:
                              </Typography>
                              <Typography color={"#1976D2"} fontWeight={"bold"} sx={{ textAlign: "center" }}>{checkError?.nrOfEmails}</Typography>
                            </Box>
                          }
                          {
                            checkError?.alreadyUser !== 0 &&
                            <Box display="flex" columnGap={1}>
                              <Typography color={"#1976D2"} sx={{ textAlign: "center" }}>
                                Existing users:
                              </Typography>
                              <Typography color={"#1976D2"} fontWeight={"bold"} sx={{ textAlign: "center" }}>{checkError?.alreadyUser}</Typography>
                            </Box>

                          }
                          {checkError?.newUsers !== 0 &&
                            <Box display="flex" columnGap={1}>
                              <Typography color={"#1976D2"} sx={{ textAlign: "center" }}>
                                Number of user creation requests:
                              </Typography>
                              <Typography color={"#1976D2"} fontWeight={"bold"} sx={{ textAlign: "center" }}>{checkError?.newUsers}</Typography>
                            </Box>
                          }
                          {checkError?.availableUsers !== 0 && checkError?.newUsers > checkError?.availableUsers &&
                            <Box display="flex" columnGap={1}>
                              <Typography color={"#1976D2"} sx={{ textAlign: "center" }}>
                                Available user capacity in your plan:
                              </Typography>
                              <Typography color={"#1976D2"} fontWeight={"bold"} sx={{ textAlign: "center" }}>{checkError?.availableUsers}</Typography>
                            </Box>
                          }
                          {checkError?.availableUsers !== 0 && checkError?.newUsers > checkError?.availableUsers &&
                            <Box display="flex" columnGap={1}>
                              <Typography color={"#1976D2"} sx={{ textAlign: "center" }}>
                                The number of new users to be created:
                              </Typography>
                              <Typography color={"#1976D2"} fontWeight={"bold"} sx={{ textAlign: "center" }}>{checkError?.availableUsers}</Typography>
                            </Box>
                          }
                          <Typography color={"#1976D2"} sx={{ textAlign: "center" }}>  Proceed?</Typography>
                        </Paper>
                      </Box> : null
                    }
                  </Box>
                </Grid>
              </Grid>
            )}
            {step === 1 && (
              <Grid xs={12} padding={2} marginTop={3} display={'flex'} justifyContent={'center'}>
                <Grid xs={6} display={'flex'} flexDirection={'column'} justifyItems={'start'}>
                  <Typography fontSize={12} color={'#8E8E8E'}>
                    Choose the groups in which the new users will be added.
                    <br />
                    <br /> You can select more than one or none.
                    <br />
                    <br /> Group selection is optional.
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Autocomplete
                    multiple
                    fullWidth
                    limitTags={2}
                    id='multiple-limit-tags'
                    value={selectedGroup}
                    loading={groupListLoading}
                    onChange={handleGroupListChange}
                    options={groupList.map((category: any) => ({
                      label: category.groupName,
                      value: category.groupId,
                    }))}
                    getOptionLabel={(option: any) => option.label}
                    style={{ minWidth: 150, marginRight: '10px' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color='primary'
                        label='Select Group'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {groupListLoading ? (
                                <CircularProgress color='primary' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {step === 2 && (
              <Grid xs={12} padding={2} marginTop={3}>
                {loadingExcel && (
                  <Box
                    height={'250px'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                  >
                    <Typography fontSize={18} color={'#6C757D'}>
                      Processing...
                    </Typography>
                    <CircularProgress size={40} />
                  </Box>
                )}
                {!loadingExcel && (
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    rowGap={2}
                    width={'100%'}
                    height={'280px'}
                  >
                    {check?.successNumber > 0 && (
                      <Paper style={{ minWidth: '350px', height: '70px', background: '#2E7D32' }}>
                        <Box
                          padding={1}
                          display={'flex'}
                          justifyContent={'start'}
                          alignContent={'center'}
                          textAlign={'center'}
                        >
                          <CheckCircleOutlineIcon style={{ color: 'white' }} />
                          <Typography marginLeft={1} color={'white'}>
                            Success
                          </Typography>
                        </Box>
                        <Typography marginLeft={1} color={'white'}>
                          Success {check?.successNumber} records have been added successfully
                        </Typography>
                      </Paper>
                    )}
                    {check?.errorNumber > 0 && (
                      <Paper style={{ minWidth: '350px', height: 'auto', background: '#D32F2F' }}>
                        <Box
                          padding={1}
                          display={'flex'}
                          justifyContent={'start'}
                          alignItems={'center'}
                        /* textAlign={'end'} */
                        >
                          <ErrorOutlineIcon style={{ color: 'white' }} />
                          <Typography marginLeft={1} color={'white'}>
                            Error
                          </Typography>
                        </Box>
                        <Typography marginLeft={1} color={'white'}>
                          {check?.errorNumber} records could NOT been saved
                        </Typography>
                        {check?.errors?.emailAlreadyInUse &&
                          isMoreThanTenErrors(check?.errors?.emailAlreadyInUse) ? (
                          <Typography marginLeft={1} color={'white'}>
                            10+ rows existing user
                          </Typography>
                        ) : (
                          check?.errors?.emailAlreadyInUse && <Typography marginLeft={1} color={'white'}>
                            Row {check?.errors?.emailAlreadyInUse} existing user
                          </Typography>
                        )}

                        {check?.errors?.notUniqueEmail &&
                          isMoreThanTenErrors(check?.errors?.notUniqueEmail) ? (
                          <Typography marginLeft={1} color={'white'}>
                            10+ rows not unique email
                          </Typography>
                        ) : (
                          check?.errors?.notUniqueEmail && <Typography marginLeft={1} color={'white'}>
                            Row {check?.errors?.notUniqueEmail} not unique email
                          </Typography>
                        )}
                        {check?.errors?.notValidEmail &&
                          isMoreThanTenErrors(check?.errors?.notValidEmail) ? (
                          <Typography marginLeft={1} color={'white'}>
                            10+ rows missing email
                          </Typography>
                        ) : (
                          check?.errors?.notValidEmail && <Typography marginLeft={1} color={'white'}>
                            Row {check?.errors?.notValidEmail} missing email
                          </Typography>
                        )}

                        {check?.errors.emptyName &&
                          isMoreThanTenErrors(check?.errors?.emptyName) ? (
                          <Typography marginLeft={1} color={'white'}>
                            10+ rows missing name
                          </Typography>
                        ) : (
                          check?.errors.emptyName && <Typography marginLeft={1} color={'white'}>
                            Row {check?.errors.emptyName} missing name
                          </Typography>
                        )}
                        {check?.errors.emptySurname &&
                          isMoreThanTenErrors(check?.errors?.emptySurname) ? (
                          <Typography marginLeft={1} color={'white'}>
                            10+ rows missing surname
                          </Typography>
                        ) : (
                          check?.errors.emptySurname && <Typography marginLeft={1} color={'white'}>
                            Row {check?.errors.emptySurname} missing surname
                          </Typography>
                        )}
                        {/*   {check?.errors.internalEmployeeIdAlreadyInUse &&
                        isMoreThanTenErrors(check?.errors?.internalEmployeeIdAlreadyInUse) ? (
                          <Typography marginLeft={1} color={'white'}>
                            10+ rows missing employeeId
                          </Typography>
                        ) : (
                          <Typography marginLeft={1} color={'white'}>
                            Row {check?.errors.internalEmployeeIdAlreadyInUse} missing employeeId
                          </Typography>
                        )} */}
                        {/* {check?.errors.notUniqueInternalEmployeeId && (
                          <Typography marginLeft={1} color={'white'}>
                            Row {check?.errors.notUniqueInternalEmployeeId} not unique employeeId
                          </Typography>
                        )} */}
                      </Paper>
                    )}
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
          <AddGroupModal
            openGroupModal={openGroupModal}
            setRefresh={setfirst}
            refresh={first}
            setOpenGroupModal={setOpenGroupModal}
          />
        </DialogContent>
        <DialogActions>
          {
            step === 1 &&
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "50px" }} >

              <Typography fontSize={12} color={'#8E8E8E'} marginRight={1}>
                Not in the list?
              </Typography>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => setOpenGroupModal(true)}
                sx={{/* textTransform: 'none',  */fontWeight: 'bold' }}
              >
                Create new group
              </Button>
            </div>
          }


          {step === 2 ? null : (
            <Button
              variant='contained'
              color='inherit'
              onClick={() =>
                step === 0
                  ? step === 0 && file === null
                    ? handleClose()
                    : setopenWarnModal(true)
                  : setStep(step !== 0 && step - 1)
              }
            >
              {' '}
              {step === 0 ? 'DISCARD' : 'BACK'}
            </Button>
          )}

          <Button
            variant='contained'
            disabled={(userWrongData.duplicateEmail?.length > 0 || userWrongData.email?.length > 0 || userWrongData.name?.length > 0) ||
              (step === 0 && file === null) || SendData.length < 1 ||
              (step === 2 && loadingExcel) /* || step === 1 && (selectedGroup?.length < 1) */
            }
            onClick={() => (
              step === 1 && handleFinish(),
              setStep(step !== 2 && step + 1),
              step === 2 && (setCheck(''), handleClose())
            )}
            style={{ marginRight: '30px', marginLeft: '30px' }}
          >
            {step === 2 ? 'DONE' : 'NEXT'}
          </Button>
        </DialogActions>
      </Dialog>
      {
        //warn modal
      }
      <Dialog
        open={openWarnModal}
        TransitionComponent={Transition}
        fullWidth
        maxWidth={'xs'}
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        /* onClose={handleClickWarnModalClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Typography color='white' variant='h6'>
              You have unsaved changes.
              <br />
              Do you want to discard, Anyway?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: 'white' }}
            onClick={() => {
              setopenWarnModal(false)
              handleClose()
            }}
          >
            Yes
          </Button>
          <Button style={{ color: 'white' }} onClick={() => setopenWarnModal(false)}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
