/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'

type Props = {
  className: string
}

const CreatorWeekWidget: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Ayın İçerik Üreticisi</h3>
        <div className='card-toolbar'></div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Item */}
        <div className='d-flex justify-content-center'>
          {/* begin::Avatar */}
          <div className='symbol symbol-100px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/300-6.jpg')} className='' alt='' />
            <div style={{position: 'absolute', width: 104, height: 104, left: 82, top: -10}}>
              <svg
                width='40'
                height='39'
                viewBox='0 0 33 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13.8688 4.95247C14.9891 2.84388 18.0109 2.84388 19.1312 4.95247V4.95247C19.8026 6.21604 21.2776 6.82388 22.6442 6.39989L22.8516 6.33555C25.0776 5.64489 27.1536 7.75832 26.4233 9.97168V9.97168C25.9772 11.3236 26.5911 12.7982 27.8647 13.4345V13.4345C29.9794 14.4909 29.9794 17.5091 27.8647 18.5655V18.5655C26.5911 19.2018 25.9772 20.6764 26.4233 22.0283V22.0283C27.1536 24.2417 25.0776 26.3551 22.8516 25.6644L22.6442 25.6001C21.2776 25.1761 19.8026 25.784 19.1312 27.0475V27.0475C18.0109 29.1561 14.9891 29.1561 13.8688 27.0475V27.0475C13.1974 25.784 11.7224 25.1761 10.3558 25.6001L10.1484 25.6644C7.92238 26.3551 5.84637 24.2417 6.57669 22.0283V22.0283C7.02277 20.6764 6.40889 19.2018 5.13534 18.5655V18.5655C3.02064 17.5091 3.02064 14.4909 5.13534 13.4345V13.4345C6.40888 12.7982 7.02277 11.3236 6.57669 9.97168V9.97168C5.84637 7.75832 7.92238 5.64489 10.1484 6.33555L10.3558 6.39989C11.7224 6.82388 13.1974 6.21604 13.8688 4.95247V4.95247Z'
                  fill='#00A3FF'
                ></path>
                <path
                  d='M15.6455 5.87764C15.9504 4.96792 17.2371 4.96792 17.5419 5.87764L19.5404 11.8419C19.6769 12.2495 20.0587 12.5242 20.4886 12.5242H26.8887C27.8649 12.5242 28.2626 13.7795 27.4646 14.3417L22.3403 17.9519C21.979 18.2065 21.8276 18.668 21.9681 19.0871L23.9378 24.9656C24.2447 25.8815 23.2033 26.6572 22.4137 26.1008L17.1697 22.4063C16.8243 22.1629 16.3632 22.1629 16.0178 22.4063L10.7738 26.1008C9.98412 26.6572 8.94276 25.8815 9.24966 24.9656L11.2194 19.0871C11.3598 18.668 11.2085 18.2065 10.8471 17.9519L5.7228 14.3417C4.9248 13.7795 5.32258 12.5242 6.29874 12.5242H12.6989C13.1287 12.5242 13.5105 12.2495 13.6471 11.8419L15.6455 5.87764Z'
                  fill='#FFCB14'
                ></path>
              </svg>
            </div>
            {/* begin::Text */}
            <div className='flex-grow-1 mt-3'>
              <span className='text-dark fw-bold  fs-6'>Emma Smith</span>
              <span className='text-muted d-block fw-semobold'>Project Manager</span>
            </div>
            {/* end::Text */}
          </div>
          {/* end::Avatar */}

          {/* begin::Text */}
          <div className='text-center'>
            <span className='text-dark fw-bold  display-4'>78</span>
            <span className='text-dark d-block fw-semobold'>Toplam İçerik</span>
            <div className='mt-5'>
            <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="button-tooltip-2">Question</Tooltip>}
              >
              <span className='border-question  border rounded text-question mx-1 p-1 fs-1'>44</span>

            </OverlayTrigger>
            <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="button-tooltip-2">Pictures</Tooltip>}
              >
              <span className='border-pictures  border rounded text-pictures mx-1 p-1 fs-1'>23</span>

            </OverlayTrigger>
            <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="button-tooltip-2">Video</Tooltip>}
              >
              <span className='border-video  border rounded text-video mx-1 p-1 fs-1'>23</span>

            </OverlayTrigger>
            <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="button-tooltip-2">Knowledge Card</Tooltip>}
              >
              <span className='border-knowledgecard  border rounded text-knowledgecard mx-1 p-1 fs-1'>23</span>

            </OverlayTrigger>
            <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="button-tooltip-2">Memory Card</Tooltip>}
              >
              <span className='border-memorycard  border rounded text-memorycard mx-1 p-1 fs-1'>23</span>

            </OverlayTrigger>
            <div style={{visibility:'hidden'}}>
            <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="button-tooltip-2">Categories</Tooltip>}
              >
              <span className='border-gray-400  border rounded text-gray-400 mx-1 p-1 fs-1'>23</span>

            </OverlayTrigger>
            </div>

            </div>
          </div>

          {/* end::Text */}
        </div>

        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {CreatorWeekWidget}
