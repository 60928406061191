/* eslint-disable jsx-a11y/anchor-is-valid */
import LoginTypes from '../../../../_MobixaPlus/ReduxNew/reducers/LoginRedux'
import ChangePasswordModal from '../../../../_MobixaPlus/auth/components/NewPasswordModal'
import Versionmodall from '../../../../_MobixaPlus/widgets/shared/Versionmodal'
import { Languages } from './Languages'
import { MySchedule } from './myschedule'
import { Avatar, Chip, Grid, Stack, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

type Props = {
  user: any
}

const HeaderUserMenu: FC<Props> = ({ user }) => {
  const [roller, setroller] = useState<any>([])
  useEffect(() => {
    setroller(user?.role?.map((li: any) => li.roleId))
  }, [user])

  const [button, setbutton] = useState(true)
  useEffect(() => {
    if (roller?.includes(0) || roller?.includes(1)) {
      setbutton(false)
    } else {
      setbutton(true)
    }
  }, [roller])
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const handlelogout = () => {
    dispatch(LoginTypes.logout())
    window.location.href = '/login'
  }

  /* const [customer, setCustomer] = useState<any>(sessionStorage.getItem('customerProfile') ) */
  const customerProfile = useSelector((state: any) => state.Login.customerProfile)

  const navigateUser = () => {
    // 👇️ navigate to /
    navigate(`/manage/users/detail?id=${user?.userId}`)
  }
  const handleAccount = () => {
    // 👇️ navigate to /
    navigate(`/administration/customers/profile?id=${customerProfile.customerId}`, { state: user })
  }

  const roleId = (e: any) => {
    const roleStyles = {
      0: { label: 'System Admin', color: '#D9214E' },
      1: { label: 'Master Admin', color: '#00BCD4' },
      2: { label: 'Content Editor', color: '#0000003B' },
      3: { label: 'Trainer', color: '#9C27B0' },
      4: { label: 'Reporter', color: '#F44336' },
      5: { label: 'IT', color: '#605656' },
      6: { label: 'Finance', color: '#4CAF50' },
    }
    const role = roleStyles[e] || { label: 'No Role', color: 'default' }
    return (
      <Chip
        label={role.label}
        variant='outlined'
        size='small'
        style={{
          borderRadius: '15px',
          backgroundColor: role.color,
          color: 'white',
          /*  fontSize: '1rem', */
          /*  height: 'auto',
          padding: '4px 12px', */
          // Burada daha fazla stil özelliği ekleyebilirsiniz.
        }}
      />
    )
  }
  /* console.log("user:", user) */
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semobold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <Stack direction='column' alignItems='start' width={'100%'} /* spacing={2} */>
            <Typography variant='h6' fontWeight='bold'>
              {user?.name} {user?.surname}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              {user?.email}
            </Typography>
            <Grid container>
              <Grid xs={5} marginTop={1}>
                <Avatar
                  sx={{ width: 90, height: 90 }}
                  src={user?.avatarPath || '/media/avatars/blank.png'}
                  alt={`${user?.name} ${user?.surname}`}
                  variant='rounded'
                />
              </Grid>
              <Grid xs={7} display={'flex'} justifyItems={'start'} alignItems={'end'}>
                <div>
                  {user?.role?.map((item: any, num: any) => {
                    return <>{roleId(item.roleId)}</>
                  })}
                </div>
              </Grid>
            </Grid>
          </Stack>
          {/*   <div className='symbol symbol-50px me-5'>
            {user?.avatarPath ? (
              <img src={user?.avatarPath} alt='' className='rounded-circle' />
            ) : (
              <img
                src={toAbsoluteUrl('/media/avatars/blank.png')}
                alt=''
                className='rounded-circle'
              />
            )}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex  fs-5'>
              {user?.name} {user?.surname}
             
            </div>
            <span className='fw-semobold text-muted text-hover-primary fs-7'>{user?.email}</span>
            <div className=''>
              <div className='text-danger fw-bold fs-8 py-1 p-0'>{user?.role?.map((item:any,num:any)=>{
                return (<>
                 {roleId(item.roleId)}</>)
              })}
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <AdminUserModal /> */}

      <div className='menu-item px-5'>
        <a onClick={navigateUser} className='menu-link px-5'>
          <span className='menu-text'>My Profile</span>
        </a>
        <div className='px-5' style={{ color: '#7e8299' }}>
          <span className='menu-text' style={{ marginRight: '3px' }}>
            My Journeys
          </span>
          <span className='menu-badge'>
            <span className='text-danger badge-circle fw-bold fs-7'>
              {customerProfile?.myJourneysCount}
            </span>
          </span>
        </div>
        <MySchedule />
      </div>

      <div className='separator my-2'></div>
      <Languages />
      <div className='menu-item px-5'>
        <button onClick={handleAccount} className='btn menu-link px-5 w-100' disabled={button}>
          <span className='menu-text' style={{ fontSize: '13.975px' }}>
            Customer Account
          </span>
        </button>
      </div>

      <Versionmodall />

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account
        </Link>
      </div> */}
      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <ChangePasswordModal />
      </div>
      <div className='menu-item px-5'>
        <a className='menu-link px-5' onClick={handlelogout}>
          Log Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
