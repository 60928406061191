import {Suspense, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {ThemeProvider, createTheme} from '@mui/material/styles'
import {LicenseInfo} from '@mui/x-license-pro'
import {SnackbarProvider} from 'notistack'
import {IconButton} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {WebSocketProvider} from '../_MobixaPlus/pages/ChatService/useChat'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
LicenseInfo.setLicenseKey(
  '83cc85ad90ba95aacb52edeec5e921f0Tz02NzgwOCxFPTE3MTczMzk1OTQ3MDEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "12px", // Burada font boyutunu istediğiniz değere ayarlayın
        },
      },
    },
  },
  /*   palette: {
    primary: {
      main: '#2196f3',

    },
    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2',
      contrastText: '#fff',
    },
    text: {
      secondary: '#a1a5b7',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#fff',
    },
  
    mode:"light"
  }, */
})

const App = () => {
  return (
    
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <WebSocketProvider>
              <SnackbarProvider
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                maxSnack={3}
              >
                <ThemeProvider theme={theme}>
                  <Outlet />
                  <MasterInit />
                  </ThemeProvider>
              </SnackbarProvider>
            </WebSocketProvider>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
  )
}

export {App}
