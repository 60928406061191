import { FC, forwardRef, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { ID, initialListView, KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Dropdown, Modal } from 'react-bootstrap'
import axios from 'axios'
import { spawn } from 'child_process'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Slide,
  SpeedDial,
  SpeedDialAction,
  Stack,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  darken,
  lighten,
  styled,
} from '@mui/material'
import { WizardAddUser } from './companents/WizardModal'
import AddUser from './AddUserEditModal'
import { useNavigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import {
  DataGridPro,
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowParams,
  GridSortItem,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import { SnackbarProvider, TransitionProps, useSnackbar } from 'notistack'
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import CheckIcon from '@mui/icons-material/Check'
import BlockIcon from '@mui/icons-material/Block'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import GroupIcon from '@mui/icons-material/Group'
import GroupRemoveIcon from '@mui/icons-material/GroupRemove'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import SettingsIcon from '@mui/icons-material/Settings'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Creators from '../../ReduxNew/reducers/LoginRedux'



type Props = {
  id?: ID
}

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const UserBreadcrumbs: Array<PageLink> = [
  {
    title: 'Manage',
    // path: '/apps/user-management/users',
    path: '/CustomerDashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const BasicPageToPage: FC<Props> = ({ id }) => {
  /* const classes = useStyles(); */
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const LoginState = useSelector((state: any) => state.Login)
  const customerProfile = useSelector((state: any) => state.Login.customerProfile)
  const userCount = customerProfile?.userCount
  const maxUserCount = customerProfile?.maxUserCount


  const [userRole, setUserRole] = useState<any>([])

  useEffect(() => {
    let role = customerProfile?.role
    setUserRole(role)
    /*     async function test2() {
      axios
        .get(`${process.env.REACT_APP_API_URL}/user/getUserWithJWT`, {
          headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
        })
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setUserRole(response.data.role)
        })
    }
    test2() */
  }, [customerProfile])
  let roleId = [0, 1, 3]
  let roleIsValid = userRole?.some((role: any) => roleId.includes(role.roleId))
  let reviewRole = [1]
  let reviewRoleIsValid = customerProfile?.role?.some((role: any) => reviewRole.includes(role.roleId))

  const intl = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const [userOptions, setUserOptions] = useState<any>({
    sortModel: [
      { field: 'statusId', sort: 'asc' },
      { field: 'createdDate', sort: 'desc' },
    ],
  })
  const [selected, setSelected] = useState<any>([])
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [rowCount, setRowCount] = useState(0)
  const [rows, setRows] = useState<any>([])
  const [loadingExport, setLoadingExport] = useState(false)
  const [userListGroup, setUserListGroup] = useState<any>([])
  const [groupList, setGroupList] = useState<any>([])
  const [groupListSelected, setGroupListSelected] = useState<any>([])
  const [groupListLoading, setGroupListLoading] = useState<boolean>(false)
  const [groupFunctionLoading, setGroupFunctionLoading] = useState<boolean>(false)
  const [userStatus, setUserStatus] = useState(-1)
  const [groupChange, setGroupChange] = useState(-1)
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    InternalEmployeeId: false,
  })
  //adduserEdit modal
  const [openEditUserModal, setOpenEditUserModal] = useState<any>(false)
  const [openAddUserModal, setOpenAddUserModal] = useState<any>(false)
  const [userData, setUserData] = useState([])
  const [selectedUserData, setSelectedUserData] = useState<any>()
  const [hoverButtonAddUser, setHoverButtonAddUser] = useState(false)
  //adduserEdit modal end

  //warn modal
  const [warnModalContent, setWarnModalContent] = useState<any>('')
  const [successMessageWarnModal, setSuccessMessageWarnModal] = useState<any>('')
  useEffect(() => {
    if (userStatus === -1) {
      return
    }
    if (userStatus === 1) {
      //open
      userCount >= maxUserCount ?
        setOpenWarnModalStatus(true) : handleChangeStatus()
    }
    if (userStatus === 0) {
      //block
      setWarnModalContent(
        <Typography color='white'>
          This operation will BLOCK selected users.
          <br /> They will NOT not use Mobixa system at all.
          <br />
          Blocked users will be seen in the historical reports, lists and game results. <br />
          You can unblock the user at any time.
          <br /> For extra security, you must confirm the process.
          <br /> Proceed?
        </Typography>
      )
      const filteredUsers = rows.filter(
        (user: any) => selected.includes(user.id) && user.statusId !== 0
      )
      setSuccessMessageWarnModal(`${filteredUsers.length} record has been successfully BLOCKED`)
      handleClickWarnModalOpen()
    } else if (userStatus === 2) {
      //Delete
      setWarnModalContent(
        <Typography color='white'>
          This operation will DELETE selected users. They will NOT not use
          <br /> Mobixa system at all.
          <br />
          They can not be seen in the reports, lists, results etc.
          <br /> Deletion process can be undone by the MasterAdmin only. <br /> For extra security,
          you must confirm the process.
          <br /> Proceed?
        </Typography>
      )
      const filteredUsers = rows.filter(
        (user: any) => selected.includes(user.id) && user.statusId !== 2
      )
      setSuccessMessageWarnModal(`${filteredUsers.length} record has been successfully DELETED`)
      handleClickWarnModalOpen()
    } else if (userStatus === 3) {
      //Erased
      setWarnModalContent(
        <Typography color='white'>
          This operation will PERMANENTLY DELETE selected users. <br /> They will NOT not use Mobixa
          system at all. <br /> All the personal data will be erased forever. <br /> This process
          can NOT be undone. <br /> For extra security, you must confirm the process. <br />{' '}
          Proceed?
        </Typography>
      )
      const filteredUsers = rows.filter(
        (user: any) => selected.includes(user.id) && user.statusId !== 3
      )
      setSuccessMessageWarnModal(
        `${filteredUsers.length} record has been PERMANENTLY DELETED successfully`
      )
      handleClickWarnModalOpen()
    }
  }, [userStatus])

  const [openWarnModal, setOpenWarnModal] = useState(false)
  const [openWarnModalStatus, setOpenWarnModalStatus] = useState(false)

  const handleClickWarnModalOpen = () => {
    setOpenWarnModal(true)
  }

  const handleClickWarnModalClose = () => {
    setOpenWarnModal(false)
    setUserStatus(-1)
  }

  //warn modal end

  const [isLoadingData, setIsLoadingData] = useState(false)

  const [status] = useState([
    { value: 0, label: 'Blocked' },
    { value: 1, label: 'Open' },
    { value: 2, label: 'Deleted' },
  ])
  const [emailStatus] = useState([
    { value: 0, label: 'Blocked' },
    { value: 1, label: 'Open' },
    { value: 2, label: 'Pending' },
    { value: 3, label: 'Confirmed' },
  ])

  const [recordChannel] = useState([
    { value: 0, label: 'Manuel' },
    { value: 1, label: 'XLS' },
    { value: 2, label: 'API' },
    { value: 3, label: 'Guest' },
  ])

  const [role] = useState([
    { value: 0, label: 'System Admin' },
    { value: 1, label: 'Master Admin' },
    { value: 2, label: 'Content Editor' },
    { value: 3, label: 'Trainer' },
    { value: 4, label: 'Reporter' },
    { value: 5, label: 'IT' },
    { value: 6, label: 'Finance' },
    { value: -1, label: 'ALL ROLES' },
    { value: -2, label: 'NO ROLES' },
  ])

  const AvatarCell = ({ value }: any) => {
    return (
      <TableCell>
        <Avatar src={value} />
      </TableCell>
    )
  }

  const columns: any = [

    {
      field: 'avatar',
      headerName: 'PROFILE',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      width: 70,
      renderCell: AvatarCell,
    },

    { field: 'name', headerName: 'NAME', width: 150 },
    { field: 'surname', headerName: 'SURNAME', width: 150 },
    {
      field: 'email',
      headerName: 'EMAIL',
      width: 200,
      type: 'string',
    },

    {
      field: 'role',
      headerName: 'ROLE',
      width: 200,
      type: 'singleSelect',
      valueOptions: role.map((category: any) => ({
        value: category.value,
        label: category.label,
      })),
      renderCell: (params: any) => params.value,
    },

    {
      field: 'group',
      headerName: 'GROUP',
      width: 200,
      type: 'singleSelect',
      selectable: false,
      valueOptions: groupList.map((category: any) => ({
        value: category.groupId,
        label: category.groupName,
      })),
      renderCell: (params: any) => params.value,
    },
    {
      field: 'userId',
      headerName: 'ID',
      width: 60,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value?.toFixed(0),
    },

    {
      field: 'InternalEmployeeId',
      headerName: 'EMPLOYEE ID',
      width: 120,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value,
    },
    {
      field: 'createdDate',
      headerName: 'JOINED DATE',
      width: 180,
      type: 'dateTime',
      valueGetter: ({ value }: { value: Date }) => value && new Date(value),
    },
    {
      field: 'recordChannel',
      headerName: 'RC. CHANNEL',
      width: 150,
      type: 'singleSelect',
      valueOptions: recordChannel.map((option) => ({
        value: option.value,
        label: option.label,
      })),
    },
    {
      field: 'emailStatus',
      headerName: 'EMAIL STATUS',
      width: 170,
      type: 'singleSelect',
      valueOptions: emailStatus.map((option) => ({
        value: option.value,
        label: option.label,
      })),
    },
    {
      field: 'statusId',
      headerName: 'USER STATUS',
      width: 170,
      type: 'singleSelect',
      valueOptions: status.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      renderCell: (params: GridCellParams) => <StatusCell value={params.value as number} />,
    },
  ]

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
      return
    } */

    setUserOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setUserOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelected(newSelectionModel)
  }

  const handleCellOneClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin

    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }
    const newSelectionModel = [...selected]; // Mevcut seçili satırların bir kopyasını oluşturun
    const selectionIndex = newSelectionModel.indexOf(params.id);

    if (selectionIndex === -1) {
      // Eğer tıklanan hücrenin ID'si şu anda seçili değilse, seçime ekleyin
      newSelectionModel.push(params.id);
    } else {
      // Eğer tıklanan hücrenin ID'si zaten seçiliyse, seçimden kaldırın
      newSelectionModel.splice(selectionIndex, 1);
    }

    setSelected(newSelectionModel);

  }

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin

    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }

    /* setOpenEditUserModal(true) */
    /*  userData.filter((user: any) => user.userId === params.row.userId)
    setSelectedUserData(userData.filter((user: any) => user.userId === params.row.userId)) */
    navigate(`/manage/users/detail?id=${params.id}`)
    // Diğer işlemleri burada yapabilirsiniz
  }

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ ml: 1, mr: 1 }}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <LoadingButton
          size='small'
          onClick={downloadExcel}
          endIcon={<FileDownloadIcon />}
          loading={loadingExport}
          loadingPosition='end'
          variant='outlined'
        >
          <span>Export</span>
        </LoadingButton>
      </GridToolbarContainer>
    )
  }
  async function downloadExcel() {
    setLoadingExport(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/getUserList?isExcel=1`,
        {
          userOptions,
          columnVisibilityModel,
        },
        {
          responseType: 'blob',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const excelBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const excelUrl = URL.createObjectURL(excelBlob)

        const link = document.createElement('a')
        link.href = excelUrl
        link.download = 'userList.xlsx'
        link.click()
        URL.revokeObjectURL(excelUrl)
        setLoadingExport(false)
      })
      .catch((err) => {
        setLoadingExport(false)
      })
    //frontend excel download
    /*     const newData = rows.map((row: any) => {
      delete row.tableData
      return row
    })
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, 'row')
    //Buffer
    XLSX.write(workBook, {bookType: 'xlsx', type: 'buffer'})
    //Binary string
    XLSX.write(workBook, {bookType: 'xlsx', type: 'binary'})
    //Download
    XLSX.writeFile(workBook, 'Veri Listesi.xlsx') */
  }

  const [isCheckAll, setIsCheckAll] = useState(false)

  const [first, setfirst] = useState(true)

  const StatusCell = ({ value }: { value: number }) => {
    const getStatusComponent = (status: number) => {
      if (status === 0) {
        {
          /* <span className='badge badge-light-secondary fw-bold fs-3'>Blocked</span> */
        }
        return (
          <Chip
            className='overflow-visible'
            label='Blocked'
            variant='outlined'
            size='small'
            color='secondary'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 1) {
        /*   <span className='badge badge-light-success fw-bold fs-3'>Open</span> */
        return (
          <Chip
            className='overflow-visible'
            label='Open'
            variant='outlined'
            size='small'
            color='success'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 2) {
        {
          /* <span className='badge badge-light-warning fw-bold fs-3'>Deleted</span> */
        }
        return (
          <Chip
            className='overflow-visible'
            label='Deleted'
            variant='outlined'
            size='small'
            color='error'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      return null
    }

    return <div style={{ height: '19px', width: '57px' }}>{getStatusComponent(value)}</div>
  }

  useEffect(() => {
    function test() {
      setGroupListLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/user/getGroupListNoPagination`, {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        })
        .then((response) => {
          setGroupList(response.data)
          setGroupListLoading(false)
        })
        .catch((err) => {
          setGroupListLoading(false)
        })
    }
    test()
  }, [first])

  useEffect(() => {
    getUserList()
  }, [userOptions, paginationModel, columnVisibilityModel, first])
  async function getUserList() {
    setIsLoadingData(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/getUserList?page=${paginationModel.page + 1}&size=${paginationModel.pageSize
        }`,
        {
          userOptions,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        const formattedRows = response.data.items?.map((row: any) => ({
          id: row.userId,
          avatar: row.avatarPath,
          name: row.name,
          surname: row.surname,
          email: row.email,
          userId: row.userId,
          InternalEmployeeId: row.InternalEmployeeId,
          role: row.role.map((item: any) => item.roleName).join(', '),
          createdDate: row.createdDate,
          group: row.group.map((item: any) => item.groupName).join(', '),
          statusId: row.statusId,
          recordChannel: row.recordChannel,
          emailStatus: row.emailStatus,
        }))
        setUserData(response.data.items)
        setRows(formattedRows)
        setRowCount(response.data.total)
        setIsLoadingData(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
      })
  }

  const [showStatu, setShowStatu] = useState(false)

  const handleClose = () => setShowStatu(false)
  const [newStatusId, setnewStatusId] = useState<any>('')
  const [newStatusName, setnewStatusName] = useState<any>('')

  const [statusLoading, setStatusLoading] = useState(false)

  function getuserWidthJwt() {
    dispatch(Creators.getUserReduxRequest())
  }
  async function handleChangeStatus() {
    if (isCheckAll === false) {
      setStatusLoading(true)
      await axios.post(
        `${process.env.REACT_APP_API_URL}/user/changeUserStatus`,
        {
          userId: selected,
          userStatus: userStatus,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      ).then((response) => {
        // expected the setProducts to be filled with the return of this request
        if (userStatus === 1) {
          const filteredUsers = rows.filter(
            (user: any) => selected.includes(user.id) && user.statusId !== 1
          )

          enqueueSnackbar(
            `The status of ${filteredUsers.length} record has been changed to OPEN successfully`,
            { variant: 'success' }
          )
          setUserStatus(-1)
        }
        userStatus !== 1 && enqueueSnackbar(successMessageWarnModal, { variant: 'success' })
        setSelected([])
        handleClickWarnModalClose()
        handleClose()
        setUserStatus(-1)
        setStatusLoading(false)
        setfirst(!first)
        setSuccessMessageWarnModal('')
        getuserWidthJwt()
      }).catch((err) => {
        setStatusLoading(false)
        if (err.response?.data?.detail.errorMessage === "ROLE NO PERMISSION") {
          enqueueSnackbar('Permission denied!', { variant: 'error' })
        } else {
          err.response?.data?.detail.errorMessage
            ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
            : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              { variant: 'error' }
            )
        }
      })
    }
  }

  //speed dial start
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const [speedDialGroupOpen, setSpeedDialGroupOpen] = useState(false)
  const handleCloseSpeedDialGroup = () => {
    setSpeedDialGroupOpen(false)
  }
  const handleOpenSpeedDialGroup = () => {
    setSpeedDialGroupOpen(true)
  }
  const handleCloseSpeedDial = () => {
    setSpeedDialOpen(false)
  }
  const handleOpenSpeedDial = () => {
    setSpeedDialOpen(true)
  }

  const actions = [
    { icon: <CheckIcon style={{ color: 'black' }} />, name: 'Open', value: 1 },
    { icon: <BlockIcon style={{ color: 'black' }} />, name: 'Block', value: 0 },
    { icon: <DeleteIcon style={{ color: 'black' }} />, name: 'Delete', value: 2 },
    { icon: <DeleteForeverOutlinedIcon style={{ color: 'black' }} />, name: 'Erased', value: 3 },
  ]
  const actionsGroup = [
    { icon: <GroupAddIcon style={{ color: 'black' }} />, name: 'GroupAdd', value: 0 },
    { icon: <GroupRemoveIcon style={{ color: 'black' }} />, name: 'GroupRemove', value: 1 },
  ]
  //speed dial end

  //group change start
  function handleGroupChangeClose() {
    setGroupChange(-1)
  }
  function handleGroupAdd() {
    setGroupFunctionLoading(true)
    const selectedGroupIds = groupListSelected.map((option: any) => option.value)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/addUserGroup`,
        {
          userList: selected,
          groupList: selectedGroupIds,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        /* setUserListGroup(response.data) */
        setSelected([])
        enqueueSnackbar('Successfully Saved', { variant: 'success' })
        setfirst(!first)
        handleGroupChangeClose()
        setGroupFunctionLoading(false)
      })
      .catch((err) => {
        setGroupFunctionLoading(false)
        err.response.data.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  function handleGroupRemove() {
    setGroupFunctionLoading(true)
    const selectedGroupIds = groupListSelected.map((option: any) => option.value)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/deleteUserGroup`,
        {
          userList: selected,
          groupList: selectedGroupIds,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        setfirst(!first)
        setSelected([])
        handleGroupChangeClose()
        setGroupFunctionLoading(false)
        enqueueSnackbar('Successfully Saved', { variant: 'success' })
      })
      .catch((err) => {
        setGroupFunctionLoading(false)
        err.response.data.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  function handleGroupListChange(event: any, newValue: any) {
    setGroupListSelected(newValue)
  }

  useEffect(() => {
    setUserListGroup([])
    if (groupChange === 1) {
      setGroupListLoading(true)
      axios
        .post(`${process.env.REACT_APP_API_URL}/user/getGroupListForUserIds`, selected, {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        })
        .then((response) => {
          setGroupListLoading(false)
          setUserListGroup(response.data)
        })
        .catch((err) => {
          setGroupListLoading(false)
        })
    }
  }, [groupChange, selected])
  //group change end
  const [hoverButton, setHoverButton] = useState(false)


  return (
    <>
      {/* <PageTitle breadcrumbs={UserBreadcrumbs}>{intl.formatMessage({id: 'USER.LIST'})}</PageTitle> */}
      <div
        style={{
          display: 'flex',
          height: 50,
          width: '80%',
          /* marginBottom: '10px', */
          marginTop: '-20px',
          cursor: 'default',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
          Manage - {intl.formatMessage({ id: 'USER.LIST' })}
        </Typography>
      </div>
      {
        //user status modal
      }
      <Modal
        show={showStatu}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {intl.formatMessage({ id: 'Modal.Warning' })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {intl.formatMessage({ id: 'Modal.Changing' })} {/* selectedLenght */}{' '}
          {intl.formatMessage({ id: 'Modal.Warning.Question.1' })}{' '}
          <span className='text-danger'>{newStatusName?.toLowerCase()}</span>.{' '}
          {intl.formatMessage({ id: 'Modal.Warning.Question.2' })}
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={handleChangeStatus}>
            {intl.formatMessage({ id: 'Yes' })}
          </button>
        </Modal.Footer>
      </Modal>

      {selected.length > 0 ? (
        <div className='border-0'>
          {/* begin::Card toolbar */}
          <div className='w-50 float-end'>
            <div style={{ marginTop: '-50px', marginRight: 80 }} className='d-flex justify-content-end '>
              {groupChange === -1 && selected.length === 1 && roleIsValid && (
                <Box
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                  alignContent='center'
                  sx={{ height: 50, marginTop: '-21px' }}
                >
                  <Tooltip
                    arrow
                    title='Edit'
                    placement='top'
                    onMouseEnter={() => {
                      setHoverButton(true)
                    }}
                    onMouseLeave={() => {
                      setHoverButton(false)
                    }}
                    style={{ background: hoverButton ? '#D5D5D5 ' : 'white' }}
                  >
                    <IconButton
                      aria-label='edit'
                      onClick={() => {
                        /*   setshowEdit(true)
                      setselectedQuestion(selectQuestion) */
                        navigate(`/manage/users/detail?id=${selected[0]}`)
                      }}
                      size='small'
                      sx={{ background: 'white' /* , marginRight: '7px' */ }}
                    >
                      <EditIcon sx={{ fontSize: '30px', color: hoverButton ? 'black' : '' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              <Box
                display='flex'
                justifyContent='flex-end'
                alignItems='center'
                alignContent='center'
                sx={{ height: 50, marginTop: '-17px', marginRight: '10px' }}
              >
                {groupChange === 0 && (
                  <Paper
                    variant='outlined'
                    elevation={2}
                    style={{ padding: '10px', borderRadius: '10px', display: 'flex' }}
                  >
                    <Autocomplete
                      multiple
                      fullWidth
                      size='small'
                      limitTags={2}
                      id='multiple-limit-tags'
                      value={groupListSelected}
                      loading={groupListLoading}
                      onChange={handleGroupListChange}
                      options={groupList.map((category: any) => ({
                        label: category.groupName,
                        value: category.groupId,
                      }))}
                      getOptionLabel={(option: any) => option.label}
                      style={{ minWidth: 150, marginRight: '10px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color='success'
                          label='Group Add'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {groupListLoading ? (
                                  <CircularProgress color='primary' size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                    <IconButton
                      color='error'
                      aria-label='delete'
                      onClick={() => handleGroupChangeClose()}
                    >
                      <CloseIcon />
                    </IconButton>
                    <IconButton
                      color='success'
                      aria-label='delete'
                      disabled={
                        groupFunctionLoading || groupListLoading || groupListSelected.length < 1
                      }
                      onClick={handleGroupAdd}
                    >
                      <DoneIcon />
                    </IconButton>
                  </Paper>
                )}
                {groupChange === 1 && (
                  <Paper
                    variant='outlined'
                    elevation={2}
                    style={{ padding: '10px', borderRadius: '10px', display: 'flex' }}
                  >
                    <Autocomplete
                      multiple
                      fullWidth
                      size='small'
                      limitTags={2}
                      id='combo-box-demo'
                      value={groupListSelected}
                      loading={groupListLoading}
                      onChange={handleGroupListChange}
                      options={userListGroup.map((category: any) => ({
                        label: category.groupName,
                        value: category.groupId,
                      }))}
                      getOptionLabel={(option: any) => option.label}
                      style={{ minWidth: 150, marginRight: '10px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color='error'
                          label='Group Remove'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {groupListLoading ? (
                                  <CircularProgress color='primary' size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                    <IconButton
                      color='error'
                      aria-label='delete'
                      onClick={() => handleGroupChangeClose()}
                    >
                      <CloseIcon />
                    </IconButton>
                    <IconButton
                      color='primary'
                      aria-label='delete'
                      disabled={
                        groupFunctionLoading || groupListLoading || groupListSelected.length < 1
                      }
                      onClick={handleGroupRemove}
                    >
                      <DoneIcon />
                    </IconButton>
                  </Paper>
                )}
              </Box>
              {groupChange === -1 && (
                <Box
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                  alignContent='center'
                  sx={{ height: 50 }}
                >
                  <Tooltip arrow title='Change Group' placement='top'>
                    <SpeedDial
                      ariaLabel='SpeedDial openIcon example'
                      style={{ transform: 'scale(0.73)', marginTop: '53px', zIndex: '4' }}
                      icon={
                        speedDialGroupOpen ? (
                          <ExpandMoreIcon fontSize='large' />
                        ) : (
                          <GroupIcon fontSize='large' />
                        )
                      }
                      onOpen={handleOpenSpeedDialGroup}
                      onClose={handleCloseSpeedDialGroup}
                      FabProps={{ color: 'inherit' }}
                      open={speedDialGroupOpen}
                      direction='down'
                    >
                      {actionsGroup.map((action) => (
                        <SpeedDialAction
                          delay={1000}
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={action.name}
                          onClick={() => (setGroupChange(action.value), setGroupListSelected([]))}
                        />
                      ))}
                    </SpeedDial>
                  </Tooltip>
                </Box>
              )}

              <Box
                display='flex'
                justifyContent='flex-end'
                alignItems='center'
                alignContent='center'
                sx={{ height: 50 }}
              >
                <Tooltip arrow title='Change Status' placement='top'>
                  <SpeedDial
                    ariaLabel='SpeedDial openIcon example'
                    style={{ transform: 'scale(0.73)', marginTop: '133px' }}
                    icon={
                      speedDialOpen ? (
                        <ExpandMoreIcon fontSize='large' />
                      ) : (
                        <SettingsIcon fontSize='large' />
                      )
                    }
                    openIcon={<DeleteIcon fontSize='large' />}
                    onOpen={handleOpenSpeedDial}
                    onClose={handleCloseSpeedDial}
                    FabProps={{ color: 'inherit' }}
                    open={speedDialOpen}
                    direction='down'
                  >
                    {actions.map((action) => (
                      <SpeedDialAction
                        /*  style={{transform: 'scale(0.70)'}} */
                        delay={1000}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => setUserStatus(action.value)}
                      />
                    ))}
                  </SpeedDial>
                </Tooltip>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div className='border-0'>
          {/* begin::Card toolbar */}
          <div className='w-50 float-end'>
            <div
              style={{ marginTop: '-70px', marginRight: 80 }}
              className='d-flex justify-content-end'
              data-kt-user-table-toolbar='base'
            >
              <Box
                display='flex'
                justifyContent='flex-end'
                alignItems='center'
                alignContent='center'
                sx={{ height: 50 }}
              >
                {roleIsValid && (
                  <Tooltip
                    arrow
                    title='Add New'
                    placement='top'
                    onMouseEnter={() => {
                      setHoverButtonAddUser(true)
                    }}
                    onMouseLeave={() => {
                      setHoverButtonAddUser(false)
                    }}
                    style={{ background: hoverButtonAddUser ? '#D5D5D5 ' : 'white' }}
                  >
                    <IconButton
                      aria-label='delete'
                      onClick={() => userCount >= maxUserCount ?
                        setOpenWarnModalStatus(true) : setOpenAddUserModal(true)}
                      size='small'
                      sx={{ background: 'white', marginRight: '7px' }}
                    >
                      <AddIcon sx={{ fontSize: '30px', color: hoverButtonAddUser ? 'black' : ' ' }} />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <AddUser
                openAddUserModal={openAddUserModal}
                setOpenAddUserModal={setOpenAddUserModal}
                openEditUserModal={openEditUserModal}
                setOpenEditUserModal={setOpenEditUserModal}
                userRole={userRole}
                setfirst={setfirst}
                first={first}
                selectedUserData={selectedUserData}
              />
              {roleIsValid && (
                <WizardAddUser
                  setfirst={setfirst}
                  first={first}
                  groupList={groupList}
                  groupListLoading={groupListLoading}
                  userCount={userCount}
                  maxUserCount={maxUserCount}
                  setOpenWarnModalStatus={setOpenWarnModalStatus}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className={`card p-3`}>
        {
          //DataGrid start
        }
        <Box sx={{ height: 600, width: '100%' }}>
          <StyledDataGrid
            rows={rows || []}
            columns={columns || []}
            rowHeight={50}
            columnHeaderHeight={40}
            disableVirtualization
            checkboxSelection
            keepNonExistentRowsSelected
            onCellDoubleClick={handleCellClick}
            onCellClick={handleCellOneClick}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
            sortingMode='server'
            onSortModelChange={handleSortModelChange}
            sortModel={userOptions?.sortModel}
            onRowSelectionModelChange={handleSelectionModelChange}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            filterMode='server'
            onFilterModelChange={onFilterChange}
            //  isRowSelectable={(params: GridRowParams) => params.row.source === 'Client'}
            rowCount={rowCount}
            loading={isLoadingData}
            paginationModel={paginationModel}
            paginationMode='server'
            rowSelectionModel={selected}
            pagination={true}
            onPaginationModelChange={setPaginationModel}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
            }
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
            sx={{
              boxShadow: "none",
              border: "none",
              '& .MuiDataGrid-footerContainer': {
                padding: '0px',
                height: 30
              },

              '& .MuiDataGrid-cell:hover': {
                /*    color: 'primary.main', */
                cursor: 'pointer',
              },
              '&	.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                color: '#A1A5B7',
                fontSize: 12,
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
              },
            }}
          />
        </Box>
        {
          //dataGrid end
        }
      </div>

      {/* begin::Row */}

      {/* <div className='row gy-5 g-xl-12'>
      <VersionModal />
      <div className='col-xl-7'>
        <GameDashboardWidget
          className=' mb-xl-8'
          chartColor='primary'
          chartHeight='200px'
          strokeColor='#ffffff'
        />
      </div>
      <div className='col-xl-5 mt-xl-9'>
        <PopularGamesWidget className='mb-xl-8' />
      </div>
    </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <NewGamesWidget className='' /> */}

      {/* end::Row */}
      {
        //warn modal
      }
      <Dialog
        open={openWarnModal}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {warnModalContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleChangeStatus()
            }}
            style={{ color: 'white' }}
            disabled={statusLoading}
          >
            Yes
          </Button>
          <Button onClick={handleClickWarnModalClose} style={{ color: 'white' }}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openWarnModalStatus}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description' color={"white"}>
            You have reached the limit of users on your plan.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => (setOpenWarnModalStatus(false), setUserStatus(-1), reviewRoleIsValid && navigate(`/administration/customers/profile?id=${customerProfile.customerId}`))} style={{ color: 'white' }}>
            REVIEW YOUR PLAN
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default function Snackbar(props: any) {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} maxSnack={3}>
      <BasicPageToPage {...props} />
    </SnackbarProvider>
  )
}
