import React, {FC, useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {useDispatch, useSelector} from 'react-redux'
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar'
import {ProgressBar} from 'react-bootstrap'
import axios from 'axios'
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'

type Props = {
  show: boolean
  setShow: (isUserLoading: boolean) => void
  journeyId: any
}

const LeaderBoard: FC<Props> = ({show, setShow, journeyId}) => {
  const handleClose = () => setShow(false)
  const dispatch = useDispatch()
  const LoginState = useSelector((state: any) => state.Login)
  const [Loading, setLoading] = useState(false)
  const [leaderBoardList, setLeaderBoardList] = useState<any>({
    completedRatio: '',
    journeyName: '',
    showingUserCount: 0,
    totalUserCount: 0,
    progressRatioAvg: 0,
    successRateAvg: 0,
    userList: [],
  })

  async function handleGetLeaderBoard() {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Game/getLeaderBoard/${journeyId}`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then(async (response) => {
        // expected the setProducts to be filled with the return of this request
        setLeaderBoardList(response.data)
        setLoading(false)
      })
      .catch((err) => {})
  }
  useEffect(() => {
    if (show) {
      handleGetLeaderBoard()
    }
  }, [show])
  
 /*  console.log('leaderBoard: ', leaderBoardList) */
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className='row w-100' style={{display:"flex",alignItems:"center"}}>
              <Avatar variant='rounded' src='/media/journey/pod.png' sx={{height:"35px",width:"auto" ,objectFit:"contain"}} />
            <div className='col-10 text-center'>
              <div className='text center w-100 h1'>{leaderBoardList?.journeyName}</div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {Loading ? (
            <CircularProgress />
          ) : (
            <Grid container>
              <Grid xs={7} display={'flex'} justifyContent={'center'}>
                <Box textAlign={'center'}>
                  <Typography fontSize={"14px"} color='#6E6F6F'>
                    Completed
                  </Typography>
                  <Typography
                   fontSize={"14px"}
                    color='#6E6F6F'
                  >{`${leaderBoardList?.completedRatio}`}</Typography>
                </Box>
              </Grid>
              <Grid xs={3} display={'flex'} justifyContent={'center'} direction={'column'}>
                <Box textAlign={'center'}>
                  <Typography fontSize={"14px"} color='#6E6F6F'>
                    Progress
                  </Typography>
                  <Typography
                    fontSize={"14px"}
                    color='#6E6F6F'
                  >{`${leaderBoardList?.progressRatioAvg}%`}</Typography>
                </Box>
              </Grid>
              <Grid xs={2} display={'flex'} justifyContent={'center'} direction={'column'}>
                <Box textAlign={'center'}>
                  <Typography fontSize={"14px"} color='#6E6F6F'>
                    Success
                  </Typography>
                  <Typography
                    fontSize={"14px"}
                    color='#6E6F6F'
                  >{`${leaderBoardList?.successRateAvg}%`}</Typography>
                </Box>
              </Grid>
              <Box padding={1} height={500} width={'100%'} overflow={'auto'}>
                {
                  //map olacak
                  leaderBoardList?.userList?.map((leader: any, index: number) => {
                    return (
                      <Box
                        style={{
                          width: '100%',
                          display: 'flex',
                          marginTop: index !== 0 ? '5px' : '',
                          paddingLeft: '5px',
                          borderBottom:"1px solid #6E6F6F",
                          paddingBottom:"5px"
                        }}
                      >
                        <Grid
                          xs={7}
                          display={'flex'}
                          justifyContent={'center'}
                          direction={'column'}
                        >
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography style={{marginRight: '10px', fontSize: '14px'}}>{`${
                              index + 1
                            }`}</Typography>
                            <Avatar
                              src={leader.avatarPath}
                              style={{border: leader?.topUser ? '4px solid #4caf50' : ''}}
                            />
                            <Tooltip arrow placement='top' title={leader?.userGroups === "" ? "No Group" : leader?.userGroups}>
                              <Typography
                                fontWeight={leader?.topUser ? 'bold' : ''}
                                style={{marginLeft: '10px', fontSize: '16px'}}
                              >
                                {leader.nameSurname}
                              </Typography>
                            </Tooltip>
                          </Box>
                        </Grid>
                        <Grid
                          xs={3}
                          display={'flex'}
                          justifyContent={'center'}
                          direction={'column'}
                        >
                          <Box display={'flex'} alignItems={'center'} justifyContent={'start'}>
                            <Typography
                              fontWeight={leader?.topUser ? 'bold' : ''}
                              style={{
                                color: leader.progressRatio === 100 ? '#4caf50' : '#2196f3',
                                fontSize: '13px',
                              }}
                            >{`${leader.progressRatio} %`}</Typography>
                          </Box>
                          <LinearProgress
                            style={{height: '5px', borderRadius: '5px'}}
                            color={leader.progressRatio === 100 ? 'success' : 'primary'}
                            variant='determinate'
                            value={leader.progressRatioAvg}
                          />
                        </Grid>
                        <Grid
                          xs={2}
                          display={'flex'}
                          justifyContent={'center'}
                          direction={'column'}
                        >
                          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Typography
                              fontWeight={leader?.topUser ? 'bold' : ''}
                              fontSize={'16px'}
                              style={{
                                color: leader.progressRatio === 100 ? '#4caf50' : '#2196f3',
                                display: 'flex',
                                alignItems: 'end',
                              }}
                            >
                              {leader.successRate === '100'
                                ? `${parseFloat(leader.successRate).toFixed(0)}`
                                : `${parseFloat(leader.successRate).toFixed(0)}.`}
                              <Typography
                                fontWeight={leader?.topUser ? 'bold' : ''}
                                fontSize={'13px'}
                                style={{
                                  color: leader.progressRatio === 100 ? '#4caf50' : '#2196f3',
                                  marginLeft: leader.successRate === 100 ? '5px' : '',
                                }}
                              >
                                {leader.successRate === '100'
                                  ? ` %`
                                  : `${parseFloat(leader.successRate).toFixed(1).split('.')[1]} %`}
                              </Typography>
                            </Typography>
                          </Box>
                        </Grid>
                      </Box>
                    )
                  })
                }
              </Box>
              <Typography variant='subtitle1' color='#6E6F6F'>
                {`Showing ${leaderBoardList?.showingUserCount} of ${leaderBoardList?.totalUserCount} records`}
              </Typography>
            </Grid>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LeaderBoard
