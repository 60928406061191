import Axios from 'axios'
import React, {FC, useEffect, useState} from 'react'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import PhoneInput from 'react-phone-input-2'
import { useSelector } from 'react-redux'
import { Divider } from '@mui/material'
import {SnackbarProvider, useSnackbar} from 'notistack'

type Props = {
    Contacts: any
    customerid:any
    setfirst:any
    first:any

  }
  
 const MainContacts: FC<Props> = ({Contacts,customerid,setfirst,first}) => {
  const {enqueueSnackbar} = useSnackbar()

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [value, setValue] = useState<any>('')

  const [salephone, setSalePhone] = useState<any>('')
  const [prophone, setProPhone] = useState<any>('')
  const [accphone, setAccPhone] = useState<any>('')
  
  const [saleName, setSaleName] = useState<any>(``)
  const [proName, setProName] = useState<any>(``)
  const [accName, setAccName] = useState<any>(``)


  

  const isValidEmail = (email: any) => {
    return /\S+@\S+\.\S+/.test(email)
  }
  const [errorSal, setErrorSal] = useState<any>('')
  const [errorPro, setErrorPro] = useState<any>('')
  const [errorAcc, setErrorAcc] = useState<any>('')
  const [messageSal, setmessageSal] = useState(``)
  const [messagePro, setmessagePro] = useState(``)
  const [messageAcc, setmessageAcc] = useState(``)

  useEffect(() => {
    if(Contacts[0]?.contactName!==undefined||null){
      setSaleName(`${Contacts[0]?.contactName} `)
    }

    if(Contacts[1]?.contactName!==undefined||null){
      setProName(`${Contacts[1]?.contactName} `)
    }

    if(Contacts[2]?.contactName!==undefined||null){
      setAccName(`${Contacts[2]?.contactName} `)
    }

    if(Contacts[0]?.contactEmail!==undefined||null){
      setmessageSal(`${Contacts[0]?.contactEmail}`)
    }

    if(Contacts[1]?.contactEmail!==undefined||null){
      setmessagePro(`${Contacts[1]?.contactEmail}`)
    }
    if(Contacts[2]?.contactEmail!==undefined||null){
      setmessageAcc(`${Contacts[2]?.contactEmail}`)
    }

    if(Contacts[0]?.contactPhone!==undefined||null){
      setSalePhone(`${Contacts[0]?.contactPhone}`)
    }

    if(Contacts[1]?.contactPhone!==undefined||null){
      setProPhone(`${Contacts[1]?.contactPhone}`)
    }

    if(Contacts[2]?.contactPhone!==undefined||null){
      setAccPhone(`${Contacts[2]?.contactPhone}`)
    }

    

  }, [Contacts])
  const HandleMailSal = (event: any) => {
    if (!isValidEmail(event.target.value)) {
        setErrorSal('Email is invalid')
    } else {
        setErrorSal(undefined)
    }

    setmessageSal(event.target.value)
  }
  const HandleMailPro = (event: any) => {
    if (!isValidEmail(event.target.value)) {
        setErrorPro('Email is invalid')
    } else {
        setErrorPro(undefined)
    }

    setmessagePro(event.target.value)
  }
  const HandleMailAcc = (event: any) => {
    if (!isValidEmail(event.target.value)) {
        setErrorAcc('Email is invalid')
    } else {
        setErrorAcc(undefined)
    }

    setmessageAcc(event.target.value)
  }
  const LoginState = useSelector((state: any) => state.Login)



    
  // const [sName, setSName] = useState<any>('')
  // const [pName, setPName] = useState<any>('')
  // const [aName, setAName] = useState<any>('')
    
  // const [sSunName, setsSunName] = useState<any>('')
  // const [pSunName, setpSunName] = useState<any>('')
  // const [aSunName, setaSunName] = useState<any>('')

  // const [s, sets] = useState<any>('')
  // const [p, setp] = useState<any>('')
  // const [a, seta] = useState<any>('')

//   useEffect(() => {
//     sets(saleName?.target?.value?.split(' '))
//     setSName(s?.slice(-1)[0])
//     setsSunName(s?.slice(0, -1).toString())
//     setp(proName?.target?.value?.split(' '))
//     setPName(p?.slice(0, -1).toString())
//     setpSunName(p?.slice(-1)[0])
//     seta(accName?.target?.value?.split(' '))
//     setAName(a?.slice(-1)[0])
//     setaSunName(a?.slice(0, -1).toString())
// }, [saleName,proName,accName])

// var data = [
//   {
//       "contactRole": "Sales",
//       "name": saleName,
//       "surname": " ",
//       "email": messageSal,
//       "phone": salephone
//   },
//   {   
//       "contactRole": "Procurement",
//       "name": proName,
//       "surname": " ",
//       "email": messagePro,
//       "phone": prophone
//   },
//   {   
//       "contactRole": "Accounting",
//       "name": accName,
//       "surname": " ",
//       "email": messageAcc,
//       "phone": accphone

//   }
// ]
// 
const [isSuccess, setIsSuccess] = useState(false)

function handleDone () {
  setIsSuccess(false)
  setfirst(!first)
  handleClose();
}

  const handleSave = () => {

    
  Axios.post(
    `${process.env.REACT_APP_API_URL}/customer/editCustomer/contacts/${customerid}`,
    [
        {
            "contactRole": "Sales",
            "name": saleName,
            "surname": " ",
            "email": messageSal,
            "phone": salephone
        },
        {   
            "contactRole": "Procurement",
            "name": proName,
            "surname": " ",
            "email": messagePro,
            "phone": prophone
        },
        {   
            "contactRole": "Accounting",
            "name": accName,
            "surname": " ",
            "email": messageAcc,
            "phone": accphone
    
        }
    ],{
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${LoginState.loginData.access_token}`,
                'Content-Type': 'application/json'
            }
        }
  ).then(function (response) {
    //handle success
    enqueueSnackbar('Successfully saved', {variant: 'success'})
    setIsSuccess(true)
  }).catch((err) => {
    enqueueSnackbar('Saved Error', {variant: 'error'})
  })
  }
  return (
    <>
      <Button size='large' variant='contained' onClick={handleShow}>
        Edit
      </Button>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header>
        <div className='w-100 d-flex justify-content-between'>
            <div className='h2 my-auto'>Edit Contact Information</div>
            <a onClick={handleClose} className='btn btn-icon btn-secondary rounded-circle btn-sm'>
              <i className='fa-solid fa-x'></i>{' '}
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'></th>
                <th scope='col'>
                  <span className='h4'>Sales</span>
                </th>
                <th scope='col'>
                  <span className='h4'>Procurement</span>
                </th>
                <th scope='col'>
                  <span className='h4'>Accounting</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope='row'>
                  <span className='text-muted h4'>Name</span>
                </th>
                <td>
                
                  <input
                    type='text'
                    defaultValue={saleName}
                    onChange={(e) => setSaleName(e.target.value)}
                    className='form-control  form-control-solid me-5  '
                  />
                </td>
                <td>
                  <input
                    type='text'
                    defaultValue={proName}
                    onChange={(e) => setProName(e.target.value)}
                    className='form-control  form-control-solid me-5  '
                  />
                </td>
                <td>
                  <input
                    type='text'
                    defaultValue={accName}
                    onChange={(e) => setAccName(e.target.value)}
                    className='form-control  form-control-solid me-5  '
                  />
                </td>
              </tr>
              <tr>
                <th scope='row'>
                  <span className='text-muted h4'>Phone</span>
                </th>
                <td>
                  {' '}
                  <PhoneInput
                    value={Contacts[0]?.contactPhone}
                    specialLabel=''
                    buttonStyle={{backgroundColor: 'white'}}
                    dropdownStyle={{color: 'black'}}
                    inputStyle={{
                      color: '#5E6278',
                      width: '100%',
                      border: 'none',
                      backgroundColor: '#f5f8fa',
                    }}
                    country={'tr'}
                    enableSearch={true}
                    onChange={(phone) => setSalePhone(phone)}
                  />
                </td>
                <td>
                  {' '}
                  <PhoneInput
                    value={Contacts[1]?.contactPhone}
                    specialLabel=''
                    buttonStyle={{backgroundColor: 'white'}}
                    dropdownStyle={{color: 'black'}}
                    inputStyle={{
                      color: '#5E6278',
                      width: '100%',
                      border: 'none',
                      backgroundColor: '#f5f8fa',
                    }}
                    country={'tr'}
                    enableSearch={true}
                    onChange={(phone) => setProPhone(phone)}
                  />
                </td>
                <td>
                  {' '}
                  <PhoneInput
                    value={Contacts[2]?.contactPhone}
                    specialLabel=''
                    buttonStyle={{backgroundColor: 'white'}}
                    dropdownStyle={{color: 'black'}}
                    inputStyle={{
                      color: '#5E6278',
                      width: '100%',
                      border: 'none',
                      backgroundColor: '#f5f8fa',
                    }}
                    country={'tr'}
                    enableSearch={true}
                    onChange={(phone) => setAccPhone(phone)}
                  />
                </td>
              </tr>
              <tr>
                <th scope='row'>
                  <span className='text-muted h4'>Email</span>
                </th>
                <td>
                  {' '}
                  <input
                    type='text'
                    value={messageSal}
                    onChange={(e) => HandleMailSal(e)}
                    className={
                      errorSal
                        ? 'form-control  me-5  form-control-solid is-invalid'
                        : 'form-control  form-control-solid me-5  '
                    }
                  />
                </td>
                <td>
                  {' '}
                  <input
                    type='text'
                    value={messagePro}
                    onChange={(e) => HandleMailPro(e)}
                    className={
                      errorPro
                        ? 'form-control  me-5  form-control-solid is-invalid'
                        : 'form-control  form-control-solid me-5  '
                    }
                  />
                </td>
                <td>
                  <input
                    type='text'
                    value={messageAcc}
                    onChange={(e) => HandleMailAcc(e)}
                    className={
                      errorAcc
                        ? 'form-control  me-5  form-control-solid is-invalid'
                        : 'form-control  form-control-solid me-5  '
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Divider variant='middle'  sx={{borderTop: '2px solid'}}/>
        <Modal.Footer>
          {
            isSuccess === false &&
            <Button size='small' color='inherit' variant='contained' style={{marginRight: '20px'}}  onClick={handleClose}>
            Discard
          </Button>
          }
          <Button size='small' variant='contained' style={{marginRight: '40px'}}  onClick={() => isSuccess === true ? handleDone() : handleSave()} disabled={
            saleName.length<2&&(messageSal.length>2||salephone.length>2)||
            proName.length<2&&(messagePro.length>2||prophone.length>2)|| 
            accName.length<2&&(messageAcc.length>2||accphone.length>2)}>
            {isSuccess === true ? "Done" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default function Snackbar({Contacts,customerid,setfirst,first}: Props) {
  return (
    <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} maxSnack={3}>
      <MainContacts {...{Contacts,customerid,setfirst,first}} />
    </SnackbarProvider>
  )
}
