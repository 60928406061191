import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Typography,
  darken,
  lighten,
  styled,
} from '@mui/material'
import {
  DataGridPro,
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import CloseIcon from '@mui/icons-material/Close'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import ReactPlayer from 'react-player'
import { Emoji, EmojiStyle } from 'emoji-picker-react'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'

type Props = {
  customer: any
  changeQuestion: any
  index: any
  category: any
  qId?: any
  qDesc?: any
  questionId?: any
  questionDescription?: any
  createQuestionId?: number
  learningCard?: any
}

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

const SelectQuestion: React.FC<Props> = ({
  qDesc,
  qId,
  index,
  changeQuestion,
  customer,
  category,
  questionId,
  questionDescription,
  createQuestionId,
  learningCard
}) => {
  const [first, setfirst] = useState(false)
  useEffect(() => {
    setfirst(true)
  }, [qDesc, qId])
  useEffect(() => {
    setTopping(qId)
    /* setSave(qDesc) */
  }, [first])
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [i, setI] = useState(false)
  const [arrow, setArrow] = useState({
    row: 0,
    sort: '',
  })
  const [filterOptions, setFilterOptions] = useState<any>({
    question: '',
    category: '',
    questionId: '',
    CreatedDateStart: '',
    CreatedDateEnd: '',
    CreatedBy: '',
    Status: '',
  })
  const arrowF = (e: any) => {
    if (arrow.row === e) {
      return <span>{arrow.sort}</span>
    }
  }
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState<any>(`page=${page}&size=15&`)
  const LoginState = useSelector((state: any) => state.Login)
  const [sortOptions, setSortOptions] = useState<any>('')
  const [expense, setExpense] = useState<any>([])
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const [topping, setTopping] = useState<any>()
  const [desc, setDesc] = useState<any>('')
  const onOptionChange = (item: any) => {
    setTopping(item?.questionId)
    setDesc(item?.description)
  }
  const [save, setSave] = useState<any>()

  //Quiz Delete Warn Modal
  const [quizDeleteWarnModal, setQuizDeleteWarnModal] = useState(false)
  const handleCloseQuizDeleteWarnModal = () => setQuizDeleteWarnModal(false)
  const [binHover, setBinHover] = useState<boolean>(false)

  const refreshButton = () => {
    if (
      filterOptions.question.length > 1 ||
      filterOptions.category.length > 1 ||
      filterOptions.questionId.length > 1 ||
      filterOptions.CreatedDateStart.length > 1 ||
      filterOptions.CreatedDateEnd.length > 1 ||
      filterOptions.CreatedBy.length > 1 ||
      filterOptions.Status.length > 1 ||
      sortOptions.length > 1
    ) {
      return (
        <button
          className='btn svg-icon  svg-icon-2x'
          style={{ marginTop: '-10px', marginRight: '-20px' }}
          onClick={() => {
            setFilterOptions({
              question: '',
              category: '',
              questionId: '',
              CreatedDateStart: '',
              CreatedDateEnd: '',
              CreatedBy: '',
              Status: '',
            })
            setArrow({
              row: 0,
              sort: '',
            })
            setSortOptions('')
            setI(false)
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='27.644'
            height='27.644'
            viewBox='0 0 27.644 27.644'
          >
            <path
              id='refresh-circle'
              d='M61.822,48A13.822,13.822,0,1,0,75.644,61.822,13.838,13.838,0,0,0,61.822,48Zm0,20.8a6.246,6.246,0,1,1,0-12.493h.28l-.938-.937a.93.93,0,1,1,1.316-1.316l2.658,2.658a.93.93,0,0,1,0,1.316L62.48,60.685a.93.93,0,0,1-1.316-1.316l1.2-1.2c-.158-.007-.339-.007-.538-.007a4.386,4.386,0,1,0,4.386,4.386.93.93,0,1,1,1.861,0A6.254,6.254,0,0,1,61.822,68.8Z'
              transform='translate(-48 -48)'
              fill='#7e8299'
            />
          </svg>
        </button>
      )
    } else {
      return <></>
    }
  }

  // Yeni Bölüm
  //States
  const [rows, setRows] = useState<any>([])
  const [rowCount, setRowCount] = useState(0)
  const [loadingExport, setLoadingExport] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [queryOptions, setQueryOptions] = useState<any>({})
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    InternalEmployeeId: false,
  })
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [statusLoading, setStatusLoading] = useState(false)
  const [activitiesStatus, setActivitiesStatus] = useState(-1)
  const [selected, setSelected] = useState<any>([])
  const [queryDescription, setQueryDescription] = useState<any>('')

  useEffect(() => {
    getQuestionList()
  }, [queryOptions, paginationModel, columnVisibilityModel, first, category, show])
  useEffect(() => {
    if (show && save === undefined) {
      setSelected([])
    }
  }, [show])

  async function getQuestionList() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/content/getAllCategoriesForQuestionList`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setCategories(res.data)
      })
      .catch((err) => { })
    setIsLoadingData(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/content/getQuestionListByCustomerId?page=${paginationModel.page + 1
        }&size=${paginationModel.pageSize}&categoryId=${category}&learningCard=1`,
        {
          queryOptions,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        const formattedRows = response.data.items?.map((row: any) => ({
          createdName: row.createdName,
          createdDate: row.createdDate,
          description: row.description,
          questionId: row.questionId,
          categoryId: row.categoryId,
          cId: row.cId,
          creator: row.creator,
        }))
        setRows(formattedRows)
        setRowCount(response.data.total)
        setExpense(response.data)
        setIsLoadingData(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
      })
  }
  const [hoverList, setHoverList] = useState(null)
  const [categories, setCategories] = useState<any>([])
  const columns: any = [
    {
      field: 'mediaUrl',
      headerName: 'MEDIA',
      sortable: false,
      width: 150,
      filterable: false,
      renderCell: (params: any) => {
        const item = params.row
        if (item.mediaType === 1) {
          if (item.mediaUrl) {
            return (
              <div className='p-3 d-flex text-center'>
                <img src={item.mediaUrl} alt='' className='rounded' width='50' height='50' />
              </div>
            )
          } else {
            return (
              <div className='p-3'>
                <img
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt=''
                  className='rounded'
                  width='50'
                  height='50'
                />
              </div>
            )
          }
        } else if (item.mediaType === 4) {
          if (item.mediaUrl) {
            return (
              <div className='p-3'>
                <Emoji unified={item.mediaUrl} emojiStyle={EmojiStyle.APPLE} size={50} />
              </div>
            )
          } else {
            return (
              <div className='p-3'>
                <img
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt=''
                  className='rounded'
                  width='50'
                  height='50'
                />
              </div>
            )
          }
        } else if (item.mediaType === 2) {
          if (item.mediaUrl) {
            return (
              <div
                className='p-3 '
                style={{ transform: hoverList === item.mediaUrl ? 'scale(1.5)' : 'none' }}
                onMouseEnter={() => setHoverList(item.mediaUrl)}
                onMouseLeave={() => setHoverList(null)}
              >
                <ReactPlayer controls={true} url={item.mediaUrl} height={'50px'} width={'50px'} />
              </div>
            )
          } else {
            return (
              <div className='p-3'>
                <img
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt=''
                  className='rounded'
                  width='50'
                  height='50'
                />
              </div>
            )
          }
        } else {
          return (
            <div className='p-3'>
              <img
                src={toAbsoluteUrl('/media/svg/image.svg')}
                alt=''
                className='rounded'
                width='50'
                height='50'
              />
            </div>
          )
        }
      },
    },
    {
      field: 'description',
      headerName: 'Question',
      width: 230,
    },
    {
      field: 'categoryId',
      headerName: 'CATEGORY',
      width: 150,
      type: 'singleSelect',
      valueOptions: categories.map((category: any) => ({
        value: category.categoryId.toString(),
        label: category.name,
      })),
      renderCell: (params: any) => params.value,
    },
    {
      field: 'questionId',
      headerName: 'ID',
      width: 80,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value.toFixed(0),
    },
    {
      field: 'createdDate',
      headerName: 'CREATED DATE',
      width: 180,
      type: 'dateTime',
      valueGetter: ({ value }: { value: Date }) => value && new Date(value),
    },
    {
      field: 'createdName',
      headerName: 'CREATED BY',
      width: 150,
    },
    { field: 'creator', headerName: 'CREATOR', width: 150 },
  ]
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
        return
      } */

    setQueryOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])

  const handleSelectionModelChange = (newSelectionModel: any) => {
    if (newSelectionModel.length <= 1) {
      setSelected(newSelectionModel)
    }
  }
  useEffect(() => {
    if (selected.length > 0) {
      let selectedData = rows.find((item: any) => {
        return item.questionId === selected[0]
      })

      setQueryDescription(selectedData?.description)
    }
  }, [selected])
  function handleSave() {
    setSave(queryDescription)
    let selectedData = rows.find((item: any) => {
      return item.questionId === selected[0]
    })
    changeQuestion(selected[0], selectedData.description, index)
    handleClose()
  }

  useEffect(() => {
    if (questionDescription !== 0) {
      setSave(questionDescription)
      setSelected([createQuestionId])
    }
  }, [questionDescription])

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    if(learningCard.some((item: any) => item.questionId === params.id)){
      return
    }
    const newSelectionModel = [params.id];
    setSelected(newSelectionModel);
  }

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ ml: 1, mr: 1 }}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }

  return (
    <>
      {!save ? (
        <div
          style={{
            width: '100%',
            background: 'transparent',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handleShow}
        >
          <Typography color={'#ADB5BD'}>Click to select a question</Typography>
        </div>
      ) : (
        /*  <button
          className='card d-flex justify-content-center rounded '
          style={{
            backgroundColor: category ? 'transparent' : '#b4b4b4',
            height: '50px',
            width: '480px',
            borderRadius: '0px',
          }}
          onClick={handleShow}
          disabled={category === undefined}
        >
          <span
            className='h1 text-center mt-2 w-100 text-muted'
            style={{backgroundColor: 'transparent', color: category ? '' : '#9c9c9c'}}
          >
            {category ? 'Click to add quiz question' : ' Please choose category '}
          </span>
        </button> */
        <button
          className='card d-flex justify-items-center '
          style={{
            backgroundColor: 'transparent',
            paddingTop: '10px',
            paddingBottom: '10px',
            height: '150px',
            width: '100%',
          }}
          onClick={handleShow}
          disabled={category === undefined}
        >
          <div
            /* style={{overflow: 'scroll'}} */
            style={{ height: '192px', width: '100%', overflowX: 'auto' }}
          >
            <span
              className='h1 text-center mt-2 w-100 text-dark'
              style={{ backgroundColor: 'transparent', fontSize: '20px', fontFamily: 'atma-font' }}
            >
              {save}
            </span>
          </div>
        </button>
      )}
      {
        <div>
          <IconButton
            disabled={category ? (save ? false : true) : true}
            className='float-end  btn'
            onClick={() => {
              setQuizDeleteWarnModal(true)
            }}
            onMouseEnter={() => setBinHover(true)}
            onMouseLeave={() => setBinHover(false)}
            style={{
              backgroundColor: category ? 'transparent' : 'transparent',
              zIndex: '99999',
              height: '190px',
              borderRadius: '0px 5px 5px 0px',
              marginRight: '-10px',
              display: selected[0] === 0 ? 'none' : 'flex',
              alignItems: 'center',
            }}
          >
            <DeleteForeverRoundedIcon color='error' fontSize='large' />
            {/* {category ? (
            save ? (
              binHover ? (
                <span className=' svg-icon svg-icon-1x svg-icon-delete'>
                  <svg
                    id='trash-bin-red'
                    xmlns='http://www.w3.org/2000/svg'
                    width='15'
                    height='14.35'
                    viewBox='0 0 15 14.35'
                  >
                    <rect
                      id='Rectangle_2203'
                      data-name='Rectangle 2203'
                      width='15'
                      height='2.565'
                      rx='1.282'
                      fill='#f1416c'
                    />
                    <path
                      id='Path_2378'
                      data-name='Path 2378'
                      d='M66.678,160a.264.264,0,0,0-.2.093.29.29,0,0,0-.068.219l.887,8.9a.055.055,0,0,0,0,.008,1.645,1.645,0,0,0,1.607,1.494H76.69A1.645,1.645,0,0,0,78.3,169.22v-.007l.885-8.9a.29.29,0,0,0-.068-.219.264.264,0,0,0-.2-.093Zm8.386,6.368a.582.582,0,0,1,0,.8.523.523,0,0,1-.763,0L72.8,165.591l-1.506,1.575a.523.523,0,0,1-.762,0,.582.582,0,0,1,0-.8l1.506-1.575-1.506-1.575a.582.582,0,0,1,0-.8.523.523,0,0,1,.763,0L72.8,164l1.506-1.575a.523.523,0,0,1,.762,0,.582.582,0,0,1,0,.8l-1.506,1.575Z'
                      transform='translate(-65.296 -156.364)'
                      fill='#f1416c'
                    />
                  </svg>
                </span>
              ) : (
                <span className=' svg-icon svg-icon-1x svg-icon-delete'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='15.35'
                    viewBox='0 0 16 15.35'
                  >
                    <g id='trash-bin-red' transform='translate(0.5 0.5)'>
                      <rect
                        id='Rectangle_2203'
                        data-name='Rectangle 2203'
                        width='15'
                        height='2.565'
                        rx='1.282'
                        fill='none'
                        stroke='rgba(250,0,0,0.5)'
                        stroke-width='1'
                      />
                      <path
                        id='Path_2378'
                        data-name='Path 2378'
                        d='M66.678,160a.264.264,0,0,0-.2.093.29.29,0,0,0-.068.219l.887,8.9a.055.055,0,0,0,0,.008,1.645,1.645,0,0,0,1.607,1.494H76.69A1.645,1.645,0,0,0,78.3,169.22v-.007l.885-8.9a.29.29,0,0,0-.068-.219.264.264,0,0,0-.2-.093Zm8.386,6.368a.582.582,0,0,1,0,.8.523.523,0,0,1-.763,0L72.8,165.591l-1.506,1.575a.523.523,0,0,1-.762,0,.582.582,0,0,1,0-.8l1.506-1.575-1.506-1.575a.582.582,0,0,1,0-.8.523.523,0,0,1,.763,0L72.8,164l1.506-1.575a.523.523,0,0,1,.762,0,.582.582,0,0,1,0,.8l-1.506,1.575Z'
                        transform='translate(-65.296 -156.364)'
                        fill='none'
                        stroke='rgba(250,0,0,0.5)'
                        stroke-width='1'
                      />
                    </g>
                  </svg>
                </span>
              )
            ) : (
              true
            )
          ) : (
            true
          )} */}
          </IconButton>
        </div>
      }

      {
        // Quiz Delete Warn Modal
      }
      <Dialog
        open={quizDeleteWarnModal}
        keepMounted
        onClose={handleCloseQuizDeleteWarnModal}
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{
          style: {
            backgroundColor: '#e14545',
          },
        }}
      >
        {/*       <DialogTitle className='bg-light-danger border-0'>
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent >
          <DialogContentText id='alert-dialog-slide-description'>
            Are You Sure? Quiz question will be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button variant='contained' color='inherit' onClick={handleCloseQuizDeleteWarnModal}>
            No
          </Button>
          <Button
            variant='contained'
            color='error'
            onClick={() => {
              setSave(undefined)
              changeQuestion(null, '', index)
              handleCloseQuizDeleteWarnModal()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={show}
        keepMounted
        fullWidth
        maxWidth='lg'
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle fontWeight={'bold'}>
          Select Question{' '}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className='card-body py-3'>
            {
              //DataGrid start
            }
            <Box sx={{ height: 600, width: '100%' }}>
              <StyledDataGrid
                rows={rows || []}
                columns={columns || []}
                rowHeight={50}
                disableVirtualization
                checkboxSelection
                getRowId={(row) => row.questionId}
                disableRowSelectionOnClick
                sortingMode='server'
                onSortModelChange={handleSortModelChange}
                onCellClick={handleCellClick}
                rowSelectionModel={selected}
                onRowSelectionModelChange={handleSelectionModelChange}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                onFilterModelChange={onFilterChange}
                filterMode='server'
                isRowSelectable={(params: GridRowParams) =>
                  !learningCard.some((item: any) => item.questionId === params.id)
                }
                rowCount={rowCount}
                loading={isLoadingData}
                paginationModel={paginationModel}
                paginationMode='server'
                pagination={true}
                onPaginationModelChange={setPaginationModel}
                getRowClassName={(params) =>
                  `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
                }
                slots={{
                  /* toolbar: CustomToolbar, */
                  pagination: CustomPagination,
                }}
                sx={{
                  boxShadow: 3,
                  '& .MuiDataGrid-cell:hover': {
                    cursor: 'pointer',
                  },
                  '&	.MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                    color: '#A1A5B7',
                    fontSize: 17,
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
            {
              //dataGrid end
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='inherit' onClick={handleClose}>
            CLOSE
          </Button>
          <Button variant='contained' onClick={handleSave}>
            DONE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SelectQuestion
