import React, {useEffect, useRef, useState} from 'react'
import {Editor} from '@tinymce/tinymce-react'
import {Button, Col, Container, Modal, Row, Stack, Tab, Tabs} from 'react-bootstrap'
import Cropper from 'react-cropper'
type Props = {
  question?: any
  setquestion: any
  setplainText: any
  html?: any
  button?:any
  mediaType?:any
}
const TinyMCEWidget: React.FC<Props> = ({question, setquestion, setplainText, html,button,mediaType}) => {
  require('./Customcss.css')
  const React = require('react')
  let rm: any = document.getElementById('remaining')
  const editorRef: any = useRef(null)
  const sizeLimit = mediaType !== 0 ? 750 : 750
  const [value, setValue] = React.useState('')
  const [length, setLength] = React.useState(0)
  const handleInit = (evt: any, editor: any) => {
    setLength(editor.getContent({format: 'text'}).length)
    editorRef.current = editor
  }

  const handleUpdate = (value: any, editor: any) => {
  
    button && button()
    const length = editor.getContent({format: 'text'}).length
    if (length <= sizeLimit) {
      setquestion(value)
      setValue(value)
      setLength(length)
    }
    if (sizeLimit - length <= 20) {
    } else {
    }
  }
  const handleBeforeAddUndo = (evt: any, editor: any) => {
    const length = editor.getContent({format: 'text'}).length
    // note that this is the opposite test as in handleUpdate
    // because we are determining when to deny adding an undo level
    if (length > sizeLimit) {
      evt.preventDefault()
    }
  }
  const [description, setDescription] = useState<any>('')
 
  function convertHtmlToPlainText(html) {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent || element.innerText || '';
  }

  useEffect(() => {
  const plainTextValue = convertHtmlToPlainText(value);
    setplainText(plainTextValue)
    setDescription(value?.replace(/<[^>]+>/g, ''))
  }, [value])

  useEffect(() => {
    setValue(html)
  }, [html])
  return (
    <>
      <div style={{fontFamily: 'Atma'}}>
       {/*  <div
          className={
            description?.length > 0
              ? 'd-none'
              : 'd-flex text-white text-center h5 justify-content-center align-items-center'
          }
          style={{width:'487px', height: '300px', marginBottom: '-300px',pointerEvents: 'none',userSelect: 'none',}}
        >
          <span style={{zIndex: '109'}}>
            Enter question <br /> Max 750 ch
          </span>
        </div> */}
        <div
          className={
            description?.length > 0
              ? ' d-flex justify-content-center    '
              : 'd-flex justify-content-center  '
          }
          style={{zIndex: '21474'}}
        >
          <Editor
            onInit={handleInit}
            value={value}
            onEditorChange={handleUpdate}
            onBeforeAddUndo={handleBeforeAddUndo}
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            init={{
              height: '200px',
              width: '487px',
              placeholder: '',
              body_class: 'atma-font',
              menubar: false,
              paste_data_images: false, //drag and drop image engellemek için
              toolbar_mode: 'wrap',
              auto_focus: 'element1',
              content_css:
                '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color: #FFFFFF; }' +
                '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color: white; }',
              content_style:
                "@import url('https://fonts.googleapis.com/css2?family=Atma:wght@400;500&display=swap');" +
                'body { font-family:Atma ;background: #181c32; color: white; font-size: 13pt;} ',
              plugins: 'lists advlist colorpicker emoticons charmap',
              //undo redo
              toolbar:
                ' bold italic underline forecolor backcolor removeformat bullist numlist Superscript subscript charmap',
              images_file_types: 'jpg,svg,webp',
              emoticons_database: 'emojis',
              relative_urls: false,
              statusbar: false,
              branding: false,
              language: 'tr_TR',
              convert_urls: false,
            }}
          />
        </div>
      </div>

      {/* <div
        className='bg-gray-800 d-flex border-top rounded-bottom justify-content-end'
        style={{
          backgroundColor: '#303741',
          color: 'white',
          fontSize: '11pt',
          fontFamily: 'Arial',
          height: '20px',
        }}
      >
        <span  style={{marginRight:'10px',marginTop:'-2px'}} id='remaining'>
          {length} / {sizeLimit}
        </span>
      </div> */}
    </>
  )
}

export default TinyMCEWidget
