/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../../app/modules/errors/ErrorsPage'
import {App} from '../../app/App'
import {useAuth, Logout} from '../auth'
import AuthPage from '../auth/AuthPage'
import {useSelector} from 'react-redux'
import {DefinePassword} from '../auth/components/DefinePassword'
import TestResult from '../pages/reports/testResult'
import ShowPaper from '../pages/reports/showPaper'

const {PUBLIC_URL} = process.env

const AppRoutes = () => {
  const [currentUser, setCurrentUser] = useState(undefined)

  const LoginState = useSelector((state) => state.Login)

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='/showpaper' element={<ShowPaper />} />
          <Route path='/showpaper/:token?' element={<ShowPaper />} />
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='/define-password/:token?' element={<AuthPage />} />
          {LoginState?.loginData ? (
            LoginState?.loginData.detail?.errorMessage ? (
              <Route path='/*' element={<AuthPage />} />
            ) : (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/CustomerDashboard' />} />
              </>
            )
          ) : (
            <>
              <Route path='/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
