import React, {useCallback, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {
  Avatar,
  Box,
  Button,
  Chip,
  MenuItem,
  Pagination,
  Select,
  Typography,
  darken,
  lighten,
  styled,
} from '@mui/material'
import {
  DataGridPro,
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {useSelector} from 'react-redux'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Emoji, EmojiStyle } from 'emoji-picker-react'
import { create } from 'sortablejs'
const ExcelJS = require('exceljs');


const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({theme}) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

function QuestionsReport() {
  const LoginState = useSelector((state: any) => state.Login)

  const QuestionsBreadcrumbs: Array<PageLink> = [
    {
      title: 'Reports',
      // path: '/apps/user-management/users',
      path: '/reports',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  })
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [selectQuestion, setSelectQuestion] = useState([])
  const [questionStatus, setQuestionStatus] = useState(-1)
  const [questionOptions, setQuestionOptions] = useState<any>({
    sortModel: [
      {field: 'questionId', sort: 'desc'},
      { field: 'createdDate', sort: 'desc' },
    ],
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    /*    id: false,
           brokerId: false,
           status: false, */
  })

  const [status] = useState([
    /* {value: 0, label: 'Blocked'}, */
    {value: 1, label: 'Open'},
  ])
  const [difficulty] = useState([
    {value: 1, label: 'Easy'},
    {value: 2, label: 'Med'},
    {value: 3, label: 'Hard'},
  ])

  const [categories, setCategories] = useState<any>([])

  const [category, setCategory] = useState<any>([{value: '', label: ''}])
  const [media, setMedia] = useState<any>([{value: '', label: ''}])
  const [editor, setEditor] = useState<any>([{value: '', label: ''}])

  useEffect(() => {
    if (rows.length > 0) {
      const uniqueCategories = Array.from(new Set(rows.map((row: any) => row.categoryName)))
      const formattedCategories = uniqueCategories.map((cat) => ({value: cat, label: cat}))
      setCategory(formattedCategories)
      const uniqueMedia = Array.from(new Set(rows.map((row: any) => row.media)))
      const formattedMedia = uniqueMedia.map((med) => ({value: med, label: med}))
      setMedia(formattedMedia)
      const uniqueEditor = Array.from(new Set(rows.map((row: any) => row.editor)))
      const formattedEditor = uniqueEditor.map((med) => ({value: med, label: med}))
      setEditor(formattedEditor)
    }
  }, [rows])

  const StatusCell = ({value}: {value: number}) => {
    const getStatusComponent = (status: number) => {
      /* if (status === 0) {
        return (
          <Chip
            className='overflow-visible'
            label='Blocked'
            variant='outlined'
            color='secondary'
            size='small'
            sx={{overflow: 'visible', maxWidth: '90px', fontSize: '13px'}}
          />
        )
      } */
      if (status === 1) {
        return (
          <Chip
            className='overflow-visible'
            label='Open'
            variant='outlined'
            color='success'
            size='small'
            sx={{overflow: 'visible', maxWidth: '90px', fontSize: '13px'}}
          />
        )
      }
      if (status === 2) {
        return (
          <Chip
            className='overflow-visible'
            label='Deleted'
            variant='outlined'
            color='error'
            size='small'
            sx={{overflow: 'visible', maxWidth: '90px', fontSize: '13px'}}
          />
        )
      }
      return null
    }

    return <div style={{height: '19px', width: '57px'}}>{getStatusComponent(value)}</div>
  }
  const DifficultyCell = ({value}: {value: number}) => {
    const getStatusComponent = (status: number) => {
      if (status === 1) {
        return <Typography color={'#47BE7D'}>Easy</Typography>
      }
      if (status === 2) {
        return <Typography color={'#BEB54D'}>Med</Typography>
      }
      if (status === 3) {
        return <Typography color={'#D00000DE'}>Hard</Typography>
      }
      return null
    }

    return <div style={{height: '19px', width: '57px'}}>{getStatusComponent(value)}</div>
  }

  const columns: any = [
    {
      field: 'questionId',
      headerName: 'ID',
      width: 60,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value.toFixed(0),
    },
    {
      field: 'mediaUrl',
      headerName: 'MEDIA',
      align: 'left',
      width: 150,
      filterable: false,
      renderCell: (params: any) => {
        const item = params.row
        if (item.mediaType === 1) {
          if (item.mediaUrl) {
            return (
              <div className='p-3 d-flex text-center'>
                <Avatar variant="rounded" src={item.mediaUrl} alt='media' sx={{
                  height: 50, width: 50, '& img': {
                    objectFit: 'contain',
                  },
                }} />
              </div>
            )
          } else {
            return (
              <div className='p-3'>
                <Avatar
                  variant="rounded"
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt='media' sx={{
                    height: 50, width: 50, '& img': {
                      objectFit: 'contain',
                    },
                  }}
                />
              </div>
            )
          }
        } else if (item.mediaType === 4) {
          if (item.mediaUrl) {
            return (
              <div className='p-3'>
                <Emoji unified={item.mediaUrl} emojiStyle={EmojiStyle.APPLE} size={50} />
              </div>
            )
          } else {
            return (
              <div className='p-3'>
                <Avatar
                  variant="rounded"
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt='media' sx={{
                    height: 50, width: 50, '& img': {
                      objectFit: 'contain',
                    },
                  }}
                />
              </div>
            )
          }
        } else if (item.mediaType === 2) {
          if (item.mediaUrl) {
            return (
              <div
                className='p-3 '
              /*   style={{ transform: hoverList === item.mediaUrl ? 'scale(1.5)' : 'none' }} */
              /*   onMouseEnter={() => setHoverList(item.mediaUrl)}
                onMouseLeave={() => setHoverList(null)} */
              >
                <Avatar
                  variant="rounded"
                  src={item.mediaUrl}
                  alt='media' sx={{
                    height: 50, width: 50, '& img': {
                      objectFit: 'contain',
                    },
                  }}
                />
              </div>
            )
          } else {
            return (
              <div className='p-3'>
                <Avatar
                  variant="rounded"
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt='media' sx={{
                    height: 50, width: 50, '& img': {
                      objectFit: 'contain',
                    },
                  }}
                />
              </div>
            )
          }
        } else {
          return (
            <div className='p-3'>
             <Avatar
                  variant="rounded"
                  src={toAbsoluteUrl('/media/svg/image.svg')}
                  alt='media' sx={{
                    height: 50, width: 50, '& img': {
                      objectFit: 'contain',
                    },
                  }}
                />
            </div>
          )
        }
      },
    },
    {
      field: 'description',
      headerName: 'QUESTION',
      width: 230,
    },
    {
      field: 'categoryName',
      headerName: 'CATEGORY',
      width: 130,
      headerAlign: 'left',
      align: 'left',
      type: 'singleSelect',
      valueOptions: categories.map((category: any) => ({
        value: category.categoryId.toString(),
        label: category.name,
      })),
      renderCell: (params: any) => params.value,
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'createdDate',
      headerName: 'CREATED DATE',
      width: 180,
      type: 'dateTime',
      valueGetter: ({ value }: { value: Date }) => value && new Date(value),
    },
    { field: 'editor', headerName: 'EDITOR', width: 150 },
    { field: 'creator', headerName: 'CREATOR', width: 150 },
    {
      field: 'status',
      headerName: 'STATUS',
      width: 150,
      type: 'singleSelect',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      valueOptions: status.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      renderCell: (params: GridCellParams) => <StatusCell value={params.value as number} />,
      /*    valueFormatter: (params: any) => {
              const selectedStatus = status.find((status: any) => status.value === params.value);
              if (!selectedStatus) {
                console.log('Unexpected value:', params.value);
                return null;
              }
              return selectedStatus ? <StatusCell value={params.value as number}/> : null;
            }, */
    },
    {
      field: 'appearances',
      headerName: 'APPEARANCES',
      description:
        'The total displayed number of question amongs all the users and all the Journeys in the given period of time.',
      width: 150,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'successRate',
      headerName: 'SUCCESS',
      width: 120,
      description:
        'The proportion of the right answers over all displayed questions in the given period of time.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => (params?.value ? params.value.toFixed(2) : '0.00'),
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'totalAnswerCount',
      headerName: 'ANSWERS',
      width: 130,
      description: 'The number of total answer options recorded in the question',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'correctAnswerCount',
      headerName: 'TRUE',
      width: 110,
      description: 'The number of total RIGHT answers of the question',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      /* valueGetter: (params: any) => params?.value ? params.value.toFixed(2) : '0.00', */
    },
    {
      field: 'selection',
      headerName: 'SELECTION',
      width: 120,
      description:
        'The number of options to be choosed by the users. Multiple choice questions has more than 1 selections here. The max nr. of selections is 4 and the minimum is 1.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      /*    sortComparator: (v1:any, v2:any) => {
          const time1 = v1.split(":");
          const time2 = v2.split(":");
          const num1 = parseInt(time1[0], 10) * 60 + parseInt(time1[1], 10);
          const num2 = parseInt(time2[0], 10) * 60 + parseInt(time2[1], 10);
          return num1 - num2;
        },
        renderCell: (params:any) => {
          return <span>{params.value}</span>;
        }, */
    },
/*     {
      field: 'difficulty',
      headerName: 'DIFFICULTY',
      width: 130,
      description: 'How difficult is the question?',
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'center',
      sortable: false,
      valueOptions: difficulty.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      renderCell: (params: GridCellParams) => <DifficultyCell value={params.value as number} />,
    }, */
    {
      field: 'timeLimit',
      headerName: 'TIME LIMIT sec',
      width: 140,
      description: 'Each question has a bulilt-in time cap defined by the editor of the question.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      /* valueGetter: (params: any) => params?.value ? params.value.toFixed(2) : '0.00', */
      /*   valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'timeUsed',
      headerName: 'TIME USED',
      width: 140,
      description: 'The average duration of time required for  answering  this question correctly.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      /* valueGetter: (params: any) => params?.value ? params.value.toFixed(2) : '0.00', */
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'deltaTime',
      headerName: 'TIME Δ %',
      width: 140,
      description:
        'The percentage of difference between Time Limit and Time Used.  If it is bigger than 25%, it would be nice to edit the question and cut Time Limit a bit.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      /* valueGetter: (params: any) => params?.value ? params.value.toFixed(2) : '0.00', */
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'exhaustedTime',
      headerName: 'EXHAUSTED TIME',
      width: 160,
      description:
        'Sometimes the users can not keep up with the pace. They simply can not answer the question, on time. When the time is exhausted, we count in here. The less is better.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      /* valueGetter: (params: any) => params?.value ? params.value.toFixed(2) : '0.00', */
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'avgExhaustedTime',
      headerName: 'EXHAUSTED TIME %',
      width: 170,
      description:
        'The ratio of number of Exhausted Time over the number of this question was displayed. If it is bigger than 10%, we advise to edit the question and add seconds.',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
  ]

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ml: 1, mr: 1}}
          >
            <MenuItem selected value={100}>
              100
            </MenuItem>
            <MenuItem value={150}>150</MenuItem>
            <MenuItem value={200}>200</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelectQuestion(newSelectionModel)
  }

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }

    // Diğer işlemleri burada yapabilirsiniz
  }

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQuestionOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
          return
        } */

    setQuestionOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: {...filterModel},
    }))
  }, [])

  const [loadingExport, setLoadingExport] = useState(false)

  async function downloadExcel() {
    setLoadingExport(true)
    setTimeout(() => {
      setLoadingExport(false)
    }, 1000)
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report');
    worksheet.columns = [
      { header: 'ID', key: 'Id', width: 10 },
      { header: 'Question', key: 'Question', width: 35 },
      { header: 'Category', key: 'Category', width: 18 },
      { header: 'Created Date', key: 'CreatedDate', width: 18 },
      { header: 'Editor', key: 'Editor', width: 18 },
      { header: 'Creator', key: 'Creator', width: 18 },
      { header: 'Status', key: 'Status', width: 18 },
      { header: 'Appearances', key: 'Appearances', width: 18 },
      { header: 'Success', key: 'Success', width: 20 },
      { header: 'Answers', key: 'Answers', width: 19 },
      { header: 'True', key: 'True', width: 18 },
      { header: 'Selection', key: 'Selection', width: 18 },
      { header: 'TimeLimit (sec)', key: 'TimeLimit', width: 18 },
      { header: 'TimeUsed', key: 'TimeUsed', width: 18 },
      { header: 'Time Δ (%)', key: 'TimeDelta', width: 18 },
      { header: 'ExhaustedTime', key: 'ExhaustedTime', width: 18 },
      { header: 'ExhaustedTime (%)', key: 'ExhaustedTimeAvg', width: 18 },
    ];

    // Başlık satırının stilini ayarlayın
    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4F81BD' } // Koyu mavi arka plan rengi
      };
      cell.font = {
        color: { argb: 'FFFFFFFF' }, // Beyaz yazı rengi
        bold: true
      };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        right: { style: 'thin', color: { argb: 'FFFFFFFF' } }
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    worksheet.autoFilter = {
      from: 'A1',
      to: 'P1'
    };

    // newData üzerinde dolaşarak her bir satırı Excel'e ekleyin
    let rowNumber = 2;
    rows.forEach(row => {
      const newRow = worksheet.addRow({
        Id: row.questionId,
        Question:row.description,
        Category: row.categoryName,
        CreatedDate: row.createdDate,
        Editor:row.editor || "",
        Creator:row.creator,
        Status:row.status === 1 ? 'Open' : row.status === 0 ? 'Block' :"",
        Appearances:row.appearances,
        Success:row.successRate,
        Answers: row.totalAnswerCount,
        True:row.correctAnswerCount,
        Selection: row.selection,
        TimeLimit: row.timeLimit,
        TimeUsed: row.timeUsed,
        TimeDelta:row.deltaTime,
        ExhaustedTime:row.exhaustedTime,
        ExhaustedTimeAvg:row.avgExhaustedTime,
      });
      const fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: rowNumber % 2 === 0 ? 'B8CCE4' : 'DCE6F1' },
      };
      newRow.border = {
        top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        right: { style: 'thin', color: { argb: 'FFFFFFFF' } }
      };
      newRow.eachCell((cell) => {
        cell.fill = fill;
      });
      rowNumber++;
    });

    // Excel dosyasını kaydedin
    const buffer = await workbook.xlsx.writeBuffer(); // Workbook'u bir ArrayBuffer olarak döndür
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Blob'u oluştur
    const url = window.URL.createObjectURL(blob); // Blob için bir URL oluştur
    const anchor = document.createElement('a'); // Bir <a> elementi oluştur
    anchor.href = url;
    anchor.download = 'questionsReport.xlsx'; // İndirilecek dosyanın adını ayarla
    document.body.appendChild(anchor); // <a> elementini DOM'a ekle
    anchor.click(); // Programatik olarak tıklayarak indirmeyi başlat
    document.body.removeChild(anchor); // <a> elementini DOM'dan kaldır
    window.URL.revokeObjectURL(url);
  /*   const newData = rows.map((row) => {
      const newRow = {
        Id: row.questionId,
        Media: row.media,
        Question:row.description,
        Category: row.categoryName,
        Appearances:row.appearances,
        Success:row.successRate,
        Answers: row.totalAnswerCount,
        True:row.correctAnswerCount,
        Selection: row.selection,
        TimeLimit: row.timeLimit,
        TimeUsed: row.timeUsed,
        TimeDelta:row.deltaTime,
        ExhaustedTime:row.exhaustedTime,
        ExhaustedTimeAvg:row.avgExhaustedTime,
        Editor:row.editor,
        Creator:row.creator,
        Status:row.status === 1 ? 'Open' : row.status === 0 ? 'Block' :"",
      }
      return newRow;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, 'row')
    //Buffer
    XLSX.write(workBook, {bookType: 'xlsx', type: 'buffer'})
    //Binary string
    XLSX.write(workBook, {bookType: 'xlsx', type: 'binary'})
    //Download
    XLSX.writeFile(workBook, 'questionsReport.xlsx') */
  }

  const [timeButtons, setTimeButtons] = useState('last3Months')

  useEffect(() => {
    fetchRows(timeButtons)
  }, [timeButtons])

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <LoadingButton
          size='small'
          onClick={downloadExcel}
          endIcon={<FileDownloadIcon />}
          loading={loadingExport}
          loadingPosition='end'
          variant='outlined'
        >
          <span>Export</span>
        </LoadingButton>
        <Box marginLeft={14} /* marginBottom={3} */>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('monthToDate')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'monthToDate' ? 'bold' : '',
              color: timeButtons === 'monthToDate' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'monthToDate' ? 'underline' : 'none',
            }}
          >
            Month to date
          </Button>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('lastMonth')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'lastMonth' ? 'bold' : '',
              color: timeButtons === 'lastMonth' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'lastMonth' ? 'underline' : 'none',
            }}
          >
            Last month
          </Button>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('last3Months')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'last3Months' ? 'bold' : '',
              color: timeButtons === 'last3Months' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'last3Months' ? 'underline' : 'none',
            }}
          >
            Last 3 months
          </Button>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('allTime')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'allTime' ? 'bold' : '',
              color: timeButtons === 'allTime' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'allTime' ? 'underline' : 'none',
            }}
          >
            All time
          </Button>
        </Box>
      </GridToolbarContainer>
    )
  }

  /*  useEffect(() => {
    fetchRows()
  }, [paginationModel, userOptions]) */

  const fetchRows = async (timeButtons: any) => {
    setIsLoadingData(true)

    axios
      .get(`${process.env.REACT_APP_API_URL}/content/getAllCategoriesForQuestionList`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setCategories(res.data)
      })
      .catch((err) => { })

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/report/questionReport?timeStamp=${timeButtons}`,
        {},
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const formattedRows = response.data?.map((row: any) => ({
          questionId: row.questionId,
          description:row.description,
          categoryName: row.categoryName,
          createdDate: row.createdDate,
          editor: row.editor,
          creator: row.creator,
          status: row.status,
          mediaUrl: row.mediaUrl,
          mediaType: row.mediaType,
          totalAnswerCount: row.totalAnswerCount,
          correctAnswerCount: row.correctAnswerCount,
          selection: row.selection,
          difficulty: row.difficulty,
          timeLimit: row.timeLimit,
          timeUsed: row.timeUsed,
          deltaTime: row.deltaTime,
          exhaustedTime: row.exhaustedTime,
          avgExhaustedTime: row.avgExhaustedTime,
          appearances: row.appearances,
          successRate: row.successRate,
        }))

        setRows(formattedRows || [])
        setRowCount(response.data.length)
        setIsLoadingData(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
      })
  }

  return (
    <>
      {/* <PageTitle breadcrumbs={QuestionsBreadcrumbs}>Question</PageTitle> */}
      <div
        style={{
          display: 'flex',
          height: 40,
          width: '80%',
          marginBottom: '5px',
          marginTop: '-25px',
          cursor: 'default',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
          Reports - Question
        </Typography>
      </div>
      <div className='card card-custom p-3  '>
        <Box sx={{height: 600, width: '100%'}}>
          <StyledDataGrid
            /*   {...data}
                loading={data.rows.length === 0} */
            rows={rows || []}
            columns={columns}
            rowHeight={50}
            columnHeaderHeight={40}
            disableVirtualization
            /* checkboxSelection */
            onCellDoubleClick={handleCellClick}
            getRowId={(row) => row.questionId}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            disableRowSelectionOnClick
            sortingMode='client'
            sortModel={questionOptions?.sortModel}
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={handleSelectionModelChange}
            filterMode='client'
            onFilterModelChange={onFilterChange}
            isRowSelectable={(params: GridRowParams) => params.row.source === 'Client'}
            rowCount={rowCount}
            loading={isLoadingData}
            paginationModel={paginationModel}
            paginationMode='client'
            pagination={true}
            onPaginationModelChange={setPaginationModel}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
            }
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
            sx={{
              boxShadow: "none",
              border: "none",
              '& .MuiDataGrid-footerContainer': {
                padding: '0px', 
                height: 30
              },

              '& .MuiDataGrid-cell:hover': {
                /*    color: 'primary.main', */
                cursor: 'pointer',
              },
              '&	.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                color: '#A1A5B7',
                fontSize: 12,
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
              },
            }}
          />
        </Box>
      </div>
    </>
  )
}

export default QuestionsReport
