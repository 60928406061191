import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'

export function Logout() {
  useEffect(() => {
    document.location.reload()
  }, [])

  return (
    <div className='bg-gray-100'>
    <Routes>
      <Navigate to='/login' />
    </Routes>
    </div>
  )
}
