import React, {FC, useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {useLocation} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import RangeSlider from 'react-bootstrap-range-slider'
import UserEditModal from './UserInfoEditModal'
import PhoneInput from 'react-phone-input-2'
import {dividerClasses, Slider} from '@mui/material'
import '../../style/userinfo.css'
import {check} from 'prettier'
import {boolean} from 'yup'
const ExamplePage = () => {
  const [phone, setPhone] = useState('')

  function ResetPassword() {
    var myElement = document.getElementById('deneme1')
    //myElement.classList.add("anotherclass");
    myElement?.classList.remove('d-none')
    var myElement = document.getElementById('button')
    //myElement.classList.add("anotherclass");
    myElement?.classList.add('disabled')
  }
  function CahngeStatusShow() {
    var myElement = document.getElementById('status')
    myElement?.classList.remove('d-none')
  }
  function DiscardStatus() {
    var myElement = document.getElementById('status')
    myElement?.classList.add('d-none')
  }
  function ConfirmStatus() {
    var myElement = document.getElementById('status')
    myElement?.classList.add('d-none')
    var myElement1 = document.getElementById('statuschanged')
    myElement1?.classList.remove('d-none')
  }
  function Discard() {
    var myElement = document.getElementById('deneme1')
    //myElement.classList.add("anotherclass");
    myElement?.classList.add('d-none')
    var myElement = document.getElementById('button')
    //myElement.classList.add("anotherclass");
    myElement?.classList.remove('disabled')
  }
  function Confirm() {
    var myElement = document.getElementById('deneme1')
    //myElement.classList.add("anotherclass");
    myElement?.classList.add('d-none')
    var myElement = document.getElementById('deneme2')
    //myElement.classList.add("anotherclass");
    myElement?.classList.remove('d-none')
  }
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const [currentValue, setCurrentValue] = React.useState('0')

  const [value, setValue] = useState('0')
  useEffect(() => {
    CahngeStatus()
  }, [value])
  const [Check, setCheck] = useState(false)
  const userStatusHere = '0'
  const [btn, setBtn] = useState(false)

  function CahngeStatus() {
    var deneme = value
    var cikti
    var stil
    if (deneme === '0') {
      cikti = 'Open'
      stil = ''
      return undefined
    } else if (deneme === '1') {
      cikti = 'Blocking'
      stil = 'text-dark h1'
    } else if (deneme === '2') {
      cikti = 'Deleting'
      stil = 'text-danger h1'
    } else {
      cikti = 'Permanently Erasing'
      stil = 'text-info h1'
    }

    return (
      <div className='card border border-dashed p-5 border-warning bg-light-warning'>
        <div className={stil}>
          {' '}
          You are <strong>{cikti}</strong> this account!{' '}
        </div>
        <div className='h5 text-gray-600'> For extra security, you must confirm the process.</div>
        <div className='text-center  '>
          <input
            onChange={(e) => setCheck(e.target.checked)}
            type='checkbox'
            id='User'
            className='form-check-input checkbox checkbox-success me-4'
          />
          <span className='text-primary'>Confirm</span>
        </div>
      </div>
    )
  }
  function ChangeBtn() {
    if (userStatusHere != value) {
      if (Check == true) {
        return <button className='btn btn-primary me-5'>Save</button>
      }
      if (Check == false) {
        return (
          <button className='btn btn-primary me-5' disabled>
            Save
          </button>
        )
      } else {
        return <div></div>
      }
    } else {
      return <button className='btn btn-primary me-5'>Save</button>
    }
  }

  function Emailverify() {
    if (location.state.GDPRAccepted !== null) {
      return <div className='badge badge-light-success fw-bold h-75 my-auto'>Verifyed</div>
    } else {
      return (
        <>
          <div className='badge badge-light-danger fw-bold h-75 my-auto'>Not Verifyed</div>{' '}
          <a href=''>resent verifty mail</a>
        </>
      )
    }
  }

  function Verify() {
    if (location.state.GDPRAccepted !== null) {
      return <div className='badge badge-light-success fw-bold h-75 '>Verifyed</div>
    } else {
      return (
        <>
          <div className='badge badge-light-danger fw-bold h-75'>Not Verifyed</div>{' '}
          <a href=''>resent verifty mail</a>
        </>
      )
    }
  }
  const location: any = useLocation()
  const userAvatarImg = toAbsoluteUrl(`${location.state.avatarPath}`)
  const [selectedImage, setSelectedImage] = useState()

  // This function will be triggered when the file field change
  const imageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0])
    }
  }
  
  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage(undefined)
  }
  const DefaoultImg = toAbsoluteUrl('/media/avatars/blank.png')

  return (
    <>
      <div className='row g-5 g-xxl-8'>
        <div className='card '>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='me-7 mb-4'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  <img src={`${location.state.avatarPath}`} alt='' />
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                </div>
              </div>

              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
                        {location.state.name} {location.state.surname}
                      </a>
                      <a href='#'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen026.svg'
                          className='svg-icon-1 svg-icon-primary'
                        />
                      </a>
                    </div>

                    <div className='d-flex flex-wrap fw-semobold fs-6 mb-4 pe-2'>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-4 me-1'
                        />
                        {location.state.customerName}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen018.svg'
                          className='svg-icon-4 me-1'
                        />
                        adres{' '}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/communication/com011.svg'
                          className='svg-icon-4 me-1'
                        />
                        {location.state.email}
                      </a>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-wrap flex-stack'>
                  <div className='d-flex flex-column flex-grow-1 pe-8'>
                    <div className='d-flex flex-wrap'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr066.svg'
                            className='svg-icon-3 svg-icon-success me-2'
                          />
                          <div className='fs-2 fw-bold'>16</div>
                        </div>

                        <div className='fw-semobold fs-6 text-gray-400'>
                          Learning Journeys <br /> Completed
                        </div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr065.svg'
                            className='svg-icon-3 svg-icon-danger me-2'
                          />
                          <div className='fs-2 fw-bold'>75</div>
                        </div>

                        <div className='fw-semobold fs-6 text-gray-400'>
                          Competition Games <br /> Completed
                        </div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr066.svg'
                            className='svg-icon-3 svg-icon-success me-2'
                          />
                          <div className='fs-2 fw-bold'>60%</div>
                        </div>

                        <div className='fw-semobold fs-6 text-gray-400'>Quizes Completed</div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                    <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                      <span className='fw-semobold fs-6 text-gray-400'>Profile Compleation</span>
                      <span className='fw-bold fs-6'>50%</span>
                    </div>
                    <div className='h-5px mx-3 w-100 bg-light mb-3'>
                      <div
                        className='bg-success rounded h-5px'
                        role='progressbar'
                        style={{width: '50%'}}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card'>
          <div className='card-header'>
            <div className='h1 my-auto'>Basic Info</div>
            <div className='h1 my-auto'>
              Status <span className='badge badge-light-success fs-base  '> Active </span>
            </div>
            <div style={{visibility: 'hidden'}}>
              <UserEditModal user={location.state} />
            </div>
          </div>
          <div className='card-body'>
            <div
              className='image-input image-input-outline float-end'
              style={{marginBottom: '-100px'}}
              id='kt_image_1'
            >
              <label className='  text-center my-auto' style={{width: '100px', height: '100px'}}>
                {(selectedImage && (
                  <>
                    <img
                      style={{width: '100%', height: '100%'}}
                      className='image-input-wrapper'
                      width='80px'
                      height='80px'
                      src={URL.createObjectURL(selectedImage)}
                      alt='Thumb'
                    />
                  </>
                )) || (
                  <img
                    style={{width: '100%', height: '100%'}}
                    className='image-input-wrapper'
                    width='80px'
                    height='80px'
                    src={location.state.avatarPath}
                    alt='Thumb'
                  />
                )}
                <input
                  onChange={imageChange}
                  type='file'
                  name='profile_avatar'
                  accept='.png, .jpg, .jpeg'
                  hidden
                />
              </label>
            </div>
            <table className='table table-hover table-responsive-xl'>
              <tbody>
                <tr>
                  <th scope='row'>Full Name</th>
                  <td className='d-flex'>
                    <input
                      type='text'
                      defaultValue={location.state.name}
                      className='form-control form-control-solid me-5 w-25 '
                    />
                    <input
                      type='text'
                      defaultValue={location.state.surnama}
                      className=' w-25 form-control form-control-solid me-5 '
                    />
                  </td>
                </tr>
                <tr>
                  <th scope='row'>E-Mail</th>
                  <td className='d-flex'>
                    <div className='row'>
                      <div className='col-12 d-flex'>
                        <input
                          type='text'
                          defaultValue={location.state.email}
                          className='form-control form-control-solid me-5  '
                        />
                        <Emailverify />
                      </div>
                      <div className='d-flex'>
                        <input
                          className='form-check-input me-5 mt-2'
                          type='checkbox'
                          name='aa'
                          id='aa'
                        />{' '}
                        <div className='mt-2'>Do NOT use for notification</div>
                      </div>
                    </div>{' '}
                  </td>
                </tr>
                <tr>
                  <th scope='row'>User Group</th>
                  <td>
                    <select
                      className='form-select form-select-solid w-50 fw-semobold'
                      name='Role'
                      id='Role'
                      defaultChecked={true}
                      defaultValue={location.state.customerName}
                    >
                      <option value='1'>{location.state.customerName}</option>
                      <option value='2'>Admin</option>
                      <option value='3'>Admin</option>
                      <option value='4'>Admin</option>
                      <option value='5'>Admin</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th scope='row'>Roles</th>
                  <td>
                    <div className='col-8 row d-flex'>
                      <div className='col-3 card bg-light-primary text-center bırder border-info m-5 d-flex justify-content-center'>
                        <div className='w-100 d-flex justify-content-center'>
                          <input type='checkbox' id='MasterAdmin' className='form-check-input ' />
                        </div>
                        <span className='text-primary'>MasterAdmin</span>
                      </div>
                      <div className='col-3 card bg-light-primary text-center bırder border-info m-5'>
                        <div className='w-100 d-flex justify-content-center'>
                          <input type='checkbox' id='ContentEditor' className='form-check-input ' />
                        </div>
                        <span className='text-primary'>ContentEditor</span>
                      </div>
                      <div className='col-3 card bg-light-primary text-center bırder border-info m-5'>
                        <div className='w-100 d-flex justify-content-center'>
                          <input
                            type='checkbox'
                            id='User'
                            className='form-check-input checkbox checkbox-success'
                          />
                        </div>
                        <span className='text-primary'>User</span>
                      </div>
                      <div className='col-3 card bg-light-primary text-center bırder border-info m-5'>
                        <div className='w-100 d-flex justify-content-center'>
                          <input type='checkbox' id='Trainer' className='form-check-input ' />
                        </div>

                        <span className='text-primary'>Trainer</span>
                      </div>
                      <div className='col-3 card bg-light-primary text-center bırder border-info m-5'>
                        <div className='w-100 d-flex justify-content-center'>
                          <input type='checkbox' id='Reporter' className='form-check-input ' />
                        </div>

                        <span className='text-primary'>Reporter</span>
                      </div>
                      <div className='col-3 card bg-light-primary text-center bırder border-info m-5'>
                        <div className='w-100 d-flex justify-content-center'>
                          <input type='checkbox' id='IT' className='form-check-input ' />
                        </div>

                        <span className='text-primary'>IT</span>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope='row'>Internal ID</th>
                  <td className='d-flex'>
                    <input
                      type='text'
                      defaultValue={location.state.userId}
                      className='form-control form-control-solid me-5 w-50 '
                    />
                  </td>
                </tr>
                <tr>
                  <th scope='row'>User Group</th>
                  <td>
                    <select
                      className='form-select form-select-solid w-50 fw-semobold'
                      defaultChecked={true}
                      defaultValue='Turkey'
                    >
                      <option value=''>Turkey</option>
                      <option value='AF'>Afghanistan</option>
                      <option value='AX'>Aland Islands</option>
                      <option value='AL'>Albania</option>
                      <option value='DZ'>Algeria</option>
                      <option value='AS'>American Samoa</option>
                      <option value='AD'>Andorra</option>
                      <option value='AO'>Angola</option>
                      <option value='AI'>Anguilla</option>
                      <option value='AQ'>Antarctica</option>
                      <option value='AG'>Antigua and Barbuda</option>
                      <option value='AR'>Argentina</option>
                      <option value='AM'>Armenia</option>
                      <option value='AW'>Aruba</option>
                      <option value='AU'>Australia</option>
                      <option value='AT'>Austria</option>
                      <option value='AZ'>Azerbaijan</option>
                      <option value='BS'>Bahamas</option>
                      <option value='BH'>Bahrain</option>
                      <option value='BD'>Bangladesh</option>
                      <option value='BB'>Barbados</option>
                      <option value='BY'>Belarus</option>
                      <option value='BE'>Belgium</option>
                      <option value='BZ'>Belize</option>
                      <option value='BJ'>Benin</option>
                      <option value='BM'>Bermuda</option>
                      <option value='BT'>Bhutan</option>
                      <option value='BO'>Bolivia, Plurinational State of</option>
                      <option value='BQ'>Bonaire, Sint Eustatius and Saba</option>
                      <option value='BA'>Bosnia and Herzegovina</option>
                      <option value='BW'>Botswana</option>
                      <option value='BV'>Bouvet Island</option>
                      <option value='BR'>Brazil</option>
                      <option value='IO'>British Indian Ocean Territory</option>
                      <option value='BN'>Brunei Darussalam</option>
                      <option value='BG'>Bulgaria</option>
                      <option value='BF'>Burkina Faso</option>
                      <option value='BI'>Burundi</option>
                      <option value='KH'>Cambodia</option>
                      <option value='CM'>Cameroon</option>
                      <option value='CA'>Canada</option>
                      <option value='CV'>Cape Verde</option>
                      <option value='KY'>Cayman Islands</option>
                      <option value='CF'>Central African Republic</option>
                      <option value='TD'>Chad</option>
                      <option value='CL'>Chile</option>
                      <option value='CN'>China</option>
                      <option value='CX'>Christmas Island</option>
                      <option value='CC'>Cocos (Keeling) Islands</option>
                      <option value='CO'>Colombia</option>
                      <option value='KM'>Comoros</option>
                      <option value='CG'>Congo</option>
                      <option value='CD'>Congo, the Democratic Republic of the</option>
                      <option value='CK'>Cook Islands</option>
                      <option value='CR'>Costa Rica</option>
                      <option value='CI'>Côte d'Ivoire</option>
                      <option value='HR'>Croatia</option>
                      <option value='CU'>Cuba</option>
                      <option value='CW'>Curaçao</option>
                      <option value='CY'>Cyprus</option>
                      <option value='CZ'>Czech Republic</option>
                      <option value='DK'>Denmark</option>
                      <option value='DJ'>Djibouti</option>
                      <option value='DM'>Dominica</option>
                      <option value='DO'>Dominican Republic</option>
                      <option value='EC'>Ecuador</option>
                      <option value='EG'>Egypt</option>
                      <option value='SV'>El Salvador</option>
                      <option value='GQ'>Equatorial Guinea</option>
                      <option value='ER'>Eritrea</option>
                      <option value='EE'>Estonia</option>
                      <option value='ET'>Ethiopia</option>
                      <option value='FK'>Falkland Islands (Malvinas)</option>
                      <option value='FO'>Faroe Islands</option>
                      <option value='FJ'>Fiji</option>
                      <option value='FI'>Finland</option>
                      <option value='FR'>France</option>
                      <option value='GF'>French Guiana</option>
                      <option value='PF'>French Polynesia</option>
                      <option value='TF'>French Southern Territories</option>
                      <option value='GA'>Gabon</option>
                      <option value='GM'>Gambia</option>
                      <option value='GE'>Georgia</option>
                      <option value='DE'>Germany</option>
                      <option value='GH'>Ghana</option>
                      <option value='GI'>Gibraltar</option>
                      <option value='GR'>Greece</option>
                      <option value='GL'>Greenland</option>
                      <option value='GD'>Grenada</option>
                      <option value='GP'>Guadeloupe</option>
                      <option value='GU'>Guam</option>
                      <option value='GT'>Guatemala</option>
                      <option value='GG'>Guernsey</option>
                      <option value='GN'>Guinea</option>
                      <option value='GW'>Guinea-Bissau</option>
                      <option value='GY'>Guyana</option>
                      <option value='HT'>Haiti</option>
                      <option value='HM'>Heard Island and McDonald Islands</option>
                      <option value='VA'>Holy See (Vatican City State)</option>
                      <option value='HN'>Honduras</option>
                      <option value='HK'>Hong Kong</option>
                      <option value='HU'>Hungary</option>
                      <option value='IS'>Iceland</option>
                      <option value='IN'>India</option>
                      <option value='ID'>Indonesia</option>
                      <option value='IR'>Iran, Islamic Republic of</option>
                      <option value='IQ'>Iraq</option>
                      <option value='IE'>Ireland</option>
                      <option value='IM'>Isle of Man</option>
                      <option value='IL'>Israel</option>
                      <option value='IT'>Italy</option>
                      <option value='JM'>Jamaica</option>
                      <option value='JP'>Japan</option>
                      <option value='JE'>Jersey</option>
                      <option value='JO'>Jordan</option>
                      <option value='KZ'>Kazakhstan</option>
                      <option value='KE'>Kenya</option>
                      <option value='KI'>Kiribati</option>
                      <option value='KP'>Korea, Democratic People's Republic of</option>
                      <option value='KW'>Kuwait</option>
                      <option value='KG'>Kyrgyzstan</option>
                      <option value='LA'>Lao People's Democratic Republic</option>
                      <option value='LV'>Latvia</option>
                      <option value='LB'>Lebanon</option>
                      <option value='LS'>Lesotho</option>
                      <option value='LR'>Liberia</option>
                      <option value='LY'>Libya</option>
                      <option value='LI'>Liechtenstein</option>
                      <option value='LT'>Lithuania</option>
                      <option value='LU'>Luxembourg</option>
                      <option value='MO'>Macao</option>
                      <option value='MK'>Macedonia, the former Yugoslav Republic of</option>
                      <option value='MG'>Madagascar</option>
                      <option value='MW'>Malawi</option>
                      <option value='MY'>Malaysia</option>
                      <option value='MV'>Maldives</option>
                      <option value='ML'>Mali</option>
                      <option value='MT'>Malta</option>
                      <option value='MH'>Marshall Islands</option>
                      <option value='MQ'>Martinique</option>
                      <option value='MR'>Mauritania</option>
                      <option value='MU'>Mauritius</option>
                      <option value='YT'>Mayotte</option>
                      <option value='MX'>Mexico</option>
                      <option value='FM'>Micronesia, Federated States of</option>
                      <option value='MD'>Moldova, Republic of</option>
                      <option value='MC'>Monaco</option>
                      <option value='MN'>Mongolia</option>
                      <option value='ME'>Montenegro</option>
                      <option value='MS'>Montserrat</option>
                      <option value='MA'>Morocco</option>
                      <option value='MZ'>Mozambique</option>
                      <option value='MM'>Myanmar</option>
                      <option value='NA'>Namibia</option>
                      <option value='NR'>Nauru</option>
                      <option value='NP'>Nepal</option>
                      <option value='NL'>Netherlands</option>
                      <option value='NC'>New Caledonia</option>
                      <option value='NZ'>New Zealand</option>
                      <option value='NI'>Nicaragua</option>
                      <option value='NE'>Niger</option>
                      <option value='NG'>Nigeria</option>
                      <option value='NU'>Niue</option>
                      <option value='NF'>Norfolk Island</option>
                      <option value='MP'>Northern Mariana Islands</option>
                      <option value='NO'>Norway</option>
                      <option value='OM'>Oman</option>
                      <option value='PK'>Pakistan</option>
                      <option value='PW'>Palau</option>
                      <option value='PS'>Palestinian Territory, Occupied</option>
                      <option value='PA'>Panama</option>
                      <option value='PG'>Papua New Guinea</option>
                      <option value='PY'>Paraguay</option>
                      <option value='PE'>Peru</option>
                      <option value='PH'>Philippines</option>
                      <option value='PN'>Pitcairn</option>
                      <option value='PL'>Poland</option>
                      <option value='PT'>Portugal</option>
                      <option value='PR'>Puerto Rico</option>
                      <option value='QA'>Qatar</option>
                      <option value='RE'>Réunion</option>
                      <option value='RO'>Romania</option>
                      <option value='RU'>Russian Federation</option>
                      <option value='RW'>Rwanda</option>
                      <option value='BL'>Saint Barthélemy</option>
                      <option value='SH'>Saint Helena, Ascension and Tristan da Cunha</option>
                      <option value='KN'>Saint Kitts and Nevis</option>
                      <option value='LC'>Saint Lucia</option>
                      <option value='MF'>Saint Martin (French part)</option>
                      <option value='PM'>Saint Pierre and Miquelon</option>
                      <option value='VC'>Saint Vincent and the Grenadines</option>
                      <option value='WS'>Samoa</option>
                      <option value='SM'>San Marino</option>
                      <option value='ST'>Sao Tome and Principe</option>
                      <option value='SA'>Saudi Arabia</option>
                      <option value='SN'>Senegal</option>
                      <option value='RS'>Serbia</option>
                      <option value='SC'>Seychelles</option>
                      <option value='SL'>Sierra Leone</option>
                      <option value='SG'>Singapore</option>
                      <option value='SX'>Sint Maarten (Dutch part)</option>
                      <option value='SK'>Slovakia</option>
                      <option value='SI'>Slovenia</option>
                      <option value='SB'>Solomon Islands</option>
                      <option value='SO'>Somalia</option>
                      <option value='ZA'>South Africa</option>
                      <option value='GS'>South Georgia and the South Sandwich Islands</option>
                      <option value='KR'>South Korea</option>
                      <option value='SS'>South Sudan</option>
                      <option value='ES'>Spain</option>
                      <option value='LK'>Sri Lanka</option>
                      <option value='SD'>Sudan</option>
                      <option value='SR'>Suriname</option>
                      <option value='SJ'>Svalbard and Jan Mayen</option>
                      <option value='SZ'>Swaziland</option>
                      <option value='SE'>Sweden</option>
                      <option value='CH'>Switzerland</option>
                      <option value='SY'>Syrian Arab Republic</option>
                      <option value='TW'>Taiwan, Province of China</option>
                      <option value='TJ'>Tajikistan</option>
                      <option value='TZ'>Tanzania, United Republic of</option>
                      <option value='TH'>Thailand</option>
                      <option value='TL'>Timor-Leste</option>
                      <option value='TG'>Togo</option>
                      <option value='TK'>Tokelau</option>
                      <option value='TO'>Tonga</option>
                      <option value='TT'>Trinidad and Tobago</option>
                      <option value='TN'>Tunisia</option>
                      <option value='TR'>Turkey</option>
                      <option value='TM'>Turkmenistan</option>
                      <option value='TC'>Turks and Caicos Islands</option>
                      <option value='TV'>Tuvalu</option>
                      <option value='UG'>Uganda</option>
                      <option value='UA'>Ukraine</option>
                      <option value='AE'>United Arab Emirates</option>
                      <option value='GB'>United Kingdom</option>
                      <option value='US'>United States</option>
                      <option value='UY'>Uruguay</option>
                      <option value='UZ'>Uzbekistan</option>
                      <option value='VU'>Vanuatu</option>
                      <option value='VE'>Venezuela, Bolivarian Republic of</option>
                      <option value='VN'>Vietnam</option>
                      <option value='VI'>Virgin Islands</option>
                      <option value='WF'>Wallis and Futuna</option>
                      <option value='EH'>Western Sahara</option>
                      <option value='YE'>Yemen</option>
                      <option value='ZM'>Zambia</option>
                      <option value='ZW'>Zimbabwe</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th scope='row'>Phone Number</th>
                  <td>
                    <div className='w-50'>
                      <PhoneInput
                        specialLabel=''
                        buttonStyle={{backgroundColor: 'white'}}
                        dropdownStyle={{color: 'black'}}
                        inputStyle={{color: 'black', width: '100%'}}
                        country={'tr'}
                        enableSearch={true}
                        onChange={(phone) => setPhone(phone)}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope='row'>User ID</th>
                  <td>
                    <input
                      type='text'
                      value={location.state.userId}
                      className='form-control form-control-solid me-5 w-50 '
                    />
                  </td>
                </tr>

                <tr>
                  <th scope='row'>Organisation</th>
                  <td>
                    <input
                      type='text'
                      defaultValue={location.state.customerName}
                      className='form-control form-control-solid me-5 w-50 '
                    />
                  </td>
                </tr>
                <tr>
                  <th scope='row'>GDPR</th>
                  <td>
                    <input
                      type='text'
                      defaultValue={location.state.GDPRAcceptedDate}
                      className='form-control form-control-solid me-5 w-50 '
                    />
                  </td>
                </tr>
                <tr>
                  <th scope='row'>Status</th>
                  <td className='d-flex'>
                    <Verify />
                    <div className='ms-5 col-9 row text-center'>
                      <div className='col-12'>
                        <input
                          type='range'
                          className={
                            value == '0'
                              ? 'w-100 open'
                              : value == '1'
                              ? 'w-100 block'
                              : value == '2'
                              ? 'w-100 delete'
                              : 'w-100 erase'
                          }
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                          step={1}
                          max={3}
                        />
                      </div>
                      <div className='col-12 row d-flex justify-content-between'>
                        <div className='col-2'>
                          {' '}
                          <span className='h5'>OPEN</span> <br /> The user can benefit from Mobixa
                        </div>
                        <div className='col-2'>
                          <span className='h5'>BLOCK</span> <br />
                          The user can benefit from Mobixa
                        </div>
                        <div className='col-2'>
                          <span className='h5'>DELETE</span> <br />
                          User and reports data will be gone!
                        </div>
                        <div className='col-2'>
                          <span className='h5'>ERASE PERMANENTLY</span> <br />
                          All the personal data will be shreded forever{' '}
                        </div>
                      </div>

                      {/* accent-color: #F55050; */}

                      {CahngeStatus()}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='card-footer '>
            <div className='float-end'>
              <ChangeBtn />
              <button className='btn btn-secondary'>Discard</button>
            </div>
          </div>
        </div>

        {/* <div className='card'>
          <div className='card-header '>
            {' '}
            <span className='h1 my-auto'>Notification</span>{' '}
          </div>
          <div className='card-body'>
            <table className='table'>
              <thead>
                <tr>
                  <th scope='col'> </th>
                  <th scope='col'>E-Mail</th>
                  <th scope='col'>In App</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope='row'>New Games</th>
                  <td>
                    <input type='checkbox' aria-label='Checkbox for following text input' />
                  </td>
                  <td>
                    <input type='checkbox' aria-label='Checkbox for following text input' />
                  </td>
                </tr>
                <tr>
                  <th scope='row'>Complated Games</th>
                  <td>
                    <input type='checkbox' aria-label='Checkbox for following text input' />
                  </td>
                  <td>
                    <input type='checkbox' aria-label='Checkbox for following text input' />
                  </td>
                </tr>
                <tr>
                  <th scope='row'>General Notifications</th>
                  <td>
                    <input type='checkbox' aria-label='Checkbox for following text input' />
                  </td>
                  <td>
                    <input type='checkbox' aria-label='Checkbox for following text input' />
                  </td>
                </tr>
              </tbody>
            </table>
            <button className='btn btn-primary float-end'> Save Changes </button>
          </div>
        </div> */}
        <div className='card'>
          <div className='card-header'>
            {' '}
            <span className='text-center my-auto h1'>Reset Password</span>{' '}
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-10 text-center mx-auto '>
                <h2>
                  Current Password Is: <h1>***</h1>
                </h2>
                <div
                  id='deneme1'
                  className=' d-none d-flex align-items-center bg-light-warning rounded p-5 mb-7 col-10 mx-auto border border-4 border-warning border-dashed'
                >
                  <div className=' col-12 text-center'>
                    <span className='h4 text-center'>
                      You're gonna reset this user's password are you sure ?
                    </span>{' '}
                    <br />
                    <button
                      className='btn btn-secondary me-5 mt-5'
                      onClick={() => {
                        Discard()
                      }}
                    >
                      {' '}
                      Discard{' '}
                    </button>
                    <button
                      className='btn btn-primary mt-5 '
                      onClick={() => {
                        Confirm()
                      }}
                    >
                      {' '}
                      Confirm{' '}
                    </button>
                  </div>
                </div>
                <div
                  id='deneme2'
                  className=' d-none d-flex align-items-center bg-light-success rounded p-5 mb-7 col-10 mx-auto border border-success border-4 border-dashed'
                >
                  <div className='col-12 text-center'>
                    <span id='first' className='h4 text-center'>
                      Password has been resteded
                    </span>{' '}
                    <br />
                    <div id='second' className='h6 text-center mt-10 text-primary'>
                      Current Password{' '}
                      <span className='text-dark'>(Please Share This paasword with user)</span> :
                    </div>{' '}
                    this.password
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <button
                  id='button'
                  className='btn btn-primary'
                  onClick={() => {
                    ResetPassword()
                  }}
                >
                  {' '}
                  Reset Password{' '}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExamplePage
