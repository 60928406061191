import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { PageLink } from '../../../_metronic/layout/core';
import Content from './Content';
import Games from './Users';
import '../../style/CustomCSS.css'
import Users from './Users';

  
function HelpPageLanding() {
  return (
    <div className=' card pt-3 pb-3'>
    <Tabs
      variant="pills"
      defaultActiveKey="Content"
      id="fill-tab-example"
      className="m-5 justify-content-center"
    >
      <Tab eventKey="Content" title="Content">
        <Content/>
      </Tab>
      <Tab eventKey="User" title="User">
      <Users/>
      </Tab>
      
      
    </Tabs>
    </div>
  );
}

export default HelpPageLanding;