import {forwardRef, useState} from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import {Interface} from 'readline'
import {Box, Divider, Grid, TextField} from '@mui/material'
import axios from 'axios'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {SnackbarProvider, useSnackbar} from 'notistack'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})
interface Props {
  setOpenGroupModal: any
  openGroupModal: any
  refresh?: any
  setRefresh?: any
}

function AddGroupModal({setOpenGroupModal, openGroupModal, refresh, setRefresh}: Props) {
  const {enqueueSnackbar} = useSnackbar()
  const intl = useIntl()
  const LoginState = useSelector((state: any) => state.Login)

  const [groupName, setGroupName] = useState('')
  const [Explanation, setExplanation] = useState('')
  const [loading, setLoading] = useState<any>(false)

  const handleClickOpen = () => {
    setOpenGroupModal(true)
  }

  const handleClose = () => {
    setExplanation('')
    setGroupName('')
    setOpenGroupModal(false)
  }

  function handleSave() {
    setLoading(true)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/groupCreate`,
        {
          name: groupName,
          explanation: Explanation,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        setRefresh(!refresh)
        setLoading(false)
        handleClose()
        enqueueSnackbar( `Successfully saved`, {variant: 'success'})
      })
      .catch((err) => {
        setLoading(false)
        err.response.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              {variant: 'error'}
            )
      })
  }

  return (
    <div>
      <Dialog
        open={openGroupModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth='xs'
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle style={{fontWeight:"bold",fontSize:"26px"}}>{'Create User Group'}</DialogTitle>
        <Divider variant='middle' sx={{borderTop: '2px solid'}} />
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item xs={8} md={8} justifyContent={'center'} display={'flex'}>
                <TextField
                  id='outlined-basic'
                  label='Name'
                  fullWidth
                  variant='outlined'
                  placeholder='36 character max'
                  onChange={(e: any) => setGroupName(e.target.value)}
                  value={groupName}
                  inputProps={{
                    maxLength: 36,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} justifyContent={'center'} display={'flex'}>
                <Grid item xs={8} md={8}>
                  <TextField
                    id='outlined-basic'
                    label='Explanation'
                    placeholder='140 character max'
                    fullWidth
                    multiline
                    value={Explanation}
                    onChange={(e: any) => setExplanation(e.target.value)}
                    rows={3}
                    variant='outlined'
                    inputProps={{
                      maxLength: 140,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <Divider variant='middle' sx={{borderTop: '2px solid'}} />
        <DialogActions>
          <Button variant='contained' onClick={handleClose} color='inherit'>
            Discard
          </Button>
          <Button
            variant='contained'
            style={{marginRight: '40px'}}
            onClick={() => {
              handleSave()
              handleClose()
            }}
            disabled={loading || groupName === ''}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default function Snackbar(props: any) {
  return (
    <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} maxSnack={3}>
      <AddGroupModal {...props} />
    </SnackbarProvider>
  )
}
