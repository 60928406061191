/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { MostActiveWidget } from '../../widgets/uDashboardWidget/MostActiveWidget'
import {NrOfUsers} from '../../widgets/uDashboardWidget/NrOfUsers'
import { UserDashboardWidged } from '../../widgets/uDashboardWidget/UserDashboardWidged'
import { NrGroupUser } from '../../widgets/uDashboardWidget/NrGroupUser'


const DashboardPage: FC = () => (
  
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-12'>
      <div className='col-xl-7'>
        <UserDashboardWidged
          className=' mb-xl-8'
          chartColor='warning'
          chartHeight='200px'
          strokeColor='#99610F'
        />
      </div>
      <div className='col-xl-5 mt-10 mb-5'>
      <div className=''>
      <MostActiveWidget className='card-xl-stretch' />
      </div>
      </div>
    </div>
    <div className='card '>
      <div className='row d-flex col-xl-12'>
      <div className='col-xl-6 mt-10 mr-5'>
        <NrOfUsers/>
      </div>
      <div className='col-xl-6 ml-5 mt-10'>
        <div className=' p-5'>
          <NrGroupUser/>
        </div>
      </div>
      </div>
    </div>
    {/* end::Row */}
    {/* end::Row */}
  </>
)

const UDashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    
    <>
      {/* <PageTitle breadcrumbs={[]}>User Dashboard</PageTitle> */}
      <DashboardPage />
    </>
    
  )
}

export {UDashboardWrapper}
