import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    loginRequest: ['body'],
    loginSuccess: ['data'],
    loginFailure: ['error'],
    logout: [],
    clearRedux: [],
    getUserRedux: ['userData'],
    getUserReduxRequest: [],
    clearState: [],
    refreshTokenRequest: ['refreshToken'],
    refreshTokenSuccess: ['newAccessToken'],
    refreshTokenFailure: ['error'],
})
export const LoginTypes = Types
export default Creators
/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({



    loginData: null,
    loginFetching: false,
    loginErrorMessage: '',
    loginError: false,
    customerProfile:null,
    tokenChange:false


})
/* ------------- Reducers ------------- */

export const loginRequest = (state, action) => {
    return state.merge({
        loginData: null,
        loginErrorMessage: '',
        loginError: false,
    })
}
export const loginSuccess = (state, action) => {

    if(action.data.errorMessage === "GDPR_NOT_CONFIRMED"){
        return state.merge({
            loginData: "GDPR_NOT_CONFIRMED",
            loginError: false,
        })  
    }
    if(action.data?.errorMessage){
        return state.merge({
            loginData: null,
            loginErrorMessage: action.data?.errorMessage,
            loginError: false,
        })  
    }
    return state.merge({
            loginData: action.data,
            loginErrorMessage :action.data.errorMessage
    })  
}
export const logout = (state, action) => {
    return state.merge({
        loginData: null
    })
}
export const clearState = (state, action) => {
    return state.merge({
        loginData: null,
        loginErrorMessage:"",
        loginError:"",
})  
}

export const loginFailure = (state, action) => {
    return state.merge({
        loginData: action.data,
        loginErrorMessage: action.error.text,
        loginError: true,
    })
}
export const refreshTokenRequest = (state) => {
    return state.merge({
       tokenChange:false
      })
}

export const refreshTokenSuccess = (state, action) => {
  return state.merge({
    loginData: {
      ...state.loginData,
      access_token: action.newAccessToken, // access_token güncellenir
    },
    tokenChange:true
  })
}

export const refreshTokenFailure = (state, action) => {
  return state.merge({
      loginData: null,
      loginErrorMessage: action.error,
      loginError: true,
  })
}
export const getUserRedux = (state, action) => {
    return state.merge({
        customerProfile: action.userData,
      })
}
export const getUserReduxRequest = (state) => {
    return state;
}
export const clearRedux = (state, action) => {
    return state;
}
export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOGIN_REQUEST]: loginRequest,
    [Types.LOGIN_SUCCESS]: loginSuccess,
    [Types.LOGIN_FAILURE]: loginFailure,
    [Types.LOGOUT]: logout,
    [Types.CLEAR_REDUX]: clearRedux,
    [Types.CLEAR_STATE]: clearState,
    [Types.REFRESH_TOKEN_REQUEST]: refreshTokenRequest,
    [Types.REFRESH_TOKEN_SUCCESS]: refreshTokenSuccess,
    [Types.REFRESH_TOKEN_FAILURE]: refreshTokenFailure,
    [Types.GET_USER_REDUX]: getUserRedux,
    [Types.GET_USER_REDUX_REQUEST]:getUserReduxRequest
})