/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, forwardRef, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { ID, initialListView, KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Dropdown, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { FilterTable } from './FilterTable'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Slide,
  SpeedDial,
  SpeedDialAction,
  Tooltip,
  Typography,
  darken,
  lighten,
  styled,
} from '@mui/material'
import CustomerCreateModal from './Components/CustomerAddModal'
import { useNavigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import Loading from '../../../_metronic/layout/components/Loading'
import {
  DataGridPro,
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { enqueueSnackbar } from 'notistack'
import CheckIcon from '@mui/icons-material/Check'
import BlockIcon from '@mui/icons-material/Block'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import CustomerAddModal1 from './Components/CustomerAddModal1'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { TransitionProps } from '@mui/material/transitions'

type Props = {
  id?: ID
}
const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const CustomerList: FC<Props> = ({ }) => {
  const LoginState = useSelector((state: any) => state.Login)
  const customerProfile = useSelector((state: any) => state.Login.customerProfile)

  useEffect(() => {
    let role = customerProfile.isSystemAdmin
    setisAdmin(role)
    /*     const ax = axios.create({headers: {Accept: 'application/json'}})

    function test() {
      const res = ax
        .get(`${process.env.REACT_APP_API_URL}/user/getUserWithJWT`, {
          headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
        })
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setisAdmin(response.data.isSystemAdmin)
        })
    }
    test() */
  }, [])
  const [isAdmin, setisAdmin] = useState<any>(3)

  const [first, setfirst] = useState(false)

  const [isLoading, setisLoading] = useState(false)

  const dispatch = useDispatch()

  const navigate = useNavigate()
  let roleId = [0]
  const [userRole, setUserRole] = useState<any>([])

  useEffect(() => {
    let role = customerProfile.role
    setUserRole(role)
    /*  async function test2() {
      axios
        .get(`${process.env.REACT_APP_API_URL}/user/getUserWithJWT`, {
          headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
        })
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setUserRole(response.data.role)
        })
    }
    test2() */
  }, [])

  let roleIsValidBasicInfo = userRole?.some((role: any) => roleId.includes(role.roleId))
  let roleIdBasicInfoEdit = [0]
  let roleIsValidBasicInfoEdit = userRole?.some((role: any) =>
    roleIdBasicInfoEdit.includes(role.roleId)
  )

  //States
  const [rows, setRows] = useState<any>([])
  const [rowCount, setRowCount] = useState(0)
  const [loadingExport, setLoadingExport] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [customerOptions, setCustomerOptions] = useState<any>({
    sortModel: [{ field: 'customerDate', sort: 'desc' }],
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    InternalEmployeeId: false,
  })
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [statusLoading, setStatusLoading] = useState(false)
  const [customerStatus, setCustomerStatus] = useState(-1)
  const [selected, setSelected] = useState<any>([])


  //Yeni alan
  const StatusCell = ({ value }: { value: number }) => {
    const getStatusComponent = (status: number) => {
      if (status === 0) {
        {
          /* <span className='badge badge-light-secondary fw-bold fs-3'>Blocked</span> */
        }
        return (
          <Chip
            label='Blocked'
            variant='outlined'
            color='secondary'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 1) {
        /*   <span className='badge badge-light-success fw-bold fs-3'>Open</span> */
        return (
          <Chip
            label='Open'
            variant='outlined'
            color='success'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      return null
    }

    return <div style={{ height: '19px', width: '57px' }}>{getStatusComponent(value)}</div>
  }

  const PlanCell = ({ value }: { value: number }) => {
    const getPlanComponent = (plan: number) => {
      if (plan === 1) {
        return (
          <Chip
            label='Demo'
            variant='outlined'
            sx={{ overflow: 'visible', maxWidth: '180px', fontSize: '13px', color: "#707070" }}
          />
        )
      }
      if (plan === 2) {
        return (
          <Chip
            label='Starter'
            variant='outlined'
            color='primary'
            sx={{ overflow: 'visible', maxWidth: '180px', fontSize: '13px' }}
          />
        )
      }
      if (plan === 3) {
        return (
          <Chip
            label='Challenger'
            variant='outlined'
            color='secondary'
            sx={{ overflow: 'visible', maxWidth: '180px', fontSize: '13px' }}
          />
        )
      }
      if (plan === 4) {
        return (
          <Chip
            label='Pro'
            variant='outlined'
            color='error'
            sx={{ overflow: 'visible', maxWidth: '180px', fontSize: '13px' }}
          />
        )
      }
      return null
    }

    return (
      <div
        style={{
          height: '19px',
          width: '57px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {getPlanComponent(value)}
      </div>
    )
  }

  const [status] = useState([
    { value: 0, label: 'Blocked' },
    { value: 1, label: 'Open' },
    { value: 2, label: 'Deleted' },
  ])
  const [plans, setPlans] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/customer/getSubscriptionList`, {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      })
      .then((res) => {
        setPlans(res.data)
      })
      .catch((err) => { })
  }, [])

  const columns: any = [
    {
      field: 'customerId',
      headerName: 'ID',
      width: 60,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value?.toFixed(0),
    },
    { field: 'title', headerName: 'CUSTOMER', width: 200 },
    {
      field: 'numberOfUsers',
      headerName: 'USERS',
      width: 100,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'subscriptionId',
      headerName: 'PLAN',
      width: 180,
      type: 'singleSelect',
      valueOptions: plans.map((option: any) => ({
        value: option.subscriptionId,
        label: option.name,
      })),
      renderCell: (params: GridCellParams) => <PlanCell value={params.value as number} />,
    },

    {
      field: 'createdDate',
      headerName: 'JOINED DATE',
      width: 200,
      type: 'dateTime',
      valueGetter: ({ value }: { value: Date }) => value && new Date(value),
    },
    {
      field: 'demoDate',
      headerName: 'DEMO DATE',
      width: 190,
      type: 'dateTime',
      valueGetter: ({ value }: { value: Date }) => value && new Date(value),
    },
    {
      field: 'customerDate',
      headerName: 'CUSTOMER DATE',
      width: 190,
      type: 'dateTime',
      valueGetter: ({ value }: { value: Date }) => value && new Date(value),
    },
    {
      field: 'expireDate',
      headerName: 'EXPIRE DATE',
      width: 190,
      type: 'dateTime',
      valueGetter: ({ value }: { value: Date }) => value && new Date(value),
    },
    { field: 'masterAdminNameSurname', headerName: 'MASTER ADMIN', width: 200 },
    { field: 'masterAdminEmail', headerName: 'MASTER ADMIN EMAIL', width: 220 },
    {
      field: 'statusId',
      headerName: 'STATUS',
      width: 150,
      type: 'singleSelect',
      valueOptions: status.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      renderCell: (params: GridCellParams) => <StatusCell value={params.value as number} />,
    },
  ]

  //add customer Edit modal start
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState<any>(false)
  const [openAddCustomerModal, setOpenAddCustomerModal] = useState<any>(false)
  const [hoverButtonAddCustomer, setHoverButtonAddCustomer] = useState(false)
  const [hoverButtonEditCustomer, setHoverButtonEditCustomer] = useState(false)

  //addcustomerEdit modal end

  //warn modal
  const [warnModalContent, setWarnModalContent] = useState<any>('')
  const [successMessageWarnModal, setSuccessMessageWarnModal] = useState<any>('')
  useEffect(() => {
    if (customerStatus === -1) {
      return
    }
    if (customerStatus === 1) {
      //open
      //handleChangeStatus()
      setWarnModalContent(
        <Typography color='white'>
          You are making the customer’s status OPEN.
          <br />
          The customer and all the users of this customer will start to use the product.
          <br />
          Continue?
        </Typography>
      )
      setSuccessMessageWarnModal(`successfully saved`)
      handleClickWarnModalOpen()
    }
    if (customerStatus === 0) {
      //block
      setWarnModalContent(
        <Typography color='white'>
          This operation will block ALL the users and admins from Mobixa. They can not use Mobixa
          system at all.
          <br />
          For extra security, you must confirm the process.
          <br />
          Proceed?
        </Typography>
      )
      setSuccessMessageWarnModal(`successfully BLOCKED`)
      handleClickWarnModalOpen()
    }
    if (customerStatus === 2) {
      //block
      setWarnModalContent(
        <Typography color='white'>
          This operation will change the Customer’s status to DEMO.
          <br />
          Demo usage will be last in 15 days.
          <br />
          For extra security, you must confirm the process. Proceed?
        </Typography>
      )
      setSuccessMessageWarnModal(`The status has been changed to DEMO`)
      handleClickWarnModalOpen()
    }
  }, [customerStatus])

  const [openWarnModal, setOpenWarnModal] = useState(false)

  const handleClickWarnModalOpen = () => {
    setOpenWarnModal(true)
  }

  const handleClickWarnModalClose = () => {
    setOpenWarnModal(false)
    setTimeout(() => {
      setCustomerStatus(-1)
    }, 1000)
  }

  //warn modal end

  useEffect(() => {
    getCustomerList()
  }, [customerOptions, paginationModel, columnVisibilityModel, first])

  async function getCustomerList() {
    setIsLoadingData(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/customer/getCustomerList?page=${paginationModel.page + 1
        }&size=${paginationModel.pageSize}`,
        {
          customerOptions,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        const formattedRows = response.data.items.map((row: any) => ({
          customerId: row.customerId,
          title: row.title,
          numberOfUsers: row.numberOfUsers,
          subscriptionId: row.subscriptionId,
          statusId: row.statusId,
          customerDate: row.customerDate,
          createdDate: row.createdDate,
          demoDate: row.demoDate,
          expireDate: row.expireDate,
          masterAdminNameSurname: row.masterAdminNameSurname,
          masterAdminEmail: row.masterAdminEmail,
        }))

        setRows(formattedRows)
        setRowCount(response.data.total)
        setIsLoadingData(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
      })
  }

  async function handleChangeStatus() {
    setStatusLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/customer/changeCustomerStatus`,
        {
          customerId: selected[0],
          statusId: customerStatus,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        if (customerStatus === 1) {
          enqueueSnackbar(successMessageWarnModal, { variant: 'success' })
          setCustomerStatus(-1)
        }
        customerStatus !== 1 && enqueueSnackbar(successMessageWarnModal, { variant: 'success' })
        handleClickWarnModalClose()

        setStatusLoading(false)
        setfirst(!first)
        setSuccessMessageWarnModal('')
      })
      .catch((err) => {
        setStatusLoading(false)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <LoadingButton
          size='small'
          onClick={downloadExcel}
          endIcon={<FileDownloadIcon />}
          loading={loadingExport}
          loadingPosition='end'
          variant='outlined'
        >
          <span>Export</span>
        </LoadingButton>
      </GridToolbarContainer>
    )
  }
  async function downloadExcel() {
    setLoadingExport(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/customer/getCustomerList?isExcel=1`,
        {
          customerOptions,
          columnVisibilityModel,
        },
        {
          responseType: 'blob',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const excelBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const excelUrl = URL.createObjectURL(excelBlob)

        const link = document.createElement('a')
        link.href = excelUrl
        link.download = 'customerList.xlsx'
        link.click()
        URL.revokeObjectURL(excelUrl)
        setLoadingExport(false)
      })
      .catch((err) => {
        setLoadingExport(false)
      })
    //frontend excel download
    /*     const newData = rows.map((row: any) => {
      delete row.tableData
      return row
    })
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, 'row')
    //Buffer
    XLSX.write(workBook, {bookType: 'xlsx', type: 'buffer'})
    //Binary string
    XLSX.write(workBook, {bookType: 'xlsx', type: 'binary'})
    //Download
    XLSX.writeFile(workBook, 'Veri Listesi.xlsx') */
  }
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setCustomerOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
      return
    } */

    setCustomerOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelected(newSelectionModel)
  }
  const handleCellOneClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin


    const newSelectionModel: any = [params.id];
    setSelected(newSelectionModel);


  }
  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }

    navigate(`/administration/customers/profile?id=${params.id}`)
    /* setOpenEditUserModal(true) */
    /*  userData.filter((user: any) => user.userId === params.row.userId)
    setSelectedUserData(userData.filter((user: any) => user.userId === params.row.userId)) */
    /*  navigate(`/manage/users/detail?id=${params.id}`) */
    // Diğer işlemleri burada yapabilirsiniz
  }

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <div className={isAdmin === true ? '' : 'd-none'}>
        <div className={isAdmin === false ? 'text-center my-auoto h1 text-muted' : 'd-none'}>
          You have no permission to view This Page
          <div>
            <img src={toAbsoluteUrl('/media/auth/chart-graph-dark.png')} alt='' />
          </div>
        </div>
        <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
          <Box>
            Rows per page:
            <Select
              value={paginationModel.pageSize}
              onChange={handlePageSizeChange}
              variant='outlined'
              size='small'
              sx={{ ml: 1, mr: 1 }}
            >
              <MenuItem selected value={25}>
                25
              </MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Box>
          <Pagination
            count={totalPages}
            page={paginationModel.page + 1}
            showFirstButton
            showLastButton
            disabled={isLoadingData}
            size='small'
            onChange={handlePageChange}
          />
          <Box>
            Showing {startRow}-{endRow} of {rowCount} record
          </Box>
        </Box>
      </div>
    )
  }

  //speed dial start
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const handleCloseSpeedDial = () => {
    setSpeedDialOpen(false)
  }
  const handleOpenSpeedDial = () => {
    setSpeedDialOpen(true)
  }

  const filteredUsers = rows.filter((row: any) => selected?.includes(row.customerId))

  const actions: any = [
    filteredUsers[0]?.statusId === 1
      ? null
      : { icon: <CheckIcon style={{ color: 'black' }} />, name: 'Open', value: 1 },
    filteredUsers[0]?.statusId === 0
      ? null
      : { icon: <BlockIcon style={{ color: 'black' }} />, name: 'Block', value: 0 },
    /*     filteredUsers[0]?.statusId === 2
          ? null
          : {icon: <Typography style={{color: 'black'}}>15d</Typography>, name: 'Demo', value: 2}, */
    /*{icon: <DeleteIcon style={{color: 'black'}} />, name: 'Delete', value: 2},
    {icon: <DeleteForeverOutlinedIcon style={{color: 'black'}} />, name: 'Erased', value: 3}, */
  ]
  //speed dial end

  return (
    <>
      <div
        style={{
          display: 'flex',
          height: 40,
          width: '80%',
          /* marginBottom: '5px', */
          marginTop: '-20px',
          cursor: 'default',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
          Manage - Customer List
        </Typography>
      </div>
      {selected.length > 0 ? (
        <div className='border-0'>
          {/* begin::Card toolbar */}
          <div className='w-50 float-end'>
            <div style={{ marginTop: '-60px', marginRight: 80 }} className='d-flex justify-content-end '>
              <Box
                display='flex'
                justifyContent='flex-end'
                alignItems='center'
                alignContent='center'
                sx={{ height: 50 }}
              >
                {selected.length === 1 && (
                  <Tooltip
                    arrow
                    title='Edit'
                    placement='top'
                    onMouseEnter={() => {
                      setHoverButtonEditCustomer(true)
                    }}
                    onMouseLeave={() => {
                      setHoverButtonEditCustomer(false)
                    }}
                    style={{ background: hoverButtonEditCustomer ? '#D5D5D5 ' : 'white' }}
                  >
                    <IconButton
                      aria-label='edit'
                      onClick={() => {
                        /*   setshowEdit(true)
                      setselectedQuestion(selectQuestion) */
                        navigate(`/administration/customers/profile?id=${selected[0]}`)
                        /* localStorage.setItem('customerProfile', JSON.stringify(selected[0]))
                        window.open(`/administration/customers/profile`, '_self') */
                      }}
                      size='small'
                      sx={{ background: 'white' /* , marginRight: '7px' */ }}
                    >
                      <EditIcon
                        sx={{ fontSize: '30px', color: hoverButtonEditCustomer ? 'black' : '' }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip arrow title='Change Status' placement='top'>
                  <SpeedDial
                    ariaLabel='SpeedDial openIcon example'
                    style={{ transform: 'scale(0.73)', marginTop: '55px' }}
                    icon={
                      speedDialOpen ? (
                        <ExpandMoreIcon fontSize='large' />
                      ) : (
                        <SettingsIcon fontSize='large' />
                      )
                    }
                    openIcon={<DeleteIcon fontSize='large' />}
                    onOpen={handleOpenSpeedDial}
                    onClose={handleCloseSpeedDial}
                    /* FabProps={{color: 'inherit'}} */
                    FabProps={{
                      style: {
                        backgroundColor: 'white', // Özel arka plan rengi
                        color: '#00000099', // Özel yazı rengi
                      }
                    }}
                    open={speedDialOpen}
                    direction='down'
                  >
                    {actions.map((action: any) =>
                      action === null ? null : (
                        <SpeedDialAction
                          style={{ transform: 'scale(0.70)' }}
                          delay={1000}
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={action.name}
                          onClick={() => setCustomerStatus(action.value)}
                        />
                      )
                    )}
                  </SpeedDial>
                </Tooltip>
              </Box>
            </div>
            {/*  <div
              style={{marginTop: '-80px'}}
              className='d-flex justify-content-end align-items-center'
            >
              <div className='fw-bold me-5'>
                <span className='me-2'>{selected.length}</span> Selected
              </div>

              <Dropdown>
                <Dropdown.Toggle variant='primary' id='dropdown-basic' onChange={handle}>
                  Change Status
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='#/action-1'>Active</Dropdown.Item>
                  <Dropdown.Item href='#/action-2'>Passive</Dropdown.Item>
                  <Dropdown.Item href='#/action-3'>Delted</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
          </div>
        </div>
      ) : (
        <div className='border-0'>
          {/* begin::Card toolbar */}
          <div className='w-50 float-end'>
            <div
              style={{ marginTop: '-68px', marginRight: 80 }}
              className='d-flex justify-content-end'
              data-kt-user-table-toolbar='base'
            >
              <Box
                display='flex'
                justifyContent='flex-end'
                alignItems='center'
                alignContent='center'
                sx={{ height: 50 }}
                marginTop={1}
              >
                <Tooltip
                  arrow
                  title='Add New'
                  placement='top'
                  onMouseEnter={() => {
                    setHoverButtonAddCustomer(true)
                  }}
                  onMouseLeave={() => {
                    setHoverButtonAddCustomer(false)
                  }}
                  style={{ background: hoverButtonAddCustomer ? '#D5D5D5 ' : 'white' }}
                >
                  <IconButton
                    aria-label='delete'
                    onClick={() => setOpenAddCustomerModal(true)}
                    size='small'
                    sx={{ background: 'white', marginRight: '7px' }}
                  >
                    <AddIcon
                      sx={{ fontSize: '30px', color: hoverButtonAddCustomer ? 'black' : ' ' }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <div className={`card p-3`}>
          {
            //DataGrid start
          }
          <Box sx={{ height: 600, width: '100%' }}>
            <StyledDataGrid
              rows={rows || []}
              columns={columns || []}
              rowHeight={50}
              columnHeaderHeight={40}
              disableVirtualization
              checkboxSelection
              onCellDoubleClick={handleCellClick}
              onCellClick={handleCellOneClick}
              getRowId={(row) => row.customerId}
              disableRowSelectionOnClick
              sortingMode='server'
              onSortModelChange={handleSortModelChange}
              sortModel={customerOptions?.sortModel}
              onRowSelectionModelChange={handleSelectionModelChange}
              rowSelectionModel={selected}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
              filterMode='server'
              onFilterModelChange={onFilterChange}
              isRowSelectable={(params: GridRowParams) =>
                selected.length < 1 ? true : params.id === selected[0]
              }
              rowCount={rowCount}
              loading={isLoadingData}
              paginationModel={paginationModel}
              paginationMode='server'
              pagination={true}
              onPaginationModelChange={setPaginationModel}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
              }
              slots={{
                toolbar: CustomToolbar,
                pagination: CustomPagination,
              }}
              sx={{
                boxShadow: "none",
                border: "none",
                '& .MuiDataGrid-footerContainer': {
                  padding: '0px',
                  height: 30
                },

                '& .MuiDataGrid-cell:hover': {
                  /*    color: 'primary.main', */
                  cursor: 'pointer',
                },
                '&	.MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  color: '#A1A5B7',
                  fontSize: 12,
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
                },
              }}
            />
          </Box>
          {
            //dataGrid end
          }
        </div>
      )}

      <CustomerAddModal1
        openAddCustomerModal={openAddCustomerModal}
        setOpenAddCustomerModal={setOpenAddCustomerModal}
        plans={plans}
        roleIsValidBasicInfoEdit={roleIsValidBasicInfoEdit}
        setFirst={setfirst}
        first={first}
      />

      {/* <CustomerCreateModal
        setShow={setOpenAddCustomerModal}
        isUserLoading={openAddCustomerModal}
        setIsAdded={setIsAdded}
        setfirst={setfirst}
        first={first}
      /> */}
      {
        //warn modal
      }
      <Dialog
        open={openWarnModal}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: customerStatus === 1 ? '#0288D1' : '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            {customerStatus === 1 ? (
              <InfoOutlinedIcon fontSize='large' />
            ) : (
              <WarningAmberIcon fontSize='large' />
            )}{' '}
            {customerStatus === 1 ? 'Info' : 'Warning'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {warnModalContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleChangeStatus()
            }}
            style={{ color: 'white' }}
            disabled={statusLoading}
          >
            Yes
          </Button>
          <Button onClick={handleClickWarnModalClose} style={{ color: 'white' }}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CustomerList
