import * as React from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  Radio,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import Axios from 'axios'
import RefreshIcon from '@mui/icons-material/Refresh'
import CheckIcon from '@mui/icons-material/Check'
//warn modal
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from 'notistack'
import { Dialog } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { AddCategory } from '../../questions/AddQuestionComponenets/AddCategory'
import CloseIcon from '@mui/icons-material/Close';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  /* border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  }, */
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ height: 20 }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  /*  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)', */
  /*   flexDirection: 'row-reverse', */
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  /*   borderTop: '1px solid rgba(0, 0, 0, .125)', */
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})
interface Props {
  openCategoryModal: boolean
  setOpenCategoryModal: any
  setSelectQuestion: any
  selectQuestion: any
  company: any
  second: any
  setSecond: any
}

function SelectCategory(props: Props) {
  const {
    openCategoryModal,
    setOpenCategoryModal,
    selectQuestion,
    setSelectQuestion,
    company,
    second,
    setSecond,
  } = props
  const [Category, setCategory] = useState<any>('')
  const [hover, setHover] = useState('')
  const LoginState = useSelector((state: any) => state.Login)
  //modal open start

  const handleClose = () => {
    setCategory('')
    setOpenCategoryModal(false)
  }
  //modal open end

  const [categories, setCategories] = useState<any>([])

  const [getCategoriesLoading, setGetCategoriesLoading] = useState(false)
  const [saveCategoriesLoading, setSaveCategoriesLoading] = useState(false)
  const [first, setfirst] = useState(false)

  useEffect(() => {
    if (openCategoryModal) {
      setGetCategoriesLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/content/getAccessibleCategories`, {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        })
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setCategories(response.data.customerCategory)
          setGetCategoriesLoading(false)
        })
        .catch((error) => {
          error.response.data.detail.errorMessage
            ? enqueueSnackbar(`${error.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
            : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              { variant: 'error' }
            )
          setGetCategoriesLoading(false)
        })
    }
  }, [first, openCategoryModal])

  async function handleSave() {
    setSaveCategoriesLoading(true)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/content/changeQuestionsCategory`,
        {
          selectedQuestion: selectQuestion,
          selectedCategory: parseInt(Category),
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        enqueueSnackbar(
          `CATEGORY CHANGE  of ${selectQuestion.length} records has been done successfully`,
          { variant: 'success' }
        )
        setCategories(response.data.customerCategory)
        setSaveCategoriesLoading(false)
        setSecond(!second)
        setSelectQuestion([])
        handleClose()
      })
      .catch((error) => {
        enqueueSnackbar(`CATEGORY CHANGE  of${selectQuestion.length} records has been done error`, {
          variant: 'error',
        })
        setSaveCategoriesLoading(false)
      })
  }

  return (
    <>
      <Dialog
        open={openCategoryModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth='xl'
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle fontWeight={'bold'}>
          {'SELECT CATEGORY'}{' '}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className='d-flex modal-bodyy'
            style={{
              overflowY: 'scroll',
              overflowX: 'scroll',
              minHeight: '600px',
              height: '600px',
            }}
          >
            {getCategoriesLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              categories?.map((data: any) => (
                <>
                  <div>
                    {
                      //1.katman
                    }
                    <Accordion
                      style={{
                        marginRight: 20,
                        borderRadius: '10px 10px 5px 5px',
                        border: '1px solid #d5d5d5',
                        minWidth: '300px',
                      }}
                      defaultExpanded={true}
                    >
                      <AccordionSummary
                        /* expandIcon={
                                  <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                } */
                        style={{
                          background: '#D5D5D5',
                          borderRadius: '10px 10px 0px 0px',
                          padding: '0px 10px 0px 0px',
                          minHeight: '60px',
                        }}
                      >
                        <div className='d-flex ' style={{ alignItems: 'center', height: 20 }}>
                          {/*  <Radio
                            checked={Category == data.categoryId}
                            onClick={(e: any) => {
                              setCategory(e.target.value)
                              e.stopPropagation()
                            }}
                            value={data.categoryId}
                            name='category'
                            color='success'
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: '22px',
                              },
                              '&.Mui-checked': {
                                color: '#53b658',
                              },
                            }}
                          /> */}
                          <div
                            style={{
                              fontWeight: 'bold',
                              cursor: 'pointer',
                              fontSize: '12px',
                              marginLeft: '-4px',
                            }}
                          >
                            {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                              data.name.slice(1)}
                          </div>
                          <span
                            title='Number of available questions'
                            className='text-dark  ms-2'
                            style={{ fontSize: '10px', color: '#8e8e8e', fontWeight: 'bold' }}
                          >
                            ({data.questionCount})
                          </span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: '3px' }}>
                        {
                          //2. katman
                        }

                        {data?.children?.map((child: any) =>
                          child.children.length > 0 ? (
                            <>
                              <Accordion
                                style={{
                                  marginTop: '5px',
                                  marginBottom: '5px',
                                  boxShadow: 'none',
                                  borderRadius: '10px 10px 0px 0px',
                                }}
                                defaultExpanded={true}
                              >
                                <AccordionSummary
                                  sx={{
                                    margin: '0px!important',
                                    '.MuiAccordionSummary-content': {
                                      // AccordionSummary'nin içeriği için stil
                                      marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                    },
                                  }}
                                  style={{
                                    borderRadius: '10px 10px 0px 0px',
                                    padding: '0px 5px 0px 0px',
                                    minHeight: '20px',
                                    marginLeft: '0px!important',
                                    background: hover === child.categoryId ? '#e7e7e7' : '',
                                  }}
                                >
                                  <div
                                    className='d-flex'
                                    style={{
                                      alignItems: 'center',
                                      justifyItems: 'start',
                                      height: 20,
                                    }}
                                    onMouseEnter={(e: any) => {
                                      setHover(child.categoryId)
                                    }}
                                    onMouseLeave={(e: any) => {
                                      setHover('')
                                    }}
                                  >
                                    {/*    <Radio
                                      checked={Category == child.categoryId}
                                      onClick={(e: any) => {
                                        setCategory(e.target.value)
                                        e.stopPropagation()
                                      }}
                                      value={child.categoryId}
                                      name='category'
                                      color='success'
                                      sx={{
                                        '& .MuiSvgIcon-root': {
                                          fontSize: '22px',
                                        },
                                        '&.Mui-checked': {
                                          color: '#53b658',
                                        },
                                      }}
                                    /> */}
                                    <span
                                      className={'text-gray-800 d-flex'}
                                      style={{
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                        marginLeft: '-4px',
                                      }}
                                    >
                                      {child.name.charAt(0).toUpperCase() + child.name.slice(1)}
                                    </span>
                                    <span
                                      title='Number of available questions'
                                      className='text-dark text-muted ms-2'
                                      style={{
                                        fontSize: '10px',
                                        color: '#8e8e8e',
                                        fontWeight: 'normal',
                                      }}
                                    >
                                      ({child.questionCount})
                                    </span>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: '3px' }}>
                                  {child?.children?.map((child: any) => (
                                    <div id={child.categoryId} className='ms-0 mt-3 '>
                                      <div
                                        className='d-flex'
                                        style={{
                                          alignItems: 'center',
                                          height: 20,
                                          paddingLeft: 5,
                                          background: hover === child.categoryId ? '#e7e7e7' : '',
                                        }}
                                        onMouseEnter={(e: any) => {
                                          setHover(child.categoryId)
                                        }}
                                        onMouseLeave={(e: any) => {
                                          setHover('')
                                        }}
                                      >
                                        <Radio
                                          checked={Category == child.categoryId}
                                          onClick={(e: any) => {
                                            setCategory(e.target.value)
                                            e.stopPropagation()
                                          }}
                                          value={child.categoryId}
                                          name='category'
                                          color='success'
                                          /* size='small' */
                                          sx={{
                                            width: '30px',
                                            height: '30px',
                                            '& .MuiSvgIcon-root': {
                                              fontSize: '16px',
                                            },
                                            '&.Mui-checked': {
                                              color: '#53b658',
                                            },
                                          }}
                                        />
                                        <a
                                          className={'text-gray-800 d-flex '}
                                          style={{
                                            cursor: 'pointer',
                                            fontSize: '12px',
                                            alignItems: 'center',
                                            maxWidth: '210px',
                                            marginLeft: '-4px',
                                          }}
                                        >
                                          {child.name.charAt(0).toUpperCase().replace('🅜', '') +
                                            child.name.slice(1)}
                                        </a>

                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '20px',
                                          }}
                                        >
                                          <span
                                            title='Number of available questions'
                                            className='ms-2'
                                            style={{ color: '#8e8e8e', fontSize: '10px' }}
                                          >
                                            {`(${child.questionCount})`}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </AccordionDetails>
                              </Accordion>
                            </>
                          ) : null
                        )}
                        {
                          // 3. katman
                        }

                        {data?.children?.map((child: any) =>
                          child.children.length > 0 ? null : (
                            <div id={child.categoryId} className='ms-0 mt-3 '>
                              <div
                                className='d-flex border-1'
                                style={{
                                  alignItems: 'center',
                                  height: 20,
                                  paddingLeft: 4,
                                  background: hover === child.categoryId ? '#e7e7e7' : '',
                                }}
                                onMouseEnter={(e: any) => {
                                  setHover(child.categoryId)
                                }}
                                onMouseLeave={(e: any) => {
                                  setHover('')
                                }}
                              >
                                <Radio
                                  checked={Category == child.categoryId}
                                  onClick={(e: any) => {
                                    setCategory(e.target.value)
                                    e.stopPropagation()
                                  }}
                                  value={child.categoryId}
                                  name='category'
                                  color='success'
                                  /* size='small' */
                                  sx={{
                                    '& .MuiSvgIcon-root': {
                                      fontSize: '22px',
                                    },
                                    '&.Mui-checked': {
                                      color: '#53b658',
                                    },
                                  }}
                                />
                                <a
                                  className={'text-gray-800 d-flex'}
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    maxWidth: '210px',
                                    marginLeft: '-4px',
                                  }}
                                >
                                  {child.name.charAt(0).toUpperCase() + child.name.slice(1)}
                                </a>

                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '20px',
                                  }}
                                >
                                  <span
                                    title='Number of available questions'
                                    className='ms-2'
                                    style={{ color: '#8e8e8e', fontSize: '10px' }}
                                  >
                                    {`(${child.questionCount})`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
              ))
            )}
            {/* {products?.map((data: any) => (
  
                      {
                        //3. section
                      }
                      {data?.children?.map((child: any) =>
                        child.children.length > 0 ? null : (
                          
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
             
            ))} */}
          </div>
          <div>
            Not in the list? <AddCategory company={company} second={first} setSecond={setfirst} />
          </div>
        </DialogContent>
        <DialogActions style={{ width: "90%" }}>
          <Button variant='contained' color='inherit' onClick={handleClose}>
            Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            style={{ marginLeft: "1%" }}
            onClick={handleSave}
            disabled={saveCategoriesLoading} // Yükleme sırasında düğmeyi devre dışı bırak
            startIcon={saveCategoriesLoading ? <CircularProgress size={24} /> : null}
          >
            DONE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SelectCategory
