import { FC, useState, useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/tr'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DateRange } from '@mui/x-date-pickers-pro'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { DateTimePicker } from '@mui/x-date-pickers-pro';



import CssBaseline from '@mui/material/CssBaseline'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { start } from 'repl'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import axios from 'axios'
import { useSelector } from 'react-redux'

type Props = {
  time?: any
  dateTime?: any,
  setDateTime?: any,
  dateTimeEnd?: any,
  setDateTimeEnd?: any,
  setChanged?: any
  time1: any
}

const JourneyTimig: FC<Props> = ({
  time,
  time1,
  setDateTime,
  dateTime,
  setDateTimeEnd,
  dateTimeEnd,
  setChanged
}) => {
  
  /*   const [startTime, setStartTime] = useState<Dayjs>(dayjs().startOf('day'))
  const [endTime, setEndTime] = useState<Dayjs>(dayjs().endOf('day')) */

  /*   const [selectedDateRange, setSelectedDateRange] = useState<[Dayjs, Dayjs]>([
    dayjs(),
    dayjs().add(1, 'day'),
  ]) */

  /*   const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Dayjs>>([
    dayjs(),
    dayjs().add(1, 'day'),
  ]) */
  /* 
  useEffect(() => {
    const startDateTime: string =
      dates[0]?.format('YYYY-MM-DD') + 'T' + timeStart.format('HH:mm:ss.SSS[Z]')
    const endDateTime: string =
      dates[1]?.format('YYYY-MM-DD') + 'T' + timeEnd.format('HH:mm:ss.SSS[Z]')
  

  }, [dates]) */



  /* const dateee = new Date(time) */
  /*   console.log('timeFormatStart  : ', timeFormatStart)
  console.log('time : ', time?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))

  console.log("time1 : ", time1?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') ) */
  const LoginState = useSelector((state: any) => state.Login)

  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [serverTime, setServerTime] = useState("")
  async function handleGetTimeOrigin() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Game/journey/getDateTime`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((response) => {
        setServerTime(response.data.dateTime)
      })
      .catch((err) => {
        /*  console.log(err) */
       
      })
  }
  useEffect(() => {
    handleGetTimeOrigin()
  }, [])
  
  async function handleGetTime() {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Game/journey/getDateTime`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((response) => {
        setLoading(false)

        setDateTime(dayjs(response.data.dateTime).add(5, 'minute'))
        setServerTime(response.data.dateTime)
      })
      .catch((err) => {
        /*  console.log(err) */
        setLoading(false)
      })
  }
  async function handleGetTime1() {
    setLoading1(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Game/journey/getDateTime`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then((response) => {
        setLoading1(false)

        setDateTimeEnd(dayjs(response.data.dateTime).add(10, 'minute'))
        setServerTime(response.data.dateTime)
      })
      .catch((err) => {
        /*  console.log(err) */
        setLoading1(false)
      })
  }



  const mode = useThemeMode()
  const darkTheme = createTheme({
    palette: {
      mode: mode.mode == 'dark' ? 'dark' : 'light',
    },
  })
  return (
    <>
      {/*       <Modal size='lg' show={isUserLoading} onHide={handleClose}>
        <Modal.Header> */}

      {/*     </Modal.Header>
        <Modal.Body> */}

      <Grid container justifyContent={"center"} >
        <Grid container justifyContent={'space-between'} width={800}>
          <Grid xs={5} item>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span className='m-4 ' /* text-success */ style={{ fontSize: '26px', fontWeight: "bold", color: "#4CAF50" }}>
                Start
              </span>
            </div>
          </Grid>
          <Grid xs={1} item></Grid>
          <Grid xs={5} item>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span className='m-4 '/* text-danger */ style={{ fontSize: '26px', fontWeight: "bold", color: "#FF0000" }}>
                Stop
              </span>
            </div>
          </Grid>
          <Grid xs={5} item display={"flex"} flexDirection={"column"} justifyItems={"center"} alignItems={"center"} textAlign={"center"}>
            <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"end",height:52}}>
            {loading && <CircularProgress size={20} sx={{ color: 'primary', marginLeft: '8px' }} />}
            <Button
              variant='text'
              color='primary'
              onClick={() => {
                handleGetTime()
                setChanged(true)
              }
              }
              disabled={time}
              sx={{ textTransform: "none" }}
            >
              Set to now
            </Button>
            </div>
            <ThemeProvider theme={darkTheme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker ampm={false} disabled={time} minDateTime={dayjs(serverTime).add(4, 'minute')} sx={{
                  width: "100%",
                }} format="DD-MM-YYYY     HH:mm" value={dateTime} onChange={(newValue) => (setDateTime(newValue), setChanged(true))} />
              </LocalizationProvider>
            </ThemeProvider>
            {
              dateTime < dayjs(serverTime).add(4, 'minute') && !time &&
              <Typography color={"#FF0000"}>Can NOT be selected from a date in the past</Typography>
            }

          </Grid>
          <Grid xs={2}></Grid>
          <Grid xs={5} item display={"flex"} flexDirection={"column"} justifyItems={"center"} alignItems={"center"} textAlign={"center"}>
          <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"end",height:52}}>
            {loading1 && <CircularProgress size={20} sx={{ color: 'primary', marginLeft: '8px' }} />}
            <Button
              variant='text'
              color='primary'
              onClick={() =>
                {
                  handleGetTime1()
                   setChanged(true)
                }
              }
              disabled={time1}
              sx={{ textTransform: "none" }}
            >
              Set to now
            </Button>
            </div>
            <ThemeProvider theme={darkTheme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker ampm={false} disabled={time1} minDateTime={dayjs(dateTime).add(5, 'minute')} sx={{ width: "100%" }} format="DD-MM-YYYY     HH:mm" value={dateTimeEnd} onChange={(newValue) => (setDateTimeEnd(newValue), setChanged(true))} />
              </LocalizationProvider>
            </ThemeProvider>
            {
              dateTimeEnd < dayjs(dateTime).add(5, 'minute') && !time1 &&
              <Typography color={"#FF0000"}>Must be at least 5m later than the start time.</Typography>
            }
            {
               dateTimeEnd < dayjs(serverTime) && !time1 &&
              <Typography color={"#FF0000"}>Can NOT be selected from a date in the past.</Typography>
            }
          </Grid>
        </Grid>

        {/*   <Grid item xs={12} marginBottom={2}>
                <ThemeProvider theme={darkTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                      value={dates || undefined}
                      minDate={dayjs()}
                      disabled={time}
                      onChange={(newValue) => setDates(newValue)}
                      format='DD-MM-YYYY'
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </Grid> */}

        {/*   <Grid item xs={5.3}>
                <ThemeProvider theme={darkTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      ampm={false}
                      sx={{ width: "228.88px" }}
                      disabled={time}
                      minTime={dayjs()}
                      value={timeStart}
                      onChange={(newValue) => {
                        setTimeStart(newValue as Dayjs)
                      }}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </Grid>
              <Grid item xs={5.3}>
                <ThemeProvider theme={darkTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      ampm={false}
                      sx={{ width: "228.88px", marginLeft: "-3px" }}
                      disabled={time}
                      value={timeEnd}
                      onChange={(newValue) => {
                        setTimeEnd(newValue as Dayjs)
                      }}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </Grid> */}
      </Grid>



      {/* <div className='col-12 text-center mb-3'>
              <a
                onClick={() =>
                  time ? null : handleTodayButtonClick()
                }
                style={{width: '136px', cursor: 'pointer'}}
                className='m-4 fs-6'
              >
                Set to current time
              </a>
              
            </div> */}




      {/*        <div className='col-4'>
                  
                  <button
                    className='btn btn-primary m-4'
                    style={{width: '136px'}}
                    onClick={() => {
                      setTime(null)
                      setTime1(null)
                    }}
                  >
                    Reset
                  </button>
                </div> */}

      {/* <div className='col-12'>
                <DatePicker
                  format='MM/DD/YYYY HH:mm:ss'
                  value={value}
                  onChange={(e:any)=>setValue(e)}
                  range
                  plugins={[<TimePicker position='bottom' />, <DatePanel markFocused />]}
                />
              </div> */}

      {/*        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' disabled={time != null && time1 != null && time < time1 ? false : true} onClick={() => handleSave()}>
            Submit
          </Button>
        </Modal.Footer> */}
      {/*  </Modal> */}
    </>
  )
}

export default JourneyTimig
