import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Journeys from './JourneyList'
import { Typography } from '@mui/material'
// import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const VideoBreadcrumbs: Array<PageLink> = [
  {
    title: 'Journey',
    // path: '/apps/user-management/users',
    path: '/action/journey',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function JourneyPage() {
  const navigate = useNavigate()
  const [first, setfirst] = useState('')

  return (
    <div >
      {/* <PageTitle breadcrumbs={VideoBreadcrumbs}></PageTitle> */}
      <div style={{height:"40px"}}></div>
      <Journeys />
    </div>
  )
}

export default JourneyPage
