import axios from "axios"
import { useQuery } from "react-query"
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Chart from 'react-apexcharts'
import { Tab, Tabs } from "react-bootstrap";
import '../../style/CustomCSS.css'
type AppProps = {
    series: number[];
    labels: string[];
}
const ApexWidged = () => {
  const series = [4, 5, 6, 1, 5]; // New Content için haftalık ve aylık olarak "Question", "Picture", "Category", "Video", "KnowledgeCard", "MemoryCard" değerleri gelecek.
  const options = {
    
    labels: ["Question", "Picture", "Video", "KnowledgeCard", "MemoryCard"],
    colors:['#50CD89', '#1592E6', '#00D8FF','#8950FC','#ff758d'],
    legend: {
        offsetX: 0,
      offsetY: 45,
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,

    },
    
    chart: {
        foreColor: '#fffff',
        horizontalAlign: 'center',
    
    },
   
    
        theme: {
            monochrome: {
                enabled: false
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    horizontalAlign: 'center',
                    chart: {
                        width: "100%"
                    },
                    
                    
                }
            }
        ],
    };
    return (
        <div className="">
        <Tabs
        defaultActiveKey="Ocak"
        className="m-3 justify-content-end"
        variant= 'pills'>
        <Tab eventKey="Ocak" title="Ocak">
        <div className="donut ">
            <Chart options={options} series={series} type="donut" width="400" class=" " />
        </div>
        
        </Tab>
        <Tab eventKey="Month" title="Aralık">
        <div className="donut ">
            <Chart options={options} series={series} type="donut" width="400" class=" " />
        </div>
        
        </Tab>
        
      </Tabs>
      </div>
    );
}
export {ApexWidged}



