import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {NewPassword} from './NewPassword'
import axios from 'axios'
import {stepButtonClasses} from '@mui/material'
import {useSearchParams} from 'react-router-dom'

export function GDPR() {
  const [products, setProducts] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  searchParams.get('token')
  const [token, setToken] = useState(searchParams.get('token'))
  useEffect(() => {
    const ax = axios.create({headers: {Accept: 'application/json'}})

    function test() {
      const res = ax
        .get(`${process.env.REACT_APP_API_URL}/customer/getCustomerGDPR/${token}`)
        .then((response) => {
          setProducts(response.data)
        })
    }
    test()
  }, [])

  return (
    <>
      <div></div>
      <div className='text-center ' style={{height: '500px'}}>
        <span className='h5 ' style={{height: '500px'}}>
          <textarea
            style={{resize: 'none', height: '500px'}}
            placeholder={products}
            className='col-6  h-100 form-control form-control-solid overflow-auto'
            readOnly
          ></textarea>
        </span>
      </div>
    </>
  )
}
