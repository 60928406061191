/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import Dropzone from 'react-dropzone'
import {KTSVG, toAbsoluteUrl} from '../../../../src/_metronic/helpers'
import {Button, Checkbox, IconButton, TextField, Typography} from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddIcon from '@mui/icons-material/Add'


interface IQuestionAnswerText {
  mediaType?: number
  mediaUrl?: string
  description?: string
  isCorrect?: boolean
  questionAnswerId: number
}

interface IQuestionAnswerImg {
  id: number
  imagePath: string
  isCorrect: boolean
  questionId: number
}

let questionIndex: number = 2
let answerImgIndex: number = 2

type Props = {
  className: string
  setAnswerList: any
  setSelection: any
  selection: any
  setcorrectAnswerCount: any
  answerList?: any
  correctAnswerCount: any
  button?:any
}

const QuestionAnswerWidgetEdit: React.FC<Props> = ({
  className,
  setAnswerList,
  setSelection,
  selection,
  setcorrectAnswerCount,
  answerList,
  correctAnswerCount,
  button
}) => {
  /* const [answer, setAnswer] = React.useState<IQuestionAnswerText[]>([
    {questionAnswerId: 0, description: '', isCorrect: false},
  ]) */

  const [totalCorrectAnswer, setTotalCorrectAnswer] = useState<any>(0)

  /*  useEffect(() => {
    console.log("answerList: ", answerList)
    console.log("answer: ", answer)
    
  }, [answerList]) */

  const [answerImg, setAnswerImg] = React.useState<any[]>([
    {id: 0, imagePath: '', isCorrect: false, questionId: 0},
    {id: 1, imagePath: '', isCorrect: false, questionId: 0},
  ])
  const userAvatarImg = toAbsoluteUrl(`/media/svg/files/blank-image.svg`)
  const [currentTab, setCurrentTab] = React.useState(0)
  const [totalAnswer, setTotalAnswer] = React.useState(0)

  useEffect(() => {
    if (currentTab == 0) {
      setTotalCorrectAnswer(answerList?.filter((item: any) => item.isCorrect === true)?.length || 0)
      setTotalAnswer(questionIndex)
    }
    if (currentTab == 1) {
      setTotalCorrectAnswer(answerImg.filter((item) => item.isCorrect)?.length || 0)
      setTotalAnswer(answerImgIndex)
    }
  }, [questionIndex, answerImgIndex, currentTab,answerList])

  const onAddBtnClick = () => {
    setAnswerList((prevAnswers: any) => [
      ...prevAnswers,
      {
        mediaType: 0,
        mediaUrl: '',
        description: '',
        isCorrect: false,
        questionAnswerId: 0,
      },
    ])
    questionIndex++
  }
  const onImgAddBtnClick = () => {
    setAnswerImg([
      ...answerImg,
      {
        mediaType: 0,
        mediaUrl: '',
        description: '',
        isCorrect: false,
        questionAnswerId: 0,
      },
    ])
    answerImgIndex++
  }
  const onRemoveBtnClick = (index: number, ansType: string) => {
    if (ansType == 'txt') {
      const newAnswer = [...answerList]
      newAnswer.splice(index, 1)
      setAnswerList(newAnswer)
      questionIndex--
    } else if (ansType == 'img') {
      const newAnswer = [...answerImg]
      newAnswer.splice(index, 1)
      setAnswerImg(newAnswer)
      answerImgIndex--
    }
  }
  const changeDescription = (index: number, value: string) => {
    const newAnswer = [...answerList]
    newAnswer[index].description = value
    setAnswerList(newAnswer)
  }
  const changeIsCorrect = (index: number, value: boolean) => {
    if (currentTab == 0) {
      const newAnswer = [...answerList]
      newAnswer[index].isCorrect = value
      setAnswerList(newAnswer)
      setTotalCorrectAnswer(answerList.filter((item: any) => item.isCorrect).length)
    }
    if (currentTab == 1) {
      const newAnswerImg = [...answerImg]
      newAnswerImg[index].isCorrect = value
      setAnswerImg(newAnswerImg)
      setTotalCorrectAnswer(answerImg.filter((item) => item.isCorrect).length)
    }
  }
  const LENGTH = 10
  const clamp = (min: any, max: any, val: any) => Math.max(min, Math.min(val, max))
  const inputRefs = useRef<any>([])
  const handleKeyPress = (index: any) => () => {
    const nextIndex = clamp(0, answerList.length - 1, index + 1)
    inputRefs?.current[nextIndex]?.focus()
  }
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onAddBtnClick()
    }
  }
  const [answMulti, setanswMulti] = useState<any>(1)

  /*   useEffect(() => {
    setSelection(answMulti)
  }, [answMulti]) */
  useEffect(() => {
    setanswMulti(correctAnswerCount)
  }, [correctAnswerCount, answerList])

  useEffect(() => {
    if (selection > answerList?.filter((re: any) => re.isCorrect == true)?.length) {
      setSelection(answerList?.filter((re: any) => re.isCorrect == true)?.length)
    }
    if (answerList?.filter((re: any) => re.isCorrect == true)?.length === 1) {
      setSelection(1)
    }
  }, [answerList])

  // useEffect(() => {
  //   if (answMulti > 0 && answMulti !== correctAnswerCount) {
  //     setcorrectAnswerCount(answMulti)
  //   }
  //   setcorrectAnswerCount(correctAnswerCount)
  // }, [correctAnswerCount, answMulti])
  useEffect(() => {
    setcorrectAnswerCount(totalCorrectAnswer)
  }, [totalCorrectAnswer, answMulti, answerList])
  /* console.log("totalCorrectAnswer: ", totalCorrectAnswer)
  console.log("answMulti: ", answMulti)
  console.log("answer: ", answer) */
  const [doubleClick, setDoubleClick] = useState('')
  return (
    <div className={`card bg-ligt rounded-top  ${className}`}>
      <div
        style={{backgroundColor: '#181c32', height: '230px', overflowY: 'scroll'}}
        className='card-body rounded p-3'
      >
        {/* begin::Chart */}
        <div>
          {answerList?.map((item: any, index: any) => (
            <div
              key={item.questionAnswerId}
              className='bg-white rounded col-12 d-flex justify-content-between mb-3'
              onClick={(e) => setDoubleClick(item.questionAnswerId)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setDoubleClick('')
                }
              }}
            >
              <Checkbox
                checked={item.isCorrect}
                id={item.questionAnswerId.toString()}
                onChange={(a) => (changeIsCorrect(index, a.target.checked),button())}
                sx={{
                  '& .MuiSvgIcon-root': {fontSize: 28},
                  '&.Mui-checked': {
                    color: '#53b658',
                  },
                }}
              />
                  {!item.description && item.questionAnswerId !== doubleClick && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    width: '100%',
                    marginLeft: 10,
                    alignItems: 'center',
                  }}
                >
                  <Typography maxWidth={320} fontSize={14} style={{color: '#c8c8c8'}}>
                    Enter answer option…
                  </Typography>
                </div>
              )}
              {item.questionAnswerId !== doubleClick && item.description && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Typography fontFamily={'Atma'} maxWidth={375} fontSize={16}>
                    {item.description}
                  </Typography>
                </div>
              )}
               {item.questionAnswerId === doubleClick && (
                <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                  <TextField
                    onChange={(a) => (changeDescription(index, a.target.value),button())}
                    className=' form-control text-center mb-3 mb-lg-0 d-flex'
                    style={{fontFamily: 'Atma', backgroundColor: 'white', border: '0px !important'}}
                    placeholder='Enter answer option…'
                    variant='outlined'
                    color='primary'
                    name='name'
                    size='small'
                    fullWidth
                    autoFocus
                    value={item.description}
                    /* multiline
                    minRows={1}
                    maxRows={7} */
                    onKeyDown={handleKeyDown}
                    inputProps={{onKeyPress: handleKeyPress(index), maxLength: 240}}
                    /* inputRef={(ref: any) => (inputRefs.current[index] = ref)} */
                    sx={{
                      '& fieldset': {border: 'none'},
                      input: {
                        textAlign: 'start',
                        fontFamily: 'Atma',
                        fontSize: '16px',
                        color: 'black',
                        padding: 0,
                      },
                    }}
                  />
                </div>
              )}
              {/* <TextField
                value={item.description}
                onChange={(a) => changeDescription(index, a.target.value)}
                className=' form-control text-center mb-3 mb-lg-0 d-flex'
                style={{fontFamily: 'Atma', backgroundColor: 'white', border: '0px !important'}}
                placeholder='Enter answer option…'
                type='text'
                name='name'
                onKeyDown={handleKeyDown}
                inputProps={{onKeyPress: handleKeyPress(index)}}
                inputRef={(ref: any) => (inputRefs.current[index] = ref)}
                sx={{
                  '& fieldset': {border: 'none'},
                  input: {
                    textAlign: 'center',
                    fontFamily: 'Atma',
                    fontSize: '15px',
                    fontWeight: '12',
                  },
                }}
              /> */}

              <IconButton
                /*  type='button'
                className='btn btn-sm col-2 h1 my-auto b' */
                onClick={() => (onRemoveBtnClick(index, 'txt'),button())}
              >
                <DeleteForeverIcon color='error' fontSize='large' />
                {
                  //delete svg
                }
                {/* <span className=' svg-icon svg-icon-2x'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='25'
                    height='23.214'
                    viewBox='0 0 25 23.214'
                  >
                    <g id='trash-bin' transform='translate(-32 -48)'>
                      <rect
                        id='Rectangle_2203'
                        data-name='Rectangle 2203'
                        width='25'
                        height='4.787'
                        rx='2.394'
                        transform='translate(32 48)'
                        fill='#fa5794'
                      ></rect>
                      <path
                        id='Path_2378'
                        data-name='Path 2378'
                        d='M66.849,160a.423.423,0,0,0-.328.158.513.513,0,0,0-.112.374l1.447,15.221a.1.1,0,0,0,0,.013,2.739,2.739,0,0,0,2.623,2.555H83.187a2.739,2.739,0,0,0,2.621-2.555v-.013l1.445-15.222a.512.512,0,0,0-.112-.374.424.424,0,0,0-.328-.158Zm13.685,10.89a1.031,1.031,0,0,1,0,1.364.826.826,0,0,1-1.244,0l-2.457-2.693-2.458,2.693a.826.826,0,0,1-1.244,0,1.031,1.031,0,0,1,0-1.364l2.458-2.693L73.131,165.5a1.031,1.031,0,0,1,0-1.364.826.826,0,0,1,1.244,0l2.458,2.693,2.457-2.693a.826.826,0,0,1,1.244,0,1.031,1.031,0,0,1,0,1.364L78.077,168.2Z'
                        transform='translate(-32.333 -107.107)'
                        fill='#fa5794'
                      ></path>
                    </g>
                  </svg>
                </span> */}
              </IconButton>
            </div>
          ))}
        </div>
        {/* end::Chart */}
      </div>
      <div className='text-center' style={{backgroundColor: '#181c32'}}>
        <div className='btn mt-2'>
          <IconButton onClick={()=>(onAddBtnClick(),button())} style={{backgroundColor: 'white', border: 'none'}}>
            <AddIcon color='inherit' fontSize='large' />
          </IconButton>
        </div>
      </div>
      <div
        className={totalCorrectAnswer <= 1 ? 'd-none' : 'pt-5 p-2 border-top'}
        style={{
          backgroundColor: '#181c32',
          color: '#7E8299',
          fontFamily: 'Atma',
          fontSize: '24px',
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: 5,
        }}
      >
        Choose answ selection{' '}
        <div style={{paddingRight: 5, display: 'flex'}}>
        {[...Array(totalCorrectAnswer + 1)].map((e, i) => (
          <Button
          style={{
            color: '#7E8299',
            fontFamily: 'Atma',
            fontWeight: 'bold',
            fontSize: 27,
            marginTop: 3,
          }}
          sx={{minWidth: 35, height: 35}}
            className={
              i === 0 || i > 4
                ? 'd-none'
                : selection === i
                ? 'btn border border-primary border-4 rounded p-0  ms-2'
                : 'btn border  border-4 rounded p-0  ms-2'
            }
            onClick={() => {
              setSelection(i)
              button()
            }}
          >
            {i}
          </Button>
        ))}
        </div>
      </div>
      {/* <div className='d-flex text-center  justify-content-around rounded-bottom' style={{backgroundColor:'rgb(48, 55, 65)', marginTop:'1px'}}>
        <p className=' my-auto' style={{color:'white'}}>Show</p>
        <select
          style={{
            transform: 'scale(0.6)',
          }}
          className='h1 text-dark bg-light'
          name='show'
          id='show'
        >
          {[...Array(totalCorrectAnswer + 1)].map((e, i) => (
            <option value={`${i}`}>{i}</option>
          ))}
        </select>
        <p className=' my-auto' style={{color:'white'}}>of</p>
        <p className='text-success my-auto'>{totalCorrectAnswer}</p>
        <p className=' my-auto' style={{color:'white'}}>correct </p>
        <p className='text-danger my-auto'>{totalAnswer - totalCorrectAnswer}</p>
        <p className=' my-auto' style={{color:'white'}}>wrong </p>
        <p className='text-primary my-auto'>{totalAnswer}</p>
        <p className=' my-auto' s
        tyle={{color:'white'}}>total </p>
      </div> */}
    </div>
  )
}

export default QuestionAnswerWidgetEdit
