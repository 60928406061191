import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useRef, useState } from 'react'
import { Avatar, Box, Grid, TextField } from '@mui/material'
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from './canvasPreview'
import ReactCrop, { Crop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

export default function SelectImageCustomer(props: any) {
  const { selectImageModal, setSelectImageModal, mediaUrl, changeMediaUrl, changeFile } = props
  const handleClose = () => {
    setSelectImageModal(false)
  }

  const [cropper, setCropper] = useState<any>()
  const [contentImage, setContentImage] = useState<any>('/media/svg/image.svg')
  const [imgSrc, setImgSrc] = useState('/media/stock/image.png')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(16 / 9)

  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
    }
  }, [completedCrop, scale, rotate, imgRef.current, previewCanvasRef.current])

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }
  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }
      const image = new Image();
      blobUrlRef.current = URL.createObjectURL(blob);
      image.onload = () => {
        // Görüntü yüklendiğinde, yeniden boyutlandırma işlemi için resizeAndDownloadCanvas'ı çağır
        resizeAndDownloadCanvas(image, 1200, 400, function(resizedBlob) {
            // Yeniden boyutlandırılmış blob'u changeFile fonksiyonuna geçir
            changeFile(resizedBlob, URL.createObjectURL(resizedBlob));
        });
      };
      image.src = blobUrlRef.current;
      /*    hiddenAnchorRef.current!.href = blobUrlRef.current */
      /* hiddenAnchorRef.current!.click() */
      setContentImage(blobUrlRef.current)
      /*  console.log(' hiddenAnchorRef.current!.href ', hiddenAnchorRef.current!.href) */

      /* setCropData(blobUrlRef.current) */

      // Burada blob'u form verisine ekliyoruz
      /*       formData.append('files', blob, `${index+1}.png`) */
      /* changeFile(blob, blobUrlRef.current) */
      /* for (let [key, value] of formData.entries()) {
        console.log('asdas', key, 'asdas', value)
      } */
    },'image/png')
  }
  function resizeAndDownloadCanvas(image, width, height, callback) {
    // Yeni bir canvas oluşturun
    let resizedCanvas = document.createElement('canvas');
    let ctx = resizedCanvas.getContext('2d');
    resizedCanvas.width = width;
    resizedCanvas.height = height;

    // Görüntüyü yeniden boyutlandırılmış canvas'a çizin
    ctx.drawImage(image, 0, 0, width, height);

    // Yeniden boyutlandırılmış canvas'ı blob olarak alın
    resizedCanvas.toBlob(function(blob) {
        // Burada blob ile istediğiniz işlemi yapabilirsiniz
        callback(blob);
    }, 'image/png');
}
  useEffect(() => {
    if (mediaUrl?.length > 0) {
      setContentImage(mediaUrl)
    }
  }, [mediaUrl])

  const handleSave = () => {
    onCropClick()
    changeMediaUrl(contentImage)
    handleClose()
  }

  return (
    <div>
      <Button variant='text' size='large' onClick={() => setSelectImageModal(true)}>
        <Avatar variant='rounded' sx={{
          width: '150px', height: '50px', img: {
            objectFit: 'contain',
          },
          background:"black"
        }}>
          <img
            src={contentImage}
            onError={(e: any) => {
              e.target.src = '/media/icons/mortarboard.png' // Varsayılan resim yolunu burada belirtin
            }}
            style={{
              width: '100%',
              height: '100%',

            }}
          />
        </Avatar>
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={selectImageModal}
        maxWidth='lg'
        fullWidth
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          Customer Logo
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container justifyContent={'center'}>
            <Box
              width={500}
              height={500}
              padding={1}
              display={'flex'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              {!!imgSrc && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                  style={{
                    background:"gray"
                  }}
                >
                  <img
                    ref={imgRef}
                    alt='Crop me'
                    src={imgSrc}
                   /*  height='350px'
                    width='100%' */
                    style={{
                      objectFit: 'contain',
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
              {!!completedCrop && (
                <div className='col-2 align-content-center'>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      height: '100%',
                      /*  visibility: 'hidden', */
                    }}
                  />
                </div>
              )}
              <div>
                <label className='col-12 text-center mt-3'>
                  <div className='btn btn-primary'>
                    <span>Select Image</span>
                  </div>
                  <input
                    type='file'
                    accept='image/*'
                    name='profile_avatar'
                    /* accept='.png, .jpg, .jpeg' */
                    hidden
                    onChange={onSelectFile}
                  />
                </label>
              </div>
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' autoFocus onClick={handleSave}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
