/* eslint-disable jsx-a11y/anchor-is-valid */
import {createContext, FC, useEffect, useState} from 'react'
import {Outlet, Route, Routes, Navigate, useNavigate} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {LanguageDropdown} from './components/LanguageDropdown'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {useDispatch, useSelector} from 'react-redux'
import Login from './components/Login'
import NewPassword from './components/NewPassword'
import {NewUserPassword} from './components/NewUserPassword'
import {GDPR} from './components/GDPR'
/* import './Bacground.scss' */
import DefinePassword from './components/DefinePassword'
import axios from 'axios'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import {useMediaQuery, useTheme} from '@mui/material'

const AuthLayout = () => {
  const dispatch = useDispatch()


  const intl = useIntl()
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])
  const navigate = useNavigate()

  const handleNavigateHome = () => {
    navigate(`/login`)
  }

  const theme = useTheme()
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      {/* begin::Content */}
      <Grid container component='main' sx={{height: '100%'}}>
        <CssBaseline />
        <Grid
          container
          justifyContent={'center'}
          justifyItems={'center'}
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage: toAbsoluteUrl('/media/logos/demo2.png'),
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => (t.palette.mode === 'light' ? '#e4e6ef' : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          {!isXsScreen && (
            <Grid container justifyContent='center' alignItems='center' direction='column'>
              <Avatar
                sx={{width: '150px', height: '150px'}}
                src={toAbsoluteUrl('/media/logos/demo2.png')}
              />
              <Typography
                component={'h1'}
                variant='h6'
                fontWeight={'bold'}
                style={{color: '#ED1477'}}
              >
                MobixaPlus
              </Typography>
              <Typography
                component={'h1'}
                variant='h5'
                fontWeight={'bold'}
                marginTop={10}
                marginBottom={5} 
                color={"black"}
              >
                Make your learning goals easier to achieve
              </Typography>
              <Avatar
                sx={{width: '352px', height: '354px'}}
                src={toAbsoluteUrl('/media/loginBottomLogo.webp')}
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component='form' noValidate sx={{mt: 1}}>
              <Outlet />
            </Box>
          </Box>
        </Grid>
      </Grid>
     
    </>
  )
}
type Props = {
  setUser: (isUserLoading: any) => void
}
export const AuthPage: FC<Props> = ({setUser}) => {
  const [products, setProducts] = useState([])
  const [customer, setCustomer] = useState<any>([])
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    const ax = axios.create({headers: {Accept: 'application/json'}})

    function test() {
      const res = ax
        .get(`${process.env.REACT_APP_API_URL}/customer/getAllLoginActiveCustomers`)
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setProducts(response.data)
          setAuth(true)
        })
    }
    test()
  }, [])

  useEffect(() => {
    setCustomer(products?.map((item: any) => item?.title?.toLowerCase().replace(/\s/g, '')))
  }, [products])

  function text() {
    return customer?.map((item: any) => <Route path={item} element={<Login />} />)
  }
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        {text()}
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='/new-password/' element={<NewPassword />} />
        <Route path='/new-password/:token?' element={<NewPassword />} />
        <Route path='/define-password/' element={<DefinePassword />} />
        <Route path='/define-password/:token?' element={<DefinePassword />} />
        <Route path='/new-user-password/' element={<NewUserPassword />} />
        <Route path='/new-user-password/:token?' element={<NewUserPassword />} />
        <Route path='/gdpr' element={<GDPR />} />
        <Route path='/gdpr/:token?' element={<GDPR />} />
        <Route index element={<Login />} />
        <Route path='/*' element={<Login />} />
      </Route>
    </Routes>
  )
}

export default AuthPage
