import { Button, CircularProgress, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import Axios from 'axios'
import { FC, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import QuestionAnswerWidgetAdd from '../../widgets/CreateQuestionWidget/QuestionAnswerForAdd'
import TinyMCEWidget from '../../widgets/CreateQuestionWidget/TinyMCEWidget'
import FAQWidget from '../../widgets/shared/FAQWidget'
import { SelectCategory } from './AddQuestionComponenets/SelectCategory'
import { Time } from './AddQuestionComponenets/Time'
import AddIcon from '@mui/icons-material/Add'
import SelectContentNew from './AddQuestionComponenets/SelectContentNew'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { enqueueSnackbar } from 'notistack'

type Props = {
  company: any
  setfirst: any
  first: any
  isQuestionCreatedId: any
  showNew: any
}

const AddQuestionModal: FC<Props> = ({ company, setfirst, first, isQuestionCreatedId, showNew }) => {
  const [show, setShow] = useState(false)

  const handleShow = () => setShow(true)
  const [time, setTime] = useState<any>('Time')
  const [AnswerList, setAnswerList] = useState<any>([
    {
      mediaType: 0,
      mediaUrl: '',
      description: '',
      isCorrect: false,
      questionAnswerId: 0,
    },
    {
      mediaType: 0,
      mediaUrl: '',
      description: '',
      isCorrect: false,
      questionAnswerId: 1,
    },
  ])
  const [selection, setSelection] = useState<any>(1)
  const [CategoryId, setCategoryId] = useState<any>(undefined)
  const [question, setQuestion] = useState<any>('')
  const LoginState = useSelector((state: any) => state.Login)
  const [selectionTypeId, setselectionTypeId] = useState(1)
  const [correctAnswerCount, setcorrectAnswerCount] = useState<any>('')
  const [plainText, setplainText] = useState<any>('')
  const [duration, setduration] = useState<any>('')
  const [isAuto, setisAuto] = useState<any>('')
  const [questionTypeId, setquestionTypeId] = useState<any>(1)
  const [MediaUrl, setMediaUrl] = useState<any>('')
  const [file, setfile] = useState<any>('')
  const [mediaType, setMediaType] = useState<any>('')

  const initializeStates = () => {
    setTime('Time')
    setAnswerList([
      { mediaType: 0, mediaUrl: '', description: '', isCorrect: false, questionAnswerId: 0 },
      { mediaType: 0, mediaUrl: '', description: '', isCorrect: false, questionAnswerId: 1 },
    ])
    setSelection(1)
    setCategoryId(undefined)
    setQuestion('')
    // ... Diğer state'lerinizi de burada sıfırlayın
  }
  useEffect(() => {
    if (show) {
      initializeStates()
    }
  }, [show])

  const changeFile = (value: any, index: number, mediaType: number) => {
    setfile(value)
  }

  const changeMediaUrl = (value: string, index: number, mediaType: number, video?: any) => {
    setMediaUrl(value)
    setMediaType(mediaType)
    setDurationVideo(video)

  }

  const handleClose = () => {
    setMediaUrl('')
    setMediaType(0)
    setfile('')
    setShow(false)
  }

  useEffect(() => {
    /*  if (setSelection > 1) {
      setselectionTypeId(3)
    }
    if (setSelection <= 1) {
      setselectionTypeId(1)
    } */
    setselectionTypeId(selection)
  }, [selection])
  const [secconds, setsecconds] = useState<any>(60)
  useEffect(() => {
    if (time == 'Auto' || time == 'Time') {
      setisAuto(true)
      setsecconds(60)
    } else if (time != 'Auto' || time != 'Time') {
      setsecconds(time)
      setisAuto(false)
    }
  }, [time, show])
  const [questionId, setquestionId] = useState(0)
  const [button, setbutton] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  function handleSave() {
    // -----

    const bodyFormData = new FormData()
    bodyFormData.append('expectedAnswerCount', selectionTypeId?.toString())
    bodyFormData.append('correctAnswerCount', selection)
    bodyFormData.append('mediaUrl', MediaUrl)
    bodyFormData.append('htmlDescription', question)
    bodyFormData.append('answerCount', AnswerList.length)
    bodyFormData.append('answerTypeId', '1')
    bodyFormData.append('duration', secconds)
    bodyFormData.append('isAuto', isAuto)
    bodyFormData.append('categoryId', CategoryId)
    bodyFormData.append('questionTypeId', questionTypeId)
    bodyFormData.append('file', file)
    bodyFormData.append('description', plainText)
    bodyFormData.append('mediaType', mediaType)
    setSaveLoading(true)
    Axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/content/createQuestion`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${LoginState.loginData.access_token}`,
      },
    })
      .then((response) => {
        setfirst(!first)
        setSaveLoading(false)
        setquestionId(response.data.questionId)
        AnswerList.forEach((e: any) => {
          const form = new FormData()
          form.append('file', '')
          form.append('questionId', response.data.questionId)
          form.append('description', e.description)
          form.append('isCorrect', e.isCorrect)
          form.append('mediaUrl', '')
          form.append('mediaType', '')
          Axios.post(`${process.env.REACT_APP_API_URL}/content/createQuestionAnswer`, form, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${LoginState.loginData.access_token}`,
            },
          }).then((response) => {
            /* setquestionId(response?.data?.questionId) */
            setShow(false)
            setShowModal(true)
            setTimeout(() => {
              setShowModal(false)
            }, 2000)
          })
        })
      })
      .catch((err) => {
        setSaveLoading(false)
        err?.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })

    //yeniden editi açma
    setMediaUrl('')
    setMediaType(0)
    setfile('')
  }
  useEffect(() => {
    if (questionId != 0) {
      setTimeout(() => {
        isQuestionCreatedId(questionId)
      }, 2000)
    }
  }, [questionId])
  useEffect(() => {
    if (showNew === true) {
      setShow(true)
    }
  }, [showNew])

  useEffect(() => {
    if (
      (MediaUrl?.length > 1 || plainText?.length > 1 || file?.size > 2) &&
      CategoryId &&
      plainText !== "undefined" &&
      AnswerList.length > 1 &&
      correctAnswerCount >= 1 &&
      AnswerList.length > correctAnswerCount &&
      AnswerList.filter((re: any) => re.description == '').length <= 0
    ) {
      setbutton(false)
    } else {
      setbutton(true)
    }
    //------
  }, [MediaUrl, plainText, file, AnswerList, CategoryId, correctAnswerCount])
  const [showmodal, setShowModal] = useState(false)
  function closeModal() {
    setShowModal(false)
  }
  // category color control
  const [colorCategoryChange, setColorCategoryChange] = useState<any>('4px solid yellow')
  useEffect(() => {
    if (CategoryId > 0) {
      if (colorCategoryChange === '4px solid #ed1477') {
      } else {
        setColorCategoryChange('4px solid #50cd89')
        if (colorCategoryChange === '4px solid yellow') {
          setTimeout(() => {
            setColorCategoryChange('4px solid #ed1477')
          }, 2000)
        }
      }
    } else {
      setColorCategoryChange('4px solid yellow')
    }
  }, [CategoryId, colorCategoryChange])

  //video-image-text color control
  const [colorChange, setColorChange] = useState<any>('yellow')
  useEffect(() => {
    if (MediaUrl?.length > 1 || plainText?.length > 1 || file?.size > 2) {
      if (colorChange === '#ed1477') {
      } else {
        setColorChange('#50cd89')
        if (colorChange === 'yellow') {
          setTimeout(() => {
            setColorChange('#ed1477')
          }, 2000)
        }
      }
    } else {
      setColorChange('yellow')
    }
  }, [MediaUrl, plainText, file, colorChange])
  // answer color control
  const [colorAnswerChange, setColorAnswerChange] = useState<any>('yellow')
  useEffect(() => {
    if (
      AnswerList.length > 1 &&
      correctAnswerCount >= 1 &&
      AnswerList.length > correctAnswerCount &&
      AnswerList.filter((re: any) => re.description == '').length <= 0
    ) {
      if (colorAnswerChange === '#ed1477') {
      } else {
        setColorAnswerChange('#50cd89')

        setTimeout(() => {
          setColorAnswerChange('#ed1477')
        }, 2000)
      }
    } else {
      setColorAnswerChange('yellow')
    }
  }, [AnswerList, correctAnswerCount, colorAnswerChange])

  const [hoverButton, setHoverButton] = useState(false)
  const [accordionOpen, setAccordionOpen] = useState(false)
  const [durationVideo, setDurationVideo] = useState("")

  return (
    <>
      <Tooltip
        arrow
        title='Add New'
        placement='top'
        onMouseEnter={() => {
          setHoverButton(true)
        }}
        onMouseLeave={() => {
          setHoverButton(false)
        }}
        style={{ background: hoverButton ? '#D5D5D5 ' : 'white' }}
      >
        <IconButton
          aria-label='delete'
          onClick={handleShow}
          size='small'
          sx={{ background: 'white', marginRight: '7px' }}
        >
          <AddIcon sx={{ fontSize: '30px', color: hoverButton ? 'black' : ' ' }} />
        </IconButton>
      </Tooltip>

      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
        <Modal.Header style={{ padding: '2%' }} closeButton>
          <Modal.Title style={{ fontSize: '26px' }}>Create Question</Modal.Title>
        </Modal.Header>
        <Modal.Body className='m-0 p-0 pt-2'>
          <div className='card p-2'>
            <div
              className='card-header text-center justify-content-center align-items-center'
              style={{
                fontFamily: 'Atma',
                marginTop: '-3%',
                backgroundColor: '#ed1477',
                minHeight: '40px',
                padding: '0px',
                paddingTop: '5px',
                /* transition: '0.5s',
                border: colorCategoryChange, */
              }}
            >
              <SelectCategory company={company} setCategoryId={setCategoryId} />
            </div>

            <div
              className='card-body p-0  '
              style={{
                backgroundColor: /* colorChange */ 'white',
                marginTop: '-1px',
                transition: '0.5s',
              }}
            >
              <div className='rounded-4' style={{ backgroundColor: '#181C32' }}>
                <div className='float-end mb-5 pt-1 pe-2 ' style={{ zIndex: 5 }}>
                  <Time Time={time} SetTime={setTime} customerId={company} durationVideo={durationVideo} />
                </div>
                <div
                  className='rounded m-2 d-flex align-items-center justify-content-center'
                  style={{ width: '473px' }}
                >
                  {/* <SelectContent
                    customerId={company}
                    mediaType={mediaType}
                    mediaUrl={MediaUrl}
                    changeMediaUrl={setMediaUrl}
                    changeMediaType={setmediaType}
                    changeFile={setfile}
                  /> */}
                  <SelectContentNew
                    changeFile={changeFile}
                    changeMediaUrl={changeMediaUrl}
                    iconDisabled={true}
                    mediaUrl={MediaUrl}
                    mediaType={mediaType}
                  />
                </div>
              </div>

              <div className='col-12' style={{ padding: '0px' }}>
                <TinyMCEWidget setquestion={setQuestion} setplainText={setplainText} mediaType={mediaType} />
              </div>
            </div>
          </div>

          <div
            className=' rounded  rounded-top-0 '
            style={{
              backgroundColor: /* colorAnswerChange */ 'white',
              padding: '2px 6px 0px 6px',
            }}
          >
            <div className='card-body rounded-top-0'>
              {/* <div
                className={
                  AnswerList.length > correctAnswerCount ? '' : 'alert alert-danger p-1 mb-1 fs-2 '
                }
              >
                {AnswerList.length > correctAnswerCount
                  ? ' '
                  : ' - En az bir yanlış cevap olmalıdır'}
              </div>
              <div
                className={
                  AnswerList.filter((re: any) => re.description == '').length <= 0
                    ? ''
                    : 'alert alert-danger p-1 mb-1 fs-2 '
                }
              >
                {AnswerList.filter((re: any) => re.description == '').length <= 0
                  ? ' '
                  : ' - Boş cevap olamaz'}
              </div>
              <div className={correctAnswerCount >= 1 ? '' : 'alert alert-danger p-1 mb-1 fs-2'}>
                {correctAnswerCount >= 1 ? ' ' : ' -En az 1 doğru cevap olmalıdır'}
              </div> */}
              <QuestionAnswerWidgetAdd
                className='col-12'
                setAnswerList={setAnswerList}
                answerList={AnswerList}
                setSelection={setSelection}
                selection={selection}
                setcorrectAnswerCount={setcorrectAnswerCount}
              />
            </div>
          </div>

          {/*  <div style={{height: 'auto',marginBottom:10}}>
            <FAQWidget title='Question' className='rounded' />
          </div> */}
        </Modal.Body>
        <Modal.Footer className='p-1 pt-5' style={{ width: '100%' }}>
          <Button style={{ textTransform: 'none' }} onClick={() => setAccordionOpen(!accordionOpen)}>
            <Typography fontSize={18} color={'primary'}>
              Learn
            </Typography>
            <ExpandMoreIcon
              fontSize='large'
              color='primary'
              style={{
                transform: accordionOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
              }}
            />
          </Button>
          <Button
            variant='contained'
            onClick={handleClose}
            style={{ marginLeft: 40 }}
            color='inherit'
          >
            Discard
          </Button>
          <Button
            variant='contained'
            disabled={button || saveLoading}
            onClick={handleSave}
            style={{ marginLeft: 10 }}
          >
            {saveLoading ? (
              <>
                <CircularProgress size={20} color='inherit' />
              </>
            ) : (
              'Save'
            )}
          </Button>
          <div style={{ height: 'auto', marginBottom: 10 }}>
            {accordionOpen && (
              <FAQWidget title='Question' className='rounded' accordionOpen={accordionOpen} />
            )}
          </div>
        </Modal.Footer>
      </Modal>

      <Modal centered show={showmodal} onHide={closeModal}>
        <Modal.Header className='bg-light-success border-0' closeButton></Modal.Header>
        <Modal.Body className='bg-light-success rounded-bottom'>
          <div className='text-center'>
            <span className='h3 text-success'>Question {questionId} successfully created.</span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default AddQuestionModal
