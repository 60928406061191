import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import React from "react";

export default function GoogleCaptchaWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const recaptchaKey: string | undefined =
  process.env.REACT_APP_RECAPTCHA
  const reCaptchaIsOn= process.env.REACT_APP_RECAPTCHA_IS_ON
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={reCaptchaIsOn === "true" ?( recaptchaKey ?? "NOT DEFINED" ): "NOT DEFINED"}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: "head",
        nonce: undefined,
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
}