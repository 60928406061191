import React, { FC, forwardRef, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import axios from 'axios'
import { ID, initialListView, } from '../../../_metronic/helpers'
import Nav from 'react-bootstrap/Nav'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import AddGroup from './JourneyAddGroup'
import { useIntl } from 'react-intl'
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Slide,
  TableCell,
  Tooltip,
  Typography,
  darken,
  lighten,
  styled,
} from '@mui/material'
import {
  DataGridPro,
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CloseIcon from '@mui/icons-material/Close'
import { TransitionProps } from '@mui/material/transitions/transition'
import { enqueueSnackbar } from 'notistack'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

type Props = {
  setAssignmentTypeOff: any
  journeyId: any
  journeyName: any
  groupList: any
  setUserList?: any
  userList?: any
  setCopyHover?: any
  copyHover?: any
  setGroupList?: any
  participantsUser?: any
  participantsUser1?: any
  privateAssignmentType?: any
  handleGroupLoading?: any
  setHandleGroupLoading?: any
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

const JourneyAddEditUserGroupModal: FC<Props> = ({
  setAssignmentTypeOff,
  groupList,
  journeyId,
  journeyName,
  setUserList,
  userList,
  setGroupList,
  participantsUser,
  participantsUser1,
  privateAssignmentType,
  handleGroupLoading,
  setHandleGroupLoading
}) => {
  const [groupName, setGroupName] = useState<any>('')

  /*   const handleClose = () => setShow(false)
  const handleShow = () => setShow(true) */
  const LoginState = useSelector((state: any) => state.Login)
  const [value, setValue] = React.useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const [userOptions, setUserOptions] = useState<any>({})
  const [selected, setSelected] = useState<any>([])

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  })
  const [rowCount, setRowCount] = useState(0)
  const [rows, setRows] = useState<any>([])
  const [allRows, setAllRows] = useState<any>([])
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    InternalEmployeeId: false,
  })
  const [isLoadingData, setIsLoadingData] = useState(false)

  const [userOptions1, setUserOptions1] = useState<any>({})
  const [selected1, setSelected1] = useState<any>([])
  const [paginationModel1, setPaginationModel1] = useState({
    page: 0,
    pageSize: 100,
  })
  const [rowCount1, setRowCount1] = useState(0)
  const [rows1, setRows1] = useState<any>([])
  const [allRows1, setAllRows1] = useState<any>([])
  const [columnVisibilityModel1, setColumnVisibilityModel1] = useState<GridColumnVisibilityModel>({
    InternalEmployeeId: false,
  })
  const [isLoadingData1, setIsLoadingData1] = useState(false)

  useEffect(() => {
    function test() {
      /*  setGroupListLoading(true) */
      axios
        .get(`${process.env.REACT_APP_API_URL}/user/getGroupListNoPagination`, {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        })
        .then((response) => {
          setGroupListFilter(response.data)
          /* setGroupListLoading(false) */
        })
        .catch((err) => {
          /*    setGroupListLoading(false) */
        })
    }
    test()
  }, [])

  const nameCell = (params: any) => {
    const { name, surname, /* group, */ avatar } = params.row
    return (
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {/*  <Avatar src={avatar} /> */}
        <Box marginLeft={'10px'}>
          <Typography>{name + ' ' + surname}</Typography>
          {/* {group.length > 0 && (
            <Typography color={'#6C757D'}>{group.split(',')[0].trim()}</Typography>
          )} */}
        </Box>
      </Box>
    )
  }
  const groupCell = (params: any) => {
    const { name, surname, group, avatar } = params.row
    return (
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Box marginLeft={'10px'}>
          {group.length > 0 && (
            <Typography color={'#6C757D'}>{group.split(',')[0].trim()}</Typography>
          )}
        </Box>
      </Box>
    )
  }

  const [selectedPages, setSelectedPages] = useState(0)



  const [groupListFilter, setGroupListFilter] = useState([])
  const columns: any = [
    /* {
      field: '__check__',
      headerName: ' ',
      headerAlign: 'center',
      sortable:false,
      width: 50,
      renderHeader: () => (
        <DropdownCheckboxHeader />
      ),
      // checkboxSelection özelliğini bu sütuna eklemeyi unutmayın
    }, */
    { field: 'name', headerName: 'NAME', align: 'start', width: 180, renderCell: nameCell },
    {
      field: 'group', headerName: 'GROUP', align: 'start', width: 160,
      type: 'singleSelect',
      selectable: false,
      valueOptions: groupListFilter.map((category: any) => ({
        value: category.groupId,
        label: category.groupName,
      })), renderCell: (params: any) => params.value,
    },
    { field: 'email', headerName: 'E-MAİL', align: 'start', width: 180 },
  ]

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
      return
    } */

    setUserOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setUserOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])

  const itemsPerPage = 50 // Her sayfada gösterilecek kullanıcı sayısı
  const [currentPage, setCurrentPage] = useState(1) // Şu anki sayfa numarası

  const getUsersForPage = (page: any) => {
    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return FilterGrouped.slice(startIndex, endIndex)
  }

  useEffect(() => {
    if (rows.length > 1) {
      setAllRows((prev: any) => {
        const userIds = prev.map((row: any) => row.id) // Önceki userId değerlerini al
        const newRows = rows.filter((row: any) => !userIds.includes(row.id)) // Yeni userId'leri öncekilerle karşılaştır

        return [...prev, ...newRows] // Yeni userId'leri ekleyerek setAllRows çağır
      })
    }
  }, [rows])





  const handleSelectionModelChange = (newSelectionModel: any) => {
    //allRows  a göre  selected yap
    setSelected(newSelectionModel)


  }

  /*  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
     // Tıklanan hücreye özgü işlemleri gerçekleştirin
     if (params.field === '__check__' || params.row.source === 'Mobixa') {
       return
     }
   } */
  const handleCellOneClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }
    const newSelectionModel = [...selected]; // Mevcut seçili satırların bir kopyasını oluşturun
    const selectionIndex = newSelectionModel.indexOf(params.id);

    if (selectionIndex === -1) {
      // Eğer tıklanan hücrenin ID'si şu anda seçili değilse, seçime ekleyin
      newSelectionModel.push(params.id);
    } else {
      // Eğer tıklanan hücrenin ID'si zaten seçiliyse, seçimden kaldırın
      newSelectionModel.splice(selectionIndex, 1);
    }

    setSelected(newSelectionModel);

  }
  useEffect(() => {
    getUserList()
  }, [userOptions, paginationModel, columnVisibilityModel])


  async function getUserList() {
    setIsLoadingData(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/getJourneyUnassignedUsers?journeyId=${journeyId}&page=${paginationModel.page + 1
        }&size=${paginationModel.pageSize}`,
        {
          userOptions: userOptions,
          columnVisibilityModel
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        const formattedRows = response.data.items?.map((row: any) => ({
          id: row.userId,
          avatar: row.avatarPath,
          name: row.name,
          surname: row.surname,
          email: row.email,
          userId: row.userId,
          InternalEmployeeId: row.InternalEmployeeId,
          role: row.role.map((item: any) => item.roleName).join(', '),
          createdDate: row.createdDate,
          group: row.group.map((item: any) => item.groupName).join(', '),
          statusId: row.statusId,
        }))
        setRows(formattedRows)
        setRowCount(response.data.total)
        setIsLoadingData(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
      })
  }



  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        {/*  <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ml: 1, mr: 1}}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box> */}
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }
  /*   useEffect(() => {
    if(value === "1"){
      getUserList()
      getUserList1()
    }
    }, [value]) */

  useEffect(() => {
    if (!privateAssignmentType) {
      setValue("2")
    }

  }, [privateAssignmentType])
  //#########################################################################
  //#########################################################################
  //#########################################################################
  //#region  userDataGrid 2

  const columns1: any = [

    { field: 'name', headerName: 'NAME', align: 'start', width: 180, renderCell: nameCell },
    {
      field: 'group', headerName: 'GROUP', align: 'start', width: 160,
      type: 'singleSelect',
      selectable: false,
      valueOptions: groupListFilter.map((category: any) => ({
        value: category.groupId,
        label: category.groupName,
      })), renderCell: (params: any) => params.value,
    },
    { field: 'email', headerName: 'E-MAİL', align: 'start', width: 180 },
  ]

  const handleSortModelChange1 = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setUserOptions1((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])

  const onFilterChange1 = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
      return
    } */

    setUserOptions1((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])
  const handleSelectionModelChange1 = (newSelectionModel: any) => {
    //allRows  a göre  selected yap
    setSelected1(newSelectionModel)

  }

  /*  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
     // Tıklanan hücreye özgü işlemleri gerçekleştirin
     if (params.field === '__check__' || params.row.source === 'Mobixa') {
       return
     }
   } */
  const handleCellOneClick1 = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }
    const newSelectionModel1 = [...selected1]; // Mevcut seçili satırların bir kopyasını oluşturun
    const selectionIndex1 = newSelectionModel1.indexOf(params.id);

    if (selectionIndex1 === -1) {
      // Eğer tıklanan hücrenin ID'si şu anda seçili değilse, seçime ekleyin
      newSelectionModel1.push(params.id);
    } else {
      // Eğer tıklanan hücrenin ID'si zaten seçiliyse, seçimden kaldırın
      newSelectionModel1.splice(selectionIndex1, 1);
    }

    setSelected1(newSelectionModel1);

  }
  useEffect(() => {
    getUserList1()
  }, [userOptions1, paginationModel1, columnVisibilityModel1])


  async function getUserList1() {
    setIsLoadingData1(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/getJourneyAssignedUsers?journeyId=${journeyId}&page=${paginationModel1.page + 1
        }&size=${paginationModel.pageSize}`,
        {
          userOptions: userOptions1,
          columnVisibilityModel1
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        const formattedRows = response.data.items?.map((row: any) => ({
          id: row.userId,
          avatar: row.avatarPath,
          name: row.name,
          surname: row.surname,
          email: row.email,
          userId: row.userId,
          InternalEmployeeId: row.InternalEmployeeId,
          role: row.role.map((item: any) => item.roleName).join(', '),
          createdDate: row.createdDate,
          group: row.group.map((item: any) => item.groupName).join(', '),
          statusId: row.statusId,
        }))

        setRows1(formattedRows)
        setRowCount1(response.data.total)
        setIsLoadingData1(false)

      })
      .catch((err) => {
        setIsLoadingData1(false)
      })
  }

  useEffect(() => {

    setUserList(rows1)

  }, [rows1])


  const CustomPagination1 = () => {
    const handlePageChange1 = (event: any, page: any) => {
      setPaginationModel1((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange1 = (event: any) => {
      setPaginationModel1((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow1 = paginationModel1.page * paginationModel1.pageSize + 1
    const endRow1 = Math.min((paginationModel1.page + 1) * paginationModel1.pageSize, rowCount1)
    const totalPages1 = Math.ceil(rowCount1 / paginationModel1.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        {/*  <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ml: 1, mr: 1}}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box> */}
        <Pagination
          count={totalPages1}
          page={paginationModel1.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData1}
          size='small'
          onChange={handlePageChange1}
        />
        <Box>
          Showing {startRow1}-{endRow1} of {rowCount1} record
        </Box>
      </Box>
    )
  }
  //#endregion


  const [groupedUserList, setgroupedUserList] = useState<any>([])
  const [unGroupedUserList, setunGroupedUserList] = useState<any>([])
  const [selectedgroupedUserList, setSelectedgroupedUserList] = useState<Array<ID>>(
    initialListView.selected
  )
  const [selectedunGroupedUserList, setSelectedunGroupedUserList] = useState<Array<ID>>(
    initialListView.selected
  )
  const [isCheckAllgroupedUserList, setIsCheckAllgroupedUserList] = useState(false)
  const [isCheckAllunGroupedUserList, setIsCheckAllunGroupedUserList] = useState(false)


  /*  console.log("group111 : ", groupList) */

  const handleGroup = async () => {
    setHandleGroupLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/addJourneyToUser?journeyId=${journeyId}`,
        {
          userIdList: selected
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      ).then(async (response: any) => {
        //loading eklenecek
        setSelected([])
        await getUserList1()
        getUserList()
        setHandleGroupLoading(false)

      }).catch((err) => {
        setHandleGroupLoading(false)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }
  const [groupAllLoading, setGroupAllLoading] = useState(false)
  const handleGroupAll = async () => {
    setHandleGroupLoading(true)
    setGroupAllLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/addJourneyToUser?journeyId=${journeyId}&isAll=true`,
        {
          filters: {
            userOptions: userOptions
          }
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      ).then(async (response: any) => {
        setGroupAllLoading(false)

        //loading eklenecek
        setSelected([])
        await getUserList1()
        getUserList()
        setHandleGroupLoading(false)
      }).catch((err) => {
        setGroupAllLoading(false)
        setHandleGroupLoading(false)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  const handleUnGroup = async () => {
    setHandleGroupLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/deleteJourneyToUser?journeyId=${journeyId}`,
        {
          userIdList: selected1
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      ).then(async (response: any) => {
        //loading eklenecek
        setSelected1([])
        await getUserList()
        getUserList1()
        setHandleGroupLoading(false)
      }).catch((err) => {
        setHandleGroupLoading(false)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }
  const [unGroupAllLoading, setUnGroupAllLoading] = useState(false)
  const handleUnGroupAll = async () => {
    setUnGroupAllLoading(true)
    setHandleGroupLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/deleteJourneyToUser?journeyId=${journeyId}&isAll=true`,
        {
          filters: {
            userOptions: userOptions1
          }
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      ).then(async (response: any) => {
        setUnGroupAllLoading(false)
        //loading eklenecek
        setSelected1([])
        await getUserList()
        getUserList1()
        setHandleGroupLoading(false)
      }).catch((err) => {
        setUnGroupAllLoading(false)
        setHandleGroupLoading(false)
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  const handleSelectAllgroupedUserList = (e: any) => {
    setIsCheckAllgroupedUserList(!isCheckAllgroupedUserList)
    setSelectedgroupedUserList(FilterGrouped?.map((li: any) => li))
    if (isCheckAllgroupedUserList) {
      setSelectedgroupedUserList([])
    }
  }
  const handleSelectAllunGroupedUserList = (e: any) => {
    setIsCheckAllunGroupedUserList(!isCheckAllunGroupedUserList)
    setSelectedunGroupedUserList(FilterUngrouped?.map((li: any) => li))
    if (isCheckAllunGroupedUserList) {
      setSelectedunGroupedUserList([])
    }
  }

  const [filterUngroupedUserList, setfilterUngroupedUserList] = useState<any>('')
  const [filterGroupedUserList, setfilterGroupedUserList] = useState<any>('')

  const FilterUngrouped = unGroupedUserList.filter((item: any) => {
    return (
      item.userName.toLowerCase().includes(filterUngroupedUserList.toLowerCase()) ||
      item.userEmail.toLowerCase().includes(filterUngroupedUserList.toLowerCase())
    )
  })

  const FilterGrouped = groupedUserList.filter((item: any) => {
    return (
      item.userName?.toLowerCase().includes(filterGroupedUserList.toLowerCase()) ||
      item.userEmail?.toLowerCase().includes(filterGroupedUserList.toLowerCase())
    )
  })
  const [button, setbutton] = useState(true)

  useEffect(() => {
    if (groupName.length < 1 || groupedUserList.length < 1) {
      setbutton(true)
    } else {
      setbutton(false)
    }
  }, [groupName, groupedUserList])

  const userlist = groupedUserList.map((re: any) => {
    return re.userId
  })




  //intl for language
  const intl = useIntl()


  /*    console.log('GroupedUserList : ', groupedUserList)
  console.log('selected : ', selected) */

  //#region copy paste email
  const [progress, setProgress] = useState(0)
  const [loadingCopyPaste, setLoadingCopyPaste] = useState(false)

  const [copyHover, setCopyHover] = useState(false)
  const [emails, setEmails] = useState<string[]>([])
  const [currentEmailBatch, setCurrentEmailBatch] = useState<string[]>([])
  const [currentEmailIndex, setCurrentEmailIndex] = useState<number>(0)
  const [progressStage, setProgressStage] = useState(0)

  function handlePaste(event: React.ClipboardEvent<HTMLDivElement>) {
    if (participantsUser1) {
      return
    }
    setProgress(0)
    setEmails([])
    setCurrentEmailIndex(0)
    const clipboardData = event.clipboardData?.getData('text') ?? ''
    const emailArray = clipboardData.split(/\r?\n/)
    const validEmails = emailArray.filter((email) => isValidEmail(email))
    enqueueSnackbar('Email list copying completed successfully  ', { variant: 'info' })
    const duplicateEmails = findDuplicateEmails(validEmails)
    if (duplicateEmails.length > 0) {
      setEmails(duplicateEmails)
    }
    setProgressStage(emails.length / 2500)
  }

  function findDuplicateEmails(emails: string[]) {
    const duplicates = []
    const uniqueEmailsReturn: any[] = []
    const uniqueEmails = new Set()
    for (const email of emails) {
      if (uniqueEmails.has(email)) {
        duplicates.push(email)
      } else {
        uniqueEmails.add(email)
        uniqueEmailsReturn.push(email)
      }
    }
    /*     console.log("duplicates : ", duplicates)
       console.log("uniqueEmails : ", uniqueEmailsReturn) */
    return uniqueEmailsReturn
  }

  function isValidEmail(email: string) {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  async function handleSaveEmails() {
    setLoadingCopyPaste(true)
    currentEmailBatch.length === 0
      ? setProgressStage(0)
      : setProgressStage(Math.round(progressStage + 1))
    const chunkSize: number = 1000 // her seferinde 25000 email kaydedeceğiz
    const startIndex: number = currentEmailIndex
    const endIndex: number = Math.min(currentEmailIndex + chunkSize, emails.length)
    const currentBatch: string[] = emails.slice(startIndex, endIndex)
    setCurrentEmailBatch(currentBatch)
    /*     console.log('startindex : ', startIndex)
    console.log('endindex : ', endIndex)
    console.log('currentmail : ', currentBatch) */
    // burada `currentBatch` isimli diziyi veritabanına kaydedebilirsiniz
    /* console.log('progressstage : ', progressStage) */
    setCurrentEmailIndex(endIndex)

    if (emails.length == currentEmailIndex) {
      setCurrentEmailIndex(0)
      setEmails([])
      setProgress(0)
      setLoadingCopyPaste(false)
    }
    /*     console.log('emails.length: ', emails.length)
    console.log('total : ', currentEmailIndex) */

    if (emails.length < 100000 && emails.length > 0) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/Game/journeyCopyPasteEmails?journeyId=${journeyId}`,
          {
            emailCopyList: currentEmailBatch,
          },
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              )
              /*  console.log(`Gönderme ilerlemesi: ${percentCompleted}%`) */
              let birim = Math.round(emails.length / 1000)
              const calculatedProgress =
                progressStage + percentCompleted / 100 + ((birim + 1) * currentEmailIndex) / 1000

              setProgress(Math.min(calculatedProgress, 100))

              /*  console.log('propgress: ' + progress)
              console.log('progressstage: ' + progressStage)
              console.log('birim: ' + birim) */
            },
            headers: {
              accept: 'application/json',
              Authorization: `Bearer ${LoginState.loginData.access_token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then(async (response) => {
          /*  console.log("currentEmailBatch: " , currentEmailBatch) */
          /*  enqueueSnackbar("E-mails saved successfully : "+ currentEmailIndex, { variant: "success" }) */
          /* console.log(response) */
          await getUserList1()
          getUserList()
          /*  response.data.verifiedEmailList.map((item: any) => {
             if (!groupedUserList.some((existingItem: any) => existingItem.userId === item.userId)) {
               setgroupedUserList((current: any) => [...current, item])
             }
             if (!allRows.some((existingItem: any) => existingItem.id === item.userId)) {
               const [firstName, lastName] = item.userName.split(' ')
               setAllRows((current: any) => [
                 ...current,
                 {
                   name: firstName,
                   surname: lastName,
                   id: item.userId,
                   email: item.userEmail,
                   avatar: { mediaUrl: item.avatarPath },
                 },
               ])
             }
             if (!selected.includes(item.userId)) {
               setSelected((prevSelected: any) => [...prevSelected, item.userId])
             }
           }) */
        })
        .catch((err) => {
          /*  console.log(err) */
        })
    } else {
      enqueueSnackbar('E-mails length max up to the number of registered users  ', {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    // Otomatik işlemi burada başlatın
    const intervalId = setInterval(() => {
      if (currentEmailIndex < emails.length) {
        handleSaveEmails()
      } else {
        clearInterval(intervalId) // İşlem tamamlandığında aralığı temizle
      }
    }, 1000) // İşlemi her saniye başlat

    return () => {
      clearInterval(intervalId) // Bileşen kaldırıldığında aralığı temizle
    }
  }, [currentEmailIndex, emails.length])

  //#endregion copy paste email end

  //warn modal
  const [openWarnModal, setOpenWarnModal] = useState(false)

  const handleClickWarnModalOpen = () => {
    setOpenWarnModal(true)
  }

  const handleClickWarnModalClose = () => {
    setOpenWarnModal(false)
  }

  return (
    <>
      {/*       <Modal size='xl' show={isUserLoading} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className='row w-100'>
            <div className='col-1'>
              <i className=' text-muted fa-solid fa-4x fa-user' />
            </div>
            <div className='col-11 text-center'>
              <div className='text center  text-muted w-100 h2'>Participants</div>
              <div className='h1 text-center w-100'>{journeyName}</div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body> */}
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} sx={{ marginLeft: 20, height: 55 }} /* centered */ >
              <Tab
                icon={value === '1' && <CheckCircleIcon />}
                iconPosition='start'
                label='User'
                value='1'
                disabled={handleGroupLoading || groupList?.length > 0}
                style={{ fontSize: 16 }}
              />
              <Tab
                icon={value === '2' && <CheckCircleIcon />}
                iconPosition='start'
                label='Group'
                value='2'
                disabled={handleGroupLoading || rows1?.length > 0}
                style={{ fontSize: 16 }}
              />
            </TabList>
          </Box>
          <TabPanel value='1' sx={{ padding: 2 }}>
            <Grid container>
              <Grid xs={5.5}>
                <Box display={"flex"} justifyContent={"space-between"} paddingLeft={2} paddingRight={2}>
                  <div></div>
                  <Typography color={"#ADB5BD"} fontSize={20}>All Users</Typography>
                  <Button variant='outlined' sx={{ fontWeight: "bold" }} size='small' color='primary' disabled={unGroupAllLoading || groupAllLoading || isLoadingData || isLoadingData1 || handleGroupLoading || rows.length < 1} onClick={() => handleGroupAll()} >Select All {groupAllLoading && <CircularProgress size={17} color='primary' sx={{ marginLeft: 1 }} />}</Button>
                </Box>
                <Box sx={{ height: 530, width: '100%', padding: 0 }}>
                  <StyledDataGrid
                    rows={rows || []}
                    columns={columns || []}
                    rowHeight={50}
                    disableVirtualization
                    key={1}
                    checkboxSelection={participantsUser === true ? false : true}
                    keepNonExistentRowsSelected
                    onCellClick={handleCellOneClick}
                    getRowId={(row) => row.id}
                    disableRowSelectionOnClick
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    rowSelectionModel={selected}
                    onRowSelectionModelChange={handleSelectionModelChange}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setColumnVisibilityModel(newModel)
                    }
                    filterMode='server'
                    onFilterModelChange={onFilterChange}
                    rowCount={rowCount}
                    loading={isLoadingData}
                    paginationModel={paginationModel}
                    paginationMode='server'
                    pagination={true}
                    onPaginationModelChange={setPaginationModel}
                    getRowClassName={(params) =>
                      `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
                    }
                    slots={{
                      pagination: CustomPagination,
                    }}
                    sx={{
                      borderRadius: '15px',
                      boxShadow: 3,
                      '& .MuiDataGrid-cell:hover': {
                        cursor: 'pointer',
                      },
                      '&	.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        color: '#A1A5B7',
                        fontSize: 17,
                      },
                      '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid xs={1} display={"flex"} alignContent={"center"} flexDirection={"column"} justifyContent={"center"}>
                <Button variant='text' disabled={selected.length < 1 || participantsUser} onClick={handleGroup}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='100'
                    height='100'
                    viewBox='0 0 35.958 49.632'
                  >
                    <g id='ArrowSmall' transform='translate(35) rotate(90)'>
                      <path
                        id='Vector'
                        d='M0,0,24.8,24.816,0,49.632H12.4L35.029,26.988a3,3,0,0,0,0-4.343L12.4,0Z'
                        transform='translate(50 40) scale(1 -1) rotate(90)'
                        fill='#6e6f6f'
                        opacity={selected.length < 1 || participantsUser ? '0.5' : '0.8'}
                      />
                    </g>
                  </svg>
                </Button>
                <Button variant='text' disabled={selected1.length < 1 || participantsUser1} onClick={handleUnGroup}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='100'
                    height='100'
                    viewBox='0 0 35.958 49.632'
                  >
                    <g id='ArrowSmall' transform='translate(35.958) rotate(90)'>
                      <path
                        id='Vector'
                        d='M0,0,24.8,24.816,0,49.632H12.4L35.029,26.988a3,3,0,0,0,0-4.343L12.4,0Z'
                        transform='translate(49.632 0) rotate(90)'
                        fill='#6e6f6f'
                        opacity={selected1.length < 1 || participantsUser1 ? '0.5' : '0.8'}
                      />
                    </g>
                  </svg>
                </Button>
              </Grid>
              <Grid xs={5.5}>
                <Box display={"flex"} justifyContent={"space-between"} paddingLeft={2} paddingRight={2}>
                  <div></div>
                  <Typography color={"#ADB5BD"} fontSize={20}>Assigned Users</Typography>
                  <Button variant='outlined' sx={{ fontWeight: "bold" }} size='small' color='error' disabled={unGroupAllLoading || groupAllLoading || isLoadingData || isLoadingData1 || handleGroupLoading || rows1.length < 1} onClick={() => handleUnGroupAll()} >Remove All{unGroupAllLoading && <CircularProgress size={17} color='error' sx={{ marginLeft: 1 }} />}</Button>
                </Box>
                <Box sx={{ height: 530, width: '100%', padding: 0 }} >
                  <div style={{
                    width: "100%", height: "100%", border: copyHover ? '1px solid #65c466' : '1px',
                    borderRadius: '15px',
                  }} onPaste={handlePaste} onMouseEnter={() => (participantsUser1 ? null : setCopyHover(true))}
                    onMouseLeave={() => (participantsUser1 ? null : setCopyHover(false))}>
                    <StyledDataGrid
                      rows={rows1 || []}
                      columns={columns1 || []}
                      key={2}
                      rowHeight={50}
                      disableVirtualization
                      checkboxSelection={participantsUser1 === true ? false : true}
                      keepNonExistentRowsSelected
                      onCellClick={handleCellOneClick1}
                      getRowId={(row) => row.id}
                      disableRowSelectionOnClick
                      sortingMode='server'
                      onSortModelChange={handleSortModelChange1}
                      rowSelectionModel={selected1}
                      onRowSelectionModelChange={handleSelectionModelChange1}
                      columnVisibilityModel={columnVisibilityModel1}
                      onColumnVisibilityModelChange={(newModel) =>
                        setColumnVisibilityModel1(newModel)
                      }
                      filterMode='server'
                      onFilterModelChange={onFilterChange1}
                      rowCount={rowCount1}
                      loading={isLoadingData1}
                      paginationModel={paginationModel1}
                      paginationMode='server'
                      pagination={true}
                      onPaginationModelChange={setPaginationModel1}
                      getRowClassName={(params) =>
                        `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
                      }
                      slots={{
                        pagination: CustomPagination1,
                      }}
                      sx={{
                        borderRadius: '15px',
                        boxShadow: 3,
                        '& .MuiDataGrid-cell:hover': {
                          cursor: 'pointer',
                        },
                        '&	.MuiDataGrid-columnHeaderTitle': {
                          fontWeight: 'bold',
                          color: '#A1A5B7',
                          fontSize: 17,
                        },
                        '& .MuiDataGrid-cell:focus': {
                          outline: 'none',
                        },
                      }}
                    />
                    {
                      rows1.length === 0 && copyHover &&
                      <div
                        className='d-flex text-center text-muted fs-3'
                        style={{ marginTop: '-250px' }}
                      >
                        Add assigned users here. <br />
                        <br /> You can also copy email addresses from Excel and Paste them here.
                        Max 1.000 emails at a time.
                      </div>
                    }
                  </div>
                </Box>
              </Grid>
            </Grid>
            {/* <div
                onMouseEnter={() => (participantsUser ? null : setCopyHover(true))}
                onMouseLeave={() => (participantsUser ? null : setCopyHover(false))}
                style={{
                  border: copyHover ? '1px solid #65c466' : '1px solid #707070',
                  borderRadius: '15px',
                }}
              >
                <div className='row' onPaste={handlePaste}>
                  <div className='text-muted text-center h5'> Assigned Users In This Journey </div>
                  <div>
                    <div className='d-flex mt-2 justify-content-between'>
                      {' '}
                      <div className='d-flex'>
                        <input
                          type='text'
                          placeholder='Search...'
                          className='ms-2 border rounded border-rounded w-50'
                          onChange={(e: any) => setfilterGroupedUserList(e.target.value)}
                        />
                        <div className='text-end my-auto ms-2' style={{ color: '#a1a5b7' }}>

                          {groupedUserList.length} Selected
                        </div>
                       
                      </div>
                      <Box width={'40px'} display={'flex'} justifyContent={'center'}>
                        <Tooltip title='All Delete'>
                          <IconButton
                            onClick={() => handleClickWarnModalOpen()}
                            disabled={participantsUser}
                            aria-label='delete'
                          >
                            <DeleteForeverIcon color='error' />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </div>
                    <hr />
                    <div style={{ height: '412px', paddingBottom: '5px', overflow: 'scroll' }}>
                      {FilterGrouped?.length === 0 && (
                        <div
                          className='d-flex text-center text-muted fs-3'
                          style={{ marginTop: '40%' }}
                        >
                          Add assigned users here. <br />
                          <br /> You can also copy email addresses from Excel and Paste them here.
                          Max 1.000 emails at a time.
                        </div>
                      )}
                      {getUsersForPage(currentPage)?.map((item: any, index: number) => {
                        return (
                          <div
                            className='ms-1 '
                            onMouseEnter={(e: any) =>
                              participantsUser ? null : setHoverSelectedUsers(index)
                            }
                            onMouseLeave={(e: any) =>
                              participantsUser ? null : setHoverSelectedUsers(-1)
                            }
                          >
                            <Paper
                              elevation={3}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '2px',
                                marginTop: index !== 0 ? '10px' : '',
                                background: hoverSelectedUsers === index ? '#e2f2fe' : '',
                              }}
                            >
                              <Box display={'flex'} justifyContent={'start'} marginLeft={1}>
                                <Avatar src={item.avatarPath} />
                                <Box>
                                  <Typography
                                    fontSize={'13px'}
                                    style={{ marginLeft: '10px', whiteSpace: 'normal' }}
                                    color={'black'}
                                  >
                                    {item.userName}
                                  </Typography>
                                  <Typography
                                    fontSize={'13px'}
                                    style={{ marginLeft: '10px', whiteSpace: 'normal' }}
                                    color={'#a1a5b7'}
                                  >
                                    {item.userEmail}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box
                                width={'40px'}
                                height={'100%'}
                                display={'flex'}
                                justifyContent={'center'}
                              >
                                <Tooltip title='Delete'>
                                  <IconButton
                                    disabled={participantsUser}
                                    onClick={() => (
                                      setgroupedUserList(
                                        groupedUserList.filter(
                                          (items: any) => items.userId !== item.userId
                                        )
                                      ),
                                      setSelected(
                                        selected.filter((items: any) => items !== item.userId)
                                      )
                                    )}
                                    aria-label='delete'
                                  >
                                    <DeleteForeverIcon color='error' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Paper>
                          </div>
                        )
                      })}
                    </div>
                    <Box
                      paddingBottom={1}
                      paddingTop={1}
                      width={'100%'}
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      <Pagination
                        count={Math.ceil(FilterGrouped.length / itemsPerPage)}
                        page={currentPage}
                        onChange={(event, value) => setCurrentPage(value)}
                        size='small'
                        showFirstButton
                        showLastButton
                      />
                    </Box>
                  </div>
                </div>
            </div> */}
          </TabPanel>
          <TabPanel value='2' sx={{ padding: 2 }}>
            <AddGroup
              groupListFilter={groupListFilter}
              setGroupList={setGroupList}
              groupList={groupList}
              journeyId={journeyId}
              journeyName={journeyName}
              participantsUser={participantsUser}
              participantsUser1={participantsUser1}
              handleGroupLoading={handleGroupLoading}
              setHandleGroupLoading={setHandleGroupLoading}
            />
          </TabPanel>
        </TabContext>
      </Box>

      {
        //warn modal
      }
      <Dialog
        open={openWarnModal}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description' color={'white'}>
            You’re removing ALL the selected users from the list. Proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => (setgroupedUserList([]), setSelected([]), handleClickWarnModalClose())}
            style={{ color: 'white' }}
          >
            Yes
          </Button>
          <Button variant='contained' onClick={handleClickWarnModalClose} style={{ color: 'white' }}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default JourneyAddEditUserGroupModal
