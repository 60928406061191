import { FC, forwardRef, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Slide,
  SpeedDial,
  SpeedDialAction,
  Tooltip,
  Typography,
  darken,
  lighten,
  styled,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './customSvg.css'
import { LearningCardEdit } from './LearningCard/EditLearningCard'
import Loading from '../../../_metronic/layout/components/Loading'
import { TransitionProps } from '@mui/material/transitions'
import {
  DataGridPro,
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import CreateTest from './Test/CreateTest'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckIcon from '@mui/icons-material/Check'
import BlockIcon from '@mui/icons-material/Block'
import DeleteIcon from '@mui/icons-material/Delete'
import SettingsIcon from '@mui/icons-material/Settings'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { CreateGame } from './Game/Game'
import CreateActivity from './LearningCard/Components/CreateActivity'
import AddIcon from '@mui/icons-material/Add'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import EditIcon from '@mui/icons-material/Edit'
import PhonelinkIcon from '@mui/icons-material/Phonelink';

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const ActivitiesList = () => {
  const [showExam, setshowExam] = useState(false)
  const [showGame, setshowGame] = useState(false)
  const [showKnowledge, setshowKnowledge] = useState(false)
  const navigate = useNavigate()
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  /*   const [selected, setSelected] = useState<Array<ID>>(initialListView.selected) */
  const [expense, setExpense] = useState<any>([])
  const [first, setFirst] = useState(true)
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [selectedActivity, setselectedActivity] = useState<any>(0)
  const [editActivity, setEditActivity] = useState<any>(0)

  const handle = () => {
    if (show === true) {
      setShow(false)
    } else {
      setShow(true)
    }
  }

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll)
    setSelected(expense?.items?.map((li: any) => li.activityId))
    if (isCheckAll) {
      setSelected([])
    }
  }
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState<any>(`page=${page}&size=10&`)
  const LoginState = useSelector((state: any) => state.Login)
  const [user, setuser] = useState<any>('')

  const [filterOptions, setFilterOptions] = useState<any>({
    question: '',
    category: '',
    questionId: '',
    CreatedDateStart: '',
    CreatedDateEnd: '',
    CreatedBy: '',
    Status: '',
    gametype: '',
  })
  const [sortOptions, setSortOptions] = useState<any>('')
  useEffect(() => { }, [LoginState.loginData])
  const [isLoading, setisLoading] = useState(false)

  const [newLearningCardId, setNewLearningCardId] = useState(-1)

  useEffect(
    () => {
      setPagination(`page=${page}&size=10&`)
      setisLoading(true)
      const ax = axios.create({ headers: { Accept: 'application/json' } })
      function test() {
        const res = ax
          .get(
            `${process.env.REACT_APP_API_URL}/Game/getActivitiesList?page=${paginationModel.page + 1
            }&size=${paginationModel.pageSize}`,
            {
              headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
            }
          )
          .then((response) => {
            // expected the setProducts to be filled with the return of this request
            setExpense(response.data)
            if (newLearningCardId !== -1) {
              setselectedActivity(response.data.items[0])
              setTimeout(() => {
                setshowKnowledge(true)
              }, 3000)
              setTimeout(() => {
                setNewLearningCardId(-1)
              }, 4000)
            }

            setisLoading(false)
          })
          .catch((err) => {
            setisLoading(false)
          })
      }
      /*  function test2() {
         const res = ax
           .get(`${process.env.REACT_APP_API_URL}/user/getUserWithJWT`, {
             headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
           })
           .then((response) => {
             // expected the setProducts to be filled with the return of this request
             setuser(response.data.customerId)
           })
       }
       test2() */
      if (user !== '') {
        /*  test() */
      }
    },
    [
      /* pagination, page, user, filterOptions, sortOptions, first, newLearningCardId */
    ]
  )

  var actvityNew = expense?.items?.filter((item: any) => item?.activityId == newLearningCardId)

  useEffect(() => { }, [newLearningCardId])

  const statusId = (e: any) => {
    if (e === 0) {
      return (
        <span
          style={{ height: '19px', width: '57px' }}
          className='badge badge-light-secondary fw-bold fs-3'
        >
          Blocked
        </span>
      )
    }
    if (e === 1) {
      return (
        <span
          style={{ height: '19px', width: '57px' }}
          className='badge badge-light-success fw-bold fs-3'
        >
          Open
        </span>
      )
    }
    if (e === 2) {
      return (
        <span
          style={{ height: '19px', width: '57px' }}
          className='badge badge-light-warning fw-bold fs-3'
        >
          Delete
        </span>
      )
    }
  }
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }
  const [i, setI] = useState(false)
  const [arrow, setArrow] = useState({
    row: 0,
    sort: '',
  })
  const arrowF = (e: any) => {
    if (arrow.row === e) {
      return <span>{arrow.sort}</span>
    }
  }

  const refreshButton = () => {
    if (
      filterOptions.question.length > 1 ||
      filterOptions.category.length > 1 ||
      filterOptions.questionId.length > 1 ||
      filterOptions.CreatedDateStart.length > 1 ||
      filterOptions.CreatedDateEnd.length > 1 ||
      filterOptions.CreatedBy.length > 1 ||
      filterOptions.Status.length > 1 ||
      sortOptions.length > 1 ||
      filterOptions.gametype.length > 1
    ) {
      return (
        <button
          className='btn svg-icon  svg-icon-2x'
          style={{ marginTop: '-10px', marginRight: '-20px' }}
          onClick={() => {
            setFilterOptions({
              question: '',
              category: '',
              questionId: '',
              CreatedDateStart: '',
              CreatedDateEnd: '',
              CreatedBy: '',
              Status: '',
              gametype: '',
            })
            setArrow({
              row: 0,
              sort: '',
            })
            setSortOptions('')
            setI(false)
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='27.644'
            height='27.644'
            viewBox='0 0 27.644 27.644'
          >
            <path
              id='refresh-circle'
              d='M61.822,48A13.822,13.822,0,1,0,75.644,61.822,13.838,13.838,0,0,0,61.822,48Zm0,20.8a6.246,6.246,0,1,1,0-12.493h.28l-.938-.937a.93.93,0,1,1,1.316-1.316l2.658,2.658a.93.93,0,0,1,0,1.316L62.48,60.685a.93.93,0,0,1-1.316-1.316l1.2-1.2c-.158-.007-.339-.007-.538-.007a4.386,4.386,0,1,0,4.386,4.386.93.93,0,1,1,1.861,0A6.254,6.254,0,0,1,61.822,68.8Z'
              transform='translate(-48 -48)'
              fill='#7e8299'
            />
          </svg>
        </button>
      )
    } else {
      return <></>
    }
  }
  const [selectedLenght, setselectedLenght] = useState<any>('')
  useEffect(() => {
    if (isCheckAll === true) {
      setselectedLenght(expense.total)
    }
    if (isCheckAll === false) {
      setselectedLenght(selected.length)
    }
  }, [isCheckAll])
  const [showStatu, setShowStatu] = useState(false)

  const handleClose = () => setShowStatu(false)
  const [newStatusId, setnewStatusId] = useState<any>('')
  const [newStatusName, setnewStatusName] = useState<any>('')

  const handleShow = (e: any) => {
    setShowStatu(true)
    setnewStatusId(e.target.tabIndex)
    setnewStatusName(e.target.innerHTML)
    if (isCheckAll === true) {
      setselectedLenght(expense.total)
    }
    if (isCheckAll === false) {
      setselectedLenght(selected.length)
    }
  }

  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)

    var offset = date.getTimezoneOffset() / 60
    var hours = date.getHours()

    newDate.setHours(hours - offset)

    return newDate
  }
  const [showDublicate, setShowDublicate] = useState(false)

  const handleClick = (item: any, activity: any) => {
    setselectedActivity(activity)
    if (item === 0) {
      setshowKnowledge(true)
    }
    //---
    if (item === 1) {
      setShowGameEdit(true)
    }
    //----
    if (item === 2) {
      setshowExam(true)
    }
  }
  const [hover, sethover] = useState('')
  const handleCloseDublicate = () => setShowDublicate(false)
  const handleShowDublicate = () => setShowDublicate(true)
  const [name, setname] = useState('')
  const [id, setid] = useState<any>('')
  const HandleSaveDublicate = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Game/duplicateActivity/${selected[0]}`, '', {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        enqueueSnackbar(`Successfully duplicated`, { variant: 'success' })
        setFirst(!first)
      })
  }

  function CreateJourneyShort() {
    navigate(`/journey/learning-journey/create?selectedActivity=${selected}`, { state: selectedForCreate })
  }
  const [selectedForCreate, setselectedForCreate] = useState<any>([])

  const activitymodal: any = localStorage.getItem('activityModal')

  useEffect(() => {
    setTimeout(() => {
      if (JSON.parse(activitymodal) != undefined || JSON.parse(activitymodal) != null) {
        if (JSON.parse(activitymodal) != '-1') {
          handleClick(JSON.parse(activitymodal).activityTypeId, JSON.parse(activitymodal))
        }
      }
      localStorage.setItem('activityModal', '-1')
    }, 2000)
  }, [])
  const [showAddActivity, setShowAddActivity] = useState(false)
  const [showNewKnowledgeCard, setShowNewKnowledgeCard] = useState(false)

  //#region
  const [createActivityModal, setCreateActivityModal] = useState(false)
  //#endregion
  // Yeni Bölüm
  //States
  const [rows, setRows] = useState<any>([])
  const [rowCount, setRowCount] = useState(0)
  const [loadingExport, setLoadingExport] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [activityOptions, setActivityOptions] = useState<any>({ sortModel: [{ field: "statusId", sort: "asc" }, { field: "createdDate", sort: "desc" },] })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    InternalEmployeeId: false,
  })
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })

  const [selected, setSelected] = useState<any>([])
  const [categoriesList, setCategoriesList] = useState<any>([])
  const [typesList, setTypesList] = useState<any>([])
  const [subTypesList, setSubTypesList] = useState<any>([])

  const [showCreateTestEdit, setShowCreateTestEdit] = useState(false)
  const [showGameEdit, setShowGameEdit] = useState(false)
  const [gameType, setGameType] = useState(0)

  useEffect(() => {
    getActivitiesList()
  }, [activityOptions, paginationModel, columnVisibilityModel, first])


  async function getActivitiesList() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/getActivitiesList?page=${paginationModel.page + 1
        }&size=${paginationModel.pageSize}`,
        {
          activityOptions,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setExpense(response.data)
        const formattedRows = response.data.items.map((row: any) => ({
          activityId: row.activityId,
          title: row.title,
          activityTypeName: row.activityTypeName,
          activitySubTypeName: row.activitySubTypeName,
          categoryName: row.categoryName,
          timeLimit: row.timeLimit,
          statusId: row.statusId,
          createdDate: row.createdDate,
          createdName: row.createdName,
          icon: row.icon,
        }))
        setRowCount(response.data.total)
        setRows(formattedRows)
        if (newLearningCardId !== -1) {
          setselectedActivity(response.data.items[0])
          setTimeout(() => {
            setshowKnowledge(true)
          }, 3000)
          setTimeout(() => {
            setNewLearningCardId(-1)
          }, 4000)
        }

        setisLoading(false)
      })
      .catch((err) => {
        setisLoading(false)
      })
  }

  useEffect(() => {
    if (categoriesList.length < 1) {
      getAllCategories()
    }

  }, [categoriesList])

  useEffect(() => {
    if (typesList.length < 1 || subTypesList.length < 1) {
      getActivityTypes()
    }
  }, [])


  //warn modal
  const [warnModalContent, setWarnModalContent] = useState<any>('')
  const [successMessageWarnModal, setSuccessMessageWarnModal] = useState<any>('')

  const [activityStatus, setActivityStatus] = useState(-1)

  useEffect(() => {
    if (activityStatus === -1) {
      return
    }
    if (activityStatus === 1) {
      //open
      //handleChangeStatus()
      setWarnModalContent(
        <Typography color='white'>
          You are making the customer’s status OPEN.
          <br />
          The customer and all the users of this customer will start to use the product.
          <br />
          Continue?
        </Typography>
      )
      setSuccessMessageWarnModal(`successfully saved`)
      handleClickWarnModalOpen()
    }
    if (activityStatus === 0) {
      //block
      setWarnModalContent(
        <Typography color='white'>
          This operation will BLOCK selected activities. Blocked activities can be seen in the
          historical reports, lists and game results. You can unblock the activity at any time. For
          extra security, you must confirm the process.
          <br /> Proceed?
        </Typography>
      )
      setSuccessMessageWarnModal(`successfully BLOCKED`)
      handleClickWarnModalOpen()
    }
    if (activityStatus === 2) {
      //block
      setWarnModalContent(
        <Typography color='white'>
          This operation will DELETE the selected records.
          Deletion process can NOT be undone.  <br />  <br />
          For extra security, you must confirm the process.
          <br />
          Proceed?
        </Typography>
      )
      setSuccessMessageWarnModal(`successfully BLOCKED`)
      handleClickWarnModalOpen()
    }
  }, [activityStatus])

  const [openWarnModal, setOpenWarnModal] = useState(false)

  const handleClickWarnModalOpen = () => {
    setOpenWarnModal(true)
  }

  const handleClickWarnModalClose = () => {
    setOpenWarnModal(false)
    setTimeout(() => {
      setActivityStatus(-1)
    }, 1000)
  }

  //warn modal end

  const [loadingStatus, setLoadingStatus] = useState(false)
  function handleChangeStatus() {
    if (isCheckAll === false) {
      setLoadingStatus(true)
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/Game/changeActivityStatus?statusId=${activityStatus}`,
          { activityList: selected },
          {
            headers: {
              accept: 'application/json',
              Authorization: `Bearer ${LoginState.loginData.access_token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setSelected([])
          enqueueSnackbar(`Saved successfully`, { variant: 'success' })
          handleClickWarnModalClose()
          setFirst(!first)
          setLoadingStatus(false)
        })
        .catch((err) => {
          if (err.response?.data?.detail.errorMessage) {
            enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
          } else if (err.response !== undefined) {
            enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              { variant: 'error' }
            )
          }
          setLoadingStatus(false)
        })
    }
  }

  async function getAllCategories() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/content/getAllCategoriesForQuestionList`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setCategoriesList(res.data)
        /* setTypesList()
        setSubTypesList() */
      })
      .catch((err) => { })
  }
  async function getActivityTypes() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Game/getActivityTypes`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setTypesList(res.data.activityTypeList)
        setSubTypesList(res.data.activitySubTypeList)
      })
      .catch((err) => { })
  }

  const [status] = useState([
    { value: 0, label: 'Blocked' },
    { value: 1, label: 'Open' },
    { value: 2, label: 'Deleted' },
  ])

  const StatusCell = ({ value }: { value: number }) => {
    const getStatusComponent = (status: number) => {
      if (status === 0) {
        {
          /* <span className='badge badge-light-secondary fw-bold fs-3'>Blocked</span> */
        }
        return (
          <Chip
            label='Blocked'
            variant='outlined'
            color='secondary'
            size='small'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 1) {
        /*   <span className='badge badge-light-success fw-bold fs-3'>Open</span> */
        return (
          <Chip
            label='Open'
            variant='outlined'
            color='success'
            size='small'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 2) {
        {
          /* <span className='badge badge-light-warning fw-bold fs-3'>Deleted</span> */
        }
        return (
          <Chip
            className='overflow-visible'
            label='Deleted'
            variant='outlined'
            color='error'
            size='small'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      return null
    }

    return <div style={{ height: '19px', width: '57px' }}>{getStatusComponent(value)}</div>
  }

  const columns: any = [
    {
      field: 'title',
      headerName: ' ACTIVITY NAME',
      width: 160,
      renderCell: (params: GridCellParams) => (
        <>
          <Avatar src={params.row.icon} style={{ marginRight: '10px' }} />
          {params.value}
        </>
      ),
    },
    {
      field: 'activityTypeName',
      headerName: 'TYPE',
      width: 100,
      type: 'singleSelect',
      valueOptions: typesList.map((option: any) => ({
        value: option.activityTypeId,
        label: option.activityTypeName,
      })),
      renderCell: (params: any) => params.value,
    },
    {
      field: 'activitySubTypeName',
      headerName: 'SUBTYPE',
      width: 100,
      type: 'singleSelect',
      valueOptions: subTypesList.map((option: any) => ({
        value: option.activitySubTypeId,
        label: option.activitySubTypeName,
      })),
      renderCell: (params: any) => params.value,
    },
    {
      field: 'categoryName',
      headerName: 'CATEGORY',
      width: 150,
      valueOptions: categoriesList.map((category: any) => ({
        value: category.categoryId,
        label: category.name,
      })),
      renderCell: (params: any) => params.value,
      type: 'singleSelect',
    },
    {
      field: 'timeLimit',
      headerName: 'TIME LIMIT',
      width: 100,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'activityId',
      headerName: 'ID',
      width: 60,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value?.toFixed(0),
    },
    {
      field: 'createdDate',
      headerName: 'CREATED DATE',
      width: 170,
      type: 'dateTime',
      valueGetter: ({ value }: { value: Date }) => value && new Date(value),
    },
    {
      field: 'createdName',
      headerName: 'CREATED BY',
      width: 150,
      type: 'string',
      /*  valueGetter: ({value}: {value: Date}) => value && new Date(value), */
    },
    {
      field: 'statusId',
      headerName: 'STATUS',
      width: 150,
      type: 'singleSelect',
      valueOptions: status.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      renderCell: (params: GridCellParams) => <StatusCell value={params.value as number} />,
    },
  ]

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <LoadingButton
          size='small'
          onClick={downloadExcel}
          endIcon={<FileDownloadIcon />}
          loading={loadingExport}
          loadingPosition='end'
          variant='outlined'
        >
          <span>Export</span>
        </LoadingButton>
      </GridToolbarContainer>
    )
  }
  async function downloadExcel() {
    setLoadingExport(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/getActivitiesList?isExcel=1`,
        {
          activityOptions,
          columnVisibilityModel,
        },
        {
          responseType: 'blob',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const excelBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const excelUrl = URL.createObjectURL(excelBlob)

        const link = document.createElement('a')
        link.href = excelUrl
        link.download = 'activityList.xlsx'
        link.click()
        URL.revokeObjectURL(excelUrl)
        setLoadingExport(false)
      })
      .catch((err) => {
        setLoadingExport(false)
      })
    //frontend excel download
    /*     const newData = rows.map((row: any) => {
        delete row.tableData
        return row
      })
      const workSheet = XLSX.utils.json_to_sheet(newData)
      const workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, workSheet, 'row')
      //Buffer
      XLSX.write(workBook, {bookType: 'xlsx', type: 'buffer'})
      //Binary string
      XLSX.write(workBook, {bookType: 'xlsx', type: 'binary'})
      //Download
      XLSX.writeFile(workBook, 'Veri Listesi.xlsx') */
  }
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setActivityOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
        return
      } */

    setActivityOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelected(newSelectionModel)
  }
  useEffect(() => {
    if (createActivityModal === false) {
      setEditActivity(0)
    }
  }, [createActivityModal])

  const handleCellOneClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }
    const newSelectionModel = [...selected]; // Mevcut seçili satırların bir kopyasını oluşturun
    const selectionIndex = newSelectionModel.indexOf(params.id);

    if (selectionIndex === -1) {
      // Eğer tıklanan hücrenin ID'si şu anda seçili değilse, seçime ekleyin
      newSelectionModel.push(params.id);
    } else {
      // Eğer tıklanan hücrenin ID'si zaten seçiliyse, seçimden kaldırın
      newSelectionModel.splice(selectionIndex, 1);
    }

    setSelected(newSelectionModel);

  }
  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin

    if (params.row.activityTypeName === 'LEARNING CARD') {
      setselectedActivity(params.id)
      setEditActivity(1)
      setCreateActivityModal(true)
    }
    if (params.row.activityTypeName === 'GAME') {
      if (params.row.activitySubTypeName === 'DEEPLEARN') {
        setselectedActivity(params.id)
        setEditActivity(2)
        setCreateActivityModal(true)
      }
      if (params.row.activitySubTypeName === 'TENTEN') {
        setselectedActivity(params.id)
        setEditActivity(3)
        setCreateActivityModal(true)
      }
      if (params.row.activitySubTypeName === 'RECALL') {
        setselectedActivity(params.id)
        setEditActivity(4)
        setCreateActivityModal(true)
      }
      if (params.row.activitySubTypeName === 'PEAK') {
        setselectedActivity(params.id)
        setEditActivity(5)
        setCreateActivityModal(true)
      }
    }
    if (params.row.activitySubTypeName === 'QUIZ') {
      setselectedActivity(params.id)
      setEditActivity(6)
      setCreateActivityModal(true)
    }
    if (params.row.activitySubTypeName === 'EXAM') {
      setselectedActivity(params.id)
      setEditActivity(7)
      setCreateActivityModal(true)
    }
    /* setOpenEditUserModal(true) */
    /*  userData.filter((user: any) => user.userId === params.row.userId)
      setSelectedUserData(userData.filter((user: any) => user.userId === params.row.userId)) */
    /*  navigate(`/manage/users/detail?id=${params.id}`) */
    // Diğer işlemleri burada yapabilirsiniz
  }

  const handleEditActivity = (selectedRows: any) => {
    /*  let selectedRows =  */

    setselectedActivity(selectedRows.activityId)
    setCreateActivityModal(true)

    if (selectedRows.activityTypeName === 'LEARNING CARD') {
      setEditActivity(1)
    }
    if (selectedRows.activityTypeName === 'GAME') {
      if (selectedRows.activitySubTypeName === 'DEEPLEARN') {
        setEditActivity(2)

      }
      if (selectedRows.activitySubTypeName === 'TENTEN') {
        setEditActivity(3)

      }
      if (selectedRows.activitySubTypeName === 'RECALL') {
        setEditActivity(4)

      }
      if (selectedRows.activitySubTypeName === 'PEAK') {
        setEditActivity(5)
      }
    }
    if (selectedRows.activitySubTypeName === 'QUIZ') {
      setEditActivity(6)

    }
    if (selectedRows.activitySubTypeName === 'EXAM') {
      setEditActivity(7)

    }
  }

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ ml: 1, mr: 1 }}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }
  //speed dial start
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const handleCloseSpeedDial = () => {
    setSpeedDialOpen(false)
  }
  const handleOpenSpeedDial = () => {
    setSpeedDialOpen(true)
  }

  const filteredUsers = rows.filter((row: any) => selected?.includes(row.customerId))

  const actions: any = [
    filteredUsers[0]?.statusId === 1
      ? null
      : { icon: <CheckIcon style={{ color: 'black' }} />, name: 'Open', value: 1 },
    filteredUsers[0]?.statusId === 0
      ? null
      : { icon: <BlockIcon style={{ color: 'black' }} />, name: 'Block', value: 0 },
    { icon: <DeleteIcon style={{ color: 'black' }} />, name: 'Delete', value: 2 },
  ]
  //speed dial end
  const [addButtonHover, setAddButtonHover] = useState(false)
  const [editButtonHover, setEditButtonHover] = useState(false)
  const [duplicateButtonHover, setDuplicateButtonHover] = useState(false)
  const [makeJourneyButtonHover, setMakeJourneyButtonHover] = useState(false)
  return (
    <>
      <Modal className='bg-opacity-10' show={showDublicate} onHide={handleCloseDublicate}>
        <Modal.Header closeButton>
          <Modal.Title>Dublicate</Modal.Title>
        </Modal.Header>
        <Modal.Body>Activity :{name}, gonna dublicate are you sure?</Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary' onClick={handleCloseDublicate}>
            Cancel
          </button>
          <button onClick={HandleSaveDublicate} className='btn btn-primary'>
            Dublicate
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showStatu}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Warning !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Changing {selectedLenght} games status to{' '}
          <span className='text-danger'>{newStatusName?.toLowerCase()}</span>. Are You Sure?
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' /* onClick={handleChangeStatus} */>Yes</button>
        </Modal.Footer>
      </Modal>

      {selected?.length > 0 ? (
        <div className='border-0'>
          {/* begin::Card toolbar */}
          <div className='w-50 float-end'>
            <div
              style={{ marginTop: '-55px', marginRight: 80 }}
              className='d-flex justify-content-end align-items-center'
            >
              <Tooltip
                title='Add New'
                placement='top'
                arrow
                onMouseEnter={() => {
                  setAddButtonHover(true)
                }}
                onMouseLeave={() => {
                  setAddButtonHover(false)
                }}
                style={{ background: addButtonHover ? '#D5D5D5 ' : 'white' }}
              >
                <IconButton
                  aria-label='delete'
                  onClick={() => setCreateActivityModal(true)}
                  size='small'
                  sx={{ background: 'white', marginRight: '7px' }}
                >
                  <AddIcon sx={{ fontSize: '30px', color: addButtonHover ? 'black' : ' ' }} />
                </IconButton>
              </Tooltip>
              {selected.length === 1 && (
                <Tooltip
                  title='Edit'
                  placement='top'
                  arrow
                  onMouseEnter={() => setEditButtonHover(true)}
                  onMouseLeave={() => {
                    setEditButtonHover(false)
                  }}
                  style={{ background: editButtonHover ? '#D5D5D5 ' : 'white' }}
                >
                  <IconButton
                    aria-label='delete'
                    onClick={() =>
                      handleEditActivity(rows.find((item: any) => item.activityId === selected[0]))
                    }
                    size='small'
                    sx={{ background: 'white' /* marginRight: '7px' */ }}
                  >
                    <EditIcon sx={{ fontSize: '30px', color: editButtonHover ? 'black' : '' }} />
                  </IconButton>
                </Tooltip>
              )}
              {selected.length === 1 && (
                <Tooltip
                  title='Duplicate'
                  placement='top'
                  arrow
                  onMouseEnter={() => {
                    setDuplicateButtonHover(true)
                  }}
                  onMouseLeave={() => {
                    setDuplicateButtonHover(false)
                  }}
                  style={{ background: duplicateButtonHover ? '#D5D5D5 ' : 'white' }}
                >
                  <IconButton
                    aria-label='Duplicate'
                    onClick={() => {
                      HandleSaveDublicate()
                    }}
                    size='small'
                    sx={{
                      background: 'white',
                      color: duplicateButtonHover ? 'black' : '',
                      marginRight: '7px',
                      marginLeft: '7px',
                    }}
                  >
                    <ContentCopyOutlinedIcon sx={{ fontSize: '30px' }} />
                  </IconButton>
                </Tooltip>
              )}
              {
                selected?.length < 10 &&
                <Tooltip
                  title='Make a journey'
                  placement='top'
                  arrow
                  onMouseEnter={() => {
                    setMakeJourneyButtonHover(true)
                  }}
                  onMouseLeave={() => {
                    setMakeJourneyButtonHover(false)
                  }}
                  style={{ background: makeJourneyButtonHover ? '#D5D5D5 ' : 'white' }}
                >
                  <IconButton
                    aria-label='Make a journey'
                    onClick={() => {
                      CreateJourneyShort()
                    }}
                    size='small'
                    sx={{
                      background: 'white',
                      color: makeJourneyButtonHover ? 'black' : '',
                      marginRight: '7px',
                      marginLeft: '7px',
                    }}
                  >
                    <PhonelinkIcon sx={{ fontSize: '30px' }} />
                  </IconButton>
                </Tooltip>
              }

              <Box
                display='flex'
                justifyContent='flex-end'
                alignItems='center'
                alignContent='center'
                sx={{ height: 50 }}
              >
                <Tooltip arrow title='Change Status' placement='top'>
                  <SpeedDial
                    ariaLabel='SpeedDial openIcon example'
                    style={{ transform: 'scale(0.73)', marginTop: '132px' }}
                    icon={
                      speedDialOpen ? (
                        <ExpandMoreIcon fontSize='large' />
                      ) : (
                        <SettingsIcon fontSize='large' />
                      )
                    }
                    openIcon={<DeleteIcon fontSize='large' />}
                    onOpen={handleOpenSpeedDial}
                    onClose={handleCloseSpeedDial}
                    FabProps={{ color: 'inherit' }}
                    open={speedDialOpen}
                    direction='down'
                  >
                    {actions.map((action: any) =>
                      action === null ? null : (
                        <SpeedDialAction
                          style={{ transform: 'scale(0.70)' }}
                          delay={1000}
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={action.name}
                          onClick={() => setActivityStatus(action.value)}
                        />
                      )
                    )}
                  </SpeedDial>
                </Tooltip>
              </Box>
              {/*       <Dropdown>
                <Dropdown.Toggle variant='secondary' id='dropdown-basic' onChange={handle}>
                  Change Status
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item tabIndex={1} onClick={(value) => handleShow(value)}>
                    Open
                  </Dropdown.Item>
                  <Dropdown.Item tabIndex={0} onClick={(value) => handleShow(value)}>
                    Block
                  </Dropdown.Item>
                  <Dropdown.Item tabIndex={2} onClick={(value) => handleShow(value)}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          </div>
        </div>
      ) : (
        <div className='border-0'>
          {/* begin::Card toolbar */}
          <div className='w-50 float-end'>
            <div
              style={{ marginTop: '-55px', marginRight: 80 }}
              className='d-flex justify-content-end'
              data-kt-user-table-toolbar='base'
            >
              {/* <FilterTableActivities setCategoryName={setFilterOptions} customerId={user} /> */}
              {/* <AddUser setfirst={setfirst} first={first} companyId={user} /> */}
              <Tooltip
                title='Add New'
                placement='top'
                arrow
                onMouseEnter={() => {
                  setAddButtonHover(true)
                }}
                onMouseLeave={() => {
                  setAddButtonHover(false)
                }}
                sx={{
                  fontSize:12
                }}
                style={{ background: addButtonHover ? '#D5D5D5 ' : 'white' }}
              >
                <IconButton
                  aria-label='delete'
                  onClick={() => setCreateActivityModal(true)}
                  size='small'
                  sx={{ background: 'white', marginRight: '7px' }}
                >
                  <AddIcon sx={{ fontSize: '30px', color: addButtonHover ? 'black' : ' ' }} />
                </IconButton>
              </Tooltip>

              {/*   <AddNewActivity
                setShowNewKnowledgeCard={setShowNewKnowledgeCard}
                companyId={user}
                first={first}
                setfirst={setFirst}
                showMain={showAddActivity}
                setShowMain={setShowAddActivity}
                setNewLearningCardId={setNewLearningCardId}
                showNewKnowledgeCard={showNewKnowledgeCard}
              /> */}
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <div className={`card p-3`} style={{ minHeight: '625px' }}>
          {
            //DataGrid start
          }
          <Box sx={{ height: 600, width: '100%' }}>
            <StyledDataGrid
              rows={rows || []}
              columns={columns || []}
              rowHeight={50}
              columnHeaderHeight={40}
              disableVirtualization
              checkboxSelection
              keepNonExistentRowsSelected
              onCellDoubleClick={handleCellClick}
              onCellClick={handleCellOneClick}
              getRowId={(row) => row.activityId}
              disableRowSelectionOnClick
              sortingMode='server'
              onSortModelChange={handleSortModelChange}
              sortModel={activityOptions?.sortModel}
              rowSelectionModel={selected}
              onRowSelectionModelChange={handleSelectionModelChange}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
              filterMode='server'
              onFilterModelChange={onFilterChange}
              /*  isRowSelectable={(params: GridRowParams) =>
                selected.length < 1 ? true : params.id === selected[0]
              } */
              rowCount={rowCount}
              loading={isLoadingData}
              paginationModel={paginationModel}
              paginationMode='server'
              pagination={true}
              onPaginationModelChange={setPaginationModel}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
              }
              slots={{
                toolbar: CustomToolbar,
                pagination: CustomPagination,
              }}
              sx={{
                boxShadow: "none",
                border: "none",
                '& .MuiDataGrid-footerContainer': {
                  padding: '0px',
                  height: 30
                },

                '& .MuiDataGrid-cell:hover': {
                  /*    color: 'primary.main', */
                  cursor: 'pointer',
                },
                '&	.MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  color: '#A1A5B7',
                  fontSize: 12,
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
                },
              }}
            />
          </Box>
          {
            //dataGrid end
          }
        </div>
      )}
      <CreateActivity
        setCreateActivityModal={setCreateActivityModal}
        selectedActivity={selectedActivity}
        setSelectedActivity={setselectedActivity}
        setFirst={setFirst}
        first={first}
        editActivity={editActivity}
        createActivityModal={createActivityModal}
      />
      {/* <ExamEdit
        companyId={user}
        finish={first}
        setFinish={setfirst}
        showMain={showExam}
        setShowMain={setshowExam}
        activity={selectedActivity}
      /> */}
      {/*   <CreateTest
        finish={first}
        setFinish={setFirst}
        closeActivty={handleClose}
        selectedActivity={selectedActivity}
        showExam={showExam}
        setShowCreateTestEdit={setShowCreateTestEdit}
        showCreateTestEdit={showCreateTestEdit}
      />
      <CreateGame
        selectedActivity={selectedActivity}
        gameType={gameType}
        first={first}
        setFirst={setFirst}
        closeActivty={handleClose}
        setShowGameEdit={setShowGameEdit}
        showGameEdit={showGameEdit}
      /> */}
      {/*  <GameEdit
        companyId={user}
        save={first}
        setSave={setFirst}
        activityId={selectedActivity}
        setShowMain={setshowGame}
        showMain={showGame}
      /> */}
      {/*   <LearningCardEdit
        first={first}
        company={user}
        KnowledgeCardId={selectedActivity}
        setfirst={setFirst}
        showAddActivity={showAddActivity}
        setShowNewKnowledgeCard={setShowNewKnowledgeCard}
        show={showKnowledge}
        setShow={setshowKnowledge}
      /> */}
      {
        //warn modal
      }
      <Dialog
        open={openWarnModal}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: activityStatus === 1 ? '#0288D1' : '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            {activityStatus === 1 ? (
              <InfoOutlinedIcon fontSize='large' />
            ) : (
              <WarningAmberIcon fontSize='large' />
            )}{' '}
            {activityStatus === 1 ? 'Info' : 'Warning'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {warnModalContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleChangeStatus()
            }}
            style={{ color: 'white' }}
            disabled={loadingStatus}
          >
            Yes
          </Button>
          <Button onClick={handleClickWarnModalClose} style={{ color: 'white' }}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default function Snackbar(/* {user, first, setfirst}: Props */ id: any) {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} maxSnack={3}>
      <ActivitiesList  /* {...{user, first, setfirst}} */ />
    </SnackbarProvider>
  )
}
