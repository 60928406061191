import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  Slide,
  Tab,
  TextField,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { TransitionProps } from '@mui/material/transitions'
import axios from 'axios'
import React, { forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalFooter } from 'react-bootstrap'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import TabList from '@mui/lab/TabList'
import AddIcon from '@mui/icons-material/Add'
import { enqueueSnackbar } from 'notistack'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  /* border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  }, */
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ height: 20 }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  /*  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)', */
  /*   flexDirection: 'row-reverse', */
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  /*   borderTop: '1px solid rgba(0, 0, 0, .125)', */
}))

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function AddQuestionAi(props: any) {
  const { open, setOpen, first, setFirst } = props

  const LoginState = useSelector((state: any) => state.Login)

  function handleClickModalClose() {
    handleClear()
    setOpen(false)
  }

  //#region TabPanel
  const [tabValue, setTabValue] = useState('0')

  //#endregion

  const questionType = [
    'Single Correct Answer',
    'Multiple Correct Answers',
    'True-False',
    'Fill in the blanks',
  ]
  const outputLanguage = [
    { name: 'Auto', value: 'Auto' },
    { name: 'English', value: 'ingilizce' },
    { name: 'Turkish', value: 'türkçe' },
    { name: 'Russian', value: 'rusça' },
  ]
  const questions = [1, 5, 10, 20]
  const options = [4, 6, 8]

  const [topic, setTopic] = useState('')
  const [errorTopic, setErrorTopic] = useState('')
  const [errorTopic1, setErrorTopic1] = useState('')
  const [questionTypeValue, setQuestionTypeValue] = useState('Single Correct Answer')
  const [outputLanguageValue, setOutputLanguageValue] = useState('Auto')
  const [questionsValue, setQuestionsValue] = useState(5)
  const [optionsValue, setOptionsValue] = useState(4)

  const validateWordCount = (text: any) => {
    const words = text.split(/\s/).filter(Boolean) // Regex ile kelime parçalama
    return words.length >= 10 && words.length <= 3000
  }
  const wordCount = (text: any) => {
    const words = text.split(/\s/).filter(Boolean) // Regex ile kelime parçalama
    return words.length
  }

  useEffect(() => {
    setAiQuestions([])
  }, [questionTypeValue])

  const [generateLoading, setGenerateLoading] = useState(false)

  const [aiQuestions, setAiQuestions] = useState<any>([])

  const handleNameChange = (event: any) => {
    const newName = event.target.value
    setTopic(newName)
  }
  const handleNameChange1 = (event) => {
    const inputText = event.target.value
    let words = inputText.trim().split(/\s+/) // Metni kelimelere ayır

    if (words.length > 3000) {
      // Kelime sayısı 3000'den fazlaysa, ilk 3000 kelimeyi koru ve gerisini kes
      words = words.slice(0, 3000)
      const trimmedText = words.join(' ') // Düzenlenmiş metni birleştir
      setTopic(trimmedText) // Düzenlenmiş metni güncelle
      setErrorTopic1('Max 3000 words') // Hata mesajını temizle, çünkü kullanıcıya sınırı aşan kısımları keserek yardımcı olduk
    } else {
      // Kelime sayısı 3000 veya altındaysa, metni doğrudan güncelle
      setTopic(inputText)
      setErrorTopic1('') // Hata mesajını temizle
    }
    setChanges(true)
  }

  async function handleGenerateAi() {
    setGenerateLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/ai/questionGenerator`,
        {
          topic: topic,
          questionType: questionTypeValue,
          language: outputLanguageValue,
          piece: questionsValue,
          maxOption: optionsValue,
          context: tabValue,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response: any) => {
        handleRemainingToken()
        setGenerateLoading(false)
        const receivedQuestions = response.data.questions
        const mergedAndShuffledData = mergeAndShuffleAnswers(receivedQuestions)
        let questionGenerator = {
          topic: topic,
          questionType: questionTypeValue,
          language: outputLanguageValue,
          piece: questionsValue,
          maxOption: optionsValue,
          context: tabValue,
          questionGeneratorId: response.data.questionGeneratorId,
        }
        sessionStorage.setItem('questionGenerator', JSON.stringify(questionGenerator))
        setAiQuestions(mergedAndShuffledData)
      })
      .catch((err: any) => {
        setGenerateLoading(false)
      })
  }
  const [saveLoading, setsaveLoading] = useState(false)

  function handleClear() {
    setAiQuestions([])
    setQuestionTypeValue('Single Correct Answer')
    setOutputLanguageValue('Auto')
    setQuestionsValue(5)
    setOptionsValue(4)
    setTopic('')
    setErrorTopic('')
    setErrorTopic1('')
    setTabValue('0')
    setFirst(!first)
    setGenerateLoading(false)
    setGenerateClick(0)
  }

  async function handleSaveFunc() {
    setGenerateClick(0)
    let questionGeneratorNotParsed: any = sessionStorage.getItem('questionGenerator')
    let questionGenerator = JSON.parse(questionGeneratorNotParsed)

    setsaveLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/ai/questionSave`,
        {
          ...questionGenerator,
          category: category,
          questions: aiQuestions,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response: any) => {
        enqueueSnackbar(`Successfuly Saved`, {
          variant: 'success',
        })
        setTimeout(() => {
          setOpen(false)
          setsaveLoading(false)
        }, 1000)

        sessionStorage.removeItem('questionGenerator')
        setFirst(!first)
      })
      .catch((err: any) => {
        setsaveLoading(false)
      })
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  function shuffleArray(array: any) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }

  function mergeAndShuffleAnswers(data: any) {
    return data.map((question: any) => {
      const correctAnswers = question.correct_options_list.map((answer: any) => {
        return { answer, isCorrect: true }
      })

      const wrongAnswers = question.wrong_answer_list.map((answer: any) => {
        return { answer, isCorrect: false }
      })

      const allAnswers = [...correctAnswers, ...wrongAnswers]

      return {
        questionNumber: question.question_number,
        questionDescription: question.question_description,
        answers: shuffleArray(allAnswers),
      }
    })
  }

  const handleQuestionChange = (index: any, newQuestion: any) => {
    const newQuestions: any = [...aiQuestions]
    newQuestions[index].questionDescription = newQuestion
    setAiQuestions(newQuestions)
  }

  const handleAnswerChange = (questionIndex: any, answerIndex: any, newAnswer: any) => {
    const newQuestions: any = [...aiQuestions]
    newQuestions[questionIndex].answers[answerIndex].answer = newAnswer
    setAiQuestions(newQuestions)
  }

  const handleRadioChange = (qIndex: any, aIndex: any) => {
    const newQuestions: any = [...aiQuestions]
    newQuestions[qIndex].answers.forEach((answer: any, index: any) => {
      answer.isCorrect = index === aIndex
    })
    setAiQuestions(newQuestions)
    // setAiQuestions or local state update here
  }
  const handleCheckboxChange = (qIndex: any, aIndex: any) => {
    const newQuestions: any = [...aiQuestions]
    newQuestions[qIndex].answers[aIndex].isCorrect = !newQuestions[qIndex].answers[aIndex].isCorrect
    setAiQuestions(newQuestions)
  }

  const handleDeleteQuestion = (qIndex: any) => {
    const newQuestions = [...aiQuestions]
    newQuestions.splice(qIndex, 1)
    setAiQuestions(newQuestions)
  }

  const handleDeleteAnswer = (qIndex: any, aIndex: any) => {
    const newQuestions = [...aiQuestions]
    newQuestions[qIndex].answers.splice(aIndex, 1)
    setAiQuestions(newQuestions)
  }
  const handleAddAnswer = (qIndex: any) => {
    const newQuestions = [...aiQuestions] // Mevcut soruların bir kopyasını al
    const newAnswer = { answer: '', isCorrect: false } // Yeni bir cevap nesnesi oluştur
    newQuestions[qIndex].answers.push(newAnswer) // Yeni cevabı ilgili sorunun cevaplarına ekle
    setAiQuestions(newQuestions) // Yeni soru listesini güncelle
  }

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  useEffect(() => {
    setIsButtonDisabled(!validateQuestions(aiQuestions))
  }, [aiQuestions])

  const validateQuestions = (questions: any) => {
    if (questions.length === 0) {
      return false
    }
    for (let question of questions) {
      if (!question.questionDescription) {
        return false
      }
      let trueCount = 0
      let falseCount = 0
      for (let answer of question.answers) {
        if (!answer.answer) {
          return false
        }
        if (answer.isCorrect) {
          trueCount++
        } else {
          falseCount++
        }
      }
      if (trueCount === 0 || falseCount === 0) {
        return false
      }
    }
    return true
  }

  const [categoryPage, setCategoryPage] = useState(false)
  const [categoriesLoading, setCategoriesLoading] = useState(false)

  const [categories, setCategories] = useState([])

  const [category, setCategory] = useState<any>(null)
  const [hover, setHover] = useState('')
  useEffect(() => {
    async function test() {
      setCategoriesLoading(true)
      await axios
        .get(`${process.env.REACT_APP_API_URL}/content/getAccessibleCategories`, {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        })
        .then((response) => {
          // expected the setProducts to be filled with the return of this request

          setCategories(response.data?.customerCategory)
          setCategoriesLoading(false)
        })
        .catch((err) => {
          setCategoriesLoading(false)
        })
    }
    if (categoryPage) {
      test()
    }
  }, [categoryPage])

  const [costGenerate, setCostGenerate] = useState<number>(0)
  const [costGenerateLoading, setCostGenerateLoading] = useState(false)
  const [generateRemaining, setGenerateRemaining] = useState<number>(0)
  const [generateRemainingLoading, setGenerateRemainingLoading] = useState(false)
  const [disabledGenerate, setDisabledGenerate] = useState(false)

  useEffect(() => {
    if (open) {
      handleRemainingToken()
    }
  }, [open])

  async function handleRemainingToken() {
    setGenerateRemainingLoading(true)
    await axios
      .get(`${process.env.REACT_APP_API_URL}/ai/remainingToken`, {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setGenerateRemaining(response.data)
        setGenerateRemainingLoading(false)
      })
      .catch((err) => {
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
        setGenerateRemainingLoading(false)
      })
  }
  const [debounceTimer, setDebounceTimer] = useState<any>(null)

  useEffect(() => {
    if (topic.trim() === '') {
      setDisabledGenerate(true)
      setCostGenerate(0)
      return
    }
    if (debounceTimer) {
      setDisabledGenerate(true)
      clearTimeout(debounceTimer)
    }

    setDebounceTimer(
      setTimeout(() => {
        handleTokenCalculator()
      }, 1000)
    )
  }, [topic, questionTypeValue, outputLanguageValue, questionsValue, optionsValue, tabValue])

  async function handleTokenCalculator() {
    setCostGenerateLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/ai/tokenCalculator`,
        {
          topic: topic,
          questionType: questionTypeValue,
          language: outputLanguageValue,
          piece: questionsValue,
          maxOption: optionsValue,
          context: tabValue,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setCostGenerate(response.data)
        setDisabledGenerate(false)
        setCostGenerateLoading(false)
      })
      .catch((err) => {
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
        setCostGenerateLoading(false)
      })
  }
  const [openWarnModal, setOpenWarnModal] = useState(false)
  const [generateClick, setGenerateClick] = useState(0)
  const [changes, setChanges] = useState(false)
  const [modalGenerateControl, setModalGenerateControl] = useState(false)
  const [warnModalContent, setWarnModalContent] = useState(
    'This operation will DISCARD the operation. The contents that you’ve just generated will be lost. Credits that you’ve spend for this process, can not be refunded. For extra security, you must confirm the process. Proceed?'
  )

  return (
    <>
      <Modal
        backdrop='static'
        keyboard={false}
        size='xl'
        show={open}
      /*  onHide={handleClose} */
      >
        <Modal.Header>
          <Modal.Title>
            <Typography variant='h4' fontWeight={'bold'}>
              Create Question With Ai
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '10px' }}>
          <Grid container height={'100%'}>
            {categoryPage === false && (
              <Grid xs={6}>
                <Grid xs={12} display={'flex'} justifyContent={'center'}>
                  <Box textAlign={'center'} sx={{ width: '100%' }}>
                    <Typography variant='subtitle1' fontWeight={'bold'} style={{ color: '#8E8E8E' }}>
                      Create Question With Ai
                    </Typography>
                    <TabContext value={tabValue}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                          centered
                          onChange={handleTabChange}
                          aria-label='lab API tabs example'
                        >
                          <Tab
                            disabled={tabValue === '1' && topic.length > 0}
                            label='SUBJECT'
                            value='0'
                          />
                          <Tab
                            disabled={tabValue === '0' && topic.length > 0}
                            label='CONTEXT'
                            value='1'
                          />
                        </TabList>
                      </Box>
                      <TabPanel value='0'>
                        <Grid container height={410} alignContent={'space-between'}>
                          <Grid xs={12} item>
                            <TextField
                              fullWidth
                              label='Topic'
                              variant='outlined'
                              placeholder='Min2 - Max256 characters'
                              required
                              value={topic}
                              onChange={(e: any) =>
                                e.target.value.length < 256
                                  ? (handleNameChange(e), setChanges(true))
                                  : null
                              }
                              onBlur={(event: any) => {
                                event.target.value.length > 0 &&
                                  (event.target.value.length < 2 || event.target.value.length > 255)
                                  ? setErrorTopic('Min2 - Max256 characters')
                                  : setErrorTopic('')
                              }}
                              error={Boolean(errorTopic)}
                              helperText={errorTopic}
                              inputProps={{
                                minLength: 2,
                                maxLength: 256,
                                endAdornment: costGenerateLoading && (
                                  <InputAdornment position='end'>
                                    <CircularProgress color='error' size={20} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              id='outlined-basic'
                              label='Question type'
                              variant='outlined'
                              style={{ marginTop: '30px' }}
                              required
                              fullWidth
                              select
                              value={questionTypeValue}
                              onChange={(e: any) => (
                                setQuestionTypeValue(e.target.value), setChanges(true)
                              )}
                            >
                              {questionType.map((option: any, i: number) => (
                                <MenuItem key={i} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                            <Box justifyContent={'space-between'} display={'flex'}>
                              <Box width={140}>
                                <TextField
                                  id='outlined-basic'
                                  label='Output language'
                                  variant='outlined'
                                  style={{ marginTop: '30px' }}
                                  required
                                  fullWidth
                                  select
                                  value={outputLanguageValue}
                                  onChange={(e: any) => (
                                    setOutputLanguageValue(e.target.value), setChanges(true)
                                  )}
                                >
                                  {outputLanguage.map((option: any, i: number) => (
                                    <MenuItem key={i} value={option.value}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Box>
                              <Box width={140}>
                                <TextField
                                  id='outlined-basic'
                                  label='Questions'
                                  variant='outlined'
                                  style={{ marginTop: '30px' }}
                                  required
                                  fullWidth
                                  select
                                  value={questionsValue}
                                  onChange={(e: any) => (
                                    setQuestionsValue(e.target.value), setChanges(true)
                                  )}
                                >
                                  {questions.map((option: any, i: number) => (
                                    <MenuItem key={i} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Box>
                              {questionTypeValue !== 'True-False' && (
                                <Box width={140}>
                                  <TextField
                                    id='outlined-basic'
                                    label='Options(Max)'
                                    variant='outlined'
                                    style={{ marginTop: '30px' }}
                                    required
                                    fullWidth
                                    select
                                    value={optionsValue}
                                    onChange={(e: any) => (
                                      setOptionsValue(e.target.value), setChanges(true)
                                    )}
                                  >
                                    {options.map((option: any, i: number) => (
                                      <MenuItem key={i} value={option}>
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Box>
                              )}
                            </Box>
                          </Grid>
                          <Grid
                            xs={12}
                            display={'flex'}
                            justifyContent={'center'}
                            direction={'column'}
                            alignSelf={'end'}
                          >
                            <Box>
                              The request will consume{' '}
                              {costGenerateLoading && <CircularProgress size={20} />}
                              {!costGenerateLoading && costGenerate} Ai credits.
                            </Box>

                            <div>
                              <Button
                                variant='contained'
                                color='secondary'
                                style={{ color: 'white' }}
                                onClick={() => (
                                  generateClick > 0
                                    ? (setWarnModalContent(
                                      'You have unsaved changes. Do you want to discard, anyway?'
                                    ),
                                      setModalGenerateControl(false),
                                      setOpenWarnModal(true))
                                    : handleGenerateAi(),
                                  setGenerateClick(generateClick + 1),
                                  setChanges(true)
                                )}
                                disabled={
                                  generateLoading ||
                                  disabledGenerate ||
                                  costGenerate > generateRemaining ||
                                  topic.length < 2 ||
                                  topic.length > 255
                                }
                              >
                                GENERATE QUESTIONS
                              </Button>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                              <Typography>
                                Ai credits remaining:{' '}
                                {generateRemainingLoading ? (
                                  <CircularProgress size={'small'} />
                                ) : (
                                  generateRemaining.toLocaleString('en-US')
                                )}
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value='1'>
                        <Grid container height={410} alignContent={'space-between'}>
                          <Grid xs={12} item>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography color={'#8E8E8E'}>Min 10, max 3.000 words</Typography>
                              <Typography color={'primary'}>{wordCount(topic)} words</Typography>
                            </div>
                            <TextField
                              fullWidth
                              /* label='Topic' */
                              variant='outlined'
                              placeholder='Enter some wordings here'
                              multiline
                              minRows={3}
                              maxRows={9}
                              required
                              value={topic}
                              onChange={(e: any) => (handleNameChange1(e), setChanges(true))}
                              onBlur={(event: any) => {
                                if (
                                  event.target.value.length > 0 &&
                                  !validateWordCount(event.target.value)
                                ) {
                                  setErrorTopic1('Min 10 - Max 3000 words')
                                } else {
                                  setErrorTopic1('')
                                }
                              }}
                              error={Boolean(errorTopic1)}
                              helperText={errorTopic1}
                              inputProps={{
                                className: 'center-placeholder',
                                minLength: 2,
                              }}
                            />

                            <TextField
                              id='outlined-basic'
                              label='Question type'
                              variant='outlined'
                              style={{ marginTop: '30px' }}
                              required
                              fullWidth
                              select
                              value={questionTypeValue}
                              onChange={(e: any) => (
                                setQuestionTypeValue(e.target.value), setChanges(true)
                              )}
                            >
                              {questionType.map((option: any, i: number) => (
                                <MenuItem key={i} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                            <Box justifyContent={'space-between'} display={'flex'}>
                              <Box width={140}>
                                <TextField
                                  id='outlined-basic'
                                  label='Output language'
                                  variant='outlined'
                                  style={{ marginTop: '30px' }}
                                  required
                                  fullWidth
                                  select
                                  value={outputLanguageValue}
                                  onChange={(e: any) => (
                                    setOutputLanguageValue(e.target.value), setChanges(true)
                                  )}
                                >
                                  {outputLanguage.map((option: any, i: number) => (
                                    <MenuItem key={i} value={option.value}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Box>
                              <Box width={140}>
                                <TextField
                                  id='outlined-basic'
                                  label='Questions'
                                  variant='outlined'
                                  style={{ marginTop: '30px' }}
                                  required
                                  fullWidth
                                  select
                                  value={questionsValue}
                                  onChange={(e: any) => (
                                    setQuestionsValue(e.target.value), setChanges(true)
                                  )}
                                >
                                  {questions.map((option: any, i: number) => (
                                    <MenuItem key={i} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Box>
                              {questionTypeValue !== 'True-False' && (
                                <Box width={140}>
                                  <TextField
                                    id='outlined-basic'
                                    label='Options(Max)'
                                    variant='outlined'
                                    style={{ marginTop: '30px' }}
                                    required
                                    fullWidth
                                    select
                                    value={optionsValue}
                                    onChange={(e: any) => (
                                      setOptionsValue(e.target.value), setChanges(true)
                                    )}
                                  >
                                    {options.map((option: any, i: number) => (
                                      <MenuItem key={i} value={option}>
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Box>
                              )}
                            </Box>
                          </Grid>
                          <Grid
                            xs={12}
                            display={'flex'}
                            justifyContent={'center'}
                            direction={'column'}
                            alignSelf={'end'}
                          >
                            <Typography>
                              The request will consume{' '}
                              {costGenerateLoading ? (
                                <CircularProgress size={'small'} />
                              ) : (
                                costGenerate
                              )}{' '}
                              Ai credits.
                            </Typography>
                            <div>
                              <Button
                                variant='contained'
                                color='secondary'
                                style={{ color: 'white' }}
                                onClick={() => (
                                  generateClick > 0
                                    ? (setWarnModalContent(
                                      'You have unsaved changes. Do you want to discard, anyway?'
                                    ),
                                      setModalGenerateControl(false),
                                      setOpenWarnModal(true))
                                    : handleGenerateAi(),
                                  setGenerateClick(generateClick + 1),
                                  setChanges(true)
                                )}
                                disabled={
                                  generateLoading ||
                                  disabledGenerate ||
                                  costGenerate > generateRemaining ||
                                  wordCount(topic) < 2 ||
                                  wordCount(topic) > 3000
                                }
                              >
                                GENERATE QUESTIONS
                              </Button>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                              <Typography>
                                Ai credits remaining:{' '}
                                {generateRemainingLoading ? (
                                  <CircularProgress size={'small'} />
                                ) : (
                                  generateRemaining.toLocaleString('en-US')
                                )}
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Grid>
              </Grid>
            )}
            {categoryPage === false && (
              <Grid
                xs={6}
                display={'flex'}
                alignItems={'center'}
                style={{ backgroundColor: '#9C27B00B' }}
              >
                <Grid
                  xs={12}
                  rowGap={2}
                  display={'flex'}
                  justifyContent={'center'}
                  direction={'column'}
                  padding={1}
                  height={540}
                >
                  {aiQuestions.length < 1 && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography variant='h6' style={{ color: '#8E8E8E' }}>
                        Questions and answer options will be listed here
                      </Typography>
                    </div>
                  )}
                  {generateLoading && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress />
                    </div>
                  )}
                  <Box overflow={'auto'}>
                    {aiQuestions?.map((question: any, qIndex: any) => (
                      <Paper
                        style={{
                          padding: '10px',
                          marginTop: qIndex !== 0 ? '15px' : '5px',
                          marginBottom: '5px',
                        }}
                      >
                        <div key={qIndex}>
                          <div style={{ display: 'flex' }}>
                            <TextField
                              /*  label='Soru' */
                              variant='outlined'
                              fullWidth
                              multiline
                              minRows={1}
                              maxRows={6}
                              value={question.questionDescription}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleQuestionChange(qIndex, event.target.value)
                              }}
                              /*  onChange={(e) => handleQuestionChange(qIndex, e.target.value)} */
                              InputProps={{
                                style: { fontWeight: 'bold' },
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    <Chip
                                      label={qIndex + 1}
                                      size='small'
                                      style={{
                                        background: '#FF0000DE',
                                        color: 'white',
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      edge='end'
                                      onClick={() => handleDeleteQuestion(qIndex)}
                                    >
                                      <DeleteIcon color='error' />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>

                          {question?.answers.map((answer: any, aIndex: any) => (
                            <div
                              style={{
                                padding: '0px 5px 5px 5px',
                                marginTop: '7px',
                                display: 'flex',
                                justifyContent: 'start',
                                flexDirection: 'column',
                              }}
                            >
                              <TextField
                                key={aIndex}
                                sx={{ width: '97%' }}
                                /*  label={`Cevap ${aIndex + 1}`} */
                                /*  focused={answer.isCorrect} */
                                /* color='success' */
                                variant='outlined'
                                size='small'
                                value={answer.answer}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleAnswerChange(qIndex, aIndex, e.target.value)
                                }
                                InputProps={{
                                  style: { fontWeight: answer.isCorrect ? 'bold' : 'normal' },
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      {questionTypeValue === 'Multiple Correct Answers' ? (
                                        <Checkbox
                                          checked={answer.isCorrect}
                                          onChange={() => handleCheckboxChange(qIndex, aIndex)}
                                        />
                                      ) : (
                                        <Radio
                                          checked={answer.isCorrect}
                                          onChange={() => handleRadioChange(qIndex, aIndex)}
                                        />
                                      )}
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <IconButton
                                        edge='end'
                                        onClick={() => handleDeleteAnswer(qIndex, aIndex)}
                                      >
                                        <DeleteIcon color='error' />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {aIndex === question.answers.length - 1 && (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '5px',
                                  }}
                                >
                                  <IconButton
                                    onClick={() => handleAddAnswer(qIndex)}
                                    aria-label='delete'
                                    size='small'
                                  >
                                    <AddIcon fontSize='inherit' />
                                  </IconButton>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </Paper>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            )}
            {categoryPage && (
              <Grid xs={12}>
                {categoriesLoading ? (
                  <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <div
                    className='d-flex modal-bodyy'
                    style={{
                      overflowY: 'scroll',
                      overflowX: 'scroll',
                      minHeight: '600px',
                      height: '600px',
                    }}
                  >
                    {categories?.map((data: any) => (
                      <>
                        <div>
                          {
                            //1.katman
                          }
                          <Accordion
                            style={{
                              marginRight: 20,
                              borderRadius: '10px 10px 5px 5px',
                              border: '1px solid #d5d5d5',
                              minWidth: '300px',
                            }}
                            defaultExpanded={true}
                          >
                            <AccordionSummary
                              /* expandIcon={
                                        <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                      } */
                              style={{
                                background: '#D5D5D5',
                                borderRadius: '10px 10px 0px 0px',
                                padding: '0px 10px 0px 0px',
                                minHeight: '60px',
                              }}
                            >
                              <div className='d-flex ' style={{ alignItems: 'center', height: 20 }}>
                                {/* <Radio
                            checked={Category == data.categoryId}
                            onClick={(e: any) => {
                              setcategoryName(data?.name)
                              setCategory(e.target.value)
                              e.stopPropagation()
                            }}
                            value={data.categoryId}
                            name='category'
                            color='success'
                            
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: '22px',
                              },
                              '&.Mui-checked': {
                                color: '#53b658',
                              },
                            }}
                          /> */}
                                <div
                                  style={{
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    marginLeft: '-4px',
                                  }}
                                >
                                  {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                    data.name.slice(1)}
                                </div>
                                <span
                                  title='Number of awailable question'
                                  className='text-dark  ms-2'
                                  style={{ fontSize: '10px', color: '#8e8e8e', fontWeight: 'bold' }}
                                >
                                  ({data.questionCount})
                                </span>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: '3px' }}>
                              {
                                //2. katman
                              }

                              {data?.children?.map((child: any) =>
                                child.children.length > 0 ? (
                                  <>
                                    <Accordion
                                      style={{
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                        boxShadow: 'none',
                                        borderRadius: '10px 10px 0px 0px',
                                      }}
                                      defaultExpanded={true}
                                    >
                                      <AccordionSummary
                                        sx={{
                                          margin: '0px!important',
                                          '.MuiAccordionSummary-content': {
                                            // AccordionSummary'nin içeriği için stil
                                            marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                          },
                                        }}
                                        style={{
                                          borderRadius: '10px 10px 0px 0px',
                                          padding: '0px 5px 0px 0px',
                                          minHeight: '35px',
                                          marginLeft: '0px!important',
                                          background: hover === child.categoryId ? '#e7e7e7' : '',
                                        }}
                                      >
                                        <div
                                          className='d-flex'
                                          style={{
                                            alignItems: 'center',
                                            justifyItems: 'start',
                                            height: 20,
                                          }}
                                          onMouseEnter={(e: any) => {
                                            setHover(child.categoryId)
                                          }}
                                          onMouseLeave={(e: any) => {
                                            setHover('')
                                          }}
                                        >
                                          {/* <Radio
                                      checked={Category == child.categoryId}
                                      onClick={(e: any) => {
                                        setcategoryName(child?.name)
                                        setCategory(e.target.value)
                                        e.stopPropagation()
                                      }}
                                      value={child.categoryId}
                                      name='category'
                                      color='success'
                                      sx={{
                                        '& .MuiSvgIcon-root': {
                                          fontSize: '22px',
                                        },
                                        '&.Mui-checked': {
                                          color: '#53b658',
                                        },
                                      }}
                                    /> */}
                                          <span
                                            className={'text-gray-800 d-flex'}
                                            style={{
                                              fontWeight: 'bold',
                                              cursor: 'pointer',
                                              fontSize: '12px',
                                              marginLeft: '-4px',
                                            }}
                                          >
                                            {child.name.charAt(0).toUpperCase() +
                                              child.name.slice(1)}
                                          </span>
                                          <span
                                            title='Number of awailable question'
                                            className='text-dark text-muted ms-2'
                                            style={{
                                              fontSize: '10px',
                                              color: '#8e8e8e',
                                              fontWeight: 'normal',
                                            }}
                                          >
                                            ({child.questionCount})
                                          </span>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails style={{ padding: '3px' }}>
                                        {child?.children?.map((child: any) => (
                                          <div id={child.categoryId} className='ms-0 mt-3 '>
                                            <div
                                              className='d-flex'
                                              style={{
                                                alignItems: 'center',
                                                height: 35,
                                                paddingLeft: 5,
                                                background:
                                                  hover === child.categoryId ? '#e7e7e7' : '',
                                              }}
                                              onMouseEnter={(e: any) => {
                                                setHover(child.categoryId)
                                              }}
                                              onMouseLeave={(e: any) => {
                                                setHover('')
                                              }}
                                            >
                                              <Radio
                                                checked={category == child.categoryId}
                                                onClick={(e: any) => {
                                                  setCategory(e.target.value)
                                                  e.stopPropagation()
                                                }}
                                                value={child.categoryId}
                                                name='category'
                                                color='success'
                                                /* size='small' */
                                                sx={{
                                                  width: '30px',
                                                  height: '30px',
                                                  '& .MuiSvgIcon-root': {
                                                    fontSize: '16px',
                                                  },
                                                  '&.Mui-checked': {
                                                    color: '#53b658',
                                                  },
                                                }}
                                              />
                                              <a
                                                className={'text-gray-800 d-flex '}
                                                style={{
                                                  cursor: 'pointer',
                                                  fontSize: '12px',
                                                  alignItems: 'center',
                                                  maxWidth: '210px',
                                                  marginLeft: '-4px',
                                                }}
                                              >
                                                {child.name
                                                  .charAt(0)
                                                  .toUpperCase()
                                                  .replace('🅜', '') + child.name.slice(1)}
                                              </a>

                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  height: '20px',
                                                }}
                                              >
                                                <span
                                                  title='Number of awailable question'
                                                  className='ms-2'
                                                  style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                >
                                                  {`(${child.questionCount})`}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </AccordionDetails>
                                    </Accordion>
                                  </>
                                ) : null
                              )}

                              {data?.children?.map((child: any) =>
                                child.children.length > 0 ? null : (
                                  <div id={child.categoryId} className='ms-0 mt-3 '>
                                    <div
                                      className='d-flex border-1'
                                      style={{
                                        alignItems: 'center',
                                        height: 35,
                                        paddingLeft: 4,
                                        background: hover === child.categoryId ? '#e7e7e7' : '',
                                      }}
                                      onMouseEnter={(e: any) => {
                                        setHover(child.categoryId)
                                      }}
                                      onMouseLeave={(e: any) => {
                                        setHover('')
                                      }}
                                    >
                                      <Radio
                                        checked={category == child.categoryId}
                                        onClick={(e: any) => {
                                          setCategory(e.target.value)
                                          e.stopPropagation()
                                        }}
                                        value={child.categoryId}
                                        name='category'
                                        color='success'
                                        /* size='small' */
                                        sx={{
                                          '& .MuiSvgIcon-root': {
                                            fontSize: '22px',
                                          },
                                          '&.Mui-checked': {
                                            color: '#53b658',
                                          },
                                        }}
                                      />
                                      <a
                                        className={'text-gray-800 d-flex'}
                                        style={{
                                          cursor: 'pointer',
                                          fontSize: '12px',
                                          maxWidth: '210px',
                                          marginLeft: '-4px',
                                        }}
                                      >
                                        {child.name.charAt(0).toUpperCase() + child.name.slice(1)}
                                      </a>

                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          height: '20px',
                                        }}
                                      >
                                        <span
                                          title='Number of awailable question'
                                          className='ms-2'
                                          style={{ color: '#8e8e8e', fontSize: '10px' }}
                                        >
                                          {`(${child.questionCount})`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </Modal.Body>
        <ModalFooter>
          <Button
            variant='contained'
            color='inherit'
            style={{ marginRight: '20px' }}
            onClick={() =>
              categoryPage
                ? setCategoryPage(false)
                : (setOpenWarnModal(true),
                  setModalGenerateControl(true),
                  setWarnModalContent(
                    'This operation will DISCARD the operation. The contents that you’ve just generated will be lost. Credits that you’ve spend for this process, can not be refunded. For extra security, you must confirm the process. Proceed?'
                  ))
            }
          >
            DISCARD
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={
              saveLoading || generateLoading || categoryPage === false
                ? isButtonDisabled
                : !category
            }
            onClick={() =>
              categoryPage ? handleSaveFunc() : (setCategoryPage(true), setChanges(true))
            }
          >
            SAVE{' '}
            {saveLoading && <CircularProgress color='inherit' size={14} style={{ marginLeft: 5 }} />}
          </Button>
        </ModalFooter>
      </Modal>
      {
        //warn modal
      }
      <Dialog
        open={openWarnModal}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Typography color='white'>{warnModalContent}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenWarnModal(false)} style={{ color: 'white' }}>
            No
          </Button>
          <Button
            onClick={() =>
              modalGenerateControl === false
                ? (handleGenerateAi(), setOpenWarnModal(false))
                : (setOpenWarnModal(false), handleClickModalClose())
            }
            style={{ color: 'white' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
