/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {ID} from '../../../_metronic/helpers'

type Props = {
  id: ID
}

const DashboardPage: FC<Props> = ({id}) => {
 
  return (
    <>
    <div>
      AAA
    </div>
    </>
  )
}

const GDashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <DashboardPage id={undefined} />
    </>
  )
}

export {GDashboardWrapper}
