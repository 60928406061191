import React, { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Radio,
  Step,
  StepButton,
  Stepper,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import * as XLSX from 'xlsx'
import CloseIcon from '@mui/icons-material/Close'
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { AddCategory } from './AddCategory'
import { enqueueSnackbar } from 'notistack'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  /* border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  }, */
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ height: 20 }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  /*  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)', */
  /*   flexDirection: 'row-reverse', */
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  /*   borderTop: '1px solid rgba(0, 0, 0, .125)', */
}))

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export interface Props {
  open: boolean
  setOpen: any
  first: any
  setFirst: any
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

function ImportQuestionModal({ open, setOpen, first, setFirst }: Props) {
  const LoginState = useSelector((state: any) => state.Login)
  const [second, setsecond] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setStep(0)
    setfile('')
    setisSelected(false)
    setText('')
    setLoadingExcel(false)
    setCheck('')
    setCategory('')
    handleDelete()
  }
  const [step, setStep] = React.useState(0)
  let steps = ['Upload Excel file', 'Select category', 'Finish']

  const [state, setState] = useState({
    photo: ' ',
  })
  const [text, setText] = useState<any>('')
  const [excelZoom, setExcelZoom] = useState(false)
  const [selectedFileName, setSelectedFileName] = useState('')
  const [SendData, setSendData] = useState<any[]>([])
  const [file, setfile] = useState<any>('')
  const [check, setCheck] = useState<any>('')
  const [isSelected, setisSelected] = useState(false)
  const readExcel = (file: any) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)

      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result
        const wb = XLSX.read(bufferArray, { type: 'buffer' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]

        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1,
        })

        resolve(data)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })

    promise.then((d: any) => {
      // setSendData(Array.from(d).map((e:any)=>e))
      var rownum = 0

      const data = d.map(function (item: any) {
        rownum++
        return {
          rowNum: rownum,
          description: item[0],
          questionAnswers: item
            .map((index: any, i: number) => {
              if (i !== 0 && index) {
                if (index?.toString().charAt(0) === '*') {
                  return {
                    description: index.slice(1),
                    isCorrect: true,
                  }
                } else {
                  return {
                    description: index,
                    isCorrect: false,
                  }
                }
              }
            })
            .slice(1),
        }
      })

      const results = data
        .filter((element) => element.description) // description'ı olmayanları filtrele
        .map((element, i) => {
          // questionAnswers dizisinde null olmayanları filtrele
          const filteredAnswers = element.questionAnswers
            ? element.questionAnswers.filter((answer) => answer !== null)
            : []

          // Yeni elementi, filtrelenmiş questionAnswers ile birlikte oluştur
          return {
            ...element,
            questionAnswers: filteredAnswers,
          }
        })

      setSendData(results)
    })
  }

  const onChange = (event: any) => {
    setisSelected(true)
    const Ulanyeterbe = 1
    event.persist()
    setState((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }))

    state.photo = event.target.value
    setText(state.photo)
    return text
  }
  const fileInputRef = useRef(null)

  const handleFileChange = (event) => {
    const file = event.target.files[0]

    setfile(event?.target.files[0])
    readExcel(file)
    onChange(event)
    if (file) {
      setSelectedFileName(file.name) // Dosya adını durum değişkenine kaydet
    }
  }
  const handleDelete = () => {
    setSelectedFileName(null)
    setfile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }
  const [text2, settext2] = useState<any>('')
  const [text3, settext3] = useState<any>('')
  useEffect(() => {
    settext2(text?.substr(text?.indexOf('.')))
  }, [text])
  useEffect(() => {
    settext3(text2.slice(1))
  }, [text2])

  const [categoryName, setcategoryName] = useState<any>('Select Category')
  const [Category, setCategory] = useState<any>('')
  const [categories, setCategories] = useState<any>([])
  const customerProfile = useSelector((state: any) => state.Login.customerProfile)
  const [getCategoriesLoading, setGetCategoriesLoading] = useState(false)

  function test() {
    setGetCategoriesLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/content/getAccessibleCategories`, {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        if (customerProfile?.customerId === 0) {
          setCategories(response.data.customerCategory)
        } else {
          setCategories(response.data.customerCategory)
        }
        setGetCategoriesLoading(false)
      })
      .catch((err) => {
        setGetCategoriesLoading(false)
      })
  }

  useEffect(() => {
    if (customerProfile) {
      test()
    }
  }, [customerProfile, second])

  const [loadingExcel, setLoadingExcel] = useState(false)

  function handleFinish() {
    var SendDataFiltered = SendData.filter((item) => item.description?.toLowerCase() != 'question')

    setLoadingExcel(true)
    const axios = require('axios')
    const form = new FormData()
    form.append('file', file)
    //422 send dataya bak
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/content/importQuestionsFromExcel/${customerProfile?.customerId}/${Category}`,
        SendDataFiltered,
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
          timeout: 90000,
        }
      )
      .then(function (response: any) {
        setCheck(response.data)
        setTimeout(() => {
          setLoadingExcel(false)
        }, 2000)
        /*    handleDelete() */
        setTimeout(() => {
          setsecond(!second)
          setFirst(!first)
        }, 1000)
        if (response.successfullRecords > 0) {
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/content/uploadQuestionExcelFile/${customerProfile?.customerId}/${Category}/${text3}`,
              form,
              {
                headers: {
                  accept: 'application/json',
                  Authorization: `Bearer ${LoginState.loginData.access_token}`,
                  'Content-Type': 'multipart/form-data',
                },
                timeout: 90000,
              }
            )
            .then((res) => {
              setFirst(!first)
            })
            .catch((err) => { })
        }
        // setCheck(response)
      })
      .catch((error) => {
        error.response.data.detail.errorMessage
          ? enqueueSnackbar(`${error.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
        setLoadingExcel(false)
      })
  }
  const [hover, setHover] = useState('')

  const getUniqueErrorRows = (check) => {
    const allErrors = Object.values(check).join(',').split(',').filter(Boolean)
    const uniqueErrors = new Set(allErrors)
    return uniqueErrors.size // Benzersiz hatalı satır sayısını döndürür
  }

  // Toplam hatalı satır sayısını kontrol et
  const totalErrorRows = getUniqueErrorRows(check)

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={'lg'}
        /* onClose={handleClose} */
        /*   style={{zIndex: 100}} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography fontSize={26} fontWeight={'bold'} color={'#212529'}>
            Import Questions
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers /* style={{height: '400px'}} */>
          <Grid container>
            {
              //#region  stepper
              <Grid xs={12} display={'flex'} justifyContent={'center'}>
                <Grid xs={8}>
                  <Stepper /* alternativeLabel */ activeStep={step}>
                    {steps.map((label) => (
                      <Step
                        key={label}
                        sx={{
                          '& .MuiStepIcon-root': {
                            fontSize: '25px',
                            color: '#9E9E9E', // Varsayılan renk
                            fontWeight: 'bold',
                          },
                          '& .Mui-active, & .Mui-completed': {
                            color: '#9c27b0 !important',
                          },
                        }}
                      >
                        <StepButton
                          sx={{
                            '& .MuiStepLabel-label': {
                              fontSize: '15px', // Label'ların font boyutunu ayarlayın
                            },
                            '& .Mui-active': {
                              fontWeight: 'bold',
                            },
                          }}
                        >
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
              </Grid>
              //#endregion
            }
            {step === 0 && (
              <Grid xs={12} marginTop={2} display={'flex'} justifyContent={'center'}>
                <Grid xs={5} container /* columnGap={2} */ rowGap={1}>
                  <Grid xs={3} display={'flex'} justifyContent={'start'}>
                    <Typography fontSize={12} fontWeight={'bold'}>
                      {' '}
                      Things width NO limit
                    </Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography color={'#8E8E8E'} fontSize={12}>
                      The number of questions to be uploaded at a time. The number of answers for
                      each question.
                    </Typography>
                  </Grid>
                  <Grid xs={3} display={'flex'} justifyContent={'start'}>
                    <Typography fontSize={12} fontWeight={'bold'}>
                      {' '}
                      Things width limitations
                    </Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography color={'#8E8E8E'} fontSize={12}>
                      Max 750 of text is allowed for a question. Max 250 for an answer option.
                    </Typography>
                  </Grid>
                  <Grid xs={3} display={'flex'} justifyContent={'start'}>
                    <Typography fontSize={12} fontWeight={'bold'}>
                      {' '}
                      NOT important
                    </Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography color={'#8E8E8E'} fontSize={12}>
                      The order of questions. <br />
                      The order of answers.
                      <br /> File name, as well as the name of the sheet.
                      <br />
                      Fonts, colors, header row.
                    </Typography>
                  </Grid>
                  <Grid xs={3} display={'flex'} justifyContent={'start'}>
                    <Typography fontSize={12} fontWeight={'bold'}>
                      {' '}
                      Important
                    </Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography color={'#8E8E8E'} fontSize={12}>
                      The first column of your Excel sheet must contain question text and following
                      all the columns can be used for the answers. <br />
                      Questions must have… <br />
                      …at least 2 answer options, <br />
                      …at least 1 right answer, <br />
                      …at least 1 wrong answer.
                    </Typography>
                  </Grid>
                  <Grid xs={3} display={'flex'} justifyContent={'start'}>
                    <Typography fontSize={12} fontWeight={'bold'}>
                      Spotting the right answer
                    </Typography>
                  </Grid>
                  <Grid xs={8}>
                    <Typography color={'#8E8E8E'} fontSize={12}>
                      To differentiate right answers, just add an asterisk * at the beginning. You
                      can define more than one right answer for a question! In this case, Mobixa
                      will randomly choose one of them at every time this question is asked.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={6}>
                  <Button
                    variant='text'
                    onClick={() => setExcelZoom(!excelZoom)}
                    style={{ padding: '5px', width: '100%' }}
                  >
                    <Avatar
                      src='/media/questionWizardExcel.png'
                      variant='rounded'
                      style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                    />
                  </Button>
                  <Backdrop
                    sx={{ color: '#fff', padding: 10, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={excelZoom}
                    onClick={() => setExcelZoom(false)}
                  >
                    <Avatar
                      src='/media/questionWizardExcel.png'
                      variant='rounded'
                      style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                    />
                  </Backdrop>
                  <Box width={'100%'} display={'flex'} justifyContent={'end'}>
                    <Typography fontSize={10}>Example Excel Sheet</Typography>
                  </Box>
                  <Box
                    width={'100%'}
                    marginTop={2}
                    marginBottom={2}
                    display={'flex'}
                    justifyContent={'center'}
                    alignContent={'center'}
                  /*     flexDirection={'column'} */
                  >
                    <Button
                      component='label'
                      variant='contained'
                      style={{ background: '#9C27B0' }}
                      startIcon={<UploadOutlinedIcon />}
                    >
                      SELECT FILE
                      <VisuallyHiddenInput
                        type='file'
                        onChange={handleFileChange}
                        accept='.xlsx,.xls,.xlsm'
                        ref={fileInputRef}
                      />
                    </Button>
                    {file && (
                      <Box
                        /*   marginTop={1} */
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Typography marginLeft={3} color={'#6E6F6F'}>
                          Selected File:
                        </Typography>

                        <Typography marginLeft={1} fontWeight={'bold'}>
                          {selectedFileName}
                        </Typography>
                        {/* <Tooltip title='Delete' placement='top'>
                          <IconButton
                            size='small'
                            style={{marginLeft: '5px'}}
                            onClick={() => handleDelete()}
                          >
                            <CloseIcon fontSize='small' color='error' />
                          </IconButton>
                        </Tooltip> */}
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
            {step === 1 && (
              <Grid xs={12} marginTop={2}>
                {/* <Grid xs={12} paddingLeft={2} marginBottom={1}>
                  <Typography fontSize={12} color={'#8E8E8E'}>
                    Choose the category in which the new users will be added. Category selection is
                    required!
                  </Typography>
                </Grid> */}
                <div
                  className='d-flex modal-bodyy'
                  style={{
                    overflowY: 'scroll',
                    overflowX: 'scroll',
                    height: '400px',
                  }}
                >
                  {getCategoriesLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    categories?.map((data: any) => (
                      <>
                        <div>
                          {
                            //1.katman
                          }
                          <Accordion
                            style={{
                              marginRight: 20,
                              borderRadius: '10px 10px 5px 5px',
                              border: '1px solid #d5d5d5',
                              minWidth: '300px',
                            }}
                            defaultExpanded={true}
                          >
                            <AccordionSummary
                              /* expandIcon={
                                  <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                } */
                              style={{
                                background: '#D5D5D5',
                                borderRadius: '10px 10px 0px 0px',
                                padding: '0px 10px 0px 0px',
                                minHeight: '60px',
                              }}
                            >
                              <div className='d-flex ' style={{ alignItems: 'center', height: 20 }}>
                                {/* <Radio
                            checked={Category == data.categoryId}
                            onClick={(e: any) => {
                              setcategoryName(data?.name)
                              setCategory(e.target.value)
                              e.stopPropagation()
                            }}
                            value={data.categoryId}
                            name='category'
                            color='success'
                            
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: '22px',
                              },
                              '&.Mui-checked': {
                                color: '#53b658',
                              },
                            }}
                          /> */}
                                <div
                                  style={{
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    marginLeft: '-4px',
                                  }}
                                >
                                  {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                    data.name.slice(1)}
                                </div>
                                <span
                                  title='Number of available questions'
                                  className='text-dark  ms-2'
                                  style={{ fontSize: '10px', color: '#8e8e8e', fontWeight: 'bold' }}
                                >
                                  ({data.questionCount})
                                </span>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: '3px' }}>
                              {
                                //2. katman
                              }

                              {data?.children?.map((child: any) =>
                                child.children.length > 0 ? (
                                  <>
                                    <Accordion
                                      style={{
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                        boxShadow: 'none',
                                        borderRadius: '10px 10px 0px 0px',
                                      }}
                                      defaultExpanded={true}
                                    >
                                      <AccordionSummary
                                        sx={{
                                          margin: '0px!important',
                                          '.MuiAccordionSummary-content': {
                                            // AccordionSummary'nin içeriği için stil
                                            marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                          },
                                        }}
                                        style={{
                                          borderRadius: '10px 10px 0px 0px',
                                          padding: '0px 5px 0px 0px',
                                          minHeight: '35px',
                                          marginLeft: '0px!important',
                                          background: hover === child.categoryId ? '#e7e7e7' : '',
                                        }}
                                      >
                                        <div
                                          className='d-flex'
                                          style={{
                                            alignItems: 'center',
                                            justifyItems: 'start',
                                            height: 20,
                                          }}
                                          onMouseEnter={(e: any) => {
                                            setHover(child.categoryId)
                                          }}
                                          onMouseLeave={(e: any) => {
                                            setHover('')
                                          }}
                                        >
                                          {/* <Radio
                                      checked={Category == child.categoryId}
                                      onClick={(e: any) => {
                                        setcategoryName(child?.name)
                                        setCategory(e.target.value)
                                        e.stopPropagation()
                                      }}
                                      value={child.categoryId}
                                      name='category'
                                      color='success'
                                      sx={{
                                        '& .MuiSvgIcon-root': {
                                          fontSize: '22px',
                                        },
                                        '&.Mui-checked': {
                                          color: '#53b658',
                                        },
                                      }}
                                    /> */}
                                          <span
                                            className={'text-gray-800 d-flex'}
                                            style={{
                                              fontWeight: 'bold',
                                              cursor: 'pointer',
                                              fontSize: '12px',
                                              marginLeft: '-4px',
                                            }}
                                          >
                                            {child.name.charAt(0).toUpperCase() +
                                              child.name.slice(1)}
                                          </span>
                                          <span
                                            title='Number of available questions'
                                            className='text-dark text-muted ms-2'
                                            style={{
                                              fontSize: '10px',
                                              color: '#8e8e8e',
                                              fontWeight: 'normal',
                                            }}
                                          >
                                            ({child.questionCount})
                                          </span>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails style={{ padding: '3px' }}>
                                        {child?.children?.map((child: any) => (
                                          <div id={child.categoryId} className='ms-0 mt-3 '>
                                            <div
                                              className='d-flex'
                                              style={{
                                                alignItems: 'center',
                                                height: 35,
                                                paddingLeft: 5,
                                                background:
                                                  hover === child.categoryId ? '#e7e7e7' : '',
                                              }}
                                              onMouseEnter={(e: any) => {
                                                setHover(child.categoryId)
                                              }}
                                              onMouseLeave={(e: any) => {
                                                setHover('')
                                              }}
                                            >
                                              <Radio
                                                checked={Category == child.categoryId}
                                                onClick={(e: any) => {
                                                  setcategoryName(child?.name)
                                                  setCategory(e.target.value)
                                                  e.stopPropagation()
                                                }}
                                                value={child.categoryId}
                                                name='category'
                                                color='success'
                                                /* size='small' */
                                                sx={{
                                                  width: '30px',
                                                  height: '30px',
                                                  '& .MuiSvgIcon-root': {
                                                    fontSize: '16px',
                                                  },
                                                  '&.Mui-checked': {
                                                    color: '#53b658',
                                                  },
                                                }}
                                              />
                                              <a
                                                className={'text-gray-800 d-flex '}
                                                style={{
                                                  cursor: 'pointer',
                                                  fontSize: '12px',
                                                  alignItems: 'center',
                                                  maxWidth: '210px',
                                                  marginLeft: '-4px',
                                                }}
                                              >
                                                {child.name
                                                  .charAt(0)
                                                  .toUpperCase()
                                                  .replace('🅜', '') + child.name.slice(1)}
                                              </a>

                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  height: '20px',
                                                }}
                                              >
                                                <span
                                                  title='Number of available questions'
                                                  className='ms-2'
                                                  style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                >
                                                  {`(${child.questionCount})`}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </AccordionDetails>
                                    </Accordion>
                                  </>
                                ) : null
                              )}
                              {
                                // 3. katman
                              }

                              {data?.children?.map((child: any) =>
                                child.children.length > 0 ? null : (
                                  <div id={child.categoryId} className='ms-0 mt-3 '>
                                    <div
                                      className='d-flex border-1'
                                      style={{
                                        alignItems: 'center',
                                        height: 35,
                                        paddingLeft: 4,
                                        background: hover === child.categoryId ? '#e7e7e7' : '',
                                      }}
                                      onMouseEnter={(e: any) => {
                                        setHover(child.categoryId)
                                      }}
                                      onMouseLeave={(e: any) => {
                                        setHover('')
                                      }}
                                    >
                                      <Radio
                                        checked={Category == child.categoryId}
                                        onClick={(e: any) => {
                                          setcategoryName(child?.name)
                                          setCategory(e.target.value)
                                          e.stopPropagation()
                                        }}
                                        value={child.categoryId}
                                        name='category'
                                        color='success'
                                        /* size='small' */
                                        sx={{
                                          '& .MuiSvgIcon-root': {
                                            fontSize: '22px',
                                          },
                                          '&.Mui-checked': {
                                            color: '#53b658',
                                          },
                                        }}
                                      />
                                      <a
                                        className={'text-gray-800 d-flex'}
                                        style={{
                                          cursor: 'pointer',
                                          fontSize: '12px',
                                          maxWidth: '210px',
                                          marginLeft: '-4px',
                                        }}
                                      >
                                        {child.name.charAt(0).toUpperCase() + child.name.slice(1)}
                                      </a>

                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          height: '20px',
                                        }}
                                      >
                                        <span
                                          title='Number of available questions'
                                          className='ms-2'
                                          style={{ color: '#8e8e8e', fontSize: '10px' }}
                                        >
                                          {`(${child.questionCount})`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </>
                    ))
                  )}
                </div>
              </Grid>
            )}
            {step === 2 && (
              <Grid xs={12} padding={2} marginTop={3}>
                {loadingExcel && (
                  <Box
                    height={'250px'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                  >
                    <Typography fontSize={18} color={'#6C757D'}>
                      Processing...
                    </Typography>
                    <CircularProgress size={40} />
                  </Box>
                )}
                {
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    height={'280px'}
                  >
                    {check?.successfullRecords > 0 && (
                      <Paper style={{ minWidth: '350px', height: '70px', background: '#2E7D32' }}>
                        <Box
                          padding={1}
                          display={'flex'}
                          justifyContent={'start'}
                          alignContent={'center'}
                          textAlign={'center'}
                        >
                          <CheckCircleOutlineIcon style={{ color: 'white' }} />
                          <Typography marginLeft={1} color={'white'}>
                            Success
                          </Typography>
                        </Box>
                        <Typography marginLeft={1} color={'white'}>
                          Success {check?.successfullRecords} records have been added to "
                          {categoryName}" category successfully.
                        </Typography>
                      </Paper>
                    )}
                    {check?.errorRecords > 0 && (
                      <Paper style={{ minWidth: '350px', height: 'auto', background: '#D32F2F' }}>
                        <Box
                          padding={1}
                          display={'flex'}
                          justifyContent={'start'}
                          alignItems={'center'}
                        /* textAlign={'end'} */
                        >
                          <ErrorOutlineIcon style={{ color: 'white' }} />
                          <Typography marginLeft={1} color={'white'}>
                            Error
                          </Typography>
                        </Box>
                        {totalErrorRows < 10 && check?.lessThanTwoAnswer && (
                          <Typography marginLeft={1} color={'white'}>
                            Rows {check?.lessThanTwoAnswer} have less than 2 answer options.
                          </Typography>
                        )}

                        {totalErrorRows < 10 && check?.hasNoCorrectAnswer && (
                          <Typography marginLeft={1} color={'white'}>
                            Row {check?.hasNoCorrectAnswer} has NO right answer.
                          </Typography>
                        )}

                        {totalErrorRows < 10 && check?.hasNoWrongAnswer && (
                          <Typography marginLeft={1} color={'white'}>
                            Rows {check?.hasNoWrongAnswer} has NO wrong answer.
                          </Typography>
                        )}

                        {totalErrorRows < 10 && check?.questionIsTooLong && (
                          <Typography marginLeft={1} color={'white'}>
                            Rows {check?.questionIsTooLong} question exceeds 750 character limit.
                          </Typography>
                        )}
                        {totalErrorRows < 10 && check?.answerIsTooLong && (
                          <Typography marginLeft={1} color={'white'}>
                            Rows {check?.answerIsTooLong} answer exceeds 250 character limit.
                          </Typography>
                        )}
                        {totalErrorRows > 10 && (
                          <Typography marginLeft={1} marginTop={1} color={'white'}>
                            There are more than 10 rows consisting of errors in your file. Please
                            make the corrections and try again.
                          </Typography>
                        )}
                        {
                          totalErrorRows < 10 &&
                          <Typography marginLeft={1} marginTop={1} color={'white'}>
                            Please make the corrections and try again.
                          </Typography>
                        }
                      </Paper>
                    )}
                  </Box>
                }
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ width: '95%' }}>
          {/*  {step === 1 && (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Typography fontSize={12} color={'#8E8E8E'} marginRight={3}>
                Not in the list ?
              </Typography>
              <AddCategory
                company={customerProfile?.customerId}
                second={second}
                setSecond={setsecond}
              />
            </div>
          )} */}

          <Button
            variant='contained'
            color='inherit'
            style={{ marginLeft: '30px' }}
            onClick={() => (step !== 0 && setStep(step - 1), step === 0 && handleClose())}
          >
            {step === 0 ? 'DISCARD' : 'BACK'}
          </Button>
          <Button
            variant='contained'
            disabled={(step === 0 && !file) || (step === 1 && !Category) || loadingExcel}
            style={{ marginRight: '30px', marginLeft: '20px' }}
            onClick={() => (
              step !== 2 && setStep(step + 1),
              step === 1 && handleFinish(),
              step === 2 && handleClose()
            )}
          >
            {step === 2 ? 'DONE' : 'NEXT'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ImportQuestionModal
