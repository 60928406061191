import React, {FC, useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import {Formik, isEmptyArray} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useSearchParams} from 'react-router-dom'
import axios from 'axios'
const Schema = Yup.object().shape({
  password: Yup.string().min(3, 'Minimum 3 symbols').required('This field is required'),
  changepassword: Yup.string().when('password', {
    is: (val: string | any[]) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
  }),
})
type Props = {
  location?: any
}
export const NewUserPassword: FC<Props> = ({location}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  searchParams.get('token')
  const [token, setToken] = useState(searchParams.get('token'))
  const [newPassword, setPassword] = useState<any>()
  const ax = axios.create({headers: {Accept: 'application/json'}})

  function test() {
    const res = ax
      .post(`${process.env.REACT_APP_API_URL}/user/definePassword`, 
        newPassword,
        {headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
      })
      .catch(function (error) {
      })
  }
  function handleClick2() {
    var myElement = document.getElementById('deneme3')
    //myElement.classList.add("anotherclass");
    myElement?.classList.add('d-none')
    var myElement1 = document.getElementById('deneme4')
    myElement1?.classList.remove('d-none')
    test()
  }
  
  return (
    <Formik
      initialValues={{
        password: '',
        changepassword: '',
      }}
      validationSchema={Schema}
      onSubmit={() => {
      }}
    >
      {({values, errors, handleSubmit, handleChange, handleBlur}) => {
        setPassword(values.changepassword)
        return (
          <>
            <form className='row' onSubmit={handleSubmit} id='deneme3'>
              <label className='col-12 mt-15 text-start ' htmlFor='passowrd'>
                Password
              </label>
              <input
                className={clsx(
                  'form-control form-control-lg mt-5',
                  {'is-invalid': values.password.length > 2 && values.password.length <= 3},
                  {'is-valid': values.password.length >= 4}
                )}
                type='password'
                name='password'
                id='password'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
              />
              <span className='text-danger text-start'>{errors.password}</span>

              <label className='col-12 mt-10 text-start' htmlFor='passowrd'>
                Confirm Password
              </label>
              <input
                className={clsx(
                  'form-control form-control-lg mt-5 ',
                  {
                    'is-invalid':
                      values.changepassword.length >= 3 &&
                      values.password !== values.changepassword,
                  },
                  {
                    'is-valid':
                      values.changepassword.length >= 3 &&
                      values.password === values.changepassword,
                  }
                )}
                type='password'
                name='changepassword'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.changepassword}
              />
              <span className='text-danger text-start'>{errors.changepassword}</span>
              <div className='col-12 w-100 text-center mt-10'>
                <button
                  className={clsx('btn btn-primary w-25', {
                    disabled:
                      values.password !== values.changepassword || values.password.length < 3,
                  })}
                  onClick={handleClick2}
                >
                  OK
                </button>
              </div>
            </form>
            <div className=' text-center d-none' id='deneme4'>
              <div className='d-flex justify-content-between'>
              <button className='col-3 btn btn-dark border border-secondary' disabled></button>
              <button className='col-3 btn btn-dark border border-secondary' disabled></button>
              <button className='col-3 btn btn-dark border border-secondary' disabled></button>
              </div>
              <div className='col-12 mt-20 mb-20'>
                <span className='h1'>HURRAY!</span>
                <br />
                <br />
                <br />
                <br />
                <span className='h2 mt-20'>Your password created.</span>
              </div>
              <button className='btn btn-danger'>
                {' '}
                <a className='text-white' href='/login'>
                  Back Home
                </a>{' '}
              </button>
            </div>
          </>
        )
      }}
    </Formik>
  )
}
