import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getQuestionRequest: ['body'],
    getQuestionSuccess: ['data'],
    getQuestionFailure: ['error']

})

export const QuestionTypes = Types
export default Creators


/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({

    questions: [],
    questionFetching: false,
    questionErrorMessage: '',
    questionError: false,

})


/* ------------- Reducers ------------- */
export const getQuestionRequest = (state, action) => {
    return state.merge({ questionFetching: true, questionErrorMessage: '', questionError: false, })
}

export const getQuestionSuccess = (state, action) => {
    // 
    return state.merge({ questions: action.data.Data, questionFetching: false, questionErrorMessage: '', questionError: false })
}

export const getQuestionFailure = (state, action) => {
    return state.merge({questionFetching: false, questionErrorMessage: action.error, questionError: true })
}


/* ------------- Connection Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_QUESTION_REQUEST]: getQuestionRequest,
    [Types.GET_QUESTION_SUCCESS]: getQuestionSuccess,
    [Types.GET_QUESTION_FAILURE]: getQuestionFailure,
})