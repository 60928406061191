import { spawn } from 'child_process'
import React, { FC, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { SelectAwardMedia } from './SelectAwardMedia'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { Avatar, Box, Grid, Paper, Typography } from '@mui/material'
import SelectContentNew from './SelectContentNew'


type Props = {
  /*   setAwardPrivate: (isUserLoading: boolean) => void */
  awardDetail?: any
  setAwardData: any
  awardData: any
  setAwardIsTrue: any
  awardIsTrue: boolean
  setSelectedAwardValue: any
  selectedAwardValue: any
  award: any
  setChanged?:any
}

const Award: FC<Props> = ({
  setAwardIsTrue,
  awardIsTrue,
  setAwardData,
  setSelectedAwardValue,
  selectedAwardValue,
  awardData,
  awardDetail,
  award,
  setChanged
}) => {
  const DefaoultImg = toAbsoluteUrl('/media/avatars/blank.png')
  //const [show, setShow] = useState(false);

  const [selectedImage, setSelectedImage] = useState<any>('')
  const [dataType, setDataType] = useState<any>(0)
  const [awardtext, setAwardText] = useState<any>('')
  const [file, setfile] = useState<any>('')

  const changeMediaUrl = (value: string, index: number, mediaType: number) => {
    setSelectedImage(value)
    setDataType(mediaType)
    setChanged(true)
  }

  useEffect(() => {
    if (awardData) {
      setDataType(awardData.awardMediaType)
      setAwardText(awardData.awardMediaText)
      setSelectedImage(awardData.awardMediaUrl)
      setfile(awardData.file)
    }
  }, [])

  useEffect(() => {
    selectedAwardValue === 0 ? setAwardIsTrue(false) : setAwardIsTrue(true)
  }, [selectedAwardValue])

  useEffect(() => {
    saveFunc()
  }, [selectedImage, dataType, awardtext, file])

  const saveFunc = () => {
    setAwardData({
      awardMediaUrl: selectedImage,
      awardMediaType: dataType,
      awardMediaText: awardtext,
      file: file,
    })
  }

  function isSelected(value: any, selectedAwardValue: any) {
    return selectedAwardValue === value
  }
  /* useEffect(() => {
   setAwardWinners(selectedAwardValue)
}, [selectedAwardValue]) */

  const getLabelStyle = (value: any) => {
    if (isSelected(value, selectedAwardValue)) {
      return { fontWeight: 'bold', fontSize: '20px' }
    } else {
      return { fontWeight: 'normal', fontSize: '20px' }
    }
  }

  const handleChange = (event: any) => {
    setSelectedAwardValue(parseInt(event.target.value))
    /*  setAwardWinners(parseInt(event.target.value)) */
  }

  return (
    <>
      {/*    <Modal show={isUserLoading} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className='row w-100'>
            <div className='col-1'>
              <i className='fa-4x fa-solid fa-2x fa-trophy ' />
            </div>
            <div className='col-10 text-center'>
              <div className='text center w-100 h1'>Describe the award</div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body> */}
      <Grid container >
        <Paper elevation={3} sx={{ padding: 1, width: "100%" }}>
          <Grid xs={12} display={'flex'} alignItems={'center'} justifyContent={"center"} flexDirection={"column"}>
            {/* <span className='text-muted fs-4' style={{marginRight: '20px'}}>
            Award Winners
          </span> */}
            <FormControl >
              <RadioGroup
                onChange={(e) => (handleChange(e),setChanged(true))}
                value={selectedAwardValue}
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue={0}

                row
                sx={{ columnGap: 4 }}
              >
                <FormControlLabel
                  value={0}
                  disabled={award}
                  control={<Radio sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '26px',
                    },
                    color: "#cd93d7",
                    '&.Mui-checked': {
                      color: "#9c27b0",
                    },
                  }} />}
                  label={<Typography style={getLabelStyle(0)}>None</Typography>}
                />
                <FormControlLabel
                  value={1}
                  disabled={award}
                  control={<Radio sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '26px',
                    },
                    color: "#cd93d7",
                    '&.Mui-checked': {
                      color: "#9c27b0",
                    },
                  }} />}
                  label={<Typography style={getLabelStyle(1)}>Champion</Typography>}
                />
                <FormControlLabel
                  value={2}
                  disabled={award}
                  control={<Radio sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '26px',
                    },
                    color: "#cd93d7",
                    '&.Mui-checked': {
                      color: "#9c27b0",
                    },
                  }} />}
                  label={<Typography style={getLabelStyle(2)}>3 winners</Typography>}
                />
                <FormControlLabel
                  value={3}
                  disabled={award}
                  control={<Radio sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '26px',
                    },
                    color: "#cd93d7",
                    '&.Mui-checked': {
                      color: "#9c27b0",
                    },
                  }} />}
                  label={<Typography style={getLabelStyle(3)}>Top 10</Typography>}
                />
                <FormControlLabel
                  value={4}
                  disabled={award}
                  control={<Radio sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '26px',
                    },
                    color: "#cd93d7",
                    '&.Mui-checked': {
                      color: "#9c27b0",
                    },
                  }} />}
                  label={<Typography style={getLabelStyle(4)}>Top 25</Typography>}
                />
                <FormControlLabel
                  value={5}
                  disabled={award}
                  control={<Radio sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '26px',
                    },
                    color: "#cd93d7",
                    '&.Mui-checked': {
                      color: "#9c27b0",
                    },
                  }} />}
                  label={<Typography style={getLabelStyle(5)}>Top 100</Typography>}
                />
                <FormControlLabel
                  value={6}
                  disabled={award}
                  control={<Radio sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '26px',
                    },
                    color: "#cd93d7",
                    '&.Mui-checked': {
                      color: "#9c27b0",
                    },
                  }} />}
                  label={<Typography style={getLabelStyle(6)}>All finishers</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid xs={12} display={'flex'} marginTop={8} direction={'column'} alignItems={'center'} justifyItems={'center'}>

            <Box
              height={400}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={selectedAwardValue === 0 ? 'row' : "column"}
            >
              {selectedAwardValue === 0 &&
                <Avatar src='/media/gif/success.gif' variant='rounded' sx={{ objectFit: "contain", width: "250px", height: "250px" }} />
              }

              {selectedAwardValue === 0 ? (
                <div className='col-3 text-center d-flex align-items-center  '>
                  <span className='text-muted' style={{ fontSize: '20px' }}>
                    Award have strong positive effects on the participants. We strongly suggest you to
                    make some commitments.{' '}
                  </span>
                </div>
              ) : null}
              {selectedAwardValue === 0 ? null : (
                <Box width={480} >
                  <SelectContentNew
                    changeFile={setfile}
                    mediaType={dataType}
                    mediaUrl={selectedImage}
                    changeMediaUrl={changeMediaUrl}
                    setMediaType={setDataType}
                  />
                </Box>

                /* <SelectAwardMedia
                  customerId={customerId}
                  mediaType={dataType}
                  mediaUrl={selectedImage}
                  changeMediaUrl={setSelectedImage}
                  changeMediaType={setDataType}
                  changeFile={setfile}
                  awardDetail={awardDetail}
                /> */
              )}
              {selectedAwardValue === 0 ? null : (
                <textarea
                  value={awardtext}
                  disabled={award}
                  onChange={(e: any) => (setAwardText(e.target.value),setChanged(true))}
                  name='Wording'
                  id='Wording'
                  rows={8}
                  maxLength={250}
                  style={{
                    resize: 'none',
                    border: awardtext?.length > 1 ? '' : dataType ? '' : '1px solid #cd93d7',
                    borderRadius: '5px',
                    width: '480px',
                    marginTop: '10px',
                  }}
                  className='form-control form-control-solid overflow-auto '
                ></textarea>
              )}
            </Box>

          </Grid>
        </Paper>
      </Grid>

      {/*  </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => {
              saveFunc()
              handleClose()
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  )
}

export default Award
