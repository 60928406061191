import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { SnackbarProvider, useSnackbar } from 'notistack'

type Props = {
  location?: any
}

const NewPassword: FC<Props> = ({ location }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [searchParams, setSearchParams] = useSearchParams()
  searchParams.get('token')
  const [isMobile, setIsMobile] = useState(searchParams.get('env'))



  const [token, setToken] = useState(searchParams.get('token'))
  const navigate = useNavigate()
  const [success, setSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<any>(false)
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [progress, setProgress] = useState(0)

  const handlePasswordChange1 = (e: any) => {
    setShowError(false)
    setPassword1(e.target.value)
    setPasswordsMatch(e.target.value === password2)
  }

  const handlePasswordChange2 = (e: any) => {
    setPassword2(e.target.value)
    setPasswordsMatch(e.target.value === password1)
  }

  useEffect(() => {
    setProgress(getPasswordStrength(password1))
  }, [password1])

  const getPasswordStrength = (password: any) => {
    let strength = 0

    // Şifre uzunluğunu kontrol edin
    if (password.length >= 1 && password.length < 10) {
      strength += 1
    } else if (password.length >= 10) {
      strength += 2
    }

    // Harf kullanımını kontrol edin
    if (/[a-zA-Z]/.test(password)) {
      strength += 1
    }
    // Buyuk Kucuk harf kullanımını kontrol edin
    if (/[a-z]/.test(password) && /[A-Z]/.test(password)) {
      strength += 1
    }

    // Rakam kullanımını kontrol edin
    if (/[0-9]/.test(password)) {
      strength += 1
    }

    // Özel karakter kullanımını kontrol edin
    if (/[^a-zA-Z0-9]/.test(password)) {
      strength += 1
    }

    // Güçlü şifre için kriterleri kontrol edin
    if (strength === 6) {
      return 100
    }
    // Güçlü şifre için kriterleri kontrol edin
    if (strength === 5) {
      return 80
    }

    // Orta şifre için kriterleri kontrol edin
    if (strength >= 3) {
      return 50
    }
    if (strength >= 2) {
      return 30
    }

    // Zayıf şifre olarak kabul edin
    return 0
  }
  const [tokenLoading, setTokenLoading] = useState(false)
  useEffect(() => {
    async function getToken() {
      setTokenLoading(true)
      axios
        .post(`${process.env.REACT_APP_API_URL}/user/isTokenValid`, '', {
          params: {
            token: `${token}`,
          },
          headers: {
            accept: 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
          },
        })
        .then((response) => {
          setTokenLoading(false)
          if (response.data === false) {
            enqueueSnackbar('Token Is Expired', { variant: 'error' })
            navigate('/login')
          }
        })
        .catch((err) => {
          enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
          navigate('/login')
          setTokenLoading(false)
        })
    }
    getToken()
  }, [token])

  const ax = axios.create({ headers: { Accept: 'application/json' } })

  function test() {
    setLoading(true)
    const res = ax
      .post(`${process.env.REACT_APP_API_URL}/user/forgotPassword`, password2, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        enqueueSnackbar('Successfully Saved', { variant: 'success' })
        setSuccess(true)
        setLoading(false)
        // expected the setProducts to be filled with the return of this request
      })
      .catch((error) => {
        error.response.data.detail.errorMessage
          ? enqueueSnackbar(`${error.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
        setSuccess(false)
        setLoading(false)
      })
  }
  const [showError, setShowError] = useState(false)

  return (
    tokenLoading ? <><CircularProgress /></> :
      success === false ? (
        <>
          <Grid
            container
            justifyContent={'center'}
            alignContent={'center'}
            alignItems={'center'}
            direction={'column'}
            marginTop={20}
          >
            <Typography color={'#6C757D'} fontWeight={'bold'} variant='h6'>
              Choose your new password
            </Typography>
            <TextField
              margin='normal'
              style={{ width: '400px' }}
              label='NEW PASSWORD'
              name='newpassword'
              type='password'
              autoComplete='password'
              value={password1}
              onChange={handlePasswordChange1}
              onBlur={() => setShowError(true)}
              error={showError && (password1.length < 6 || password1.length > 254)}
              helperText={
                showError
                  ? password1.length < 6
                    ? 'Minimum 6 character'
                    : password1.length > 254
                      ? 'Max 255 character'
                      : null
                  : null
              }
            />
            {password1.length > 2 && (
              <Box sx={{ width: '100%', height: 20 }}>
                <LinearProgress variant='buffer' value={progress} />
                <Typography style={{ color: '#6C757D' }} fontWeight={'bold'}>
                  {progress === 30 ? 'Weak' : null}
                  {progress === 50 ? 'Moderate' : null}{' '}
                  {progress === 80 ? 'Strong' : null}
                  {progress === 100 ? 'Very Strong' : null}
                </Typography>
              </Box>
            )}
            <TextField
              margin='normal'
              style={{ width: '400px' }}
              label='RETYPE'
              name='RETYPE'
              type='password'
              autoComplete='password'
              value={password2}
              onChange={handlePasswordChange2}
              error={!passwordsMatch}
              helperText={!passwordsMatch ? 'Does NOT match' : null}
            />
          </Grid>




          <Grid container justifyContent={'center'} marginTop={10}>

            <Button
              variant='contained'
              style={{ marginRight: 20 }}
              color='inherit'
              onClick={() => navigate('/login')}
            >
              DISCARD
            </Button>



            <Button
              variant='contained'
              style={{ marginRight: 20 }}
              disabled={
                !passwordsMatch ||
                progress < 30 ||
                password2.length < 6 ||
                loading ||
                password2.length > 254
              }
              onClick={() => {
                test()
              }}
            >
              SAVE
              {loading && (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress size={22} color='inherit' style={{ marginLeft: '5px' }} />
                </Box>
              )}
            </Button>

          </Grid>

        </>)
        : (
          <>
            <Grid
              container
              justifyContent={'center'}
              textAlign={'center'}
              alignContent={'center'}
              alignItems={'center'}
              direction={'column'}
              marginTop={20}
            >
              <Typography color={'#6C757D'} fontWeight={'bold'} variant='h6'>
                Hurray!
                <br /> Your password has been changed successfully.
              </Typography>
            </Grid>
            <Grid container justifyContent={'center'} marginTop={10}>
              <Button
                variant='contained'
                style={{ marginRight: 20 }}
                disabled={!passwordsMatch || progress < 30 || password2.length < 6}
                onClick={() => {
                  isMobile !== "WEB" ?
                    window.location.href = process.env.REACT_APP_APP_URL :
                    navigate('/login')
                }}
              >
                GO BACK TO SIGN IN
              </Button>
            </Grid>
          </>
        )
  )
}
export default function Snackbar(props: any) {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} maxSnack={3}>
      <NewPassword {...props} />
    </SnackbarProvider>
  )
}
