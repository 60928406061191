import React from 'react'
import {FC, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Button from '@mui/material/Button'
import {Editor} from '@tinymce/tinymce-react'
import Axios from 'axios'
import {useSelector} from 'react-redux'
import {SnackbarProvider, useSnackbar} from 'notistack'

type Props = {
  customerId: any
  first: any
  setfirst: any
}
const EditNotes: FC<Props> = ({customerId, first, setfirst}) => {
  const {enqueueSnackbar} = useSnackbar()

  const [show, setShow] = useState(false)
  let rm: any = document.getElementById('remaining')
  const editorRef: any = useRef(null)
  const sizeLimit = 750
  const [value, setValue] = React.useState<string>('')
  const [length, setLength] = React.useState(0)
  const handleInit = (evt: any, editor: any) => {
    setLength(editor.getContent({format: 'text'}).length)
    editorRef.current = editor
  }
  const handleUpdate = (value: any, editor: any) => {
    setValue(`<body>${value}</body>`)
  }
  const handleBeforeAddUndo = (evt: any, editor: any) => {
    const length = editor.getContent({format: 'text'}).length
    // note that this is the opposite test as in handleUpdate
    // because we are determining when to deny adding an undo level
    if (length > sizeLimit) {
      evt.preventDefault()
    }
  }
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const LoginState = useSelector((state: any) => state.Login)

  const [isSuccess, setIsSuccess] = useState(false)

  function handleDone() {
    setIsSuccess(false)
    setfirst(!first)
    handleClose()
  }

  const handleSave = () => {
    Axios.post(
      `${process.env.REACT_APP_API_URL}/customer/editCustomer/addNote/${customerId}`,
      {
        noteText: value,
      },
      {
        headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
      }
    )
      .then(function (response: any) {
        enqueueSnackbar('Successfully saved', {variant: 'success'})
        setIsSuccess(true)
      })
      .catch((err) => {
        enqueueSnackbar('Saved Error', {variant: 'error'})
      })
  }
  return (
    <>
      <Button size='large' variant='contained' onClick={handleShow}>
        Add Notes
      </Button>

      <Modal className='bg-white bg-opacity-75 p-3 mb-5' show={show} onHide={handleClose}>
        <Modal.Header>
          <div className='w-100 d-flex justify-content-between'>
            <div className='h2 my-auto'>Add Notes</div>
            <a onClick={handleClose} className='btn btn-icon btn-secondary rounded-circle btn-sm'>
              <i className='fa-solid fa-x'></i>{' '}
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Editor
            onInit={handleInit}
            value={value}
            onEditorChange={handleUpdate}
            onBeforeAddUndo={handleBeforeAddUndo}
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            init={{
              menubar: false,
              toolbar_mode: 'wrap',
              auto_focus: 'element1',
              content_style: 'body { font-size: 14pt; font-family: Arial; }',
              plugins: ' lists advlist colorpicker emoticons  charmap',
              toolbar: 'undo redo | bold italic underline |bullist numlist',
              relative_urls: false,
              statusbar: false,
              branding: false,
              automatic_uploads: false,
              image_advtab: false,
              image_caption: false,
              image_description: false,
              paste_data_images: false,
              language: 'tr_TR',
              convert_urls: false,

              setup: function (ed) {
                ed.ui.registry.addButton('customInsertButton', {
                  icon: 'edit-image',
                  onAction: function (_) {},
                })
              },
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          {isSuccess === false && (
            <Button
              size='small'
              color='inherit'
              variant='contained'
              style={{marginRight: '20px'}}
              onClick={handleClose}
            >
              Close
            </Button>
          )}

          <Button
            size='small'
            variant='contained'
            style={{marginRight: '40px'}}
            onClick={() => (isSuccess === true ? handleDone() : handleSave())}
          >
            {isSuccess === true ? 'Done' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default function Snackbar({customerId, first, setfirst}: Props) {
  return (
    <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} maxSnack={3}>
      <EditNotes {...{customerId, first, setfirst}} />
    </SnackbarProvider>
  )
}
