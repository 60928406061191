import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {ForgotPassword} from './ForgotPassword'
import NewPassword from './NewPassword'
import * as Yup from 'yup'
import {Formik} from 'formik'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import LoginTypes from '../../../_MobixaPlus/ReduxNew/reducers/LoginRedux'
import PasswordStrengthBar from 'react-password-strength-bar'
//aaa
const Schema = Yup.object().shape({
  password: Yup.string().min(3, 'Minimum 3 symbols').required('This field is required'),
  changepassword: Yup.string().when('password', {
    is: (val: string | any[]) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
  }),
})
function ChangePasswordModal() {
  const sleep = async (milliseconds: any) => {
    await new Promise((resolve) => {
      return setTimeout(resolve, milliseconds)
    })
  }
  const [oldPassword, setOldPassword] = useState<any>()
  const [show, setShow] = useState(false)
  const handleClose = () => {setShow(false)
    setSuccess(false)}
  const handleShow = () => setShow(true)
  const LoginState = useSelector((state: any) => state.Login)
  useEffect(() => {}, [LoginState.loginData])
  const [newPassword, setPassword] = useState<any>()
  const ax = axios.create({headers: {Accept: 'application/json'}})
  function test() {
    const res = ax
      .post(
        `${process.env.REACT_APP_API_URL}/user/changePassword`,
        {newPassword: newPassword},
        {headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`}}
      )
      .then(async function (response: any) {
        // expected the setProducts to be filled with the return of this request
        setSuccess(true)
        await sleep(1000)
        handleClose()
      })
      .catch(function (error) {})
  }
  const dispatch = useDispatch()
  function handleClick2() {
    test()
  }

  const [success, setSuccess] = useState<any>(false)

  return (
    <>
      <a onClick={handleShow} className='menu-link px-5'>
        Change Password
      </a>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              password: '',
              changepassword: '',
            }}
            validationSchema={Schema}
            onSubmit={() => {}}
          >
            {({values, errors, handleSubmit, handleChange, handleBlur}) => {
              setPassword(values.password)
              return (
                <>
                  <form className='row' onSubmit={handleSubmit} id='deneme3'>
                    <label className='col-12 text-start ' htmlFor='passowrd'>
                      Password
                    </label>

                    <input
                      className={clsx(
                        'form-control form-control-lg mt-5',
                        {'is-invalid': values.password.length > 2 && values.password.length <= 3},
                        {'is-valid': values.password.length >= 4}
                      )}
                      name='password'
                      id='password'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      type='password'
                      placeholder='Password'
                      autoComplete='off'
                    />
                    <PasswordStrengthBar password={newPassword} scoreWordClassName="d-none"/>
                    {/* <div
                      className='d-flex align-items-center mb-3 mt-3'
                      data-kt-password-meter-control='highlight'
                    >
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div> */}
                    <span className='text-danger text-start'>{errors.password}</span>

                    <label className='col-12 mt-10 text-start' htmlFor='passowrd'>
                      Confirm Password
                    </label>
                    <input
                      className={clsx(
                        'form-control form-control-lg mt-5 ',
                        {
                          'is-invalid':
                            values.changepassword.length >= 3 &&
                            values.password !== values.changepassword,
                        },
                        {
                          'is-valid':
                            values.changepassword.length >= 3 &&
                            values.password === values.changepassword,
                        }
                      )}
                      type='password'
                      name='changepassword'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.changepassword}
                    />
                    <span className='text-danger text-start'>{errors.changepassword}</span>
                    <div className={success===false?'d-none':'card p-10 bg-light-success mt-5 text-success'}>
                    Your password has been changed successfully
                    </div>
                    <div className='col-12 w-100 text-center mt-10'>
                      <button
                        className={clsx('btn btn-primary w-25', {
                          disabled:
                            values.password !== values.changepassword || values.password.length < 3,
                        })}
                        onClick={handleClick2}
                      >
                        OK
                      </button>
                    </div>
                  </form>
                  
                </>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ChangePasswordModal
