/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
/* import Accordion from 'react-bootstrap/Accordion' */
import ContentEditable from 'react-contenteditable'
import ReactPlayer from 'react-player'
import getVideoId from 'get-video-id'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type Props = {
  className: string
  title: string
  accordionOpen?:any,
}
let accordionListIndex: number = 0
const FAQWidget: React.FC<Props> = ({className, title,accordionOpen}) => {
  const [accordionList, setAccordionList] = React.useState<any[]>([])
  const [videoUrl, setVideoUrl] = React.useState('')
  let video: any

  useEffect(() => {
    setVideoUrl('https://www.youtube.com/watch?v=uMSDyajqrXE')
    setVideo(video)
    setAccordionList([
      {
        id: 1,
        title: 'What is Mobixa?',
        content: 'Mobixa is a platform for educating and motivating.',
      },
      {
        id: 2,
        title: 'How do I create a video?',
        content:
          'You can create a video by clicking the "Create Video" button in the top right corner of the page.',
      },
      {
        id: 3,
        title: 'How do I share a video?',
        content:
          'You can share a video by clicking the "Share Video" button in the top right corner of the page.',
      },
      {
        id: 4,
        title: 'How do I edit a video?',
        content:
          'You can edit a video by clicking the "Edit Video" button in the top right corner of the page.',
      },
    ])
  }, [])

  const setVideo = (video: any) => {
    video = getVideoId(videoUrl)
  }

  const onAddBtnClick = () => {
    setAccordionList([
      ...accordionList,
      {
        id: accordionListIndex,
        title: 'Deneme',
        content:
          'First, a disclaimer – the entire process of writing a blog post often takes more than a couple of hours, even if you can type eighty',
      },
    ])

    accordionListIndex++
  }
  const onRemoveBtnClick = (index: number) => {
    const newAccordionList = [...accordionList]
    newAccordionList.splice(index, 1)
    setAccordionList(newAccordionList)
  }
  const handleTitleChange = (index: number, event: any) => {
    setAccordionList([...accordionList[index], {content: event.target.value}])
  }
  const handleContentChange = (index: number, event: any) => {
    setAccordionList([...accordionList[index], {content: event.target.value}])
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <Accordion expanded={accordionOpen}>
       {/*  <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize='large' color='primary'/>}
          aria-controls='panel1a-content'
          id='panel1a-header'
          style={{
            display:"flex",
            justifyContent:"center",
          }}
        >
          <Typography fontSize={20} color={"primary"}>Learn</Typography>
        </AccordionSummary> */}
       
        <AccordionDetails>
          <div className='card-body row pt-2'>
            <div className='col-xl-12'>
              <div className='border-0 pt-5 mb-3'>
                <h3 className='align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1 text-dark'>FAQ-{title}</span>
              

                </h3>
              </div>
              {accordionList.map((item, index) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel2a-content'
                    id='panel2a-header'
                  >
                    <ContentEditable
                      html={item.title} // innerHTML of the editable div
                      disabled={false} // use true to disable edition
                      onChange={(e) => handleTitleChange(index, e)} // handle innerHTML change
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <ContentEditable
                      html={item.content} // innerHTML of the editable div
                      disabled={false} // use true to disable edition
                      onChange={(e) => handleContentChange(index, e)} // handle innerHTML change
                    />

                    <ReactPlayer
                      controls={true}
                      playing={false}
                      url={videoUrl}
                      width={300}
                      height={300}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}

            </div>
          </div>
        </AccordionDetails>
      </Accordion>


      {/* end::Header */}

      {/* begin::Body */}

      {/* end::Body */}
    </div>
  )
}

export default FAQWidget
