import React, { useCallback, useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Box, Button, Chip, MenuItem, Pagination, Select, Typography, darken, lighten, styled } from '@mui/material'
import {
  DataGridPro,
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useSelector } from 'react-redux'
const ExcelJS = require('exceljs');

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

function UserActivityReport() {
  const LoginState = useSelector((state: any) => state.Login)

  const QuestionsBreadcrumbs: Array<PageLink> = [
    {
      title: 'Reports',
      // path: '/apps/user-management/users',
      path: '/reports',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  })
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [selectQuestion, setSelectQuestion] = useState([])
  const [questionStatus, setQuestionStatus] = useState(-1)
  const [activityOptions, setActivityOptions] = useState<any>({ sortModel: [{ field: "activityId", sort: "desc" }] })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    /*    id: false,
           brokerId: false,
           status: false, */
  })

  const [status] = useState([
    { value: 0, label: 'Blocked' },
    { value: 1, label: 'Open' },
    { value: 2, label: 'Deleted' },
  ])
  const [difficulty] = useState([
    { value: 1, label: 'Easy' },
    { value: 2, label: 'Med' },
    { value: 3, label: 'Hard' },
  ])

  const StatusCell = ({ value }: { value: number }) => {
    const getStatusComponent = (status: number) => {
      if (status === 0) {
        return (
          <Chip
            className='overflow-visible'
            label='Blocked'
            variant='outlined'
            color='secondary'
            size='small'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 1) {
        return (
          <Chip
            className='overflow-visible'
            label='Open'
            variant='outlined'
            color='success'
            size='small'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      if (status === 2) {
        return (
          <Chip
            className='overflow-visible'
            label='Deleted'
            variant='outlined'
            color='error'
            size='small'
            sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
          />
        )
      }
      return null
    }

    return <div style={{ height: '19px', width: '57px' }}>{getStatusComponent(value)}</div>
  }
  const DifficultyCell = ({ value }: { value: number }) => {
    const getStatusComponent = (status: number) => {
      if (status === 1) {
        return (
          <Typography color={"#47BE7D"} >Easy</Typography>
        )
      }
      if (status === 2) {
        return (
          <Typography color={"#BEB54D"}>Med</Typography>
        )
      }
      if (status === 3) {
        return (
          <Typography color={"#D00000DE"}>Hard</Typography>
        )
      }
      return null
    }

    return <div style={{ height: '19px', width: '57px' }}>{getStatusComponent(value)}</div>
  }

  const [activityType] = useState([
    { value: "LEARNING CARD", label: 'LEARNING CARD' },
    { value: "TEST", label: 'TEST' },
    { value: "GAME", label: 'GAME' },
  ])
  const [activitySubType] = useState([
    { value: "DEEPLEARN", label: 'DEEPLEARN ' },
 /*    { value: "TENTEN", label: 'TENTEN' },
    { value: "RECALL", label: 'RECALL' }, */
    { value: "PEAK", label: 'PEAK' },
    { value: "QUIZ", label: 'QUIZ' },
    { value: "EXAM", label: 'EXAM' },
    { value: "-", label: '-' },
  ])




  const columns: any = [
    {
      field: 'userId',
      headerName: 'USER ID',
      width: 130,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value.toFixed(0),
    },
    { field: 'name', headerName: 'NAME', width: 150 },
    { field: 'surname', headerName: 'SURNAME', width: 150 },
    { field: 'email', headerName: 'EMAIL', width: 150, sortable: false },
    {
      field: 'title',
      headerName: 'ACTIVITY',
      width: 130,
      headerAlign: 'left',
      align: 'left',
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'activityTypeId',
      headerName: 'ACTIVITY TYPE',
      width: 140,
      description: "Learning Card, Game, Test ",
      type: 'singleSelect',
      headerAlign: 'left',
      align: "left",
      sortable: false,
      valueOptions: activityType.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'activitySubTypeName',
      headerName: 'ACTIVITY SUBTYPE',
      width: 130,
      description: "Learning Card doesn’t have any subtype activity. DeepLearn,TenTen,Recall and Peak are Game activities. We have two types of test which are Quiz and Exam.",
      type: 'singleSelect',
      headerAlign: 'left',
      align: "left",
      sortable: false,
      valueOptions: activitySubType.map((option) => ({
        value: option.value,
        label: option.label,
      })),
      valueGetter: (params: any) => params.value ? params.value : "-",
    },
    {
      field: 'journey',
      headerName: 'JOURNEY',
      width: 130,
      headerAlign: 'left',
      align: 'left',
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'startDate',
      headerName: 'START',
      width: 120,
      type: 'dateTime',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => {
        if (!params.value || isNaN(new Date(params.value).getTime())) {
          return '';
        }
        return new Date(params.value);
      },
    },
    {
      field: 'finishDate',
      headerName: 'FINISH',
      width: 120,
      type: 'dateTime',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => {
        if (!params.value || isNaN(new Date(params.value).getTime())) {
          return '';
        }
        return new Date(params.value);
      },
    },    
    {
      field: 'successRate',
      headerName: 'SUCCESS %',
      width: 120,
      description: "It is the correct answer rate of all questions shown to users in this activity in a certain time interval.",
      type: 'number',
      headerAlign: 'left',
      align: "left",
      valueGetter: (params: any) => params?.value ? params.value.toFixed(2) : '0.00',
      sortable: false,
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'progressRate',
      headerName: 'PROGRESS %',
      width: 120,
      description: "It is the average completion rate of users of this activity in a given time period.",
      type: 'number',
      headerAlign: 'left',
      align: "left",
      valueGetter: (params: any) => params?.value ? params.value.toFixed(2) : '0.00',
      sortable: false,
      /* valueGetter: (params: any) => params.value.toFixed(0), */
    },
  ]

  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ ml: 1, mr: 1 }}
          >
            <MenuItem selected value={100}>
              100
            </MenuItem>
            <MenuItem value={150}>150</MenuItem>
            <MenuItem value={200}>200</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={isLoadingData}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelectQuestion(newSelectionModel)
  }

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    if (params.field === '__check__' || params.row.source === 'Mobixa') {
      return
    }

    // Diğer işlemleri burada yapabilirsiniz
  }
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setActivityOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    /*    if (filterModel.items.length < 1) {
          return
        } */

    setActivityOptions((prevOptions: any) => ({
      ...prevOptions,
      filterModel: { ...filterModel },
    }))
  }, [])

  const [loadingExport, setLoadingExport] = useState(false)
  async function downloadExcel() {
    setLoadingExport(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/report/activityUserReport?isExcel=1`,
        {
          activityOptions,
          columnVisibilityModel,
        },
        {
          responseType: 'blob',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (response) => {
        const excelBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const excelUrl =await URL.createObjectURL(excelBlob)

        const link = document.createElement('a')
        link.href = excelUrl
        link.download = 'userReport.xlsx'
        link.click()
        URL.revokeObjectURL(excelUrl)
        setLoadingExport(false)
      })
      .catch((err) => {
        setLoadingExport(false)
      })
    /* const newData = rows.map((row: any) => {
          delete row.tableData
          row.statusId = row.statusId === 1 ? 'Open' : row.statusId === 0 ? 'Block' :""
          return row
        })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, 'row')
        //Buffer
        XLSX.write(workBook, {bookType: 'xlsx', type: 'buffer'})
        //Binary string
        XLSX.write(workBook, {bookType: 'xlsx', type: 'binary'})
        //Download
        XLSX.writeFile(workBook, 'userReports.xlsx') */
  }

  const [timeButtons, setTimeButtons] = useState('last3Months')

  /* useEffect(() => {
    fetchRows()
  }, [timeButtons]) */


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <LoadingButton
          size='small'
          onClick={downloadExcel}
          endIcon={<FileDownloadIcon />}
          loading={loadingExport}
          loadingPosition='end'
          variant='outlined'
        >
          <span>Export</span>
        </LoadingButton>
       {/*  <Box marginLeft={14} >
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('monthToDate')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'monthToDate' ? 'bold' : '',
              color: timeButtons === 'monthToDate' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'monthToDate' ? 'underline' : 'none',
            }}
          >
            Month to date
          </Button>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('lastMonth')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'lastMonth' ? 'bold' : '',
              color: timeButtons === 'lastMonth' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'lastMonth' ? 'underline' : 'none',
            }}
          >
            Last month
          </Button>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('last3Months')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'last3Months' ? 'bold' : '',
              color: timeButtons === 'last3Months' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'last3Months' ? 'underline' : 'none',
            }}
          >
            Last 3 months
          </Button>
          <Button
            variant='text'
            disabled={isLoadingData}
            onClick={() => setTimeButtons('allTime')}
            style={{
              textTransform: 'none',
              fontWeight: timeButtons === 'allTime' ? 'bold' : '',
              color: timeButtons === 'allTime' ? '#1976D2' : '#6C757D',
              textDecoration: timeButtons === 'allTime' ? 'underline' : 'none',
            }}
          >
            All time
          </Button>
        </Box> */}
      </GridToolbarContainer>
    )
  }

   useEffect(() => {
     fetchRows()
   }, [paginationModel, activityOptions])

  const fetchRows = async () => {
    setIsLoadingData(true)
    /* ?timeStamp=${timeButtons} */
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/report/activityUserReport?page=${paginationModel.page + 1}&size=${paginationModel.pageSize
        }`, {activityOptions},
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const formattedRows = response.data.items?.map((row: any) => ({
          uniqueId:row.uniqueId,
          activityId: row.activityId,
          activityTypeId: row.activityTypeId,
          activitySubTypeName: row.activitySubTypeName,
          finishDate:row.completedDate,
          startDate:row.startedDate,
          userId:row.userId,
          name:row.userName,
          surname:row.userSurname,
          email:row.userEmail,
          journey:row.journeyName,
          progressRate: row.progressRate,
          successRate: row.successRate,
          title: row.title,
          statusId: row.statusId,
          createdName: row.createdName,
       
         /*  completedCount: row.completedCount, */
       /*    appearances: row.appearances, */
       /*    totalCount: row.totalCount, */
       /*    correctAnswers: row.correctAnswers, */
        /*   totalAnswers: row.totalAnswers, */
     
        }))
        setRows(formattedRows || [])
        setRowCount(response.data.total)
        setIsLoadingData(false)
      })
      .catch((err) => {
        setIsLoadingData(false)
      })
  }

  return (
    <>
      {/*  <PageTitle breadcrumbs={QuestionsBreadcrumbs}>Activity</PageTitle> */}
      <div
        style={{
          display: 'flex',
          height: 40,
          width: '80%',
          marginBottom: '5px',
          marginTop: '-25px',
          cursor: 'default',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
          Reports - UserActivity
        </Typography>
      </div>
      <div className='card card-custom p-3  '>
        <Box sx={{ height: 600, width: '100%' }}>
        <StyledDataGrid
            /*   {...data}
                loading={data.rows.length === 0} */
            rows={rows || []}
            columns={columns}
            rowHeight={50}
            columnHeaderHeight={40}
            disableVirtualization
            /* checkboxSelection */
            onCellDoubleClick={handleCellClick}
            getRowId={(row) => row.uniqueId}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            disableRowSelectionOnClick
            sortingMode='server'
            sortModel={activityOptions?.sortModel}
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={handleSelectionModelChange}
            filterMode='server'
            onFilterModelChange={onFilterChange}
            isRowSelectable={(params: GridRowParams) => params.row.source === 'Client'}
            rowCount={rowCount}
            loading={isLoadingData}
            paginationModel={paginationModel}
            paginationMode='server'
            pagination={true}
            onPaginationModelChange={setPaginationModel}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
            }
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
            sx={{
              boxShadow: "none",
              border: "none",
              '& .MuiDataGrid-footerContainer': {
                padding: '0px', 
                height: 30
              },

              '& .MuiDataGrid-cell:hover': {
                /*    color: 'primary.main', */
                cursor: 'pointer',
              },
              '&	.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                color: '#A1A5B7',
                fontSize: 12,
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
              },
            }}
          />
        </Box>
      </div>
    </>
  )
}

export default UserActivityReport
