// WebSocketContext.js

import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginTypes from '../../ReduxNew/reducers/LoginRedux'

const WebSocketContext :any= createContext(null);

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }:any) => {
  const LoginState = useSelector((state: any) => state.Login);
  const tokenChange = useSelector((state: any) => state.Login.tokenChange);

  const [socket, setSocket] = useState<WebSocket | null>(null);
  const dispatch = useDispatch()
  useEffect(() => {
    if(tokenChange){
      if (!LoginState.loginData?.access_token || socket ) {
        if(LoginState.loginData === null && socket){
          socket.close()
          setSocket(null)
        }
      return; // Eğer kullanıcı girişi yapmamışsa WebSocket'i başlatma
    }else{
      const url = `${process.env.REACT_APP_CHAT_URL}?token=${LoginState.loginData.access_token}`;
      const newSocket = new WebSocket(url);
      
      newSocket.onmessage = (event:any) => {
        const data = JSON.parse(event.data);
        if (data.message === "*kill") {
          // WebSocket'ten gelen mesajları işleme 
          dispatch(LoginTypes.logout())
        }
      };
      setSocket(newSocket);
    
    }
    }

  }, [tokenChange,socket]);

  useEffect(() => {
    if(socket){
      const intervalId = setInterval(() => {
        socket.send(JSON.stringify({ action: "sendMessage", message: "ping" }));
      }, 60000);
  
      return () => {
        clearInterval(intervalId);
     
      };
    }
  }, [socket])
  

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};
