import {FC, useState, useEffect} from 'react'
import dayjs, {Dayjs} from 'dayjs'
import 'dayjs/locale/tr'
import TextField from '@mui/material/TextField'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DateRange} from '@mui/x-date-pickers-pro'
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker'
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker'
import {ThemeProvider, createTheme} from '@mui/material/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {StaticDateRangePicker} from '@mui/x-date-pickers-pro/StaticDateRangePicker'
import {Grid} from '@mui/material'

type Props = {
  setShowTimePreview?: any
  showTimePreview?: any
  setTimeEnd?: any
  timeEnd?: any
  setDates?: any
  dates?: any
  journeyTime: any
}

const JourneyTimig: FC<Props> = ({setShowTimePreview, showTimePreview, journeyTime}) => {
  const handleClickOpen = () => {
    setShowTimePreview(true)
  }

  const handleClose = () => {
    setShowTimePreview(false)
  }

  return (
    <>
      <Dialog
        open={showTimePreview}
        onClose={handleClose}
        fullWidth
        maxWidth='md'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Time Preview'}</DialogTitle>
        <DialogContent dividers>
          <Grid container marginBottom={2}>
            <Grid xs={12} display={'flex'} justifyContent={'center'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateRangePicker
                  disabled
                  value={[dayjs(journeyTime.startDate), dayjs(journeyTime.endDate)]}
                  slotProps={{
                    actionBar: {actions: []},
                  }}
                  calendars={2}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={12} display={'flex'} justifyContent={'center'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker disabled={true} ampm={false} value={journeyTime.startDate} />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker disabled={true} ampm={false} value={journeyTime.endDate} />
              </LocalizationProvider>
            </Grid>
          </Grid>
          {/*   <DialogContentText id='alert-dialog-description'>
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default JourneyTimig
