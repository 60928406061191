import {lazy, FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {CDashboardWrapper} from '../pages/dashboard/cDashboardWrapper'
import {GDashboardWrapper} from '../pages/dashboard/gDashboardWrapper'
import {UDashboardWrapper} from '../pages/dashboard/uDashboardWrapper'
import {MenuTestPage} from '../../app/pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {HelpPage} from '../pages/Help/HelpPage'
import GroupManagementPage from '../pages/group-management/GroupPage'
import BasicPageToPage from '../pages/user-management/UserList'
import ExamplePage from '../pages/user-management/UserInfo'
import QuestionsPage from '../pages/questions/QuestionsPage'
import CustomerProfile from '../pages/Administration/CustomerInfo'
import RolesPage from '../pages/Administration/Components/Roles'
import JourneyPage from '../pages/Journeys/JourneyPage'
import {ForgotPassword} from '../auth/components/ForgotPassword'
import {Activities} from '../pages/Activities/Activities'
import {CustomerDashboardWrapper} from '../pages/customer-dashboard/customerdashboard'
import {Reports} from '../pages/reports/reports'
import CustomerList from '../pages/Administration/CustomerList'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import React from 'react'
import UserProfile from '../pages/user-management/UserDetail'
import JourneyCreate from '../pages/Journeys/JourneyCreate'
import Categories from '../pages/Category/Categories'
import UserReports from '../pages/reports/userReports'
import UserGroupReports from '../pages/reports/userGroupReports'
import CategoriesReport from '../pages/reports/categoriesReports'
import QuestionsReport from '../pages/reports/questionsReport'
import ActivityReport from '../pages/reports/activityReport'
import JourneysReport from '../pages/reports/journeysReport'
import LoginActions from '../ReduxNew/reducers/LoginRedux'
import JourneysReportDetail from '../pages/reports/journeysReportDetail'
import JourneysReportActivity from '../pages/reports/journeyReportActivity'
import TestResult from '../pages/reports/testResult'
import UserActivityReport from '../pages/reports/userActivityReport'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../../app/modules/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../../app/modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../../app/modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../../app/modules/apps/chat/ChatPage'))
  const Categories = lazy(() => import('../../../src/_MobixaPlus/pages/Category/Categories'))

  const LoginState = useSelector((state: any) => state.Login)
  const location = useLocation()
  const dispatch = useDispatch()

  const fetchNewAccessToken = async () => {
    dispatch(LoginActions.refreshTokenRequest(LoginState.loginData.refresh_token))
  }

  useEffect(() => {
   if(LoginState){
    fetchNewAccessToken()
   }
  }, [])
  useEffect(() => {
    // Her 5 saatte bir refresh token isteği atmak için bir interval oluştur.
    const intervalId = setInterval(() => {
      // LoginState varsa ve kullanıcının giriş yapmış olduğunu varsayarak, yeni bir access token isteği gönder.
      if (LoginState) {
        fetchNewAccessToken();
      }
    }, 18000000); // 5 saat = 18000000 milisaniye
  
    // Component unmount edildiğinde interval'ı temizle.
    return () => clearInterval(intervalId);
  }, [LoginState]);
  

  return (
    //all custom roots includes metronic roots must clean end of the project
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='/*' element={<Navigate to='/CustomerDashboard' />} />
        <Route path='/CustomerDashboard' element={<CustomerDashboardWrapper />} />

        <Route path='manage/users/profile' element={<ExamplePage />} />
        <Route path='manage/content' element={<CDashboardWrapper />} />
        <Route path='manage/games-dashboard' element={<GDashboardWrapper />} />
        <Route path='systemadmindashboard' element={<UDashboardWrapper />} />
        <Route path='manage/activities' element={<Activities />} />
        <Route path='manage/users/' element={<BasicPageToPage />} />
        <Route path='manage/users/detail' element={<UserProfile />} />
        <Route path='manage/users/detail/:id?' element={<UserProfile />} />
        <Route path='manage/users/detail/:id?/:comp?' element={<UserProfile />} />
        <Route path='/manage/GroupManagement' element={<GroupManagementPage />} />
        <Route path='/manage/QuestionPage' element={<QuestionsPage />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='/help/onboarding' element={<HelpPage />} />
        <Route path='/administration/customers' element={<CustomerList />} />
        <Route path='/journey' element={<JourneyPage />} />
        <Route path='/reports' element={<Reports />} />
        <Route path='/reports/UserReport' element={<UserReports />} />
        <Route path='/reports/UserGroupReport' element={<UserGroupReports />} />
        <Route path='/reports/CategoriesReport' element={<CategoriesReport />} />
        <Route path='/reports/QuestionReport' element={<QuestionsReport />} />
        <Route path='/reports/ActivitiesReport' element={<ActivityReport />} />
        <Route path='/reports/UserActivityReport' element={<UserActivityReport />} />
        <Route path='/reports/JourneysReport' element={<JourneysReport />} />
        <Route path='/reports/JourneysReportDetail' element={<JourneysReportDetail />} />
        <Route path='/reports/JourneysReportDetail/:id?/:name:?' element={<JourneysReportDetail />} />
        <Route path='/reports/JourneysReportActivity' element={<JourneysReportActivity />} />
        <Route path='/reports/JourneysReportDetail/:id?/:name:?' element={<JourneysReportActivity />} />
        <Route path='/reports/TestResult' element={<TestResult />} />
        <Route path='/reports/TestResult/:id?/:name:?' element={<TestResult />} />
        <Route path='/journey/learning-journey/create' element={<JourneyCreate />} />
        <Route path='/journey/learning-journey/create/:id?' element={<JourneyCreate />} />
        <Route
          path='/journey/learning-journey/create/:selectedActivity?'
          element={<JourneyCreate />}
        />
        <Route path='/category/categories' element={
            <SuspensedView>
              <Categories />
            </SuspensedView>
          } />
        <Route path='/administration/customers/profile' element={<CustomerProfile />} />
        <Route path='/administration/roles' element={<RolesPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
