import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { VersionNotes3Widget } from './VersionNotes3Widget';

const Versionmodall = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      
      <div className='menu-item px-5 my-1'> 
        <a className='menu-link px-5' onClick={handleShow}>
          Release Notes
        </a>
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{border:'0px'}}>
        </Modal.Header>
        <Modal.Body> <VersionNotes3Widget/> </Modal.Body>
        
      </Modal>
    </>
  );
}

export default Versionmodall