import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import Accordion from 'react-bootstrap/Accordion'
import { FC, forwardRef, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import Axios from 'axios'
import { useSelector } from 'react-redux'
import RefreshIcon from '@mui/icons-material/Refresh'
import CheckIcon from '@mui/icons-material/Check'
//warn modal
import { SnackbarProvider, useSnackbar } from 'notistack'
import { Dialog } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}))

const AccordionMui = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  /* border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  }, */
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ height: 20 }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  /*  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)', */
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  /*   borderTop: '1px solid rgba(0, 0, 0, .125)', */
}))

type Props = {
  /* user: any */
  first?: any
  setFirst?: any
  customerId: any
}
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

function MobixaCategory({ customerId, /*  user, */ first, setFirst }: Props) {
  //warn modal
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClickClose = () => {
    setOpen(false)
  }
  //warn modal end

  const [show, setShow] = useState(false)

  const LoginState = useSelector((state: any) => state.Login)
  const [categories, setCategories] = useState<any>([])
  /* const [selected, setSelected] = useState<any>([]) */
  const [purchasedCounts, setPurchasedCounts] = useState<
    { categoryId: number; purchasedQuestionCount: number }[]
  >([])
  const [totalCredit, setTotalCredit] = useState<any>({
    totalCount: 500,
    selectedTotalCount: 0,
    selectedCategory: [],
  })
  function getCheckedCategories(categories) {
    let checkedCategories = [];

    function checkCategory(category) {
      if (category.checked) {
        checkedCategories.push(category);
      }
      if (category.children && category.children.length > 0) {
        category.children.forEach(checkCategory);
      }
    }

    categories.forEach(checkCategory);
    return checkedCategories;
  }

  function handleGetCategories() {
    Axios.get(
      `${process.env.REACT_APP_API_URL}/customer/getCheckedCategories/${customerId}`,
      {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      }
    ).then((response) => {
      // expected the setProducts to be filled with the return of this request
      const checkedCategories = getCheckedCategories(response.data.rootCategory);
      const formattedCategories = checkedCategories.map(category => ({
        categoryId: category.categoryId,
        questionCount: category.selectedQuestionCount
      }));

      setTotalCredit({
        ...totalCredit,
        totalCount: response.data.credit,
        selectedTotalCount: response.data.checkedTotalQuestionCount,
        selectedCategory: formattedCategories
      })
      setCategories(response.data.rootCategory)
    })
  }
  useEffect(() => {
    if (show) {
      handleGetCategories()
    }
  }, [show])
  const hasAllChildrenSelected = (children: any[], selected: string[]): boolean => {
    if (!children || children.length === 0) {
      return false
    }

    return children.every((child: any) => {
      if (!selected.includes(child.categoryId)) {
        return false
      }

      if (child.children && child.children.length > 0) {
        const allChildrenSelected = hasAllChildrenSelected(child.children, selected)

        if (!allChildrenSelected) {
          return false
        }
      }

      return true
    })
  }

  const hasAnyChildSelected = (children: any[], selected: string[]): boolean => {
    if (!children || children.length === 0) {
      return false
    }

    return children.some((child: any) => {
      if (selected.includes(child.categoryId)) {
        return true
      }

      if (child.children && child.children.length > 0) {
        const anyChildSelected = hasAnyChildSelected(child.children, selected)

        if (anyChildSelected) {
          return true
        }
      }

      return false
    })
  }
  const handleRefresh = () => {
    /*   setTotalCredit((prevState: any) => ({
        ...prevState,
        selectedTotalCount: 0,
        selectedCategory: [],
      }))
      setPurchasedCounts([]) */
    handleGetCategories()
  }
  const handleShow = () => {
    setShow(true)
  }
  const handleClose = () => {
    handleRefresh()
    setShow(false)
  }

  const [isSuccess, setIsSuccess] = useState(false)

  function handleDone() {
    setIsSuccess(false)
    handleClose()
  }

  const handleSave = () => {
    Axios.post(
      `${process.env.REACT_APP_API_URL}/customer/editCustomer/addCustomerFreeQuestion/${customerId}`,

      totalCredit.selectedCategory,
      {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      }
    )
      .then(async function (response: any) {
        /*  console.log('response', response) */
        enqueueSnackbar('Successfully saved', { variant: 'success' })
        setIsSuccess(true)
        setOpen(false)
        setTimeout(() => {
          setFirst(!first)
        }, 500);

      })
      .catch((error) => {
        /* console.log('error', error) */
        enqueueSnackbar('Saved Error', { variant: 'error' })
      })
  }
  /*   const handleCheckboxChange = (categoryId: number, purchasedQuestionCount: any) => {
      if (!selected.includes(categoryId)) {
        setSelected((current: any) => [...current, categoryId])
        setPurchasedCounts((prevCounts) => [
          ...prevCounts,
          { categoryId, purchasedQuestionCount: purchasedQuestionCount },
        ])
      } else {
        setSelected((current: any[]) => current.filter((id) => id !== categoryId))
        setPurchasedCounts((prevCounts) =>
          prevCounts.filter((item) => item.categoryId !== categoryId)
        )
      }
    } */

  const handlePurchasedCountChange = (categoryId: number, purchasedQuestionCount: number) => {
    if (!purchasedCounts?.some((item) => item.categoryId === categoryId)) {
      setPurchasedCounts((prevCount) => [
        ...(prevCount || []),
        { categoryId, purchasedQuestionCount },
      ])
    } else {
      setPurchasedCounts((prevCounts) =>
        prevCounts.map((item) =>
          item.categoryId === categoryId ? { ...item, purchasedQuestionCount } : item
        )
      )
    }
  }
  const [hover, setHover] = useState('')


  return (
    <>
      <Button variant='contained' size='large' onClick={handleShow}>
        Select Category
      </Button>
      <Dialog
        open={show}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth='lg'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <div className='w-100 d-flex justify-content-between'>
            <div className='h2 my-auto'>Edit Free Mobixa Contents</div>
            <a onClick={handleClose} className='btn btn-icon btn-secondary rounded-circle btn-sm'>
              <i className='fa-solid fa-x'></i>{' '}
            </a>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container xs={12} justifyContent='center'>
            <Grid xs={1} marginRight={3}>
              <Grid xs={12} container justifyContent='center'>
                <Typography variant='h6' fontWeight={'bold'} component='h2'>
                  Credit
                </Typography>
              </Grid>
              <Grid xs={12} textAlign='center'>
                <Typography
                  variant='h6'
                  fontWeight={'bold'}
                  bgcolor='#DEE2E6'
                  borderRadius={2}
                  component='h2'
                >
                  {totalCredit.totalCount}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={1}>
              <Grid xs={12} container justifyContent='center'>
                <Typography variant='h6' fontWeight={'bold'} component='h2'>
                  Selected
                </Typography>
              </Grid>
              <Grid
                xs={12}
                textAlign='center'
                sx={{
                  bgcolor:
                    totalCredit.selectedTotalCount === totalCredit.totalCount
                      ? 'secondary.main'
                      : '#DEE2E6',
                }}
                borderRadius={2}
                container
                justifyContent={'center'}
                alignItems={'center'}
              >
                {totalCredit.selectedTotalCount === totalCredit.totalCount && (
                  <CheckIcon
                    fontSize='medium'
                    style={{
                      marginRight: '10px',
                      color:
                        totalCredit.selectedTotalCount === totalCredit.totalCount ? 'white' : '',
                    }}
                  />
                )}

                <Typography
                  variant='h6'
                  style={{
                    color: totalCredit.selectedTotalCount === totalCredit.totalCount ? 'white' : '',
                  }}
                  component='h2'
                >
                  {totalCredit.selectedTotalCount}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={1} container alignContent='end'>
              <Grid xs={12} container justifyContent='center' alignItems={'end'}>
                {totalCredit.selectedTotalCount !== 0 && (
                  <Tooltip arrow title='Refresh'>
                    <IconButton onClick={handleRefresh}>
                      <RefreshIcon fontSize='medium' />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} container justifyContent='center' marginTop={2}>
            <div
              className=' d-flex card-body pt-0'
              style={{
                overflowY: 'scroll',
                overflowX: 'scroll',
                minHeight: '600px',
                height: '600px',
              }}
            >
              {categories?.map((data: any) => (
                <>
                  <div>
                    {
                      //1.katman
                    }
                    <AccordionMui
                      style={{
                        marginRight: 20,
                        borderRadius: '10px 10px 5px 5px',
                        border: '1px solid #d5d5d5',
                        minWidth: '340px',
                      }}
                      defaultExpanded={true}
                    >
                      <AccordionSummary
                        /* expandIcon={
                                  <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                } */
                        style={{
                          background: '#D5D5D5',
                          borderRadius: '10px 10px 0px 0px',
                          padding: '0px 10px 0px 5px',
                          minHeight: '60px',
                        }}
                      >
                        <div className='d-flex ' style={{ alignItems: 'center', height: 20 }}>
                          {/*  <div>
                            <FormControlLabel
                              control={
                                <Android12Switch
                                  defaultChecked
                                  color='success'
                                  size='medium'
                                  checked={hasAllChildrenSelected(data.children, selected)}
                                  onClick={(e) => {
                                    if (!selected?.includes(data.categoryId)) {
                                      setSelected((current: any) => [...current, data.categoryId])
                                      data?.children?.map((child: any) => {
                                        setSelected((current: any) =>
                                          current.filter((index: any) => index !== child.categoryId)
                                        )
                                        if (child.children && child.children.length > 0) {
                                          child.children.map((child2: any) => {
                                            setSelected((current: any) =>
                                              current.filter(
                                                (index: any) => index !== child2.categoryId
                                              )
                                            )
                                          })
                                          setSelected((current: any) => [
                                            ...current,
                                            ...child.children.map((item: any) => item.categoryId),
                                          ])
                                        }
                                      })
                                      setSelected((current: any) => [
                                        ...current,
                                        ...data?.children.map((item: any) => item.categoryId),
                                      ])
                                    } else {
                                      setSelected((current: any) =>
                                        current.filter((index: any) => index !== data.categoryId)
                                      )
                                      data?.children?.map((child: any) => {
                                        setSelected((current: any) =>
                                          current.filter((index: any) => index !== child.categoryId)
                                        )
                                        child.children.map((child2: any) => {
                                          setSelected((current: any) =>
                                            current.filter(
                                              (index: any) => index !== child2.categoryId
                                            )
                                          )
                                        })
                                      })
                                    }
                                    e.stopPropagation()
                                  }}
                                  value={data.categoryId}
                                />
                              }
                              label=''
                            />
                            
                          </div> */}

                          <div
                            style={{
                              fontWeight: 'bold',
                              cursor: 'pointer',
                              fontSize: '12px',
                              marginLeft: '-4px',
                            }}
                          >
                            {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                              data.name.slice(1)}
                          </div>
                          <span
                            title='Number of available questions'
                            className='text-dark  ms-2'
                            style={{
                              fontSize: '10px',
                              color: '#8e8e8e',
                              fontWeight: 'bold',
                            }}
                          >
                            ({data.questionCount})
                          </span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: '3px' }}>
                        {
                          //2. katman
                        }

                        {data?.children?.map((child: any) =>
                          child.children.length > 0 ? (
                            <>
                              <AccordionMui
                                style={{
                                  marginTop: '5px',
                                  marginBottom: '5px',
                                  boxShadow: 'none',
                                  borderRadius: '10px 10px 0px 0px',
                                }}
                                defaultExpanded={true}
                              >
                                <AccordionSummary
                                  sx={{
                                    margin: '0px!important',
                                    '.MuiAccordionSummary-content': {
                                      // AccordionSummary'nin içeriği için stil
                                      /* marginLeft: '4px', */
                                      // Sol marjin değerini 0px olarak ayarlayın
                                    },
                                  }}
                                  style={{
                                    borderRadius: '10px 10px 0px 0px',
                                    padding: '0px 0px 0px 0px',
                                    minHeight: '20px',
                                    marginLeft: '0px!important',
                                    background: hover === child.categoryId ? '#e7e7e7' : '',
                                  }}
                                >
                                  <div
                                    className='d-flex'
                                    style={{
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      width: '100%',
                                      height: 20,
                                    }}
                                    onMouseEnter={(e: any) => {
                                      setHover(child.categoryId)
                                    }}
                                    onMouseLeave={(e: any) => {
                                      setHover('')
                                    }}
                                  >

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <span
                                        className={'text-gray-800 d-flex'}
                                        style={{
                                          fontWeight: 'bold',
                                          cursor: 'pointer',
                                          fontSize: '12px',
                                          marginLeft: '-4px',
                                        }}
                                      >
                                        {child.name.charAt(0).toUpperCase() + child.name.slice(1)}
                                      </span>
                                    </div>

                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'end',
                                      justifyContent: 'end',
                                      height: '18px',
                                    }}
                                  >

                                  </div>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: '3px' }}>
                                  {child?.children?.map((child: any) => (
                                    <div id={child.categoryId} className='ms-0 mt-3 '>
                                      <div
                                        className='d-flex'
                                        style={{
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          height: 30,
                                          paddingLeft: 2,
                                          background: hover === child.categoryId ? '#e7e7e7' : '',
                                        }}
                                        onMouseEnter={(e: any) => {
                                          setHover(child.categoryId)
                                        }}
                                        onMouseLeave={(e: any) => {
                                          setHover('')
                                        }}
                                      >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <div>
                                            <FormControlLabel
                                              control={
                                                <Android12Switch
                                                  defaultChecked
                                                  color='secondary'
                                                  size='medium'
                                                  value={child.categoryId}
                                                  disabled={
                                                    /* selected?.includes(child.categoryId) */ child?.questionCount ===
                                                    0 ||
                                                    totalCredit.totalCount ===
                                                    totalCredit.selectedTotalCount
                                                  }
                                                  checked={totalCredit?.selectedCategory?.some(item => item.categoryId === child.categoryId)}
                                                  onChange={() => {
                                                    const categorySelected = totalCredit.selectedCategory.some(item => item.categoryId === child.categoryId);

                                                    setTotalCredit(prevState => {
                                                      if (!categorySelected) {
                                                        // Kategori ekleniyor
                                                        let newSelectedTotalCount = prevState.selectedTotalCount + child.questionCount;

                                                        if (newSelectedTotalCount > prevState.totalCount) {
                                                          // Eğer totalCount aşılırsa, sadece aşmayacak kadarını ekleyin
                                                          newSelectedTotalCount = prevState.totalCount;
                                                        }

                                                        return {
                                                          ...prevState,
                                                          selectedCategory: [
                                                            ...prevState.selectedCategory,
                                                            {
                                                              categoryId: child.categoryId,
                                                              questionCount: newSelectedTotalCount - prevState.selectedTotalCount,
                                                            },
                                                          ],
                                                          selectedTotalCount: newSelectedTotalCount,
                                                        };
                                                      } else {
                                                        // Kategori kaldırılıyor
                                                        const updatedSelectedCategory = prevState.selectedCategory.filter(item => item.categoryId !== child.categoryId);
                                                        const categoryToRemove = prevState.selectedCategory.find(item => item.categoryId === child.categoryId);
                                                        const updatedSelectedTotalCount = categoryToRemove ? prevState.selectedTotalCount - categoryToRemove.questionCount : prevState.selectedTotalCount;

                                                        return {
                                                          ...prevState,
                                                          selectedCategory: updatedSelectedCategory,
                                                          selectedTotalCount: updatedSelectedTotalCount,
                                                        };
                                                      }
                                                    });

                                                  }}
                                                />
                                              }
                                              label=''
                                            />
                                          </div>
                                          <a
                                            className={'text-gray-800 d-flex '}
                                            style={{
                                              cursor: 'pointer',
                                              fontSize: '12px',
                                              alignItems: 'center',
                                              maxWidth: '210px',
                                              marginLeft: '-4px',
                                            }}
                                          >
                                            {child.name.charAt(0).toUpperCase().replace('🅜', '') +
                                              child.name.slice(1)}
                                          </a>

                                          {/*  <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              height: '20px',
                                            }}
                                          >
                                            <span
                                              title='Number of available questions'
                                              className='ms-2'
                                              style={{
                                                color: '#8e8e8e',
                                                fontSize: '10px',
                                              }}
                                            >
                                              {`(${child.questionCount})`}
                                            </span>
                                          </div> */}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'end',
                                            justifyContent: 'end',
                                            height: '18px',
                                          }}
                                        >
                                          <TextField
                                            size='small'
                                            value={child?.questionCount}
                                            disabled={true}
                                            type='number'
                                            variant='outlined'
                                            style={{
                                              width: '44px',
                                              height: 18,
                                              marginTop: '-4px',
                                              /*  marginRight: '3px', */
                                            }}
                                            inputProps={{
                                              min: 1,
                                              max: child.questionCount,
                                              style: {
                                                padding: '0px', // Input padding'ini azaltın
                                              },
                                            }}
                                          />
                                          {
                                            // Question Count open false
                                          }

                                          {
                                            <TextField
                                              className='ms-2'
                                              size='small'
                                              value={
                                                totalCredit.selectedCategory.find(
                                                  (item: any) => item.categoryId === child.categoryId
                                                )?.questionCount || 0
                                              }
                                              disabled={!totalCredit?.selectedCategory?.some(item => item.categoryId === child.categoryId) || (totalCredit.totalCount ===
                                                totalCredit.selectedTotalCount)}
                                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                let count = Number(e.target.value);
                                                const maxPossibleCount = Math.min(child.questionCount, totalCredit.totalCount - (totalCredit.selectedTotalCount - (totalCredit.selectedCategory.find(
                                                  (item: any) => item.categoryId === child.categoryId
                                                )?.questionCount || 0)));

                                                if (!isNaN(count) && count >= (child.selectedQuestionCount || 1) && count <= maxPossibleCount) {
                                                  setTotalCredit((prevState: any) => {
                                                    const filteredCredit = prevState.selectedCategory.filter(
                                                      (credit: any) => credit.categoryId !== child.categoryId
                                                    );

                                                    // Yeni total question count hesaplama
                                                    let newTotalSelectedCount = prevState.selectedTotalCount - (totalCredit.selectedCategory.find(
                                                      (item: any) => item.categoryId === child.categoryId
                                                    )?.questionCount || 0) + count;

                                                    return {
                                                      ...prevState,
                                                      selectedCategory: [
                                                        ...filteredCredit,
                                                        {
                                                          categoryId: child.categoryId,
                                                          questionCount: count,
                                                        },
                                                      ],
                                                      selectedTotalCount: newTotalSelectedCount,
                                                    };
                                                  });
                                                }
                                              }}
                                              type='text'
                                              variant='outlined'
                                              style={{
                                                width: '44px',
                                                height: 18,
                                                marginTop: '-4px',
                                                marginLeft: '5px',
                                              }}
                                              inputProps={{
                                                min: child.selectedQuestionCount || 1,
                                                max: child.questionCount,
                                                style: {
                                                  padding: '0px', // Input padding'ini azaltın
                                                },
                                                pattern: '\\d*',
                                              }}
                                            />
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </AccordionDetails>
                              </AccordionMui>
                            </>
                          ) : null
                        )}
                        {
                          // 3. katman
                        }

                        {data?.children?.map((child: any) =>
                          child.children.length > 0 ? null : (
                            <div id={child.categoryId} className='ms-0 mt-3 '>
                              <div
                                className='d-flex border-1'
                                style={{
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  height: 30,
                                  paddingLeft: 4,
                                  background: hover === child.categoryId ? '#e7e7e7' : '',
                                }}
                                onMouseEnter={(e: any) => {
                                  setHover(child.categoryId)
                                }}
                                onMouseLeave={(e: any) => {
                                  setHover('')
                                }}
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <Android12Switch
                                          size='medium'
                                          color='secondary'
                                          value={child.categoryId}
                                          disabled={
                                            /* selected?.includes(child.categoryId) */ child?.questionCount ===
                                            0 ||
                                            totalCredit.totalCount ===
                                            totalCredit.selectedTotalCount
                                          }
                                          checked={totalCredit?.selectedCategory?.some(item => item.categoryId === child.categoryId)}
                                          onChange={() => {
                                            const categorySelected = totalCredit.selectedCategory.some(item => item.categoryId === child.categoryId);

                                            setTotalCredit(prevState => {
                                              if (!categorySelected) {
                                                // Kategori ekleniyor
                                                let newSelectedTotalCount = prevState.selectedTotalCount + child.questionCount;

                                                if (newSelectedTotalCount > prevState.totalCount) {
                                                  // Eğer totalCount aşılırsa, sadece aşmayacak kadarını ekleyin
                                                  newSelectedTotalCount = prevState.totalCount;
                                                }

                                                return {
                                                  ...prevState,
                                                  selectedCategory: [
                                                    ...prevState.selectedCategory,
                                                    {
                                                      categoryId: child.categoryId,
                                                      questionCount: newSelectedTotalCount - prevState.selectedTotalCount,
                                                    },
                                                  ],
                                                  selectedTotalCount: newSelectedTotalCount,
                                                };
                                              } else {
                                                // Kategori kaldırılıyor
                                                const updatedSelectedCategory = prevState.selectedCategory.filter(item => item.categoryId !== child.categoryId);
                                                const categoryToRemove = prevState.selectedCategory.find(item => item.categoryId === child.categoryId);
                                                const updatedSelectedTotalCount = categoryToRemove ? prevState.selectedTotalCount - categoryToRemove.questionCount : prevState.selectedTotalCount;

                                                return {
                                                  ...prevState,
                                                  selectedCategory: updatedSelectedCategory,
                                                  selectedTotalCount: updatedSelectedTotalCount,
                                                };
                                              }
                                            });
                                          }}
                                        />
                                      }
                                      label=''
                                    />
                                  </div>
                                  <a
                                    className={'text-gray-800 d-flex'}
                                    style={{
                                      cursor: 'pointer',
                                      fontSize: '12px',
                                      maxWidth: '210px',
                                      marginLeft: '-4px',
                                    }}
                                  >
                                    {child.name.charAt(0).toUpperCase() + child.name.slice(1)}
                                  </a>

                                  {/*  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      height: '20px',
                                    }}
                                  >
                                    <span
                                      title='Number of available questions'
                                      className='ms-2'
                                      style={{color: '#8e8e8e', fontSize: '10px'}}
                                    >
                                      {`(${child.questionCount})`}
                                    </span>
                                  </div> */}
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    justifyContent: 'end',
                                    height: '18px',
                                  }}
                                >
                                  <TextField
                                    size='small'
                                    value={child.questionCount || 0}
                                    disabled={true}
                                    type='number'
                                    variant='outlined'
                                    style={{
                                      width: '44px',
                                      height: 18,
                                      marginTop: '-4px',
                                      marginRight: '3px',
                                    }}
                                    inputProps={{
                                      min: 1,
                                      max: child.questionCount,
                                      style: {
                                        padding: '0px', // Input padding'ini azaltın
                                      },
                                    }}
                                  />
                                  {
                                    // Question Count open false
                                  }

                                  {
                                    <TextField
                                      size='small'
                                      value={totalCredit.selectedCategory.find(
                                        (item: any) => item.categoryId === child.categoryId
                                      )?.questionCount || 0
                                      }
                                      disabled={!totalCredit?.selectedCategory?.some(item => item.categoryId === child.categoryId) || (totalCredit.totalCount ===
                                        totalCredit.selectedTotalCount)}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        let count = Number(e.target.value);
                                        const maxPossibleCount = Math.min(child.questionCount, totalCredit.totalCount - (totalCredit.selectedTotalCount - (totalCredit.selectedCategory.find(
                                          (item: any) => item.categoryId === child.categoryId
                                        )?.questionCount || 0)));

                                        if (!isNaN(count) && count >= (child.selectedQuestionCount || 1) && count <= maxPossibleCount) {
                                          setTotalCredit((prevState: any) => {
                                            const filteredCredit = prevState.selectedCategory.filter(
                                              (credit: any) => credit.categoryId !== child.categoryId
                                            );

                                            // Yeni total question count hesaplama
                                            let newTotalSelectedCount = prevState.selectedTotalCount - (totalCredit.selectedCategory.find(
                                              (item: any) => item.categoryId === child.categoryId
                                            )?.questionCount || 0) + count;

                                            return {
                                              ...prevState,
                                              selectedCategory: [
                                                ...filteredCredit,
                                                {
                                                  categoryId: child.categoryId,
                                                  questionCount: count,
                                                },
                                              ],
                                              selectedTotalCount: newTotalSelectedCount,
                                            };
                                          });
                                        }
                                      }}
                                      type='text'
                                      variant='outlined'
                                      style={{
                                        width: '44px',
                                        height: 18,
                                        marginTop: '-4px',
                                        marginLeft: '5px',
                                        marginRight: 3,
                                      }}
                                      inputProps={{
                                        min: child.selectedQuestionCount || 1,
                                        max: child.questionCount,
                                        style: {
                                          padding: '0px', // Input padding'ini azaltın
                                        },
                                        pattern: '\\d*',
                                      }}
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </AccordionDetails>
                    </AccordionMui>
                  </div>
                </>
              ))}
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          {isSuccess === false && (
            <Button
              variant='contained'
              color='inherit'
              style={{ marginRight: '20px' }}
              onClick={handleClose}
            >
              Discard
            </Button>
          )}
          <Button
            variant='contained'
            style={{ marginRight: '40px' }}
            color='primary'
            /*   disabled={totalCredit.totalCount === totalCredit.selectedTotalCount ? false : true} */
            onClick={() => (isSuccess === true ? handleDone() : handleClickOpen())}
          >
            {isSuccess === true ? 'Done' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {
        //warn modal
      }
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Typography color='white'>
              This is the default password for all users to login Mobixa App only. It is NOT for
              Admin users! From now on, only new added users and also the users whom password has
              been reset by the manager will use this password to login Mobixa App for the first
              time. This operation does NOT affect the actual users of Mobixa. Proceed?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} style={{ color: 'white' }}>
            No
          </Button>
          <Button
            onClick={() => {
              handleSave()
            }}
            style={{ color: 'white' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default function Snackbar({/* user, */ first, setFirst, customerId }: Props) {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} maxSnack={3}>
      <MobixaCategory {...{/* user, */ first, setFirst, customerId }} />
    </SnackbarProvider>
  )
}
