import LoginApi from '../services/LoginApi';
import { loginUser,refreshToken,userWidthJwtSaga } from '../sagas/LoginSaga';
import { takeLatest, all,takeEvery } from 'redux-saga/effects';
import { LoginTypes } from '../reducers/LoginRedux';
import QuestionsApi from '../services/QuestionListApi';
import { QuestionTypes } from '../reducers/QuestionRedux';
import { getQuestions } from '../sagas/QuestionSaga';

const loginApi = LoginApi.create();
const questionApi = QuestionsApi.create();


export default function* root() {
    yield all([
        takeLatest(LoginTypes.LOGIN_REQUEST, loginUser, loginApi),
        takeLatest(QuestionTypes.GET_QUESTION_REQUEST, getQuestions, questionApi),
        takeLatest(LoginTypes.REFRESH_TOKEN_REQUEST, refreshToken, loginApi),
        takeLatest(LoginTypes.REFRESH_TOKEN_SUCCESS, userWidthJwtSaga, loginApi),
        takeLatest(LoginTypes.GET_USER_REDUX_REQUEST, userWidthJwtSaga, loginApi),
        /* takeEvery('*', function*(action) {
            console.log(action);
        }) */
    ])
}
