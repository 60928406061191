import { forwardRef, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Switch,
  Tooltip,
  Typography,
  darken,
  lighten,
  styled,
} from '@mui/material'
import EditUserInfo from './companents/EditUserInfo'
import { spawn } from 'child_process'
import { AddNotes } from './companents/addNotes'
import { CategoryView } from './companents/CategoryView'
import { ResetPsw } from './companents/ResetPsw'
import { ChangeStatus } from './companents/ChangeStatus'
import Loading from '../../../_metronic/layout/components/Loading'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import Tabs1 from '@mui/material/Tabs'
import Tab1 from '@mui/material/Tab'
import Tabs2 from '@mui/material/Tabs'
import Tab2 from '@mui/material/Tab'
import AddUser from './AddUserEditModal'
import EditNotes from '../Administration/Components/EditNotes'
import { SnackbarProvider, useSnackbar } from 'notistack'
import CustomerList from '../Administration/CustomerList'
import GppMaybeIcon from '@mui/icons-material/GppMaybe'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import GaugeChart from 'react-gauge-chart'
import ReactApexChart from 'react-apexcharts'

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DataGridPro, GridCellParams, GridColumnVisibilityModel, GridSortModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro'
import StatusBars from '../customer-dashboard/component/statusBars'
import { useIntl } from 'react-intl'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';


const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}))
const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--secondary': {
    backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.light,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.light,
          theme.palette.mode
        ),
      },
    },
  },
}))

const AccordionMui = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  /* border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  }, */
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ height: 20 }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  /*  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)', */
  /*   flexDirection: 'row-reverse', */
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  /*   borderTop: '1px solid rgba(0, 0, 0, .125)', */
}))


const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const UserProfile = () => {
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()
  const language: any = intl?.messages?.performanceTab
  const [first, setfirst] = useState(true)
  const [userRole, setUserRole] = useState<any>([])
  const customerProfile = useSelector((state: any) => state.Login.customerProfile)
  useEffect(() => {
    setUserRole(customerProfile?.role)
    /*     async function test2() {
      
      setisLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/user/getUserWithJWT`, {
          headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
        })
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setUserRole(response.data.role)
          setisLoading(false)
        })
    }
    test2() */
  }, [customerProfile])

  let roleIds = [0, 1, 3]
  let roleIdTabs = [0, 1, 3, 4]
  let roleIdCategory = [0, 1]
  let roleIsValid = userRole?.some((role: any) => roleIds.includes(role.roleId))
  let roleIsValidTab = userRole?.some((role: any) => roleIdTabs.includes(role.roleId))
  let roleIsValidCategory = userRole?.some((role: any) => roleIdCategory.includes(role.roleId))
  const [showGdpr, setshowGdpr] = useState(false)
  const defaultSrc = toAbsoluteUrl('/media/svg/image.svg')
  const [searchParams, setSearchParams] = useSearchParams()
  searchParams.get('id')
  const [id, setId] = useState(searchParams.get('id'))
  const LoginState = useSelector((state: any) => state.Login)
  const [products, setProducts] = useState<any>()
  const ax = axios.create({ headers: { Accept: 'application/json' } })
  const [isLoading, setisLoading] = useState(false)
  const [categoriesMobixa, setCategoriesMobixa] = useState<any>([])
  const [categoriesCustomer, setCategoriesCustomer] = useState<any>([])
  const [selectedMobixa, setSelectedMobixa] = useState<any>([])
  const [selectedCustomer, setSelectedCustomer] = useState<any>([])
  const [categoriesLoading, setCategoriesLoading] = useState<any>(false)
  //adduserEdit modal
  const [openEditUserModal, setOpenEditUserModal] = useState<any>(false)
  const [userData, setUserData] = useState<any>([])
  //adduserEdit modal end

  //category discard modal start
  const [openCategoryDiscardModal, setOpenCategoryDiscardModal] = useState(false)
  //category discard modal end

  const [categoryIsChanged, setcategoryIsChanged] = useState(false)

  useEffect(() => {
    getCategoryList()
  }, [])
  const [getCategoryLoading, setGetCategoryLoading] = useState(false)
  async function getCategoryList() {
    setGetCategoryLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/content/getAllCategories`, {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setCategoriesCustomer(response.data.customerCategory)
        setCategoriesMobixa(response.data.rootCategory)
      })
      .catch((err) => { })
    axios
      .get(`${process.env.REACT_APP_API_URL}/content/getCategoryListByUserId/${id}`, {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        /*       setSelectedCustomer(response.data) */
        setGetCategoryLoading(false)
        setSelectedMobixa(response.data)
        if (openCategoryDiscardModal) {
          setOpenCategoryDiscardModal(false)
        }
      })
      .catch((err) => {
        setGetCategoryLoading(false)
      })
  }
  const [emailSendLoading, setEmailSendLoading] = useState(false)

  async function handleEmailResend() {
    setEmailSendLoading(true)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/definePasswordEmail`,
        // '{\n  "email": "user@example.com",\n  "customerId": 0\n}',
        {
          email: userData[0]?.email,
          customerId: customerProfile?.customerId,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async function (response: any) {
        enqueueSnackbar(`Verification email has been successfully sent`, {
          variant: 'success',
        })
      })
      .catch((err) => {
        err?.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  useEffect(() => {
    setisLoading(true)
    const res = ax
      .get(`${process.env.REACT_APP_API_URL}/user/getUser/${id}`, {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setProducts(response.data)
        setUserData([response.data])
        setisLoading(false)
      })
      .catch((err) => {
        setisLoading(false)
      })
  }, [id, first])

  const [UserImage, setUserImage] = useState<any>(undefined)
  useEffect(() => {
    setUserImage(products?.avatarPath)
  }, [products])
  const statusId = (e: any) => {
    if (e === 0) {
      return (
        <Chip
          className='overflow-visible'
          label='Blocked'
          variant='outlined'
          color='secondary'
          size='small'
          sx={{ overflow: 'visible', width: 100, fontSize: '13px' }}
        />
      )
    }
    if (e === 1) {
      return (
        <Chip
          className='overflow-visible'
          label='Open'
          variant='outlined'
          color='success'
          sx={{ overflow: 'visible', width: 100, fontSize: '13px' }}
        />
      )
    }
    if (e === 2) {
      return (
        <Chip
          className='overflow-visible'
          label='Deleted'
          variant='outlined'
          color='error'
          sx={{ overflow: 'visible', width: 100, fontSize: '13px' }}
        />
      )
    }
    if (e === 3) {
      return (
        <Chip
          label='Pending'
          variant='outlined'
          sx={{ overflow: 'visible', width: 100, fontSize: '13px' }}
        />
      )
    }
    if (e === -1) {
      return (
        <Chip
          label='Erased'
          variant='outlined'
          sx={{ overflow: 'visible', width: 100, fontSize: '13px' }}
        />
      )
    }
  }
  const emailStatus = (e: any) => {
    if (e === 0) {
      return <Chip label='Blocked' variant='outlined' sx={{ fontWeight: 'bold', width: 100 }} />
    }
    if (e === 1) {
      return <Chip label='Open' variant='outlined' sx={{ fontWeight: 'bold', width: 100 }} />
    }
    if (e === 2) {
      return (
        <Tooltip
          arrow
          title={`Verification email has been sent on ${products?.emailVerificationSendDate} Click to resend.`}
          placement='right-end'
        >
          <Chip label='Pending' variant='outlined' sx={{ fontWeight: 'bold', width: 100 }} />
        </Tooltip>
      )
    }
    if (e === 3) {
      return (
        <Tooltip
          arrow
          title={`${products?.emailConfirmedDate} Email adress has been confirmed by the user.`}
          placement='right-end'
        >
          <Chip label='Confirmed' variant='outlined' sx={{ fontWeight: 'bold', width: 100 }} />
        </Tooltip>
      )
    }
  }


  const roleId = (e: any) => {
    if (e === 0) {
      return (
        <Chip label='System Admin' color='error' variant='outlined' style={{ fontWeight: 'bold', width: 100 }} />
      )
    }
    if (e === 1) {
      return <Chip label='Master Admin' variant='outlined' style={{ fontWeight: 'bold', width: 100 }} />
    }
    if (e === 2) {
      return <Chip label='Content Editor' variant='outlined' style={{ fontWeight: 'bold', width: 100 }} />
    }
    if (e === 3) {
      return <Chip label='Trainer' variant='outlined' style={{ fontWeight: 'bold', width: 100 }} />
    }
    if (e === 4) {
      return <Chip label='Reporter' variant='outlined' style={{ fontWeight: 'bold', width: 100 }} />
    }
    if (e === 5) {
      return <Chip label='IT' variant='outlined' style={{ fontWeight: 'bold', width: 100 }} />
    }
    if (e === 6) {
      return <Chip label='Finance' variant='outlined' style={{ fontWeight: 'bold', width: 100 }} />
    }
  }
  const [noteid, setnoteid] = useState<any>()

  function deleteNote(noteId: any) {
    setShow(true)
    setnoteid(noteId)
  }
  function deleteNoteModal(noteId: any) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/deleteUserNotes/${noteid}`, '', {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setfirst(!first)
        handleClose()
      })
  }
  const navigate = useNavigate()
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [tabValue, setTabValue] = useState(0)
  const [tabValue1, setTabValue1] = useState(0)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }
  const handleTabChange1 = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue1(newValue)
  }

  useEffect(() => {
    filterChildCategory()
  }, [selectedMobixa])

  const [filterCategory, setFilterCategory] = useState<any>([])
  function filterChildCategory() {
    const selectedSet = new Set(selectedMobixa) // selected arrayini Set'e dönüştürme
    categoriesMobixa.map((product: any, i: number) => {
      if (product.children && selectedSet.has(product.categoryId)) {
        selectedSet.delete(product.categoryId)
      }
      product.children?.map((child: any, j: number) => {
        if (child.children.length > 0 && selectedSet.has(child.categoryId)) {
          selectedSet.delete(child.categoryId)
        }
      })
    })
    setFilterCategory(Array.from(selectedSet))
  }

  function handleSave() {
    setCategoriesLoading(true)
    setcategoryIsChanged(false)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/addUserCategories/${id}`,
        {
          categoryIdList: filterCategory,
        },
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        setCategoriesLoading(false)
        enqueueSnackbar(`Permissions of the user has been changed successfully`, {
          variant: 'success',
        })
        setTimeout(() => {
          handleClose()
        }, 2000)
      })
      .catch((err) => {
        setCategoriesLoading(false)
        err?.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
      })
  }

  interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  function a11yProps1(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const hasAllChildrenSelected = (children: any[], selected: string[]): boolean => {
    if (!children || children.length === 0) {
      return false
    }

    return children.every((child: any) => {
      if (!selected?.includes(child.categoryId)) {
        return false
      }

      if (child.children && child.children.length > 0) {
        const allChildrenSelected = hasAllChildrenSelected(child.children, selected)

        if (!allChildrenSelected) {
          return false
        }
      }

      return true
    })
  }
  const hasAllChildrenSelected2 = (children, selected) => {
    if (!children || children.length === 0) {
      return false;
    }

    return children.every(child => {
      // Eğer alt elemanları varsa, onların hepsinin seçilmiş olup olmadığını kontrol et
      if (child.children && child.children.length > 0) {
        return hasAllChildrenSelected(child.children, selected);
      }

      // Alt elemanı yoksa, kendisinin seçilmiş olup olmadığını kontrol et
      return selected.includes(child.categoryId);
    });
  };

  const hasAnyChildSelected = (children: any[], selected: string[]): boolean => {
    if (!children || children.length === 0) {
      return false
    }

    return children.some((child: any) => {
      if (selected?.includes(child.categoryId)) {
        return true
      }

      if (child.children && child.children.length > 0) {
        const anyChildSelected = hasAnyChildSelected(child.children, selected)

        if (anyChildSelected) {
          return true
        }
      }

      return false
    })
  }

  const [knowledgeLevelData, setKnowledgeLevelData] = useState<any>(null)
  const [loadingUserDetail, setLoadingUserDetail] = useState(false)
  const [topbarData, setTopbarData] = useState<any>(null)

  const processDataForChart = (data) => {
    const categories = data.map(item => item.date);
    const seriesData = data.map(item => item.successRate);

    return {
      categories,
      seriesData
    };
  };
  const [apexChartSuccess, setApexChartSuccess] = useState<any>({
    series: [{
      name: 'Knowledge Level',
      data: [0, 0, 0]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        /* bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }, */
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val ? val + "%" : "";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: ["", "", ""],
        labels: {
          style: {
            fontSize: '12px' // Burada font boyutunu ayarlıyoruz
          }
        },
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        enabled: false
      },
    },
  })
  const [apexChartTimeSpend, setApexChartTimeSpend] = useState<any>({
    series: [{
      name: "Avg. Time Spend",
      data: [0, 0, 0, 0]
    }],
    options: {
      chart: {
        /*  height: 200, */
        width: '100%',
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      legend: {
        position: 'right', // Legend'ı grafiğin altına yerleştir
        offsetY: 100, // Legend'ı yukarı doğru 10px kaydır
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      /* title: {
        text: 'Product Trends by Month',
        align: 'left'
      }, */
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: ["", "", "", ""],
        labels: {
          style: {
            fontSize: '8px' // Burada font boyutunu ayarlıyoruz
          }
        },
      }
    },
  })
  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return { hours, minutes: remainingMinutes };
  }
  function convertMinutesToHoursAndMinutes1(minutes) {
    const sign = minutes < 0 ? "-" : "+"; // İşareti belirle
    const absoluteMinutes = Math.abs(minutes); // Negatif değerleri pozitife çevir
    const hours = Math.floor(absoluteMinutes / 60);
    const remainingMinutes = absoluteMinutes % 60;
    return sign + `${hours}hr ${remainingMinutes}min`; // İşareti ve formatlı string'i dön
  }
  function convertData2(data) {
    // data değeri pozitifse başına "+" ekler, değilse işareti zaten "-" olacaktır.
    const formattedData = data >= 0 ? `+${data}` : `-${data}`;
    return formattedData;
  }
  const processDataForChartAvgTime = (data) => {
    const categories1 = data.map(item => item.date);
    const seriesData1 = data.map(item => item.avgOnlineTime);

    return {
      categories1,
      seriesData1
    };
  };
  const processDataForChartAvgTime1 = (data) => {
    const categories2 = data.map(item => item.date);
    const seriesData2 = data.map(item => item.avgOnlineTime);

    return {
      categories2,
      seriesData2
    };
  };
  const [avgTimeSpend, setAvgTimeSpend] = useState({ hours: 0, minutes: 0 })
  const [avgTimeSpendDelta, setAvgTimeSpendDelta] = useState<any>(null)

  useEffect(() => {
    if (tabValue === 1) {
      fetchGetUserPerformance()
    }
  }, [tabValue])
  const [latestJourneys, setLatestJourneys] = useState([])
  const [journeyCountInfo, setJourneyCountInfo] = useState<any>(null)
  const fetchGetUserPerformance = async () => {
    setLoadingUserDetail(true)
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/user/getUser/performance/${id}`,
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        setKnowledgeLevelData(response.data)
        const avgTimeSpend = convertMinutesToHoursAndMinutes(response?.data?.totalUserOnlineTime)
        setAvgTimeSpend(avgTimeSpend)
        const avgTimeSpendDelta = convertMinutesToHoursAndMinutes1(response?.data?.deltaTimeSpent)
        setAvgTimeSpendDelta(avgTimeSpendDelta)
        const { categories, seriesData } = processDataForChart(response.data.successRateChartData);
        setApexChartSuccess(prevState => ({
          ...prevState,
          series: [{ ...prevState.series[0], data: seriesData }],
          options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories } }
        }));
        const { categories1, seriesData1 }: any = processDataForChartAvgTime(response.data.timeSpentChartData);
        const { categories2, seriesData2 }: any = processDataForChartAvgTime1(response.data.generalTimeSpentChartData);
        setApexChartTimeSpend(prevState => ({
          ...prevState,
          series: [
            { ...prevState.series[0], data: seriesData2, name: "Team Average" },
            { data: seriesData1, name: ` ${products?.name} ${products?.surname}` }
          ],
          options: {
            ...prevState.options,
            colors: ['#007DBF', '#9C27B0'],
            xaxis: {
              ...prevState.options.xaxis,
              categories: categories1
            }
          }
        }));
        const formattedRows = response.data?.categoryReportArray?.map((row: any) => ({
          categoryId: row.categoryId,
          categoryName: row.categoryName,
          correctAnswers: row.correctAnswers,
          successRate: row.successRate,
          totalAnswers: row.totalAnswers,
        }))
        setRows(formattedRows || [])
        setRowCount(response.data?.categoryReportArray?.length)
        const formattedRows1 = response.data?.testResultReportArray
          ?.map((row: any) => ({
            activityId: row.activityId,
            activityTitle: row.activityTitle,
            completedDate: row.completedDate,
            deltaSuccessRate: row.deltaSuccessRate,
            startedDate: row.startedDate,
            successRate: row.successRate,
            timeSpent: row.timeSpent,
            type: row.type,
          }))
        setRows1(formattedRows1 || [])
        setRowCount1(response.data?.testResultReportArray?.length)
        setLatestJourneys(response.data?.lastestJourneys)
        setJourneyCountInfo(response.data?.journeyCountInfo)
        setLoadingUserDetail(false)
      })
      .catch((err) => {
        setLoadingUserDetail(false)
      })
  }

  //#region Category DataGrid Just Sorting not filter
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [selectQuestion, setSelectQuestion] = useState([])
  const [journeyOptions, setJourneyOptions] = useState<any>({
    sortModel: [{ field: 'journeyId', sort: 'desc' }],
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    /*    id: false,
           brokerId: false,
           status: false, */
  })
  const columns: any = [
    {
      field: 'categoryName',
      headerName: 'CATEGORY',
      width: 160,
      headerAlign: 'left',
      align: 'start',
      filterable: "false"
    },
    {
      field: 'totalAnswers',
      headerName: 'QUESTIONS',
      width: 130,
      headerAlign: 'left',
      align: 'left',
      filterable: "false"
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'correctAnswers',
      headerName: 'CORRECT',
      width: 120,
      headerAlign: 'left',
      align: 'left',
      type: 'singleSelect',
      filterable: "false"
    },
    {
      field: 'successRate',
      headerName: 'SCORE',
      width: 130,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      filterable: "false",
      renderCell: (params) => (
        <Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
          <Box width={97} mr={0.5} display={"flex"} alignItems={"center"}>
            <LinearProgress variant="determinate" value={100}
              sx={{
                height: 20,
                borderRadius: "0px 5px 5px 0px",
                width: (parseInt(params.row.successRate) === 0 ? "1%" : `${parseInt(params.row.successRate) / 100}`),
                '& .MuiLinearProgress-barColorPrimary': {
                  backgroundColor: "#2196F3"/*  params.row.successRate < 50 ? "#FF0000" :
                                params.row.successRate < 75 ? "#D9F82D" : params.row.successRate <= 100 ? "#4CAF50" : "",  */
                },
              }}
            />
            <Typography marginLeft={0.3} fontSize={14} fontWeight={"bold"}>
              {`${params.row.successRate}%`}
            </Typography>
          </Box>
          <Box>
          </Box>
        </Box>
      ),
    },
    /*   {
        width: 80,
        headerAlign: 'left',
        align: 'left',
        filterable: false,
        sortable: false,
        renderHeader: (params) => (
          <Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
            <Tooltip title={"Kullanıcıların Mobixa’da geçirdikleri ortalama süredir. Seçilen tarih aralığında Mobixa’yı aktif olarak kullanmış kişilerin ortalama kullanım süresidir."} placement='top' >
              <IconButton size='small'>
                <InfoOutlinedIcon fontSize='medium' />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      }, */
  ]
  const CustomPagination = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow = paginationModel.page * paginationModel.pageSize + 1
    const endRow = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount)
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            variant='outlined'
            size='small'
            sx={{ ml: 1, mr: 1 }}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages}
          page={paginationModel.page + 1}
          showFirstButton
          showLastButton
          disabled={loadingUserDetail}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow}-{endRow} of {rowCount} record
        </Box>
      </Box>
    )
  }

  const handleSelectionModelChange = (newSelectionModel: any) => {
    if (newSelectionModel.length > 1) {
      // Yalnızca en son seçilen öğeyi koruyun
      const newSelectedId = newSelectionModel[newSelectionModel.length - 1];
      setSelectQuestion([newSelectedId]);
    } else {
      setSelectQuestion(newSelectionModel);
    }
  }
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setJourneyOptions((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])

  //#endregion

  //#region Activity Just Sorting not filter
  const [paginationModel1, setPaginationModel1] = useState({
    page: 0,
    pageSize: 25,
  })
  const [rows1, setRows1] = useState([])
  const [rowCount1, setRowCount1] = useState(0)
  const [selectQuestion1, setSelectQuestion1] = useState([])
  const [journeyOptions1, setJourneyOptions1] = useState<any>({
    sortModel: [{ field: 'journeyId', sort: 'desc' }],
  })
  const [columnVisibilityModel1, setColumnVisibilityModel1] = useState<GridColumnVisibilityModel>({
    /*    id: false,
           brokerId: false,
           status: false, */
  })
  const formatDate = (dateString) => {

    if (!dateString) {

      return { datePart: "", timePart: "" };
    }
    const date = new Date(dateString);
    const dateOptions: any = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const timeOptions: any = { hour: '2-digit', minute: '2-digit' };

    const datePart = date.toLocaleDateString('tr-TR', dateOptions);
    const timePart = date.toLocaleTimeString('tr-TR', timeOptions).split(' ')[0];

    return { datePart, timePart };
  };
  const columns1: any = [
    {
      field: 'activityTitle',
      headerName: 'TEST',
      width: 180,
      headerAlign: 'left',
      align: 'start',
      filterable: false,
    },
    {
      field: 'type',
      headerName: 'TYPE',
      width: 90,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'startedDate',
      headerName: 'START',
      width: 90,
      headerAlign: 'center',
      align: 'left',
      type: 'singleSelect',
      filterable: false,
      renderCell: (params) => {
        const { datePart, timePart } = formatDate(params.row.startedDate);
        return (
          <Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
            <Box textAlign="center">
              <Typography fontSize={12} color={"#000000"}>
                {datePart}
              </Typography>
              <Typography fontSize={12} color={"#000000"}>
                {timePart}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'completedDate',
      headerName: 'FINISH',
      width: 90,
      headerAlign: 'center',
      align: 'left',
      type: 'singleSelect',
      filterable: false,
      renderCell: (params) => {
        const { datePart, timePart } = formatDate(params.row.completedDate);
        return (
          <Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
            <Box textAlign="center">
              <Typography fontSize={12} color={"#000000"}>
                {datePart}
              </Typography>
              <Typography fontSize={12} color={"#000000"}>
                {timePart}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'timeSpent',
      headerName: 'TIME SPENT',
      width: 110,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      /*  valueGetter: (params: any) => params.value.toFixed(0), */
    },
    {
      field: 'successRate',
      headerName: 'SCORE',
      width: 130,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: (params) => (
        params.row.successRate &&
        <Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
          <Box width={97} mr={0.5} display={"flex"} alignItems={"center"}>
            <LinearProgress variant="determinate" value={100}
              sx={{
                height: 20,
                borderRadius: "0px 5px 5px 0px",
                width: (parseInt(params.row.successRate) === 0 ? "1%" : `${parseInt(params.row.successRate) / 100}`),
                '& .MuiLinearProgress-barColorPrimary': {
                  backgroundColor: params.row.successRate < 50 ? "#FF0000" :
                    params.row.successRate < 75 ? "#D9F82D" : params.row.successRate <= 100 ? "#4CAF50" : "",
                },
              }}
            />
            <Box marginLeft={0.5} display={"flex"} flexDirection={"column"} textAlign={"center"} marginTop={2}>
              <Typography fontSize={14} fontWeight={"bold"}>
                {`${params.row.successRate}%`}
              </Typography>
              <Typography fontSize={10} color={convertData2(params.row.deltaSuccessRate).startsWith('-') ? "#FF0000" : "#4CAF50"}>
                {`( ${convertData2(params.row.deltaSuccessRate)} )`}
              </Typography>
            </Box>
          </Box>
          <Box>
          </Box>
        </Box>
      ),
    },
    /*  {
       width: 80,
       headerAlign: 'left',
       align: 'left',
       filterable: false,
       sortable: false,
       renderHeader: (params) => (
         <Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
           <Tooltip title={"Kullanıcıların Mobixa’da geçirdikleri ortalama süredir. Seçilen tarih aralığında Mobixa’yı aktif olarak kullanmış kişilerin ortalama kullanım süresidir."} placement='top' >
             <IconButton size='small'>
               <InfoOutlinedIcon fontSize='medium' />
             </IconButton>
           </Tooltip>
         </Box>
       ),
     }, */
  ]
  const CustomPagination1 = () => {
    const handlePageChange = (event: any, page: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: page - 1,
      }))
    }

    const handlePageSizeChange1 = (event: any) => {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: parseInt(event.target.value, 10),
        page: 0,
      }))
    }

    const startRow1 = paginationModel1.page * paginationModel1.pageSize + 1
    const endRow1 = Math.min((paginationModel1.page + 1) * paginationModel1.pageSize, rowCount)
    const totalPages1 = Math.ceil(rowCount1 / paginationModel1.pageSize)

    return (
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
        <Box>
          Rows per page:
          <Select
            value={paginationModel1.pageSize}
            onChange={handlePageSizeChange1}
            variant='outlined'
            size='small'
            sx={{ ml: 1, mr: 1 }}
          >
            <MenuItem selected value={25}>
              25
            </MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
        <Pagination
          count={totalPages1}
          page={paginationModel1.page + 1}
          showFirstButton
          showLastButton
          disabled={loadingUserDetail}
          size='small'
          onChange={handlePageChange}
        />
        <Box>
          Showing {startRow1}-{endRow1} of {rowCount1} record
        </Box>
      </Box>
    )
  }
  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    // Tıklanan hücreye özgü işlemleri gerçekleştirin
    let name = params.row.activityTitle;
    let id = params.row.activityId

    navigate(`reports/TestResult?id=${id}&name=${name}`)
  }
  const handleSelectionModelChange1 = (newSelectionModel: any) => {
    if (newSelectionModel.length > 1) {
      // Yalnızca en son seçilen öğeyi koruyun
      const newSelectedId = newSelectionModel[newSelectionModel.length - 1];
      setSelectQuestion1([newSelectedId]);
    } else {
      setSelectQuestion1(newSelectionModel);
    }
  }
  const handleSortModelChange1 = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setJourneyOptions1((prevOptions: any) => ({
      ...prevOptions,
      sortModel: [...sortModel],
    }))
  }, [])

  //#endregion

  const capitalizeFirstLetter = (string) => {
    if (string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return string;
  };
  const [hoverButton, setHoverButton] = useState(false)
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className='bg-light-danger border-0' closeButton></Modal.Header>
        <Modal.Body className='bg-light-danger text-danger text-center  border-0'>
          Are You Sure? Note will be deleted.
        </Modal.Body>
        <Modal.Footer className='bg-light-danger border-0'>
          <Button
            variant='contained'
            color='inherit'
            style={{ marginRight: '20px' }}
            onClick={handleClose}
          >
            No
          </Button>
          <Button variant='contained' onClick={deleteNoteModal}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='mb-5 d-flex justify-content-between align-items-center'>
            <span className='fs-3 text-white-50 '>Prepare / User Detail</span>
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              alignContent='center'
            /*     sx={{ height: 50 }} */
            >
              <div style={{ marginRight: 90 }}>
                {
                  <Tooltip
                    arrow
                    title='User List'
                    placement='top'
                    onMouseEnter={() => {
                      setHoverButton(true)
                    }}
                    onMouseLeave={() => {
                      setHoverButton(false)
                    }}
                    style={{ background: hoverButton ? '#D5D5D5 ' : 'white' }}
                  >
                    <IconButton
                      aria-label='By Activity'
                      onClick={(e) => navigate(`/manage/users`)}
                      size='small'
                      sx={{
                        background: 'white',
                        color: hoverButton ? 'black' : '',
                        marginLeft: '7px',
                      }}
                    >
                      <FormatListBulletedOutlinedIcon sx={{ fontSize: '30px' }} />
                    </IconButton>
                  </Tooltip>
                }
              </div>
            </Box>
            {/*   <button className='btn p-0 ' style={{ marginRight: 90 }} onClick={(e) => navigate(`/manage/users`)}>
              <span className='svg-icon svg-icon-3x'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='512'
                  height='512'
                  viewBox='0 0 512 512'
                >
                  <line
                    x1='224'
                    y1='184'
                    x2='352'
                    y2='184'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                  <line
                    x1='224'
                    y1='256'
                    x2='352'
                    y2='256'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                  <line
                    x1='224'
                    y1='327'
                    x2='352'
                    y2='327'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                  <path
                    d='M448,258c0-106-86-192-192-192S64,152,64,258s86,192,192,192S448,364,448,258Z'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeMiterlimit: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                  <circle
                    cx='168'
                    cy='184'
                    r='8'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                  <circle
                    cx='168'
                    cy='257'
                    r='8'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                  <circle
                    cx='168'
                    cy='328'
                    r='8'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                </svg>
              </span>
            </button> */}
          </div>
          <div className='card rounded mb-5 ' style={{ position: 'initial' }}>
            <Grid container xs={12} padding={2}>
              <Grid xs={6}>
                <Grid xs={12}>
                  <Typography variant='h4' fontWeight={'bold'}>
                    {products?.name} {products?.surname}
                  </Typography>
                  {/* <Typography variant='h6' fontWeight={'bold'} color={'#6C757D'}>
                    {products?.email}{' '}
                    {(products?.emailStatus !== 0 || products?.emailStatus !== 1) && products?.emailNotification ? (
                      <VerifiedUserIcon color='success' />
                    ) : (
                      <Tooltip
                        title={`Verification email has been sent on ${products?.emailVerificationSendDate} Click to resend.`}
                        placement='right-end'
                      >
                        <IconButton onClick={handleEmailResend} disabled={emailSendLoading}>
                          <GppMaybeIcon color='error' />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Typography> */}
                </Grid>
                <Grid xs={12} container marginTop={3}>
                  <Grid xs={4} padding={1} justifyContent={'center'}>
                    <Avatar
                      variant='rounded'
                      sx={{ width: '109px', height: '109px' }}
                      src={UserImage}
                      alt='Thumb'
                      imgProps={{ style: { objectFit: 'contain' } }}
                    />
                  </Grid>
                  <Grid xs={8} container height={115}>
                    <Grid
                      xs={4}
                      display={'flex'}
                      direction={'column'}
                      justifyContent={'space-between'}
                    >
                      <Typography color={'#ADB5BD'}>User Status</Typography>
                      <Typography color={'#ADB5BD'}>Email Status</Typography>
                      <Typography color={'#ADB5BD'}>User Role</Typography>
                    </Grid>
                    <Grid
                      xs={4}
                      display={'flex'}
                      direction={'column'}
                      justifyContent={'space-between'}
                    >
                      <Typography height={32}>{statusId(products?.statusId)}</Typography>
                      <Typography height={32}>{emailStatus(products?.emailStatus)}</Typography>
                      <Typography height={32}>{roleId(products?.role[0]?.roleId)}</Typography>
                    </Grid>
                    <Grid
                      xs={1}
                      display={'flex'}
                      direction={'column'}
                      justifyContent={'space-between'}
                    >
                      <div></div>
                      {products?.emailStatus === 2 && (
                        <Button
                          style={{ textTransform: 'none', textDecoration: 'underline' }}
                          onClick={handleEmailResend}
                          disabled={emailSendLoading}
                        >
                          Resend
                        </Button>
                      )}

                      <div></div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*    <Grid xs={1} container justifyContent={'center'}></Grid> */}
              {/* <Grid xs={4} container justifyContent={'center'}>
                <Grid xs={4} justifyContent={'center'} textAlign={'center'}>
                  <Typography
                    variant='overline'
                    fontWeight={'bold'}
                    color={'#6C757D'}
                  >
                    Role
                  </Typography>
                  <Typography>{roleId(products?.role[0]?.roleId)}</Typography>
                </Grid>
                <Grid xs={4} justifyContent={'center'} textAlign={'center'}>
                  <Typography
                    variant='overline'
                     fontWeight={'bold'}
                    color={'#6C757D'}
                  >
                    Status
                  </Typography>
                  <Typography>{statusId(products?.statusId)}</Typography>
                </Grid>
              </Grid> */}
              <Grid xs={6} container>
                <Grid xs={4} display={"flex"} justifyContent={'center'} alignItems={"center"} >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      '& > :not(style)': {
                        /* m: 0.5, */
                        width: '110px',
                        height: '110px',
                      },
                    }}
                  >
                    <div
                      className='border border-dashed border-success text-center rounded '
                      style={{ height: '110px', width: "110px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                    >
                      <p className=' text-success m-0' style={{ fontSize: "40px", fontWeight: "bold" }}>{products?.finishedJourney}</p>

                      <span className='text-success' style={{ fontSize: '16px' }}>
                        Journeys Completed
                      </span>
                    </div>
                  </Box>
                </Grid>
                <Grid xs={4} display={"flex"} justifyContent={'center'} alignItems={"center"}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      '& > :not(style)': {
                        /* m: 0.5, */
                        width: '110px',
                        height: '110px',
                      },
                    }}
                  >
                    <div
                      className='border border-dashed rounded  border-primary text-center '
                      style={{ height: '110px', width: "110px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                    >
                      <p className='text-primary m-0' style={{ fontSize: "40px", fontWeight: "bold" }}>{products?.completedActivityCount}</p>

                      <span className='text-primary' style={{ fontSize: '16px' }}>
                        Activities Completed
                      </span>
                    </div>
                  </Box>
                </Grid>
                <Grid xs={4} display={"flex"} justifyContent={'center'} alignItems={"center"}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      '& > :not(style)': {
                        /*  m: 0.5, */
                        width: '110px',
                        height: '110px',
                      },
                    }}
                  >
                    <div
                      className=' text-center rounded '
                      style={{ height: '110px', width: "110px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", border: "1px dashed #9C27B0" }}
                    >
                      <p className='m-0' style={{ fontSize: "40px", fontWeight: "bold", color: "#9C27B0" }}>{products?.questionsAnsweredCount}</p>
                      <span style={{ fontSize: '16px', color: "#9C27B0" }}>
                        Questions Answered
                      </span>
                    </div>
                  </Box>
                </Grid>
                {/*  <Grid xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      '& > :not(style)': {
                        m: 0.5,
                        width: '100%',
                        height: '100%',
                      },
                    }}
                  >
                    <div
                      className='border border-dashed border-info text-center rounded '
                      style={{ height: '60px' }}
                    >
                      <span className='fs-1 text-info m-0'>{products?.finishedQuiz}</span>
                      <br />
                      <span className='text-info' style={{ fontSize: '12px' }}>
                        Quizes Completed
                      </span>
                    </div>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      '& > :not(style)': {
                        m: 1,
                        width: '100%',
                        height: '100%',
                      },
                    }}
                  >
                    <div className=' '>
                      <span className='text-muted fs-6'>Knowledge Level</span>
                      <span className='text-muted float-end fs-6'>{products?.knowledgeLevel}%</span>
                      <div className='progress'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: `${products?.knowledgeLevel}%` }}
                          data-dz-uploadprogress
                        ></div>
                      </div>
                    </div>
                  </Box>
                </Grid> */}
              </Grid>
            </Grid>
          </div>
          <Paper>
            <Box sx={{ width: '100%' }} mb={5}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs1 value={tabValue} onChange={handleTabChange} aria-label='basic tabs example'
                  sx={{
                    marginTop: 2,

                    '& .MuiTab-root': {

                      fontSize: '15px', // İsteğe bağlı olarak font boyutunu ayarlayın
                    },
                  }}
                >
                  <Tab1
                    disabled={roleIsValidTab ? false : true}
                    label='User Info'
                    {...a11yProps(0)}
                    sx={{ fontSize: 13 }}
                  />
                  <Tab1
                    disabled={roleIsValidTab ? false : true}
                    label='Performance'
                    {...a11yProps(1)}
                    sx={{ fontSize: 13 }}
                  />
                  <Tab1 disabled={roleIsValidTab ? false : true} label='Notes' {...a11yProps(2)} sx={{ fontSize: 13 }} />
                  {
                    <Tab1
                      disabled={!roleIsValidCategory || (products?.role?.length > 0 ? false : true)}
                      label='Category'
                      {...a11yProps(3)}
                      sx={{ fontSize: 13 }}
                    />
                  }
                </Tabs1>
              </Box>
              {
                //#region  User İnfo
                <TabPanel value={tabValue} index={0}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container xs={12} spacing={2} rowGap={1}>
                      <Grid item container xs={6} spacing={2} rowGap={1} marginLeft={0}>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            Name Surname
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {products?.name} {products?.surname}
                          </Typography>
                          {
                            <Divider sx={{ borderTop: '2px solid' }} />
                          }
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            Email
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {products?.email}
                          </Typography>
                          {<Divider sx={{ borderTop: '2px solid' }} />}
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            Use email address for notifications?
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {products?.emailNotification ? 'Yes' : 'No'}
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            Phone
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {/*   {products?.phone ? `+${products?.phone}` : null} */}
                            {products?.phone}
                          </Typography>
                          {<Divider sx={{ borderTop: '2px solid' }} />}
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            Employee ID
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {products?.InternalEmployeeId}
                          </Typography>
                          {<Divider sx={{ borderTop: '2px solid' }} />}
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            Assigned Groups
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {products?.group.map((item: any, index: any) => {
                              return (
                                <>
                                  {index > 0 && ', '}
                                  {item.groupName}
                                </>
                              )
                            })}
                          </Typography>
                          {<Divider sx={{ borderTop: '2px solid' }} />}
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            Role
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {products?.role.map((item: any, index: any) => {
                              return (
                                <>
                                  {index > 0 && ', '}
                                  {item.roleName}
                                </>
                              )
                            })}
                          </Typography>
                          {<Divider sx={{ borderTop: '2px solid' }} />}
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            Country
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography variant='h5' fontWeight='bold' component='h2' height={21}>
                            {/* {products?.createdDate?.slice(0, 10)} {products?.createdDate?.slice(11, 15)} */}
                            {products?.countryName}
                          </Typography>
                          {<Divider sx={{ borderTop: '2px solid' }} />}
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            Organisation
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {/*  {products?.customerDate?.slice(0, 10)}{' '}
                        {products?.customerDate?.slice(11, 15)} */}
                            {products?.customer.customerName}
                          </Typography>
                          {
                            <Divider sx={{ borderTop: '2px solid' }} />
                          }
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            User ID
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {/*  {products?.defaultUserPassword.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} */}
                            {id}
                          </Typography>
                          {<Divider sx={{ borderTop: '2px solid' }} />}
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            Status
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {/*  {products?.defaultUserPassword.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} */}
                            {products?.statusName}
                          </Typography>
                          {<Divider sx={{ borderTop: '2px solid' }} />}
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            Joined Date
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {/*  {products?.defaultUserPassword.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} */}
                            {products?.createdDate}
                          </Typography>
                          {<Divider sx={{ borderTop: '2px solid' }} />}
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            Added by
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {/*  {products?.defaultUserPassword.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} */}
                            {products?.createdName}
                          </Typography>
                          {<Divider sx={{ borderTop: '2px solid' }} />}
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} color='text.secondary' component='h2'>
                            GDPR Consent
                          </Typography>
                          <Divider sx={{ borderTop: '2px solid' }} />
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontSize={14} fontWeight='bold' component='h2' height={21}>
                            {/*  {products?.defaultUserPassword.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} */}
                            {products?.GDPRAcceptedDate}
                          </Typography>
                          {<Divider sx={{ borderTop: '2px solid' }} />}
                        </Grid>
                      </Grid>
                      {/*   <Grid xs={12}>
                      <Typography variant='h4' fontWeight={'bold'} component='h2'>
                        Customer Info
                      </Typography>
                      <Divider style={{marginTop: '5px'}} />
                    </Grid> */}

                      <Grid xs={12} mt={2}>
                        <Divider variant='middle' sx={{ borderTop: '2px solid' }} />
                      </Grid>
                      <Grid
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}
                      >
                        {roleIsValid && (
                          <Button variant='contained' onClick={() => setOpenEditUserModal(true)}>
                            EDIT
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
                //#endregion
              }
              {
                //#region  Performance
                <TabPanel value={tabValue} index={1}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container item xs={12} md={12} lg={12} xl={12} spacing={2} >
                      {
                        //#region Knowledge Level
                        <Grid item xs={12} md={12} lg={6} xl={6} rowGap={2} height={"auto"} display={"flex"} flexDirection={"column"} justifyContent={"center"}  >
                          <Grid xs={12} justifyContent={"start"} width={"100%"} height={750} display={"flex"} flexDirection={"column"} rowGap={2} >
                            <Paper elevation={3} sx={{ height: "250px", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                              <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                sx={{
                                  background: "#EFF7FF",
                                  borderRadius: "10px 10px 0px 0px"
                                }}
                              >
                                <div style={{ width: "100%", display: "flex", justifyContent: "start", paddingLeft: 50 }}>
                                  <div style={{ width: "70%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography marginLeft={3} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Knowledge Level</Typography>
                                  </div>
                                </div>


                                <div>
                                  <Tooltip arrow title={language?.knowledgeLevel} placement='top' >
                                    <IconButton size='small'>
                                      <InfoOutlinedIcon fontSize='medium' />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </Grid>
                              {
                                //#region content
                                loadingUserDetail ?
                                  <Grid xs={12} height={"100%"} minHeight={170} display={"flex"} alignItems={"center"} justifyContent={"center"} padding={2}>
                                    <CircularProgress size={26} color='primary' />
                                  </Grid>
                                  :
                                  <Grid xs={12} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                    <div style={{ position: "relative", display: "inline-block", height: "100%", width: 220, marginLeft: -15, paddingTop: 40 }}>
                                      <GaugeChart
                                        id="gauge-chart5"
                                        nrOfLevels={420}
                                        arcsLength={[0.5, 0.25, 0.25]}
                                        colors={['#FF0000', '#D9F82D', '#76FF00']}
                                        percent={(knowledgeLevelData?.generalSuccessRate / 100)}
                                        arcPadding={0.02}
                                        textColor={'#555555'}
                                        needleColor={'#C9CED4'}
                                        needleBaseColor={'#E0E0E0'}
                                        hideText={true}
                                      />
                                      <div style={{ display: "flex", alignItems: "end", justifyContent: "center", marginTop: -70, paddingLeft: 15 }}>
                                        <Typography fontSize={40} fontWeight={"bold"} color={"#212529"}>{knowledgeLevelData?.generalSuccessRate}</Typography>
                                        <Typography fontSize={25} fontWeight={"bold"} color={"#212529"}>%</Typography>
                                      </div>
                                    </div>
                                    <Box marginLeft={-2.5} sx={{ height: 90, width: 115, border: "1px solid #D5D5D5", borderRadius: "5px", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", textAlign: "center" }}>
                                      <Typography color={"#212529"} fontSize={14} fontWeight={"bold"}>{knowledgeLevelData?.generalAnswerCount}</Typography>
                                      <Typography color={"#212529"} fontSize={12} >Asked questions</Typography>
                                      <Typography color={"#212529"} fontSize={14} fontWeight={"bold"}>{knowledgeLevelData?.generalCorrectAnswerCount}</Typography>
                                      <Typography color={"#212529"} fontSize={12} >Correct answers</Typography>
                                    </Box>
                                    {
                                      <Box sx={{ width: 430, minHeight: 170, height: "100%", paddingRight: 2 }}>
                                        <ReactApexChart key={0} options={apexChartSuccess.options} series={apexChartSuccess.series} type="bar" height={"100%"} />
                                      </Box>
                                    }

                                  </Grid>
                                //#endregion
                              }
                            </Paper>
                            {
                              //#region Avg. TimeSpent
                              <Grid item xs={12} width={"100%"} height={"auto"} display={"flex"} justifyContent={"center"} >
                                <Paper elevation={3} sx={{ height: "250px", width: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                                  <Grid xs={12} height={25} maxHeight={25} paddingLeft={9} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                    sx={{
                                      background: "#EFF7FF"
                                    }}
                                  >
                                    <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Avg. Time Spend</Typography>
                                    <div>
                                      <Tooltip arrow title={language?.avgTimeSpend} placement='top' >
                                        <IconButton size='small'>
                                          <InfoOutlinedIcon fontSize='medium' />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </Grid>
                                  {
                                    //#region content
                                    <Grid xs={12} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} padding={1}>
                                      <Box sx={{ borderRadius: "5px", width: 280, display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", textAlign: "center" }}>
                                        <Box display={"flex"} alignItems={"end"} textAlign={"center"} justifyContent={"center"} >
                                          {avgTimeSpend.hours > 0 && (
                                            <>
                                              <Typography color={"#212529"} fontSize={40} fontWeight={"bold"} sx={{ height: 55 }}>
                                                {avgTimeSpend.hours}
                                              </Typography>
                                              <Typography color={"#212529"} fontSize={25}>
                                                hr
                                              </Typography>
                                            </>
                                          )}
                                          <Typography color={"#212529"} marginLeft={1} fontSize={40} fontWeight={"bold"} sx={{ height: 55 }}>{avgTimeSpend.minutes}</Typography>
                                          <Typography color={"#212529"} fontSize={25}  >min</Typography>
                                        </Box>
                                        <Typography color={"#212529"} fontSize={16} >{"per month"}</Typography>
                                        {
                                          avgTimeSpendDelta !== null &&
                                          <Box display={"flex"} alignItems={"end"} textAlign={"center"} justifyContent={"center"} >

                                            <Typography
                                              style={{
                                                color: avgTimeSpendDelta.startsWith('-') ? "#FF0000" : "#4CAF50" // Negatifse kırmızı, değilse yeşil
                                              }} fontSize={16} >
                                              {`( ${avgTimeSpendDelta} )`}
                                            </Typography>

                                          </Box>
                                        }
                                      </Box>
                                      {
                                        <Box sx={{ width: "100%", height: "200px", paddingRight: 2 }}>
                                          <ReactApexChart key={new Date().getTime()} options={apexChartTimeSpend.options} series={apexChartTimeSpend.series} type="line" height={"100%"} />
                                        </Box>
                                      }

                                    </Grid>
                                    //#endregion
                                  }
                                  <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                    sx={{
                                      background: "#EFF7FF"
                                    }}
                                  >
                                    <div></div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      {/* <Button variant='text' size='small' onClick={handleRouteUsers}>
                                  <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>View Detail</Typography>
                                  <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />
                                </Button> */}
                                    </div>
                                    <div></div>
                                  </Grid>
                                </Paper>
                              </Grid>
                              //#endregion
                            }
                            {
                              //#region  JOURNEYS
                              <Grid item height={220} marginTop={-2} xs={12} width={"100%"} display={"flex"} columnGap={2} justifyContent={"center"} alignContent={"start"}  >
                                {
                                  //#region Live Journeys
                                  <Grid item xs={6} height={220}  >
                                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                                      <Grid xs={12} height={25} maxHeight={25} paddingLeft={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                        sx={{
                                          background: "#EFF7FF"
                                        }}
                                      >
                                        <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Live Journeys</Typography>
                                        <div >
                                          <Tooltip arrow title={language?.liveJourneys} placement='top' >
                                            <IconButton size='small'>
                                              <InfoOutlinedIcon fontSize='medium' />
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      </Grid>
                                      {
                                        //#region content Live Journeys
                                        <Grid xs={12} height={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} padding={0.5}>

                                          <Box display={"flex"} justifyContent={"center"} width={"100%"}>
                                            {
                                              !loadingUserDetail &&
                                              <StatusBars live={journeyCountInfo?.startedJourneyCount} finished={journeyCountInfo?.finishedJourneyCount} paused={journeyCountInfo?.pausedJourneyCount} scheduled={journeyCountInfo?.readyJourneyCount} totalCount={journeyCountInfo?.totalJourneyCount} />
                                            }
                                          </Box>
                                        </Grid>
                                        //#endregion
                                      }

                                    </Paper>
                                  </Grid>
                                  //#endregion
                                }
                                {
                                  //#region Latest Journeys
                                  <Grid item xs={6} height={220} >
                                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                                      <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                        sx={{
                                          background: "#EFF7FF"
                                        }}
                                      >
                                        <Typography marginLeft={2} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Latest Journeys</Typography>

                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: 130 }}>
                                          <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Progress</Typography>
                                          <Tooltip arrow title={language?.latestJourneys} placement='top' >
                                            <IconButton size='small' >
                                              <InfoOutlinedIcon fontSize='medium' />
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      </Grid>
                                      {
                                        //#region content Latest Journeys
                                        <Grid xs={12} height={"100%"} minWidth={290} display={"flex"} flexDirection={"column"} alignItems={"start"} justifyContent={"start"} padding={1}>
                                          {
                                            latestJourneys?.map((item: any, index) =>
                                              <Box key={index} display={"flex"} alignContent={"end"} justifyContent={"space-between"} width={"100%"}>
                                                <Box display={"flex"} justifyContent={"start"} alignItems={"center"} columnGap={2} paddingLeft={0.5} width={"100%"}>
                                                  <Typography fontSize={20} color={"#707070"}>{index + 1}</Typography>

                                                  <Typography fontSize={14} fontWeight={"bold"} color={"#000000"} >
                                                    {`${capitalizeFirstLetter(item.journeyName)} `}
                                                  </Typography>
                                                </Box>
                                                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"start"} mr={1}>
                                                  <LinearProgress variant="determinate" value={item.progressRate} sx={{
                                                    width: 115, height: 20, borderRadius: "0px 5px 5px 0px", zIndex: 1, backgroundColor: '#4caf5080',
                                                    '& .MuiLinearProgress-bar': {
                                                      backgroundColor: '#4CAF50',
                                                    }
                                                  }} />
                                                  <Typography fontSize={14} color={"white"} marginTop={-2.6} zIndex={2} ml={"2px"} >
                                                    {`${item.progressRate}%`}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            )
                                          }
                                        </Grid>
                                        //#endregion
                                      }

                                    </Paper>
                                  </Grid>
                                  //#endregion
                                }
                              </Grid>
                              //#endregion
                            }
                          </Grid>
                        </Grid>
                        //#endregion
                      }
                      {
                        //#region Category
                        <Grid item xs={12} md={12} lg={6} xl={6} display={"flex"} flexDirection={"column"} rowGap={2} justifyContent={"start"}  >
                          <Paper elevation={3} sx={{ height: "auto", width: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                            {
                              //#region content
                              <Grid xs={12} height={"100%"} display={"flex"} alignItems={"start"} justifyContent={"space-between"} padding={1}>
                                <Box sx={{ height: 321, width: '95%' }}>
                                  <StyledDataGrid
                                    /*   {...data}
                                        loading={data.rows.length === 0} */
                                    rows={rows || []}
                                    columns={columns}
                                    rowHeight={50}
                                    density='standard'
                                    columnHeaderHeight={40}
                                    disableVirtualization
                                    /* checkboxSelection */
                                    /* onCellClick={handleCellClick} */
                                    getRowId={(row) => row.categoryId}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                                    disableRowSelectionOnClick
                                    sortingMode='client'
                                    sortModel={journeyOptions?.sortModel}
                                    onSortModelChange={handleSortModelChange}
                                    onRowSelectionModelChange={handleSelectionModelChange}
                                    filterMode='client'
                                    /* onFilterModelChange={onFilterChange} */
                                    /* isRowSelectable={(params: GridRowParams) => params.row.source === 'Client'} */
                                    rowCount={rowCount}
                                    loading={loadingUserDetail}
                                    paginationModel={paginationModel}
                                    rowSelectionModel={selectQuestion}
                                    paginationMode='client'
                                    pagination={true}
                                    onPaginationModelChange={setPaginationModel}
                                    getRowClassName={(params) =>
                                      `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
                                    }
                                    slots={{
                                      /*    toolbar: CustomToolbar, */
                                      pagination: CustomPagination,
                                    }}
                                    sx={{
                                      boxShadow: "none",
                                      border: "none",
                                      '& .MuiDataGrid-footerContainer': {
                                        padding: '0px', // Footer'ın içerisindeki padding'i daraltmak için bu değerleri ayarlayabilirsiniz
                                        height: 30
                                      },

                                      '& .MuiDataGrid-cell:hover': {
                                        /*    color: 'primary.main', */
                                        cursor: 'pointer',
                                      },
                                      '&	.MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold',
                                        color: '#A1A5B7',
                                        fontSize: 12,
                                      },
                                      '& .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                      '& .MuiDataGrid-columnHeaders': {
                                        backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
                                      },
                                    }}
                                  />
                                </Box>
                                <Box sx={{ width: "30px" }} display={"flex"} width={"auto"} alignItems={"center"} justifyContent={"space-between"}>
                                  <Tooltip arrow title={language?.categoryDataGrid} placement='top' >
                                    <IconButton size='small'>
                                      <InfoOutlinedIcon fontSize='medium' />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Grid>
                              //#endregion
                            }
                          </Paper>
                          {
                            //#region test
                            /*  <Grid item xs={12} display={"flex"} justifyContent={"center"} height={"100%"} > */
                            <Paper elevation={3} sx={{ height: "auto", width: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                              {
                                //#region content
                                <Grid xs={12} height={"100%"} display={"flex"} alignItems={"start"} justifyContent={"space-between"} padding={1}>
                                  <Box sx={{ height: 321, width: '95%' }}>
                                    <StyledDataGrid
                                      /*   {...data}
                                          loading={data.rows.length === 0} */
                                      rows={rows1 || []}
                                      columns={columns1}
                                      rowHeight={50}
                                      columnHeaderHeight={40}
                                      density='standard'
                                      /* checkboxSelection */
                                      onCellDoubleClick={handleCellClick}
                                      getRowId={(row) => row.activityId}
                                      columnVisibilityModel={columnVisibilityModel1}
                                      onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel1(newModel)}
                                      disableRowSelectionOnClick
                                      sortingMode='client'
                                      sortModel={journeyOptions1?.sortModel}
                                      onSortModelChange={handleSortModelChange1}
                                      onRowSelectionModelChange={handleSelectionModelChange1}
                                      filterMode='client'
                                      /* onFilterModelChange={onFilterChange} */
                                      /* isRowSelectable={(params: GridRowParams) => params.row.source === 'Client'} */
                                      rowCount={rowCount1}
                                      loading={loadingUserDetail}
                                      paginationModel={paginationModel1}
                                      rowSelectionModel={selectQuestion1}
                                      paginationMode='client'
                                      pagination={true}
                                      onPaginationModelChange={setPaginationModel1}
                                      getRowClassName={(params) =>
                                        `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
                                      }
                                      slots={{
                                        /*    toolbar: CustomToolbar, */
                                        pagination: CustomPagination1,
                                      }}
                                      sx={{
                                        boxShadow: "none",
                                        border: "none",
                                        '& .MuiDataGrid-footerContainer': {
                                          padding: '0px', // Footer'ın içerisindeki padding'i daraltmak için bu değerleri ayarlayabilirsiniz
                                          height: 30
                                        },

                                        '& .MuiDataGrid-cell:hover': {
                                          /*    color: 'primary.main', */
                                          cursor: 'pointer',
                                        },
                                        '&	.MuiDataGrid-columnHeaderTitle': {
                                          fontWeight: 'bold',
                                          color: '#A1A5B7',
                                          fontSize: 12,
                                        },
                                        '& .MuiDataGrid-cell:focus': {
                                          outline: 'none',
                                        },
                                        '& .MuiDataGrid-columnHeaders': {
                                          backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
                                        },
                                      }}
                                    />
                                  </Box>
                                  <Box sx={{ width: "30px" }} display={"flex"} width={"auto"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Tooltip arrow title={language?.testDataGrid} placement='top' >
                                      <IconButton size='small'>
                                        <InfoOutlinedIcon fontSize='medium' />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                                //#endregion
                              }
                            </Paper>
                            /* </Grid> */
                            //#endregion
                          }
                        </Grid>
                        //#endregion
                      }
                    </Grid>
                  </Box>
                </TabPanel>
                //#endregion
              }
              {
                //#region  Notes
                <TabPanel value={tabValue} index={2}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} rowGap={1}>
                      <Grid
                        xs={12}
                        height={500}
                        style={{ overflowX: 'hidden', overflowY: 'auto' }}
                        padding={1}
                      >
                        <div>
                          {products?.notes?.map((item: any, index: any) => {
                            return (
                              <>
                                <div className='row d-flex align-items-center'>
                                  <div className='col-11 '>
                                    <span className='fs-3'>
                                      <div dangerouslySetInnerHTML={{ __html: item.note }} />
                                    </span>
                                  </div>
                                  <div className='col-1'>
                                    <button
                                      className='btn'
                                      onClick={() => deleteNote(item?.noteId)}
                                    >
                                      {' '}
                                      <span className=' svg-icon svg-icon-2x'>
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='25'
                                          height='23.214'
                                          viewBox='0 0 25 23.214'
                                        >
                                          <g id='trash-bin' transform='translate(-32 -48)'>
                                            <rect
                                              id='Rectangle_2203'
                                              data-name='Rectangle 2203'
                                              width='25'
                                              height='4.787'
                                              rx='2.394'
                                              transform='translate(32 48)'
                                              fill='#F1416C'
                                            />
                                            <path
                                              id='Path_2378'
                                              data-name='Path 2378'
                                              d='M66.849,160a.423.423,0,0,0-.328.158.513.513,0,0,0-.112.374l1.447,15.221a.1.1,0,0,0,0,.013,2.739,2.739,0,0,0,2.623,2.555H83.187a2.739,2.739,0,0,0,2.621-2.555v-.013l1.445-15.222a.512.512,0,0,0-.112-.374.424.424,0,0,0-.328-.158Zm13.685,10.89a1.031,1.031,0,0,1,0,1.364.826.826,0,0,1-1.244,0l-2.457-2.693-2.458,2.693a.826.826,0,0,1-1.244,0,1.031,1.031,0,0,1,0-1.364l2.458-2.693L73.131,165.5a1.031,1.031,0,0,1,0-1.364.826.826,0,0,1,1.244,0l2.458,2.693,2.457-2.693a.826.826,0,0,1,1.244,0,1.031,1.031,0,0,1,0,1.364L78.077,168.2Z'
                                              transform='translate(-32.333 -107.107)'
                                              fill='#F1416C'
                                            />
                                          </g>
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <br />
                                <span
                                  style={{ fontWeight: 'bold' }}
                                  className='fs-3 d-flex justify-content-start'
                                >
                                  {item.createdName} {item?.createdDate?.slice(0, 10)}{' '}
                                  {item?.createdDate?.slice(11, 16)}
                                </span>
                                <br />
                                {products.notes.length - 1 !== index && (
                                  <hr className='border border-dashed border-dark' />
                                )}
                              </>
                            )
                          })}
                        </div>
                      </Grid>

                      <Grid xs={12} mt={2}>
                        <Divider variant='middle' sx={{ borderTop: '2px solid' }} />
                      </Grid>
                      <Grid
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}
                      >
                        <AddNotes userId={id} first={first} setfirst={setfirst} />
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
                //#endregion
              }
              {
                //#region  Category
                <TabPanel value={tabValue} index={3}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} rowGap={1}>
                      <Grid xs={12}>
                        <Box sx={{ width: '100%' }} mb={1}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs2
                              value={tabValue1}
                              onChange={handleTabChange1}
                              aria-label='basic tabs example'
                              sx={{
                                marginTop: 2,
                                minHeight: '40px', // Tabs bileşeninin min yüksekliğini ayarlayın
                                '& .MuiTabs-flexContainer': {
                                  minHeight: '40px',
                                },
                                '& .MuiTab-root': {
                                  minHeight: '30px', // Her bir Tab bileşeninin min yüksekliğini ayarlayın
                                  padding: '3px 6px', // Daha dar bir görünüm için padding'i azaltın
                                  fontSize: '15px', // İsteğe bağlı olarak font boyutunu ayarlayın
                                },
                              }}
                            >
                              <Tab2
                                label={products?.customer.customerName + ' Category'}
                                {...a11yProps1(0)}
                              />
                              <Tab1 label='Mobixa Category' {...a11yProps1(1)} />
                            </Tabs2>
                          </Box>
                          {
                            //#region  Category Customer
                            <TabPanel value={tabValue1} index={0}>
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} rowGap={1}>
                                  <Grid item xs={12}>
                                    <div
                                      className=' d-flex card-body pt-0'
                                      style={{
                                        overflowY: 'scroll',
                                        /*  overflowX: 'auto', */
                                        minHeight: "500px"
                                      }}
                                    >
                                      {categoriesCustomer?.map((data: any) => (
                                        <>
                                          <div>
                                            {
                                              //1.katman
                                            }
                                            <AccordionMui
                                              style={{
                                                marginRight: 20,
                                                borderRadius: '10px 10px 5px 5px',
                                                border: '1px solid #d5d5d5',
                                                minWidth: '300px',
                                              }}
                                              defaultExpanded={true}
                                            >
                                              <AccordionSummary
                                                /* expandIcon={
                                          <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                        } */
                                                style={{
                                                  background: '#D5D5D5',
                                                  borderRadius: '10px 10px 0px 0px',
                                                  padding: '0px 10px 0px 0px',
                                                  minHeight: '60px',
                                                }}
                                              >
                                                <div
                                                  className='d-flex '
                                                  style={{ alignItems: 'center', height: 20 }}
                                                >
                                                  <div>
                                                    <FormControlLabel
                                                      control={
                                                        <Android12Switch
                                                          defaultChecked
                                                          color='secondary'
                                                          size='medium'
                                                          value={data.categoryId}
                                                          checked={
                                                            hasAllChildrenSelected2(
                                                              data.children,
                                                              selectedMobixa
                                                            )
                                                          }
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();

                                                            const toggleSelection = (categories) => {
                                                              categories.forEach(category => {
                                                                const isSelected = selectedMobixa.includes(category.categoryId);

                                                                if (!isSelected) {
                                                                  setSelectedMobixa(prevSelected => [...prevSelected, category.categoryId]);
                                                                } else {
                                                                  setSelectedMobixa(prevSelected =>
                                                                    prevSelected.filter(selectedCategory => selectedCategory !== category.categoryId)
                                                                  );
                                                                }

                                                                if (category.children && category.children.length > 0) {
                                                                  toggleSelection(category.children);
                                                                }
                                                              });
                                                            };

                                                            if (data.children && data.children.length > 0) {
                                                              toggleSelection(data.children);
                                                            } else {
                                                              // Eğer alt kategorisi yoksa, normal seçim işlemi yapılır
                                                              const isCategorySelected = selectedMobixa.includes(data.categoryId);
                                                              setSelectedMobixa(prevSelected => isCategorySelected
                                                                ? prevSelected.filter(categoryId => categoryId !== data.categoryId)
                                                                : [...prevSelected, data.categoryId]);
                                                            }

                                                            setcategoryIsChanged(true)

                                                          }}
                                                        />
                                                      }
                                                      label=''
                                                    />

                                                  </div>

                                                  <div
                                                    style={{
                                                      fontWeight: 'bold',
                                                      cursor: 'pointer',
                                                      fontSize: '12px',
                                                      marginLeft: '-4px',
                                                    }}
                                                  >
                                                    {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                                      data.name.slice(1)}
                                                  </div>
                                                  <span
                                                    title='Number of available questions'
                                                    className='text-dark  ms-2'
                                                    style={{
                                                      fontSize: '10px',
                                                      color: '#8e8e8e',
                                                      fontWeight: 'bold',
                                                    }}
                                                  >
                                                    ({data.questionCount})
                                                  </span>
                                                </div>
                                              </AccordionSummary>
                                              <AccordionDetails style={{ padding: '3px' }}>
                                                {
                                                  //2. katman
                                                }

                                                {data?.children?.map((child: any) =>
                                                  child.children.length > 0 ? (
                                                    <>
                                                      <AccordionMui
                                                        style={{
                                                          marginTop: '5px',
                                                          marginBottom: '5px',
                                                          boxShadow: 'none',
                                                          borderRadius: '10px 10px 0px 0px',
                                                        }}
                                                        defaultExpanded={true}
                                                      >
                                                        <AccordionSummary
                                                          sx={{
                                                            margin: '0px!important',
                                                            '.MuiAccordionSummary-content': {
                                                              // AccordionSummary'nin içeriği için stil
                                                              marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                                            },
                                                            '&:hover': {
                                                              backgroundColor: '#e7e7e7', // Hover durumu için arka plan rengi
                                                            },
                                                          }}
                                                          style={{
                                                            borderRadius: '10px 10px 0px 0px',
                                                            padding: '0px 5px 0px 0px',
                                                            minHeight: '35px',
                                                            marginLeft: '0px!important',
                                                          }}
                                                        >
                                                          <div
                                                            className='d-flex'
                                                            style={{
                                                              alignItems: 'center',
                                                              justifyItems: 'start',
                                                              height: 20,
                                                            }}
                                                          /*   onMouseEnter={(e: any) => {
                                                            setHover(child.categoryId)
                                                          }}
                                                          onMouseLeave={(e: any) => {
                                                            setHover('')
                                                          }} */
                                                          >
                                                            <div>
                                                              <FormControlLabel
                                                                control={
                                                                  <Android12Switch
                                                                    defaultChecked
                                                                    color='secondary'
                                                                    size='medium'
                                                                    value={child.categoryId}
                                                                    checked={hasAllChildrenSelected(
                                                                      child.children,
                                                                      selectedMobixa
                                                                    )
                                                                    }
                                                                    onClick={(e) => {
                                                                      e.stopPropagation();
                                                                      if (child.children && child.children.length > 0) {
                                                                        child.children.forEach(childCategory => {
                                                                          const childIsSelected = selectedMobixa?.some(
                                                                            selectedCategory => selectedCategory === childCategory.categoryId
                                                                          );

                                                                          if (!childIsSelected) {
                                                                            setSelectedMobixa(prevSelected => [...prevSelected, childCategory.categoryId]);
                                                                          } else {
                                                                            setSelectedMobixa(prevSelected =>
                                                                              prevSelected.filter(selectedCategory => selectedCategory !== childCategory.categoryId)
                                                                            );
                                                                          }
                                                                        });
                                                                      }

                                                                      setcategoryIsChanged(true)
                                                                      e.preventDefault();
                                                                    }}
                                                                  />
                                                                }
                                                                label=''
                                                              />

                                                            </div>
                                                            <span
                                                              className={'text-gray-800 d-flex'}
                                                              style={{
                                                                fontWeight: 'bold',
                                                                cursor: 'pointer',
                                                                fontSize: '12px',
                                                                marginLeft: '-4px',
                                                              }}
                                                            >
                                                              {child.name.charAt(0).toUpperCase() +
                                                                child.name.slice(1)}
                                                            </span>
                                                            <span
                                                              title='Number of available questions'
                                                              className='text-dark text-muted ms-2'
                                                              style={{
                                                                fontSize: '10px',
                                                                color: '#8e8e8e',
                                                                fontWeight: 'normal',
                                                              }}
                                                            >
                                                              ({child.questionCount})
                                                            </span>
                                                          </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ padding: '3px' }}>
                                                          {child?.children?.map((child: any) => (
                                                            <div
                                                              id={child.categoryId}
                                                              className='ms-0 mt-3 '
                                                            >
                                                              <div
                                                                className='d-flex div-element-class'
                                                                style={{
                                                                  alignItems: 'center',
                                                                  height: 35,
                                                                  paddingLeft: 32,
                                                                  /* background:
                                                                    hover === child.categoryId
                                                                      ? '#e7e7e7'
                                                                      : '', */
                                                                }}
                                                              /*  onMouseEnter={(e: any) => {
                                                                setHover(child.categoryId)
                                                              }}
                                                              onMouseLeave={(e: any) => {
                                                                setHover('')
                                                              }} */
                                                              >
                                                                <div>
                                                                  <FormControlLabel
                                                                    control={
                                                                      <Android12Switch
                                                                        defaultChecked
                                                                        color='secondary'
                                                                        size='medium'
                                                                        value={child.categoryId}
                                                                        checked={selectedMobixa?.some(
                                                                          (category: any) =>
                                                                            category === child.categoryId
                                                                        )}
                                                                        onClick={(e) => {
                                                                          e.stopPropagation();
                                                                          const isCategorySelected = selectedMobixa?.some(
                                                                            category => category === child.categoryId
                                                                          );

                                                                          if (isCategorySelected) {
                                                                            setSelectedMobixa(prevSelected =>
                                                                              prevSelected.filter(category => category !== child.categoryId)
                                                                            );
                                                                          } else {
                                                                            setSelectedMobixa(prevSelected => [...prevSelected, child.categoryId]);
                                                                          }
                                                                          setcategoryIsChanged(true)
                                                                          e.preventDefault();
                                                                        }}
                                                                      />
                                                                    }
                                                                    label=''
                                                                  />

                                                                </div>
                                                                <a
                                                                  className={'text-gray-800 d-flex '}
                                                                  style={{
                                                                    cursor: 'pointer',
                                                                    fontSize: '12px',
                                                                    alignItems: 'center',
                                                                    maxWidth: '210px',
                                                                    marginLeft: '-4px',
                                                                  }}
                                                                >
                                                                  {child.name
                                                                    .charAt(0)
                                                                    .toUpperCase()
                                                                    .replace('🅜', '') + child.name.slice(1)}
                                                                </a>

                                                                <div
                                                                  style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    height: '20px',
                                                                  }}
                                                                >
                                                                  <span
                                                                    title='Number of available questions'
                                                                    className='ms-2'
                                                                    style={{
                                                                      color: '#8e8e8e',
                                                                      fontSize: '10px',
                                                                    }}
                                                                  >
                                                                    {`(${child.questionCount})`}
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ))}
                                                        </AccordionDetails>
                                                      </AccordionMui>
                                                    </>
                                                  ) : null
                                                )}
                                                {
                                                  // 3. katman
                                                }

                                                {data?.children?.map((child: any) =>
                                                  child.children.length > 0 ? null : (
                                                    <div id={child.categoryId} className='ms-0 mt-3 '>
                                                      <div
                                                        className='d-flex border-1 div-element-class'
                                                        style={{
                                                          alignItems: 'center',
                                                          height: 35,
                                                          paddingLeft: 4,
                                                          /* background:
                                                            hover === child.categoryId ? '#e7e7e7' : '', */
                                                        }}
                                                      /* onMouseEnter={(e: any) => {
                                                        setHover(child.categoryId)
                                                      }}
                                                      onMouseLeave={(e: any) => {
                                                        setHover('')
                                                      }} */
                                                      >
                                                        <div>
                                                          <FormControlLabel
                                                            control={
                                                              <Android12Switch
                                                                color='secondary'
                                                                value={child.categoryId}
                                                                checked={selectedMobixa?.some(
                                                                  (category: any) =>
                                                                    category === child.categoryId
                                                                )}
                                                                onClick={(e) => {
                                                                  e.stopPropagation();
                                                                  const isCategorySelected = selectedMobixa?.some(
                                                                    category => category === child.categoryId
                                                                  );

                                                                  if (isCategorySelected) {
                                                                    setSelectedMobixa(prevSelected =>
                                                                      prevSelected.filter(category => category !== child.categoryId)
                                                                    );
                                                                  } else {
                                                                    setSelectedMobixa(prevSelected => [...prevSelected, child.categoryId]);
                                                                  }
                                                                  setcategoryIsChanged(true)
                                                                  e.preventDefault();
                                                                }}
                                                                size='medium'
                                                              />
                                                            }
                                                            label=''
                                                          />

                                                        </div>
                                                        <a
                                                          className={'text-gray-800 d-flex'}
                                                          style={{
                                                            cursor: 'pointer',
                                                            fontSize: '12px',
                                                            maxWidth: '210px',
                                                            marginLeft: '-4px',
                                                          }}
                                                        >
                                                          {child.name.charAt(0).toUpperCase() +
                                                            child.name.slice(1)}
                                                        </a>

                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            height: '20px',
                                                          }}
                                                        >
                                                          <span
                                                            title='Number of available questions'
                                                            className='ms-2'
                                                            style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                          >
                                                            {`(${child.questionCount})`}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </AccordionDetails>
                                            </AccordionMui>
                                          </div>
                                        </>
                                      ))}
                                    </div>

                                  </Grid>
                                </Grid>
                              </Box>
                            </TabPanel>
                            //#endregion
                          }
                          {
                            //#region  Category Mobixa
                            <TabPanel value={tabValue1} index={1}>
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} rowGap={1}>
                                  <Grid item xs={12}>
                                    <div
                                      className=' d-flex card-body pt-0'
                                      style={{
                                        overflowY: 'auto',
                                        overflowX: 'auto',
                                        height: '500px',
                                      }}
                                    >
                                      {categoriesMobixa?.map((data: any) => (
                                        <>
                                          <div>
                                            {
                                              //1.katman
                                            }
                                            <AccordionMui
                                              style={{
                                                marginRight: 20,
                                                borderRadius: '10px 10px 5px 5px',
                                                border: '1px solid #d5d5d5',
                                                minWidth: '300px',
                                              }}
                                              defaultExpanded={true}
                                            >
                                              <AccordionSummary
                                                /* expandIcon={
                                          <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                        } */
                                                style={{
                                                  background: '#D5D5D5',
                                                  borderRadius: '10px 10px 0px 0px',
                                                  padding: '0px 10px 0px 0px',
                                                  minHeight: '60px',
                                                }}
                                              >
                                                <div
                                                  className='d-flex '
                                                  style={{ alignItems: 'center', height: 20 }}
                                                >
                                                  <div>
                                                    <FormControlLabel
                                                      control={
                                                        <Android12Switch
                                                          defaultChecked
                                                          color='secondary'
                                                          size='medium'
                                                          value={data.categoryId}
                                                          checked={
                                                            hasAllChildrenSelected2(
                                                              data.children,
                                                              selectedMobixa
                                                            )
                                                          }
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();

                                                            const toggleSelection = (categories) => {
                                                              categories.forEach(category => {
                                                                const isSelected = selectedMobixa.includes(category.categoryId);

                                                                if (!isSelected) {
                                                                  setSelectedMobixa(prevSelected => [...prevSelected, category.categoryId]);
                                                                } else {
                                                                  setSelectedMobixa(prevSelected =>
                                                                    prevSelected.filter(selectedCategory => selectedCategory !== category.categoryId)
                                                                  );
                                                                }

                                                                if (category.children && category.children.length > 0) {
                                                                  toggleSelection(category.children);
                                                                }
                                                              });
                                                            };

                                                            if (data.children && data.children.length > 0) {
                                                              toggleSelection(data.children);
                                                            } else {
                                                              // Eğer alt kategorisi yoksa, normal seçim işlemi yapılır
                                                              const isCategorySelected = selectedMobixa.includes(data.categoryId);
                                                              setSelectedMobixa(prevSelected => isCategorySelected
                                                                ? prevSelected.filter(categoryId => categoryId !== data.categoryId)
                                                                : [...prevSelected, data.categoryId]);
                                                            }

                                                            setcategoryIsChanged(true)

                                                          }}
                                                        />
                                                      }
                                                      label=''
                                                    />

                                                  </div>

                                                  <div
                                                    style={{
                                                      fontWeight: 'bold',
                                                      cursor: 'pointer',
                                                      fontSize: '12px',
                                                      marginLeft: '-4px',
                                                    }}
                                                  >
                                                    {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                                      data.name.slice(1)}
                                                  </div>
                                                  <span
                                                    title='Number of available questions'
                                                    className='text-dark  ms-2'
                                                    style={{
                                                      fontSize: '10px',
                                                      color: '#8e8e8e',
                                                      fontWeight: 'bold',
                                                    }}
                                                  >
                                                    ({data.questionCount})
                                                  </span>
                                                </div>
                                              </AccordionSummary>
                                              <AccordionDetails style={{ padding: '3px' }}>
                                                {
                                                  //2. katman
                                                }

                                                {data?.children?.map((child: any) =>
                                                  child.children.length > 0 ? (
                                                    <>
                                                      <AccordionMui
                                                        style={{
                                                          marginTop: '5px',
                                                          marginBottom: '5px',
                                                          boxShadow: 'none',
                                                          borderRadius: '10px 10px 0px 0px',
                                                        }}
                                                        defaultExpanded={true}
                                                      >
                                                        <AccordionSummary
                                                          sx={{
                                                            margin: '0px!important',
                                                            '.MuiAccordionSummary-content': {
                                                              // AccordionSummary'nin içeriği için stil
                                                              marginLeft: '4px', // Sol marjin değerini 0px olarak ayarlayın
                                                            },
                                                            '&:hover': {
                                                              backgroundColor: '#e7e7e7', // Hover durumu için arka plan rengi
                                                            },
                                                          }}
                                                          style={{
                                                            borderRadius: '10px 10px 0px 0px',
                                                            padding: '0px 5px 0px 0px',
                                                            minHeight: '35px',
                                                            marginLeft: '0px!important',
                                                          }}
                                                        >
                                                          <div
                                                            className='d-flex'
                                                            style={{
                                                              alignItems: 'center',
                                                              justifyItems: 'start',
                                                              height: 20,
                                                            }}
                                                          /*   onMouseEnter={(e: any) => {
                                                            setHover(child.categoryId)
                                                          }}
                                                          onMouseLeave={(e: any) => {
                                                            setHover('')
                                                          }} */
                                                          >
                                                            <div>
                                                              <FormControlLabel
                                                                control={
                                                                  <Android12Switch
                                                                    defaultChecked
                                                                    color='secondary'
                                                                    size='medium'
                                                                    value={child.categoryId}
                                                                    checked={hasAllChildrenSelected(
                                                                      child.children,
                                                                      selectedMobixa
                                                                    )
                                                                    }
                                                                    onClick={(e) => {
                                                                      e.stopPropagation();
                                                                      if (child.children && child.children.length > 0) {
                                                                        child.children.forEach(childCategory => {
                                                                          const childIsSelected = selectedMobixa?.some(
                                                                            selectedCategory => selectedCategory === childCategory.categoryId
                                                                          );

                                                                          if (!childIsSelected) {
                                                                            setSelectedMobixa(prevSelected => [...prevSelected, childCategory.categoryId]);
                                                                          } else {
                                                                            setSelectedMobixa(prevSelected =>
                                                                              prevSelected.filter(selectedCategory => selectedCategory !== childCategory.categoryId)
                                                                            );
                                                                          }
                                                                        });
                                                                      }

                                                                      setcategoryIsChanged(true)
                                                                      e.preventDefault();
                                                                    }}
                                                                  />
                                                                }
                                                                label=''
                                                              />

                                                            </div>
                                                            <span
                                                              className={'text-gray-800 d-flex'}
                                                              style={{
                                                                fontWeight: 'bold',
                                                                cursor: 'pointer',
                                                                fontSize: '12px',
                                                                marginLeft: '-4px',
                                                              }}
                                                            >
                                                              {child.name.charAt(0).toUpperCase() +
                                                                child.name.slice(1)}
                                                            </span>
                                                            <span
                                                              title='Number of available questions'
                                                              className='text-dark text-muted ms-2'
                                                              style={{
                                                                fontSize: '10px',
                                                                color: '#8e8e8e',
                                                                fontWeight: 'normal',
                                                              }}
                                                            >
                                                              ({child.questionCount})
                                                            </span>
                                                          </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ padding: '3px' }}>
                                                          {child?.children?.map((child: any) => (
                                                            <div
                                                              id={child.categoryId}
                                                              className='ms-0 mt-3 '
                                                            >
                                                              <div
                                                                className='d-flex div-element-class'
                                                                style={{
                                                                  alignItems: 'center',
                                                                  height: 35,
                                                                  paddingLeft: 32,
                                                                  /* background:
                                                                    hover === child.categoryId
                                                                      ? '#e7e7e7'
                                                                      : '', */
                                                                }}
                                                              /*  onMouseEnter={(e: any) => {
                                                                setHover(child.categoryId)
                                                              }}
                                                              onMouseLeave={(e: any) => {
                                                                setHover('')
                                                              }} */
                                                              >
                                                                <div>
                                                                  <FormControlLabel
                                                                    control={
                                                                      <Android12Switch
                                                                        defaultChecked
                                                                        color='secondary'
                                                                        size='medium'
                                                                        value={child.categoryId}
                                                                        checked={selectedMobixa?.some(
                                                                          (category: any) =>
                                                                            category === child.categoryId
                                                                        )}
                                                                        onClick={(e) => {
                                                                          e.stopPropagation();
                                                                          const isCategorySelected = selectedMobixa?.some(
                                                                            category => category === child.categoryId
                                                                          );

                                                                          if (isCategorySelected) {
                                                                            setSelectedMobixa(prevSelected =>
                                                                              prevSelected.filter(category => category !== child.categoryId)
                                                                            );
                                                                          } else {
                                                                            setSelectedMobixa(prevSelected => [...prevSelected, child.categoryId]);
                                                                          }
                                                                          setcategoryIsChanged(true)
                                                                          e.preventDefault();
                                                                        }}
                                                                      />
                                                                    }
                                                                    label=''
                                                                  />

                                                                </div>
                                                                <a
                                                                  className={'text-gray-800 d-flex '}
                                                                  style={{
                                                                    cursor: 'pointer',
                                                                    fontSize: '12px',
                                                                    alignItems: 'center',
                                                                    maxWidth: '210px',
                                                                    marginLeft: '-4px',
                                                                  }}
                                                                >
                                                                  {child.name
                                                                    .charAt(0)
                                                                    .toUpperCase()
                                                                    .replace('🅜', '') + child.name.slice(1)}
                                                                </a>

                                                                <div
                                                                  style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    height: '20px',
                                                                  }}
                                                                >
                                                                  <span
                                                                    title='Number of available questions'
                                                                    className='ms-2'
                                                                    style={{
                                                                      color: '#8e8e8e',
                                                                      fontSize: '10px',
                                                                    }}
                                                                  >
                                                                    {`(${child.questionCount})`}
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ))}
                                                        </AccordionDetails>
                                                      </AccordionMui>
                                                    </>
                                                  ) : null
                                                )}
                                                {
                                                  // 3. katman
                                                }

                                                {data?.children?.map((child: any) =>
                                                  child.children.length > 0 ? null : (
                                                    <div id={child.categoryId} className='ms-0 mt-3 '>
                                                      <div
                                                        className='d-flex border-1 div-element-class'
                                                        style={{
                                                          alignItems: 'center',
                                                          height: 35,
                                                          paddingLeft: 4,
                                                          /* background:
                                                            hover === child.categoryId ? '#e7e7e7' : '', */
                                                        }}
                                                      /* onMouseEnter={(e: any) => {
                                                        setHover(child.categoryId)
                                                      }}
                                                      onMouseLeave={(e: any) => {
                                                        setHover('')
                                                      }} */
                                                      >
                                                        <div>
                                                          <FormControlLabel
                                                            control={
                                                              <Android12Switch
                                                                color='secondary'
                                                                value={child.categoryId}
                                                                checked={selectedMobixa?.some(
                                                                  (category: any) =>
                                                                    category === child.categoryId
                                                                )}
                                                                onClick={(e) => {
                                                                  e.stopPropagation();
                                                                  const isCategorySelected = selectedMobixa?.some(
                                                                    category => category === child.categoryId
                                                                  );

                                                                  if (isCategorySelected) {
                                                                    setSelectedMobixa(prevSelected =>
                                                                      prevSelected.filter(category => category !== child.categoryId)
                                                                    );
                                                                  } else {
                                                                    setSelectedMobixa(prevSelected => [...prevSelected, child.categoryId]);
                                                                  }
                                                                  setcategoryIsChanged(true)
                                                                  e.preventDefault();
                                                                }}
                                                                size='medium'
                                                              />
                                                            }
                                                            label=''
                                                          />

                                                        </div>
                                                        <a
                                                          className={'text-gray-800 d-flex'}
                                                          style={{
                                                            cursor: 'pointer',
                                                            fontSize: '12px',
                                                            maxWidth: '210px',
                                                            marginLeft: '-4px',
                                                          }}
                                                        >
                                                          {child.name.charAt(0).toUpperCase() +
                                                            child.name.slice(1)}
                                                        </a>

                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            height: '20px',
                                                          }}
                                                        >
                                                          <span
                                                            title='Number of available questions'
                                                            className='ms-2'
                                                            style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                          >
                                                            {`(${child.questionCount})`}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </AccordionDetails>
                                            </AccordionMui>
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Box>
                            </TabPanel>
                            //#endregion
                          }
                        </Box>
                      </Grid>

                      <Grid xs={12} mt={2}>
                        <Divider variant='middle' sx={{ borderTop: '2px solid' }} />
                      </Grid>
                      <Grid
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}
                      >
                        <Button
                          variant='contained'
                          disabled={!categoryIsChanged}
                          color='inherit'
                          style={{ marginRight: '20px' }}
                          onClick={() => setOpenCategoryDiscardModal(true)}
                        >
                          DISCARD
                        </Button>
                        <Button
                          variant='contained'
                          disabled={!categoryIsChanged || categoriesLoading}
                          onClick={() => handleSave()}
                        >
                          SAVE{' '}
                          {categoriesLoading && (
                            <CircularProgress
                              color='inherit'
                              size={20}
                              style={{ marginLeft: '5px' }}
                            />
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
                //#endregion
              }
            </Box>
          </Paper>
          <AddUser
            openEditUserModal={openEditUserModal}
            setOpenEditUserModal={setOpenEditUserModal}
            setfirst={setfirst}
            first={first}
            userRole={userRole}
            selectedUserData={userData}
          />
          <Dialog
            open={openCategoryDiscardModal}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            PaperProps={{
              style: {
                backgroundColor: '#ED6C02',
              },
            }}
            maxWidth='xs'
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle>
              <Typography color='white' variant='h5'>
                <WarningAmberOutlinedIcon fontSize='large' /> {' WARNING'}
              </Typography>
            </DialogTitle>
            {/*   <Divider variant='middle' sx={{borderTop: '2px solid'}} /> */}
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                <Grid container spacing={2} justifyContent={'center'}>
                  <Grid item xs={12} md={12} justifyContent={'center'} display={'flex'}>
                    <Typography color='white' variant='h6'>
                      You have unsaved changes.
                      <br /> Do you want to discard, anyway?
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            {/*      <Divider variant='middle' sx={{borderTop: '2px solid'}} /> */}
            <DialogActions>
              <Button
                variant='text'
                disabled={getCategoryLoading}
                style={{ marginRight: '10px', color: 'white' }}
                onClick={() => getCategoryList()}
              >
                Yes{' '}
                {getCategoryLoading && (
                  <CircularProgress color='inherit' size={20} style={{ marginLeft: '5px' }} />
                )}
              </Button>
              <Button
                variant='text'
                onClick={() => setOpenCategoryDiscardModal(false)}
                color='inherit'
              >
                <CloseIcon style={{ color: 'white' }} />
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}
export default function Snackbar(props: any) {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} maxSnack={3}>
      <UserProfile {...props} />
    </SnackbarProvider>
  )
}
