import React from 'react';
import { Box } from '@material-ui/core';
import { Typography } from '@mui/material';

function StatusBars({ finished, live, paused, scheduled,totalCount }) {
  const total = finished + live + paused + scheduled;

/*   const calculateWidth = (value) => `${(value / total) * 100}%`; */

  return (
    <Box display="flex" justifyContent="center" width="100%" height={44} border={"1px solid #707070"} borderRadius={6}>
      <Box bgcolor="#BCBCBC" width={/* calculateWidth(finished) */"30%"} p={1} display={"flex"} justifyContent={"center"} borderRadius={"5px 0px 0px 5px"}>
        <Typography marginTop={"-25px"} fontSize={12} color={"#8E8E8E"} fontWeight={"bold"} position={"absolute"}>Finished</Typography>
        <Typography fontSize={16} color={"white"} >{finished}</Typography> 
      </Box>
      <Box bgcolor="#47BE7D" width={/* calculateWidth(live) */"30%"} p={1} display={"flex"} justifyContent={"center"}>
      <Typography marginTop={"-25px"} fontSize={12} color={"#8E8E8E"} fontWeight={"bold"} position={"absolute"}>Live</Typography>
        <Typography fontSize={20} fontWeight={"bold"} color={"white"} >{live}</Typography> 
      </Box>
    {/*   <Box bgcolor="#6C757D" width={"20%"} p={1} display={"flex"} justifyContent={"center"}>
      <Typography marginTop={"-25px"} fontSize={10} color={"#8E8E8E"} position={"absolute"}>Paused</Typography>
        <Typography fontSize={16} color={"white"} >{paused}</Typography> 
      </Box> */}
      <Box bgcolor="#2196F3" width={/* calculateWidth(scheduled) */"30%"} p={1} display={"flex"} justifyContent={"center"}>
      <Typography marginTop={"-25px"} fontSize={12} color={"#8E8E8E"} fontWeight={"bold"} position={"absolute"}>Scheduled</Typography>
        <Typography fontSize={16} color={"white"} >{scheduled}</Typography> 
      </Box>
      <Box bgcolor="#fff" width={"10%"} p={1} display={"flex"} justifyContent={"center"} borderRadius={"0px 5px 5px 0px"}>
      <Typography marginTop={"-25px"} fontSize={12} color={"#8E8E8E"} fontWeight={"bold"} position={"absolute"}>Total</Typography>
        <Typography fontSize={18} fontWeight={"bold"} color={"#212529"} >{totalCount}</Typography> 
      </Box>
    </Box>
  );
}

export default StatusBars;
