import React, {useEffect, useRef, useState} from 'react'
import {Editor} from '@tinymce/tinymce-react'
import {Button, Col, Container, Modal, Row, Stack, Tab, Tabs} from 'react-bootstrap'
import Cropper from 'react-cropper'
type Props = {
  question?: any
  html?: any
  changeDescription:any
  index:any
}
const TinyMCEWidgetLearning: React.FC<Props> = ({index,changeDescription,question,html}) => {

  let rm: any = document.getElementById('remaining')
  const [first, setfirst] = useState(false)

  const editorRef: any = useRef(null)
  const sizeLimit = 750
  const [value, setValue] = useState<any>(html)
  const [length, setLength] = useState<any>(0)
  const handleInit = (evt: any, editor: any) => {
    setLength(editor.getContent({format: 'text'}).length)
    editorRef.current = editor
  }

  

  const handleUpdate = (value: any, editor: any) => {
    const length = editor.getContent({format: 'text'}).length
      setValue(value)
      setLength(length)
  }
  const handleBeforeAddUndo = (evt: any, editor: any) => {
    const length = editor.getContent({format: 'text'}).length
    // note that this is the opposite test as in handleUpdate
    // because we are determining when to deny adding an undo level
    if (length > sizeLimit) {
      evt.preventDefault()
    }
  }
  useEffect(() => {
    setValue(html)
  }, [html])
  useEffect(() => {
    changeDescription(index,value)
  }, [value])

  return (
    <>
      <div style={{fontFamily: 'Atma'}}>  
        <div>
          <Editor
            onInit={handleInit}
            value={value}
            onEditorChange={handleUpdate}
            onBeforeAddUndo={handleBeforeAddUndo}
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            init={{
              height:"280px",
              width:"350px",
              body_class: 'atma-font',
              placeholder:"Details 1250 ch. max.",
              menubar: false,
              automatic_uploads: false,
              image_advtab: false,
              image_caption: false,
              image_description: false,
              paste_data_images: false,
              toolbar_mode: 'scrolling',
              auto_focus: 'element1',
              content_css: "./Customcss.css",
              content_style:
                'body { font-family:Atma ;background: #fff; color: #000000; font-size: 16pt;  } ',
              plugins: ' lists advlist colorpicker emoticons  charmap',
              toolbar:// undo redo
                ' bold italic underline forecolor backcolor removeformat bullist numlist Superscript subscript charmap',
              emoticons_database: 'emojis',
              relative_urls: false,
              statusbar: false,
              branding: false,
              language: 'tr_TR',
              convert_urls: false,
            }}
          />
        </div>
      </div>

      {/* <div
        className='bg-gray-800 d-flex border-top rounded-bottom justify-content-end'
        style={{
          backgroundColor: '#303741',
          color: 'white',
          fontSize: '11pt',
          fontFamily: 'Arial',
          height: '20px',
        }}
      >
        <span  style={{marginRight:'10px',marginTop:'-2px'}} id='remaining'>
          {length} / {sizeLimit}
        </span>
      </div> */}
    </>
  )
}

export default TinyMCEWidgetLearning
