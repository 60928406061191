/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'
import { Typography } from '@mui/material'

const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <div className='footer py-2 d-flex flex-lg-column' style={{height:"50px",marginTop:"15px" ,background: "url('PageFooterDark.png') center / cover no-repeat", mixBlendMode:"luminosity" }} id='kt_footer'>
  {/*     <hr style={{padding:"0px", margin:"0px"}}/> */}
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='col-md-2 text-dark ' style={{color:"#fff"}}>
        <Typography  style={{color:"#D5D5D5"}}> {new Date().getDate()}  {new Date().getMonth() + 1} {new Date().getFullYear()}  {/* &copy; */}</Typography>
          
        </div>
        {/* end::Copyright */}
        <div className='col-md-8 text-center' >
          <Typography  style={{color:"#D5D5D5"}}>
          Copyright {new Date().getFullYear()}  MobixaPlus <br/> SmartCV Technology Incorporated
          </Typography>
        </div>
        <div className='col-md-2 text-center' >
        <Typography  style={{color:"#D5D5D5"}}>
         v2.1
         </Typography>
        </div>
        {/* begin::Nav */}
       {/*  <a href='#' className='col-md-2 text-end '>
        GDPR
        </a> */}
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export default Footer
