import { Button } from '@mui/material';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
type Props = {
    company: any
    questionId: any
    show:any
    setShow:any
    setDublicateId:any
  }
  
  export const Dublicate: FC<Props> = ({company, questionId,show,setShow,setDublicateId}) => {

  const LoginState = useSelector((state: any) => state.Login)
  const [showmodal,setShowModal] = useState(false)
const [response, setresponse] = useState<any>('')
  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    setShowModal(true)
    axios.post(
      `${process.env.REACT_APP_API_URL}/content/question/duplicate/${questionId}`,
      '',
      {
          headers: {
              'accept': 'application/json',
              'Authorization':  `Bearer ${LoginState.loginData.access_token}`,
              'Content-Type': 'application/x-www-form-urlencoded'
          }
      }
  ).then((res:any)=>{setresponse(res.data.questionId)
    setDublicateId(res.data.questionId)
    })
    setTimeout(() => {
      setShow(!show)
      setShowModal(false)

    }, 2000)
  }
  

  return (
    <>
      <Button onClick={handleShow} variant='contained' style={{marginLeft:10}} color='secondary'>
            Duplicate
      </Button>
      <Modal centered show={showmodal} onHide={handleClose}>
        <Modal.Header className='bg-light-success border-0' closeButton>
        </Modal.Header>
        <Modal.Body className='bg-light-success rounded-bottom'><div className='text-center'>
        <span className='h3 text-success'>Question{questionId} successfully dublicated as {response} id.</span></div></Modal.Body>
        
      </Modal>
    </>
  );
}