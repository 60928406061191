/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import {Routes, Route, useNavigate} from 'react-router-dom';
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { useIntl } from 'react-intl';

type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
}

const UserDashboardWidged: React.FC<Props> = ({className, chartColor, chartHeight, strokeColor}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const navigate = useNavigate();

const navigateUserAdmin = () => {
  // 👇️ navigate to /
  navigate('/manage/users');
};
const navigateMemberAdmin = () => {
  // 👇️ navigate to /
  navigate('/manage/user-admin');
};
const navigateMemberGroup = () => {
  // 👇️ navigate to /
  navigate('/manage/GroupManagement');
};
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor, strokeColor)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])
  const intl = useIntl()

  return (
    <div className={`card mt-5 ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 bg-${chartColor}`}>
        <h3 className='card-title fw-bold text-white'> {intl.formatMessage({id: 'DASHBOARD.CATEGORIES'})}</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}

          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div  className='card-body p-0'>
        {/* begin::Chart */}
        <div
          ref={chartRef}
          className={`mixed-widget-2-chart card-rounded-bottom bg-${chartColor}`}
        ></div>
        {/* end::Chart */}
        {/* begin::Stats */}
        <div className='card-p mt-n20 position-relative'>
          {/* begin::Row */}
          <div className='justify-content-evenly row g-0'>
            {/* begin::Col */}
            <button onClick={navigateUserAdmin} className='btn text-start col-4 bg-light-warning px-6 py-8 rounded-2 me-7 mb-7' style={{backgroundColor:"#FFF4DE"}}>
            <span className="  svg-icon svg-icon-3x svg-icon-warning opacity-75  " style={{width: "auto" ,display: "block"}}>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path d="M53.3335 16H42.6668V10.6667C42.6668 9.25222 42.1049 7.89567 41.1047 6.89547C40.1045 5.89528 38.748 5.33337 37.3335 5.33337H26.6668C23.7068 5.33337 21.3335 7.70671 21.3335 10.6667V16H10.6668C7.70683 16 5.3335 18.3734 5.3335 21.3334V50.6667C5.3335 52.0812 5.8954 53.4378 6.89559 54.4379C7.89579 55.4381 9.25234 56 10.6668 56H53.3335C54.748 56 56.1045 55.4381 57.1047 54.4379C58.1049 53.4378 58.6668 52.0812 58.6668 50.6667V21.3334C58.6668 19.9189 58.1049 18.5623 57.1047 17.5621C56.1045 16.5619 54.748 16 53.3335 16V16ZM26.6668 10.6667H37.3335V16H26.6668V10.6667ZM32.0002 24C33.7683 24 35.464 24.7024 36.7142 25.9527C37.9644 27.2029 38.6668 28.8986 38.6668 30.6667C38.6668 32.4348 37.9644 34.1305 36.7142 35.3808C35.464 36.631 33.7683 37.3334 32.0002 37.3334C30.2321 37.3334 28.5364 36.631 27.2861 35.3808C26.0359 34.1305 25.3335 32.4348 25.3335 30.6667C25.3335 28.8986 26.0359 27.2029 27.2861 25.9527C28.5364 24.7024 30.2321 24 32.0002 24V24ZM45.3335 50.6667H18.6668V47.3334C18.6668 43.6534 24.6402 40.6667 32.0002 40.6667C39.3602 40.6667 45.3335 43.6534 45.3335 47.3334V50.6667Z" fill="currentColor" />
                </g>
            </svg>
            </span> 
            <div  className="opacity-75  text-gray-600  display-5"   >
            {/*value of total question*/} 000
            </div>
              <a href='#' className='text-gray-600 fw-semobold fs-6 mt-5'>
              Users
              </a>
            </button>
            <div  className='text-start btn col-4  px-6 py-8 rounded-2 me-7 mb-7 bg-light-info' >
              <span className="  svg-icon svg-icon-3x svg-icon-info opacity-75  my-2">
              <svg width="64" height="64"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M352 128C352 198.7 294.7 256 224 256C153.3 256 96 198.7 96 128C96 57.31 153.3 0 224 0C294.7 0 352 57.31 352 128zM209.1 359.2L176 304H272L238.9 359.2L272.2 483.1L311.7 321.9C388.9 333.9 448 400.7 448 481.3C448 498.2 434.2 512 417.3 512H30.72C13.75 512 0 498.2 0 481.3C0 400.7 59.09 333.9 136.3 321.9L175.8 483.1L209.1 359.2z"/>
              </svg>
                  
              </span>
              <div  className=" opacity-75  text-gray-600  display-5"   >
              {/*value of total knowlodge-card*/} 000
              </div>
              <a href='#' className='text-gray-600 fw-semobold fs-6'>
                Admin Users
              </a>
            </div>
            {/* end::Col */}
            {/* begin::Col */}

            {/* end::Col */}
          </div>
          <div className='justify-content-evenly row g-0'>
          <button style={{height:'80px'}} onClick={navigateMemberGroup} className='bg-light-danger text-start btn col-4  px-6 py-8 rounded-2 me-7 mb-7'>

            <div  className=" opacity-75  text-gray-600  h2">
            {/*value of total memory-card*/} 000 <a href='#' className='text-gray-600 fw-semobold h4'>
                User Group
              </a>
            </div>
              
            </button>
            <button style={{visibility:'hidden'}} onClick={navigateMemberGroup} className='bg-light-danger text-start btn col-4  px-6 py-8 rounded-2 me-7 mb-7'>
            <span className="  svg-icon svg-icon-3x svg-icon-danger opacity-75  my-2" >

            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M58.6668 21.3333V36.8266C56.3202 35.4666 53.6002 34.6666 50.6668 34.6666C41.8402 34.6666 34.6668 41.84 34.6668 50.6666C34.6668 51.5733 34.7735 52.4533 34.9068 53.3333H10.6668C7.7335 53.3333 5.3335 50.96 5.3335 48V16C5.3335 13.04 7.70683 10.6666 10.6668 10.6666H26.6668L32.0002 16H53.3335C56.2668 16 58.6668 18.3733 58.6668 21.3333ZM42.6668 48H48.0002V58.6666H53.3335V48H58.6668L50.6668 40L42.6668 48Z" fill="currentColor"  />
            </svg>
              
            </span>
            <div  className=" opacity-75  text-gray-600  display-5">
            {/*value of total memory-card*/}000
            </div>
              <a href='#' className='text-gray-600 fw-semobold fs-6'>
                Group
              </a>
            </button>
          </div>
          {/* end::Row */}
          {/* begin::Row */}
          
          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  strokeColor: string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const color = getCSSVariableValue('--kt-' + chartColor)

  return {
    series: [
      {
        name: 'Net Profit',
        data: [40, 55, 42, 80, 50, 45, 70],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 100,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      enabled:false,
      style: {
        
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['transparent'],
    markers: {
      colors: [color],
      strokeColors: [strokeColor],
      strokeWidth: 3,
    },
  }
}

export {UserDashboardWidged}
