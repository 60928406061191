import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
  
export default function Users() {
  return (
    
    <Carousel>
    <Carousel.Item >
      <div className='row d-flex'>
        <img
        className="col-6"
        src={"https://media-cdn.tripadvisor.com/media/photo-s/15/a4/9b/77/legacy-hotel-at-img-academy.jpg"}
        alt="Image Two"
        />
        <img
        className="col-6 "
        src={"https://media-cdn.tripadvisor.com/media/photo-s/15/a4/9b/77/legacy-hotel-at-img-academy.jpg"}
        alt="Image Two"
        />
        </div>
      <Carousel.Caption>
        <div className='m-1 card bg-opacity-75 bg-light'>
        <h3 className='text-dark m-1'>Content</h3>
        <span className='text-dark mb-25'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi suscipit voluptates magni provident maiores, sunt blanditiis ipsam harum natus voluptate atque officiis ipsum. Error hic ea debitis sit quaerat libero?</span>
      
        </div>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item >
    <div className='row d-flex'>

            <img
            className="col-6"
            src={"https://media-cdn.tripadvisor.com/media/photo-s/15/a4/9b/77/legacy-hotel-at-img-academy.jpg"}
            alt="Image Two"
            />
            <img
        className="col-6 "
        src={"https://media-cdn.tripadvisor.com/media/photo-s/15/a4/9b/77/legacy-hotel-at-img-academy.jpg"}
        alt="Image Two"
        />
            
            </div>
      <Carousel.Caption>
      <div className='m-1 card bg-opacity-75 bg-light'>
        <h3 className='text-dark m-1'>Content 1</h3>
        <span className='text-dark mb-25'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi suscipit voluptates magni provident maiores, sunt blanditiis ipsam harum natus voluptate atque officiis ipsum. Error hic ea debitis sit quaerat libero?</span>
      
        </div></Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item >
    <div className='row d-flex'>
        <img
        className="col-6"
        src={"https://media-cdn.tripadvisor.com/media/photo-s/15/a4/9b/77/legacy-hotel-at-img-academy.jpg"}
        alt="Image Two"
        />
        <img
        className="col-6 "
        src={"https://media-cdn.tripadvisor.com/media/photo-s/15/a4/9b/77/legacy-hotel-at-img-academy.jpg"}
        alt="Image Two"
        />
       
        </div>
        <Carousel.Caption>
        <div className='m-1 card bg-opacity-75 bg-light'>
        <h3 className='text-dark m-1'>Content 2</h3>
        <span className='text-dark mb-25'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi suscipit voluptates magni provident maiores, sunt blanditiis ipsam harum natus voluptate atque officiis ipsum. Error hic ea debitis sit quaerat libero?</span>
      
        </div></Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  );
}