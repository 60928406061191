import React, { forwardRef, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import '../../style/CircularProgressbar.css'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  Slider,
  SpeedDial,
  Divider,
  SpeedDialAction,
  Tooltip,
  Typography,
} from '@mui/material'
import '../../style/Journey.css'
import JourneyTimig from './JourneyTiming'
import Award from './JourneyAwardModal'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useIntl } from 'react-intl'
import LeaderBoard from './JourneyLeaderBoard'
import JourneyAddEditModal from './JourneyAddEditUserGroupModal'
import Chat from './JourneyChat'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import './CustomJourneyCss.css'
import Loading from '../../../_metronic/layout/components/Loading'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import JourneyTimePreview from './JourneyTimePreview'
import { useWebSocket } from '../ChatService/useChat'
import LoginTypes from '../../ReduxNew/reducers/LoginRedux'
import JourneyAwardPreview from './JourneyAwardPreview'
import AddIcon from '@mui/icons-material/Add'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import BlockIcon from '@mui/icons-material/Block'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SettingsIcon from '@mui/icons-material/Settings'
import { enqueueSnackbar } from 'notistack'
import { TransitionProps } from '@mui/material/transitions'
import CloseIcon from '@mui/icons-material/Close'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LockIcon from "@mui/icons-material/Lock";

type Props = {
  companyId?: any
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Journeys: React.FC<Props> = ({ companyId }) => {
  const [filter, setFilter] = useState({
    Explanation: '',
    nrofUsersS: '',
    nrofUsersE: '',
    EditDateS: '',
    EditDateE: '',
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [index, setIndex] = useState(0)
  const percentage = 66
  const [Journey, setJourney] = useState<any>([])

  const LoginState = useSelector((state: any) => state.Login)
  const dispatch = useDispatch()
  const [user, setUser] = useState<any>()
  const customerProfile = useSelector((state: any) => state.Login.customerProfile)
  useEffect(() => {
    /*   const userDetailString: any = sessionStorage.getItem('customerProfile')
    const userDetail: any = JSON.parse(userDetailString) || null */

    if (customerProfile !== null) {
      setUser(customerProfile?.userId)
    }
  }, [customerProfile])

  const [first, setfirst] = useState(false)
  const [awardDetail, setAwardDetail] = useState<any>({
    journeyAwardMediaText: '',
    journeyAwardMediaType: '',
    journeyAwardMediaUrl: '',
    journeyAwardWinners: -1,
  })

  useEffect(() => {
    async function getJourneyList() {
      setIsLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/Game/getJourneyList`, {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
          },
        })
        .then((response) => {
          setIsLoading(false)
          setJourney(response.data)
        })
        .catch((err) => {
          /*  console.log(err) */
          setIsLoading(false)
        })
    }

    getJourneyList()
  }, [first])

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex)
  }
  const [activeIndex, setActiveIndex] = useState(-1)
  const [selectedJourneyId, setselectedJourneyId] = useState<any>()

  const handleToggle = (index: any, day: any) => {
    setselectedJourneyId(day)
  }
  function valueLabelFormat(value: number) {
    return `${value} `
  }
  const [showModal, setShowModal] = useState(false)
  const [showAward, setShowAward] = useState(false)
  const [showLeader, setLeader] = useState(false)

  const [showChat, setShowChat] = useState(false)

  const [journeyName, setJourneyName] = useState<any>('')
  const [journeyChatIsOn, setJourneyChatIsOn] = useState<any>(false)
  const [journeyUnReadedMessage, setJourneyUnReadedMessage] = useState<any>(false)
  const [journeyChatDisable, setJourneyChatDisable] = useState<any>(false)

  const [JourneyInfo, setJourneyInfo] = useState<any>([])
  const resultsRender: any = []
  const [showTimePreview, setShowTimePreview] = useState<boolean>(false)
  const [journeyTime, setJourneyTime] = useState<any>({ startDate: null, endDate: null })

  const socket: WebSocket | null = useWebSocket() as WebSocket | null

  const [messages, setMessages] = useState<string[]>([])
  const [message, setMessage] = useState<string>('')

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }

  let journey: any = sessionStorage.getItem('journeyId')
  let sessionJourneyId: any = JSON.parse(journey)

  // Mesaj gönderme işlevi
  const handleSendMessage = () => {
    if (socket && socket.readyState === WebSocket.OPEN && message.trim() !== '') {
      socket.send(
        JSON.stringify({
          action: 'sendMessage',
          message: message,
          journeyId: sessionJourneyId,
          token: LoginState.loginData.access_token,
        })
      )
      setMessage('') // Gönderildikten sonra mesaj alanını temizle
    }
  }

  /*  console.log('journey: ', Journey)
  console.log('messages : ', messages) */

  function formatDate(dateString: any) {
    const date = new Date(dateString)
    const options: any = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
    const formattedDate = date.toLocaleDateString('tr-TR', options)
    return dateString ? formattedDate : ''
  }
  const [closeListUnreadMessage, setCloseListUnreadMessage] = useState({
    journeyId: -1,
    closeListUnreadMessage: false,
  })

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data)

        let journey: any = sessionStorage.getItem('journeyId')
        let sessionJourneyId: any = JSON.parse(journey)
        const {
          userId,
          message,
          name,
          surname,
          avatarPath,
          journeyId,
          sendedTime,
          timeStamp,
          groups,
        } = data

        const content = {
          name,
          surname,
          message,
          userId,
          avatarPath,
          journeyId,
          sendedTime,
          timeStamp,
          groups,
        }

        if (message !== 'pong') {
          if (journeyId === sessionJourneyId) {
            setMessages((prevMessages: any) => [...prevMessages, content])
          }

          if (user !== userId) {
            setCloseListUnreadMessage({ journeyId: journeyId, closeListUnreadMessage: true })
          }
        }
        if (data.message === 'kill') {
          // WebSocket'ten gelen mesajları işleme
          dispatch(LoginTypes.logout())
        }
      }
    }
  }, [socket])
  //#region chatModalClose && unreadmessages journeylist
  useEffect(() => {
    if (closeListUnreadMessage.journeyId !== -1 && closeListUnreadMessage.closeListUnreadMessage) {
      const updatedJourneyList = Journey.map((journey: any) => {
        if (closeListUnreadMessage.journeyId === /* sessionJourneyId */ journey.journeyId) {
          return {
            ...journey,
            unReadedMessage: true,
            unreadMessagesCount: journey.unreadMessagesCount + 1,
          }
        }
        return journey
      })
      setJourney(updatedJourneyList)
      setCloseListUnreadMessage({ journeyId: -1, closeListUnreadMessage: false })
    }
  }, [closeListUnreadMessage])
  //#endregion

  const [hoverGifButton, setHoverGifButton] = useState<any>(-1)

  useEffect(() => {
    const updatedJourneyList = Journey.map((journey: any) => {
      if (journey.journeyId === sessionJourneyId) {
        return {
          ...journey,
          chatIsOn: journeyChatIsOn,
        }
      }
      return journey
    })
    setJourney(updatedJourneyList)
  }, [journeyChatIsOn])

  useEffect(() => {
    if (journeyUnReadedMessage) {
      const updatedJourneyList = Journey.map((journey: any) => {
        if (journey.journeyId === sessionJourneyId) {
          return {
            ...journey,
            unReadedMessage: false,
            unreadMessagesCount: 0,
          }
        }
        return journey
      })
      setJourney(updatedJourneyList)
    }
  }, [journeyUnReadedMessage])
  console.log("journey : ", Journey)
  for (var i = 0; i < Journey.length; i += 3) {
    resultsRender.push(
      <Carousel.Item>
        <div className='d-flex justify-content-center '>
          {Journey.slice(i, i + 3).map((J: any, index: any) => (
            <Card
              key={index}
              onClick={() => {
                handleToggle(index, J.journeyId)
                setJourneyInfo(J)
                sessionStorage.setItem('journeyId', J.journeyId)
              }}
              style={{
                width: '390px',
                height: '709px',
                color: 'white',
                marginRight: '50px',
                marginLeft: '50px',
                cursor: 'pointer',
                border:
                  selectedJourneyId === J.journeyId
                    ? J.journeyStatus === 'PAUSED' ?
                      '5px solid #b0b0b0' :
                      J.journeyStatus === 'DELETED' ? "5px solid #ef5350"
                        : '5px solid #50cd89 '
                    : '5px solid #1e1e2d ',
                backgroundColor:
                  J.journeyStatus === 'PAUSED'
                    ? '#1e1e2d'
                    /* : J.journeyStatus === 'DELETED'
                      ? '#ef5350' */
                    : '#1e1e2d',
                borderRadius: '15px',
              }}
            >
              <div
                className='text-center text-uppercase'
                style={{
                  width: '100%',
                  height: '30px',
                  background: 'black',
                  borderRadius: '15px 15px 0px 0px',
                  borderBottom: "2px solid #707070"
                }}
              >
                <span style={{ fontSize: '20px' }}> {J.journeyName}</span>
              </div>

              <Card.Body>
                <div className='row'>
                  <div className='col-1 text-center' style={{ marginBottom: '15px' }}>
                    <div>
                      <Avatar variant='rounded' src='/media/journey/goal_edited.png' sx={{ height: "auto", width: "25px", objectFit: "contain", marginLeft: "-18px" }} />
                    </div>
                    <Slider
                      sx={{
                        '&.Mui-disabled': {
                          color: '#50CD89',
                          height: '95%',
                          marginTop: '10px',
                          width: '25%',
                          borderRadius: ' 15px 15px 15px 15px'
                        },
                        '&.MuiSlider-thumb': {
                          display: 'none',
                        },
                        '& input[type="range"]': {
                          WebkitAppearance: 'slider-vertical',
                        },
                      }}
                      getAriaLabel={valueLabelFormat}
                      orientation='vertical'
                      defaultValue={J.progress}
                      valueLabelDisplay='auto'
                      disabled={true}
                      style={{ marginLeft: '-20px', }}
                    />
                    {/*    <Row > <span id='non-linear-slider'>{valueLabelFormat(J.progress)} %</span></Row>  */}
                  </div>
                  <div className='col-10 hide-scroll' style={{ height: '560px', width: 269, overflow: 'scroll' }}>
                    {' '}
                    {J.activityList.length === 1 ? (
                      <div style={{ width: '60px', height: '420px' }}></div>
                    ) : J.activityList.length === 2 ? (
                      <div style={{ width: '60px', height: '295px' }}></div>
                    ) : J.activityList.length === 3 ? (
                      <div style={{ width: '60px', height: '110px' }}></div>
                    ) : (
                      <></>
                    )}
                    {J.activityList.map((Activity: any) => (
                      <div className='row m-10 hide-scroll' >
                        {
                          J.flowType === 1 &&
                          <div style={{ position: "relative", display: "flex", justifyContent: "end", width: 150 }}>
                            <Box
                              style={{ background: "#EE3468" }}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: -0.9,
                                padding: 0.5,
                                borderRadius: 15,
                                position: "absolute",
                                zIndex: 10
                              }}
                            >
                              <LockIcon fontSize='small' />
                            </Box>
                          </div>
                        }
                        <div className='col-12 d-flex justify-content-center '>
                          {Activity.activityType === 0 ? (
                            <div className='rounded-circle border border-warning border-4 bg-white p-1'>
                              <Avatar
                                variant="circular"
                                src={Activity.activityIcon}
                                sx={{
                                  width: "60px",
                                  height: "60px",
                                  '& img': {
                                    objectFit: 'contain',
                                  }
                                }}
                              >
                                <Avatar
                                  variant="circular"
                                  src={"/media/stock/image.png"}
                                  sx={{
                                    width: "60px",
                                    height: "60px",
                                    '& img': {
                                      objectFit: 'contain',
                                    }
                                  }}
                                />
                              </Avatar>
                              {/*   <img
                                src={Activity.activityIcon}
                                onError={(e: any) => {
                                  e.target.src = toAbsoluteUrl('/media/stock/image.png')
                                }}
                                width={60}
                                height={60}
                                alt='Cinque Terre'
                              /> */}
                            </div>
                          ) : Activity.activityType === 2 ? (
                            <div className='rounded-circle border border-secondary border-4 bg-white p-1'>
                              <Avatar
                                variant="circular"
                                src={Activity.activityIcon}
                                sx={{
                                  width: "60px",
                                  height: "60px",
                                  '& img': {
                                    objectFit: 'contain',
                                  }
                                }}
                              >
                                <Avatar
                                  variant="circular"
                                  src={"/media/stock/image.png"}
                                  sx={{
                                    width: "60px",
                                    height: "60px",
                                    '& img': {
                                      objectFit: 'contain',
                                    }
                                  }}
                                />
                              </Avatar>
                            </div>
                          ) : (
                            <div
                              className={
                                Activity.activityType === 1 && Activity.gameType === 0
                                  ? 'rounded-circle border border-primary border-4 bg-primary'
                                  : Activity.activityType === 1 && Activity.gameType === 1
                                    ? 'rounded-circle border border-danger border-4 bg-danger'
                                    : Activity.activityType === 1 && Activity.gameType === 2
                                      ? 'rounded-circle border border-success border-4 bg-success'
                                      : 'rounded-circle border border-info border-4 bg-info'
                              }
                            >
                              <Avatar
                                variant="circular"
                                src={Activity.activityIcon}
                                sx={{
                                  width: "60px",
                                  height: "60px",
                                  '& img': {
                                    objectFit: 'contain',
                                  }
                                }}
                              >
                                <Avatar
                                  variant="circular"
                                  src={"/media/stock/image.png"}
                                  sx={{
                                    width: "60px",
                                    height: "60px",
                                    '& img': {
                                      objectFit: 'contain',
                                    }
                                  }}
                                />
                              </Avatar>
                            </div>
                          )}
                        </div>

                        <div className='col-12 text-center d-flex justify-content-center mt-2'>
                          <span style={{ fontSize: '16px' }}>{Activity.activityTitle}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='col-1 text-center' style={{ marginBottom: '15px' }}>
                    <div>
                      <Avatar variant='rounded' src='/media/journey/mortarboard_edited.png' sx={{ height: "auto", width: "25px", objectFit: "contain", marginLeft: "3px" }} />
                    </div>
                    <Slider
                      sx={{
                        '&.Mui-disabled': {
                          color: '#ED1C77',
                          height: '95%',
                          marginTop: '10px',
                          width: '25%',
                          borderRadius: ' 15px 15px 15px 15px'
                        },
                        '&.MuiSlider-thumb': {
                          display: 'none',
                        },
                        '& input[type="range"]': {
                          WebkitAppearance: 'slider-vertical',
                        },
                      }}
                      orientation='vertical'
                      defaultValue={J.successRate}
                      valueLabelDisplay='auto'
                      disabled={true}
                    />
                  </div>
                </div>
              </Card.Body>

              <Card.Header
                className='p-0 m-0'
                style={{
                  minHeight: '56px',
                  border: 0,
                  background: 'black',
                  borderRadius: '0px 0px 15px 15px',
                  borderTop: "2px solid #707070"
                }}
              >
                <div className=' w-100  row d-flex justify-content-around'>
                  {J.journeyStatus === 'DELETED' || J.journeyStatus === 'PAUSED' ?
                    <div
                      className='col-2 p-0 m-0 text-center'>
                      <Tooltip
                        arrow
                        title={"Paused"
                        }
                        placement='top'
                      >
                        <IconButton>
                          <Avatar
                            variant='rounded'
                            src={
                              J.journeyStatus === 'PAUSED'
                                ? '/media/journey/Pause.png'
                                : ''
                            }
                            imgProps={{ style: { objectFit: "contain" } }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    : (
                      <div
                        className='col-2 p-0 m-0 text-center'
                      /*     onClick={() => {
                      setShowModal(true)
                      setJourneyName(J.journeyName)
                    }} */
                      >
                        <Tooltip
                          arrow
                          title={
                            J.journeyStatus === 'READY'
                              ? `${formatDate(J.startDate)}`
                              : `${formatDate(J.endDate)}`
                          }
                          placement='top'
                        >
                          <IconButton
                            onClick={() => (
                              setShowTimePreview(true),
                              setJourneyTime({ startDate: J.startDate, endDate: J.endDate })
                            )}
                            onMouseEnter={() => setHoverGifButton(J.journeyId)}
                            onMouseLeave={() => setHoverGifButton(-1)}
                            style={{
                              mixBlendMode: J.chatIsOn ? 'initial' : 'luminosity',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Avatar
                              variant='rounded'
                              src={
                                J.journeyStatus === 'READY'
                                  ? /* hoverGifButton === J.journeyId */true ? '/media/journey/hourglass.gif' : '/media/journey/hourglass1.png'
                                  : J.journeyStatus === 'STARTED'
                                    ? /* hoverGifButton === J.journeyId */ true ? '/media/journey/live.gif' : '/media/journey/live1.png'
                                    : (J.journeyStatus === 'FINISHED' || J.journeyStatus === 'STOPPED')
                                      ? /* hoverGifButton === J.journeyId */true ? '/media/journey/finish-line.gif' : '/media/journey/finish-line1.png'
                                      /*  : J.journeyStatus === 'STOPPED' ? hoverGifButton === J.journeyId ? '/media/journey/stop.gif' : '/media/journey/stop1.png' */
                                      : J.journeyStatus === 'DRAFT'
                                        ? '/media/journey/Draft.png'
                                        : ''
                              }
                            />
                            {/*  <span style={{color: '#A1A5B7', fontSize: '14px'}}>
                        {formatDate(J.startDate)}
                      </span> */}
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  <div className='col-2 p-0 m-0 text-center'>
                    <Tooltip arrow title={'Leader Board'} placement='top'>
                      <IconButton
                        onClick={() => {
                          setLeader(true)
                          setJourneyName(J.journeyName)
                        }}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          mixBlendMode:
                            J.journeyStatus === 'STARTED' ||
                              J.journeyStatus === 'FINISHED' ||
                              J.journeyStatus === 'READY' ||
                              J.journeyStatus === 'STOPPED'
                              ? 'initial'
                              : 'luminosity',
                        }}
                        disabled={
                          J.journeyStatus === 'STARTED' ||
                            J.journeyStatus === 'FINISHED' ||
                            J.journeyStatus === 'READY' ||
                            J.journeyStatus === 'STOPPED'
                            ? false
                            : true
                        }
                        onMouseEnter={() => setHoverGifButton({ j: J.journeyId, i: 0 })}
                        onMouseLeave={() => setHoverGifButton(-1)}
                      >
                        <Avatar variant='rounded' style={{ width: "50px", height: "40px", objectFit: "contain" }} src={/* hoverGifButton.j === J.journeyId && hoverGifButton.i === 0  */true ? "/media/journey/champion.gif" : '/media/journey/champion1.png'} />

                      </IconButton>
                    </Tooltip>
                    {/* <div style={{color: '#A1A5B7', fontSize: '14px'}}>Success</div> */}
                  </div>
                  {
                    //award
                  }
                  <div className='col-2 p-0 m-0 text-center '>
                    <Tooltip arrow title={'Award'} placement='top'>
                      <IconButton
                        disabled={J.journeyAwardWinners === 0}
                        onClick={() => {
                          setShowAward(true)
                          setJourneyName(J.journeyName)
                          setAwardDetail({
                            journeyAwardMediaText: J.journeyAwardMediaText,
                            journeyAwardMediaType: J.journeyAwardMediaType,
                            journeyAwardMediaUrl: J.journeyAwardMediaUrl,
                            journeyAwardWinners: J.journeyAwardWinners,
                          })
                        }}
                        style={{
                          mixBlendMode: J.journeyAwardWinners !== 0 ? 'initial' : 'luminosity',
                        }}
                        onMouseEnter={() => setHoverGifButton({ j: J.journeyId, i: 1 })}
                        onMouseLeave={() => setHoverGifButton(-1)}
                      >
                        <Avatar variant='rounded' style={{ width: "40px", height: "40px", }} imgProps={{ style: { objectFit: "contain" } }} src={/* hoverGifButton.j === J.journeyId && hoverGifButton.i === 1  */true ? "/media/journey/gift.gif" : '/media/journey/gift1.png'} />

                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className='col-2 p-0 m-0 text-center d-flex justify-content-center'>
                    <div className='text-center'>
                      {J.unReadedMessage && (
                        <div
                          style={{
                            height: '17px',
                            minWidth: '17px',
                            borderRadius: '15px',
                            background: 'red',
                            fontSize: '11px',
                            position: 'absolute',
                            zIndex: 2,
                            marginLeft: '52px',
                          }}
                        >
                          {J.unreadMessagesCount}
                        </div>
                      )}
                      <Tooltip arrow title={'Chat'} placement='top'>
                        <IconButton
                          onClick={() => {
                            setShowChat(true)
                            setJourneyName(J.journeyName)
                            setJourneyChatIsOn(J.chatIsOn)
                            setJourneyChatDisable(J.journeyStatus === 'STOPPED' ? false : true)
                          }}
                          style={{
                            mixBlendMode: J.chatIsOn ? 'initial' : 'luminosity',
                          }}
                          disabled={
                            J.journeyStatus === 'PAUSED' || J.journeyStatus === 'DELETED'
                              ? true
                              : false
                          }
                          onMouseEnter={() => setHoverGifButton({ j: J.journeyId, i: 2 })}
                          onMouseLeave={() => setHoverGifButton(-1)}
                        >
                          <Avatar variant='rounded' src={/* hoverGifButton.j === J.journeyId && hoverGifButton.i === 2 */ true ? "/media/journey/chat.gif" : '/media/journey/chat1.png'} imgProps={{ style: { objectFit: "contain" } }} style={{ width: "56px", height: "40px", }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </Card.Header>
            </Card>
          ))}
        </div>
      </Carousel.Item>
    )
  }
  const navigate = useNavigate()

  function handleEdit() {
    navigate(`/journey/learning-journey/create/?id=${selectedJourneyId}`)
  }
  function handleDublicate() {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Game/duplicateJourney/${selectedJourneyId}`, '', {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response: any) => {
        setfirst(!first)
      })
  }
  function handleCreate() {
    navigate(`/journey/learning-journey/create`)
  }
  const [successModalShow, setSuccessModalShow] = useState(false)
  const [result, setResult] = useState(false)

  async function handleChangeStatus() {
    setSpeedDialOpen(false)
    setLoadingStatus(true)
    //hatalı silme
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Game/changeJourneyStatus`,
        {
          journeyId: selectedJourneyId,
          statusId: journeyStatus,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
          },
        }
      )
      .then(() => {
        setLoadingStatus(false)
        setfirst(!first)
        journeyStatus === 1 &&
          enqueueSnackbar(' The status of 1 record has been changed to OPEN successfully', {
            variant: 'success',
          })
        journeyStatus === 0 &&
          enqueueSnackbar(' 1 record has been successfully PAUSED', { variant: 'success' })
        journeyStatus === 4 &&
          enqueueSnackbar(' 1 record has been successfully DELETED', { variant: 'success' })
      })
      .catch((err: any) => {
        err.response?.data?.detail.errorMessage
          ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
            variant: 'error',
          })
          : enqueueSnackbar(
            'The process could not be completed. Please try again a few minutes later.',
            { variant: 'error' }
          )
        setLoadingStatus(false)
      })
    setJourneyStatus(-1)
    setOpenWarnModal(false)
  }
  const handleSuccessModalClose = () => {
    setSuccessModalShow(false)
  }
  const intl = useIntl()

  const [addButtonHover, setAddButtonHover] = useState(false)
  const [editButtonHover, setEditButtonHover] = useState(false)
  const [duplicateButtonHover, setDuplicateButtonHover] = useState(false)
  const [makeJourneyButtonHover, setMakeJourneyButtonHover] = useState(false)

  //speed dial start
  const [speedDialOpen, setSpeedDialOpen] = useState(false)

  const handleCloseSpeedDial = () => {
    setSpeedDialOpen(false)
  }
  const handleOpenSpeedDial = () => {
    setSpeedDialOpen(true)
  }
  //question Status Change
  const [journeyStatus, setJourneyStatus] = useState(-1)
  const [loadingStatus, setLoadingStatus] = useState(false)

  const [journeyStatusDelete, setJourneyStatusDelete] = useState(false)
  const [journeyStatusPaused, setJourneyStatusPaused] = useState(false)
  const [journeyStatusReady, setJourneyStatusReady] = useState(false)
  const actions = [
    journeyStatusReady && { icon: <CheckIcon style={{ color: 'black' }} />, name: 'Ready', value: 1 },
    journeyStatusPaused && { icon: <BlockIcon style={{ color: 'black' }} />, name: 'Pause', value: 0 },
    journeyStatusDelete && { icon: <DeleteIcon style={{ color: 'black' }} />, name: 'Delete', value: 4 },
  ].filter(Boolean);
  useEffect(() => {
    setJourneyStatusDelete(false);
    setJourneyStatusPaused(false)
    setJourneyStatusReady(false)
    const selectedJourney = Journey.find(journey => journey.journeyId === selectedJourneyId);
    const journeyDelete = selectedJourney ? calculateJourneyStatusDelete(selectedJourney) : false;
    const journeyPaused = selectedJourney ? calculateJourneyStatusPaused(selectedJourney) : false;
    const journeyReady = selectedJourney ? calculateJourneyStatusReady(selectedJourney) : false;
    setJourneyStatusDelete(journeyDelete);
    setJourneyStatusPaused(journeyPaused)
    setJourneyStatusReady(journeyReady)
  }, [selectedJourneyId, Journey]);

  function calculateJourneyStatusDelete(journey) {
    const invalidStatusesForDelete = [3, 6, 5, 0];
    return invalidStatusesForDelete.includes(journey.statusId);
  }
  function calculateJourneyStatusPaused(journey) {
    const invalidStatusesForDelete = [1, 2];
    return invalidStatusesForDelete.includes(journey.statusId);
  }
  function calculateJourneyStatusReady(journey) {
    const invalidStatusesForDelete = [0];
    return invalidStatusesForDelete.includes(journey.statusId);
  }

  //speed dial end


  //warn modal

  const [warnModalContent, setWarnModalContent] = useState<any>('')

  useEffect(() => {
    if (journeyStatus === -1) {
      return
    }
    if (journeyStatus === 1) {
      //open
      handleChangeStatus()
    }
    if (journeyStatus === 0) {
      //paused
      setWarnModalContent(
        <Typography color='white'>
          This operation will PAUSE selected Journey. Paused journeys can be seen but can not be
          played. You can change the status of the journey at any time. For extra security, you must
          confirm the process. Proceed?
        </Typography>
      )

      handleClickWarnModalOpen()
    }
    if (journeyStatus === 4) {
      //delete
      setWarnModalContent(
        <Typography color='white'>
          This operation will DELETE the selected records.
          Deletion process can NOT be undone.
          For extra security, you must confirm the process.
          Proceed?
        </Typography>
      )

      handleClickWarnModalOpen()
    }
  }, [journeyStatus])

  const [openWarnModal, setOpenWarnModal] = useState(false)

  const handleClickWarnModalOpen = () => {
    setOpenWarnModal(true)
  }

  const handleClickWarnModalClose = () => {
    setOpenWarnModal(false)
    setTimeout(() => {
      setJourneyStatus(-1)
    }, 1000)
  }

  //warn modal end

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div style={{ marginTop: '-110px' }}>
          {
            //success modal
          }
          <Modal
            show={successModalShow}
            onHide={handleSuccessModalClose}
            aria-labelledby='contained-modal-title-vcenter '
            centered
            size='lg'
          >
            <Modal.Header
              closeButton
              className={result ? 'bg-light-success border-0' : 'bg-light-danger border-0'}
            ></Modal.Header>
            <Modal.Body
              className={result ? 'bg-light-success text-success' : 'bg-light-danger text-danger'}
            >
              {' '}
              <div className='row text-center'>
                <div className='col-1'>
                  <span
                    className={
                      result
                        ? 'svg-icon svg-icon-4hx svg-icon-success me-3 '
                        : 'svg-icon svg-icon-4hx svg-icon-danger me-3 '
                    }
                  >
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='mh-50px'
                    >
                      <path
                        opacity='0.3'
                        d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                        fill='currentColor'
                      ></path>
                      <path
                        d='M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z'
                        fill='currentColor'
                      ></path>
                    </svg>
                  </span>
                </div>

                <div className='text-center col-10'>
                  {result
                    ? (intl.formatMessage({ id: 'JOURNEY.SUCCESS' }), selectedJourneyId)
                    : (intl.formatMessage({ id: 'JOURNEY.FAILED' }), selectedJourneyId)}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer
              className={result ? 'bg-light-success  border-0 ' : 'bg-light-danger  border-0'}
            ></Modal.Footer>
          </Modal>
          <div className='d-flex flex-row-reverse m-5 '>
            {
              //delete button
            }
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              alignContent='center'
              sx={{ height: 50, marginTop: '15px', marginRight: 10 }}
            >
              {
                //add button
              }
              <Tooltip
                arrow
                title='Add New'
                placement='top'
                onMouseEnter={() => {
                  setAddButtonHover(true)
                }}
                onMouseLeave={() => {
                  setAddButtonHover(false)
                }}
                style={{ background: addButtonHover ? '#D5D5D5 ' : 'white', marginTop: '20px' }}
              >
                <IconButton
                  aria-label='delete'
                  onClick={() => handleCreate()}
                  size='small'
                  sx={{ background: 'white', marginRight: '7px' }}
                >
                  <AddIcon sx={{ fontSize: '30px', color: addButtonHover ? 'black' : ' ' }} />
                </IconButton>
              </Tooltip>
              {
                //edit button
              }
              {selectedJourneyId && (
                <Tooltip
                  arrow
                  title='Edit'
                  placement='top'
                  onMouseEnter={() => {
                    setEditButtonHover(true)
                  }}
                  onMouseLeave={() => {
                    setEditButtonHover(false)
                  }}
                  style={{ background: editButtonHover ? '#D5D5D5 ' : 'white', marginTop: '20px' }}
                >
                  <IconButton
                    aria-label='delete'
                    onClick={() => {
                      handleEdit()
                    }}
                    size='small'
                    sx={{ background: 'white' /* marginRight: '7px' */ }}
                  >
                    <EditIcon sx={{ fontSize: '30px', color: editButtonHover ? 'black' : '' }} />
                  </IconButton>
                </Tooltip>
              )}

              {selectedJourneyId && (
                <Tooltip arrow title='Change Status' placement='top'>
                  <SpeedDial
                    ariaLabel='SpeedDial openIcon example'
                    style={{ transform: 'scale(0.73)', marginTop: (journeyStatusPaused && journeyStatusReady) ? "112px" : journeyStatusPaused ? "71px" : journeyStatusReady ? "112px" : journeyStatusDelete ? "71px" : '30px', zIndex: 2, display: journeyStatusPaused ? "" : journeyStatusReady ? "" : journeyStatusDelete ? "" : 'none' }}
                    icon={
                      speedDialOpen ? (
                        <ExpandMoreIcon fontSize='large' />
                      ) : (
                        <SettingsIcon fontSize='large' />
                      )
                    }
                    openIcon={<DeleteIcon fontSize='large' />}
                    onOpen={handleOpenSpeedDial}
                    onClose={handleCloseSpeedDial}
                    FabProps={{ color: 'inherit' }}
                    open={speedDialOpen}
                    direction='down'
                  >
                    {actions.map((action) => (
                      <SpeedDialAction
                        /*  style={{transform: 'scale(0.70)'}} */
                        delay={1000}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => setJourneyStatus(action.value)}
                      />
                    ))}
                  </SpeedDial>
                </Tooltip>
              )}

              {
                //dublicate button
              }
              {selectedJourneyId && (
                <Tooltip
                  arrow
                  title='Duplicate'
                  placement='top'
                  onMouseEnter={() => {
                    setDuplicateButtonHover(true)
                  }}
                  onMouseLeave={() => {
                    setDuplicateButtonHover(false)
                  }}
                  style={{
                    background: duplicateButtonHover ? '#D5D5D5 ' : 'white',
                    marginTop: '20px',
                  }}
                >
                  <IconButton
                    aria-label='Duplicate'
                    onClick={() => {
                      handleDublicate()
                    }}
                    size='small'
                    sx={{
                      background: 'white',
                      color: duplicateButtonHover ? 'black' : '',
                      marginRight: '7px',
                      marginLeft: '7px',
                    }}
                  >
                    <ContentCopyOutlinedIcon sx={{ fontSize: '30px' }} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>

            {
              //filter button
            }

          </div>
          {resultsRender.length > 0 && (
            <Carousel
              className='w-100'
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
              indicators={false}
              interval={null}
              activeIndex={index}
              onSelect={handleSelect}
            >
              {resultsRender}
            </Carousel>
          )}
          {
            resultsRender.length < 1 &&
            <Box display={"flex"} justifyContent={"center"} textAlign={"center"} alignItems={"start"} width={"100%"} height={"480px"} sx={{ background: "white", borderRadius: "10px" }}>
              <Typography marginTop={5} fontSize={16} fontWeight={"bold"} color={"#8E8E8E"} >Your organisation doesn’t have a Journey, yet.<br />
                Please click “+” button above and build a Journey.</Typography>
            </Box>
          }
          {
            //#region journey time preview
            <JourneyTimePreview
              setShowTimePreview={setShowTimePreview}
              showTimePreview={showTimePreview}
              journeyTime={journeyTime}
            />
            //#endregion
          }
          {/* <JourneyTimig setShow={setShowModal} isUserLoading={showModal} /> */}
          <LeaderBoard show={showLeader} setShow={setLeader} journeyId={sessionJourneyId} />
          <JourneyAwardPreview
            show={showAward}
            setShow={setShowAward}
            journeyId={sessionJourneyId}
            journeyName={journeyName}
            awardDetail={awardDetail}
          />
          {/*    <JourneyAddEditModal
            isUserLoading={showUser}
            setShow={setUser}
            companyId={user}
            journeyId={chatId}
            journeyName={journeyName}
          /> */}
          <Chat
            journeyName={journeyName}
            show={showChat}
            setShow={setShowChat}
            journeyId={sessionJourneyId}
            handleMessageChange={handleMessageChange}
            handleSendMessage={handleSendMessage}
            setMessages={setMessages}
            messages={messages}
            message={message}
            journeyChatIsOn={journeyChatIsOn}
            setJourneyChatIsOn={setJourneyChatIsOn}
            journeyUnReadedMessage={journeyUnReadedMessage}
            setJourneyUnReadedMessage={setJourneyUnReadedMessage}
            journeyChatDisable={journeyChatDisable}
          />
          {
            //warn modal
          }
          <Dialog
            open={openWarnModal}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
              style: {
                backgroundColor: journeyStatus === 1 ? '#0288D1' : '#ED6C02',
              },
            }}
            /*     onClose={handleClickClose} */
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle>
              <Typography color='white' variant='h5'>
                {journeyStatus === 1 ? (
                  <InfoOutlinedIcon fontSize='large' />
                ) : (
                  <WarningAmberIcon fontSize='large' />
                )}{' '}
                {journeyStatus === 1 ? 'Info' : 'Warning'}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description'>
                {warnModalContent}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleChangeStatus()
                }}
                style={{ color: 'white' }}
                disabled={loadingStatus}
              >
                Yes
              </Button>
              <Button onClick={handleClickWarnModalClose} style={{ color: 'white' }}>
                <CloseIcon />
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  )
}

export default Journeys
