import { MenuItem } from '../../_metronic/layout/components/header/MenuItem'
import { MenuInnerWithSub } from '../../_metronic/layout/components/header/MenuInnerWithSub'
import { MegaMenu } from '../../_metronic/layout/components/header/MegaMenu'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

export function MenuInner() {
  const intl = useIntl()
  const LoginState = useSelector((state: any) => state.Login)
  const customerProfile = useSelector((state: any) => state.Login.customerProfile)
  const [userRole, setUserRole] = useState<any>([])
  /*   const [isSystemAdmin, setIsSystemAdmin] = useState<any>(false)
   */
  useEffect(() => {

    let role = customerProfile?.role

    if (role) {
      setUserRole(role)
    }
    /*  if(isSystemAdmin){
       setIsSystemAdmin(true)
     }else{
       setIsSystemAdmin(false)
     } */
  }, [LoginState, customerProfile])
  let roleSystem = [0]
  let roleId = [0, 1, 3, 4, 5]
  let roleIsValid = userRole?.some((role: any) => roleId.includes(role.roleId))
  let roleSystemIsValid = userRole?.some((role: any) => roleSystem.includes(role.roleId))
  let roleReport = [0, 1, 3]
  let roleIsValidReport = userRole?.some((role: any) => roleReport.includes(role.roleId))
  const location = useLocation();
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      {/*    <MenuItem title={intl.formatMessage({id: 'MENU.Dashboard'})} to='/CustomerDashboard' /> */}

      <MenuItem to='/journey' title={intl.formatMessage({ id: 'MENU.journey' })} />
      {roleIsValidReport && (
        <MenuInnerWithSub
          title={intl.formatMessage({ id: 'MENU.Reports' })}
          to='/reports'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          <div className='ms-3'>
            {/* <span className='fs-2 text-muted'>User</span> */}
            <div className='ms-4'>
              <MenuItem
                to='reports/UserReport'
                title={intl.formatMessage({ id: 'MENU.UserDetail' })}
                hasBullet={true}
              />
              <MenuItem
                to='reports/UserGroupReport'
                title={intl.formatMessage({ id: 'MENU.UserGroup' })}
                hasBullet={true}
              />
              <MenuItem
                to='reports/UserActivityReport'
                title={intl.formatMessage({ id: 'MENU.userActivities' })}
                hasBullet={true}
              />
               <MenuItem
                to='reports/QuestionReport'
                title={intl.formatMessage({ id: 'MENU.Questions' })}
                hasBullet={true}
              />
              <MenuItem
                to='reports/CategoriesReport'
                title={intl.formatMessage({ id: 'MENU.Categories' })}
                hasBullet={true}
              />
              {/* <MenuItem to='/prepare/games-dashboard' title='Games' hasBullet={true} /> */}
              <MenuItem
                to='reports/activitiesReport'
                title={"Activity"}
                hasBullet={true}
              />
              <MenuItem
                to='reports/journeysReport'
                title={intl.formatMessage({ id: 'MENU.journey' })}
                hasBullet={true}
              />
            </div>
          </div>
        </MenuInnerWithSub>
      )}

      <MenuInnerWithSub
        title={intl.formatMessage({ id: 'USER.Manage' })}
        to='/manage'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <div className='ms-3'>
          {/*   <span className='fs-2 text-muted'>Manage Users</span> */}
          <div className='ms-4'>
            {roleIsValid && (
              /*  <div onClick={() => location.pathname === "/manage/users" && sessionStorage.setItem("navigate","/manage/users")}>
                 </div> */
              <MenuItem
                to='manage/users'
                title={intl.formatMessage({ id: 'MENU.User' })}
                hasBullet={true}
              />

            )}
            {
              roleIsValid &&
              <MenuItem
                to='manage/GroupManagement'
                title={intl.formatMessage({ id: 'MENU.UserGroup' })}
                hasBullet={true}
              />
            }

          </div>
          {/*  <span className='fs-2 text-muted'>Manage Content</span> */}
          <div className='ms-4'>
          <MenuItem
              to='manage/QuestionPage'
              title={intl.formatMessage({ id: 'MENU.Questions' })}
              hasBullet={true}
            />
            <MenuItem
              to='category/Categories'
              title={intl.formatMessage({ id: 'MENU.Categories' })}
              hasBullet={true}
            />
            {/* <MenuItem to='/prepare/games-dashboard' title='Games' hasBullet={true} /> */}
            <MenuItem
              to='manage/activities'
              title={intl.formatMessage({ id: 'MENU.Activities' })}
              hasBullet={true}
            />
            <MenuItem to='/journey' title={"Journeys"}  hasBullet={true} />
          </div>
        </div>
      </MenuInnerWithSub>
      {
        roleSystemIsValid &&
        <MenuItem to='/administration/customers' title={"Customer List"} />
      }
    </>
  )
}
