import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import QuestionsList from './QuestionsList'
import {Typography} from '@mui/material'
// import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const QuestionsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Manage',
    // path: '/apps/user-management/users',
    path: '/manage/QuestionPage',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function QuestionsPage() {
  return (
    <div>
      <>
        {/*     <PageTitle breadcrumbs={QuestionsBreadcrumbs}>Question List</PageTitle> */}
        <div
          style={{
            display: 'flex',
            height: 40,
            width: '80%',
            marginTop: '-20px',
            cursor: 'default',
            pointerEvents: 'none',
            userSelect: 'none',
          }}
        >
          <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
            Manage - Question List
          </Typography>
        </div>
        <QuestionsList />
      </>
    </div>
  )
}
export default QuestionsPage
