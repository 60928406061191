import React, { FC, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Axios from 'axios'
import {Button, TextField, MenuItem, Typography, CircularProgress, DialogContent, DialogActions, DialogContentText, DialogTitle, Dialog } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Modal from 'react-bootstrap/Modal'
import { SelectCategory } from './AddQuestionComponenets/SelectCategory'
import { Time } from './AddQuestionComponenets/Time'
import SelectContentNew from './AddQuestionComponenets/SelectContentNew'
import TinyMCEWidget from '../../widgets/CreateQuestionWidget/TinyMCEWidget'
import QuestionAnswerWidgetEdit from '../../widgets/CreateQuestionWidget/QuestionAnswerForEdit'
import { Dublicate } from './AddQuestionComponenets/DublicateQuestion'
import FAQWidget from '../../widgets/shared/FAQWidget'
import Loading from '../../../_metronic/layout/components/Loading'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CloseIcon from '@mui/icons-material/Close'

type Props = {
  company: any
  setfirst: any
  first: any
  questionId: any
  setShow: (isUserLoading: boolean) => void
  show: boolean
  setDublicateId: any
  setNew: any
}

const EditQuestionModal: FC<Props> = ({
  company,
  setfirst,
  first,
  questionId,
  setShow,
  show,
  setDublicateId,
  setNew,
}) => {
  const [questionEdit, setQuestionEdit] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [accordionOpen, setAccordionOpen] = useState(false)
  const [showWarnModal, setShowWarnModal] = useState(false)

  const [time, setTime] = useState<string>('Time')
  const [mediaType, setMediaType] = useState<number>(0)
  const [mediaUrl, setMediaUrl] = useState<string>('')
  const [file, setFile] = useState<any>('')
  const [answerList, setAnswerList] = useState<any[]>([])
  const [selection, setSelection] = useState<number>(1)
  const [categoryId, setCategoryId] = useState<string>('')
  const [question, setQuestion] = useState<string>('')
  const [plainText, setPlainText] = useState<string>('')
  const [status, setStatus] = useState<number>(1)
  const [button, setButton] = useState(true)

  const LoginState = useSelector((state: any) => state.Login)

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false)
  const initialDataRef = useRef<any>(null)

  useEffect(() => {
    if (questionId && show) {
      fetchQuestionData()
    }
  }, [questionId, show])

  useEffect(() => {
    updateQuestionEditState()
  }, [questionEdit, show])

  useEffect(() => {
    validateForm()
  }, [mediaUrl, plainText, file, answerList, categoryId, selection])

  const fetchQuestionData = async () => {
    setIsLoading(true)
    try {
      const response = await Axios.get(`${process.env.REACT_APP_API_URL}/content/getQuestionById/${questionId}`, {
        headers: {
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      setQuestionEdit(response.data)
      setStatus(response.data.statusId)
      initialDataRef.current = JSON.parse(JSON.stringify(response.data)) // Derin kopya oluştur
      setHasUnsavedChanges(false) // Reset unsaved changes flag
    } catch (err) {
      console.error('Error fetching question data:', err)
    }
    setIsLoading(false)
  }

  const areAnswerListsEqual = (list1, list2) => {
    if (!list1 || !list2 || list1.length !== list2.length) return false;
    
    return list1.every((answer1, index) => {
      const answer2 = list2[index];
      return (
        answer1.questionAnswerId === answer2.questionAnswerId &&
        answer1.description === answer2.description &&
        answer1.isCorrect === answer2.isCorrect
      );
    });
  };
  
  useEffect(() => {
    if (initialDataRef.current && initialDataRef.current.questionAnswers) {
      const hasChanges = 
        time !== (initialDataRef.current.isAuto ? 'Auto' : initialDataRef.current.duration) ||
        mediaType !== (initialDataRef.current.mediaType || 0) ||
        mediaUrl !== (initialDataRef.current.mediaUrl || '') ||
        !areAnswerListsEqual(answerList, initialDataRef.current.questionAnswers) ||
        categoryId !== initialDataRef.current.categoryId ||
        selection !== initialDataRef.current.expectedAnswerCount ||
        question !== initialDataRef.current.htmlDescription ||
        status !== initialDataRef.current.statusId;
      
      setHasUnsavedChanges(hasChanges);
    }
  }, [time, mediaType, mediaUrl, answerList, categoryId, selection, question, status]);

  const updateQuestionEditState = () => {
    setTime(questionEdit.isAuto ? 'Auto' : questionEdit.duration)
    setMediaType(questionEdit.mediaType || 0)
    setMediaUrl(questionEdit.mediaUrl || '')
    setAnswerList(questionEdit.questionAnswers || [])
    setCategoryId(questionEdit.categoryId)
    setSelection(questionEdit.expectedAnswerCount)
    setQuestion(questionEdit.htmlDescription)
  }

  const validateForm = () => {
    const isContentValid = mediaUrl?.length > 1 || plainText?.length > 1 || (file && file.size > 2)
    const isAnswersValid = answerList.length > 1 &&
      answerList.filter(answer => answer.isCorrect).length >= 1 &&
      answerList.length > answerList.filter(answer => answer.isCorrect).length &&
      !answerList.some(answer => answer.description === '')

    setButton(!(isContentValid && categoryId && isAnswersValid && plainText !== "undefined"))
  }

  const handleClose = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedChangesDialog(true)
    } else {
      closeModal()
    }
  }

  const closeModal = () => {
    setShow(false)
    setStatus(0)
    setMediaType(0)
    setMediaUrl('')
    setHasUnsavedChanges(false)
    initialDataRef.current = null
  }

  const handleSave = async () => {
    setSaveLoading(true)
    try {
      const bodyFormData = new FormData()
      appendFormData(bodyFormData)

      await Axios.post(`${process.env.REACT_APP_API_URL}/content/editQuestion`, bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })

      await updateAnswers()

      setHasUnsavedChanges(false)
      initialDataRef.current = null // Clear initial data after successful save
      setShowWarnModal(true)
      setTimeout(() => {
        setfirst(!first) 
        setShowWarnModal(false)
        closeModal()
      }, 2000)
    } catch (err) {
      console.error('Error saving question:', err)
    }
    setSaveLoading(false)
  }

  const appendFormData = (bodyFormData: FormData) => {
    bodyFormData.append('expectedAnswerCount', selection.toString())
    bodyFormData.append('questionId', questionId)
    bodyFormData.append('correctAnswerCount', selection.toString())
    bodyFormData.append('mediaUrl', mediaUrl)
    bodyFormData.append('statusId', status.toString())
    bodyFormData.append('htmlDescription', question)
    bodyFormData.append('answerCount', answerList.length.toString())
    bodyFormData.append('answerTypeId', '1')
    bodyFormData.append('duration', time === 'Auto' ? '60' : time)
    bodyFormData.append('isAuto', (time === 'Auto').toString())
    bodyFormData.append('categoryId', categoryId)
    bodyFormData.append('file', file)
    bodyFormData.append('description', plainText)
    bodyFormData.append('mediaType', mediaType.toString())
  }

  const updateAnswers = async () => {
    const answerData = answerList.map(answer => ({
      questionAnswerId: answer.questionAnswerId,
      description: answer.description,
      isCorrect: answer.isCorrect,
    }))

    await Axios.post(`${process.env.REACT_APP_API_URL}/content/editQuestionAnswer?questionId=${questionId}`, answerData, {
      headers: {
        Authorization: `Bearer ${LoginState.loginData.access_token}`,
      },
    })
  }

  const getStatusColor = () => {
    const colors = {
      0: '#9c27b0',
      1: '#53b658',
      2: '#d32f2f',
    }
    return colors[status] || 'default'
  }

  return (
    <>
      <Modal show={showWarnModal} onHide={() => setShowWarnModal(false)} centered>
        <Modal.Header className='bg-light-success border-0' closeButton />
        <Modal.Body className='bg-light-success rounded-bottom'>
          <div className='text-center'>
            <span className='h3 text-success'>Question {questionId} successfully edited.</span>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
        <Modal.Header className='p-2' closeButton>
          <div className='row w-100'>
            <div className='d-flex justify-content-between align-items-center'>
              <span className='h1'>Edit Question</span>
              <span style={{ marginRight: 10, fontSize: 18, fontWeight: 'bold' }}>
                {questionEdit.questionId}
              </span>
              <TextField
                select
                value={status}
                onChange={(e) => { setStatus(Number(e.target.value)); setButton(false); }}
                variant='outlined'
                size='small'
                InputLabelProps={{ style: { color: getStatusColor() } }}
                inputProps={{ style: { color: getStatusColor() } }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '20px',
                    width: "94px",
                    borderColor: getStatusColor(),
                    '&:hover fieldset': { borderColor: getStatusColor() },
                    '&.Mui-focused fieldset': { borderColor: getStatusColor() },
                  },
                  '& .MuiInputLabel-root': { color: getStatusColor() },
                  '& .MuiSelect-select': { color: getStatusColor() },
                }}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                  },
                }}
              >
                <MenuItem value={1} style={{ color: '#50cd89' }}>Open</MenuItem>
                <MenuItem value={2} style={{ color: 'red' }}>Deleted</MenuItem>
              </TextField>
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className='m-0 p-0 pt-2'>
          {isLoading ? (
            <Loading isModal={true} />
          ) : (
            <div className='card p-2'>
              <div className='card-header text-center justify-content-center align-items-center'
                style={{
                  fontFamily: 'Atma',
                  marginTop: '-3%',
                  backgroundColor: '#ed1477',
                  minHeight: '40px',
                  padding: '0px',
                  paddingTop: '5px',
                }}>
                <SelectCategory
                  categoryNamee={questionEdit.categoryName}
                  company={company}
                  setCategoryId={setCategoryId}
                  categoryId={questionEdit.categoryId}
                  button={() => setButton(false)}
                />
              </div>

              <div className='card-body p-0' style={{ backgroundColor: 'white', marginTop: '-1px' }}>
                <div className='rounded-4' style={{ backgroundColor: '#181c32' }}>
                  <div className='float-end mb-5 pt-1 pe-2'>
                    <Time Time={time} SetTime={setTime} button={() => setButton(false)} customerId={company} />
                  </div>
                  <div className='rounded m-2 d-flex align-items-center justify-content-center' style={{ width: '473px' }}>
                    <SelectContentNew
                      changeFile={setFile}
                      changeMediaUrl={(value, _, mediaType) => { setMediaUrl(value); setMediaType(mediaType); }}
                      iconDisabled={true}
                      mediaUrl={mediaUrl}
                      mediaType={mediaType}
                      button={() => setButton(false)}
                    />
                  </div>
                </div>

                <div className='col-12' style={{ padding: '0px' }}>
                  <TinyMCEWidget
                    html={questionEdit.htmlDescription}
                    setquestion={setQuestion}
                    setplainText={setPlainText}
                    button={() => setButton(false)}
                    mediaType={mediaType}
                  />
                </div>
              </div>
            </div>
          )}

          <div className='rounded rounded-top-0' style={{ backgroundColor: 'white', padding: '2px 6px 0px 6px' }}>
            <div className='card-body rounded-top-0'>
              <QuestionAnswerWidgetEdit
                answerList={answerList}
                className='col-12'
                setAnswerList={setAnswerList}
                setSelection={setSelection}
                selection={selection}
                setcorrectAnswerCount={() => {}}
                correctAnswerCount={questionEdit.correctAnswerCount}
                button={() => setButton(false)}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className='p-2 pt-5 mt-1 mb-1' style={{ marginTop: '-20px', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Dublicate
              setDublicateId={setDublicateId}
              company={company}
              questionId={questionId}
              show={first}
              setShow={setfirst}
            />
            <Button
              variant='contained'
              color='success'
              onClick={() => { setNew(true); handleClose(); }}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              New
            </Button>
          </div>
          <Button style={{ textTransform: 'none' }} onClick={() => setAccordionOpen(!accordionOpen)}>
            <Typography fontSize={18} color={'primary'}>
              Learn
            </Typography>
            <ExpandMoreIcon
              fontSize='large'
              color='primary'
              style={{
                transform: accordionOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
              }}
            />
          </Button>
          <div>
            <Button
              variant='contained'
              color='inherit'
              style={{ marginLeft: 10 }}
              onClick={handleClose}
            >
              Discard
            </Button>
            <Button
              disabled={button || saveLoading}
              variant='contained'
              onClick={handleSave}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              {saveLoading ? <CircularProgress size={20} color='inherit' /> : 'Save'}
            </Button>
          </div>

          <div style={{ height: 'auto', marginBottom: 10 }}>
            {accordionOpen && (
              <FAQWidget title='Question' className='rounded' accordionOpen={accordionOpen} />
            )}
          </div>
        </Modal.Footer>
      </Modal>

      <Dialog
        open={showUnsavedChangesDialog}
        onClose={() => setShowUnsavedChangesDialog(false)}
        fullWidth
        maxWidth={'xs'}
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Typography color='white' variant='h6'>
              You have unsaved changes.
              <br />
              Do you want to discard, Anyway?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: 'white' }}
            onClick={() => {
              setShowUnsavedChangesDialog(false)
              closeModal()
            }}
          >
            Yes
          </Button>
          <Button style={{ color: 'white' }} onClick={() => setShowUnsavedChangesDialog(false)}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditQuestionModal