import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import React, { FC, useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
//icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { TransitionProps } from '@mui/material/transitions'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  /* border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  }, */
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ height: 20 }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  /*  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)', */
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  /*   borderTop: '1px solid rgba(0, 0, 0, .125)', */
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

type Props = {
  company: any
  second: any
  setSecond: any
}

export const AddCategory: FC<Props> = ({ company, second, setSecond }) => {
  const [first, setfirst] = useState(false)
  const [show, setShow] = useState(false)
  const [categoryName, setcategoryName] = useState<any>('Select Category')
  const [deleteCategory, setDeleteCategory] = useState<any>()
  const [showDelete, setShowDelete] = useState(false)
  const [addCategory, setAddCategory] = useState<any>()
  const [showAdd, setShowAdd] = useState(false)
  const handleCloseDelete = () => {
    setdeleteMessage('')
    setShowDelete(false)
  }
  const [warnModal, setWarnModal] = useState(false)
  const handleCloseAdd = () => setShowAdd(false)
  const handleWarnModal = () => setWarnModal(false)
  const [newCategory, setNewCategory] = useState<any>()

  const handleClose = () => {
    setShow(false)
    setcategoryName('Select Category')
  }
  const handleSave = () => {
    setShow(false)
  }
  const handleShow = () => setShow(true)
  const LoginState = useSelector((state: any) => state.Login)
  const [categories, setCategories] = useState<any>([])

  const [getCategoriesLoading, setGetCategoriesLoading] = useState(false)
  useEffect(() => {
    function test() {
      setGetCategoriesLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/content/getAccessibleCategories`, {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        })
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setCategories(response.data.customerCategory)
          /*   if (company === 0) {
             
            } else {
              setCategories(response.data.rootCategory)
            } */
          setGetCategoriesLoading(false)
        })
        .catch((error) => {
          error.response.data.detail.errorMessage
            ? enqueueSnackbar(`${error.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
            : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              { variant: 'error' }
            )
          setGetCategoriesLoading(false)
        })
    }

    test()
  }, [first, second])

  const [Hover, setHover] = useState<any>('')
  const [hoverPlus, setHoverPlus] = useState(false)
  const [haveContent, sethaveContent] = useState<any>(null)
  const [deleteMessage, setdeleteMessage] = useState<any>('')
  const postCategoryDelete = async (id: any) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/content/checkCategoryIsEmpty/${id.categoryId}`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      })
      .then(async (response) => {
        if (response.data.isEmpty === true) {
          axios
            .post(`${process.env.REACT_APP_API_URL}/content/deleteCategory/${id.categoryId}`, '', {
              headers: {
                accept: 'application/json',
                Authorization: `Bearer ${LoginState.loginData.access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            })
            .then((response) => {
              if (deleteChilNumber == 1) {
                axios
                  .post(`${process.env.REACT_APP_API_URL}/content/deleteCategory/${parentId}`, '', {
                    headers: {
                      accept: 'application/json',
                      Authorization: `Bearer ${LoginState.loginData.access_token}`,
                      'Content-Type': 'application/x-www-form-urlencoded',
                    },
                  })
                  .then((response) => {
                    setfirst(!first)
                    setSecond(!second)
                    handleCloseDelete()
                  })
              } else {
                setfirst(!first)
                setSecond(!second)
                handleCloseDelete()
              }
            })
        }
        if (response.data.isEmpty === false) {
          setdeleteMessage('The category is not empty and can not be deleted.')
        }
      })
  }
  const postCategory = (id: any) => {
    if (id.categoryId != 0) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/content/addCategory`,
          {
            name: `${newCategory}`,
            parentId: `${id.categoryId}`,
          },
          {
            headers: {
              accept: 'application/json',
              Authorization: `Bearer ${LoginState.loginData.access_token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          setfirst(!first)
          setSecond(!second)
        })
        .catch((err) => {
          err.response.data.detail.errorMessage == 'CATEGORY_ALREADY_EXISTS'
            && setWarnModal(true)

        })
    } else if (id.categoryId == 0) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/content/addCategory`,
          {
            name: `${newCategory}`,
            parentId: `${id.categoryId}`,
          },
          {
            headers: {
              accept: 'application/json',
              Authorization: `Bearer ${LoginState.loginData.access_token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          var parentid = response.data.id
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/content/addCategory`,
              {
                name: `${newCategory} 1`,
                parentId: `${parentid}`,
              },
              {
                headers: {
                  accept: 'application/json',
                  Authorization: `Bearer ${LoginState.loginData.access_token}`,
                  'Content-Type': 'application/json',
                },
              }
            )
            .then((re) => {

            })
            .catch((er) => {

            })
          setfirst(!first)
          setSecond(!second)
        })
    }
  }
  const AddMainCategory = async () => {
    var eNum = categories.length + 1
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/content/addCategory`,
        {
          name: `NewMainCategory${eNum}`,
          parentId: null,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (response) => {
        var responsee: any = response

        secondAddCategory(response.data.id)
      })
      .catch((err) => {
        /* console.log('err : ', err) */
      })
  }

  async function secondAddCategory(id: any) {
    var eNum = categories.length + 1
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/content/addCategory`,
        {
          name: `newsubCategory1${eNum}`,
          parentId: `${id}`,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        setfirst(!first)
        setSecond(!second)
        setmainCategory(false)
      })
      .catch((err) => {
        /*  console.log('error', err) */
      })
  }
  const [doubleClick, setdoubleClick] = useState(0)
  const [parentId, setparentId] = useState(0)
  function EditNameOfCategory(e: any) {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/content/editCategory/${doubleClick}`,
        // '{\n  "name": "ANA KATEGORİ "\n}',
        {
          name: e.target.value,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          setfirst(!first)
          setSecond(!second)
        }, 1000)
      })
      .catch((err) => {
        if (err.response.data.detail.errorMessage === 'CATEGORY_ALREADY_EXISTS') {
          setWarnModal(true)
        } else {
          /* console.log(err) */
        }
      })
  }
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      if (event.target.value.length >= 3 && event.target.value.length <= 28) {
        setdoubleClick(0)
        EditNameOfCategory(event)
      }
    }
  }

  const handleKeyDown2 = (event: any) => {
    if (event.key === 'Enter') {
      if (event.target.value.length >= 3 && event.target.value.length <= 34) {
        setdoubleClick(0)
        EditNameOfCategory(event)
      }
    }
  }
  const [isClosing, setIsClosing] = useState(false)
  const handleBlur = (e) => {
    if (!isClosing && e.target.value.length >= 3 && e.target.value.length <= 34) {
      setdoubleClick(0)
      EditNameOfCategory(e)
    }
    // Odak kaybından sonra, isClosing'i sıfırlayın
    setIsClosing(false)
  }
  const handleCloseClick = () => {
    setIsClosing(true)
    setdoubleClick(0)
  }
  const [deleteChilNumber, setdeleteChilNumber] = useState(0)
  const [mainCategory, setmainCategory] = useState(false)
  return (
    <>
      <Button
        variant='contained'
        color='success'
        /* style={{textTransform: 'none'}} */ onClick={handleShow}
      >
        Add new category
      </Button>


      <Dialog
        open={show}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth='xl'
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle fontWeight={'bold'}>
          {'MANAGE CATEGORY'}{' '}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className='row '>
            <div className='col-11'>
              <div
                className='d-flex'
                style={{
                  overflowY: 'scroll',
                  overflowX: 'scroll',
                  minHeight: '600px',
                  height: '600px',
                }}
              >
                {getCategoriesLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  categories?.map((data: any) => (
                    <>
                      <div style={{ width: '300px' }}>
                        <TextField
                          onKeyDown={handleKeyDown}
                          onBlur={handleBlur}
                          defaultValue={data.name}
                          id={data.categoryId}
                          type='text'
                          placeholder={data.name}
                          inputProps={{
                            maxLength: 28,
                            minLength: 3,
                            style: { textTransform: 'uppercase' },
                          }}
                          style={{ height: '63px', marginRight: '25px', width: '250px' }}
                          className={doubleClick === data.categoryId ? '' : 'd-none'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton onClick={handleCloseClick}>
                                  <CloseIcon color='error' fontSize='small' />
                                </IconButton>
                                {/* <IconButton
                                  onClick={(e) => {
                                    handleKeyDown(e)
                                  }}
                                >
                                  <CheckIcon color='success' fontSize='small' />
                                </IconButton> */}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      <div>
                        {
                          //1.katman
                        }
                        <Accordion
                          className={doubleClick == data.categoryId ? 'd-none' : ''}
                          style={{
                            marginRight: 20,
                            borderRadius: '10px 10px 5px 5px',
                            border: '1px solid #d5d5d5',
                            minWidth: '300px',
                          }}
                          defaultExpanded={true}
                        >
                          <AccordionSummary
                            /* expandIcon={
                                  <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                } */
                            style={{
                              background: '#D5D5D5',
                              borderRadius: '10px 10px 0px 0px',
                              padding: '0px 10px',
                              minHeight: '60px',
                            }}
                          >
                            <div
                              className='d-flex '
                              onMouseEnter={(e: any) => {
                                setHover(data.categoryId)
                              }}
                              onMouseLeave={(e: any) => {
                                setHover('')
                              }}
                              style={{ alignItems: 'center', height: 20 }}
                            >
                              <div
                                className={
                                  doubleClick == data.categoryId ? 'd-none' : 'text-gray-800 '
                                }
                                style={{ fontWeight: 'bold', cursor: 'pointer', fontSize: '12px' }}
                                onDoubleClick={() => setdoubleClick(data.categoryId)}
                              >
                                {data.name.toUpperCase()}
                              </div>
                              <span
                                title='Number of available questions'
                                className='text-dark  ms-2'
                                onClick={() => {
                                  setAddCategory(data)
                                  setShowAdd(true)
                                }}
                                style={{ fontSize: '10px', color: '#8e8e8e', fontWeight: 'bold' }}
                              >
                                ({data.questionCount})
                              </span>
                              {Hover === data.categoryId ? (
                                <div className='ms-1 '>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton
                                      size='small'
                                      style={{ padding: '3px', fontSize: '15px' }}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        setAddCategory(data)
                                        setShowAdd(true)
                                      }}
                                    >
                                      <AddCircleOutlinedIcon
                                        sx={{
                                          color: '#53b658',
                                          fontSize: '16px',
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                  {data.children.length > 0 ? null : (
                                    <div>
                                      <IconButton
                                        size='small'
                                        style={{ padding: '3px', fontSize: '15px' }}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          setDeleteCategory(data)
                                          setShowDelete(true)
                                          setdeleteChilNumber(data.children.length)
                                          setparentId(data.categoryId)
                                        }}
                                      >
                                        <DeleteForeverIcon color='error' sx={{ fontSize: '16px' }} />
                                      </IconButton>
                                    </div>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails style={{ padding: '3px' }}>
                            {
                              //2. katman
                            }

                            {data?.children?.map((child: any) =>
                              child.children.length > 0 ? (
                                <>
                                  <div
                                    className={
                                      doubleClick == child.categoryId ? 'd-flex' : 'd-none'
                                    }
                                    style={{ height: '32px' }}
                                  >
                                    <TextField
                                      onKeyDown={handleKeyDown}
                                      size='small'
                                      onBlur={handleBlur}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <IconButton onClick={handleCloseClick}>
                                              <CloseIcon color='error' fontSize='small' />
                                            </IconButton>
                                            {/*   <IconButton
                                              onClick={(e) => {
                                                handleKeyDown2(e)
                                              }}
                                            >
                                              <CheckIcon color='success' fontSize='small' />
                                            </IconButton> */}
                                          </InputAdornment>
                                        ),
                                      }}
                                      id={child.categoryId}
                                      type='text'
                                      placeholder={child.name}
                                      defaultValue={child.name}
                                      inputProps={{
                                        maxLength: 34,
                                        minLength: 3,
                                        style: { fontSize: '12px' },
                                      }}
                                      style={{
                                        height: '63px',
                                        marginRight: '15px',
                                        width: '250px',
                                      }}
                                      className={doubleClick == child.categoryId ? '' : 'd-none'}
                                    />
                                  </div>
                                  <Accordion
                                    className={doubleClick == child.categoryId ? 'd-none' : ''}
                                    style={{
                                      marginTop: '5px',
                                      marginBottom: '5px',
                                      boxShadow: 'none',
                                      borderRadius: '10px 10px 0px 0px',
                                    }}
                                    defaultExpanded={true}
                                  >
                                    <AccordionSummary
                                      /*  expandIcon={
                                            <ExpandMoreIcon
                                              fontSize='large'
                                              style={{color: 'black'}}
                                            />
                                          } */
                                      sx={{ margin: '0px!important' }}
                                      style={{
                                        borderRadius: '10px 10px 0px 0px',
                                        padding: '0px 3px',
                                        minHeight: '20px',
                                        background: Hover === child.categoryId ? '#e7e7e7' : '',
                                      }}
                                    >
                                      <div
                                        className='d-flex align-items-center'
                                        onMouseEnter={(e: any) => {
                                          setHover(child.categoryId)
                                        }}
                                        onMouseLeave={(e: any) => {
                                          setHover('')
                                        }}
                                        style={{ alignItems: 'center', height: 20 }}
                                      >
                                        <span
                                          className={
                                            doubleClick == child.categoryId
                                              ? 'd-none'
                                              : 'text-gray-800 d-flex'
                                          }
                                          style={{
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            fontSize: '12px',
                                          }}
                                          onDoubleClick={() => setdoubleClick(child.categoryId)}
                                        >
                                          {child.name.charAt(0).toUpperCase() + child.name.slice(1)}
                                        </span>
                                        {doubleClick == child.categoryId && (
                                          <span
                                            title='Number of available questions'
                                            className='text-dark text-muted ms-2'
                                            onClick={() => {
                                              setAddCategory(data)
                                              setShowAdd(true)
                                            }}
                                            style={{
                                              fontSize: '10px',
                                              color: '#8e8e8e',
                                              fontWeight: 'normal',
                                            }}
                                          >
                                            ({child.questionCount})
                                          </span>
                                        )}

                                        {Hover === child.categoryId ? (
                                          <div className='ms-1 '>
                                            <div
                                              className='d-flex'
                                              style={{ alignItems: 'center', marginLeft: 10 }}
                                            >
                                              {child.children.length > 0 ? null : (
                                                <div>
                                                  <IconButton
                                                    size='small'
                                                    style={{ padding: '3px', fontSize: '15px' }}
                                                    onClick={(e) => {
                                                      e.stopPropagation()
                                                      setDeleteCategory(child)
                                                      setShowDelete(true)
                                                      setdeleteChilNumber(child.children.length)
                                                      setparentId(child.categoryId)
                                                    }}
                                                  >
                                                    <DeleteForeverIcon
                                                      color='error'
                                                      sx={{ fontSize: '16px' }}
                                                    />
                                                  </IconButton>
                                                </div>
                                              )}
                                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <IconButton
                                                  size='small'
                                                  style={{ padding: '3px', fontSize: '15px' }}
                                                  onClick={(e) => {
                                                    e.stopPropagation()
                                                    setAddCategory(child)
                                                    setShowAdd(true)
                                                  }}
                                                >
                                                  <AddCircleOutlinedIcon
                                                    sx={{
                                                      color: '#53b658',
                                                      fontSize: '16px',
                                                    }}
                                                  />
                                                </IconButton>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '3px' }}>
                                      {child?.children?.map((child: any) => (
                                        <div id={child.categoryId} className='ms-0 mt-3 '>
                                          <div
                                            className='d-flex'
                                            onMouseEnter={(e: any) => {
                                              setHover(child.categoryId)
                                            }}
                                            onMouseLeave={(e: any) => {
                                              setHover('')
                                            }}
                                            style={{
                                              alignItems: 'start',
                                              background:
                                                Hover === child.categoryId ? '#e7e7e7' : '',
                                            }}
                                          >
                                            <Typography
                                              className={
                                                doubleClick == child.categoryId ? 'd-none' : ''
                                              }
                                            >
                                              •
                                            </Typography>
                                            <a
                                              className={
                                                doubleClick == child.categoryId
                                                  ? 'd-none'
                                                  : 'text-gray-800 d-flex '
                                              }
                                              style={{
                                                cursor: 'pointer',
                                                fontSize: '12px',
                                                alignItems: 'center',
                                                maxWidth: '210px',
                                                marginLeft: '5px',
                                              }}
                                              onDoubleClick={() => setdoubleClick(child.categoryId)}
                                            >
                                              {child.name.charAt(0).toUpperCase() +
                                                child.name.slice(1)}
                                            </a>
                                            <div
                                              className={
                                                doubleClick == child.categoryId
                                                  ? 'd-flex'
                                                  : 'd-none'
                                              }
                                              style={{
                                                height: '33px',
                                              }}
                                            >
                                              <TextField
                                                onKeyDown={handleKeyDown}
                                                size='small'
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position='end'>
                                                      <IconButton onClick={handleCloseClick}>
                                                        <CloseIcon color='error' fontSize='small' />
                                                      </IconButton>
                                                      {/* <IconButton
                                                        onClick={(e) => {
                                                          handleKeyDown2(e)
                                                        }}
                                                      >
                                                        <CheckIcon
                                                          color='success'
                                                          fontSize='small'
                                                        />
                                                      </IconButton> */}
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                id={child.categoryId}
                                                type='text'
                                                placeholder={child.name}
                                                defaultValue={child.name}
                                                inputProps={{
                                                  maxLength: 34,
                                                  minLength: 3,
                                                  style: { fontSize: '12px' },
                                                }}
                                                style={{
                                                  height: '63px',
                                                  marginRight: '15px',
                                                  width: '250px',
                                                }}
                                                className={
                                                  doubleClick == child.categoryId ? '' : 'd-none'
                                                }
                                              />
                                            </div>

                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '20px',
                                              }}
                                            >
                                              {doubleClick !== child.categoryId && (
                                                <span
                                                  title='Number of available questions'
                                                  className='ms-2'
                                                  style={{ color: '#8e8e8e', fontSize: '10px' }}
                                                >
                                                  {`(${child.questionCount})`}
                                                </span>
                                              )}

                                              <div
                                                className={
                                                  Hover === child.categoryId &&
                                                    doubleClick !== child.categoryId
                                                    ? 'd-flex ms-1'
                                                    : 'd-none'
                                                }
                                              >
                                                <a
                                                  href='#'
                                                  className={
                                                    child?.children.length > 0
                                                      ? 'd-none'
                                                      : 'ms-1 d-flex text-white rounded justify-content-center align-content-center'
                                                  }
                                                  style={{ maxHeight: '25px', width: '25px' }}
                                                  onClick={() => {
                                                    setDeleteCategory(child)
                                                    setShowDelete(true)
                                                    setdeleteChilNumber(data.children.length)
                                                    setparentId(data.categoryId)
                                                  }}
                                                >
                                                  <div
                                                    className='d-flex'
                                                    style={{ alignItems: 'center' }}
                                                  >
                                                    <div>
                                                      <IconButton
                                                        size='small'
                                                        style={{ padding: '3px', fontSize: '15px' }}
                                                      >
                                                        <DeleteForeverIcon
                                                          color='error' /* fontSize='small' */
                                                          sx={{ fontSize: '16px' }}
                                                        />
                                                      </IconButton>
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </AccordionDetails>
                                  </Accordion>
                                </>
                              ) : null
                            )}
                            {
                              // 3. katman
                            }

                            {data?.children?.map((child: any) =>
                              child.children.length > 0 ? null : (
                                <div id={child.categoryId} className='ms-0 mt-3 '>
                                  <div
                                    className='d-flex border-1'
                                    onMouseEnter={(e: any) => {
                                      setHover(child.categoryId)
                                    }}
                                    onMouseLeave={(e: any) => {
                                      setHover('')
                                    }}
                                    style={{
                                      alignItems: 'start',
                                      background: Hover === child.categoryId ? '#e7e7e7' : '',
                                    }}
                                  >
                                    <a
                                      className={
                                        doubleClick == child.categoryId
                                          ? 'd-none'
                                          : 'text-gray-800 d-flex'
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                        maxWidth: '210px',
                                      }}
                                      onDoubleClick={() => setdoubleClick(child.categoryId)}
                                    >
                                      {child.name.charAt(0).toUpperCase() + child.name.slice(1)}
                                    </a>

                                    <div
                                      className={
                                        doubleClick == child.categoryId ? 'd-flex' : 'd-none'
                                      }
                                      style={{
                                        height: '33px',
                                      }}
                                    >
                                      <TextField
                                        onKeyDown={handleKeyDown}
                                        size='small'
                                        onBlur={handleBlur}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <IconButton onClick={handleCloseClick}>
                                                <CloseIcon color='error' fontSize='small' />
                                              </IconButton>
                                              {/*  <IconButton
                                                onClick={(e) => {
                                                  handleKeyDown2(e)
                                                }}
                                              >
                                                <CheckIcon color='success' fontSize='small' />
                                              </IconButton> */}
                                            </InputAdornment>
                                          ),
                                        }}
                                        id={child.categoryId}
                                        type='text'
                                        placeholder={child.name}
                                        defaultValue={child.name}
                                        inputProps={{
                                          maxLength: 34,
                                          minLength: 3,
                                          style: { fontSize: '12px' },
                                        }}
                                        style={{
                                          height: '63px',
                                          marginRight: '15px',
                                          width: '250px',
                                        }}
                                        className={doubleClick == child.categoryId ? '' : 'd-none'}
                                      />
                                    </div>

                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '20px',
                                      }}
                                    >
                                      {doubleClick !== child.categoryId && (
                                        <span
                                          title='Number of available questions'
                                          className='ms-2'
                                          style={{ color: '#8e8e8e', fontSize: '10px' }}
                                        >
                                          {`(${child.questionCount})`}
                                        </span>
                                      )}

                                      <div
                                        className={
                                          Hover === child.categoryId &&
                                            doubleClick !== child.categoryId
                                            ? 'd-flex ms-4'
                                            : 'd-none'
                                        }
                                      >
                                        <a
                                          href='#'
                                          className={
                                            child?.children.length > 0
                                              ? 'd-none'
                                              : 'ms-1 d-flex text-white rounded justify-content-center align-content-center'
                                          }
                                          style={{
                                            maxHeight: '25px',
                                            width: '25px',
                                            alignContent: 'center',
                                          }}
                                          onClick={() => {
                                            setDeleteCategory(child)
                                            setShowDelete(true)
                                            setdeleteChilNumber(data.children.length)
                                            setparentId(data.categoryId)
                                          }}
                                        >
                                          <div
                                            className='d-flex'
                                            style={{ alignItems: 'center', marginLeft: 10 }}
                                          >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                              <IconButton
                                                size='small'
                                                style={{ padding: '3px', fontSize: '15px' }}
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  setAddCategory(child)
                                                  setShowAdd(true)
                                                }}
                                              >
                                                <AddCircleOutlinedIcon
                                                  sx={{
                                                    color: '#53b658',
                                                    fontSize: '16px',
                                                  }} /* fontSize='small' */
                                                />
                                              </IconButton>
                                            </div>
                                            <div>
                                              <IconButton
                                                size='small'
                                                style={{ padding: '3px', fontSize: '15px' }}
                                              >
                                                <DeleteForeverIcon
                                                  color='error' /* fontSize='small' */
                                                  sx={{ fontSize: '16px' }}
                                                />
                                              </IconButton>
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </>
                  ))
                )}
              </div>
            </div>
            <div className='col-1 d-flex justify-content-start'>
              <div className='d-flex  mt-4 text-white rounded align-items-start '>
                <Tooltip arrow title='Add Main Category' placement='top'>
                  <IconButton
                    size='medium'
                    style={{ padding: '3px', fontSize: '15px' }}
                    onClick={(e) => {
                      setmainCategory(true)
                    }}
                  >
                    <AddCircleOutlinedIcon
                      sx={{
                        color: '#53b658',
                        fontSize: '30px',
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ width: "90%" }}>
          {/*  <Button onClick={handleClose}>Disagree</Button> */}
          <Button variant='contained' color='primary' onClick={handleSave}>
            DONE
          </Button>
        </DialogActions>
      </Dialog>

      {
        // add mother category
      }
      <Dialog
        open={mainCategory}
        TransitionComponent={Transition}
        /*    keepMounted */
        onClose={() => setmainCategory(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        {/*      <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Main category and one child category will be added . Proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='inherit' onClick={() => setmainCategory(false)}>
            Close
          </Button>
          <Button variant='contained' onClick={AddMainCategory}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      {
        // category add modal
      }
      <Dialog
        open={showAdd}
        /* TransitionComponent={Transition} */
        keepMounted
        onClose={handleCloseAdd}
        aria-describedby='alert-dialog-slide-description'
      /* PaperProps={{
        style: {
          backgroundColor: '#1976d2',
        },
      }} */
      >
        <DialogTitle fontWeight={'bold'}>
          {addCategory?.name != null ? addCategory.name : 'Ana kategori'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Enter the category to be added under{' '}
            {addCategory?.name != null ? addCategory.name : 'Ana kategori'}
          </DialogContentText>
          <TextField
            className='form-control form-control-solid overflow-auto '
            onChange={(name) => setNewCategory(name.target.value)}
            value={newCategory}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleCloseAdd}>
            Close
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              setShowAdd(false)
              postCategory(addCategory)
            }}
          >
            Category Add
          </Button>
        </DialogActions>
      </Dialog>

      {
        // warn modal
      }
      <Dialog
        open={warnModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleWarnModal}
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
      >
        <DialogTitle fontWeight={'bold'}>WARNİNG</DialogTitle>
        <DialogContent>
          <DialogContentText color='white' id='alert-dialog-slide-description'>
            Category with the same name !
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' style={{ color: 'white' }} onClick={handleWarnModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDelete}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle fontWeight={'bold'}>
          {' '}
          {deleteCategory?.name != null ? deleteCategory.name : 'Ana kategori'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'></DialogContentText>
          <span className={deleteMessage.length < 1 ? 'd-none' : ''}> {deleteMessage}</span>
          <span className={deleteMessage.length > 1 ? 'd-none' : ''}>
            Category {deleteCategory?.name != null ? deleteCategory.name : 'Ana kategori'} will be
            deleted. Proceed?
          </span>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='inherit' onClick={handleCloseDelete}>
            Discard
          </Button>
          <Button
            className={deleteMessage.length > 1 ? 'd-none' : ''}
            variant='contained'
            color='error'
            onClick={() => {
              postCategoryDelete(deleteCategory)
            }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
