/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, forwardRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import LoginActions from '../../ReduxNew/reducers/LoginRedux'
import {GDPRModal} from './GDPRModal'
import {IconButton, Slide, Typography, Fade} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { SnackbarProvider, useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import GoogleCaptchaWrapper from './GoogleCaptchaWrapper'
import { useIntl } from 'react-intl'
import { useLang } from '../../../_metronic/i18n/Metronici18n'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function Login(props) {
  const [token, setToken] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

  return (
    <GoogleCaptchaWrapper>
      <LoginFunc
        {...props}
        setRefreshReCaptcha={setRefreshReCaptcha}
        refreshReCaptcha={refreshReCaptcha}
        token={token}
        setToken={setToken}
      />
      <GoogleReCaptcha onVerify={setToken} refreshReCaptcha={refreshReCaptcha} />
    </GoogleCaptchaWrapper>
  )
}

function LoginFunc(props) {
  const { refreshReCaptcha, setRefreshReCaptcha, token, setToken } = props
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl();
  const messages = intl?.messages?.login;
  const location = useLocation()
  const [path, setPath] = useState('')
  const lang = useLang()
  //path namede direkt kurum adı ile girildiğinde kurum seçilmez örneğin;
  //https://plusadmin.mobixa.net/mobixa

  const [gdprModal, setGdprModal] = useState(false)
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false)
  //start recapcha

  const doSomething = async () => {
    setRefreshReCaptcha(!refreshReCaptcha)
  }

  //end recapcha
  const [pathMatch, setPathMatch] = useState(false)
  const [pathMatchForgotPassword, setPathMatchForgotPassword] = useState(false)

  const [products, setProducts] = useState([])
  const [customer, setCustomer] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(LoginActions.clearState())
  }, [])

  useEffect(() => {
    async function test() {
      setIsLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/customer/getAllLoginActiveCustomers`)
        .then((response) => {
          // expected the setProducts to be filled with the return of this request
          setProducts(response.data)
          setIsLoading(false)
        })
    }
    if (products.length === 0) {
      test()
    }
  }, [])

  useEffect(() => {
    setPath(decodeURIComponent(location?.pathname?.split('/')[1]))
    for (let i = 0; i < products.length; i++) {
      if (products[i].title === path.trim()) {
        /*  setPathMatch(true) */
        setcustomerName(products[i].title)
        break // Eşleşme bulunduğunda döngüden çık
      }
    }
  }, [products])

  useEffect(() => {
    setCustomer(products?.map((item) => item?.title?.toLowerCase().replace(/\s/g, '')))
  }, [products])

  const [customerInPath, setcustomerInPath] = useState()
  useEffect(() => {
    setcustomerInPath(
      products?.filter(
        (item) =>
          item?.title?.toLowerCase().replace(/\s/g, '') ==
          decodeURI(path).toLowerCase().replaceAll(' ', '')
      )
    )
  }, [products, customer, path, location])

  useEffect(() => {
    if (customerInPath?.length > 0) {
      setcustomerId(customerInPath[0]?.customerId)
    }
  }, [customerInPath])
  const [email, setEmail] = useState('')
  const [forgotEmail, setForgotEmail] = useState('')
  const [password, setPasword] = useState('')
  const dispatch = useDispatch()
  const [customerId, setcustomerId] = useState(-1)
  const [customerName, setcustomerName] = useState('')
  const navigate = useNavigate()

  const [gdprData, setGdprData] = useState()
  const [gdprAccept, setGdprAccept] = useState(false)

  const loginError = useSelector((state) => state)

  useEffect(() => {
    /* if( loginError.Login?.loginData?.detail?.errorMessage === "USER_EMAIL_NOT_CONFIRMED"){
    enqueueSnackbar(`${loginError.Login?.loginData?.detail?.errorMessage}`, {variant: 'error'})
   }
   if( loginError.Login?.loginData?.detail?.errorMessage === "USER EMAIL OR PASSWORD NOT VALID"){
    enqueueSnackbar(`${loginError.Login?.loginData?.detail?.errorMessage}`, {variant: 'error'})
   } */
  }, [loginError.Login])

  useEffect(() => {
    if (gdprAccept) {
      HandleSign()
      setGdprAccept(false)
    }
  }, [gdprAccept])

  let loginErrorMessage = useSelector((state) => state.Login.loginErrorMessage)

  useEffect(() => {
    if (loginErrorMessage?.errorMessage === 'GDPR NOT CONFIRMED') {
      let gdpr = { customerName: loginErrorMessage?.customerName, gdpr: loginErrorMessage?.GDPRText }
      setGdprData(gdpr)
      setGdprModal(true)
    }
    if (loginErrorMessage?.errorMessage === 'RECAPTCHA NOT VALID') {
      dispatch(LoginActions.clearState())
      /* window.location.reload() */
    }
    if (loginErrorMessage?.errorMessage === 'USER CUSTOMER REQUIRED') {
      setPathMatch(true)
      setCustomerRequired(true)
      /* window.location.reload() */
    }
    if (loginErrorMessage) {
      /*  doSomething() */
    }
  }, [loginErrorMessage])

  const LoginState = useSelector((state) => state.Login);

  const HandleSign = async () => {
    setIsLoading(true);

    validateOrganisation();
  
    // Organizasyon alanı kontrolü ve gerekli uyarı
    if (pathMatch && customerId === -1) {
      setIsLoading(false);
      return;
    }

    try {
      const token = process.env.REACT_APP_RECAPTCHA_IS_ON === 'true' ? await executeRecaptcha('login') : '';
  
      dispatch(LoginActions.loginRequest({ customerName, email, password, token, gdprAccept }));
  
      // State güncellemelerini bekleyin
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      console.error('Login error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  

  var edit = true

  const [matches, setMatches] = useState(0)
  const checkMatch = (event) => {
    const value = event.target.value.toLocaleUpperCase('tr-TR')
    const matches = products.filter((product) => {
      return product?.title?.toLocaleUpperCase('tr-TR').startsWith(value)
    })

    if (matches.length > 0 && value.length > 2) {
      if (edit === true) {
        event.target.value = matches[0].title
        edit = false
      }
      setMatches(matches)
      setcustomerId(matches[0].customerId)
      setcustomerName(matches[0].title)
    }

    matches.length > 0
                ? (setIsOutBlurOrganisation(false), setCustomerRequired(false))
                : setIsOutBlurOrganisation(false)
  }

  const [customerRequired, setCustomerRequired] = useState(false)
  function handleForgot() {
    setIsOutBlurOrganisation(true)
    setPathMatchForgotPassword(false)
    setForgotEmail('')
    setSendEmail(false)
    setForgotPasswordModal(true)
    /*  if (customerId >= 0) {
      setForgotPasswordModal(true)
    } else {
    } */
  }
  const [emailisvalid, setemailisvalid] = useState(false)
  const [forgotEmailisvalid, setforgotEmailisvalid] = useState(false)
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  const HandleMail = (event) => {
    if (!isValidEmail(event.target.value.toLowerCase())) {
      setemailisvalid(false)
    } else {
      setemailisvalid(true)
    }
  }
  const HandleForgotMail = (event) => {
    if (!isValidEmail(event.target.value)) {
      setforgotEmailisvalid(false)
    } else {
      setforgotEmailisvalid(true)
    }
  }
  const [recaptcha, setRecaptcha] = useState(false)
  const [buttonClickCount, setbuttonClickCount] = useState(0)

  /* useEffect(() => {
    if (buttonClickCount > 1) doSomething()
  }, [buttonClickCount]) */

  const [isOutBlur, setisOutBlur] = useState(true)
  const [isOutBlurOrganisation, setIsOutBlurOrganisation] = useState(true)
  const [forgotIsOutBlur, setForgotIsOutBlur] = useState(true)

  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false)
  const [sendEmail, setSendEmail] = useState(false)
  function sendMailReset() {
    setForgotPasswordLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/forgotPasswordEmail`, {
        email: forgotEmail,
        customerId: customerId,
        loginEnv: 1,
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setSendEmail(true)
        enqueueSnackbar('Successfully Saved', { variant: 'success' })
        setTimeout(() => {
          /*  setForgotPasswordModal(false) */
          setForgotPasswordLoading(false)
        }, 3000)
      })
      .catch((err) => {
        setForgotPasswordLoading(false)
        if (err.response.data.detail?.errorMessage === 'USER CUSTOMER REQUIRED') {
          setPathMatchForgotPassword(true)
          setCustomerRequired(true)
          /* window.location.reload() */
        } else {
          err?.response?.data?.detail?.errorMessage
            ? enqueueSnackbar(`${err.response.data.detail.errorMessage.replace(/_/g, ' ')}`, {
              variant: 'error',
            })
            : enqueueSnackbar(
              'The process could not be completed. Please try again a few minutes later.',
              { variant: 'error' }
            )
        }
      })
  }

  /* console.log("token : ", token) */
  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    if (!executeRecaptcha) {
      doSomething()
    }
  }, [executeRecaptcha])

  // Organisation alanının doğrulama kontrolü
const validateOrganisation = () => {
  // Sadece pathMatch true olduğunda kontrol yap
  if (pathMatch) {
    if (matches.length > 0) {
      setIsOutBlurOrganisation(false);
      setCustomerRequired(false);
    } else {
      setIsOutBlurOrganisation(true);
      setCustomerRequired(true);
    }
  }
};

  return (
    <div className=' form w-100'>
      <Grid xs={12} textAlign={'center'} marginTop={10}>
        <Typography component={'h1'} variant='h4' fontWeight={'bold'} style={{ color: '#A1A5B7' }}>
          {messages?.title}
        </Typography>
        <div
          className={
            loginErrorMessage?.errorMessage === 'USER EMAIL OR PASSWORD NOT VALID'
              ? 'mb-5 alert alert-danger m-1'
              : 'd-none'
          }
        >
          <div className='alert-text font-weight-bold'>{messages?.emailOrPasswordIncorrect}</div>
        </div>
        {/* <div className={loginErrorMessage?.errorMessage === "CUSTOMER NOT FOUND"? 'mb-5 alert alert-danger m-1' : 'd-none'}>
          <div className='alert-text font-weight-bold'>{messages?.emailOrPasswordIncorrect}</div>
        </div>
        <div className={loginErrorMessage?.errorMessage === "CUSTOMER NOT ACTIVE"? 'mb-5 alert alert-danger m-1' : 'd-none'}>
          <div className='alert-text font-weight-bold'>{messages?.emailOrPasswordIncorrect}</div>
        </div> */}
        <div
          className={
            loginErrorMessage?.errorMessage === 'USER NOT FOUND'
              ? 'mb-5 alert alert-danger m-1'
              : 'd-none'
          }
        >
          <div className='alert-text font-weight-bold'>{messages?.emailOrPasswordIncorrect}</div>
        </div>
        <div
          className={
            loginErrorMessage?.errorMessage === 'THIS ACCOUNT HAS BEEN EXPIRED'
              ? 'mb-5 alert alert-danger m-1'
              : 'd-none'
          }
        >
          <div className='alert-text font-weight-bold'>
            {messages?.accountExpired}
          </div>
        </div>
        <div
          className={
            loginErrorMessage?.errorMessage === 'RECAPTCHA NOT VALID'
              ? 'mb-5 alert alert-danger m-1'
              : 'd-none'
          }
        >
          <div className='alert-text font-weight-bold'>
            {messages?.recaptchaNotValid}
          </div>
        </div>
        <div
          className={
            loginErrorMessage?.errorMessage === 'TOO MANY REQUESTS'
              ? 'mb-5 alert alert-danger m-1'
              : 'd-none'
          }
        >
          <div className='alert-text font-weight-bold'>{messages?.tryIn1Minute}</div>
        </div>
        <div
          className={
            loginErrorMessage?.errorMessage ===
              'USER IS BLOCKED FOR 5 MINUTES DUE TO MULTIPLE FAILED ATTEMPTS'
              ? 'mb-5 alert alert-danger m-1'
              : 'd-none'
          }
        >
          <div className='alert-text font-weight-bold'>
            {messages?.userBlockedFor5Minutes}
          </div>
        </div>
        <div
          className={
            loginErrorMessage?.errorMessage === 'USER HAS NO ROLE'
              ? 'mb-5 alert alert-danger m-1'
              : 'd-none'
          }
        >
          <div className='alert-text font-weight-bold'>{messages?.userHasNoRole}</div>
        </div>
        <div
          className={
            loginErrorMessage?.errorMessage === 'USER HAS DELETED'
              ? 'mb-5 alert alert-danger m-1'
              : 'd-none'
          }
        >
          <div className='alert-text font-weight-bold'>{messages?.emailOrPasswordIncorrect}</div>
        </div>
        <div
          className={
            loginErrorMessage?.errorMessage === 'USER EMAIL NOT CONFIRMED'
              ? 'mb-5 alert alert-danger m-1'
              : 'd-none'
          }
        >
          <div className='alert-text font-weight-bold'>
            {messages?.emailNotConfirmed}
          </div>
        </div>
        <div
          className={
            loginErrorMessage?.errorMessage === 'USER HAS BEEN BLOCKED'
              ? 'mb-5 alert alert-danger m-1'
              : 'd-none'
          }
        >
          <div className='alert-text font-weight-bold'>{messages?.userBlocked}</div>
        </div>
      </Grid>
      <Grid
        xs={12}
        textAlign={'center'}
        container
        justifyContent={'center'}
        justifyItems={'center'}
        direction={'column'}
      >
        <TextField
          margin='normal'
          fullWidth
          label={messages?.email}
          name='email'
          autoComplete='email'
          sx={{ input: { textTransform: 'lowercase' } }}
          error={email.length > 0 && emailisvalid !== true && isOutBlur === false}
          helperText={
            email.length > 0 && emailisvalid !== true && isOutBlur === false
              ? 'Please Enter a Valid Email'
              : null
          }
          onChange={(e) => {
            const lowerCaseEmail = e.target.value.toLowerCase()
            setEmail(lowerCaseEmail)
            HandleMail(e)
            setisOutBlur(true)
          }}
          onBlur={(e) => setisOutBlur(false)}
        />
        <TextField
          margin='normal'
          fullWidth
          label={messages?.password}
          name='password'
          type='password'
          autoComplete='password'
          helperText={password.length < 5 && password.length !== 0 ? 'Required Field' : null}
          error={password.length < 5 && password.length !== 0}
          onChange={(e) => setPasword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && password.length > 5) {
              // Enter tuşu basıldığında çalışacak fonksiyon
              HandleSign()
            }
          }}
        />
        {pathMatch && (
           <Fade in={pathMatch} timeout={300}>
           <TextField
             margin='normal'
             fullWidth
             onChange={(e) => {
               if (e.nativeEvent.inputType !== 'deleteContentBackward') {
                 checkMatch(e);
               }
               if (e.nativeEvent.inputType === 'deleteContentBackward') {
                 setcustomerId(-1);
                 setcustomerName(e.target.value.toLocaleUpperCase('tr-TR'));
               }
               if (matches.length !== 1 || customerId === -1) {
                 setcustomerName(e.target.value.toLocaleUpperCase('tr-TR'));
                 setIsOutBlurOrganisation(true);
               }

               matches.length > 0
                ? (setIsOutBlurOrganisation(false), setCustomerRequired(false))
                : setIsOutBlurOrganisation(false)
             }}
             onBlur={() =>
              matches.length > 0
                ? (setIsOutBlurOrganisation(false), setCustomerRequired(false))
                : setIsOutBlurOrganisation(false)
            }
             value={customerName}
             label='ORGANISATION'
             name='organisation'
             InputProps={{
               endAdornment: customerName.length > 1 && (
                 <IconButton onClick={() => (setcustomerName(''), setcustomerId(-1))}>
                   <CloseIcon />
                 </IconButton>
               ),
               inputProps: {style: {textTransform: 'uppercase'}},
             }}
             helperText={
               (customerRequired && 'Required Field') ||
               (customerName.length > 2 &&
                 customerId === -1 &&
                 isOutBlurOrganisation === false &&
                 'Required Field')
             }
             error={
               customerRequired ||
               (customerName.length > 2 && customerId === -1 && isOutBlurOrganisation === false)
             }
             autoComplete='organisation'
             autoFocus
           />
         </Fade>
        )}
        {/* <div className={' d-flex justify-content-center mb-1'}>
            {
              // 6LdTCxAnAAAAABJFXDR2lYmmcCc5llMwGmXYjArm sunucu taraflı
            }

            <GoogleReCaptcha onVerify={setToken} refreshReCaptcha={refreshReCaptcha} />
          </div> */}
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box component='form' noValidate textAlign={'start'}>
            <FormControlLabel
              control={<Checkbox value='remember' color='primary' />}
              label={messages?.rememberMe}
            />
          </Box>
          <Box textAlign={'start'}>
            <Button
              variant='text'
              style={{ textTransform: 'none', fontWeight: 'bold' }}
              onClick={() =>
                /*  customerId === -1
                ? setCustomerRequired(true)
                : (handleForgot(), setCustomerRequired(false)) */
                handleForgot()
              }
            >
              {messages?.forgotPassword}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid xs={12} justifyContent={'center'} container>
        <Box textAlign={'center'} sx={{ minHeight: '60px', display: 'flex', alignItems: 'center' }}>
          <Button
            disabled={
              emailisvalid !== true ||
              password.length <= 5 ||
              (!recaptcha && buttonClickCount > 3) ||
              isLoading
            }
            variant='contained'
            style={{width: '189px'}}
            onClick={() => {
              if (!isLoading) {
                HandleSign();
              }
            }}
          >
            {isLoading ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress size={22} color='inherit' />
              </Box>
            ) : (
              <span style={{ color: 'white' }}>{messages?.signIn}</span>
            )}
          </Button>
        </Box>
      </Grid>
      <Grid xs={12} justifyContent={'center'} container mt={15}>
        <span className={'text-muted'}>
          By signing in, you approve the{' '}
          <Button variant='text' style={{ padding: '0px' }} onClick={() => setGdprModal(true)}>
            GDPR
          </Button>{' '}
          document.{' '}
        </span>
        <GDPRModal
          show={gdprModal}
          setShow={setGdprModal}
          gdprData={gdprData}
          gdprAccept={gdprAccept}
          setGdprAccept={setGdprAccept}
          customerID={customerId}
        />
      </Grid>
      <Grid xs={12} container justifyContent={'center'} mt={2}>
        <Grid xs={8} container justifyContent={'space-between'}>
        <Link href={lang === 'en' ? 'https://www.mobixa.net/#price' : 'https://www.mobixa.net/#price'} underline='hover' fontWeight={'bold'}>
            {messages?.plans}
          </Link>
          <Link href='mailto:sales@mobixa.net' underline='hover' fontWeight={'bold'}>
            {messages?.contactUs}
          </Link>
        </Grid>
      </Grid>
      <Dialog
        open={forgotPasswordModal}
        TransitionComponent={Transition}
        maxWidth='sm'
        fullWidth
        keepMounted
        onClose={() => setForgotPasswordModal(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography fontWeight={'bold'} variant='h5'>
            {messages?.forgotPassword}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {!sendEmail ? (
            <Grid
              container
              justifyContent={'center'}
              alignContent={'center'}
              display={'column'}
              textAlign={'center'}
              rowGap={2}
              height={400}
            >
              <Typography color={'#6C757D'} variant='h6'>
                Please enter your email address <br /> registered in Mobixa
              </Typography>
              <TextField
                margin='normal'
                style={{ width: '376px' }}
                label={messages?.email}
                name='email'
                autoComplete='email'
                value={forgotEmail}
                error={
                  forgotEmail.length > 0 && forgotEmailisvalid !== true && forgotIsOutBlur === false
                }
                helperText={
                  forgotEmail.length > 0 && forgotEmailisvalid !== true && forgotIsOutBlur === false
                    ? 'Please Enter a Valid Email'
                    : null
                }
                onChange={(e) => {
                  setForgotEmail(e.target.value)
                  HandleForgotMail(e)
                  setForgotIsOutBlur(true)
                }}
                onBlur={(e) => setForgotIsOutBlur(false)}
              />
              {pathMatchForgotPassword && (
                <TextField
                  style={{ width: '376px' }}
                  onChange={(e) => {
                    if (e.nativeEvent.inputType !== 'deleteContentBackward') {
                      checkMatch(e)
                    }
                    if (e.nativeEvent.inputType === 'deleteContentBackward') {
                      setcustomerId(-1)
                      setcustomerName(e.target.value.toLocaleUpperCase('tr-TR'))
                    }
                    if (matches.length !== 1 || customerId === -1) {
                      setcustomerName(e.target.value.toLocaleUpperCase('tr-TR'))
                      setIsOutBlurOrganisation(true)
                    }
                  }}
                  onBlur={() =>
                    matches.length > 0
                      ? (setIsOutBlurOrganisation(false), setCustomerRequired(false))
                      : setIsOutBlurOrganisation(false)
                  }
                  value={customerName}
                  label='ORGANISATION'
                  name='organisation'
                  InputProps={{
                    endAdornment: customerName.length > 1 && (
                      <IconButton onClick={() => (setcustomerName(''), setcustomerId(-1))}>
                        <CloseIcon />
                      </IconButton>
                    ),
                    inputProps: { style: { textTransform: 'uppercase' } },
                  }}
                  helperText={
                    (customerRequired && 'Required Field') ||
                    (customerName.length > 2 &&
                      customerId === -1 &&
                      isOutBlurOrganisation === false &&
                      'Required Field')
                  }
                  error={
                    customerRequired ||
                    (customerName.length > 2 &&
                      customerId === -1 &&
                      isOutBlurOrganisation === false)
                  }
                  autoComplete='organisation'
                  autoFocus
                />
              )}
            </Grid>
          ) : (
            <Grid
              container
              justifyContent={'center'}
              alignContent={'center'}
              display={'column'}
              textAlign={'center'}
              rowGap={2}
              height={400}
            >
              <Typography fontWeight={'bold'} variant='h4'>
                Verify Your Email
              </Typography>
              <Typography color={'#6C757D'} variant='h6'>
                The password reset link will be sent to the email you’ve provided. It would be a
                good idea to check your junk emails.
              </Typography>
              <Typography color={'#6C757D'} variant='h6'>
                Did’t receive the email?{' '}
                <Button
                  variant='text'
                  size='small'
                  style={{ fontSize: '16px', textTransform: 'none' }}
                  disabled={forgotPasswordLoading}
                  onClick={() => sendMailReset()}
                >
                  Resend
                </Button>
              </Typography>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          {!sendEmail && (
            <Button
              variant='contained'
              onClick={() => setForgotPasswordModal(false)}
              style={{ marginRight: 20 }}
              color='inherit'
            >
              DISCARD
            </Button>
          )}

          {!sendEmail && (
            <Button
              variant='contained'
              disabled={
                forgotEmailisvalid.length > 0 ||
                forgotEmailisvalid !== true ||
                forgotPasswordLoading
              }
              onClick={() => sendMailReset()}
              style={{ marginRight: 7 }}
            >
              SEND{' '}
              {forgotPasswordLoading && (
                <CircularProgress color='inherit' size={20} style={{ marginLeft: 5 }} />
              )}
            </Button>
          )}
          {sendEmail && (
            <Button
              variant='contained'
              disabled={forgotPasswordLoading}
              onClick={() => setForgotPasswordModal(false)}
              style={{ marginRight: 7 }}
            >
              DONE
              {forgotPasswordLoading && (
                <CircularProgress color='inherit' size={20} style={{ marginLeft: 5 }} />
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default function Snackbar(props) {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} maxSnack={3}>
      <Login {...props} />
    </SnackbarProvider>
  )
}