/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Accordion} from 'react-bootstrap'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

type Props = {}

const VersionNotes3Widget: React.FC<Props> = () => {
  const [accordionList, setAccordionList] = React.useState<any[]>([])

  useEffect(() => {
    setAccordionList([
      {
        id: 4,
        title: 'MobixaPlus v3.0.8',
        date: '5 Şubat 2023',
        content: 'Mobixa is a platform for educating and motivating.',
        version:'3.0.8'
      },
      {
        id: 3,
        title: 'MobixaPlus v3.0.7',
        date: '2 Ocak 2023',
        content:
          'You can create a video by clicking the "Create Video" button in the top right corner of the page.',
        version:'3.0.7'

      },
      {
        id: 2,
        title: 'MobixaPlus v3.0.6',
        date: '1 Aralık 2022',
        content:
          'You can share a video by clicking the "Share Video" button in the top right corner of the page.',
        version:'3.0.6'

      },
      {
        id: 1,
        title: 'MobixaPlus v3.0.5',
        date: '15 Kasım 2022',
        content:
          'You can edit a video by clicking the "Edit Video" button in the top right corner of the page.',
        version:'3.0.5'

      },
    ])
  }, [])
  return (
    <div className='card'>
      <div className='card-body row'>
        <div className='col-5  border-end  border-2'>
          <div className='row col-md-8'>
            <span className='card-label text-center fw-bold text-muted fs-2 mb-1'>Mobixa Plus</span>
            <br />
            <span className='card-label text-center fw-bold text-muted fs-2 mb-1'>
              {accordionList[0]?.version}
            </span>
            <img
              width='240px'
              height='240px'
              src={toAbsoluteUrl('/media/logos/custom-1.svg')}
              className=''
              alt='mobixa.ixon'
            />
          </div>
        </div>
        <div className='col-md-7'>
          <div className='text-center'>
            <span className='ms-5 card-label fw-bold fs-6 mb-1 text-muted '>Change Log</span>
          </div>
          <Accordion defaultActiveKey={['0']} flush alwaysOpen className='accordion accordion-icon-toggle' id='kt_accordion_2'>
            {accordionList.map((item, index) => (
              <Accordion.Item key={item.id} eventKey={item.id} >
                <Accordion.Header className='accordion-icon-toggle' >
                  {item.title} - {item.date}
                </Accordion.Header>
                <Accordion.Body>{item.content}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>

        </div>
      </div>
    </div>
  )
}

export {VersionNotes3Widget}
