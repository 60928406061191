import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    LinearProgress,
    Link,
    MenuItem,
    Pagination,
    PaginationItem,
    Paper,
    Select,
    Slide,
    TextField,
    Tooltip,
    Typography,
    darken,
    lighten,
    styled,
} from '@mui/material'
import {
    DataGridPro,
    GridCellParams,
    GridColumnVisibilityModel,
    GridFilterModel,
    GridRowParams,
    GridSortModel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { TransitionProps } from '@mui/material/transitions'
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReactApexChart from 'react-apexcharts'
import GaugeChart from 'react-gauge-chart'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LinkIcon from '@mui/icons-material/Link';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { useNavigate } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useIntl } from 'react-intl'


const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9) /* color */

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

const getSelectedBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    '& .super-app-theme--Open': {
        backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode
                ),
            },
        },
    },
    '& .super-app-theme--secondary': {
        backgroundColor: getBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.secondary.light, theme.palette.mode),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.secondary.light,
                theme.palette.mode
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.secondary.light,
                    theme.palette.mode
                ),
            },
        },
    },
}))
const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />
})

function TestResult() {
    const LoginState = useSelector((state: any) => state.Login)
    const intl = useIntl()
    const language: any = intl?.messages?.testResults

    const navigate = useNavigate()
    const getIdFromUrl = () => {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        return params.get('id')
    }
    const journeyActivityId = getIdFromUrl()
    const [openProcessingModal, setOpenProcessingModal] = useState(false)
    const getNameFromUrl = () => {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        return params.get('name')
    }
    const testName = getNameFromUrl()

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100,
    })
    const [rows, setRows] = useState([])
    const [rowCount, setRowCount] = useState(0)
    const [selectQuestion, setSelectQuestion] = useState([])
    const [isLoadingData, setIsLoadingData] = useState(false)
    const [questionStatus, setQuestionStatus] = useState(-1)
    const [queryOptions, setQueryOptions] = useState<any>({
        sortModel: [{ field: 'isCompleted', sort: 'desc' }],
    })
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
        /*    id: false,
               brokerId: false,
               status: false, */
    })
    const formatDate = (dateString) => {
        if (!dateString) {
            return { datePart: "", timePart: "" };
        }
        const date = new Date(dateString);
        const dateOptions: any = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const timeOptions: any = { hour: '2-digit', minute: '2-digit' };

        const datePart = date.toLocaleDateString('tr-TR', dateOptions);
        const timePart = date.toLocaleTimeString('tr-TR', timeOptions).split(' ')[0];

        return { datePart, timePart };
    };
    const [hoverCopy, setHoverCopy] = useState<number>(null)

    const columns: any = [
        {
            field: 'name',
            headerName: 'NAME',
            width: 210,
            headerAlign: 'left',
            align: 'left',
            filterable: false,
            /*  valueGetter: (params: any) => params.value.toFixed(0), */
            renderCell: (params) => (
                <Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"start"}>
                    <Typography fontSize={20} color={"#8E8E8E"}>
                        {params.row.userRank}
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: 8 }}>
                        <Avatar
                            src={params.row.avatarPath}
                            sx={{
                                width: "35px",
                                height: "35px"
                            }}
                            imgProps={{
                                style: {
                                    objectFit: "contain"
                                }
                            }}
                        />
                        <Box marginLeft={0.5}>
                            <Typography fontSize={14} fontWeight={"bold"} color={"#000000"}>
                                {`${params.row.name} ${params.row.surname}`}
                            </Typography>
                            <Tooltip arrow title={<Typography fontSize={12} > {`${params.row.email}`}</Typography>}>
                                <Typography fontSize={10} color={"#A1A57B7"} > {`${params.row.email}`}</Typography>
                            </Tooltip>
                        </Box>

                    </div>
                </Box>
            ),
        },
        {
            field: 'startedDate',
            headerName: 'START',
            width: 110,
            headerAlign: 'left',
            align: 'left',
            filterable: false,
            /*  valueGetter: (params: any) => params.value.toFixed(0), */
            renderCell: (params) => {
                const { datePart, timePart } = formatDate(params.row.startDate);
                return (
                    <Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                        <Box textAlign="center">
                            <Typography fontSize={12} color={"#000000"}>
                                {datePart}
                            </Typography>
                            <Typography fontSize={12} color={"#000000"}>
                                {timePart}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'finishedDate',
            headerName: 'FINISH',
            width: 110,
            headerAlign: 'left',
            align: 'left',
            filterable: false,
            /*  valueGetter: (params: any) => params.value.toFixed(0), */
            renderCell: (params) => {
                const { datePart, timePart } = formatDate(params.row.finishedDate);
                return (
                    <Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                        <Box textAlign="center">
                            <Typography fontSize={12} color={"#000000"}>
                                {datePart}
                            </Typography>
                            <Typography fontSize={12} color={"#000000"}>
                                {timePart}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
        },

        {
            field: 'timeSpent',
            headerName: (
                <Box display={"flex"} flexDirection={"column"} textAlign={"center"} justifyContent={"center"} >
                    <Typography
                        display={'flex'}
                        alignItems={'center'}
                        fontSize={12}
                        color={'#a1a5b7'}
                        fontWeight={'bold'}
                    >
                        TIME SPENT
                    </Typography>
                    {/* <Typography marginLeft={1} fontSize={10}>
                dd : hh : mm
              </Typography> */}
                </Box>
            ),
            width: 80,
            /*             description:
                            'How long does  Journey take? Time difference between start date and finish date.', */
            /* type: 'dateTime', */
            headerAlign: 'left',
            align: 'left',
            filterable: false,
            /*      valueFormatter: (params: any) => {
                     const timeParts = params?.row.timeSpent?.split(':') || ''
                     const days = Number(timeParts[0])
                     const hours = Number(timeParts[1])
                     const minutes = Number(timeParts[2])
                     return days || hours || minutes ? `${days}:${hours}:${minutes}` : ''
                 }, */
        },
        {
            field: 'isCompleted',
            headerName: 'COMPLETED',
            width: 110,
            /*  description: 'The number of total users who are assigned to the journey.', */
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            filterable: false,
            renderCell: (params) => (
                <Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontSize={12} fontWeight={"bold"} color={params.row.isCompleted === "Yes" ? "#4CAF50" : "#FF0000"}>
                        {params.row.isCompleted}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'successRate',
            headerName: (
                <Box display={"flex"} flexDirection={"column"} textAlign={"center"} justifyContent={"center"} >
                    <Typography
                        display={'flex'}
                        alignItems={'center'}
                        fontSize={12}
                        color={'#a1a5b7'}
                        fontWeight={'bold'}
                    >
                        SCORE
                    </Typography>
                    {/* <Typography marginLeft={1} fontSize={10}>
                %
              </Typography> */}
                </Box>
            ),
            width: 190,
            /*    description:
                   'The ratio of the users who competed the journey over the number of all assigned users.', */
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            filterable: false,
            renderCell: (params) => (
                <Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                    <Box width={97} mr={0.5} display={"flex"} alignItems={"center"}>
                        <LinearProgress variant="determinate" value={100}
                            sx={{
                                height: 20,
                                borderRadius: "0px 5px 5px 0px",
                                width: (parseInt(params.row.successRate) / 100),
                                '& .MuiLinearProgress-barColorPrimary': {
                                    backgroundColor: params.row.successRate < 50 ? "#FF0000" :
                                        params.row.successRate < 75 ? "#D9F82D" : params.row.successRate <= 100 ? "#4CAF50" : "", // Renk durumuna göre ayarlanıyor
                                },
                            }}
                        />
                        <Typography marginLeft={0.3} fontSize={14} fontWeight={"bold"}>
                            {`${params.row.successRate}%`}
                        </Typography>
                    </Box>
                    {
                        params.row.startDate &&
                        <Box>
                            <Tooltip arrow title="Private link for the participant" placement='top'>
                                <IconButton size='small' onClick={() => {
                                    setOpenProcessingModal(true)
                                    handleCreatePaper(params.row.userId)
                                }} onMouseEnter={() => setHoverCopy(params.row.userId)} onMouseLeave={() => setHoverCopy(null)}>
                                    <LinkIcon fontSize='medium' sx={{ color: params.row.userId === (selectQuestion[0] || hoverCopy) ? "#707070" : "#D5D5D5" }} />
                                </IconButton>
                            </Tooltip>
                            <IconButton size='small' disabled={categoryLoading} onClick={(e) => {
                                if (selectQuestion.includes(params.row.userId)) {
                                    setSelectQuestion([]);
                                } else {
                                    setSelectQuestion([params.row.userId]);
                                }
                                params.row.userId === selectQuestion[0] ? fetchCategoryReport() : fetchCategoryReport(params.row.userId)
                                params.row.userId === selectQuestion[0] ? fetchQuizPaper() : fetchQuizPaper(params.row.userId)
                            }} onMouseEnter={() => setHoverCopy(params.row.userId)} onMouseLeave={() => setHoverCopy(null)}>
                                <VisibilityIcon fontSize='medium' sx={{ color: params.row.userId === (selectQuestion[0] || hoverCopy) ? "#707070" : "#D5D5D5" }} />
                            </IconButton>
                        </Box>
                    }
                </Box>
            ),
        },

    ]

    const CustomPagination = () => {
        const handlePageChange = (event: any, page: any) => {
            setPaginationModel((prevModel) => ({
                ...prevModel,
                page: page - 1,
            }))
        }

        const handlePageSizeChange = (event: any) => {
            setPaginationModel((prevModel) => ({
                ...prevModel,
                pageSize: parseInt(event.target.value, 10),
                page: 0,
            }))
        }

        const startRow = paginationModel.page * paginationModel.pageSize
        const endRow = Math.min((paginationModel.page) * paginationModel.pageSize, rowCount)
        const totalPages = Math.ceil(rowCount / paginationModel.pageSize)

        return (
            <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
                <Box>
                    Rows per page:
                    <Select
                        value={paginationModel.pageSize}
                        onChange={handlePageSizeChange}
                        variant='outlined'
                        size='small'
                        sx={{ ml: 1, mr: 1 }}
                    >
                        <MenuItem selected value={100}>
                            100
                        </MenuItem>
                        <MenuItem value={150}>150</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                    </Select>
                </Box>
                <Pagination
                    count={totalPages}
                    page={paginationModel.page + 1}
                    showFirstButton
                    showLastButton
                    disabled={isLoadingData}
                    size='small'
                    onChange={handlePageChange}
                />
                <Box>
                    Showing {startRow}-{endRow} of {rowCount} record
                </Box>
            </Box>
        )
    }

    const handleSelectionModelChange = (newSelectionModel: any) => {
        if (newSelectionModel.length > 1) {
            // Yalnızca en son seçilen öğeyi koruyun
            const newSelectedId = newSelectionModel[newSelectionModel.length - 1];
            setSelectQuestion([newSelectedId]);
        } else {
            setSelectQuestion(newSelectionModel);
        }
    }

    const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
        // Tıklanan hücreye özgü işlemleri gerçekleştirin
        /*  if (params.field === '__check__' || params.row.source === 'Mobixa') {
          return
        } */
        /*  setOpenUserList(true) */
        if (selectQuestion.includes(params.id)) {
            setSelectQuestion([]);
        } else {
            // Değilse, tıklanan satırı seç
            setSelectQuestion([params.id]);
        }
        /*  navigate(`/reports/JourneysReportDetail?id=${params.row.journeyId}&name=${params.row.name}`);
         fetchUserList(params.row.journeyId) */
        // Diğer işlemleri burada yapabilirsiniz
    }
    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions((prevOptions: any) => ({
            ...prevOptions,
            sortModel: [...sortModel],
        }))
    }, [])
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        // Here you save the data you need from the filter model

        /*    if (filterModel.items.length < 1) {
              return
            } */

        setQueryOptions((prevOptions: any) => ({
            ...prevOptions,
            filterModel: { ...filterModel },
        }))
    }, [])



    useEffect(() => {
        if (journeyActivityId) {
            fetchTestResult()
        }
    }, [journeyActivityId])

    function handleRouteNotes(id) {
        navigate(`/manage/users/detail?id=${id}&page=${1}`)
    }

    const [loadingTestResult, setLoadingTestResult] = useState(false)
    const [topbarData, setTopbarData] = useState<any>(null)

    const processDataForChart = (data) => {
        const categories = data.map(item => item.date);
        const seriesData = data.map(item => item.successRate);

        return {
            categories,
            seriesData
        };
    };
    const [apexChartSuccess, setApexChartSuccess] = useState<any>({
        series: [{
            name: 'Knowledge Level',
            data: [0, 0, 0]
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                /* bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded'
                }, */
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val ? val + "%" : "";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },

            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: ["", "", ""],
                labels: {
                    style: {
                        fontSize: '12px' // Burada font boyutunu ayarlıyoruz
                    }
                },
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                enabled: false
            },
        },
    })
    const fetchTestResult = async () => {
        setLoadingTestResult(true)
        await axios
            .get(
                `${process.env.REACT_APP_API_URL}/report/testResult/${journeyActivityId}`,
                {
                    headers: {
                        accept: 'application/json',
                        Authorization: `Bearer ${LoginState.loginData.access_token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                setTopbarData(response.data)
                const { categories, seriesData } = processDataForChart(response.data.chart);
                setApexChartSuccess(prevState => ({
                    ...prevState,
                    series: [{ ...prevState.series[0], data: seriesData }],
                    options: { ...prevState.options, xaxis: { ...prevState.options.xaxis, categories } }
                }));
                setLoadingTestResult(false)
            })
            .catch((err) => {
                setLoadingTestResult(false)
            })
    }
    const [categoryLoading, setCategoryLoading] = useState(false)
    const [categoryData, setCategoryData] = useState<any>(null)
    const [invidualScore, setInvidualScore] = useState<any>(null)

    useEffect(() => {
        fetchCategoryReport()
        fetchQuizPaper()
    }, [])

    const fetchCategoryReport = async (number?: number) => {
        setCategoryLoading(true)
        await axios
            .post(
                `${process.env.REACT_APP_API_URL}/report/testResult/journeyActivityCategoryReport/${journeyActivityId}/${number ? number : -1}`, {},
                {
                    headers: {
                        accept: 'application/json',
                        Authorization: `Bearer ${LoginState.loginData.access_token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                setCategoryData(response.data.categoryList)
                setInvidualScore({ userDetail: response.data.userDetail, userId: number })
                setCategoryLoading(false)
            })
            .catch((err) => {
                setCategoryLoading(false)
            })
    }
    const [quizData, setQuizData] = useState<any>([])
    const [quizLoading, setQuizLoading] = useState(false)
    const [quizAnswerOpen, setQuizAnswerOpen] = useState(false)

    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };



    const fetchQuizPaper = async (number?: number) => {
        setQuizLoading(true)
        await axios
            .post(
                `${process.env.REACT_APP_API_URL}/report/testResult/getQuizPaper/${journeyActivityId}/${number ? number : -1}`, {},
                {
                    headers: {
                        accept: 'application/json',
                        Authorization: `Bearer ${LoginState.loginData.access_token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                setQuizData(response.data)
                setQuizLoading(false)
            })
            .catch((err) => {
                setQuizLoading(false)
            })
    }

    const startRow = ((page - 1) * pageSize)
    const endRow = startRow + pageSize
    const totalPages = Math.ceil((quizData?.length) / pageSize)

    const [loadingCreatePaper, setLoadingCreatePaper] = useState(false)
    const [createPaperData, setCreatePaperData] = useState<any>(null)

    const handleCreatePaper = async (userId?: number) => {
        setLoadingCreatePaper(true)
        await axios
            .post(
                `${process.env.REACT_APP_API_URL}/report/testResult/createQuizLink/${journeyActivityId}/${userId}`, {},
                {
                    headers: {
                        accept: 'application/json',
                        Authorization: `Bearer ${LoginState.loginData.access_token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                setCreatePaperData(response.data)
                setTimeout(() => {
                    setLoadingCreatePaper(false)
                }, 2000);
            })
            .catch((err) => {
                setLoadingCreatePaper(false)
            })
    }

    useEffect(() => {
        fetchRows()
    }, [paginationModel, queryOptions])

    const fetchRows = async () => {
        setIsLoadingData(true)
        await axios
            .post(
                `${process.env.REACT_APP_API_URL}/report/testResult/journeyActivityAssignedUsers/${journeyActivityId}?page=${paginationModel.page + 1
                }&size=${paginationModel.pageSize}`,
                {
                    queryOptions
                },
                {
                    headers: {
                        accept: 'application/json',
                        Authorization: `Bearer ${LoginState.loginData.access_token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                const formattedRows = response.data.items?.map((row: any) => ({
                    userId: row.userId,
                    name: row.name,
                    surname: row.surname,
                    email: row.email,
                    startDate: row.startedDate,
                    finishedDate: row.finishedDate,
                    timeSpent: row.timeSpent,
                    isCompleted: row.isCompleted,
                    successRate: row.successRate,
                    userRank: row.userRank,
                    avatarPath: row.avatarPath
                }))
                setRows(formattedRows || [])
                setRowCount(response.data.total)
                setIsLoadingData(false)
            })
            .catch((err) => {
                setIsLoadingData(false)
            })
    }
    const [copy, setCopy] = useState(false)
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopy(true)
            setTimeout(() => {
                setCopy(false)
            }, 2000);
            // Başarılı kopyalama işlemi sonrası bir geri bildirim sağlayabilirsiniz.
        }).catch(err => {
            // Kopyalama işlemi sırasında bir hata oluşursa
            setCopy(false)
        });
    };
    const textAreaRef = useRef(null);

    useEffect(() => {
        // Component mount edildiğinde metin alanının içeriğini seç
        if (textAreaRef.current && createPaperData) {
            textAreaRef.current.select();
        }
    }, [createPaperData]);


    return (
        <>
            <div
                style={{
                    display: 'flex',
                    height: 40,
                    width: '80%',
                    marginBottom: '5px',
                    marginTop: '-30px',
                    cursor: 'default',
                    pointerEvents: 'none',
                    userSelect: 'none',
                }}
            >
                <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
                    Reports - Journey Detail By Activity - {testName} - Test Results
                </Typography>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={12} padding={0}>
                    <Box display={"flex"} width={"100%"} justifyContent={"space-around"} >
                        <Paper sx={{ width: 150, height: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                            <Typography fontSize={14} fontWeight={"bold"} color={"#000000"}>{topbarData?.testType}</Typography>
                            <Typography fontSize={12} color={"#707070"}>Test Type</Typography>
                        </Paper>
                        <Paper sx={{ width: 150, height: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                            <Typography fontSize={14} fontWeight={"bold"} color={"#000000"}>{topbarData?.testName}</Typography>
                            <Typography fontSize={12} color={"#707070"}>Test Name</Typography>
                        </Paper>
                        <Paper sx={{ width: 150, height: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                            <Typography fontSize={14} fontWeight={"bold"} color={"#000000"}>{topbarData?.questionCount}</Typography>
                            <Typography fontSize={12} color={"#707070"}>Questions</Typography>
                        </Paper>
                        <Paper sx={{ width: 150, height: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                            <Typography fontSize={14} fontWeight={"bold"} color={"#000000"}>{topbarData?.assignedUsers}</Typography>
                            <Typography fontSize={12} color={"#707070"}>Assigned Users</Typography>
                        </Paper>
                        <Paper sx={{ width: 150, height: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                            <Typography fontSize={14} fontWeight={"bold"} color={"#000000"}>{topbarData?.participatedUsers}</Typography>
                            <Typography fontSize={12} color={"#707070"}>Participated Users</Typography>
                        </Paper>
                        <Paper sx={{ width: 150, height: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                            <Typography fontSize={14} fontWeight={"bold"} color={"#000000"}>{topbarData?.timeLimit}</Typography>
                            <Typography fontSize={12} color={"#707070"}>Time Limit</Typography>
                        </Paper>
                        <Paper sx={{ width: 150, height: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                            <Typography fontSize={14} fontWeight={"bold"} color={"#000000"}>{topbarData?.finished}</Typography>
                            <Typography fontSize={12} color={"#707070"}>Finished?</Typography>
                        </Paper>
                    </Box>
                </Grid>
                {
                    //#region Mother Grid
                    <Grid container item xs={12} md={12} lg={12} xl={12} spacing={2}    /* minWidth={640} */ >
                        {
                            //#region teamScore
                            <Grid item xs={12} md={12} lg={7} xl={7} display={"flex"} justifyContent={"center"} /* paddingLeft={2} paddingRight={1} */ >
                                <Grid xs={12} width={"100%"} height={"auto"} >
                                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                                        <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                            sx={{
                                                background: "#EFF7FF",
                                                borderRadius: "10px 10px 0px 0px"
                                            }}
                                        >
                                            <div style={{ width: "100%", display: "flex", justifyContent: "start", paddingLeft: 50 }}>
                                                <div style={{ width: "70%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography marginLeft={3} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Team Score</Typography>
                                                    <Typography marginLeft={3} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Score Distirbution</Typography>
                                                </div>
                                            </div>


                                            <div>
                                                <Tooltip arrow title={language?.teamScore} placement='top' >
                                                    <IconButton size='small'>
                                                        <InfoOutlinedIcon fontSize='medium' />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        {
                                            //#region content
                                            <Grid xs={12} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                                <div style={{ position: "relative", display: "inline-block", height: "100%", width: 220, marginLeft: -15, paddingTop: 40 }}>
                                                    <GaugeChart
                                                        id="gauge-chart5"
                                                        nrOfLevels={420}
                                                        arcsLength={[0.5, 0.25, 0.25]}
                                                        colors={['#FF0000', '#D9F82D', '#76FF00']}
                                                        percent={(topbarData?.successRate / 100)}
                                                        arcPadding={0.02}
                                                        textColor={'#555555'}
                                                        needleColor={'#C9CED4'}
                                                        needleBaseColor={'#E0E0E0'}
                                                        hideText={true}
                                                    />
                                                    <div style={{ display: "flex", alignItems: "end", justifyContent: "center", marginTop: -70, paddingLeft: 15 }}>
                                                        <Typography fontSize={40} fontWeight={"bold"} color={"#212529"}>{topbarData?.successRate}</Typography>
                                                        <Typography fontSize={25} fontWeight={"bold"} color={"#212529"}>%</Typography>
                                                    </div>
                                                </div>
                                                <Box marginLeft={-2.5} sx={{ height: 90, width: 115, border: "1px solid #D5D5D5", borderRadius: "5px", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", textAlign: "center" }}>
                                                    <Typography color={"#212529"} fontSize={14} fontWeight={"bold"}>{topbarData?.totalAnswerCount}</Typography>
                                                    <Typography color={"#212529"} fontSize={12} >Asked questions</Typography>
                                                    <Typography color={"#212529"} fontSize={14} fontWeight={"bold"}>{topbarData?.correctAnswerCount}</Typography>
                                                    <Typography color={"#212529"} fontSize={12} >Correct answers</Typography>
                                                </Box>
                                                {
                                                    <Box sx={{ width: 430, minHeight: 150, height: "100%", paddingRight: 2 }}>
                                                        {
                                                            !isLoadingData &&
                                                            <ReactApexChart key={0} options={apexChartSuccess.options} series={apexChartSuccess.series} type="bar" height={"100%"} />
                                                        }
                                                    </Box>
                                                }

                                            </Grid>
                                            //#endregion
                                        }

                                    </Paper>
                                </Grid>
                            </Grid>
                            //#endregion
                        }

                        {
                            //#region individual score
                            <Grid item xs={12} md={12} lg={5} xl={5}>
                                <Grid xs={12} width={"100%"} height={"100%"} >
                                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                                        <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                            sx={{
                                                background: "#EFF7FF",
                                                borderRadius: "10px 10px 0px 0px"
                                            }}
                                        >
                                            <Typography marginLeft={3} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Individual Score</Typography>
                                            <div>
                                                <Tooltip arrow title={language?.individualScore} placement='top' >
                                                    <IconButton size='small'>
                                                        <InfoOutlinedIcon fontSize='medium' />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        {
                                            //#region content
                                            <Grid xs={12} minHeight={170} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} padding={1}>
                                                {categoryLoading &&
                                                    <Box width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                                                        <CircularProgress size={30} color='primary' />
                                                    </Box>
                                                }
                                                {
                                                    !categoryLoading && !invidualScore?.userId &&
                                                    <Box width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                                                        <Typography fontSize={14} fontWeight={"600"} color={"#707070"}>Select a participant to see the individual performance.</Typography>
                                                    </Box>
                                                }

                                                {invidualScore?.userId &&
                                                    <Grid xs={4} sm={4.5}/*  width={"30%"} */ height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                                                        <Typography fontSize={14} fontWeight={"bold"} color={"black"}>{`${rows.find((item: any) => item.userId === invidualScore?.userId).name} ${rows.find((item: any) => item.userId === invidualScore?.userId).surname}`}</Typography>
                                                        <Avatar
                                                            src={rows.find((item: any) => item.userId === invidualScore?.userId).avatarPath}
                                                            variant='rounded'
                                                            sx={{
                                                                width: "95px",
                                                                height: "95px",
                                                                borderRadius: 2
                                                            }}
                                                            imgProps={{
                                                                style: {
                                                                    objectFit: "contain"
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                }

                                                {
                                                    invidualScore?.userId &&
                                                    <Grid xs={8} sm={7.5} height={"100%"}/*  width={"70%"}  */ display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                                                        <div style={{ position: "relative", display: "inline-block", height: "100%", width: 220, marginLeft: -15, paddingTop: 40 }}>
                                                            <GaugeChart
                                                                id="gauge-chart5"
                                                                nrOfLevels={420}
                                                                arcsLength={[0.5, 0.25, 0.25]}
                                                                colors={['#FF0000', '#D9F82D', '#76FF00']}
                                                                percent={(invidualScore?.userDetail.successRate / 100)}
                                                                arcPadding={0.02}
                                                                textColor={'#555555'}
                                                                needleColor={'#C9CED4'}
                                                                needleBaseColor={'#E0E0E0'}
                                                                hideText={true}
                                                            />
                                                            <div style={{ display: "flex", alignItems: "end", justifyContent: "center", marginTop: -70, paddingLeft: 15 }}>
                                                                <Typography fontSize={40} fontWeight={"bold"} color={"#212529"}>{invidualScore?.userDetail.successRate}</Typography>
                                                                <Typography fontSize={25} fontWeight={"bold"} color={"#212529"}>%</Typography>
                                                            </div>
                                                        </div>
                                                        <Box marginLeft={-2.5} sx={{ height: 90, width: 115, border: "1px solid #D5D5D5", borderRadius: "5px", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", textAlign: "center" }}>
                                                            <Typography color={"#212529"} fontSize={14} fontWeight={"bold"}>{invidualScore?.userDetail.totalAnswers}</Typography>
                                                            <Typography color={"#212529"} fontSize={12} >Asked questions</Typography>
                                                            <Typography color={"#212529"} fontSize={14} fontWeight={"bold"}>{invidualScore?.userDetail.correctAnswers}</Typography>
                                                            <Typography color={"#212529"} fontSize={12} >Correct answers</Typography>
                                                        </Box>
                                                    </Grid>
                                                }

                                            </Grid>

                                            //#endregion
                                        }
                                        {
                                            invidualScore?.userId &&
                                            <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                                sx={{
                                                    background: "#EFF7FF"
                                                }}
                                            >
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%" }} onClick={() => selectQuestion[0] ? handleRouteNotes(selectQuestion[0]) : null}>

                                                    <Typography fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Add Note</Typography>
                                                    <ChevronRightOutlinedIcon fontSize='large' sx={{ color: "#6C757D" }} />

                                                </div>
                                            </Grid>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                            //#endregion
                        }
                        {
                            //#region  participant list
                            <Grid item xs={12} md={12} lg={7} xl={7} display={"flex"} justifyContent={"center"} /* paddingLeft={2} paddingRight={1} */ >
                                <Grid xs={12} width={"100%"} height={"auto"} >
                                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                                        <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                            sx={{
                                                background: "#EFF7FF",
                                                borderRadius: "10px 10px 0px 0px"
                                            }}
                                        >

                                            <Typography marginLeft={3} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Participant List</Typography>
                                            <div>
                                                <Tooltip arrow title={language?.participantList} placement='top' >
                                                    <IconButton size='small'>
                                                        <InfoOutlinedIcon fontSize='medium' />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        {
                                            //#region content
                                            <Grid xs={12} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                                <div className='card card-custom p-3  ' style={{ width: "100%" }}>
                                                    <Box sx={{ height: 500, width: '100%' }}>
                                                        <StyledDataGrid
                                                            /*     {...data}
                                                                  loading={data.rows.length === 0} */
                                                            rows={rows || []}
                                                            columns={columns}
                                                            rowHeight={50}
                                                            columnHeaderHeight={40}
                                                            disableVirtualization
                                                            /* checkboxSelection */
                                                            /* onCellClick={handleCellClick} */
                                                            getRowId={(row) => row.userId}
                                                            columnVisibilityModel={columnVisibilityModel}
                                                            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                                                            disableRowSelectionOnClick
                                                            sortingMode='server'
                                                            sortModel={queryOptions?.sortModel}
                                                            onSortModelChange={handleSortModelChange}
                                                            onRowSelectionModelChange={handleSelectionModelChange}
                                                            filterMode='client'
                                                            onFilterModelChange={onFilterChange}
                                                            /*  isRowSelectable={(params: GridRowParams) => params.row.source === 'Client'} */
                                                            rowCount={rowCount}
                                                            loading={isLoadingData}
                                                            paginationModel={paginationModel}
                                                            rowSelectionModel={selectQuestion}
                                                            paginationMode='server'
                                                            pagination={true}
                                                            onPaginationModelChange={setPaginationModel}
                                                            getRowClassName={(params) =>
                                                                `super-app-theme--${params.row.source === 'Mobixa' ? 'secondary' : ''}`
                                                            }
                                                            slots={{
                                                                /*    toolbar: CustomToolbar, */
                                                                pagination: CustomPagination,
                                                            }}
                                                            sx={{
                                                                boxShadow: "none",
                                                                border: "none",
                                                                '& .MuiDataGrid-footerContainer': {
                                                                    padding: '0px', // Footer'ın içerisindeki padding'i daraltmak için bu değerleri ayarlayabilirsiniz
                                                                    height: 30
                                                                },

                                                                '& .MuiDataGrid-cell:hover': {
                                                                    /*    color: 'primary.main', */
                                                                    cursor: 'pointer',
                                                                },
                                                                '&	.MuiDataGrid-columnHeaderTitle': {
                                                                    fontWeight: 'bold',
                                                                    color: '#A1A5B7',
                                                                    fontSize: 12,
                                                                },
                                                                '& .MuiDataGrid-cell:focus': {
                                                                    outline: 'none',
                                                                },
                                                                /*   '& .MuiDataGrid-columnHeaders': {
                                                                      backgroundColor: '#E5F1FD', // Başlık hücresinin arka plan rengi
                                                                  }, */
                                                                '& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover': {
                                                                    backgroundColor: '#D5D5D5',
                                                                }
                                                            }}
                                                        />
                                                    </Box>
                                                </div>
                                            </Grid>
                                            //#endregion
                                        }

                                    </Paper>
                                </Grid>
                            </Grid>
                            //#endregion
                        }
                        {
                            //#region Category
                            <Grid item xs={12} md={12} lg={5} xl={5}>
                                <Grid xs={12} width={"100%"} height={"300px"} >
                                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                                        <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                            sx={{
                                                background: "#EFF7FF",
                                                borderRadius: "10px 10px 0px 0px"
                                            }}
                                        >

                                            <Typography marginLeft={3} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Category</Typography>
                                            <div>
                                                <Tooltip arrow title={language?.category} placement='top' >
                                                    <IconButton size='small'>
                                                        <InfoOutlinedIcon fontSize='medium' />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        {
                                            //#region content
                                            <Grid xs={12} height={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"start"}>
                                                {categoryLoading &&
                                                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"} width={"100%"}>

                                                        <CircularProgress size={30} color='primary' />
                                                    </Box>
                                                }
                                                {
                                                    !categoryLoading && categoryData?.map((item: any, index: number) => {
                                                        return <Box display={"flex"} height={38} width={"100%"} alignItems={"center"} justifyContent={"space-between"}
                                                            sx={{
                                                                background: (index + 1) % 2 === 0 ? "#e6e6e6" : "",
                                                                paddingTop: 3,
                                                                paddingBottom: 3,
                                                            }}>
                                                            <Box width={"100%"} mr={0.5} display={"flex"} alignItems={"center"} padding={1} columnGap={1}>
                                                                <Box display={"flex"} alignItems={"center"} width={"50%"} columnGap={2} >
                                                                    <Typography fontSize={20} fontWeight={"bold"} color={"#8E8E8E"}>
                                                                        {index + 1}
                                                                    </Typography>
                                                                    <Typography fontSize={14} fontWeight={"bold"} color={"black"}>
                                                                        {item.categoryName}
                                                                    </Typography>
                                                                </Box>
                                                                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} height={"100%"} width={"100%"}>
                                                                    <Box display={"flex"} alignItems={"center"} height={"100%"} width={"30%"} >
                                                                        <LinearProgress variant="determinate" value={100}
                                                                            sx={{
                                                                                height: item.userSuccessRate !== null ? 15 : 30,
                                                                                borderRadius: "0px 5px 5px 0px",
                                                                                width: (item.successRate === 0 ? "1%" : `${parseInt(item.successRate)}%`),
                                                                                '& .MuiLinearProgress-barColorPrimary': {
                                                                                    backgroundColor: "#007DBF"
                                                                                },
                                                                            }}
                                                                        />
                                                                        <Box width={30}>
                                                                            <Typography marginLeft={0.3} fontSize={14} fontWeight={"bold"}>
                                                                                {`${item.successRate}%`}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    {
                                                                        item.userSuccessRate !== null &&
                                                                        <Box display={"flex"} alignItems={"center"} height={"100%"} width={"30%"} >
                                                                            <LinearProgress variant="determinate" value={100}
                                                                                sx={{
                                                                                    height: 15,
                                                                                    borderRadius: "0px 5px 5px 0px",
                                                                                    width: (item.userSuccessRate === 0 ? "1%" : `${parseInt(item.userSuccessRate)}%`),
                                                                                    '& .MuiLinearProgress-barColorPrimary': {
                                                                                        backgroundColor: "#2196F3"
                                                                                    },
                                                                                }}
                                                                            />
                                                                            <Box width={30}>
                                                                                <Typography marginLeft={0.3} fontSize={14} fontWeight={"bold"}>
                                                                                    {`${item.userSuccessRate}%`}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    })
                                                }

                                            </Grid>
                                            //#endregion
                                        }
                                        {invidualScore?.userId &&
                                            <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                                sx={{
                                                    background: "#EFF7FF"
                                                }}
                                            >
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} >
                                                    <Box width={15} height={15} borderRadius={"5px"} sx={{ background: "#007DBF" }}></Box>
                                                    <Typography marginLeft={1} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Team Average</Typography>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} >
                                                    <Box width={15} height={15} borderRadius={"5px"} sx={{ background: "#2196F3" }}></Box>
                                                    <Typography marginLeft={1} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>{`${rows.find((item: any) => item.userId === invidualScore?.userId).name} ${rows.find((item: any) => item.userId === invidualScore?.userId).surname}`}</Typography>
                                                </div>
                                            </Grid>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                            //#endregion
                        }
                        {
                            //#region question list
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <Grid xs={12} width={"100%"} height={500} marginBottom={5} >
                                    <Paper elevation={3} sx={{ height: "100%", borderRadius: "10px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                                        <Grid xs={12} height={25} maxHeight={25} display={"flex"} justifyContent={"space-between"} alignItems={"center"}
                                            sx={{
                                                background: "#EFF7FF",
                                                borderRadius: "10px 10px 0px 0px"
                                            }}
                                        >
                                            <Typography marginLeft={3} fontSize={14} fontWeight={"bold"} color={"#6C757D"}>Question List</Typography>

                                            <div style={{ display: "flex", alignItems: "center", width: 100, justifyContent: "space-between" }}>
                                                <div>
                                                    <IconButton size='small' onClick={() => setQuizAnswerOpen(quizAnswerOpen ? false : true)}>
                                                        <KeyboardArrowUpRoundedIcon
                                                            fontSize='large'
                                                            sx={{
                                                                transform: quizAnswerOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                                                transition: 'transform 0.3s ease-in-out'
                                                            }}
                                                        />
                                                    </IconButton>
                                                </div>

                                                <div>
                                                    <Tooltip arrow title={language?.questionList} placement='top' >
                                                        <IconButton size='small'>
                                                            <InfoOutlinedIcon fontSize='medium' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </Grid>
                                        {
                                            //#region content
                                            <Grid container xs={12} height={"100%"} display={"flex"} paddingLeft={1} paddingRight={1} rowGap={1} alignItems={"start"} justifyContent={"space-between"}
                                                sx={{ overflowX: "auto" }}
                                            >
                                                <Grid container item xs={12} height={35} sx={{ position: "sticky", top: 0, background: "white", zIndex: 2 }}  >
                                                    <Grid item xs={2} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                        <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>MEDIA</Typography>
                                                    </Grid>
                                                    <Grid item xs={5} height={"100%"} display={"flex"} alignItems={"center"} >
                                                        <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>QUESTION</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} height={"100%"} display={"flex"} alignItems={"center"}>
                                                        <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>CATEGORY</Typography>
                                                    </Grid>
                                                    <Grid item xs={1} height={"100%"} display={"flex"} alignItems={"center"}>
                                                        <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>ID</Typography>
                                                    </Grid>
                                                    <Grid item xs={1} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                        <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>TEAM SCORE</Typography>
                                                    </Grid>
                                                    <Grid item xs={1} height={"100%"} display={"flex"} alignItems={"center"} textAlign={"center"}>
                                                        {
                                                            invidualScore?.userId &&
                                                            <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>{`${rows?.find((item: any) => item.userId === invidualScore?.userId)?.name} ${rows.find((item: any) => item.userId === invidualScore?.userId)?.surname}`}</Typography>
                                                        }
                                                    </Grid>
                                                    {quizLoading &&
                                                        <Grid item xs={12} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                            <CircularProgress size={30} color='primary' />
                                                        </Grid>
                                                    }
                                                </Grid>
                                                {
                                                    quizData?.slice(startRow, endRow)?.map((item: any, index: number) => {
                                                        return <>
                                                            <Grid item xs={12} height={"auto"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                                <Grid item xs={2} height={"100%"} display={"flex"} alignItems={"center"}>
                                                                    <div style={{ width: 40 }}>
                                                                        <Typography fontSize={20} fontWeight={"bold"} color={"#8E8E8E"} >{startRow + (index + 1)}</Typography>
                                                                    </div>

                                                                    <Avatar
                                                                        src={item.mediaUrl}
                                                                        variant='rounded'
                                                                        sx={{
                                                                            width: "80px",
                                                                            height: "36px",
                                                                            borderRadius: 2
                                                                        }}
                                                                        imgProps={{
                                                                            style: {
                                                                                objectFit: "contain"
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Avatar
                                                                            src={"/media/stock/image.png"}
                                                                            variant='rounded'
                                                                            sx={{
                                                                                width: "80px",
                                                                                height: "36px",
                                                                                borderRadius: 2,
                                                                                background: "none"
                                                                            }}
                                                                            imgProps={{
                                                                                style: {
                                                                                    objectFit: "contain"
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Avatar>
                                                                </Grid>
                                                                <Grid item xs={5} height={"100%"} display={"flex"} alignItems={"center"} >
                                                                    <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>{item.description}</Typography>
                                                                </Grid>
                                                                <Grid item xs={2} height={"100%"} display={"flex"} alignItems={"center"}>
                                                                    <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>{item.category}</Typography>
                                                                </Grid>
                                                                <Grid item xs={1} height={"100%"} display={"flex"} alignItems={"center"}>
                                                                    <Link href={`/manage/QuestionPage?edit=${item.questionId}`} color="#1280C0" underline="hover">
                                                                        {item.questionId}
                                                                    </Link>
                                                                    {/*  <Typography fontSize={12} fontWeight={"bold"} color={"#1280C0"}>{item.categoryId}</Typography> */}
                                                                </Grid>
                                                                <Grid item xs={1} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                                    <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>{item.teamScore}</Typography>
                                                                </Grid>

                                                                <Grid item xs={1} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                                                                    {
                                                                        invidualScore?.userId ?
                                                                            item.userIsCorrect ?
                                                                                <CheckCircleIcon sx={{ color: '#4CAF50' }} /> : <CancelRoundedIcon sx={{ color: '#FF0000' }} />
                                                                            : null
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            {
                                                                quizAnswerOpen &&
                                                                <Grid item xs={12} minHeight={"100px"} display={"flex"} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                                                                    <Grid item xs={6} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                                                                    </Grid>
                                                                    <Grid item xs={4} height={"auto"} display={"flex"}
                                                                        rowGap={1} paddingTop={1} paddingBottom={2} flexDirection={"column"} justifyContent={"start"} alignItems={"center"} textAlign={"center"}
                                                                    /*   sx={{
                                                                          overflowY: "scroll",
                                                                          '&::-webkit-scrollbar': {
                                                                              display: 'none',
                                                                          },
                                                                          '-ms-overflow-style': 'none', 
                                                                          'scrollbarWidth': 'none',
                                                                      }} */
                                                                    >
                                                                        {item.questionAnswers?.map((item: any) => {
                                                                            return <Box display={"flex"} width={"100%"} height={"100%"} maxHeight={80} borderRadius={"3px"} border={"1px solid #D5D5D5"} justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Checkbox disabled={true} checked={item.isCorrect} />
                                                                                <Box width={"100%"} height={"100%"} maxHeight={80} display={"flex"} justifyContent={"start"}
                                                                                    sx={{
                                                                                        overflowY: "scroll",
                                                                                        '&::-webkit-scrollbar': {
                                                                                            display: 'none',
                                                                                        },
                                                                                        '-ms-overflow-style': 'none',
                                                                                        'scrollbarWidth': 'none',
                                                                                    }}
                                                                                    paddingTop={2} paddingBottom={2}>
                                                                                    <Typography fontSize={12} color={"#707070"}>{item.description}</Typography>
                                                                                </Box>
                                                                                {
                                                                                    invidualScore?.userId && item.isSelected &&
                                                                                    <Checkbox disabled={true} checked={item.isSelected} />
                                                                                }

                                                                            </Box>
                                                                        })
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                            <Divider sx={{ width: "95%" }} />

                                                        </>
                                                    })
                                                }


                                            </Grid>
                                            //#endregion
                                        }
                                        <Box
                                            display="flex"
                                            justifyContent="end"
                                            alignItems="center"
                                        >
                                            <Pagination
                                                count={totalPages}
                                                page={page}
                                                onChange={handleChangePage}
                                                size='small'
                                                color="primary"
                                                showFirstButton
                                                showLastButton
                                                sx={{
                                                    marginRight: 1
                                                }}
                                            />

                                            <Typography variant="caption" sx={{ color: "#A8ADB2" }}>
                                                Showing {startRow}-{endRow} of {quizData?.length} records
                                            </Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                            //#endregion
                        }
                    </Grid >
                    //#endregion
                }

            </Grid >
            <Dialog
                open={openProcessingModal}
                fullWidth
                maxWidth={"sm"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ display: "flex", alignItems: "center" }}>
                    {
                        loadingCreatePaper ?
                            <Box width={"100%"} height={"200px"} display={"flex"} flexDirection={"column"} textAlign={"center"} justifyContent={"center"} alignItems={"center"}>
                                <Typography fontSize={18} color={"#6C757D"}>Processing…</Typography>
                                <CircularProgress size={40} sx={{ color: "#000000" }} />
                            </Box> :
                            <Box width={"100%"} height={"200px"} display={"flex"} justifyContent={"center"} alignItems={"end"}>
                                <Box width={"80%"} height={"80%"} padding={1} border={"1px solid #707070"} borderRadius={"5px"} display={"flex"} textAlign={"start"} alignItems={"center"} sx={{ wordBreak: 'break-word' }}>
                                    {/* <Typography fontSize={12} color={"#2196F3"}>
                                        <Link href={createPaperData?.paperLink} color="inherit" underline="hover">
                                            {createPaperData?.paperLink}
                                        </Link>
                                        
                                    </Typography> */}
                                    <textarea
                                        ref={textAreaRef}
                                        value={createPaperData?.paperLink || ''}
                                        readOnly // Kullanıcı metni değiştiremesin diye
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            border: 'none',
                                            overflow: 'auto',
                                            outline: 'none',
                                            boxShadow: 'none',
                                            resize: 'none', // Kullanıcı textarea'nın boyutunu değiştiremesin
                                            padding: '8px',
                                            borderRadius: '4px',
                                        }}
                                        onClick={(e) => e.currentTarget.select()} // Kullanıcı metni tekrar tıkladığında, tüm metni seç
                                    />
                                </Box>
                                <Box marginLeft={1} width={"50px"} display={"flex"} flexDirection={"column"} alignItems={"end"} justifyContent={"center"} textAlign={"center"}>
                                    {copy &&
                                        <Typography color={"#4CAF50"}>Copied</Typography>
                                    }
                                    <IconButton onClick={() => copyToClipboard(createPaperData?.paperLink)}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Box>


                            </Box>
                    }

                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center", paddingBottom: 10 }}>
                    <Button variant='contained' color='inherit' onClick={() => setOpenProcessingModal(false)} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default TestResult
