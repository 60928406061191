import React, { FC } from 'react'
import { Spinner } from 'react-bootstrap'
type Props = {
    Loading?: boolean
    isModal?:boolean
  }

const Loading: FC<Props> = ({Loading,isModal}) => {

  return (
    <div className={`card d-flex justify-content-center align-items-center`} style={{minHeight: isModal? '600px':'300px'}}>
      <Spinner style={{scale: '6'}} animation="border" variant="primary" />
    </div>
  )
}

export default Loading